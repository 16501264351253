import React from "react"
import styled from "styled-components"
import { Link, Navigate } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import * as EmailValidator from "email-validator"
import { map, find, filter, isEqual, isEmpty } from "lodash/fp"
import Slim from "_root/slim/slim.react"
import UploadService from "_services/upload-service"
import MemberLayout from "./member-layout"
import { FlexCol, Label, Input, FilePicker, TextArea, Checkbox } from "_layout/form-elements"
import { PrimaryButton, PrimaryInvertLinkButton, SaveButton } from "_layout/buttons"
import OccupationalGroupPicker from "_components/occupational-group-picker"
import ObjectPickerBase from "_components/object-picker"
import { GET_EDUCATIONS, GET_REGIONS, GET_OCCUPATIONAL_AREAS, GET_OCCUPATIONAL_GROUPS } from "_root/common-ql"
import { REGISTER_CANDIDATE, CHECK_CANDIDATE } from "_root/containers/member/members-ql"
import { ADMIN_COMPANY_ID, FileType, AllFileTypes } from "_root/constants"
import inject from "_services/inject"
import to from "_services/await.to"
import all from "_services/await.all"
import { MEDLEM, UPPDATERAD, PROFIL } from "_root/routes/url-names"
import confirm from "_root/components/confirm"

//#region Styles
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);

  input {
    display: block;
  }

  .doc-link {
    margin-right: 1em;
  }
`
const GridRow = styled.div`
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
`
const Error = styled.p`
  margin: 1em 0 1em 0;
  font-size: 0.9em;
  color: var(--color-brand-red);
`
const Notice = styled.p`
  margin: 1em 0 1em 0;
  padding: 1em;
  font-size: 0.9rem;
  border: 2px solid var(--color-brand-red);
  background: var(--color-bg-white);
`
const ButtonWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-line);
  text-align: center;
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
const InfoBox = styled.div`
  margin: 20px 0 40px 0;
  padding: 20px;
  border: 1px solid var(--color-bg-grey);
  background: var(--color-bg-white);

  h4 {
    margin-top: 0;
    margin-bottom: 1em;
  }
`
const SlimEditor = styled.div`
  width: min(300px, 100%);
  height: 300px;
  .slim {
    cursor: pointer;
    border: 1px solid var(--color-bg-dark);
    background: var(--color-bg-white);
    box-shadow: inset 0 0 20px 0 var(--color-bg-grey);

    &[data-state*="empty"]:hover {
      background: var(--color-bg-white);
      box-shadow: inset 0 0 20px 5px var(--color-bg-grey);
    }
  }
`
const RegionList = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  column-count: 3;

  @media (max-width: 600px) {
    column-count: 2;

    li {
      width: 50%;
    }
  }

  @media (max-width: 420px) {
    column-count: 1;

    li {
      width: 100%;
    }
  }

  li {
    display: block;
    margin-right: 15px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
`
const ObjectPicker = styled(ObjectPickerBase)`
  margin-bottom: 20px;
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(REGISTER_CANDIDATE, { name: "registerCandidate" }),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "occupationalAreasQuery" }),
  graphql(GET_OCCUPATIONAL_GROUPS, { name: "occupationalGroupsQuery" }),
  graphql(GET_EDUCATIONS, { name: "education" })
)
export default class MemberRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
    this.setPristineUser()
  }

  initialState = {
    init: true,
    register: false,
    approve: false,
    approved: false,
    loading: true,
    candidateId: null,
    searching: false,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    postalCity: "",
    postalCode: "",
    links: "",
    information: "",
    docs: [],
    spam: false,
    mailChecked: false,
    checkedEmail: null,
    login: false,
    regions: [],
    occupationalAreas: [],
    occupationalGroups: [],
    selectedGroups: [],
    selectedRegions: [],
    isUpdating: false,
    picture: null,
    pictureChanged: false,
    password1: "",
    password2: "",
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const { user, regionsQuery, occupationalAreasQuery, occupationalGroupsQuery, education } = nextProps
    if (user.authenticated) {
      nextState.loggedIn = true
    }
    if (regionsQuery.regions && regionsQuery.regions.length) {
      nextState.regions = regionsQuery.regions
    }
    if (!prevState.occupationalAreas.length && occupationalAreasQuery.occupationalAreas && occupationalAreasQuery.occupationalAreas.length) {
      nextState.occupationalAreas = occupationalAreasQuery.occupationalAreas
    }
    if (!prevState.occupationalGroups.length && occupationalGroupsQuery.occupationalGroups && occupationalGroupsQuery.occupationalGroups.length) {
      nextState.occupationalGroups = occupationalGroupsQuery.occupationalGroups
    }
    nextState.loading = regionsQuery.loading || occupationalAreasQuery.loading || occupationalGroupsQuery.loading || education.loading
    return !isEmpty(nextState) ? nextState : null
  }

  setPristineUser = (state) => {
    const { firstName, lastName, email, postalCity, postalCode, mobile, picture } = state || this.state
    this.variables = { firstName, lastName, email, postalCity, postalCode, mobile, picture }
  }

  isPristineUser = () => {
    const { firstName, lastName, email, postalCity, postalCode, mobile, picture } = this.state
    const variables = { firstName, lastName, email, postalCity, postalCode, mobile, picture }
    return isEqual(variables)(this.variables)
  }

  validatePassword = () => {
    const { password1, password2 } = this.state
    if (!password1 || !password2) return false
    if (password1 !== password2) return false
    if (password1.length < 8) return false
    const hasUpperCase = /[A-Z]/.test(password1)
    const hasLowerCase = /[a-z]/.test(password1)
    const hasNumbers = /\d/.test(password1)
    const hasNonalphas = /\W/.test(password1)
    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) return false
    return true
  }

  validate = () => {
    const { email, firstName, lastName, mobile, postalCity, postalCode, education, selectedGroups } = this.state
    const invalid = {
      email: !EmailValidator.validate(email),
      password: !this.validatePassword(),
      firstName: !firstName || firstName.trim().length === 0,
      lastName: !lastName || lastName.trim().length === 0,
      mobile: !mobile || mobile.trim().length === 0,
      postalCity: !postalCity || postalCity.trim().length === 0,
      postalCode: !postalCode || postalCode.trim().length === 0,
      education: !education || !education.id,
      occupationalGroup: !selectedGroups || !selectedGroups.length,
    }
    const isInvalid =
      invalid.email || invalid.password || invalid.firstName || invalid.lastName || invalid.mobile || invalid.postalCity || invalid.postalCode || invalid.education || invalid.occupationalGroup
    this.setState({ invalid: isInvalid ? invalid : null })
    return !isInvalid
  }

  searchUser = async () => {
    if (EmailValidator.validate(this.state.email)) {
      this.setState({ searching: true })
      const variables = { email: this.state.email }
      const [error, result] = await to(this.props.client.query({ query: CHECK_CANDIDATE, variables: variables }))
      if (error || !result) {
        console.error("member-registration:searchUser:error", error, variables)
        return
      }
      const {
        data: { user: applicant },
      } = result
      const applicantExists = applicant && applicant.id
      if (applicantExists) {
        this.setState({ applicantExists, searching: false })
      } else {
        this.setState({ init: false, register: true, searching: false })
      }
    } else {
      this.setState({ invalid: { email: true } })
    }
  }

  registerCandidate = async () => {
    if (!this.validate()) return
    const { email, firstName, lastName, mobile, postalCity, postalCode, picture, password1, cvId, docs, links, information, selectedGroups, selectedRegions, spam, education } = this.state
    const proceed = !!cvId || (await confirm("Vill du gå vidare utan att bifoga ett CV?"))
    if (!proceed) return
    this.setState({ saving: true })
    const variables = {
      email,
      password: password1,
      firstName,
      lastName,
      mobile,
      interestCompanyId: ADMIN_COMPANY_ID,
      cvId,
      links,
      information,
      docsIds: map((doc) => doc.id)(docs),
      occupationalGroupsIds: map((g) => g.id)(selectedGroups),
      regionsIds: map((r) => r.id)(selectedRegions),
      postalCity,
      postalCode,
      pictureId: picture && picture.id,
      spam,
      educationId: education && education.id,
    }
    if (picture) {
      const [error, { data }] = await to(UploadService.uploadBase64(picture.image, picture.name))
      if (error || !data || !data.id) {
        console.error("member-registration:registerCandidate:uploadPicture:error", error || data)
        this.setState({ errorMessage: "Ett fel uppstod vid uppladdning av foto.", saving: false })
        return
      }
      variables.pictureId = data.id
    }
    const [error, result] = await to(this.props.registerCandidate({ variables }))
    if (error || !result) {
      console.error("member-registration:registerCandidate:error", error, variables)
      this.setState({ errorMessage: "Ett fel uppstod och kontot kunde inte skapas.", saving: false })
    } else {
      this.setState({ saving: false, confirmed: true })
    }
  }

  onEmailKeyPress = (e) => {
    const key = `${e.keyCode || e.charCode}`
    if (key === "13" || key === "10") {
      this.searchUser()
    }
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value.trim() })
  }

  checkEmail = () => {
    const invalid = EmailValidator.validate(this.state.email) ? null : { email: true }
    this.setState({ invalid })
  }

  onSlimInit = (data, slim) => {
    this.slim = slim
    const { picture } = this.state
    if (picture && picture.url) {
      slim._options.instantEdit = false
      slim.load(picture.url, (error, data) => {
        slim._options.instantEdit = true
      })
    }
  }

  onPhotoCancel = (data, slim) => {
    const { picture, pictureChanged } = this.state
    if (pictureChanged || !picture || !picture.url || !this.slim) return
    this.slim._options.instantEdit = false
    this.slim.load(picture.url, (error, data) => {
      this.slim._options.instantEdit = true
    })
  }

  onPhotoConfirm = (data, slim) => {
    this.setState({ picture: slim.dataBase64.output, pictureChanged: true })
  }

  onPhotoRemoved = (data, slim) => {
    this.setState({ picture: undefined, pictureChanged: true })
  }

  onCvSelected = async (file) => {
    if (file) {
      // TODO: Delete existing file?
      const [error, { data }] = await to(UploadService.uploadFile(file))
      if (error || !data || !data.id) {
        console.error("member-registration:onCvSelected:uploadProfile:error", error || data)
        return
      }
      this.setState({ cvId: data.id, cv: data })
    }
  }

  onDocsSelected = async (files) => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map((file) => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload || !resultUpload.length) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        return
      }
      const uploaded = map((upload) => upload.data)(resultUpload)
      const docs = [...this.state.docs, ...uploaded]
      this.setState({ docs })
    }
  }

  onRegionChange = (e, region) => {
    let selectedRegions = [...this.state.selectedRegions]
    if (find((r) => r.id === region.id)(this.state.selectedRegions)) {
      selectedRegions = filter((r) => r.id !== region.id)(selectedRegions)
    } else {
      selectedRegions = [...selectedRegions, region]
    }
    this.setState({ selectedRegions })
  }

  onEducationChange = (education) => {
    this.setState({ education }, () => {
      this.state.invalid && this.validate()
    })
  }

  deleteFile = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!e.target.dataset.doc) return
    const docs = this.state.docs.filter((doc) => doc.id !== e.target.dataset.doc)
    if (docs.length !== this.state.docs.length) {
      this.setState({ docs: docs })
    }
  }

  deleteCV = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ cv: null, cvId: null })
  }

  render() {
    let content = null
    if (this.state.loggedIn) {
      return <Navigate to={MEDLEM + PROFIL} />
    }
    if (this.state.confirmed) {
      return <Navigate to={MEDLEM + UPPDATERAD} />
    }
    else if (this.state.init) {
      const { email, invalid, searching, applicantExists } = this.state
      content = (
        <Section>
          <ButtonWrapper>
            <PrimaryInvertLinkButton to={MEDLEM}>Redan medlem?</PrimaryInvertLinkButton>
          </ButtonWrapper>
          <Ingress>Vänligen börja med att fylla i din e-postadress nedan.</Ingress>
          <Label>E-post *</Label>
          <Input
            type="email"
            autoFocus
            className="mr4"
            placeholder="kandidat@exempel.com"
            value={email}
            $invalid={invalid && invalid.email}
            onKeyPress={this.onEmailKeyPress}
            onBlur={this.checkEmail}
            onChange={this.setEmail}
          />
          <div>
            <PrimaryButton onClick={this.searchUser} disabled={invalid && invalid.email} loading={searching}>
              Gå vidare
            </PrimaryButton>
          </div>
          {applicantExists && !invalid && (
            <Notice>
              E-postadressen är redan kopplad till ett konto. <Link to={MEDLEM}>Logga in</Link> eller använd en annan adress.
            </Notice>
          )}
          {invalid && invalid.email && <Error>Ange en fullständig e-postadress</Error>}
        </Section>
      )
    } else if (this.state.register) {
      const {
        firstName,
        lastName,
        email,
        postalCity,
        postalCode,
        mobile,
        links,
        spam,
        information,
        education,
        occupationalAreas,
        occupationalGroups,
        invalid,
        cv,
        docs,
        errorMessage,
        regions,
        selectedGroups,
        selectedRegions,
        appRegions,
        appAreas,
        password1,
        password2,
        approved,
        saving,
      } = this.state

      const incomplete = !approved

      const { educations } = this.props.education

      content = [
        <Section key="section-contact-details">
          <h3>Kontaktuppgifter</h3>
          <GridRow>
            <FlexCol>
              <Label>E-post *</Label>
              <Input type="email" placeholder="namn@exempel.com" $invalid={invalid && invalid.email} value={email} onBlur={this.checkEmail} onChange={this.setEmail} />
              {invalid && invalid && invalid.email && <Error>Ange en fullständig e-postadress</Error>}
              <Label>Förnamn *</Label>
              <Input
                type="text"
                autoFocus={!firstName}
                placeholder="Förnamn"
                $invalid={invalid && invalid.firstName}
                value={firstName}
                onBlur={invalid && this.validate}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
              {invalid && invalid && invalid.firstName && <Error>Ange förnamn</Error>}
              <Label>Efternamn *</Label>
              <Input
                type="text"
                placeholder="Efternamn"
                $invalid={invalid && invalid.lastName}
                value={lastName}
                onBlur={invalid && this.validate}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
              {invalid && invalid && invalid.lastName && <Error>Ange efternamn</Error>}
              <Label>Mobil *</Label>
              <Input
                type="text"
                placeholder="07x-xxxxxxx"
                $invalid={invalid && invalid.mobile}
                value={mobile}
                onBlur={invalid && this.validate}
                onChange={(e) => this.setState({ mobile: e.target.value })}
              />
              {invalid && invalid && invalid.mobile && <Error>Ange mobilnummer</Error>}
              <Label>Bostadsort *</Label>
              <Input
                type="text"
                placeholder="Bostadsort"
                $invalid={invalid && invalid.postalCity}
                value={postalCity}
                onBlur={invalid && this.validate}
                onChange={(e) => this.setState({ postalCity: e.target.value })}
              />
              {invalid && invalid && invalid.postalCity && <Error>Ange bostadsort</Error>}
              <Label>Postnummer *</Label>
              <Input
                type="text"
                className="mb0"
                placeholder="Postnummer"
                $invalid={invalid && invalid.postalCode}
                value={postalCode}
                onBlur={invalid && this.validate}
                onChange={(e) => this.setState({ postalCode: e.target.value })}
              />
              {invalid && invalid && invalid.postalCode && <Error className="mt2">Ange postnummer</Error>}
            </FlexCol>
            <FlexCol>
              <Label>Foto</Label>
              <SlimEditor>
                <Slim
                  ratio="1:1"
                  label="Dra din bild hit eller klicka här"
                  labelLoading="Laddar bild..."
                  instantEdit={true}
                  buttonEditTitle="Redigera"
                  buttonRemoveTitle="Ta bort"
                  buttonRotateTitle="Rotera"
                  buttonCancelLabel="Avbryt"
                  buttonCancelTitle="Avbryt"
                  buttonConfirmLabel="OK"
                  buttonConfirmTitle="OK"
                  didInit={this.onSlimInit}
                  didCancel={this.onPhotoCancel}
                  didConfirm={this.onPhotoConfirm}
                  didRemove={this.onPhotoRemoved}
                >
                  <input type="file" name="pictureFile" />
                </Slim>
              </SlimEditor>
            </FlexCol>
          </GridRow>
        </Section>,
        <Section key="section-education">
          <h3>Vad är din högsta utbildning?</h3>
          <ObjectPicker form={"true"} values={educations} placeholder="Välj utbildning..." current={education} onSelected={this.onEducationChange} noMatch="Inga träffar" />
          {invalid && invalid.education && <Error>Ange din högsta utbildning</Error>}
        </Section>,
        <Section key="section-cv">
          <h3>CV</h3>
          <p>Här kan du ladda upp ditt CV.</p>
          <FilePicker
            $black
            className="mb1 mr4"
            fileId={"candidate-cv"}
            text={"Ladda upp CV…"}
            accept={[FileType.DOC, FileType.DOCX, FileType.XLS, FileType.XLSX, FileType.ODT, FileType.ODS, FileType.PDF, FileType.TXT, FileType.RTF]}
            onFile={this.onCvSelected}
          />
          {cv && (
            <ul>
              <li>
                <a className="doc-link" href={cv.url}>
                  {cv.name}
                </a>
                [
                <a className="delete-link" onClick={this.deleteCV}>
                  Ta bort
                </a>
                ]
              </li>
            </ul>
          )}
        </Section>,
        <Section key="section-occupation">
          <h3>Vilka yrken är du intresserad av? *</h3>
          {appAreas && appAreas.length > 0 && (
            <InfoBox>
              <h4>Branscher från tidigare ansökningar:</h4>
              <div>{map((r) => r.name)(appAreas).join(", ")}</div>
            </InfoBox>
          )}
          <OccupationalGroupPicker
            black
            occupationalAreas={occupationalAreas}
            occupationalGroups={occupationalGroups}
            selected={selectedGroups}
            onChange={(selectedGroups) => this.setState({ selectedGroups }, this.validate)}
          />
          {invalid && invalid.occupationalGroup && <Error>Ange minst ett yrke</Error>}
        </Section>,
        <Section key="section-regions">
          <h3>Vilka regioner är du intresserad av att jobba i?</h3>
          {appRegions && appRegions.length > 0 && (
            <InfoBox>
              <h4>Regioner från tidigare ansökningar:</h4>
              <div>{map((r) => r.name)(appRegions).join(", ")}</div>
            </InfoBox>
          )}
          {regions && regions.length > 0 && (
            <RegionList>
              {map((region) => (
                <li key={region.id}>
                  <label>
                    <Checkbox value={region} onChange={(e) => this.onRegionChange(e, region)} checked={!!find((r) => r.id === region.id)(selectedRegions)} />
                    {region.name}
                  </label>
                </li>
              ))(regions)}
            </RegionList>
          )}
        </Section>,
        <Section key="section-links">
          <h3>Länkar</h3>
          <p>Lägg till länkar till relevanta sidor som LinkedIn etc.</p>
          <TextArea placeholder="Länkar" width="100%" rows="3" value={links} onChange={(e) => this.setState({ links: e.target.value })} />
        </Section>,
        <Section key="section-attachments">
          <h3>Bilagor</h3>
          <p>Här kan du ladda upp personligt brev, intyg och liknande.</p>
          <FilePicker black className="mb1" fileId={"candidate-files"} text={"Ladda upp bilagor…"} accept={AllFileTypes} onMultiple={this.onDocsSelected} />
          {docs && !!docs.length && (
            <ul>
              {map((doc) => (
                <li key={doc.id}>
                  <a className="doc-link" href={doc.url}>
                    {doc.name}
                  </a>
                  [
                  <a className="delete-link" data-doc={doc.id} onClick={this.deleteFile}>
                    Ta bort
                  </a>
                  ]
                </li>
              ))(docs)}
            </ul>
          )}
        </Section>,
        <Section key="section-other">
          <h3>Övriga kunskaper</h3>
          <p>Här kan du lägga in certifieringar, språkkunskaper, körkort etc.</p>
          <TextArea placeholder="Skriv eller klistra in text..." width="100%" rows="4" value={information} onChange={(e) => this.setState({ information: e.target.value })} />
        </Section>,
        <Section key="section-password">
          <h3>Välj lösenord *</h3>
          <Ingress>
            Lösenordet måste var minst 8 tecken långt samt uppfylla minst 3 av följande:
            <br />
            <span title="ABC...">stor bokstav</span>, <span title="abc...">liten bokstav</span>, <span title="123...">siffra</span>, <span title='!"#?...'>specialtecken</span>.
          </Ingress>
          <Input
            type="password"
            required
            value={password1}
            autoComplete="new-password"
            onChange={(e) => this.setState({ password1: e.target.value })}
            onBlur={invalid && this.validate}
            $invalid={invalid && invalid.password}
            placeholder="Ange lösenord"
          />
          <Input
            type="password"
            required
            value={password2}
            autoComplete="new-password"
            onChange={(e) => this.setState({ password2: e.target.value })}
            onBlur={invalid && this.validate}
            $invalid={invalid && invalid.password}
            placeholder="Bekräfta lösenord"
          />
          {invalid && invalid.password && <Error>Lösenordet uppfyller inte kraven.</Error>}
        </Section>,
        <Section key="section-register">
          <Ingress>Innan du kan bli medlem behöver du godkänna villkoren nedan.</Ingress>
          <p>
            <a href="https://api.jobbet.se/filer/cltclwcln007dtkvmhf6g824o/PolicyJobbet.se.pdf" target="_blank" rel="noopener noreferrer">
              Personuppgiftspolicy
            </a>
          </p>
          <p>
            <Checkbox checked={approved} onChange={(e) => this.setState({ approved: !approved })} label="Jag godkänner villkoren i policyn ovan." />
          </p>
          <p>
            <Checkbox defaultChecked={true} onChange={(e) => this.setState({ spam: !spam })} label="Ja tack, skicka mig jobbtips!" />
          </p>
          <div>
            <SaveButton className="mt0" disabled={saving || incomplete} onClick={this.registerCandidate} loading={saving}>
              Registrera mig
            </SaveButton>
          </div>
          {invalid && <Error>Något saknas. Kontrollera formuläret ovan.</Error>}
          {errorMessage && <Error>{errorMessage}</Error>}
        </Section>,
      ]
    }
    return (
      <MemberLayout nomenu>
        <Section>
          <H1>Bli medlem</H1>
        </Section>
        {content}
      </MemberLayout>
    )
  }
}

import React from "react"
import { Navigate } from "react-router-dom"
import inject from "_services/inject"
import { ROOT, MEDLEM, LOGIN } from "_root/routes/url-names"
import { Spinner } from "_root/layout/form-elements"

const MemberRoute = props => {

  const { user, children } = props

  if (user.loading) return <Spinner />

  if (user.loggedIn) {

    if (!user.isCandidate) {
      return <Navigate to={ROOT} state={{ referrer: MEDLEM }} />
    }

    // TODO: Use location from withRouter?
    if (location?.state?.referrer) {
      return <Navigate to={location.state.referrer} />
    }

    return React.Children.only(children)
  }
  else {
    return <Navigate to={MEDLEM + LOGIN} />
  }
}

export default inject("user")(MemberRoute)

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import to from '_services/await.to'
import { PrimaryButton } from '_layout/buttons'
import { gql } from '@apollo/client'

//#region Styles
//#endregion

const IMPORT_MUTATION = gql(`
mutation Import {
    userImport: consoleImport(data: "User")
}
`)

@compose(
    graphql(IMPORT_MUTATION, { name: 'import' })
)
export default class Console extends React.Component {
    state = { 
        error: null,
        errorInfo: null
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error, errorInfo})
    }

    import = async () => {
        const [error, result] = await to(this.props.import());
        if (error || !result) {
            this.setState({ error });
        }
    }

    render() {
        const { error, errorInfo } = this.state
        let content
        if (error) {
            content = (
                <div>
                    <h3>{error.message}</h3>
                    <p>{error.toString()}</p>
                    <pre>{errorInfo && errorInfo.componentStack}</pre>
                </div>
            )
        } else {
            content = (
                <div>
                    <PrimaryButton onClick={this.import}>Import anonymous data</PrimaryButton>
                </div>
            )    
        }
        return (
            <div style={{margin: "40px"}}>
                {content}
            </div>
        )
    }
}

import React from "react"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import { format, parseISO, differenceInCalendarDays } from "date-fns"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { map, isEqual } from "lodash/fp"
import TrashIcon from "_images/trash.svg"
import { GET_CANDIDATE_APPLICATIONS, DELETE_APPLICATION } from "_root/containers/member/members-ql"
import { ANSOK } from "_routes/url-names"
import inject from "_services/inject"
import { RecruitmentState } from "_root/constants"
import confirm from "_root/components/confirm"
import to from "_root/services/await.to"
import MemberLayout from "./member-layout"

//#region Styles
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
const Trash = styled.a`
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 10px;
`
const Rows = styled.div`
  flex: 1;
  margin: 0;

  .row {
    & + .row {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid var(--color-line);
    }

    .titleCell {
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 0;

      ${Trash} {
        margin-left: 10px;
      }
    }

    .infoArea {
      display: flex;

      .infoCell {
        cursor: ${(props) => (props.$pointer ? "pointer" : "default")};
        display: inline-block;
        position: relative;
        font-size: 0.9em;
        background: var(--color-bg-white);
        white-space: nowrap;

        &.date {
          margin-left: auto;
        }
      }
    }

    .actions {
      position: absolute;
      top: 15px;
      right: 7px;
      bottom: 50%;
      width: 25px;
      height: 25px;

      .actionSymbol {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px 5px 0;
        cursor: pointer;

        > img {
          max-width: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: var(--color-bg-grey);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
        }

        .message svg {
          stroke: #027874;
          fill: #027874;
        }
      }
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px 5px 10px;
  color: var(--color-text-dark);
  background: var(--color-bg-lighter);
  border-bottom: 1px solid var(--color-line-light);

  .header & {
    border: none;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background-color: var(--color-bg-light);
      }
    }
  }
`
const InfoArea = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  cursor: ${(props) => (props.$pointer ? "pointer" : "default")};
  position: relative;
  flex: ${(props) => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.$left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);
  white-space: nowrap;

  ${Row}.header & {
    border: none;
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0 10px;

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background: var(--color-bg-light);
      }
    }
  }

  .content & {
    padding: 5px 10px;
    ${(props) => (props.$toggle ? "padding-right: 50px;" : null)};
  }

  &.date {
    max-width: 90px;
  }
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(GET_CANDIDATE_APPLICATIONS, {
    skip: (props) => !props.user.candidate,
    name: "candidateQuery",
    options: (props) => ({ variables: { candidateId: props.user.candidate.id }, fetchPolicy: "network-only" }),
  }),
  graphql(DELETE_APPLICATION, { name: "deleteApplication" })
)
export default class MemberApplications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "Hämtar ansökningar...",
      error: null,
      applications: [],
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { findManyApplication, error } = nextProps.candidateQuery
    if (error) {
      console.error("member-applications:getDerivedStateFromProps:error:", error)
      return { error: "Något gick fel, ansökningar kunde inte hämtas.", status: null }
    } else if (!findManyApplication || !findManyApplication.length) {
      return { error: null, status: "Ansökningar saknas", applications: [] }
    } else if (!isEqual(findManyApplication)(prevState.applications)) {
      return { applications: findManyApplication, error: null, status: null }
    }
    return null
  }

  applicationUrl = (id) => {
    return `${ANSOK}/${id}`
  }

  applyable = (recruitment) => {
    const remainingDays = differenceInCalendarDays(parseISO(recruitment.applyStop), new Date())
    return recruitment.state === RecruitmentState.ACTIVE && remainingDays > -1
  }

  deleteApplication = async (id) => {
    const confirmed = await confirm("Är du säker på att du vill radera din ansökan?")
    if (confirmed) {
      const [error, result] = await to(this.props.deleteApplication({ variables: { id } }))
      if (error || !result) {
        console.error("member-applications:deleteApplication:error:", error)
      } else {
        this.props.candidateQuery.refetch()
      }
    }
  }

  date = (date) => {
    if (!date || typeof date !== "string" || date.length < 10) {
      return " "
    }
    return format(parseISO(date), "yy-MM-dd")
  }

  render() {
    const { status, applications, error } = this.state

    return (
      <MemberLayout>
        <Section>
          <H1>Tidigare ansökningar</H1>
        </Section>
        {(status || error) && (
          <Section>
            <Ingress>{status || error}</Ingress>
          </Section>
        )}
        {!error && applications && applications.length > 0 && (
          <Section>
            <Rows>
              {map((app) => (
                <div className="row" key={app.id}>
                  <div className="titleCell">
                    <a className="link" href={this.applicationUrl(app.recruitment.id)}>
                      {app.recruitment.title}
                    </a>
                    {this.applyable(app.recruitment) && app.grade === 0 && <Trash onClick={(e) => this.deleteApplication(app.id)} />}
                  </div>
                  <div className="infoArea">
                    <div className="infoCell">{app.recruitment.company.name}</div>
                    <div className="infoCell date">{this.date(app.createdAt)}</div>
                  </div>
                </div>
              ))(applications)}
            </Rows>
          </Section>
        )}
      </MemberLayout>
    )
  }
}

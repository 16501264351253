import React from 'react'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import Slim from '_root/slim/slim.react'
import styled from 'styled-components'
import {
    isEmpty,
    filter,
    map,
    flatten,
    uniqBy
} from 'lodash/fp'
import confirm from '_components/confirm'
import { Spinner, Radio } from '_layout/form-elements'
import MainLayout from '_layout/main-layout'
import UploadService from '_services/upload-service'
import { H2, FlexRow, FlexCol, Input } from '_layout/form-elements'
import { PrimaryButton, DeleteButton } from '_layout/buttons'
import to from '_services/await.to'
import {
    COMPANY_QUERY,
    GROUPS_QUERY,
    COPY_FILE,
    GALLERY_UPDATE_MUTATION,
    GALLERY_UPDATE_DESCRIPTION,
    GALLERY_DELETE_IMAGE
} from '_containers/recruitment/recruitment-ql'
import { isEqual } from 'lodash/fp'
import inject from '_services/inject'
import { ADMIN_COMPANY } from '_root/constants'

//#region Styles
const RatioList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin-right: 10px;
    }
`
const SlimEditor = styled.div`
    display: block;
    min-width: 500px;
    margin-right: 40px;
    margin-bottom: 20px;

    .slim {
        cursor: pointer;
        border: 2px dotted var(--color-bg-grey-dark);
        background: var(--color-bg-white);
    }
`
const ImageList = styled.ul `
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: auto;

    li {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 10px 0;
        padding: 10px;
        width: 120px;
        height: 120px;
        border: 1px solid var(--color-bg-grey);
        background: var(--color-bg-white);
        
        &:hover {
            background: var(--color-bg-lightest);
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
`
//#endregion

@compose(
    withRouter,
    inject('user'),
    graphql(COMPANY_QUERY, { name: 'companyQuery', options: props => ({ variables: { urlName: props.match.params.company } }) }),
    graphql(COMPANY_QUERY, { name: 'groupQuery', options: props => ({ variables: { urlName: props.user.employer.urlName } }), skip: props => !props.user.groupAdmin || props.match.params.company === props.user.employer.urlName }),
    graphql(GROUPS_QUERY, { name: 'groupsQuery', options: props => ({ variables: { urlNames: props.user.companyView.groups.map(g => g.urlName) } }), skip: props => !props.user.superAdmin || props.match.params.company === ADMIN_COMPANY }),
    graphql(COPY_FILE, { name: 'copyFile' }),
    graphql(GALLERY_UPDATE_MUTATION, { name: 'updateCompany' }),
    graphql(GALLERY_UPDATE_DESCRIPTION, { name: 'updateDescription' }),
    graphql(GALLERY_DELETE_IMAGE, { name: 'deleteImage' })
)
export default class RecruitmentImages extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ratio: 'input',
            uploaded: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { company, loading } = nextProps.companyQuery
      if (!company || loading) return null
      const nextState = {}
      if (prevState.companyId !== company.id) nextState.companyId = company.id
      const galleryIds = map(image => image.id)(company.gallery)
      if (!isEqual(galleryIds)(prevState.galleryIds)) nextState.galleryIds = galleryIds
      const uploaded = uniqBy('id')(company.gallery)
      if (!isEqual(uploaded)(prevState.uploaded)) nextState.uploaded = uploaded
      if (!prevState.groupGallery && nextProps.groupQuery && !nextProps.groupQuery.loading) {
        const { company: group } = nextProps.groupQuery
        nextState.groupGallery = group.gallery
      }
      if (!prevState.groupGallery && nextProps.groupsQuery && !nextProps.groupsQuery.loading) {
        const { companies: groups } = nextProps.groupsQuery
        nextState.groupGallery = flatten(groups.map(g => g.gallery))
      }
      return !isEmpty(nextState) ? nextState : null
    }

    refetch = () => {
      this.props.companyQuery && this.props.companyQuery.refetch()
      this.props.groupQuery && this.props.groupQuery.refetch()
    }

    onSlimInit = (data, slim) => {
        this.slim = slim
        const { picture } = this.state
        if (picture && picture.url) {
            slim._options.instantEdit = false
            slim._options.crossOrigin = true
            slim.load(picture.url+'?'+Math.random(), (error, data) => { slim._options.instantEdit = true }, slim._options)
        }
    }

    onPhotoCancel = slim => {
        const { picture } = this.state
        if (!picture || !picture.url || !this.slim) return
        this.cancel = true
        this.slim._options.instantEdit = false
        this.slim.load(picture.url, (error, data) => { this.slim._options.instantEdit = true })
    }

    onPhotoConfirm = (data, slim) => {
        const picture = this.state.picture || {}
        this.setState({picture: {...picture, edited: slim.dataBase64.output}})
    }

    onPhotoRemoved = (data, slim) => {
        if (this.cancel) {
            this.cancel = false // Hack due to call from cancel event in Slim component
            return
        }
        this.setState({picture: null})
    }

    onPhotoDescriptionChange = e => {
        this.setState({ picture: {...this.state.picture, description: e.target.value, changed: true }})
    }

    onPhotoEdit = picture => {
        if (this.slim && picture) {
            this.cancel = true
            this.slim._options.instantEdit = false
            this.slim.load(picture.url+'?'+Math.random(), (error, data) => { this.slim._options.instantEdit = true }, { crossOrigin: true })
        }
        this.setState({ picture })
    }

    onPhotoCopy = async picture => {
      const confirmed = await confirm(`Är du säker på att du vill kopiera bilden från gruppen till företaget?`)
      if (confirmed) {
        const { galleryIds = [] } = this.state
        const { id, name, contentType, size } = picture
        const [errorCopy, resultCopy] = await to(this.props.copyFile({ variables: { id, name, contentType, size } }))
        if (!errorCopy && resultCopy) {
          galleryIds.push(resultCopy.data.copyFile.id)
          const [errorConnect, { data: { updateCompany } }] = await to(this.props.updateCompany({
            variables: { id: this.state.companyId, galleryIds: galleryIds.map(id => ({ id: id })) }
          }))
          if (errorConnect) {
            console.error('recruitment-images:onPhotoCopy:updateCompany:error', errorConnect, updateCompany)
          }
          else {
            this.refetch()
          }
        }
      }
    }

    onSave = async e => {
        const { picture } = this.state
        if (!picture) return
        if (!picture.edited) {
            if (typeof(picture.description) === 'string' && picture.id) {
                const [errorDescription, resultDescription] = await to(this.props.updateDescription({
                    variables: { id: picture.id, description: picture.description }
                }))
                if (errorDescription || !resultDescription) {
                    console.error('recruitment-images:onSave:updateDescription:error', errorDescription, resultDescription)
                }
                else {
                  this.refetch()
                  this.setState({ picture: { ...picture, changed: false } })
                }
            }
            return
        }
        const [error, result] = await to(UploadService.uploadBase64(picture.edited.image, picture.name || picture.edited.name))
        if (error || !result || !result.data || !result.data.id) {
            console.error('recruitment-images:onSaver:uploadBase64:error', error || result)
            return
        }
        let galleryIds = [...this.state.galleryIds]
        if (picture.id) {
            const [errorDelete, resultDelete] = await to(this.props.deleteImage({
                variables: { id: picture.id }
            }))
            if (errorDelete || !resultDelete) {
                console.error('recruitment-images:onSave:deleteImage:error', errorDelete, resultDelete)
                return
            }
            galleryIds = filter(id => id !== picture.id)(galleryIds)
        }
        if (picture.description) {
            const [errorDescription, resultDescription] = await to(this.props.updateDescription({
                variables: { id: result.data.id, description: picture.description }
            }))
            if (errorDescription || !resultDescription) {
                console.error('recruitment-images:onSave:updateDescription:error', errorDescription, resultDescription)
            }
        }
        galleryIds.push(result.data.id)
        const [errorConnect, { data: { updateCompany } }] = await to(this.props.updateCompany({
            variables: { id: this.state.companyId, galleryIds: galleryIds.map(id => ({ id: id }))}
        }))
        if (errorConnect || !updateCompany) {
            console.error('recruitment-images:onSave:updateCompany:error', errorConnect, updateCompany)
            return
        }
        this.slim && this.slim.remove()
        this.setState({
            galleryIds: map(image => { return image.id })(updateCompany.gallery),
            picture: null,
            uploaded: uniqBy('id')([...updateCompany.gallery])
        }, this.refetch)
    }

    onDelete = async e => {
        const confirmed = await confirm(`Vill du ta bort denna bild permanent?`)
        if (!confirmed) return
        const { picture } = this.state
        if (!picture || !picture.id) return
        const [errorDelete, resultDelete] = await to(this.props.deleteImage({
            variables: { id: picture.id }
        }))
        if (errorDelete || !resultDelete) {
            console.error('recruitment-images:onDelete:deleteImage:error', errorDelete, resultDelete)
            return
        }
        this.slim && this.slim.remove()
        this.setState({picture: null}, this.refetch)
}

    setRatio = ratio => {
        this.setState({ratio}, () => this.slim && this.slim.setRatio(ratio))
    }

    render() {
      const { groupGallery = [] } = this.state
      let content = null
      if (!this.state.companyId) {
        content = <FlexCol><Spinner /></FlexCol>
      }
      else {
        const { ratio, picture, uploaded } = this.state

        const images = map(image => {
          return <li key={image.id} onClick={e => this.onPhotoEdit(image)} title={image.description || image.name}>
            <img src={image.url} alt={image.description || image.name} />
          </li>
        })(uploaded)

        const groupImages = map(image => {
          return <li key={image.id} onClick={e => this.onPhotoCopy(image)} title={image.description || image.name}>
            <img src={image.url} alt={image.description || image.name} />
          </li>
        })(groupGallery)

        content = 
            <FlexCol className='mb4'>
                <h3>Ange önskat bildförhållande (ratio):</h3>
                <RatioList>
                    <li><label><Radio name='imageRatio' value='input' onChange={e => this.setRatio('input')} checked={ratio === 'input'} />Original</label></li>
                    <li><label><Radio name='imageRatio' value='free' onChange={e => this.setRatio('free')} checked={ratio === 'free'} />Valfritt</label></li>
                    <li><label><Radio name='imageRatio' value='1:1' onChange={e => this.setRatio('1:1')} checked={ratio === '1:1'} />1:1 (kvadrat)</label></li>
                    <li><label><Radio name='imageRatio' value='3:2' onChange={e => this.setRatio('3:2')} checked={ratio === '3:2'} />3:2</label></li>
                    <li><label><Radio name='imageRatio' value='4:1' onChange={e => this.setRatio('4:1')} checked={ratio === '4:1'} />4:1 (liggande annonsbild)</label></li>
                    <li><label><Radio name='imageRatio' value='4:3' onChange={e => this.setRatio('4:3')} checked={ratio === '4:3'} />4:3</label></li>
                    <li><label><Radio name='imageRatio' value='16:9' onChange={e => this.setRatio('16:9')} checked={ratio === '16:9'} />16:9</label></li>
                </RatioList>
                <div className='mv4'>
                    <SlimEditor>
                        <Slim
                            ratio={ratio}
                            label='Dra din bild hit eller klicka här för att ladda upp en ny bild'
                            labelLoading='Laddar bild...'
                            instantEdit={true}
                            buttonEditTitle='Redigera'
                            buttonRemoveTitle='Töm bildredigeraren'
                            buttonRotateTitle='Rotera'
                            buttonCancelLabel='Avbryt'
                            buttonCancelTitle='Avbryt'
                            buttonConfirmLabel='OK'
                            buttonConfirmTitle='OK'
                            download='true'
                            didInit={this.onSlimInit}
                            didCancel={this.onPhotoCancel}
                            didConfirm={this.onPhotoConfirm}
                            didRemove={this.onPhotoRemoved}>
                            <input type='file' name='pictureFile'/>
                        </Slim>
                    </SlimEditor>
                    {picture &&
                    <div>
                        <Input value={picture.description || ''} placeholder="Beskrivning av bild" onChange={this.onPhotoDescriptionChange} />
                    </div>
                    }
                    <PrimaryButton className='mr3' disabled={!picture || (!picture.edited && !picture.changed)} onClick={this.onSave}>Spara</PrimaryButton>
                    <DeleteButton disabled={!picture} onClick={this.onDelete}>Radera</DeleteButton>
                </div>
                {this.state.uploaded.length > 0 &&
                <div>
                    <h2 className='mt2'>Uppladdade bilder</h2>
                    <p>Klicka på en bild för att redigera eller ta bort.</p>
                    <ImageList>
                        {images}
                    </ImageList>
                </div>}
                {groupImages.length > 0 &&
                <div>
                    <h2 className='mt4'>Bilder från grupp</h2>
                    <p>Klicka på en bild för att kopiera den till företaget.</p>
                    <ImageList>
                        {groupImages}
                    </ImageList>
                </div>}
            </FlexCol>
      }

      return (
        <MainLayout padding maxHeight scroll>
          <div>
            <H2>Bildbank</H2>
            <FlexRow className='margin20'>
              {content}
            </FlexRow>
          </div>
        </MainLayout>
      )
    }
}

import React from 'react'
import { Navigate } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { gql } from '@apollo/client'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { format } from "date-fns"
import { REKRYTERING, REK_AKTIVA, MEDLEM, AKTIVERA } from '_routes/url-names'
import { UPDATE_POLICY } from '_root/common-ql'
import MainLayout from '_layout/main-layout'
import { PrimaryButton } from '_layout/buttons'
import { H2, FlexRow, FlexCol, Checkbox, Spinner } from '_layout/form-elements'
import Policy from '_components/policy'
import LoginService from '_services/login-service'
import inject from '_services/inject'
import to from '_services/await.to'

const Ingress = styled.p`
    margin-bottom: 30px;
`

@compose(
    withRouter,
    withApollo,
    inject('user'),
    graphql(UPDATE_POLICY, { name: 'updatePolicy' }),
)
export default class AfterLogin extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: props.user.loading,
        policyOpened: false,
        accepted: false,
        nextUrl: props.nextUrl
      }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const { user, match, client } = nextProps
        if (user.loading) return null
        let nextUrl = nextProps.nextUrl
        if (user.active === false) {
            nextUrl = AKTIVERA + '/' + user.id
        }
        else if (!nextUrl) {
            if (user.isCandidate) {
              nextUrl = MEDLEM
            }
            else {
              const companyViewUrl = match.params.company || (user.companyView && user.companyView.urlName) || (user.employer && user.employer.urlName)
              if (!companyViewUrl) return null
              nextUrl = '/' + companyViewUrl + REKRYTERING + REK_AKTIVA
              // TODO: change to makeVar?
              client.writeQuery
              ({
                query: gql`
                  query RecruitmentStats {
                    recruitmentStats {
                      countRecruitmentsIsStale
                      activeRecruitmentsIsStale
                      draftRecruitmentsIsStale
                      closedRecruitmentsIsStale
                    }
                  }`,
                data: {
                  recruitmentStats: {
                    __typename: 'RecruitmentStats',
                    countRecruitmentsIsStale: true
                  }
                }
              })
            }
        }
        return { nextUrl }
    }

    onPolicyOpen = () => {
        this.setState({policyOpened: true})
    }

    onAccept = e => {
        this.setState({accepted: e.target.checked})
    }

    onProceed = async e => {
        const { accepted } = this.state
        if (accepted) {
            const { user, client } = this.props
            const policyAccepted = `${user.firstName} ${user.lastName} accepterade villkoren ${format(new Date(), 'yy-MM-dd')}`
            const [errorPolicy] = await to(this.props.updatePolicy({ variables: { id: user.employer.id, policyAccepted: policyAccepted } }))
            if (errorPolicy) {
                console.error('after-login:onProceed:errorPolicy: ', errorPolicy)
                return
            }
            LoginService.reloadUser(client)
        }
    }

    render() {
        if (this.state.nextUrl) {
          return <Navigate to={{ pathname: this.state.nextUrl }} />
        }
        const { user } = this.props
        if (user && user.policyCompany && !user.policyCompany.policyAccepted) {
            const { policyOpened, accepted } = this.state
            return <MainLayout padding nomenu>
                <H2 className='margin20 mv3'>Godkänn företagsvillkor</H2>
                <FlexRow className='margin20'>
                    <FlexCol>
                        <Ingress>Läs igenom villkoren nedan, acceptera dem genom att kryssa i rutan under och klicka sedan på knappen för att gå vidare.</Ingress>
                        <Policy className='mb4' onOpen={this.onPolicyOpen}>Läs villkor</Policy>
                        <Checkbox className='mb4' onChange={this.onAccept} disabled={!policyOpened} label='Jag har läst och godkänner villkoren ovan.' />
                        <PrimaryButton onClick={this.onProceed} disabled={!accepted}>Gå vidare</PrimaryButton>
                    </FlexCol>
                </FlexRow>
            </MainLayout>
        }
        return <MainLayout padding nomenu>
            <Spinner/>
        </MainLayout>
    }
}

export default (filter, state) => {

  if (!filter || !filter.where) return { state: { equals: state } }

  const where = [{ state: { equals: state } }]

  const {
    addons, applyStartAfter, applyStartBefore, applyStopAfter, applyStopBefore, company,
    process, recruiter, recruiterGuests, recruiterExternals, occupationalAreas, regions, title
  } = filter.where

  if (addons) where.push({ addons: addons })
  if (applyStartAfter) where.push({ applyStart: applyStartAfter })
  if (applyStartBefore) where.push({ applyStart: applyStartBefore })
  if (applyStopAfter) where.push({ applyStop: applyStopAfter })
  if (applyStopBefore) where.push({ applyStop: applyStopBefore })
  if (company?.name) where.push({ company: { name: company.name } })
  if (company?.groups) where.push({ company: { groups: company.groups } })
  if (company?.OR) where.push({ company: { OR: company.OR } })
  if (company?.jobbetContacts) where.push({ company: { jobbetContacts: company.jobbetContacts } })
  if (recruiter) where.push({ recruiter: recruiter })
  if (recruiterGuests) where.push({ recruiterGuests: recruiterGuests })
  if (recruiterExternals) where.push({ recruiterExternals: recruiterExternals })
  if (regions) where.push({ regions: regions })
  if (occupationalAreas) where.push({ occupationalAreas: occupationalAreas })
  if (process) where.push({ process: process })
  if (title) where.push({ title: title })

  return { AND: where }
}

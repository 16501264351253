import React from 'react';
import Query from '_components/apollo-query'

export default function withApolloQuery(operationOptions, queryProvider) {
    return (WrappedComponent) => {
        return class extends React.PureComponent {
            constructor(props) {
                super(props)
                this.displayName = "ApolloQuery"
                this.state = {
                    queryContext: null
                }
            }

            static getDerivedStateFromProps(nextProps, prevState) {
                if (!nextProps.user || !nextProps.user.role) return null
                return {
                    queryContext: queryProvider.getQuery(operationOptions, {
                        userContext: nextProps.user,
                        routerContext: nextProps.match.params,
                        props: nextProps
                    })
                }
            }

            queryRender(queryData) {
                const apolloQueryParam = {
                    [operationOptions.name || 'apollodata']: queryData
                }
                return <WrappedComponent {...apolloQueryParam} {...this.props} />
            }

            render() {
                if ( !this.state.queryContext ) {
                    return null
                }
                const { query, options: queryOptions } = this.state.queryContext
                return (
                    <Query
                        name={operationOptions.name}
                        query={query}
                        fetchPolicy='network-only'
                        options={Object.assign({}, queryOptions)}
                        loading={ this.queryRender.bind(this) }
                        render={ this.queryRender.bind(this) }
                        {...this.props}
                    />
                )
            }
        }
    }
}
import React, { PureComponent } from 'react'
import withRouter from '_hoc/withRouter'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { PrimaryButton, CancelButton, CheckboxButton } from '_layout/buttons'
import { TextArea } from '_layout/form-elements'
import CapLogoSrc from '_images/cap-logo.png'
import CapImageSrc from '_images/cap-dense.png'
import CompleteImageSrc from '_images/komplettrekrytering.jpg'
import AdminImageSrc from '_images/rekryteringsadministration.jpg'
import Modal from '_components/modal'
import message from '_components/message'
import { AddonType } from '_root/constants'
import { ALL_ADDONS } from '_containers/recruitment/recruitment-ql'
import { getBookedServiceMailContent } from '_modules/recruitments/services/mailtemplate-service'
import withSendmailMutation from '_hoc/withSendmailMutation'
import inject from '_services/inject'
import to from '_services/await.to'

//#region Styles
const Deals = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 220px;
  max-width: 960px;
`
const Deal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 60px;
  border-bottom: 2px solid var(--color-line-dark);
  background-color: var(--color-bg-white);
  grid-column: ${props => props.$double ? '1 / -1' : 'initial'};

  > h3 {
    font-size: 18px;
    margin-top: 0;
  }

  > p {
    flex: 1 0 auto;
    margin-top: 0;
  }

  strong {
    margin-right: 10px;
  }

  > img:last-child {
    margin-top: 20px;
  }
`
const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 60px;
  margin-bottom: 20px;

  div {
    flex: 1;

    p {
      margin: 0;
    }

    img:first-child {
      width: 140px;
      margin-bottom: 20px;
    }

    img:last-child {
      width: 360px;
    }
  }
`
const ModalHeader = styled.header`
  font-size: 1.5rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const Nowrap = styled.div`
  text-align: center;
  white-space: nowrap;
`
const Checkout = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  bottom: 30px;
  right: 40px;
  width: 220px;
  min-height: 200px;
  background: var(--color-bg-white);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
  z-index: 200;
`
const Title = styled.div`
  padding: 5px 0;
  text-align: center;
  color: var(--color-text-white);
  background: var(--color-brand-red);
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  > p {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: 10px 20px;
  }
`
const AddonList = styled.ul`
  flex: 1;
  margin-bottom: 0;
  padding: 0 16px;
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 18px;
    font-size: 14px;

    &:before {
      content: '✓';
      font-size: 16px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.added {
      &:before {
        content: '+';
      }
      color: var(--color-brand-red);
    }

    &.removed {
      &:before {
        content: '–';
      }
      color: var(--color-brand-red);
      text-decoration: line-through;
    }
  }
`
//#endregion Styles

@compose(
  withRouter,
  withApollo,
  inject('user'),
  withSendmailMutation,
  graphql(ALL_ADDONS, { name: 'addonsQuery' })
)
export default class RecruitingDeals extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmOrderModal: false,
      disableOrder: true,
      disableAddons: false,
      previousAddons: props.addons || [],
      selectedAddons: props.addons || [],
      orderMessage: '',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addons && !nextProps.addons.equals(prevState.previousAddons)) {
      const selectedAddons = [...nextProps.addons]
      const previousAddons = selectedAddons
      return { selectedAddons, previousAddons }
    }
    return null
  }

  onOrderAddon = (checked, type) => {
    const { addonsQuery, addons = [] } = this.props
    const selectedAddons = [...this.state.selectedAddons]
    const addon = addonsQuery.addons.find(a => a.type === type)
    if (checked) {
      !selectedAddons.find(a => a.id === addon.id) && selectedAddons.push(addon)
    }
    else {
      const index = selectedAddons.findIndex(a => a.id === addon.id)
      index > -1 && selectedAddons.splice(index, 1)
    }
    const disableOrder = selectedAddons.equals(addons)
    this.setState({ selectedAddons, disableOrder })
  }

  orderCap = e => {
    this.onOrderAddon(e.checkedState, AddonType.CAP)
  }

  orderComplete = e => {
    this.onOrderAddon(e.checkedState, AddonType.COMPLETE)
  }

  orderAdministration = e => {
    this.onOrderAddon(e.checkedState, AddonType.ADMIN)
  }

  orderInterviews = e => {
    this.onOrderAddon(e.checkedState, AddonType.INTERVIEWS)
  }

  orderTests = e => {
    this.onOrderAddon(e.checkedState, AddonType.TESTS)
  }

  orderSearch = e => {
    this.onOrderAddon(e.checkedState, AddonType.SEARCH)
  }

  orderReferences = e => {
    this.onOrderAddon(e.checkedState, AddonType.REFERENCES)
  }

  confirmActivation = () => {
    this.setState({showConfirmOrderModal: true })
  }

  dismissOrderModal = () => {
    this.setState({showConfirmOrderModal: false, orderMessage: '' })
  }

  onOrderMessageChange = e => {
    this.setState({ orderMessage: e.currentTarget.value })
  }

  handleOrder = async () => {
    const { orderMessage, selectedAddons } = this.state
    const order = selectedAddons?.map(a => a.name).join(', ')
    const { sendMail, user, title, onChange } = this.props
    const jobTitle = title || 'Ej skapad'
    const [error] = await to(sendMail({
      from: 'noreply@jobbet.se',
      to: process.env.REACT_APP_AD_SUPPORT,
      subject: `Beställning - rekryteringstjänster`,
      html: getBookedServiceMailContent({sender: user.fullName, company: user.employer.name, job: jobTitle, order, orderMessage}),
      showFooter: false
    }))
    if (!error) {
      await message('Din beställning har skickats!')
      this.setState({
        showConfirmOrderModal: false,
        orderMessage: ''
       })
    }
    else {
      // Kontakta kundtjänst.
    }
    onChange && onChange({ selectedAddons })
  }

  render() {
    const { selectedAddons, disableOrder, disableAddons, orderMessage, showConfirmOrderModal } = this.state
    const { user, addons = [] } = this.props

    const checkedCap = addons.find(a => a.type === AddonType.CAP) || false
    const checkedComplete = addons.find(a => a.type === AddonType.COMPLETE) || false
    const checkedAdministration = addons.find(a => a.type === AddonType.ADMIN) || false
    const checkedInterviews = addons.find(a => a.type === AddonType.INTERVIEWS) || false
    const checkedReferences = addons.find(a => a.type === AddonType.REFERENCES) || false
    const checkedTests = addons.find(a => a.type === AddonType.TESTS) || false
    const checkedSearch = addons.find(a => a.type === AddonType.SEARCH) || false

    return <>
      <Deals className={this.props.className}>
        <Deal $double>
          <Columns>
            <div>
              <h3>Vår mest bokade tjänst!</h3>
              <p>
                De rekryteringar där det tidigare räckt med annonsering/marknadsföring behöver nu en mer aktiv searchprocess för att få kontakt med de bästa kandidaterna. Företag som tidigare köpt dyra kompletta rekryteringar inser att det är just CAP-momentet som är det kritiska i rekryteringsprocessen. Genom att boka CAP och sen slutföra rekryteringen på egen hand säkerställs en lyckad rekrytering till en halverad kostnad.
              </p>
            </div>
            <div>
              <img src={CapLogoSrc} alt='Logotype for candidate aquisition process' title='CAP - Candidate Aquisition Process' />
              <img src={CapImageSrc} alt='Graphics showing candidate aquisition process' title='CAP - Candidate Aquisition Process' />
            </div>
          </Columns>
          <div>
            <CheckboxButton className="fr" checked={!!checkedCap} onClick={this.orderCap}>{checkedCap ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>45 000 - 85 000 kr
          </div>
        </Deal>
        <Deal>
          <h3>Komplett rekrytering</h3>
          <p>
            En rekryteringskonsult hjälper er genom hela rekryteringsprocessen från kravprofil, bedömning av ansökningshandlingar, telefon- / teamsintervjuer, tester, personliga intervjuer, bakgrundskontroll och referenser till anställning.
            <br/><br/>
            Tjänsten innefattar 6 månaders garanti. 
          </p>
          <div>
            <CheckboxButton className="fr" checked={!!checkedComplete} onClick={this.orderComplete}>{checkedComplete ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>Uppstart: 35 000 kr inkl. marknadsföring. Vid anställning: 2 månadslöner
          </div>
          <img src={CompleteImageSrc} alt='Affärskvinna skakar hand' title='Komplett rekrytering' />
        </Deal>
        <Deal>
          <h3>Rekryteringsadministration</h3>
          <p>
            En rekryteringskonsult håller kontakt med sökande, bedömer ansökningshandlingar och genomför telefon- / teamsintervjuer.
            <br/><br/>
            Ni får tillgång till hela rekryteringsprocessen och ni genomför själva personliga intervjuer med era slutkandidater.
          </p>
          <div>
            <CheckboxButton className="fr" checked={!!checkedAdministration} onClick={this.orderAdministration}>{checkedAdministration ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>24 900 kr
          </div>
          <img src={AdminImageSrc} alt='Kvinnor samarbetar framför dator' title='Rekryteringsadministration' />
        </Deal>
        <Deal>
          <h3>Intervjuer inklusive dokumentation</h3>
          <div>
            <CheckboxButton className="fr" checked={!!checkedInterviews} onClick={this.orderInterviews}>{checkedInterviews ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>2 500 kr / kandidat
          </div>
        </Deal>
        <Deal>
          <h3>Referenser</h3>
          <div>
            <CheckboxButton className="fr" checked={!!checkedReferences} onClick={this.orderReferences}>{checkedReferences ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>1500 kr / referens
          </div>
        </Deal>
        <Deal>
          <h3>Tester</h3>
          <p>Välj mellan ett stort antal personlighets-, färdighets- och begåvningstester. </p>
          <div>
            <CheckboxButton className="fr" checked={!!checkedTests} onClick={this.orderTests}>{checkedTests ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>Från 12 900 kr / kandidat
          </div>
        </Deal>
        <Deal>
          <h3>Search</h3>
          <p>Låt en researcher på Jobbet.se kartlägga och ta personlig kontakt med toppkandidater via nätverk, sociala medier och våra personliga kontaktnät.</p>
          <div>
            <CheckboxButton className="fr" checked={!!checkedSearch} onClick={this.orderSearch}>{checkedSearch ? 'Vald tjänst' : 'Välj tjänsten'}</CheckboxButton>
            <strong>Pris:</strong><br/>29 900 kr
          </div>
        </Deal>
      </Deals>
      <Checkout>
        <Title>Valda tjänster</Title>
        <Content>
          {disableAddons &&
          <p>Inga tjänster valda</p>
          }
          {!disableAddons &&
          <AddonList>
            {selectedAddons.map(addon => <li key={addon.id}>{addon.name}</li>)}
          </AddonList>
          }
          <PrimaryButton disabled={disableOrder} onClick={this.confirmActivation}>Skicka beställning</PrimaryButton>
        </Content>
      </Checkout>
      <Modal isOpen={showConfirmOrderModal} onRequestClose={this.dismissOrderModal}>
        <ModalHeader>Beställ våra rekryteringstjänster</ModalHeader>
        <p>
          Våra rekryterare vill och kan hjälpa er i denna rekrytering!
          <br/><br/>
          Ni har valt följande tjänster:
        </p>
        <AddonList>
          {selectedAddons.map(addon => <li key={addon.id}>{addon.name}</li>)}
        </AddonList>
        <TextArea
          width='500px'
          rows={8}
          className='mt3'
          placeholder='Plats för ett frivilligt meddelande.'
          onChange={this.onOrderMessageChange}
          value={orderMessage} />
        <Nowrap>
          <CancelButton className='mr3' onClick={this.dismissOrderModal}>Avbryt</CancelButton>
          <PrimaryButton onClick={this.handleOrder}>Skicka</PrimaryButton>
        </Nowrap>
      </Modal>
    </>
  }
}

import React from "react"
import { Tooltip } from "react-tooltip"
import withRouter from "_hoc/withRouter"
import { bindActionCreators } from "redux"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { connect } from "react-redux"
import { setUser } from "_actions/user-actions"
import { UserRoles } from "_root/constants"
import { Spinner } from "_layout/form-elements"
import to from "_services/await.to"
import inject from "_services/inject"
import LoginService from "_services/login-service"
import { SYSTEM_SETTINGS } from "_root/common-ql"

const USER_QUERY = gql`
  query UserQuery {
    getCurrentUser
  }
`

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(SYSTEM_SETTINGS, { name: "system", options: props => ({ fetchPolicy: "network-only" }) }),
  connect(null, dispatch => bindActionCreators({ setUser }, dispatch))
)
export default class AppRoot extends React.Component {
  state = {
    mobile: window.innerWidth < 768,
    hasLoaded: false,
  }

  componentDidMount = () => {
    if (console && console.info) {
      console.info("REACT_APP_VERSION", process.env.REACT_APP_VERSION)
      console.info("REACT_APP_BUILDNR", process.env.REACT_APP_BUILDNR)
      console.info("REACT_APP_ENV", process.env.REACT_APP_ENV)
    }
    const { user } = this.props
    if (user.loggedIn) {
      this.fetchAndSetUser(this.props)
    } else {
      this.setUserLoadingState(user, false)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { system, user, client } = this.props
    const panic = system.systems && system.systems.length && system.systems[0].panic
    if (panic && user.authenticated && !user.superAdmin) {
      LoginService.logoutUser(client)
    } else if (user.loading === true || user.reload || (user.loggedIn === true && !prevProps.user.loggedIn)) {
      this.fetchAndSetUser(this.props)
    } else if (user.loggedIn === false && prevProps.user.loggedIn === true) {
      LoginService.logoutUser(client)
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.user.loading === false) {
      return { hasLoaded: true }
    }
    return null
  }

  setUserLoadingState = async (user, loading) => {
    const { setUser } = this.props
    if (loading === user.loading) return
    setUser({ ...user, loading })
  }

  fetchAndSetUser = async props => {
    const { client, user } = props
    if (user.loading === false) {
      this.setUserLoadingState(user, true)
    } else {
      const [error, result] = await to(client.query({ query: USER_QUERY, fetchPolicy: "network-only" }))
      if (error || !result) {
        console.error("app-root:fetchAndSetUser:error", error)
      } else {
        if (!result.data.getCurrentUser || !result.data.getCurrentUser.authenticated) {
          LoginService.logoutUser(props.client)
        } else {
          const currentUser = { ...user, ...result.data.getCurrentUser }
          currentUser.siteAdmin = currentUser.role === UserRoles.SITEADMIN
          currentUser.superAdmin = currentUser.siteAdmin || currentUser.role === UserRoles.SUPERADMIN
          currentUser.groupAdmin = currentUser.role === UserRoles.GROUPADMIN
          currentUser.groupRecruiter = currentUser.role === UserRoles.GROUPRECRUITER
          currentUser.groupViewer = currentUser.role === UserRoles.GROUPVIEWER
          currentUser.isAdmin = currentUser.role === UserRoles.ADMINISTRATOR
          currentUser.isRecruiter = currentUser.role === UserRoles.RECRUITER
          currentUser.isViewer = currentUser.role === UserRoles.VIEWER
          currentUser.isCandidate = currentUser.role === UserRoles.CANDIDATE
          currentUser.reload = false
          this.setUserLoadingState(currentUser, false)
        }
      }
    }
  }

  render() {
    return this.state.hasLoaded ? (
      <>
        {this.props.children}
        {!this.state.mobile && <Tooltip id="root-tooltip" place="bottom" effect="solid" multiline={true} />}
      </>
    ) : (
      <Spinner />
    )
  }
}

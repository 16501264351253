import { compose } from "react-recompose"
import React, { PureComponent } from "react"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import { symbols, ProcessTitles, ProcessSubTypeTitles } from "_root/constants"
import { Spinner } from "_layout/form-elements"
import withAuditLogs from "_modules/audit/hoc/withAuditLogs"

//#region Styles
const AuditLogTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`
const TableHead = styled.thead`
  & > tr {
    position: relative;
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
  }
`
const Header = styled.th`
  padding: 0 8px;
  white-space: nowrap;
`
const TableBody = styled.tbody``
const TableRow = styled.tr``
const TableCol = styled.td`
  position: relative;
  border-bottom: 2px solid var(--color-bg-lightest);
  border-left: 2px solid var(--color-bg-lightest);
  background: var(--color-bg-white);
  white-space: nowrap;
  padding: 8px;

  &:last-child {
    border-right: 2px solid var(--color-bg-lightest);
  }
`
const LogMessage = styled.span``
const Symbol = styled.i`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px 5px 0;

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--color-bg-grey);
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
  }

  .blackFlag svg {
    stroke: #000000;
  }
  .redFlag svg {
    stroke: #ff0000;
  }
  .blueFlag svg {
    stroke: #0000ff;
  }
  .greenFlag svg {
    stroke: #00ff00;
  }
  .yellowFlag svg {
    stroke: #ffe600;
  }
`
//#endregion

@compose(withAuditLogs)
export default class AuditLogList extends PureComponent {
  getPropertyFriendlyName(propertyName) {
    const valueMap = {
      grade: "gradering",
      process: "rekryteringsstatus",
      default: "",
    }
    return valueMap[propertyName] || valueMap["default"]
  }

  getProcessName(logEntry) {
    let returnValue = ""
    if (logEntry.messageData && logEntry.propertyName === "processSubType") {
      returnValue = ProcessTitles[logEntry.messageData.parentType]
    }
    if (logEntry.propertyName === "process") {
      returnValue = ProcessTitles[logEntry.kind === "NEW" ? logEntry.propertyValueTo : logEntry.propertyValueFrom]
    }

    return returnValue
  }

  createdFullName = (createdByUser) => {
    return !!createdByUser ? `${createdByUser.firstName} ${createdByUser.lastName}` : "Användare borttagen"
  }

  render() {
    const { loading, logEntries } = this.props.auditLogs

    if (loading) {
      return <Spinner />
    }

    return (
      <AuditLogTable>
        <TableHead>
          <TableRow>
            <Header width="110px">Datum</Header>
            <Header width="300px">Användare</Header>
            <Header>Meddelande</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {logEntries.length > 0 &&
            logEntries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCol>{format(parseISO(entry.createdAt), "yy-MM-dd")}</TableCol>
                <TableCol>{this.createdFullName(entry.createdByUser)}</TableCol>
                <TableCol>
                  {entry.message !== null && <LogMessage>{entry.message}</LogMessage>}
                  {entry.propertyName === "symbols" && (
                    <LogMessage>
                      {`${entry.kind === "NEW" ? "Lade till " : "Tog bort "} symbolen `}
                      {!!entry.propertyValueTo ? (
                        <Symbol>
                          <img src={symbols[entry.propertyValueTo]} className={entry.propertyValueTo} alt="Processymbol" />
                        </Symbol>
                      ) : (
                        <Symbol>
                          <img src={symbols[entry.propertyValueFrom]} className={entry.propertyValueFrom} alt="Processymbol" />
                        </Symbol>
                      )}
                    </LogMessage>
                  )}
                  {entry.propertyName === "process" && (
                    <LogMessage>
                      Ändrade rekryteringsstatus för <strong>{this.getProcessName(entry)}</strong> till <strong>{entry.kind === "NEW" ? "Aktiv" : "Inaktiv"}</strong>
                    </LogMessage>
                  )}
                  {entry.propertyName === "processSubType" && (
                    <LogMessage>
                      Ändrade intervjutyp för <strong>{this.getProcessName(entry)}</strong> från <strong>{ProcessSubTypeTitles[entry.propertyValueFrom]}</strong> till{" "}
                      <strong>{ProcessSubTypeTitles[entry.propertyValueTo]}</strong>
                    </LogMessage>
                  )}
                  {entry.propertyName !== null && ["symbols", "process", "processSubType"].indexOf(entry.propertyName) === -1 && (
                    <LogMessage>
                      Ändrade <strong>{this.getPropertyFriendlyName(entry.propertyName)}</strong> från <strong>{entry.propertyValueFrom || "[ tomt ] "}</strong> till{" "}
                      <strong>{entry.propertyValueTo || " [ tomt ]"}</strong>
                    </LogMessage>
                  )}
                </TableCol>
              </TableRow>
            ))}
        </TableBody>
      </AuditLogTable>
    )
  }
}

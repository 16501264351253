import React from 'react'
import styled from 'styled-components'
import { format } from "date-fns"
import { CancelButton, PrimaryButton, SecondaryButton } from '_root/layout/buttons'

//#region Styles
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 80vw;
  max-width: 1024px;
  height: calc(95vh - 80px);
  max-height: 100%;

  input:focus-visible {
    outline: unset;
  }
`
const Header = styled.div`
  display: flex;
  padding: 16px 8px;
  line-height: 1.4rem;
  background: var(--color-brand-green-light);

  > div:first-child {
    font-weight: bold;
    margin-right: 2rem;
  }
`
const Body = styled.div`
  flex: 1;
  margin-bottom: 1rem;
  padding: 0 8px;
  border: 1px solid var(--color-line);
  overflow-y: auto;
`
const Footer = styled.div`
  flex: 0 0 auto;
  text-align: right;
`
//#endregion Styles

export default class PreviewTemplate extends React.Component {

  render() {
    const { template, company, job, recruiter, external, interviewer, candidate, phone, email, onAction, onActionText, onCopy, onClose } = this.props
    const date = format(new Date(), 'yy-MM-dd')
    return (
      <Wrapper>
          <input 
            type='text'
            className='mb3 w-100'
            value={template.name}
            readOnly />
        <Header>
          <div>
            Tjänst:<br/>
            Företag:<br/>
            Kandidat:<br/>
            Intervjuare:<br/>
            Datum:<br/>
          </div>
          <div>
            {job || 'Namn på tjänst'}<br/>
            {company || 'Företagsnamn'}<br/>
            {candidate || 'Namn på kandidat'} {phone && <a href={`tel:${phone}`}>{phone}</a>} {email && <a href={`mailto:${email}`}>{email}</a>}<br/>
            {interviewer || 'Namn på intervjuare'}<br/>
            {date}<br/>
          </div>
        </Header>
        <Body dangerouslySetInnerHTML={{ __html: template.contentHTML}}></Body>
        <Footer>
          {onCopy &&
          <SecondaryButton className="mr3" onClick={onCopy}>Kopiera</SecondaryButton>
          }
          <CancelButton onClick={onClose}>Stäng</CancelButton>
          {onAction &&
          <PrimaryButton className="ml3" onClick={onAction}>{onActionText}</PrimaryButton>
          }
        </Footer>
      </Wrapper>
    )
  }
}

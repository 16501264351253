import React from 'react'
import styled from 'styled-components'
import withRouter from '_hoc/withRouter'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { DELETE_MEMBER } from '_root/containers/member/members-ql'
import confirm from '_components/confirm'
import LoginService from '_services/login-service'
import { isStrongEnough } from '_services/password'
import message from '_components/message'
import { UPDATE_PASSWORD_MUTATION } from '_root/common-ql'
import { MEDLEM } from '_routes/url-names'
import inject from '_services/inject'
import to from '_services/await.to'
import { PrimaryButton } from '_layout/buttons'
import { Password } from '_layout/form-elements'
import MemberLayout from './member-layout'

//#region Styles
const Error = styled.div`
  margin-top: -0.5em;
  margin-bottom: 20px;
  color: var(--color-error);
`
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);

  input {
    display: block;
  }
`
//#endregion

@compose(
    withApollo,
    withRouter,
    inject('user'),
    graphql(UPDATE_PASSWORD_MUTATION, { name: 'updateUserPassword' })
)
export default class MemberSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.initialState
    }

    initialState = {
        currentPassword: '',
        newPassword1: '',
        newPassword2: ''
    }

    deleteAccount = async () => {
        this.setState({deleteing: true})
        const confirmed = await confirm('Är du säker på att all information om dig ska raderas? Detta går inte att återställas!')
        if (!confirmed) return
        const [error, result] = await to(this.props.client.mutate({ mutation: DELETE_MEMBER, variables: { id: this.props.user.id } }))
        if (error || !result) {
            console.error('member-settings:deleteAccount:error', error, this.props.user.id)
            return
        }
        this.setState({deleted: true, deleteing: false})
        LoginService.logoutUser(this.props.client, MEDLEM)
    }

    updatePassword = async () => {
        this.setState({updatingPassword: true})
        const { currentPassword, newPassword1 } = this.state
        if (isStrongEnough(newPassword1)) {
            const variables = { email: this.props.user.email, oldPassword: currentPassword, newPassword: newPassword1 }
            const [error, result] = await to(this.props.updateUserPassword({ variables }))
            if (error || !result) {
                console.error('updatePassword:error: ', error)
                message('Ett fel uppstod när lösenord skulle uppdateras.')
            }
            else {
                message(result.data.updateUserPassword.message)
            }
            this.setState({weakPassword: false, updatingPassword: false})
        }
        else {
            this.setState({weakPassword: true, updatingPassword: false})
        }
    }

    render() {
      const { currentPassword, newPassword1, newPassword2, weakPassword, updatingPassword, deleteing } = this.state
      const { user } = this.props
      const disableButton = !currentPassword || !newPassword1 || !newPassword2 || newPassword1 !== newPassword2

      return (
        <MemberLayout>
          <Section>
            <H1>Hantera ditt konto</H1>
          </Section>
          {user.loggedIn && user.candidate && user.candidate.id &&
          <Section>
            <h3>Byte av lösenord</h3>
            <p>Ange minst 8 tecken, minst 1 stor bokstav och minst 1 siffra.</p>
            <Password autoComplete='new-password' placeholder='Nuvarande lösenord' value={currentPassword} onChange={e => this.setState({currentPassword: e.target.value})} />
            <Password autoComplete='new-password' placeholder='Nytt lösenord' value={newPassword1} onChange={e => this.setState({newPassword1: e.target.value, weakPassword: false})} />
            <Password autoComplete='new-password' placeholder='Upprepa nytt lösenord' value={newPassword2} onChange={e => this.setState({newPassword2: e.target.value, weakPassword: false})} />
            {weakPassword &&
            <Error>Lösenordet uppfyller inte villkoren</Error>
            }
            <PrimaryButton onClick={this.updatePassword} disabled={disableButton} loading={updatingPassword}>Byt lösenord</PrimaryButton>
          </Section>
          }
          {user.loggedIn && user.candidate && user.candidate.id &&
          <Section>
            <h3>Radera mina uppgifter</h3>
            <Ingress>Jag vill inte längre vara medlem och jag vill att alla uppgifter kopplade till mig raderas ur systemet.</Ingress>
            <Error>Även dina ansökningshandlingar i pågående rekryteringsprocesser kommer att tas bort.</Error>
            <PrimaryButton className='mt2' onClick={this.deleteAccount} loading={deleteing}>Radera mitt konto</PrimaryButton>
          </Section>
          }
        </MemberLayout>
      )
    }
}

import { ApolloLink, Observable } from '@apollo/client'

class WindowBroadcastLink extends ApolloLink {
    constructor(window) {
        super()
        this.windowInstance = window
    }

    request(operation, forward) {
      const { query } = operation

      const isChildwindow = !!this.windowInstance.opener
      const isMutationOperation = query.definitions.some((definition) => {
        return definition.kind === "OperationDefinition" && definition.operation === "mutation"
      })
      
      if ( ( !isChildwindow || !isMutationOperation) && forward !== null ) {
        return forward(operation)
      }

      return new Observable((observer) => {
        const handle = forward(operation).subscribe({
          next: (response) => {
            const message = JSON.parse(JSON.stringify({ operation, response }))
            this.windowInstance.postMessage(message, "*")
            this.windowInstance.opener.postMessage(message, "*")
            observer.next.apply(observer, [response])
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        })
        return () => {
          if (handle)
            handle.unsubscribe()
        }
      })
    }
}

export default WindowBroadcastLink
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import ArrowDownIcon from '_images/menu-down.svg'

export const ResultWrapper = styled.div`
    padding: 5px 20px;
`
export const GrowingResultWrapper = styled.div`
    display: none;
    flex-flow: column nowrap;
    flex: 1;

    &.active {
        display: flex;
    }
`

export const ResultGroupHeader = styled.div`
    cursor: pointer;
    display: flex;
    color: var(--color-text-white);
    text-decoration: none;
    padding: 10px 20px;
    margin: 2px 0;
    background: var(--color-nav-3);

    &.active {
        pointer-events: none;
    }

    &:hover {
        background: var(--color-nav-2-dark);
    }

    .count {
        color: var(--color-bg-grey-dark);
    }
`

export const ArrowDown = styled.span`
    display: inline-block;
    width: 24px;
    height: auto;
    margin-left: auto;
    background: url(${ArrowDownIcon}) no-repeat scroll 50% 50%;
`

export const ArrowUp = styled(ArrowDown)`
    transform: rotate(180deg);
`

export const ResultList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

export const ResultItem = styled.li`
    padding: 8px 20px;
    border-bottom: 1px solid #ccc;
    display: flex;

    a:first-child {
        margin-right: 1em;
        white-space: nowrap;
    }

    &:last-child {
        border-bottom: 0;
    }
`

const SimpleHyperLinkCss = css`
    &:hover {
        text-decoration: underline;
    }
`
export const SimpleLink = styled.a`${SimpleHyperLinkCss}`
export const SimpleRoutedLink = styled(Link)`${SimpleHyperLinkCss}`

export const SimpleShowMoreLink = styled(Link)`
    display: block;
    border-top: 1px solid #ccc;
    color: #000;
    font-weight: bold;
    padding: 8px 0;
`
import React from 'react'
import styled from 'styled-components'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { Spinner } from '_layout/form-elements'
import MemberLayout from './member-layout'
import JobAdEditor from '_components/recruit/job-ad-editor'
import CloseIconSrc from '_images/cross.svg'
import { JOB_AD_QUERY, UPDATE_JOB_AD_VIEWS } from '_containers/recruitment/recruitment-ql'
import to from '_services/await.to'
import { ANNONSER, MEDLEM } from '_root/routes/url-names'

//#region Styles
const Section = styled.div`
  position: relative;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const Close = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url(${CloseIconSrc}) no-repeat;
  opacity: 0.5;

  &:hover { opacity: 1; }
`
const BackLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  margin-left: 40px;
`
//#endregion

@compose(
    withRouter,
    graphql(JOB_AD_QUERY, { name: 'jobAdQuery', options: props => ({ variables: { id: props.match.params.annons } }) }),
    graphql(UPDATE_JOB_AD_VIEWS, { name: 'updateJobAdViews' })
)
export default class MemberAd extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        incremented: false
      }
    }

    componentDidMount = async () => {
      const [error, result] = await to(this.props.updateJobAdViews({variables: { jobAdId: this.props.match.params.annons }}))
      if (error || !result) {
        console.error('MemberAd:componentDidMount:error: ', error, this.props.match.params.annons);
      }
    }

    render() {
        let content = null
        if (this.props.jobAdQuery.loading || !this.props.jobAdQuery.jobAd) {
            content = <Spinner />
        }
        else {
            const { jobAd } = this.props.jobAdQuery
            content = <JobAdEditor company={jobAd.recruitment.company} jobAd={jobAd} readOnly />
        }
        return (
          <MemberLayout nomenu wide>
            <Section>
              <Close href={MEDLEM+ANNONSER} />
              {content}
            </Section>
            <BackLink href={MEDLEM+ANNONSER}>&laquo; Tillbaka till platsannonser</BackLink>
          </MemberLayout>
        )
    }
}

import React from "react"
import { compose } from "react-recompose"
import styled from "styled-components"
import inject from "_services/inject"
import { SecondaryButton, CheckboxButton, SecondaryInvertButton } from "_layout/buttons"
import withChannelActivationInterceptor from "_modules/recruitments/hoc/withChannelActivationInterceptor"

//#region Styles
const Channel = styled.div`
  min-width: 304px;
  background: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);

  &.selected {
    background: var(--color-bg-grey);

    button {
      background: var(--color-bg-dark);
    }
  }
`
const ChannelHeader = styled.div`
  position: relative;
  height: 40px;
  background-color: var(--color-bg-lightest);

  .publishchannel__logo,
  .publishchannel__activationbutton {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
  }

  .publishchannel__logo {
    left: 20px;
  }
  .publishchannel__activationbutton {
    right: 20px;
  }
`
const ChannelContent = styled.div`
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100% - 40px);
  text-align: center;

  .channel__title {
    font-weight: bold;
    margin-bottom: 8px;
    padding: 0 52px;
  }

  .channel__description {
    color: var(--color-text-dark);
    margin: 0 0 16px 0;
    padding: 0 10px;
  }

  .channel__adduration {
    color: var(--color-text-dark);
    font-size: 14px;
    margin-bottom: 16px;
  }
`
const Price = styled.div`
  margin-top: auto;
  margin-bottom: 10px;
`
const StyledCheckboxButton = styled(CheckboxButton)`
  margin: 0;
  align-self: center;
`
const Offer = styled.div`
  margin-top: 20px;
`
//#endregion
@compose(withChannelActivationInterceptor, inject("user"))
export default class PublishChannel extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      activated: this.props.activated,
      selected: this.props.selected,
      selectedOffer: this.props.selectedOffer,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.selected !== prevState.selected) {
      nextState.selected = nextProps.selected
    }
    if (nextProps.selectedOffer !== prevState.selectedOffer) {
      nextState.selectedOffer = nextProps.selectedOffer
    }
    if (nextProps.activated !== prevState.activated) {
      nextState.activated = nextProps.activated
      nextState.loading = false
    }
    return nextState
  }

  onToggleActiveState = () => {
    this.setState({ loading: true })
    if (!this.props.selectionId) return
    this.props.onActivationChange({
      selectionId: this.props.selectionId,
      channelId: this.props.id,
      activated: !this.props.activated,
    })
  }

  onClick = () => {
    const selected = !this.state.selected
    this.setState({ selected: selected })
    this.props.onChange &&
      this.props.onChange({
        checkedState: selected,
        channelId: this.props.id,
        selectionId: this.props.selectionId,
      })
  }

  onOffer = () => {
    const selectedOffer = !this.state.selectedOffer
    this.setState({ selectedOffer: selectedOffer })
    this.props.onOffer &&
      this.props.onOffer({
        selectedOffer: selectedOffer,
        channelId: this.props.id,
        selectionId: this.props.selectionId,
      })
  }

  render() {
    const { selected, selectedOffer, loading } = this.state
    const { activated, user, logoUrl, readOnly, title, description, duration, price, offer } = this.props
    const isSuperAdminOrSiteAdmin = ["SUPERADMIN", "SITEADMIN"].indexOf(user.role) > -1

    return (
      <Channel className={this.props.className}>
        <ChannelHeader>
          {selected &&
            isSuperAdminOrSiteAdmin &&
            (activated ? (
              <SecondaryInvertButton className="publishchannel__activationbutton" disabled={readOnly} onClick={this.onToggleActiveState} loading={loading}>
                Avaktivera kanal
              </SecondaryInvertButton>
            ) : (
              <SecondaryButton className="publishchannel__activationbutton" disabled={readOnly} onClick={this.onToggleActiveState} loading={loading}>
                Aktivera kanal
              </SecondaryButton>
            ))}
          <img className="publishchannel__logo" src={logoUrl} alt={`Logga för publiseringskanal - ${title}`} />
        </ChannelHeader>
        <ChannelContent>
          <header className="channel__title">{title}</header>
          {description && <p className="channel__description">{description}</p>}
          {duration && <span className="channel__adduration">{`${duration} dagars annonsering`}</span>}
          {price && (
            <Price>
              <strong>Pris:</strong> {price}
            </Price>
          )}
          <StyledCheckboxButton disabled={readOnly} checked={!!selected} onClick={this.onClick}>
            {selected ? "Vald kanal" : "Välj kanalen"}
          </StyledCheckboxButton>
          {offer && (
            <React.Fragment>
              <Offer>
                <strong className="db">Erbjudande:</strong>
                {offer}
              </Offer>
              <StyledCheckboxButton disabled={readOnly} checked={!!selectedOffer} onClick={this.onOffer}>
                {selectedOffer ? "Erbjudande valt" : "Välj erbjudande"}
              </StyledCheckboxButton>
            </React.Fragment>
          )}
        </ChannelContent>
      </Channel>
    )
  }
}

import {
    isFunction,
    isObject,
    mapValues,
    merge
} from 'lodash/fp'

const flatMap = require('lodash/fp/flatMap').convert({ 'cap': false })
const reduce = require('lodash/fp/reduce').convert({ 'cap': false })

/**
 * @function objectToFilterGqlString
 * @param  {type} filterObject {description}
 * @return {type} {description}
 */
const objectToFilterGqlString = (filterObject) => {
    const strings = flatMap((value, key) => {
        if (Array.isArray(value)) {
            return `${key}: [ ${ value.map((item) => objectToFilterGqlString(item)) } ]`
        } else if (isObject(value)) {
            return `${key}: ${ objectToFilterGqlString(value) }`
        } else {
            return `${key}: ${value}`
        }
    })(filterObject)

    return `{ ${strings.join()} }`
}

/**
 * @function {function name}
 * @param  {type}  {description}
 * @return {type} {description}
 */
class QueryProviderConfigBuilder {
    config = {
        filterProvider: null,
        queryReconciler: null
    }

    withFilterProvider(filterProvider) {
        this.config.filterProvider = filterProvider
        return this
    }

    withQueryReconciler(queryReconciler) {
        this.config.queryReconciler = queryReconciler
        return this
    }

    get() {
        return new QueryProvider(this.config)
    }
}

/**
 * @function {function name}
 * @param  {type}  {description}
 * @return {type} {description}
 */
export class QueryProvider {
    static Create() {
        return new QueryProviderConfigBuilder()
    }

    constructor(providerConfig) {
        this._providerConfig = providerConfig
    }

    getQuery(queryConfig, runtimeContext) {
        const { filterProvider, queryReconciler } = this._providerConfig

        const _filterContext = filterProvider.getFilterContext(runtimeContext)

        const query = queryReconciler(_filterContext)

        const { variableResolvers } = _filterContext
        const { options: queryOptions, ...otherOperationOptions } = queryConfig

        const optionResolvers = [
            isFunction(queryOptions) ? queryOptions : () => queryOptions,
            props => ({
                variables: reduce((result, resolver) => {
                    return merge(result, resolver(props))
                }, {})(variableResolvers)
            })
        ]

        const options = {
            ...otherOperationOptions || {},
            options: props => {
                const result = reduce((result, resolver) => {
                    return merge(result, resolver(props))
                }, {})(optionResolvers)
                return result
            }
        }

        return {
            query,
            options
        }
    }
}

/**
 * @function {function name}
 * @param  {type}  {description}
 * @return {type} {description}
 */
export class FilterProviderMediator {
    constructor(filterProvider) {
        this._filterProvider = filterProvider
    }

    getFilterContext(runtimeContext) {
        const { userContext, routerContext } = runtimeContext
        const isSuperAdminOrSiteAdmin = ["SUPERADMIN", "SITEADMIN"].includes(userContext.role)
        const isGroupAdmin = userContext.role === "GROUPADMIN"
        const isGroupRecruiterOrViewer = ["GROUPRECRUITER", "GROUPVIEWER"].includes(userContext.role)
        const isAdmin = userContext.role === "ADMINISTRATOR"
        const isRecruiterOrViewer = ["RECRUITER", "VIEWER"].includes(userContext.role)
        const isViewingNonJobbetCompany = (routerContext.company && routerContext.company !== "jobbet.se")

        let filterContext

        if (isSuperAdminOrSiteAdmin && !isViewingNonJobbetCompany) {
            filterContext = this._filterProvider.getContextForSuperadmin(runtimeContext)
        } else if (isGroupAdmin) {
            filterContext = this._filterProvider.getContextForGroupAdmin(runtimeContext)
        } else if (isGroupRecruiterOrViewer) {
            filterContext = this._filterProvider.getContextForGroupRecruiterViewer(runtimeContext)
        } else if (isAdmin || (isSuperAdminOrSiteAdmin && isViewingNonJobbetCompany)) {
            filterContext = this._filterProvider.getContextForAdmin(runtimeContext)
        } else if (isRecruiterOrViewer) {
            filterContext = this._filterProvider.getContextForRecruiterViewer(runtimeContext)
        } else {
            return null // TODO: Handle differently?
        }

        filterContext.filters = mapValues((filterObject) => objectToFilterGqlString(filterObject))(filterContext.filters)
        filterContext.variableDefinitions = reduce((result, value) => {
            for (let [vName, vType] of Object.entries(value)) {
                result.push(`${vName}: ${vType}`)
            }
            return result
        }, [])(filterContext.variableDefinitions, {}).join(', ')

        return filterContext
    }
}
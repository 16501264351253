import React, { PureComponent } from "react"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { map, flow, filter, get, pick } from "lodash/fp"
import { ButtonNavLink } from "_layout/form-elements"
import * as url from "_routes/url-names"
import AdMeter from "_components/dashboard/ad-meter"
import { memoizedCalculateScore } from "_modules/recruitments"
import { RECRUITMENT_PUBLICATIONCHANNELS } from "_modules/recruitments/publicationchannel-ql"
import inject from "_services/inject"
import { UserRoles } from "_root/constants"

//#region
const PublishChannelsList = styled.div``
const Title = styled.header`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid var(--color-line-dark);

  .title {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`
const PrimaryLink = styled(ButtonNavLink)`
  width: auto;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 9px 22px;
  font-size: 0.9em;
  border-radius: 20px;
  color: var(--color-text-white);
  background: var(--color-brand-green);
`

const PublishChannelLogo = styled.img`
  opacity: 0.3;
  margin: 0 10px 10px 0;

  &.selected {
    opacity: 1;
  }
`

const withPublicationChannelData = graphql(RECRUITMENT_PUBLICATIONCHANNELS, {
  name: "publicationChannelsQuery",
  options: props => ({
    variables: {
      recruitmentId: props.match.params.recruitment,
    },
  }),
})
//#endregion

@compose(withRouter, inject("user"), withPublicationChannelData)
export default class PublishChannelsSummary extends PureComponent {
  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  render() {
    let { loading, publishChannels = [] } = this.props.publicationChannelsQuery

    const {
      adMeterScoreContext: { publishChannelsOther },
      match: { params },
    } = this.props

    const editUrl = `/${params.company}${url.REKRYTERING}/${params.state}/${params.recruitment}${url.REK_KANALER}`

    const activeChannels = flow(
      filter(ch => ch.selected && ch.selected.length && get("selected[0].activated")(ch)),
      map(pick(["id", "sort", "title"]))
    )(publishChannels)

    const inactiveChannels = flow(
      filter(ch => ch.selected && ch.selected.length && !get("selected[0].activated")(ch)),
      map(pick(["id", "sort", "title"]))
    )(publishChannels)

    const adMeterScore = memoizedCalculateScore({
      publicationChannels: activeChannels,
      hasOther: !!publishChannelsOther,
    })

    const adMeterBookedScore = memoizedCalculateScore({
      publicationChannels: inactiveChannels,
      hasOther: false,
    })

    return (
      <div>
        <Title>
          <span className="title">Annonskanaler</span>
          <div className="ad-meter-wrapper">
            <AdMeter level={Math.ceil(adMeterScore * 7)} booked={Math.ceil(adMeterBookedScore * 7)} />
          </div>
        </Title>
        {activeChannels.length === 0 && <span className="sub-heading-missing">Ni har inga aktiverade annonskanaler för denna rekrytering</span>}
        {activeChannels.length > 0 && <span className="sub-heading">Lägg till fler kanaler för att synas mer</span>}
        <PublishChannelsList>
          {!loading &&
            publishChannels.map(pc => {
              const active = activeChannels.some(activeChannel => activeChannel.id === pc.id)
              return (
                <PublishChannelLogo
                  key={pc.id}
                  className={active ? "selected" : ""}
                  src={pc.logo && pc.logo.url && pc.logo.url}
                  alt={pc.title}
                  data-tooltip-id="root-tooltip"
                  data-tooltip-content={`${pc.title} ${active ? "" : "- (Ej aktiverad)"}`}
                />
              )
            })}
        </PublishChannelsList>
        {this.hasWriteAccess() && <PrimaryLink to={editUrl}>Redigera annonskanaler</PrimaryLink>}
      </div>
    )
  }
}

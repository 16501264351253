import React from "react"
import { Navigate } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { setUser } from "_actions/user-actions"
import withRouter from "_hoc/withRouter"
import inject from "_services/inject"
import to from "_services/await.to"
import * as url from "_routes/url-names"
import CommonRoute from "_routes/common-route"
import Page404 from "_containers/404"
import { COMPANY_QUERY } from "_containers/settings/company-profile-ql"
import { ADMIN_COMPANY, STORE_FILTER } from "_root/constants"
import FaviconRedBg from "_images/favicon-red-bg.png"
import FaviconGreenBg from "_images/favicon-green-bg.png"
import FaviconWhiteBg from "_images/favicon-white-bg.png"
import message from "_root/components/message"
import { Spinner } from "_root/layout/form-elements"

@compose(
  withRouter,
  withApollo,
  inject("user"),
  connect(null, dispatch => bindActionCreators({ setUser }, dispatch)))
export default class AuthRoute extends React.Component {
  state = { pageNotFound: false }

  componentDidMount() {
    this.setCompanyView()
  }

  componentDidUpdate() {
    this.setCompanyView()
  }

  setCompanyView = async () => {
    const { user, client, match, navigate, setUser } = this.props
    if (user.companyView || this.state.pageNotFound) return

    if (!user.companyView && user.employer && match.params.company) {
      let companyView
      const [errorCompany, resultCompany] = await to(client.query({ query: COMPANY_QUERY, variables: { urlName: match.params.company } }))
      if (errorCompany || !resultCompany || !resultCompany.data.company) {
        await message("Det finns inget företag på denna webbadress!")
        this.setState({ pageNotFound: true })
      }
      else {
        companyView = { ...resultCompany.data.company }
        if (companyView.id !== user.employer.id && !user.superAdmin && !user.employer.groupies.find(g => g.id === companyView.id)) {
          navigate(url.ROOT + user.employer.urlName + url.REKRYTERING + url.REK_AKTIVA, { replace: true })
        }
        else {
          let filter = localStorage.getItem(STORE_FILTER + companyView.id)
          filter = filter ? JSON.parse(filter) : {}
          if (companyView.id !== user.employer.id) {
            filter.where = filter.where || {}
            filter.where.company = filter.where.company || {}
            filter.where.company.id = filter.where.company.id || {}
            filter.where.company.id.equals = companyView.id
          }
          companyView.isJobbet = companyView.urlName === ADMIN_COMPANY
          setUser({ ...user, companyView, filter: { ...filter } })
          document.title = companyView.isJobbet ? companyView.name : companyView.name + " - Jobbet.se"
          let favicon = FaviconWhiteBg
          if (companyView.group) favicon = FaviconGreenBg
          else if (companyView.isJobbet) favicon = FaviconRedBg
          document.getElementById("favicon").href = favicon
        }
      }
    }
  }

  render() {
    const { user, location } = this.props
    if (this.state.pageNotFound) {
      return <Page404 />
    }
    if (!user.loggedIn) {
      return <Navigate to={url.LOGIN} state={{ from: location }} />
    }
    if (!user.companyView) {
      return <Spinner />
    }
    const { component: Component, ...rest } = this.props
    return (
      <CommonRoute {...rest} authenticate={true}>
        <Component {...rest} />
      </CommonRoute>
    )
  }
}

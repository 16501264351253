import React from "react"
import withRouter from "_hoc/withRouter"
import { Navigate, Outlet, matchPath } from "react-router-dom"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { Spinner } from "_root/layout/form-elements"
import inject from "_services/inject"
import { LOGIN, MEDLEM, PROFIL, SLASH } from "_root/routes/url-names"
import { SIGNIN_USER_MUTATION, FORGOT_PASSWORD_MUTATION, SYSTEM_SETTINGS } from "_root/common-ql"
import MemberLayout from "./member-layout"

//#region Styles
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const H2 = styled.h2`
  margin: 0;
  color: var(--color-text);

  @media (max-width: 360px) {
    a {
      display: none;
    }
  }
`
//#endregion

@compose(
  inject("user"),
  withRouter,
  graphql(SYSTEM_SETTINGS, { name: "system" }),
  graphql(SIGNIN_USER_MUTATION, { name: "signinUser" }),
  graphql(FORGOT_PASSWORD_MUTATION, { name: "forgotPassword" }))
export default class Member extends React.Component {

  render() {
    const { system, user, location } = this.props
    const panic = system.systems && system.systems[0].panic

    if (system.loading) {
      return (
        <MemberLayout>
          <Section>
            <Spinner />
          </Section>
        </MemberLayout>
      )
    }

    if (panic) {
      return (
        <MemberLayout>
          <H2>Systemet är tillfälligt stängt för underhåll.</H2>
          <Section>
            <h2 className="mt4">Kundtjänst</h2>
            <p>Telefon: 018 - 100 112</p>
            <p>
              E-post: <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>
            </p>
            <p>Adress: Fyristorg 6, 753 10 Uppsala</p>
          </Section>
        </MemberLayout>
      )
    }

    const { pathname } = location
    if (pathname.endsWith(MEDLEM) || pathname.endsWith(MEDLEM + SLASH)) {
      if (user.authenticated)
      {
        return <Navigate to={MEDLEM + PROFIL} />
      }
      return <Navigate to={MEDLEM + LOGIN} />
    }

    return <Outlet context={[]} />
  }
}

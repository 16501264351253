import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import styled from "styled-components"
import { isUndefined, isEqual, uniq } from "lodash/fp"
import { TextArea, Input, Radio } from "_layout/form-elements"
import { SecondaryButton, CancelButton, SaveButton } from "_layout/buttons"
import confirm from "_components/confirm"
import Modal from "_components/modal"
import { AnswerType } from "_root/constants"
import DownIcon from "_images/menu-down-dark.svg"
import TrashIcon from "_images/trash.svg"
import to from "_services/await.to"
import { UPDATE_QUESTION } from "_containers/recruitment/recruitment-ql"
import message from "_components/message"

//#region Styles
const ArrowDown = styled.a`
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url(${DownIcon}) no-repeat scroll 50% 50% transparent;
  background-size: 16px 16px;
`
const ArrowUp = styled.a`
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url(${DownIcon}) no-repeat scroll 50% 50% transparent;
  background-size: 16px 16px;
  transform: rotate(180deg);
`
const Trash = styled.a`
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url(${TrashIcon}) no-repeat scroll 50% 50% transparent;
  background-size: 16px 16px;
`
const AnswerList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-line);

    &:first-child > ${ArrowUp} {
      visibility: hidden;
    }

    &:last-child > ${ArrowDown} {
      visibility: hidden;
    }

    span {
      flex: 1;
    }
  }
`
const Area = styled.div`
  margin-bottom: 40px;
  textarea {
    margin-bottom: 0;
  }
`
//#endregion

@graphql(UPDATE_QUESTION, { name: "updateQuestion" })
export default class SelectionEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: props.open || false,
      editQuestion: props.question ? { ...props.question, answers: uniq(props.question.answers) } : this.noQuestion(),
      newAnswer: "",
    }
  }

  noQuestion = () => {
    return {
      text: "",
      type: null,
      answers: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (!isUndefined(nextProps.open) && nextProps.open !== prevState.modalOpen) {
      nextState.modalOpen = nextProps.open
      nextState.editQuestion = { ...nextProps.question, answers: uniq(nextProps.question.answers) }
    }
    return nextState
  }

  onNewAnswer = e => {
    this.setState({ newAnswer: e.target.value })
  }

  onAddAnswer = async () => {
    const { editQuestion, newAnswer } = this.state
    if (!newAnswer || !newAnswer.trim().length) return
    if (editQuestion.answers.find(a => a.value === newAnswer.trim())) {
      await message("Det finns redan ett svarsalternativ med texten: " + newAnswer)
      return
    }
    const position = editQuestion.answers && editQuestion.answers.length ? Math.max(...editQuestion.answers.map(a => a.position)) + 1 : 0
    const answers = uniq([...editQuestion.answers, { value: newAnswer.trim(), position }])
    const question = { ...editQuestion, answers }
    const canSave = this.checkSave(question)
    this.setState({ newAnswer: "", editQuestion: question, canSave })
  }

  onModalClose = e => {
    this.setState({ modalOpen: false, editQuestion: this.noQuestion() })
    this.props.onModalClose()
  }

  onSaveQuestion = async () => {
    const { editQuestion } = this.state
    const { id, answers } = editQuestion
    const questionInput = {
      text: { set: editQuestion.text },
      type: { set: editQuestion.type },
      order: editQuestion.order !== undefined ? { set: editQuestion.order } : undefined,
    }
    if (answers) {
      const create = answers.filter(a => !a.id)
      const update = answers.filter(a => !!a.id).map(a => ({ where: { id: a.id }, data: { value: { set: a.value }, position: { set: a.position } } }))
      const del = this.props.question.answers.filter(a => !answers.find(answer => answer.id === a.id)).map(a => ({ id: a.id }))
      if (create.length || update.length || del.length) {
        questionInput.answers = {}
        if (create.length) questionInput.answers.create = create
        if (update.length) questionInput.answers.update = update
        if (del.length) questionInput.answers.delete = del
      }
    }
    const [error, result] = await to(this.props.updateQuestion({ variables: { id, questionInput } }))
    if (error) {
      console.error("selection-edit:onSaveQuestion:error: ", error)
    } else {
      const {
        data: { updateQuestion: editQuestion },
      } = result
      this.props.onSaveQuestion && this.props.onSaveQuestion(editQuestion)
      this.setState({ canSave: false, editQuestion })
    }
  }

  checkSave = question => {
    if (isEqual(question)(this.state.editQuestion) && isEqual(question.answers)(this.state.editQuestion.answers)) return false
    if (!question.text || !question.type) return false
    if (question.type === AnswerType.TEXT || question.type === AnswerType.BOOL || question.type === AnswerType.GRADE) return true
    if (question.answers && question.answers.length > 1) return true
    return false
  }

  onTextChange = e => {
    const question = { ...this.state.editQuestion, text: e.target.value }
    const canSave = this.checkSave(question)
    this.setState({ editQuestion: question, canSave })
  }

  onTypeSelect = e => {
    const question = { ...this.state.editQuestion, type: e.target.value }
    if (question.type !== AnswerType.MULTIPLE && question.type !== AnswerType.SINGLE) question.answers = []
    const canSave = this.checkSave(question)
    this.setState({ editQuestion: question, canSave })
  }

  onMoveDown = from => {
    const answers = JSON.parse(JSON.stringify(this.state.editQuestion.answers))
    answers.splice(from + 1, 0, answers.splice(from, 1)[0])
    answers.forEach((a, i) => (a.position = i))
    this.setState({ editQuestion: { ...this.state.editQuestion, answers }, canSave: true })
  }

  onMoveUp = from => {
    const answers = JSON.parse(JSON.stringify(this.state.editQuestion.answers))
    answers.splice(from - 1, 0, answers.splice(from, 1)[0])
    answers.forEach((a, i) => (a.position = i))
    this.setState({ editQuestion: { ...this.state.editQuestion, answers }, canSave: true })
  }

  onDeleteAnswer = async (answer, index) => {
    const confirmed = await confirm('Vill du ta bort svarsalternativet: "' + answer.value + '"?', { confirmText: "Ta bort" })
    if (!confirmed) return
    const answers = [...this.state.editQuestion.answers]
    answers.splice(index, 1)
    this.setState({ editQuestion: { ...this.state.editQuestion, answers }, canSave: true })
  }

  render() {
    const { editQuestion, modalOpen } = this.state

    const answers = editQuestion.answers.map((answer, index) => (
      <li key={answer.value}>
        <span>{answer.value}</span>
        <ArrowDown title="Flytta ner" onClick={e => this.onMoveDown(index)} />
        <ArrowUp title="Flytta upp" onClick={e => this.onMoveUp(index)} />
        <Trash title="Ta bort" onClick={e => this.onDeleteAnswer(answer, index)} />
      </li>
    ))
    const answerList = editQuestion.answers.length ? <AnswerList>{answers}</AnswerList> : null

    return (
      <Modal className={this.props.className} isOpen={modalOpen} onRequestClose={this.onModalClose}>
        <Area>
          <h3>Formulera urvalsfrågan:</h3>
          <TextArea placeholder="Skriv din fråga här..." onChange={this.onTextChange} value={editQuestion.text} />
        </Area>
        <Area>
          <h3>Välj typ av svarsalternativ:</h3>
          <Radio
            id="answerText"
            className="mr4"
            name="answerType"
            value={AnswerType.TEXT}
            label="text"
            title="Ange textsvar"
            onClick={this.onTypeSelect}
            checked={this.state.editQuestion.type === AnswerType.TEXT}
          />
          <Radio
            id="answerBool"
            className="mr4"
            name="answerType"
            value={AnswerType.BOOL}
            label="ja / nej"
            title="Ange ja eller nej"
            onClick={this.onTypeSelect}
            checked={this.state.editQuestion.type === AnswerType.BOOL}
          />
          <Radio
            id="answerGrade"
            className="mr4"
            name="answerType"
            value={AnswerType.GRADE}
            label="gradering 1-5"
            title="Gradering 1-5"
            onClick={this.onTypeSelect}
            checked={this.state.editQuestion.type === AnswerType.GRADE}
          />
          <Radio
            id="answerSingle"
            className="mr4"
            name="answerType"
            value={AnswerType.SINGLE}
            label="enval"
            title="Välj ett av flera alternativ"
            onClick={this.onTypeSelect}
            checked={this.state.editQuestion.type === AnswerType.SINGLE}
          />
          <Radio
            id="answerMultiple"
            name="answerType"
            value={AnswerType.MULTIPLE}
            label="flerval"
            title="Välj flera alternativ"
            onClick={this.onTypeSelect}
            checked={this.state.editQuestion.type === AnswerType.MULTIPLE}
          />
        </Area>
        {(editQuestion.type === AnswerType.MULTIPLE || editQuestion.type === AnswerType.SINGLE) && (
          <Area>
            <h3>Ange minst två svarsalternativ:</h3>
            <Input className="mr3" type="text" placeholder="Skriv svarsalternativ här..." value={this.state.newAnswer} onChange={this.onNewAnswer} />
            <SecondaryButton onClick={this.onAddAnswer} disabled={!this.state.newAnswer.trim().length}>
              Lägg till
            </SecondaryButton>
            {answerList}
          </Area>
        )}
        <CancelButton onClick={this.onModalClose}>Stäng</CancelButton>
        <SaveButton className="mv0 ml3" disabled={!this.state.canSave} onClick={this.onSaveQuestion}>
          Spara urvalsfrågan
        </SaveButton>
      </Modal>
    )
  }
}

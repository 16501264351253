import React from "react"
import MainLayout from "_layout/main-layout"
import { H2, FlexRow, FlexCol } from "_layout/form-elements"

export default class AdminPermission extends React.Component {
  render() {
    return <MainLayout padding maxHeight scroll>
      <div>
        <H2>Roller &amp; behörighet</H2>
        <FlexRow className="margin20">
          <FlexCol>Det finns tre roller i systemet admin, rekryterare och granskare. Här kan du sätta behörigheter på rollerna i systemet.</FlexCol>
        </FlexRow>
      </div>
    </MainLayout>
  }
}

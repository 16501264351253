import React from "react"
import MainLayout from "_layout/main-layout"
import { Spinner } from "_layout/form-elements"

export default props => {
  return <MainLayout padding>
    <h4 className="mt0 mb3">{props.title || "Laddar..."}</h4>
    <Spinner />
  </MainLayout>
}

export const MENU_CLOSE_MAIN = "MENU_CLOSE_MAIN"
export const MENU_TOGGLE_MAIN = "MENU_TOGGLE_MAIN"
export const MENU_TOGGLE_FILTER = "MENU_TOGGLE_FILTER"
export const MENU_ACTIVE_COUNT = "MENU_ACTIVE_COUNT"
export const MENU_DRAFT_COUNT = "MENU_DRAFT_COUNT"
export const MENU_CLOSED_COUNT = "MENU_CLOSED_COUNT"

export const closeMainMenu = () => {
  return dispatch => {
    dispatch({
      type: MENU_CLOSE_MAIN,
    })
  }
}

export const toggleMainMenu = () => {
  return dispatch => {
    dispatch({
      type: MENU_TOGGLE_MAIN,
    })
  }
}

export const toggleFilterMenu = () => {
  return dispatch => {
    dispatch({
      type: MENU_TOGGLE_FILTER,
    })
  }
}

export const setMenuActiveCount = activeCount => {
  return dispatch => {
    dispatch({
      type: MENU_ACTIVE_COUNT,
      payload: activeCount,
    })
  }
}

export const setMenuDraftCount = draftCount => {
  return dispatch => {
    dispatch({
      type: MENU_DRAFT_COUNT,
      payload: draftCount,
    })
  }
}

export const setMenuClosedCount = closedCount => {
  return dispatch => {
    dispatch({
      type: MENU_CLOSED_COUNT,
      payload: closedCount,
    })
  }
}

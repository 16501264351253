import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { map, isEmpty } from "lodash/fp"
import { Actions, ADMIN_COMPANY, ADMIN_COMPANY_ID, TemplateType } from "_root/constants"
import Modal from "_components/modal"
import confirm from "_components/confirm"
import message from "_components/message"
import { PrimaryButton, SecondaryButton } from "_layout/buttons"
import { Checkbox, Spinner } from "_layout/form-elements"
import AddTemplate from "_components/templates/add-template"
import CopyTemplate from "_components/templates/copy-template"
import BrowseTemplates from "_components/templates/browse-templates"
import CopyTemplates from "_components/templates/copy-templates"
import TemplateJobbetSrc from "_images/template-job.svg"
import TrashIcon from "_images/trash.svg"
import { CREATE_TEMPLATE, UPDATE_TEMPLATE, DELETE_TEMPLATE, COMPANY_QUERY, GROUPS_QUERY } from "_containers/recruitment/recruitment-docs-ql"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const Wrapper = styled.div`
  margin-bottom: 60px;
`
const JobbetList = styled.ul`
  display: inline-flex;
  margin: 1rem 3rem 2rem 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-right: 20px;

    a {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      img {
        width: 50px;
        height: 64px;
        margin-bottom: 1rem;
      }
    }
  }
`
const Title = styled.span`
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  min-height: 100%;
  line-height: 1.5;

  .spinner {
    flex: 1;
    margin-left: auto;
  }
`
const Cell = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 100%;
  border-left: 1px solid var(--color-line-light);
  line-height: 1.5;
  text-align: center;

  &.head {
    align-items: flex-end;
    padding-bottom: 5px;
  }

  input + span {
    margin-right: 0;
  }
`
const Link = styled.a`
  flex: 1 0 auto;
  text-decoration: none;
`
const Trash = styled.a`
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const List = styled.ul`
  margin: 0 0 20px 0;
  padding: 0;
  width: 100%;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--color-line-light);

    &.head {
      font-weight: 700;
      align-items: flex-end;
      height: 40px;
    }
  }
`
//endregion

@compose(
  withRouter,
  inject("user"),
  graphql(CREATE_TEMPLATE, { name: "createTemplate" }),
  graphql(UPDATE_TEMPLATE, { name: "updateTemplate" }),
  graphql(DELETE_TEMPLATE, { name: "deleteTemplate" }),
  graphql(COMPANY_QUERY, { name: "companyQuery", options: props => ({ variables: { urlName: props.match.params.company, filter: { type: { equals: props.type } } } }) }),
  graphql(COMPANY_QUERY, {
    name: "jobbetQuery",
    options: props => ({ variables: { urlName: ADMIN_COMPANY, filter: { type: { equals: props.type } } } }),
    skip: props => props.match.params.company === ADMIN_COMPANY,
  }),
  graphql(COMPANY_QUERY, {
    name: "groupQuery",
    options: props => ({ variables: { urlName: props.user.employer.urlName, filter: { type: { equals: props.type } } } }),
    skip: props => !props.user.groupAdmin || props.match.params.company === props.user.employer.urlName,
  }),
  graphql(GROUPS_QUERY, {
    name: "groupsQuery",
    options: props => ({ variables: { urlNames: props.user.companyView.groups.map(g => g.urlName), filter: { type: { equals: props.type } } } }),
    skip: props => !props.user.superAdmin || props.match.params.company === ADMIN_COMPANY,
  })
)
export default class TemplateList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      modalOpen: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (!prevState.company && nextProps.user.companyView) {
      nextState.isJobbet = nextProps.user.companyView.id === ADMIN_COMPANY_ID
    }

    const loading = nextProps.companyQuery.loading || (nextProps.jobbetQuery && nextProps.jobbetQuery.loading) || (nextProps.groupQuery && nextProps.groupQuery.loading)
    if (prevState.loading !== loading) {
      nextState.loading = loading
    }

    if (!nextProps.companyQuery.loading && nextProps.companyQuery.company) {
      const {
        companyQuery: { company },
      } = nextProps
      nextState.company = company
      nextState.templates = company.templates
      if (!prevState.company) {
        nextState.isJobbet = nextState.company.id === ADMIN_COMPANY_ID
      }
    }

    if (nextProps.jobbetQuery && !nextProps.jobbetQuery.loading && nextProps.jobbetQuery.company) {
      const {
        jobbetQuery: { company: jobbet },
      } = nextProps
      nextState.jobbetTemplates = jobbet.templates
      nextState.jobbetShared = jobbet.templates.filter(t => t.shared)
    } else {
      nextState.jobbetTemplates = []
    }

    if (nextProps.groupQuery && !nextProps.groupQuery.loading && nextProps.groupQuery.company) {
      const {
        groupQuery: { company: group },
      } = nextProps
      nextState.groupTemplates = group.templates
    } else {
      nextState.groupTemplates = []
    }

    if (nextProps.groupsQuery && !nextProps.groupsQuery.loading && nextProps.groupsQuery.companies) {
      const {
        groupsQuery: { companies: groups },
      } = nextProps
      nextState.groups = groups
    } else {
      nextState.groups = []
    }

    return !isEmpty(nextState) ? nextState : null
  }

  onPreview = e => {
    const id = e.currentTarget.dataset.tid
    const template = this.state.jobbetShared.find(j => j.id === id)
    this.setState({ previewTemplate: template })
  }

  onCreate = async template => {
    if (!this.state.company.id) return
    this.onModalClose()
    const variables = { companyId: this.state.company.id, ...template }
    const [errorCreate, resultCreate] = await to(this.props.createTemplate({ variables }))
    if (errorCreate || !resultCreate) {
      console.error("RecruitmentTemplates:createTemplate:error", errorCreate)
    } else {
      this.props.companyQuery.refetch()
      message(`Mallen "${template.name}" har sparats.`)
    }
    this.onPreviewModalClose()
  }

  onUpdate = async template => {
    if (!this.state.company.id) return
    const variables = { companyId: this.state.company.id, ...template, content: template.content || undefined }
    const [errorUpdate, resultUpdate] = await to(this.props.updateTemplate({ variables }))
    if (errorUpdate || !resultUpdate) {
      console.error("RecruitmentTemplates:updateTemplate:error", errorUpdate)
      return
    }
    this.props.companyQuery.refetch()
  }

  onCopy = async template => {
    if (!this.state.company.id) return
    const variables = { companyId: this.state.company.id, ...template, name: template.name }
    const [errorCreate, resultCopy] = await to(this.props.createTemplate({ variables }))
    if (errorCreate || !resultCopy) {
      console.error("RecruitmentTemplates:createTemplate:error", errorCreate)
      message(`Något gick fel. Mallen "${template.name}" kunde inte kopieras.`)
    } else {
      this.setState({ modalOpen: false, selected: null }, this.props.companyQuery.refetch)
    }
  }

  onShared = async e => {
    const template = {...this.state.templates.find(t => t.id === e.currentTarget.value)}
    template.shared = e.currentTarget.checked
    this.onUpdate(template)
  }

  onDelete = async template => {
    if (!template || !template.id) return
    const confirmed = await confirm(`Är du säker på att mallen "${template.name}" ska raderas?`)
    if (!confirmed) return
    const variables = { id: template.id }
    const [errorUpdate, resultUpdate] = await to(this.props.deleteTemplate({ variables }))
    if (errorUpdate || !resultUpdate) {
      console.error("RecruitmentTemplates:deleteTemplate:error", errorUpdate)
      return
    }
    this.props.companyQuery.refetch()
    this.setState({ selected: null, modalOpen: false, name: "", content: null })
    message(`Mallen "${template.name}" har raderats`)
  }

  onAdd = () => {
    this.setState({ modalOpen: true, action: Actions.ADD })
  }

  onShow = template => {
    this.setState({ modalOpen: true, selected: template, action: Actions.BROWSE })
  }

  onOpenCopy = () => {
    this.setState({ modalOpen: true, action: Actions.COPY })
  }

  onGroupCopy = () => {
    this.setState({ modalOpen: true, action: Actions.GROUPCOPY })
  }

  onGroupsCopy = e => {
    this.setState({ modalOpen: true, action: Actions.GROUPSCOPY, selectedGroupId: e.currentTarget.dataset.group })
  }

  onCopyPreviewTemplate = async () => {
    const { previewTemplate } = this.state
    this.setState({ modalOpen: true, selected: previewTemplate, previewTemplate: null, action: Actions.COPY_SINGLE })
  }

  onRequestModalClose = () => {
    this.setState({ closeEvent: true })
  }

  onPreviewModalClose = () => {
    this.setState({ previewTemplate: null })
  }

  onModalCancel = () => {
    this.setState({ modalOpen: true, closeEvent: false })
  }

  onModalClose = () => {
    this.setState({ modalOpen: false, closeEvent: false })
  }

  render() {
    const { type, title, noTemplatesText, user } = this.props

    const { action, isJobbet, company, groups, selectedGroupId, templates, jobbetTemplates, jobbetShared, groupTemplates, closeEvent, selected, loading, modalOpen, previewTemplate } = this.state

    const noTemplates =
      !templates || !templates.length
        ? [
            <List key="1">
              <li className="italic">{noTemplatesText || "Inga mallar tillgängliga"}</li>
            </List>,
            ...(loading
              ? [
                  <List key="2">
                    <Spinner />
                  </List>,
                ]
              : []),
          ]
        : null

    let groupsTemplates = groups.find(g => g.id === selectedGroupId)
    groupsTemplates = groupsTemplates ? groupsTemplates.templates : []

    let content
    switch (action) {
      case Actions.ADD:
        content = <AddTemplate type={type} title={"Skapa mall för " + title} close={closeEvent} onAdd={this.onCreate} onCancel={this.onModalCancel} onClose={this.onModalClose} />
        break
      case Actions.COPY_SINGLE:
        content = <CopyTemplate type={type} original={selected} close={closeEvent} onCopy={this.onCopy} onCancel={this.onModalCancel} onClose={this.onModalClose} />
        break
      case Actions.COPY:
        content = (
          <CopyTemplates
            type={type}
            company={company}
            title={"Kopiera mall för " + title}
            close={closeEvent}
            templates={jobbetTemplates}
            onCopy={this.onCopy}
            onCancel={this.onModalCancel}
            onClose={this.onModalClose}
          />
        )
        break
      case Actions.GROUPCOPY:
        content = (
          <CopyTemplates
            type={type}
            company={company}
            title={"Kopiera mall för " + title}
            close={closeEvent}
            templates={groupTemplates}
            onCopy={this.onCopy}
            onCancel={this.onModalCancel}
            onClose={this.onModalClose}
          />
        )
        break
      case Actions.GROUPSCOPY:
        content = (
          <CopyTemplates
            type={type}
            company={company}
            title={"Kopiera mall för " + title}
            close={closeEvent}
            templates={groupsTemplates}
            onCopy={this.onCopy}
            onCancel={this.onModalCancel}
            onClose={this.onModalClose}
          />
        )
        break
      default:
        content = (
          <BrowseTemplates
            type={type}
            title={"Välj mall för " + title}
            close={closeEvent}
            selected={selected}
            templates={templates}
            onSave={this.onUpdate}
            onSaveAs={this.onCreate}
            onDelete={this.onDelete}
            onCancel={this.onModalCancel}
            onClose={this.onModalClose}
          />
        )
        break
    }

    const sharable = (user.superAdmin || user.isAdmin || user.groupAdmin) && !user.companyView.group && type !== TemplateType.APPLICANT_EMAIL

    return (
      <Wrapper>
        {!isJobbet && jobbetShared && !!jobbetShared.length && type !== TemplateType.APPLICANT_EMAIL && (
          <JobbetList>
            {jobbetShared.map(t => (
              <li key={t.id}>
                <Link data-tid={t.id} onClick={this.onPreview}>
                  <img src={TemplateJobbetSrc} alt="Doc icon with part of Jobbet.se logo" title={t.name} />
                  <span>{t.name}</span>
                </Link>
              </li>
            ))}
          </JobbetList>
        )}
        {noTemplates || (
          <List>
            <li className="head">
              <Title>Titel {loading && <Spinner />}</Title>
              {sharable && (
                <Cell className="head" data-tooltip-id="root-tooltip" data-tooltip-content="Delad mall blir tillgänglig för alla rekryteringar.">
                  Delad
                </Cell>
              )}
              <Cell className="head">Ta bort</Cell>
            </li>
            {map(template => (
              <li key={template.id}>
                <Link onClick={e => this.onShow(template)}>{template.name}</Link>
                {sharable && (
                  <Cell data-tooltip-id="root-tooltip" data-tooltip-content="Delad mall blir tillgänglig för alla rekryteringar.">
                    <Checkbox value={template.id} onChange={this.onShared} checked={template.shared} />
                  </Cell>
                )}
                <Cell>
                  <Trash onClick={e => this.onDelete(template)} />
                </Cell>
              </li>
            ))(templates)}
          </List>
        )}
        <div className="tr">
          {!isJobbet && user.superAdmin && jobbetTemplates && jobbetTemplates.length > 0 && <SecondaryButton onClick={this.onOpenCopy}> Jobbet.se</SecondaryButton>}
          {groupTemplates && groupTemplates.length > 0 && (
            <SecondaryButton className="ml3" onClick={this.onGroupCopy}>
              {user.employer.name}
            </SecondaryButton>
          )}
          {groups.map(
            group =>
              !!group.templates.length && (
                <SecondaryButton key={group.id} className="ml3 mb3" data-group={group.id} onClick={this.onGroupsCopy}>
                  {group.name}
                </SecondaryButton>
              )
          )}
          <PrimaryButton className="ml3" onClick={this.onAdd}>
            Skapa mall
          </PrimaryButton>
        </div>
        <Modal isOpen={modalOpen} onRequestClose={this.onRequestModalClose} overflow>
          {content}
        </Modal>
        {previewTemplate && (
          <Modal isOpen={!!previewTemplate} onRequestClose={this.onRequestModalClose} overflow>
            <AddTemplate type={previewTemplate.type} name={previewTemplate.name} content={previewTemplate.contentHTML} onAdd={this.onCreate} onClose={this.onPreviewModalClose}></AddTemplate>
          </Modal>
        )}
      </Wrapper>
    )
  }
}

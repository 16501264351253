import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import styled from "styled-components"
import { assign, isEmpty, filter, find, map, partition } from "lodash/fp"
import { format } from "date-fns"
import Modal from "_components/modal"
import { FilePicker, Radio, Spinner, TextArea } from "_layout/form-elements"
import { PrimaryButton, SecondaryInvertButton, SecondaryButton, CancelButton } from "_layout/buttons"
import CreateDocument from "_components/recruit/create-document"
import EditDocument from "_components/recruit/edit-document"
import DocumentList from "_components/recruit/document-list"
import confirm from "_components/confirm"
import message from "_components/message"
import UploadService from "_services/upload-service"
import { COMPANY_QUERY, RECRUITMENT_DOCUMENTS_QUERY, DELETE_DOCUMENT, DELETE_FILE_AND_DOC } from "_containers/recruitment/recruitment-docs-ql"
import { getBookedServiceMailContent } from "_modules/recruitments/services/mailtemplate-service"
import withSendmailMutation from "_hoc/withSendmailMutation"
import { ADMIN_COMPANY, TemplateType, AllFileTypes } from "_root/constants"
import TemplateJobbetSrc from "_images/template-job.svg"
import TemplateClientSrc from "_images/template-client.svg"
import TemplateEmptySrc from "_images/template-empty.svg"
import AddTemplate from "_components/templates/add-template"
import to from "_services/await.to"
import all from "_services/await.all"
import inject from "_services/inject"

//#region Styles
const Content = styled.div`
  flex: 1;
  padding-bottom: 80px;
`
const OrderTemplates = styled.div`
  position: relative;
  text-align: center;
  margin: 20px 0;
`
const Tabs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--color-brand-green);

  li {
    cursor: pointer;
    display: inline-block;
    padding: 0 40px 10px 0;

    h3 {
      margin-bottom: 0;
      color: var(--color-text-light);
    }

    &:hover,
    &.active {
      h3 {
        color: var(--color-text-dark);
      }
    }
  }
`
const Section = styled.div`
  display: none;
  margin: 0 auto;
  padding: 2rem 0 0 0;
  max-width: 960px;
  transform: translateZ(0);
  border-bottom: none;

  &.active {
    display: block;
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`
const ModalHeader = styled.header`
  font-size: 1.5rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const Nowrap = styled.div`
  text-align: center;
  white-space: nowrap;
`
const Templates = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 30px 60px;
  max-width: 960px;
  background: var(--color-brand-green-light);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
`
const TemplateList = styled.ul`
  display: inline-flex;
  margin: 1rem 0 1rem 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 150px;

    a {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      height: 100%;

      &:hover {
        > img {
          opacity: 1;
        }
      }

      > img {
        width: 50px;
        height: 64px;
        margin-bottom: 0.5rem;
        opacity: ${(props) => (props.$shady ? "0.75" : "1")};
      }

      > span {
        text-align: center;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
`
const ActionHeader = styled.div`
  margin: 1.5rem 0 0.5rem 2.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-brand-green);

  span {
    display: inline-block;
    font-weight: 700;

    &:nth-child(1) {
      width: 400px;
    }

    &:nth-child(2) {
      width: 3rem;
    }
  }
`
const ActionList = styled.ul`
  list-style-type: none;
  margin: 0 0 0 2.5rem;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-line-dark);

    label {
      display: inline-block;
      margin: 0;
      width: 3rem;
      text-align: center;
    }

    .name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
      width: 400px;
    }
  }
`
const BaseTemplates = styled.div`
  margin-bottom: 2rem;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    span {
      width: 2.5rem;
      color: var(--color-brand-green);
      font-size: 1.5rem;
    }

    & + p {
      margin: 0 0 0 2.5rem;
    }
  }
`
const CurrentTemplates = styled.ul`
  padding: 20px 0;
`
const Link = styled.a`
  text-decoration: none;
`
const Controls = styled.div`
  margin-top: 30px;
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  withSendmailMutation,
  graphql(COMPANY_QUERY, {
    name: "clientQuery",
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        urlName: props.recruitment.company.urlName,
        filter: { OR: [{ type: { equals: TemplateType.INTERVIEW } }, { type: { equals: TemplateType.REFERENCES } }] },
      },
    }),
  }),
  graphql(COMPANY_QUERY, {
    skip: (props) => props.recruitment.company.urlName === ADMIN_COMPANY,
    name: "jobbetQuery",
    options: () => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        urlName: ADMIN_COMPANY,
        filter: { OR: [{ type: { equals: TemplateType.INTERVIEW } }, { type: { equals: TemplateType.REFERENCES } }] },
      },
    }),
  }),
  graphql(RECRUITMENT_DOCUMENTS_QUERY, {
    name: "docsQuery",
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: { id: props.recruitment.id },
    }),
  }),
  graphql(DELETE_FILE_AND_DOC, { name: "deleteFile" }),
  graphql(DELETE_DOCUMENT, { name: "deleteDocument" })
)
export class WorkDocumentsClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      documents: [],
      docsUploaded: [],
      docsSelected: [],
      docsContentInt: [],
      docsContentRef: [],
      jobbetFiles: [],
      companyFiles: [],
      jobbetSharedInt: [],
      jobbetSharedRef: [],
      companySharedInt: [],
      companySharedRef: [],
      companyTemplates: [],
      jobbetTemplates: [],
      startEditDoc: false,
      orderMessage: "",
      showConfirmOrderModal: false,
      showConfirmOrderTemplatesModal: false,
    }
  }

  componentDidMount = () => {
    const view = document.getElementById("docs-content")
    if (view) view.scrollIntoView(true)
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = {}

    const { user, clientQuery, jobbetQuery, docsQuery } = nextProps

    if (!prevState.employerId && user && user.employer) {
      nextState.employerId = user.employer.id
    }

    if (!prevState.clientId) nextState.clientId = nextProps.recruitment.company.id
    if (!prevState.settings) nextState.settings = nextProps.recruitment.settings || { templates: { selected: {} } }

    if (clientQuery && !clientQuery.loading && clientQuery.company) {
      nextState.companyFiles = [...clientQuery.company.files]
      nextState.companyTemplatesInt = [...clientQuery.company.templates.filter((t) => !t.shared && t.type === TemplateType.INTERVIEW)]
      nextState.companyTemplatesRef = [...clientQuery.company.templates.filter((t) => !t.shared && t.type === TemplateType.REFERENCES)]
      nextState.companySharedInt = [...clientQuery.company.templates.filter((t) => t.shared && t.type === TemplateType.INTERVIEW)]
      nextState.companySharedRef = [...clientQuery.company.templates.filter((t) => t.shared && t.type === TemplateType.REFERENCES)]
    }

    if (jobbetQuery && !jobbetQuery.loading && jobbetQuery.company) {
      nextState.jobbetFiles = [...jobbetQuery.company.files]
      nextState.jobbetTemplatesInt = [...jobbetQuery.company.templates.filter((t) => !t.shared && t.type === TemplateType.INTERVIEW)]
      nextState.jobbetTemplatesRef = [...jobbetQuery.company.templates.filter((t) => !t.shared && t.type === TemplateType.REFERENCES)]
      nextState.jobbetSharedInt = [...jobbetQuery.company.templates.filter((t) => t.shared && t.type === TemplateType.INTERVIEW)]
      nextState.jobbetSharedRef = [...jobbetQuery.company.templates.filter((t) => t.shared && t.type === TemplateType.REFERENCES)]
    }

    if (docsQuery && !docsQuery.loading && docsQuery.recruitment) {
      const {
        recruitment: { documents },
      } = docsQuery

      let recruitmentDocs = documents
      if (!user.superAdmin) {
        recruitmentDocs = filter((doc) => find((c) => c.id === docsQuery.recruitment.company.id)(doc.visible))(recruitmentDocs)
      }

      const docsUploaded = recruitmentDocs.filter((doc) => doc.file && !doc.file.fileCompany)
      const docsSelected = recruitmentDocs.filter((doc) => doc.file && doc.file.fileCompany)
      const filesSelected = docsSelected.map((doc) => doc.file)
      const docsContentInt = recruitmentDocs.filter((doc) => (!!doc.content || !!doc.contentHTML) && doc.type === TemplateType.INTERVIEW)
      const docsContentRef = recruitmentDocs.filter((doc) => (!!doc.content || !!doc.contentHTML) && doc.type === TemplateType.REFERENCES)

      assign.convert({ immutable: false })(nextState, {
        documents,
        docsUploaded,
        docsSelected,
        filesSelected,
        docsContentInt,
        docsContentRef,
        loadingFiles: false,
        loadingCompanyFiles: false,
      })
    }
    return !isEmpty(nextState) ? nextState : null
  }

  onFilesSelected = async (files) => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map((file) => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        return
      }
      const visibleIds = this.props.user.superAdmin ? `[]` : `[{ id: "${this.state.clientId}" }]`
      const createDocuments = resultUpload.map(file => `
        ${file.data.id}: createDocument(data: {
          name: "${file.data.name}",
          file: { connect: { id: "${file.data.id}"} },
          recruitment: { connect: { id: "${this.props.recruitment.id}" }},
          visible: { connect: ${visibleIds}}
        }) {
          id
        }`
      )
      .join("\n")
      const mutation = gql`mutation CreateDocuments {
        ${createDocuments}
      }`
      const [error, result] = await to(this.props.client.mutate({ mutation }))
      if (error || !result) {
        console.error("work-documents:onFilesSelected:CreateDocuments:error", error)
      }
      else {
        this.props.docsQuery.refetch()
      }
    }
  }

  refetch = () => {
    this.props.clientQuery.refetch()
    this.props.jobbetQuery.refetch()
    this.props.docsQuery.refetch()
    this.props.refetch()
  }

  showDocument = (doc) => {
    this.setState({ editDoc: doc, openEditDocumentDialog: true })
  }

  onCompanyFilesSelected = async (files) => {
    const [selected, removed] = partition((doc) => !!find((f) => f.id === doc.file.id)(files))(this.state.docsSelected)
    const newFiles = filter((f) => !find((doc) => doc.file.id === f.id)(selected))(files)
    const visibleIds = this.props.user.superAdmin ? `[]` : `[{ id: "${this.state.clientId}" }]`
    this.setState({ openSharedDialog: false, openSharedJobbetDialog: false, loadingCompanyFiles: true })
    if (newFiles && newFiles.length) {
      const createDocuments = newFiles
        .map(
          (file) => `
            ${file.id}: createDocument(data: {
              file: { connect: { id: "${file.id}" }},
              name: "${file.name}",
              recruitment: { connect: { id: "${this.props.recruitment.id}" }},
              visible: { connect: ${visibleIds}}
            }) {
              id
            }`
        )
        .join("\n")
      const mutation = gql`mutation CreateDocuments {
        ${createDocuments}
      }`
      const [error, result] = await to(this.props.client.mutate({ mutation }))
      if (error || !result) {
        console.error("work-documents:deleteDoc:deleteFile:error", error)
      }
    }
    if (removed && removed.length) {
      const [errorRemoved] = await all(
        map((doc) => {
          this.props.deleteDocument({ variables: { id: doc.id } })
        })(removed)
      )
      if (errorRemoved) {
        console.error("work-documents:onCompanyFilesSelected:deleteDocument:errorRemoved: ", errorRemoved)
      }
    }
    this.refetch()
  }

  deleteFiles = (doc) => {
    this.setState({ loadingFiles: true }, () => {
      this.deleteDoc(doc)
    })
  }

  deleteCompanyFiles = (doc) => {
    this.setState({ loadingCompanyFiles: true }, () => {
      this.deleteDoc(doc)
    })
  }

  deleteDoc = async (doc) => {
    const confirmed = await confirm(`Vill du ta bort ${doc.name}?`)
    if (!confirmed) return
    this.setState({ openEditDocumentDialog: false, editDoc: null })
    if (doc.file && !doc.file.fileCompany) {
      const [error] = await to(this.props.deleteFile({ variables: { fileId: doc.file.id, docId: doc.id } }))
      if (error) {
        console.error("work-documents:deleteDoc:deleteFile:error", error)
        return
      }
    } else {
      const [errorRemoved] = await to(this.props.deleteDocument({ variables: { id: doc.id } }))
      if (errorRemoved) {
        console.error("work-documents:deleteDoc:deleteDocument:errorRemoved: ", errorRemoved)
        return
      }
      const { settings } = this.state
      if (settings?.templates?.selected?.interview === doc.id) {
        delete settings.templates.selected.interview
      }
      if (settings?.templates?.selected?.references === doc.id) {
        delete settings.templates.selected.references
      }
      this.setState({ settings }, this.saveSettings)
    }
    this.refetch()
  }

  createHeader = () => {
    const {
      recruitment: { title, company, recruiter, recruiterExternals },
    } = this.props
    return {
      date: format(new Date(), "yy-MM-dd"),
      title,
      company: company.name,
      recruiter: recruiter.firstName + " " + recruiter.lastName,
      external: recruiterExternals.map((e) => e.firstName + " " + e.lastName).join(", "),
    }
  }

  onCopyTemplateDoc = async (doc) => {
    const visibleIds = this.props.user.superAdmin ? [] : [{ id: this.state.clientId }]
    this.setState({
      openIntDocumentDialog: false,
      openIntDocumentJobbetDialog: false,
      openRefDocumentDialog: false,
      openRefDocumentJobbetDialog: false,
    })
    const mutation = gql`
      mutation CreateDocument($content: Json, $contentHTML: String, $recruitmentId: String, $name: String!, $type: TemplateType!, $visibleIds: [CompanyWhereUniqueInput!]!) {
        createDocument(data: { content: $content, contentHTML: $contentHTML, name: $name, type: $type, recruitment: { connect: { id: $recruitmentId } }, visible: { connect: $visibleIds } }) {
          id
          name
          type
          company {
            id
          }
          file {
            id
            name
            url
            fileCompany {
              id
            }
          }
          content
          contentHTML
          readonly
          visible {
            id
          }
        }
      }
    `
    const variables = {
      name: doc.name,
      type: doc.type,
      content: doc.content || { pagehead: this.createHeader() },
      contentHTML: doc.contentHTML,
      recruitmentId: this.props.recruitment.id,
      visibleIds: visibleIds,
    }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("work-documents:onCopyTemplateDoc:error", error)
      return null
    }
    this.refetch()
    this.onRequestModalClose()
    return result && { ...result.data.createDocument }
  }

  toggleClientDocument = async (doc) => {
    const { clientId } = this.state
    let visibleIds = map((client) => client.id)(doc.visible)
    if (find((id) => id === clientId)(visibleIds)) {
      visibleIds = filter((id) => id !== clientId)(visibleIds)
    } else {
      visibleIds.push(clientId)
    }
    const variables = { id: doc.id, visibleIds: visibleIds.map((id) => ({ id: id })) }
    const mutation = gql`
      mutation UpdateDocument($id: String!, $visibleIds: [CompanyWhereUniqueInput!]!) {
        updateDocument(where: { id: $id }, data: { visible: { set: $visibleIds } }) {
          id
        }
      }
    `
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("work-documents:toggleClientDocument:updateDocument:error", error)
    }
    else {
      this.refetch()
    }
  }

  saveSettings = async () => {
    const { settings } = this.state
    const { recruitment } = this.props
    const mutation = gql`
      mutation UpdateRecruitmentSettings($settings: Json, $recruitmentId: String) {
        updateRecruitment(where: { id: $recruitmentId }, data: { settings: $settings }) {
          id
        }
      }
    `
    const variables = { recruitmentId: recruitment.id, settings }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("work-documents:onSelectInterviewTemplate:updateRecruitment:error", error)
    }
    else {
      this.refetch()
    }
  }

  onSaveTemplateDoc = async (doc) => {
    const mutation = gql`
      mutation UpdateDocument($id: String!, $name: String, $contentHTML: String, $readonly: Boolean) {
        updateDocument(where: { id: $id }, data: { name: { set: $name }, contentHTML: { set: $contentHTML }, readonly: { set: $readonly } }) {
          id
        }
      }
    `
    const [error, result] = await to(this.props.client.mutate({ mutation, variables: { id: doc.id, name: doc.name, contentHTML: doc.contentHTML, readonly: doc.readonly } }))
    if (error || !result) {
      console.error("work-documents:onSaveTemplateDoc:updateDocument:error", error)
    }
    this.refetch()
  }

  onSelectView = (e) => {
    this.setState({ activeTab: e.currentTarget.tabIndex })
  }

  orderTemplates = () => {
    const orderType = "anpassade mallar"
    this.setState({ showConfirmOrderTemplatesModal: true, orderType })
  }

  onSelectInterviewTemplate = async (e) => {
    const { recruitment } = this.props
    const settings = recruitment?.settings ? JSON.parse(JSON.stringify(recruitment.settings)) : { templates: { selected: {} } }
    if (!settings.templates) settings.templates = { selected: {} }
    if (!settings.templates.selected) settings.templates.selected = {}
    settings.templates.selected.interview = e.currentTarget.value
    this.setState({ settings: settings }, this.saveSettings)
  }

  onSelectReferencesTemplate = async (e) => {
    const { recruitment } = this.props
    const settings = recruitment?.settings ? JSON.parse(JSON.stringify(recruitment.settings)) : { templates: { selected: {} } }
    if (!settings) settings = { templates: { selected: {} } }
    if (!settings.templates) settings.templates = { selected: {} }
    if (!settings.templates.selected) settings.templates.selected = {}
    settings.templates.selected.references = e.currentTarget.value
    this.setState({ settings }, this.saveSettings)
  }

  onPreview = (template) => {
    const title = this.props.recruitment.title
    const name = (template.type === TemplateType.INTERVIEW ? "Intervjumall " : "Referensmall ") + title
    this.setState({ previewTemplate: { ...template, name: name } })
  }

  onRequestModalClose = () => {
    this.setState({ previewTemplate: null })
  }

  onOrderMessageChange = (e) => {
    this.setState({ orderMessage: e.currentTarget.value })
  }

  dismissOrderModal = () => {
    this.setState({ showConfirmOrderModal: false, showConfirmOrderTemplatesModal: false, orderType: "", orderMessage: "" })
  }

  handleOrder = async () => {
    const { orderType, orderMessage } = this.state
    const { sendMail, user, recruitment } = this.props
    const [error] = await to(
      sendMail({
        from: "noreply@jobbet.se",
        to: process.env.REACT_APP_AD_SUPPORT,
        subject: `Beställning - ${orderType}`,
        html: getBookedServiceMailContent({ sender: user.fullName, company: recruitment.company.name, job: recruitment.title, order: orderType, orderMessage }),
        showFooter: false,
      })
    )
    if (!error) {
      await message("Din beställning har skickats!")
      this.setState({
        showConfirmOrderModal: false,
        showConfirmOrderTemplatesModal: false,
        orderType: "",
        orderMessage: "",
      })
    } else {
      // Kontakta kundtjänst.
    }
  }

  getDefaultInterviewTemplateId = () => {
    const { docsContentInt, jobbetSharedInt, companySharedInt, settings } = this.state
    if (settings?.templates?.selected?.interview) {
      return settings.templates.selected.interview
    }
    const { clientQuery, jobbetQuery, docsQuery } = this.props
    if (!docsQuery || docsQuery.loading || !jobbetQuery || jobbetQuery.loading || !clientQuery || clientQuery.loading) return null
    if (docsContentInt && docsContentInt.length) return docsContentInt[0].id
    if (companySharedInt && companySharedInt.length) return companySharedInt[0].id
    if (jobbetSharedInt && jobbetSharedInt.length) return jobbetSharedInt[0].id
  }

  getDefaultReferencesTemplateId = () => {
    const { docsContentRef, jobbetSharedRef, companySharedRef, settings } = this.state
    if (settings?.templates?.selected?.references) {
      return settings.templates.selected.references
    }
    const { clientQuery, jobbetQuery, docsQuery } = this.props
    if (!docsQuery || docsQuery.loading || !jobbetQuery || jobbetQuery.loading || !clientQuery || clientQuery.loading) return null
    if (docsContentRef && docsContentRef.length) return docsContentRef[0].id
    if (companySharedRef && companySharedRef.length) return companySharedRef[0].id
    if (jobbetSharedRef && jobbetSharedRef.length) return jobbetSharedRef[0].id
  }

  render() {
    const { docsQuery, clientQuery, jobbetQuery, user } = this.props

    if (
      docsQuery.networkStatus < 4 ||
      !docsQuery.recruitment ||
      (jobbetQuery && (jobbetQuery.networkStatus < 4 || !jobbetQuery.company)) ||
      (clientQuery && (clientQuery.networkStatus < 4 || !clientQuery.company))
    ) {
      return <Spinner />
    }

    const {
      activeTab,
      clientId,
      docsUploaded,
      docsContentInt,
      docsContentRef,
      openIntDocumentDialog,
      openRefDocumentDialog,
      openIntDocumentJobbetDialog,
      openRefDocumentJobbetDialog,
      companyTemplatesInt,
      companyTemplatesRef,
      jobbetTemplatesInt,
      jobbetTemplatesRef,
      jobbetSharedInt,
      jobbetSharedRef,
      companySharedInt,
      companySharedRef,
      previewTemplate,
    } = this.state

    const selectedInterview = this.getDefaultInterviewTemplateId()
    const selectedReferences = this.getDefaultReferencesTemplateId()

    return (
      <div id="docs-content">
        <Content>
          <OrderTemplates>
            <SecondaryInvertButton onClick={this.orderTemplates}>Beställ anpassade mallar</SecondaryInvertButton>
          </OrderTemplates>
          <Templates>
            <Tabs>
              <li tabIndex={0} onClick={this.onSelectView} className={activeTab === 0 ? "active" : ""}>
                <h3>Intervjumallar</h3>
              </li>
              <li tabIndex={1} onClick={this.onSelectView} className={activeTab === 1 ? "active" : ""}>
                <h3>Referensmallar</h3>
              </li>
              <li tabIndex={2} onClick={this.onSelectView} className={activeTab === 2 ? "active" : ""}>
                <h3>Filer för denna rekrytering</h3>
              </li>
            </Tabs>
            <Section className={activeTab === 0 ? "active" : ""}>
              <p>
                Här kan du skapa en anpassad intervjumall för just denna rekrytering.
                <br />
                Du kan utgå från Jobbet.se's grundmall alternativt skapa en egen intervjumall från grunden.
                <br />
                Sedan kommer du att kunna öppna din anpassade mall direkt på kandidatkortet under rekrytering/mallar.
              </p>
              <BaseTemplates>
                <h4>
                  <span>1.</span>Börja här om du vill skapa en anpassad mall.
                </h4>
                <p>Välj ett av alternativen nedan.</p>
                {!!jobbetSharedInt?.length && (
                  <TemplateList $shady>
                    {jobbetSharedInt.map((t) => (
                      <li key={t.id}>
                        <Link onClick={() => this.onPreview(t)}>
                          <img src={TemplateJobbetSrc} alt="Ikon för grundmall med Jobbet.se logo" title={t.name} />
                          <span data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                            {t.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </TemplateList>
                )}
                {!!companySharedInt.length && (
                  <TemplateList $shady>
                    {companySharedInt.map((t) => (
                      <li key={t.id}>
                        <Link onClick={() => this.onPreview(t)}>
                          <img src={TemplateClientSrc} alt="Ikon för företagsmall med kugghjul" title={t.name} />
                          <span data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                            {t.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </TemplateList>
                )}
                {!user.isViewer && !user.groupViewer && (
                  <TemplateList $shady>
                    <li key="custom-blank">
                      <Link onClick={() => this.setState({ openIntDocumentDialog: true })}>
                        <img src={TemplateEmptySrc} alt="Ikon för tom mall" title="Egen mall" />
                        <span data-tooltip-content="Klicka för att skapa en egen anpassad mall" data-tooltip-id="root-tooltip">
                          Egen mall
                        </span>
                      </Link>
                    </li>
                  </TemplateList>
                )}
                {user.superAdmin && jobbetTemplatesInt?.length > 0 && (
                  <TemplateList $shady>
                    <li key="custom-jobbet">
                      <Link onClick={() => this.setState({ openIntDocumentJobbetDialog: true })}>
                        <img src={TemplateEmptySrc} alt="Ikon för tom mall" title="Kopiera mall från Jobbet.se" />
                        <span data-tooltip-content="Klicka för att skapa en mall från Jobbet.se" data-tooltip-id="root-tooltip">
                          Kopiera mall från Jobbet.se
                        </span>
                      </Link>
                    </li>
                  </TemplateList>
                )}
              </BaseTemplates>
              <BaseTemplates>
                <h4>
                  <span>2.</span>Välj vilken mall som ska vara primär (förvald på kandidatkort) nedan.
                </h4>
                <p>Dessa mallar är tillgängliga på kandidatkortet. För att hantera en anpassad mall klicka på dess namn.</p>
                <ActionHeader>
                  <span>Namn</span>
                  <span>Primär</span>
                </ActionHeader>
                {!!docsContentInt.length && (
                  <ActionList>
                    {docsContentInt.map((t) => (
                      <li key={t.id} className={selectedInterview === t.id ? "active" : ""}>
                        <a className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip" onClick={() => this.showDocument(t)}>
                          {t.name}
                        </a>
                        <Radio name="select-interview-radio" value={t.id} checked={selectedInterview === t.id} onChange={this.onSelectInterviewTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
                {!!companySharedInt.length && (
                  <ActionList>
                    {companySharedInt.map((t) => (
                      <li key={t.id} className={selectedInterview === t.id ? "active" : ""}>
                        <span className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                          {t.name}
                        </span>
                        <Radio name="select-interview-radio" value={t.id} checked={selectedInterview === t.id} onChange={this.onSelectInterviewTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
                {!!jobbetSharedInt?.length && (
                  <ActionList>
                    {jobbetSharedInt.map((t) => (
                      <li key={t.id} className={selectedInterview === t.id ? "active" : ""}>
                        <span className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                          {t.name}
                        </span>
                        <Radio name="select-interview-radio" value={t.id} checked={selectedInterview === t.id} onChange={this.onSelectInterviewTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
              </BaseTemplates>
              <SecondaryButton className="mla mt5" tabIndex={1} onClick={this.onSelectView}>
                Gå vidare till referensmallar &raquo;
              </SecondaryButton>
              {user.superAdmin && jobbetTemplatesInt?.length > 0 && (
                <CreateDocument
                  type={TemplateType.INTERVIEW}
                  templates={jobbetTemplatesInt}
                  open={openIntDocumentJobbetDialog}
                  onClose={(e) => this.setState({ openIntDocumentJobbetDialog: false })}
                  onSave={this.onCopyTemplateDoc}
                />
              )}
              <CreateDocument
                type={TemplateType.INTERVIEW}
                templates={companyTemplatesInt}
                open={openIntDocumentDialog}
                onClose={(e) => this.setState({ openIntDocumentDialog: false })}
                onSave={this.onCopyTemplateDoc}
              />
            </Section>
            <Section className={activeTab === 1 ? "active" : ""}>
              <BaseTemplates>
                <p>
                  Här kan du skapa en anpassad referensmall för just denna rekrytering.
                  <br />
                  Du kan utgå från Jobbet.se's grundmall alternativt skapa en egen referensmall från grunden.
                  <br />
                  Du kommer sedan att kunna öppna din anpassade mall direkt på kandidatkortet under rekrytering/mallar.
                </p>
                <h4>
                  <span>1.</span>Börja här om du vill skapa en anpassad mall.
                </h4>
                <p>Välj ett av alternativen nedan.</p>
                {!!jobbetSharedRef?.length && (
                  <TemplateList $shady>
                    {jobbetSharedRef.map((t) => (
                      <li key={t.id}>
                        <Link onClick={() => this.onPreview(t)}>
                          <img src={TemplateJobbetSrc} alt="Ikon för grundmall med Jobbet.se logo" title={t.name} />
                          <span data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                            {t.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </TemplateList>
                )}
                {!!companySharedRef?.length && (
                  <TemplateList $shady>
                    {companySharedRef.map((t) => (
                      <li key={t.id}>
                        <Link onClick={() => this.onPreview(t)}>
                          <img src={TemplateClientSrc} alt="Ikon för företagsmall med kugghjul" title={t.name} />
                          <span data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                            {t.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </TemplateList>
                )}
                {!user.isViewer && !user.groupViewer && (
                  <TemplateList $shady>
                    <li key="custom-blank">
                      <Link onClick={() => this.setState({ openRefDocumentDialog: true })}>
                        <img src={TemplateEmptySrc} alt="Ikon för tom mall" title="Egen mall" />
                        <span data-tooltip-content="Klicka för att skapa en egen anpassad mall" data-tooltip-id="root-tooltip">
                          Egen mall
                        </span>
                      </Link>
                    </li>
                  </TemplateList>
                )}
                {user.superAdmin && jobbetTemplatesRef?.length > 0 && (
                  <TemplateList $shady>
                    <li key="custom-jobbet">
                      <Link onClick={() => this.setState({ openRefDocumentJobbetDialog: true })}>
                        <img src={TemplateEmptySrc} alt="Ikon för tom mall" title="Kopiera mall från Jobbet.se" />
                        <span data-tooltip-content="Klicka för att skapa en mall från Jobbet.se" data-tooltip-id="root-tooltip">
                          Kopiera mall från Jobbet.se
                        </span>
                      </Link>
                    </li>
                  </TemplateList>
                )}
              </BaseTemplates>
              <BaseTemplates>
                <h4>
                  <span>2.</span>Välj vilken mall som ska vara primär (förvald på kandidatkort) nedan.
                </h4>
                <p>Dessa mallar är tillgängliga på kandidatkortet. För att hantera en anpassad mall klicka på dess namn.</p>
                <ActionHeader>
                  <span>Namn</span>
                  <span>Primär</span>
                </ActionHeader>
                {!!docsContentRef?.length && (
                  <ActionList>
                    {docsContentRef.map((t) => (
                      <li key={t.id} className={selectedReferences === t.id ? "active" : ""}>
                        <a className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip" onClick={() => this.showDocument(t)}>
                          {t.name}
                        </a>
                        <Radio name="select-references-radio" value={t.id} checked={selectedReferences === t.id} onChange={this.onSelectReferencesTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
                {!!companySharedRef?.length && (
                  <ActionList>
                    {companySharedRef.map((t) => (
                      <li key={t.id} className={selectedReferences === t.id ? "active" : ""}>
                        <span className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                          {t.name}
                        </span>
                        <Radio name="select-references-radio" value={t.id} checked={selectedReferences === t.id} onChange={this.onSelectReferencesTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
                {!!jobbetSharedRef.length && (
                  <ActionList>
                    {jobbetSharedRef.map((t) => (
                      <li key={t.id} className={selectedReferences === t.id ? "active" : ""}>
                        <span className="name" id={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                          {t.name}
                        </span>
                        <Radio name="select-references-radio" value={t.id} checked={selectedReferences === t.id} onChange={this.onSelectReferencesTemplate} />
                      </li>
                    ))}
                  </ActionList>
                )}
              </BaseTemplates>
              <SecondaryButton className="mla mt5" tabIndex={2} onClick={this.onSelectView}>
                Gå vidare till filer &raquo;
              </SecondaryButton>
              {user.superAdmin && jobbetTemplatesRef?.length > 0 && (
                <CreateDocument
                  type={TemplateType.REFERENCES}
                  templates={jobbetTemplatesRef}
                  open={openRefDocumentJobbetDialog}
                  onClose={(e) => this.setState({ openRefDocumentJobbetDialog: false })}
                  onSave={this.onCopyTemplateDoc}
                />
              )}
              <CreateDocument
                type={TemplateType.REFERENCES}
                templates={companyTemplatesRef}
                open={openRefDocumentDialog}
                onClose={(e) => this.setState({ openRefDocumentDialog: false })}
                onSave={this.onCopyTemplateDoc}
              />
            </Section>
            <Section className={activeTab === 2 ? "active" : ""}>
              <p>Här kan man ladda upp filer som kopplas till denna rekrytering.</p>
              <DocumentList clientId={clientId} docs={docsUploaded} toggleClientDocument={this.toggleClientDocument} deleteDoc={this.deleteDoc} />
              <Controls>
                {!user.isViewer && !user.groupViewer && <FilePicker red className="mr0 mb0" fileId={"work-document"} text={"Ladda upp filer…"} accept={AllFileTypes} onMultiple={this.onFilesSelected} />}
              </Controls>
            </Section>
          </Templates>
          <Modal center isOpen={this.state.showConfirmOrderTemplatesModal} onRequestClose={this.dismissOrderModal}>
            <ModalHeader>
              Vill du rekrytera strukturerat och professionellt?
              <br />
              Beställ anpassad intervju- och referensmall av oss!
            </ModalHeader>
            <p className="mb4">
              Intervjumallen skräddarsys efter kravprofilen i er aktuella rekrytering.
              <br />
              Skicka din förfrågan här och glöm inte att uppge dina kontaktuppgifter.
              <br />
              En rekryterare återkommer till dig inom kort. Pris från 2500 kr per rekrytering.
            </p>
            <TextArea width="500px" rows={8} placeholder="Skriv din förfrågan till oss här..." onChange={this.onOrderMessageChange} value={this.state.orderMessage} />
            <Nowrap>
              <CancelButton className="mr3" onClick={this.dismissOrderModal}>
                Avbryt
              </CancelButton>
              <PrimaryButton onClick={this.handleOrder}>Skicka</PrimaryButton>
            </Nowrap>
          </Modal>
          {previewTemplate && (
            <Modal isOpen={!!previewTemplate} onRequestClose={this.onRequestModalClose} overflow>
              <AddTemplate
                type={previewTemplate.type}
                name={previewTemplate.name}
                content={previewTemplate.contentHTML}
                onAdd={this.onCopyTemplateDoc}
                onClose={this.onRequestModalClose}
              ></AddTemplate>
            </Modal>
          )}
          {this.state.editDoc && (
            <EditDocument
              companyId={clientId}
              startEdit={this.state.startEditDoc}
              open={this.state.openEditDocumentDialog}
              document={this.state.editDoc}
              onClose={() => this.setState({ openEditDocumentDialog: false, editDoc: null, startEditDoc: false })}
              onDelete={this.deleteDoc}
              onVisible={this.toggleClientDocument}
              onSave={this.onSaveTemplateDoc}
            />
          )}
        </Content>
      </div>
    )
  }
}

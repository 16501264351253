import { gql } from '@apollo/client'

export const SIGNIN_USER_MUTATION = gql`
mutation SigninUserMutation($email: String!, $password: String!) {
    authenticateUser(
        email: $email,
        password: $password
    ) {
        id
        token
    }
}
`
export const FORGOT_PASSWORD_MUTATION = gql`
mutation ForgotPassword($email: String!, $fromAdmin: Boolean) {
    forgotPassword(
        email: $email,
        fromAdmin: $fromAdmin
    )
}
`
export const UPDATE_POLICY = gql`
mutation UpdatePolicy(
    $id: String!,
    $policyAccepted: String
) {
    updateCompany(
        where: { id: $id },
        data: { policyAccepted: { set: $policyAccepted } }
    ) {
        id
    }
}
`
export const ACTIVATE_USER_MUTATION = gql`
mutation ActivateUser($id: String!, $sendPassword: Boolean, $policyAccepted: Boolean) {
    activateUser(id: $id, sendPassword: $sendPassword, policyAccepted: $policyAccepted)
}
`
export const DISABLE_USER_MUTATION = gql`
mutation DisableUser($id: String!, $disable: Boolean) {
    disableUser(id: $id, disable: $disable)
}
`
export const DELETE_USER_MUTATION = gql`
mutation DeleteUser($id: String!) {
    deleteUser(
        where: { id: $id }
    ) {
        id
    }
}
`
export const UPDATE_PASSWORD_MUTATION = gql`
mutation UpdatePassword($email: String!, $oldPassword: String, $newPassword: String!) {
    updateUserPassword(
        email: $email,
        oldPassword: $oldPassword,
        newPassword: $newPassword
    ) {
        message
    }
}
`
export const UPDATE_USER_PICTURE = gql`
mutation UpdateUserPicture($userId: String!, $pictureId: FileWhereUniqueInput!) {
    updateUser(
        where: { id: $userId },
        data: {
            picture: { connect: $pictureId },
        }
    ) {
        id
    }
}
`
export const UPDATE_USER_COMPANY = gql`
mutation UpdateUserCompany($userId: String!, $companyId: String!, $oldEmployerId: String!, $userRole: UserRole!) {
    updateUser(
        where: { id: $userId },
        data: {
            employer: { connect: { id: $companyId } },
            oldEmployer: { connect: { id: $oldEmployerId } }
            role: { set: $userRole }
        }
    ) {
        id
    }
}
`
export const UPDATE_USER_COMPANY_BACK = gql`
mutation UpdateUserCompany($userId: String!, $companyId: String!, $userRole: UserRole!) {
    updateUser(
        where: { id: $userId },
        data: {
            employer: { connect: { id: $companyId } },
            oldEmployer: { disconnect: true }
            role: { set: $userRole }
        }
    ) {
        id
    }
}
`
export const DELETE_USER_PICTURE = gql`
mutation DeleteUserPicture($pictureId: FileWhereUniqueInput!) {
    deleteFile(
        where: $pictureId
    ) {
        id
    }
}
`
export const UPDATE_USER_INTERVIEW_EMAIL = gql`
mutation UpdateUserInterviewEmail($userId: String!, $accept: Boolean!) {
    updateUser(
        where: { id: $userId },
        data: {
            acceptInterviewEmail: { set: $accept }
        }
    ) {
        id
        acceptInterviewEmail
    }
}
`
export const GET_OCCUPATIONAL_AREAS = gql`query GetOccupationalAreas {
    occupationalAreas(orderBy: { name: asc }) {
        id
        name
    }
}
`
export const GET_OCCUPATIONAL_GROUPS = gql`query GetOccupationalGroups {
    occupationalGroups(orderBy: { name: asc }) {
        id
        name
        occupationalArea {
            id
            name
        }
    }
}
`
export const GET_EDUCATIONS = gql`
query GetEducation {
    educations(orderBy: { level: desc }) {
        id
        name
        level
    }
}
`
export const GET_REGIONS = gql`
    query GetRegions {
        regions(orderBy: { name: asc }) {
            id
            name
        }
    }
`

export const SEND_MAIL = gql`
mutation SendMail($from: String!, $to: String!, $cc: String, $bcc: String, $subject: String, $text: String, $html: String, $group: Boolean, $showLogo: Boolean, $showFooter: Boolean) {
    sendMail(from: $from, to: $to, cc: $cc, bcc: $bcc, subject: $subject, text: $text, html: $html, group: $group, showLogo: $showLogo, showFooter: $showFooter) {
        message
    }
}
`
export const USER_ROLE_QUERY = gql`
query getUser($id: String!) {
    user(where: { id: $id }) {
        id
        active
        role
        policyCompany {
            id
            policyAccepted
        }
    }
}
`
export const CLEANUP_FILES = gql`
mutation CleanupFiles {
    cleanupFiles
}
`
export const SYSTEM_SETTINGS = gql`
query GetSystem {
    systems(take: 1) {
        id
        panic
    }
}
`
export const PANIC_MODE = gql`
mutation PanicMode($id: String!, $panic: Boolean!) {
    updateSystem(where: { id: $id }, data: { panic: { set: $panic } }) {
        id
        panic
    }
}
`
export const REGISTER_FILE = gql`
mutation RegisterFile($id: String!, $name: String!, $type: String!, $size: Int!) {
  registerFile(id: $id, name: $name, type: $type, size: $size) {
    id
    url
    name
  }
}
`
export const SIGNED_URL = gql`
query GetSignedUrl($filename: String!, $filetype: String!) {
  signedUrl(filename: $filename, filetype: $filetype)
}
`
export const USER_INFO = gql`
query GetUserInfo($id: String!) {
    user(where: { id: $id }) {
        id
        info
    }
}
`
export const UPDATE_USER_INFO = gql`
mutation UpdateUserInfo($id: String!, $userInfo: Json!) {
    updateUserInfo(id: $id userInfo: $userInfo)
}
`
export const CHECK_USER = gql`
query User($email: String!) {
    user(where: { email: $email }) {
        id
        active
        candidate {
            id
        }
        employer {
            id
        }
    }
}
`

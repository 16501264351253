import React from 'react'
import { isEmpty, isEqual } from 'lodash/fp'
import draftToHtml from 'draftjs-to-html';
import confirm from '_components/confirm'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import PreviewTemplate from './preview-template'
import { PrimaryButton, SecondaryButton, CancelButton, DeleteButton } from '_layout/buttons'

export default class BrowseTemplates extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            close: false,
            name: props.selected ? props.selected.name : '',
            contentHTML: props.selected ? props.selected.contentHTML || BrowseTemplates.getHtmlContent(props.selected.content) : null,
            selected: props.selected,
            selectedDefault: props.selected,
            templates: props.templates, 
            isDirty: false,
        }
    }

    static getHtmlContent = content => {
        return draftToHtml(content)
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const nextState = {}
        if (prevState.selectedDefault !== nextProps.selected) {
            nextState.selected = nextProps.selected
            nextState.selectedDefault = nextProps.selected
            if (nextState.selected) {
                nextState.name = nextState.selected.name
                nextState.contentHTML = nextProps.contentHTML || BrowseTemplates.getHtmlContent(nextProps.selected.content)
            }
        }
        if (!isEqual(prevState.templates)(nextProps.templates)) {
            nextState.templates = nextProps.templates
        }
        if (nextProps.close !== prevState.close) {
            nextState.close = nextProps.close
        }
        return isEmpty(nextState) ? null : nextState
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.state.close) {
          this.onClose()
        }
    }

    onNameChange = e => {
        this.setState({name: e.target.value, isDirty: true})
    }

    onContentChange = contentHTML => {
      this.setState({ contentHTML, isDirty: true })
    }

    onSelected = async template => {
        const { selected, isDirty } = this.state
        if (selected && (!template || template.id !== selected.id) && isDirty) {
            const confirmed = await confirm('Vill du byta mall utan att spara eventuella ändringar?')
            if (!confirmed) return false
        }
        if (template) {
            const contentHTML = template.contentHTML || BrowseTemplates.getHtmlContent(template.content)
            this.setState({name: template.name || '', contentHTML: contentHTML, selected: template, isDirty: false})
        }
        else {
            this.setState({name: '', contentHTML: null, selected: null, isDirty: false})
        }
        return true
    }

    onDelete = async e => {
        if (!this.state.selected) return
        this.props.onDelete(this.state.selected)
    }

    onClose = async () => {
      const { isDirty, preview } = this.state
      if (preview) {
        this.props.onCancel()
        this.onClosePreview()
      }
      else {
        if (isDirty && await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
          this.onSave()
        }
        this.props.onClose && this.props.onClose()
      }
    }

    onPreview = () => {
      this.setState({ preview: true })
    }

    onClosePreview = () => {
      this.setState({ preview: false })
    }

    onSave = async () => {
        if (!this.state.selected) return
        if (this.state.selected.shared) {
          const confirmed = await confirm('Ändringar i delad mall kommer att gälla alla rekryteringar. Vill du spara ändå?')
          if (!confirmed) return
        }
        this.setState({isDirty: false}, () => {
            this.props.onSave({...this.state.selected, name: this.state.name, contentHTML: this.state.contentHTML})
        })
    }

    onSaveAs = e => {
      if (!this.state.selected) return
      this.setState({ isDirty: false }, () => {
          this.props.onSaveAs({...this.state.selected, name: this.state.name, contentHTML: this.state.contentHTML})
      })
    }

    render() {
        const { name, contentHTML, selected, templates, isDirty, preview } = this.state

        if (preview) {
          return <PreviewTemplate template={{...selected, contentHTML}} external='Namn' onClose={this.onClosePreview} />
        }

        return (
          <div>
            <h3>{this.props.title}</h3>
            <ObjectPicker
              form={'true'}
              className='mb3'
              values={templates}
              placeholder='Välj mall...' 
              current={selected}
              onSelected={this.onSelected}
              noMatch='Inga träffar' />
            <input 
              type='text'
              className='mb3 w-100'
              placeholder={'Spara mall som...'}
              value={name}
              onChange={this.onNameChange} />
            <TinyEditor
              key={selected && selected.id}
              form
              placeholder='Skriv annonstext här...'
              insert='h3 bold italic underline | bullist numlist | link table'
              selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
              plugins='autolink link lists image table'
              content={contentHTML}
              onChange={this.onContentChange}
              onDirty={this.props.onDirty}
              heading
              style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(94vh - 280px)', minHeight: 300 }} />
            <div className="tr">
              <CancelButton className='mr3' onClick={this.onClose}>Stäng</CancelButton>
              {selected &&
              <React.Fragment>
                <DeleteButton className='mr3' onClick={this.onDelete} disabled={!templates.length}>Radera</DeleteButton>
                <SecondaryButton className='mr3' onClick={this.onPreview}>Granska</SecondaryButton>
                <SecondaryButton className='mr3' onClick={this.onSaveAs} disabled={name.trim() === selected.name.trim()}>Spara kopia</SecondaryButton>
                <PrimaryButton onClick={this.onSave} disabled={!isDirty}>Spara</PrimaryButton>
              </React.Fragment>
              }
            </div>
          </div>
        )
    }
}
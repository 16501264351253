import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

const ADMIN_SEND_COMPANY_INFO = gql`
mutation MailCompanyMutation(
    $to: [String!]!,
    $from: String!,
    $subject: String!,
    $html: String!
) {
    emailCompany(
        to: $to,
        from: $from,
        subject: $subject,
        html: $html
    ) {
        message
    }
}
`

const withEmailCompanyMutation = graphql(ADMIN_SEND_COMPANY_INFO, {
    props: ({ ownProps, mutate }) => ({
        emailCompany: ({ to, from, subject, html }) => {
            return mutate({
                variables: {
                    to,
                    from,
                    subject,
                    html
                }
            })
        }
    })
})

export default withEmailCompanyMutation
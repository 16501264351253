import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import user from './reducers/user-reducer'
import menu from './reducers/menu-reducer'

export const history = createBrowserHistory()

const store = configureStore({
  reducer: combineReducers({
    user,
    menu
  }),
  preloadedState: {},
})

export default store

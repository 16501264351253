import React from 'react'
import styled from 'styled-components'
import { Editor } from '@tinymce/tinymce-react'
import { EditIcon } from '_layout/form-elements'

//#region Styles
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-width: 200px;
    font-size: ${props => props.$fontSize || '1em'};
    margin-bottom: 20px;
    padding: ${props => props.readOnly ? '0' : '10px 20px 10px 10px'};
    border: 1px solid ${props => props.readOnly ? 'transparent' : 'var(--color-line)'};
    background: var(--color-bg-white);

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    .mce-content-body {
        outline: none;
        padding: 0;
    }

    .mce-edit-focus {
        outline: none !important;
    }

    .mce-tinymce {
        position: absolute;
        bottom: 100%;
        top: initial !important;
        left: -1px !important;
        z-index: 10000;
    }
`
//#endregion

export default class IngressEditor extends React.Component {
    constructor(props) {
      super(props)

      const config = {
        language: 'sv_SE',
        language_url : '/langs/sv_SE.js',
        inline: true,
        toolbar: false,
        menubar: false,
        placeholder: (!props.readOnly && props.placeholder) || 'Ingress',
        // setup: editor => { editor.on('keydown', e => { if (e.keyCode === 13) { e.preventDefault() } }) },
      }

      this.state = {
        config,
        readOnly: props.readOnly,
        ingress: props.text
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.readOnly !== prevState.readOnly) {
            return { readOnly: nextProps.readOnly }
        }
        return null
    }

    componentDidMount = () => {
        if (this.props.readOnly && this.editor && this.editor.editor) this.editor.editor.setMode('readonly')
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.editor && this.editor.editor && this.props.readOnly !== prevState.readOnly) {
            this.editor.editor.setMode(this.props.readOnly ? 'readonly' : null)
        }
    }

    focusEditor = () => {
        this.editor && this.editor.editor && this.editor.editor.focus()
    }

    onFocus = focus => {
        this.setState({focus: focus})
    }

    handleEditorChange = (_, editor) => {
      const ingress = editor.getContent({format : 'text'})
      this.props.onChange && this.props.onChange(ingress)
    }

    render() {

      const { config, ingress, focus } = this.state
      const { className, readOnly } = this.props
      const onFocus = this.onFocus.bind(this)

      if (!config.init_instance_callback) {
        config.init_instance_callback = function (editor) {
          editor.on('focus', () => onFocus(true))
          editor.on('blur', () => onFocus(false))
        }
      }

      return (
        <Wrapper className={`${focus ? 'ingress-editor focus' : 'ingress-editor'} ${className}`} readOnly={this.props.readOnly} $fontSize={this.props.fontSize}>
          {this.props.icon &&
          <EditIcon onClick={this.focusEditor} title='Klicka för att redigera' />
          }
          <Editor
            tinymceScriptSrc='/tinymce/tinymce.min.js'
            ref={ref => this.editor = ref}
            init={config}
            initialValue={ingress}
            readOnly={readOnly}
            onEditorChange={this.handleEditorChange} />
        </Wrapper>
      )
    }
}

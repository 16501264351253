import React from "react"
import { Link } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { isEmpty, flatten } from "lodash/fp"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import confirm from "_components/confirm"
import message from "_components/message"
import * as url from "_routes/url-names"
import MainLayout from "_layout/main-layout"
import TrashIcon from "_images/trash.svg"
import { FlexRow, FlexCol, Spinner, UserPicker } from "_layout/form-elements"
import { PrimaryLinkButton, MiniButton, PrimaryButton } from "_root/layout/buttons"
import { FORGOT_PASSWORD_MUTATION, DELETE_USER_MUTATION, UPDATE_USER_COMPANY, UPDATE_USER_COMPANY_BACK, DISABLE_USER_MUTATION } from "_root/common-ql"
import inject from "_services/inject"
import to from "_services/await.to"
import all from "_services/await.all"
import { UserRoles, symbols } from "_root/constants"

//#region Styles
const UserList = styled.ul`
  width: 100%;
  margin: 20px 0;
  padding: 0;
  list-style-type: none;

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 36px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-line-light);

    span {
      margin-left: 1em;
      color: var(--color-text);
    }

    button {
      display: inline-block;
      margin-left: 10px;
      visibility: hidden;

      &.right {
        margin-left: auto;
      }
    }

    &:hover {
      button {
        visibility: visible;
      }
    }
  }
`
const Trash = styled.a`
  position: absolute;
  right: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const UserLink = styled(({ disabledUser, activeUser, ...rest }) => <Link {...rest} />)`
  color: ${props => (props.activeUser && !props.disabledUser ? null : "var(--color-text-dark)")};
  text-decoration: ${props => (props.disabled ? "line-through" : null)};
`
const MailSymbol = styled.img`
  margin-left: 1em;
  width: 20px;
`
//#endregion Styles

const COMPANY_QUERY = gql`
  query CompanyQuery($urlName: String!) {
    company(where: { urlName: $urlName }) {
      id
      name
      urlName
      group
      employees(orderBy: { firstName: { sort: asc } }) {
        id
        active
        disabled
        firstName
        lastName
        email
        info
        role
        employerId
        oldEmployer {
          id
          name
        }
        policyCompany {
          id
        }
        recruitments {
          id
        }
      }
      groups {
        id
        name
        employees(orderBy: { firstName: { sort: asc } }) {
          id
          firstName
          lastName
          email
          role
          employer {
            id
            name
          }
          oldEmployer {
            id
            name
          }
        }
      }
      groupies {
        employees(orderBy: { firstName: { sort: asc } }) {
          id
          firstName
          lastName
          email
          role
          employer {
            id
          }
          policyCompany {
            id
          }
        }
      }
    }
  }
`
const SEND_ACTIVATION_MAIL = gql`
  mutation SendActivationEmail($email: String!, $companyName: String!) {
    sendActivationEmail(email: $email, companyName: $companyName)
  }
`

@compose(
  withRouter,
  inject("user"),
  graphql(COMPANY_QUERY, { name: "companyQuery", options: props => ({ variables: { urlName: props.match.params.company } }) }),
  graphql(SEND_ACTIVATION_MAIL, { name: "sendActivationEmail" }),
  graphql(FORGOT_PASSWORD_MUTATION, { name: "forgotPassword" }),
  graphql(DISABLE_USER_MUTATION, { name: "disableUser" }),
  graphql(UPDATE_USER_COMPANY, { name: "updateUserCompany" }),
  graphql(UPDATE_USER_COMPANY_BACK, { name: "updateUserCompanyBack" }),
  graphql(DELETE_USER_MUTATION, { name: "deleteUser" })
)
export default class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  initialState = {
    employees: [],
    groupies: [],
    selectedGroupies: [],
  }

  static getDerivedStateFromProps(nextProps) {
    const nextState = {}
    const { companyQuery } = nextProps
    if (!companyQuery.loading && companyQuery.company) {
      const { employees } = companyQuery.company
      nextState.employees = employees && employees.length ? [...employees] : []
      nextState.employees.sort((a, b) => a.firstName.localeCompare(b.firstName))
      nextState.company = companyQuery.company
      nextState.groups = companyQuery.company.groups || []
      nextState.groupies = flatten(companyQuery.company.groupies.map(g => g.employees)).filter(g => !g.policyCompany)
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidMount = () => {
    this.props.companyQuery.refetch()
  }

  sendLoginMail = async email => {
    const [error, result] = await to(this.props.forgotPassword({ variables: { email, fromAdmin: true } }))
    if (error || !result.data.forgotPassword) {
      message("Ett fel uppstod när lösenord skulle skickas ut.")
    } else {
      this.props.companyQuery.refetch()
      message("Ett nytt lösenord har skickats till " + email)
    }
  }

  sendActivationMail = async (email, companyName) => {
    const [error, result] = await to(this.props.sendActivationEmail({ variables: { email, companyName } }))
    if (error || !result) {
      console.error("users:sendActivationMail:error:", error, email)
      return
    }
    this.props.companyQuery.refetch()
    message("Ett mejl om aktivering har skickats till " + email)
  }

  disableUser = async (id, disable) => {
    const [error, result] = await to(this.props.disableUser({ variables: { id, disable } }))
    if (error || !result) {
      console.error("users:disableUser:error:", error, id, disable)
      return
    }
    this.props.companyQuery.refetch()
    message("Användaren har " + (disable ? " inaktiverats!" : " aktiverats!"))
  }

  deleteUser = async user => {
    const name = `${user.firstName} ${user.lastName}`
    const confirmed = await confirm(`Vill du ta bort ${name}?`)
    if (!confirmed) return
    const [error, result] = await to(this.props.deleteUser({ variables: { id: user.id } }))
    if (error || !result.data.deleteUser) {
      console.error("users:deleteUser:error: ", error, result)
      message("Användaren kan inte raderas. Kontrollera så att inte användaren är kopplad till en rekrytering el. dyl.")
    } else {
      message(`Användaren (${name}) har raderats.`)
      this.props.companyQuery.refetch()
    }
  }

  selectedGroupies = selected => {
    const selectedGroupies = JSON.parse(JSON.stringify(selected))
    selectedGroupies.forEach(s => {
      switch (s.role) {
        case UserRoles.ADMINISTRATOR:
          s.role = UserRoles.GROUPADMIN
          break
        case UserRoles.RECRUITER:
          s.role = UserRoles.GROUPRECRUITER
          break
        case UserRoles.VIEWER:
          s.role = UserRoles.GROUPVIEWER
          break
        default:
          break
      }
    })
    this.setState({ selectedGroupies })
  }

  migrateEmployees = async () => {
    const confirmed = await confirm("Är du säker på att du vill flytta valda användare till denna grupp", { confirmText: "Flytta" })
    if (confirmed) {
      const companyId = this.state.company.id
      const mutations = this.state.selectedGroupies.map(g => this.props.updateUserCompany({ variables: { userId: g.id, oldEmployerId: g.employer.id, companyId, userRole: g.role } }))
      const [error, result] = await all(mutations)
      if (error || !result) {
        console.error("users:updateUserCompany:error:", error)
        return
      }
      this.selectedGroupies([])
      this.props.companyQuery.refetch()
      message("Valda användare har flyttats!")
    }
  }

  migrateBack = async e => {
    if (e.currentTarget.dataset && e.currentTarget.dataset.employee) {
      const employeeId = e.currentTarget.dataset.employee
      const confirmed = await confirm("Är du säker på att du vill flytta tillbaka vald användare?", { confirmText: "Flytta" })
      if (confirmed) {
        const employee = this.state.employees.find(e => e.id === employeeId)
        const companyId = employee.oldEmployer.id
        let role = UserRoles.VIEWER
        switch (employee.role) {
          case UserRoles.GROUPADMIN:
            role = UserRoles.ADMINISTRATOR
            break
          case UserRoles.GROUPRECRUITER:
            role = UserRoles.RECRUITER
            break
          default:
        }
        const [error, result] = await to(this.props.updateUserCompanyBack({ variables: { userId: employee.id, companyId, userRole: role } }))
        if (error || !result) {
          console.error("users:migrateBack:error:", error)
          return
        }
        this.selectedGroupies([])
        this.props.companyQuery.refetch()
        message("Vald användare har flyttats!")
      }
    }
  }

  sentMailDescription = info => {
    if (!info) return "Info saknas"
    const history = (info.passwordMailHistory || []).concat(info.activateMailHistory || [])
    return "Mejl skickat:<br/>" + history.map(h => format(parseISO(h), "yy-MM-dd HH:mm")).join("<br/>")
  }

  roleText = role => {
    switch (role) {
      case UserRoles.ADMINISTRATOR:
        return "administratör"
      case UserRoles.RECRUITER:
        return "rekryterare"
      case UserRoles.VIEWER:
        return "granskare"
      case UserRoles.GROUPADMIN:
        return "gruppadministratör"
      case UserRoles.GROUPRECRUITER:
        return "grupprekryterare"
      case UserRoles.GROUPVIEWER:
        return "gruppgranskare"
      default:
        return ""
    }
  }

  render() {
    const { user } = this.props
    const { employees, company, groups, groupies, selectedGroupies } = this.state
    const editUrlPrefix = "/" + this.props.match.params.company + url.INSTALLNINGAR + url.INST_USERS + "/"
    const content = this.props.companyQuery.loading ? (
      <Spinner />
    ) : employees.length > 0 ? (
      <React.Fragment>
        <h3 className="mt4 mb2">Företagets användare</h3>
        <p>Klicka på en användares namn för att redigera dess uppgifter.</p>
        <UserList>
          {employees.map(employee => (
            <li key={employee.id}>
              <UserLink disabledUser={employee.disabled} activeUser={employee.active} to={editUrlPrefix + employee.id}>
                {`${employee.firstName} ${employee.lastName}`}
              </UserLink>
              {employee.oldEmployer && (
                <span>
                  {" "}
                  (
                  <a onClick={this.migrateBack} data-employee={employee.id} data-tooltip-id="root-tooltip" data-tooltip-content="Klicka här för att flytta tillbaka till företag">
                    {employee.oldEmployer.name}
                  </a>
                  )
                </span>
              )}
              <span>{this.roleText(employee.role)}</span>
              {employee.disabled && <span> (inaktiverad)</span>}
              {employee.active || <span> (ej loggat in)</span>}
              {employee.policyCompany && <span> Huvudansvarig</span>}
              {employee.disabled || (
                <React.Fragment>
                  {employee.active ? (
                    <MiniButton className="right" onClick={e => this.sendLoginMail(employee.email)}>
                      Skicka inloggningsuppgifter
                    </MiniButton>
                  ) : (
                    <MiniButton className="right" onClick={e => this.sendActivationMail(employee.email, company.name)}>
                      Skicka aktiveringsmejl
                    </MiniButton>
                  )}
                </React.Fragment>
              )}
              {employee.policyCompany === null && employee.id !== this.props.user.id && (
                <React.Fragment>
                  {employee.disabled ? (
                    <MiniButton className="right" onClick={e => this.disableUser(employee.id, false)}>
                      Aktivera
                    </MiniButton>
                  ) : (
                    <MiniButton onClick={e => this.disableUser(employee.id, true)}>Inaktivera</MiniButton>
                  )}
                </React.Fragment>
              )}
              {employee.info && <MailSymbol src={symbols["message"]} className="mail-sent" alt="" data-tooltip-id="root-tooltip" data-tooltip-html={this.sentMailDescription(employee.info)} />}
              {employee.policyCompany === null && employee.id !== this.props.user.id && employee.recruitments.length <= 0 && (
                <Trash data-tooltip-id="root-tooltip" data-tooltip-content="Ta bort användare som inte är kopplad till en rekrytering." onClick={e => this.deleteUser(employee)} />
              )}
            </li>
          ))}
        </UserList>
        {groups.length > 0 &&
          groups.map(group => (
            <React.Fragment key={group.id}>
              <h3 className="mt4 mb2">{group.name}</h3>
              <UserList>
                {group.employees.map(e => (
                  <li key={e.id}>
                    {`${e.firstName} ${e.lastName}`}
                    {e.oldEmployer && ` (från ${e.oldEmployer.name})`}
                  </li>
                ))}
              </UserList>
            </React.Fragment>
          ))}
      </React.Fragment>
    ) : (
      <p>Företaget saknar användare</p>
    )

    return (
      <MainLayout padding scroll>
        <h2>Hantera användare</h2>
        <FlexRow>
          <FlexCol $full>
            <PrimaryLinkButton to={window.location.pathname + url.INST_NY}>Ny användare</PrimaryLinkButton>
            {content}
          </FlexCol>
        </FlexRow>
        {user.superAdmin && company && company.group && groupies.length > 0 && (
          <FlexRow className="mt4">
            <FlexCol $full>
              <h3>Flytta användare från kopplat företag</h3>
              <UserPicker
                className="mr3"
                users={groupies}
                placeholder="Välj användare..."
                selected={selectedGroupies}
                onSelected={this.selectedGroupies}
                selectedText="användare vald(a)"
                noSelected="Inga användare valda"
                noUsers="Inga valbara användare"
              />
              <PrimaryButton disabled={!selectedGroupies.length} onClick={this.migrateEmployees}>
                Flytta användare
              </PrimaryButton>
            </FlexCol>
          </FlexRow>
        )}
      </MainLayout>
    )
  }
}

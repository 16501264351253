import { graphql } from "@apollo/client/react/hoc"

import { SEND_MAIL } from "_root/common-ql"

export default graphql(SEND_MAIL, {
  props: ({ ownProps, mutate }) => ({
    sendMail: ({ from, to, cc, bcc, subject, text, html, group, showLogo, showFooter }) => {
      return mutate({
        variables: { from, to, cc, bcc, subject, text, html, group, showLogo: showLogo !== false, showFooter },
      })
    },
  }),
})

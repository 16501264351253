import React from 'react'
import styled from 'styled-components'
import {
    isEmpty,
    uniq,
    find,
    remove
} from 'lodash/fp'
import { AnswerType } from '_root/constants'
import { Label as LabelStyle, Checkbox } from '_layout/form-elements'

//#region Styles
const QuestionText = styled.h4`
  font-weight: 400;
`
const Label = styled(LabelStyle)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 400;

  > span {
    margin-right: 15px;
  }
`
const AnswerArea = styled.div`
    margin-bottom: 30px;
`
//#endregion

export default class FilterQuestion extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        question: props.question,
        selectedAnswers: props.selected || []
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}
        if (nextProps.question !== prevState.question) {
            nextState.question = nextProps.question
        }
        return !isEmpty(nextState) ? nextState : null
    }

    onAnswer = e => {
        e.persist()

        let { selectedAnswers } = this.state

        if (e.target.checked) {
            if (!find(s => s === e.target.value)(selectedAnswers)) {
                selectedAnswers.push(e.target.value)
            }
        }
        else {
            selectedAnswers = remove(s => s === e.target.value)(selectedAnswers)
        }

        this.setState({ selectedAnswers }, () => {
            this.props.onChange(selectedAnswers)
        })
    }

    render() {
        const { question, selectedAnswers } = this.state

        let answers = []
        switch(question.type) {
          case AnswerType.TEXT:
            answers = [true]
          break
          case AnswerType.BOOL:
            answers = ['ja', 'nej']
          break
          case AnswerType.GRADE:
            answers = ['1', '2', '3', '4', '5']
          break
          case AnswerType.SINGLE:
          case AnswerType.MULTIPLE:
            answers = uniq(question.answers)
            if (!answers || !answers.length) {
              return null
            }
          break
          default:
        }

        return (
          <li>
            <QuestionText>{ question.text }</QuestionText>
            <AnswerArea>
              {answers.map(answer =>
              <Label key={answer.value || answer}>
                <Checkbox name={question.id} value={answer.value || answer} onChange={this.onAnswer} checked={selectedAnswers.indexOf(answer.value || answer) > -1} /><span>{answer.value || answer}</span>
              </Label>
              )}
            </AnswerArea>
          </li>
        )
    }
}
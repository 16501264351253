import React from "react"

export default function withUpdateApolloQuery() {
  return WrappedComponent => {
    return class extends React.PureComponent {
      displayName = "UpdateApolloQuery"

      constructor(props) {
        super(props)
        this.state = {
          location: props.location,
        }
      }

      static getDerivedStateFromProps(props, state) {
        const { updateQuery, loading, results } = props.apollodata
        const resultCount = (results && results.length) || 0
        if (props.location.search !== state.location.search && resultCount > props.limit && !loading) {
          updateQuery(previousResult => {
            return {
              ...previousResult,
              results: previousResult.results.slice(0, this.props.limit),
              location: props.location,
            }
          })
        }
        return null
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}

import React from 'react'
import styled from 'styled-components'
import { TextArea } from '_layout/form-elements'

import otherChannelsLogo from '_images/other-channels.png'

const Channel = styled.div`
    min-width: 304px;
    background: var(--color-bg-white);
    border-bottom: 2px solid var(--color-line-dark);

    &.selected {
        background: var(--color-bg-grey);
        
        button {
            background: var(--color-bg-dark);
        }
    }
`

const ChannelHeader = styled.div`
    position: relative;
    height: 40px;
    background-color: var(--color-bg-lightest);

    .publishchannel__logo {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        left: 20px
    }
`

const ChannelContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 24px;

  .channel__title {
    font-weight: bold;
    margin-bottom: 14px;
    text-align: center;
  }

  textarea {
    width: 100%;
  }
`

export default class PublishOther extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            text: this.props.text,
            selected: !!this.props.text
        }
    }

    onChange = e => {
        const text = e.target.value
        this.setState({text: text, selected: !!text})
        this.props.onChange && this.props.onChange(text)
    }

    render() {
        return (
            <Channel className={this.props.className}>
                <ChannelHeader>
                    <img className="publishchannel__logo" src={otherChannelsLogo} alt="Loggo för publiceringskanal - Övriga kanaler" />
                </ChannelHeader>
                <ChannelContent>
                    <header className="channel__title">Övriga annonskanaler</header>
                    <TextArea rows='5' disabled={this.props.readOnly} placeholder='Beskriv dina önskemål så kontaktar vi dig med förslag och offert.' onChange={this.onChange} defaultValue={this.props.text}></TextArea>
                </ChannelContent>
            </Channel>
        )
    }
}

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'

import inject from '_services/inject'
import { SEARCH_CANDIDATES } from './candidate-ql'
import SimpleCandidateResults from './simple-results';
import { Spinner } from '_layout/form-elements';
import {
    ResultGroupHeader,
    ResultWrapper
} from '_modules/search/common-layout/result-lists';

@compose(
    withRouter,
    inject('user'),
    graphql(SEARCH_CANDIDATES, { name: 'candidatesQuery', options: props => ({ variables: { take: props.limit, query: props.queryInput } }) }),
)
export default class SimpleCandidatesResultGroup extends React.PureComponent {
    displayName = "CandidatesResultGroup"

    render() {
        const { queryInput, onClose } = this.props
        const { searchCandidates, loading } = this.props.candidatesQuery
        const { total = 0, candidates = [] } = searchCandidates || {}
        const resultProps = { queryInput, total, candidates, onClose }

        return [
            <ResultGroupHeader key="groupheader">
                Kandidater 
                { !loading && ((total > 0) ? ` - ${total} ${total === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
            </ResultGroupHeader>,
            <ResultWrapper key="groupresults">
                { loading ? <Spinner className="center" /> : <SimpleCandidateResults { ...resultProps } /> }
            </ResultWrapper>
        ]
    }
}
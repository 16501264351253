import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import inject from '_services/inject'
import { SEARCH_EMPLOYEES } from './users-ql'
import SimpleUsersResults from './simple-results'
import { Spinner } from '_layout/form-elements';
import {
    ResultGroupHeader,
    ResultWrapper
} from '_modules/search/common-layout/result-lists';

@compose(
    withRouter,
    inject('user'),
    graphql(SEARCH_EMPLOYEES, { name: 'employeeQuery', options: props => ({ variables: { query: props.queryInput, take: props.limit, skip: 0 } }) }),
)
export default class SimpleUsersResultGroup extends React.PureComponent {
    displayName = "UsersResultGroup"

    render() {
        const { user, queryInput, onClose } = this.props
        const { searchEmployees, loading } = this.props.employeeQuery
        const { total = 0, employees = [] } = searchEmployees || {}
        const resultProps = { user, queryInput, total, employees, onClose }

        return [
            <ResultGroupHeader key="groupheader">
                Användare 
                { !loading && (total ? ` - ${total === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
            </ResultGroupHeader>,
            <ResultWrapper key="groupresults">
                { loading ? <Spinner className="center" /> : <SimpleUsersResults { ...resultProps } /> }
            </ResultWrapper>
        ]
    }
}
import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import some from 'lodash/fp/some'
import orderBy from 'lodash/fp/orderBy'
import Modal from '_components/modal'
import { FlexRow, FlexCol, Spinner } from '_layout/form-elements'
import { SecondaryButton } from '_layout/buttons'
import SelectionQuestion from '_components/recruit/selection-question'
import SelectionDropzone from '_components/recruit/selection-dropzone'
import SelectionRemovezone from '_components/recruit/selection-removezone'
import { COMPANY_SELECTION_QUERY, CREATE_COMPANY_QUESTION } from '_containers/recruitment/recruitment-ql'
import ArrowSrc from '_images/arrow_right_light.svg'
import inject from '_services/inject'
import to from '_services/await.to'

//#region Styles
const Wrapper = styled.div`
    position: relative;
    display: ${props => props.$full ? 'block' : 'inline-block'};

    h3 {
        color: var(--color-text);
        text-transform: uppercase;
        text-align: left;
    }
`
const Button = styled(SecondaryButton)`
    width: ${props => props.$full ? '100%' : 'auto'};
`
const Arrow = styled.div`
    position: absolute;
    top: 250px;
    left: 50%;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    border-radius: 50%;
    background: url(${ArrowSrc}) no-repeat scroll 50% 50% var(--color-brand-green);
    background-size: 50% 50%;
    transform: translateX(-50%);
`
const LeftCol = styled(FlexCol)`
    width: 500px;
    margin-right: 40px;
    padding: 20px;
    background: var(--color-bg-lightest);
    box-shadow: 0 0 10px 0 var(--color-line-dark);
    text-align: left;
`
const RightCol = styled(FlexCol)`
    width: 500px;
    padding: 20px;
    background: var(--color-bg-white);
    box-shadow: 0 0 10px 0 var(--color-line-dark);
    text-align: left;
`
//#endregion

@compose(
    inject('user'),
    graphql(CREATE_COMPANY_QUESTION, { name: 'createQuestion' }),
    graphql(COMPANY_SELECTION_QUERY, { name: 'questionsQuery', skip: props => !props.company, options: props => ({ variables: { companyId: props.company && props.company.id } }) }),
    graphql(COMPANY_SELECTION_QUERY, { name: 'parentQuery', options: props => ({ variables: { companyId: props.parent.id } }) })
)
export default class SelectionCopy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: props.company.id,
            modalOpen: false,
            questions: [],
            selection: []
        }
    }

    filterQuestions = state => {
        const questions = filter(q => !some(s => s.text === q.text)(state.selection))(state.questions)
        return {...state, questions}
    }

    copyQuestion = async question => {
      if (!this.props.user.companyView) return
      const questionInput = {
        company: { connect: { id: this.props.user.companyView.id } },
        order: 0,
        type: question.type,
        text: question.text,
      }
      if (question.answers && question.answers.length) {
        if (question.answers[0].value) {
          questionInput.answers = { create: question.answers.map(answer => ({ value: answer.value, position: answer.position })) }
        }
        else {
          questionInput.answers = { create: question.answers.map((answer, index) => ({ value: answer, position: index })) }
        }
      }
      const [error, result] = await to(this.props.createQuestion({ variables: { questionInput } }))
        if (error || !result) {
          console.error('copyQuestion:error', error, question)
          return
        }
        this.props.questionsQuery.refetch()
    }

    onDropped = question => {
        if (question.removezone) return
        const questions = orderBy(q => q.text)(['asc'])(filter(q => q.id !== question.id)(this.state.questions))
        const selection = orderBy(q => q.text)(['asc'])([...this.state.selection, question])
        const state = {...this.state, questions, selection}
        this.setState(this.filterQuestions(state), () => this.copyQuestion(question))
    }

    onModalOpen = () => {
        this.props.questionsQuery.refetch()
        this.props.parentQuery.refetch()
        this.setState({modalOpen: true})
    }

    onModalClose = () => {
        this.props.onClose && this.props.onClose()
        this.setState({modalOpen: false})
    }

    onCreate = question => {
        this.props.parentQuery.refetch()
    }

    render() {
        const { company, button, className, full, questionsQuery, parentQuery, parent } = this.props
        const { modalOpen } = this.state

        const loading = questionsQuery.loading || parentQuery.loading

        const selection = !questionsQuery.loading && questionsQuery.company ? questionsQuery.company.questions : []
        const questions = !parentQuery.loading && parentQuery.company ? parentQuery.company.questions : []

        const questionsMarkup = modalOpen && map(question =>
            <SelectionQuestion key={question.id} id={question.id} question={question} onAdd={this.onDropped} onAddText='Kopiera' onDropped={this.onDropped} />)(questions)

        const selectionMarkup = map(question =>
            <SelectionQuestion key={question.id} id={question.id} question={question} />)(selection)

        return (
            <Wrapper $full={full}>
                {company.name !== parent.name && <Button className={className} onClick={this.onModalOpen} $full={full} loading={loading}>{button || ('Kopiera urval från ' + parent.name)}</Button> }
                <Modal isOpen={modalOpen} onRequestClose={this.onModalClose}>
                    <FlexRow $nowrap $relative>
                        <Arrow />
                        <LeftCol>
                            <h3>Urvalsfrågor {parent.name}</h3>
                            {this.props.parentQuery.loading && <Spinner/>}
                            <SelectionRemovezone companyId={parent.id} onCreate={this.onCreate}>
                                {questionsMarkup}
                            </SelectionRemovezone>
                        </LeftCol>
                        <RightCol>
                            <h3>Kopiera till {company.name}</h3>
                            {this.props.questionsQuery.loading && <Spinner/>}
                            <SelectionDropzone companyId={this.props.companyId}>
                                {selectionMarkup}
                            </SelectionDropzone>
                        </RightCol>
                    </FlexRow>
                </Modal>
            </Wrapper>
        )
    }
}

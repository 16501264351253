export const USER_SET_USER = "USER_SET_USER"
export const USER_SET_LOGGEDIN = "USER_SET_LOGGEDIN"
export const USER_SET_RELOAD = "USER_SET_RELOAD"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_DELETE = "USER_DELETE"

export const setUser = user => {
  return dispatch => {
    dispatch({
      type: USER_SET_USER,
      payload: user,
    })
  }
}

import { memoize } from 'lodash'
import {
    flow,
    join,
    map,
    sortBy
} from 'lodash/fp'

const calculateScore = ({ publicationChannels, hasOther }) => {
    let score = 0.0;

    flow(
        sortBy('sort'),
        map('title'),
        map((title) => {
            switch (title) { 
                case "Arbetsförmedlingen - genom Jobbet.se":
                    score += 0.1
                    break;
                case "Blocket Jobb":
                    score += 0.15
                    break;
                case "Linkedin":
                    score += 0.15
                    break;
                case "Facebook":
                    score += 0.1
                    break;
                case "Jobbet.se":
                    score += 0.2
                    break;
                case "Karriärsida":
                    score += 0
                    break;
                case "Search":
                    score += 0.3
                    break;
                default:
                    break;
            }
        })
    )(publicationChannels)

    if ( hasOther ) score += 0.1

    return score;
}

export const memoizedCalculateScore = memoize(calculateScore, ({ publicationChannels, hasOther }) => {
    const keyParts = []

    const pubChannelKeyPart = flow(
        sortBy('sort'),
        map('id'),
        join('_')
    )(publicationChannels)

    if ( pubChannelKeyPart ) keyParts.push(pubChannelKeyPart)

    keyParts.push(`other:${hasOther ? 't' : 'f'}`)

    return keyParts.join("_")
} );
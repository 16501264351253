import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

const MAIL_CANDIDATE_MUTATION = gql`
mutation MailCandidateMutation(
    $to: [CandidateEmailMapPayload!]!,
    $from: String!,
    $subject: String!,
    $html: String!
) {
    emailCandidate(
        to: $to,
        from: $from
        subject: $subject,
        html: $html
    ) {
        message
    }
}
`

const withEmailCandidateMutation = graphql(MAIL_CANDIDATE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        emailCandidate: ({ to, from, subject, html }) => {
            return mutate({
                variables: {
                    to,
                    from,
                    subject,
                    html
                }
            })
        }
    })
})

export default withEmailCandidateMutation
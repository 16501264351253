import React from 'react'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import { Spinner } from '_layout/form-elements'
import TrashIcon from '_images/trash.svg'
import inject from '_services/inject'

//#region Styles
const Title = styled.span`
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    min-height: 100%;
    line-height: 1.5;

    > div {
        margin-left: auto;
        img {
            margin-bottom: 0;
        }
    }
`
const Cell = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    min-height: 100%;
    border-left: 1px solid var(--color-line-light);
    line-height: 1.5;
    text-align: center;

    &.head {
        align-items: flex-end;
        padding-bottom: 5px;
    }

    input + span {
        margin-right: 0;
    }
`
const Link = styled.a`
    flex: 1 0 auto;
    text-decoration: none;
`
const Trash = styled.a`
    display: inline-block;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    background: url(${TrashIcon}) no-repeat scroll 50% 50%;
    background-size: 16px 16px;

    &:hover {
        opacity: 1;
    }
`
const List = styled.ul`
    margin: 20px 0;
    padding: 0;
    width: 100%;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid var(--color-line-light);

        &.head {
            font-weight: 700;
            align-items: flex-end;
            height: 55px;
        }
    }
`
//endregion

@inject('user')
export default class FileList extends React.Component {
    visible = visibleClients => {
        return !!find(client => client.id === this.props.clientId)(visibleClients)
    }

    render() {
        const { files, deleteFile, loading, noFilesText } = this.props

        if (!files || !files.length) {
            return ([
                <List key="1"><li className='italic'>{noFilesText || 'Inga filer tillgängliga'}</li></List>,
                ...( loading ? [<List key="2"><Spinner /></List>] : [] )
            ])
        }
        return (
        <List>
            <li className='head'>
                <Title>Titel {loading && <Spinner/>}</Title>
                {deleteFile &&
                <Cell className='head'>Ta bort</Cell>
                }
            </li>
            {map(file =>
            <li key={file.id}>
                <Link href={file.url} download>{file.name}</Link>
                {deleteFile &&
                <Cell><Trash onClick={e => deleteFile(file)}/></Cell>
                }
            </li>
            )(files)}
        </List>
        )
    }
}

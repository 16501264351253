import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { gql } from "@apollo/client"
import styled from "styled-components"
import { map, filter, orderBy, some, isEqual, isEmpty } from "lodash/fp"
import update from "immutability-helper"
import confirm from "_components/confirm"
import ArrowSrc from "_images/arrow_right_light.svg"
import { Spinner, FlexRow, FlexCol } from "_layout/form-elements"
import SelectionQuestion from "_components/recruit/selection-question"
import SelectionDropzone from "_components/recruit/selection-dropzone"
import SelectionRemovezone from "_components/recruit/selection-removezone"
import SelectionEdit from "_components/recruit/selection-edit"
import { COMPANY_SELECTION_QUERY, CREATE_RECRUITMENT_QUESTION, DELETE_QUESTION } from "_containers/recruitment/recruitment-ql"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const Arrow = styled.div`
  position: absolute;
  top: 250px;
  left: 50%;
  width: 60px;
  height: 60px;
  opacity: 0.5;
  border-radius: 50%;
  background: url(${ArrowSrc}) no-repeat scroll 50% 50% var(--color-brand-green);
  background-size: 50% 50%;
  transform: translateX(-50%);
`
const Column = styled.div`
  width: 100%;
  max-width: 500px;
  flex: 1 0 auto;
  margin: 1rem;
  padding: 40px;
  background: var(--color-bg-white);
  box-shadow: 0 0 10px 0 var(--color-line-dark);

  &.faded {
    background: var(--color-bg-bright);
  }

  h3 {
    color: var(--color-text);
    text-transform: uppercase;
    text-align: left;
  }
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(CREATE_RECRUITMENT_QUESTION, { name: "createQuestion" }),
  graphql(DELETE_QUESTION, { name: "deleteQuestion" }),
  graphql(COMPANY_SELECTION_QUERY, { name: "questionsQuery", options: props => ({ variables: { companyId: props.companyId }, fetchPolicy: "network-only" }) })
)
export default class Selection extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      companyId: props.companyId,
      selectionDirty: false,
      validVersion: props.version,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.version && prevState.validVersion !== nextProps.version) {
      nextState.selection = null
      nextState.validVersion = nextProps.version
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidUpdate = () => {
    this.sortAndFilterQuestions()
  }

  sortAndFilterQuestions = async () => {
    const {
      onChange,
      questionsQuery: { company, loading },
    } = this.props
    let { selection, companySelection, selectionDirty } = this.state
    const prevSelectionIds = selection ? map((q) => q.id)(selection) : null
    const prevQuestionIds = companySelection ? map((q) => q.id)(companySelection) : null
    if (!selection) {
      if (this.props.recruitment) {
        selection = orderBy((q) => q.order)(["asc"])(this.props.recruitment.selection)
      }
    }
    if (selection) {
      if (!loading && company) {
        companySelection = filter((q) => !some((s) => s.text === q.text)(selection))(company.questions)
      }
      let index = 0
      const currentSelection = map((q) => {
        return { ...q, currentOrder: index++ }
      })(selection)
      const unsortedSelection = filter((question) => question.currentOrder !== question.order)(currentSelection)
      if (unsortedSelection.length > 0) {
        const updateQuestions = map((question) => {
          question.order = question.currentOrder
          return `
                    ${question.id}: updateQuestion(where: { id: "${question.id}" }, data: { order: { set: ${question.order} } }) {
                        id
                    }
                    `
        })(unsortedSelection).join("\n")
        const mutation = gql`mutation UpdateQuestions {
                    ${updateQuestions + ""}
                }`
        const [error, result] = await to(this.props.client.mutate({ mutation }))
        if (error || !result) {
          // console.error('selection:onSortQuestions:error', error) // just pretend nothing happened
        } else {
          const newSelection = map((q) => {
            return { ...q, order: q.currentOrder }
          })(currentSelection)
          this.setState({ selection: newSelection })
        }
      }
    }
    const nextState = {}

    if (selectionDirty) nextState.selectionDirty = false

    const selectionIds = selection ? map((q) => q.id)(selection) : []
    if (!isEqual(prevSelectionIds, selectionIds)) {
      nextState.selection = selection
    }
    const questionIds = companySelection ? map((q) => q.id)(companySelection) : []
    if (!isEqual(prevQuestionIds, questionIds)) {
      nextState.companySelection = companySelection
    }

    if (!isEmpty(nextState)) {
      this.setState(nextState, () => {
        selectionDirty && onChange && onChange({ selectionIds })
      })
    }
  }

  onCreateCompanyQuestion = () => {
    this.props.questionsQuery.refetch()
  }

  onCreateRecruitmentQuestion = (question) => {
    const selection = orderBy((q) => q.order)(["asc"])([...this.state.selection, question])
    this.setState({ selection, selectionDirty: true })
  }

  onCopiedQuestion = async (question) => {
    const { selection } = this.state
    const order = selection && selection.length ? Math.max(...map((q) => q.order)(selection)) + 1 : 0
    const questionInput = {
      order: order || 0,
      type: question.type,
      text: question.text,
    }
    if (question.answers && question.answers.length) {
      questionInput.answers = { create: question.answers.map((answer, index) => ({ value: answer.value, position: answer.position || index })) }
    }
    if (this.props.recruitment.id) questionInput.recruitments = { connect: [{ id: this.props.recruitment.id }] }
    const [error, result] = await to(this.props.createQuestion({ variables: { questionInput } }))
    if (error || !result) {
      console.error("onAddedQuestion:createQuestion:error:", error)
    } else {
      this.setState({ selection: orderBy((q) => q.order)(["asc"])([...this.state.selection, result.data.createQuestion]), selectionDirty: true })
    }
  }

  onRemoveQuestion = async question => {
    if (!question) return
    const confirmed = await confirm('Vill du ta bort frågan: "' + question.text + '" från denna rekrytering?', { confirmText: "Ta bort" })
    if (!confirmed) return
    const [error, result] = await to(this.props.deleteQuestion({ variables: { id: question.id } }))
    if (error || !result) {
      console.error("deleteQuestion:error:", error, question)
      return
    }
    const selection = filter((q) => q.id !== question.id)(this.state.selection)
    this.setState({ selection, selectionDirty: true })
  }

  onDroppedCopy = dropped => {
    if (dropped.removezone) return
    this.onCopiedQuestion(dropped.item)
  }

  onDroppedRemove = dropped => {
    if (dropped.dropzone) {
      this.setState({ selectionDirty: true })
    } else {
      this.onRemoveQuestion(dropped.item)
    }
  }

  onEditQuestion = (question) => {
    this.setState({ editQuestion: question })
  }

  onEditQuestionSaved = (question) => {
    const selection = map((q) => (q.id === question.id ? question : q))(this.state.selection)
    this.setState({ selection })
  }

  onCloseEdit = () => {
    this.setState({ editQuestion: null })
  }

  sortSelected = (dragId, hoverId) => {
    if (dragId === hoverId) return
    const { selection } = this.state
    const question = selection.filter(c => c.id === dragId)[0];
    const hoverQuestion = selection.filter(c => c.id === hoverId)[0];
    const questionIndex = selection.indexOf(question);
    const hoverIndex = selection.indexOf(hoverQuestion);
    // - copy the dragged item before hovered element (i.e., [hoverIndex, 0, dragQuestion])
    // - remove the previous reference of dragged element (i.e., [dragIndex, 1])
    // - here we are using this update helper method from immutability-helper package
   this.setState({ selection: update(selection, { $splice: [[questionIndex, 1], [hoverIndex, 0, question]] }), selectionDirty: true })
  }
  
  render() {
    let index = 0
    if (!this.state.selection || !this.state.companySelection) {
      return (
        <FlexRow $center style={{ minHeight: "50vh" }}>
          <FlexCol>
            <Spinner />
          </FlexCol>
        </FlexRow>
      )
    }

    const { editQuestion, selection } = this.state
    const { user } = this.props
    const nextOrder = selection && selection.length ? Math.max(...map((q) => q.order)(selection)) + 1 : 0

    const companyQuestions = map((question) => (
      <SelectionQuestion key={question.id} id={question.id} question={question} onAdd={this.onCopiedQuestion} onAddText="Välj" onDropped={this.onDroppedCopy} />
    ))(this.state.companySelection)

    const recruitmentSelection = map((question) => (
      <SelectionQuestion
        index={index++}
        key={question.id}
        id={question.id}
        question={question}
        onEdit={this.onEditQuestion}
        onDelete={this.onRemoveQuestion}
        onDropped={this.onDroppedRemove}
        onMove={this.sortSelected}
      />
    ))(this.state.selection)

    return (
      <FlexRow style={{ minHeight: "50vh" }} $center $nowrap $relative>
        <Arrow />
        <Column className="faded">
          <h3>Företagets fasta urvalsfrågor</h3>
          <SelectionRemovezone companyId={this.props.companyId} onCreate={user.isAdmin && this.onCreateCompanyQuestion}>
            {companyQuestions}
          </SelectionRemovezone>
        </Column>
        <Column>
          <h3>Urvalsfrågor för denna rekrytering</h3>
          <SelectionDropzone recruitmentId={this.props.recruitment.id} onCreate={this.onCreateRecruitmentQuestion} order={nextOrder}>
            {recruitmentSelection}
          </SelectionDropzone>
        </Column>
        <SelectionEdit open={!!editQuestion} question={editQuestion} onSaveQuestion={this.onEditQuestionSaved} onModalClose={this.onCloseEdit} />
      </FlexRow>
    )
  }
}

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import styled from 'styled-components'
import { uniq } from 'lodash/fp'
import PlusIcon from '_images/plus.svg'
import { TextArea, Input, Radio } from '_layout/form-elements'
import { SecondaryButton, CancelButton, SaveButton } from '_layout/buttons'
import confirm from '_components/confirm'
import Modal from '_components/modal'
import DownIcon from '_images/menu-down-dark.svg'
import TrashIcon from '_images/trash.svg'
import { AnswerType } from '_root/constants'
import to from '_services/await.to'
import { CREATE_COMPANY_QUESTION } from '_containers/recruitment/recruitment-ql'
import message from '_components/message'

//#region Styles
const ArrowDown = styled.a`
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background: url(${DownIcon}) no-repeat scroll 50% 50% transparent;
    background-size: 16px 16px;
`
const ArrowUp = styled.a`
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background: url(${DownIcon}) no-repeat scroll 50% 50% transparent;
    background-size: 16px 16px;
    transform: rotate(180deg);
`
const Trash = styled.a`
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background: url(${TrashIcon}) no-repeat scroll 50% 50% transparent;
    background-size: 16px 16px;
`
const AnswerList = styled.ul`
    margin: 0;
    padding: 0;

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--color-line);

        &:first-child > ${ArrowUp} {
            visibility: hidden;
        }

        &:last-child > ${ArrowDown} {
            visibility: hidden;
        }

        span {
            flex: 1;
        }
    }
`
const Area = styled.div`
    margin-bottom: 40px;
    textarea { margin-bottom: 0; }
`
const NewQuestion = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 0.5rem;
    padding: 0 0 0 10px;
    color: var(--color-text-white);
    border: none;
    background: var(--color-brand-green);
    box-shadow: 0 1px 3px 0 var(--color-menu-zero);
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: url(${PlusIcon}) no-repeat scroll 50% 50% var(--color-brand-green-dark);
        background-size: 20px 20px;
    }

    &:hover {
        opacity: 0.75;
    }

    &:focus {
        outline: none;
    }
`
//#endregion

@graphql(CREATE_COMPANY_QUESTION, { name: 'createQuestion' })
export default class SelectionAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
            newQuestion: this.noQuestion(),
            newAnswer: '',
        }
    }

    noQuestion = () => {
        return {
            text: '',
            type: null,
            answers: []
        }
    }

    onNewAnswer = e => {
        this.setState({newAnswer: e.target.value})
    }

    onAddAnswer = async () => {
        const { newQuestion, newAnswer } = this.state
        if (!newAnswer || !newAnswer.trim().length) return
        if (newQuestion.answers.indexOf(newAnswer) >= 0) {
            await message('Det finns redan ett svarsalternativ med texten: ' + newAnswer)
            return
        }
        const answers = uniq([...newQuestion.answers, newAnswer.trim()])
        const question = {...newQuestion, answers}
        const canSave = this.checkSave(question)
        this.setState({newAnswer: '', newQuestion: question, canSave})
    }

    onModalClose = e => {
        this.setState({modalOpen: false})
    }

    onNewQuestion = e => {
        this.setState({modalOpen: true})
    }

    onAddQuestion = async e => {
        const question = this.state.newQuestion
        const { companyId, recruitmentId, order } = this.props
        const questionInput = {
            order: order || 0,
            type: question.type,
            text: question.text,
        }
        if (question.answers && question.answers.length) {
            questionInput.answers = { create: uniq(question.answers).map((answer, index) => ({ value: answer, position: index })) }
        }
        if (companyId) questionInput.company = { connect: { id: companyId } }
        if (recruitmentId) questionInput.recruitments = { connect: [{ id: recruitmentId }] }

        const [error, result] = await to(this.props.createQuestion({ variables: { questionInput } }))
        if (error) {
            console.error('onAddQuestion:error', error, question)
        }
        else {
            const { data: { createQuestion: newQuestion } } = result
            this.props.onAddQuestion(newQuestion)
            this.onModalClose()
            this.setState({newQuestion: this.noQuestion()})
        }
    }

    checkSave = question => {
        if (!question.text || !question.type) return false
        if (question.type === AnswerType.TEXT || question.type === AnswerType.BOOL || question.type === AnswerType.GRADE) return true
        if (question.answers && question.answers.length > 1) return true
        return false
    }

    onTextChange = e => {
        const question = { ...this.state.newQuestion, text: e.target.value }
        const canSave = this.checkSave(question)
        this.setState({newQuestion: question, canSave})
    }

    onTypeSelect = e => {
        const question = { ...this.state.newQuestion, type: e.target.value }
        const canSave = this.checkSave(question)
        this.setState({newQuestion: question, canSave})
    }
    
    onMoveDown = from => {
        const answers = [...this.state.newQuestion.answers]
        answers.splice(from+1, 0, answers.splice(from, 1)[0])
        this.setState({newQuestion: {...this.state.newQuestion, answers}, canSave: true })
    }

    onMoveUp = from => {
        const answers = [...this.state.newQuestion.answers]
        answers.splice(from-1, 0, answers.splice(from, 1)[0])
        this.setState({newQuestion: {...this.state.newQuestion, answers}, canSave: true })
    }

    onDeleteAnswer = async (answer, index) => {
        const confirmed = await confirm('Vill du ta bort svarsalternativet: "' + answer + '"?', { confirmText: 'Ta bort' })
        if (!confirmed) return
        const answers = [...this.state.newQuestion.answers]
        answers.splice(index, 1)
        this.setState({newQuestion: {...this.state.newQuestion, answers}, canSave: true })
    }

    render() {

        const { newQuestion, modalOpen } = this.state
        const answers = newQuestion.answers.map((answer, index) =>
            <li key={answer}>
                <span>{answer}</span>
                <ArrowDown title='Flytta ner' onClick={e => this.onMoveDown(index)} />
                <ArrowUp title='Flytta upp' onClick={e => this.onMoveUp(index)} />
                <Trash title='Ta bort' onClick={e => this.onDeleteAnswer(answer, index)} />
            </li>)

        const answerList = newQuestion.answers.length ? <AnswerList>{answers}</AnswerList> : null

        return <div className={this.props.className}>
            <NewQuestion onClick={this.onNewQuestion}>Skapa urvalsfråga</NewQuestion>
            <Modal isOpen={modalOpen} onRequestClose={this.onModalClose}>
                <Area>
                    <h3>Formulera urvalsfrågan:</h3>
                    <TextArea
                        placeholder='Skriv din fråga här...'
                        onChange={this.onTextChange}
                        value={newQuestion.text} />
                </Area>
                <Area>
                    <h3>Välj typ av svarsalternativ:</h3>
                    <Radio
                        id='answerText'
                        className='mr4'
                        name='answerType' value={AnswerType.TEXT}
                        label='text'
                        title='Ange textsvar'
                        onClick={this.onTypeSelect}
                        checked={this.state.newQuestion.type === AnswerType.TEXT} />
                    <Radio
                        id='answerBool'
                        className='mr4'
                        name='answerType' value={AnswerType.BOOL}
                        label='ja / nej'
                        title='Ange ja eller nej'
                        onClick={this.onTypeSelect}
                        checked={this.state.newQuestion.type === AnswerType.BOOL} />
                    <Radio
                        id='answerGrade'
                        className='mr4'
                        name='answerType' value={AnswerType.GRADE}
                        label='gradering 1-5'
                        title='Gradering 1-5'
                        onClick={this.onTypeSelect}
                        checked={this.state.newQuestion.type === AnswerType.GRADE} />
                    <Radio
                        id='answerSingle'
                        className='mr4'
                        name='answerType' value={AnswerType.SINGLE}
                        label='enval'
                        title='Välj ett av flera alternativ'
                        onClick={this.onTypeSelect}
                        checked={this.state.newQuestion.type === AnswerType.SINGLE} />
                    <Radio
                        id='answerMultiple'
                        name='answerType' value={AnswerType.MULTIPLE}
                        label='flerval'
                        title='Välj flera alternativ'
                        onClick={this.onTypeSelect}
                        checked={this.state.newQuestion.type === AnswerType.MULTIPLE} />
                </Area>
                {(newQuestion.type === AnswerType.MULTIPLE || newQuestion.type === AnswerType.SINGLE) &&
                <Area>
                    <h3>Ange minst två svarsalternativ:</h3>
                    <Input className='mr3' type='text' placeholder='Skriv svarsalternativ här...' value={this.state.newAnswer} onChange={this.onNewAnswer} />
                    <SecondaryButton onClick={this.onAddAnswer} disabled={!this.state.newAnswer.trim().length}>Lägg till</SecondaryButton>
                    {answerList}
                </Area>
                }
                <CancelButton onClick={this.onModalClose}>Avbryt</CancelButton>
                <SaveButton className='mv0 ml3' disabled={!this.state.canSave} onClick={this.onAddQuestion}>Spara urvalsfrågan</SaveButton>
            </Modal>
        </div>
    }
}
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`
const Title = styled.h1`
  margin-top: 0;
`

const Page404 = () => (
  <Content>
    <Title>404 - Page not found</Title>
    <Link to="/">Startsidan</Link>
  </Content>
)

export default Page404

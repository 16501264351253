import React, { useState, useEffect } from "react"
import { useOutletContext } from 'react-router-dom'
import styled from "styled-components"
import debounce from "lodash/debounce"
import ValuePicker from "_components/value-picker"
import RangeSlider from "_components/range-slider"
import { FlexRow, FlexCol, Label, TextArea } from "_layout/form-elements"
import { SecondaryButton } from "_root/layout/buttons"

//#region Styles
const Content = styled.div`
  padding: 40px;

  p {
    margin-top: 0;
  }
`
const Relative = styled.div`
  position: relative;

  textarea:focus {
    &::placeholder {
      color: transparent;
    }

    & + div {
      opacity: 1;
    }
  }
`
const TipBox = styled.div`
  position: absolute;
  top: 20px;
  left: 320px;
  width: fit-content;
  padding: 24px;
  background: var(--color-brand-green-light);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
  opacity: 0;
  transition: opacity 1s;
  white-space: nowrap;
  z-index: 2000;

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--color-brand-green-light);
  }

  header {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    color: var(--color-brand-green-dark);
  }

  p {
    strong {
      font-weight: 700;
      color: var(--color-brand-green);
    }
  }
`
//#endregion

export default function RecruitmentInfo(props) {
  const [recruitment, state, groups, urlBase, saving, saved, saveRecruitment, saveRecruitmentInfo] = useOutletContext()
  const { information = {} } = recruitment
  const [employmentForm, setEmploymentForm] = useState(information?.employmentForm || null)
  const [employersOffer, setEmployersOffer] = useState(information?.employersOffer || "")
  const [otherInformation, setOtherInformation] = useState(information?.otherInformation || "")
  const [recruitmentReason, setRecruitmentReason] = useState(information?.recruitmentReason || null)
  const [remoteWorkFrom, setRemoteWorkFrom] = useState(information?.remoteWorkFrom || 0)
  const [remoteWorkTo, setRemoteWorkTo] = useState(information?.remoteWorkTo || 0)
  const [salaryFrom, setSalaryFrom] = useState(information?.salaryFrom || 0)
  const [salaryTo, setSalaryTo] = useState(information?.salaryTo || 0)
  const [workScope, setWorkScope] = useState(information?.workScope || null)
  const [workingHours, setWorkingHours] = useState(information?.workingHours || "")

  useEffect(() => {
    document.getElementById("info-content").scrollIntoView(true)
  })

  useEffect(() => {
    onSave()
  }, [employmentForm, employersOffer, otherInformation, recruitmentReason, remoteWorkFrom, remoteWorkTo, salaryFrom, salaryTo, workScope, workingHours])

  const onSave = debounce(() => {
    saveRecruitmentInfo({
      employmentForm,
      employersOffer,
      otherInformation,
      recruitmentReason,
      remoteWorkFrom,
      remoteWorkTo,
      salaryFrom,
      salaryTo,
      workScope,
      workingHours,
    })
  })

  const setRemoteWork = (values) => {
    setRemoteWorkFrom(values[0])
    setRemoteWorkTo(values[1])
  }

  const setSalary = (values) => {
    setSalaryFrom(values[0])
    setSalaryTo(values[1])
  }

  return (
    <Content id="info-content">
      <FlexRow>
        <FlexCol className="mr5">
          <Label>Orsak till rekryteringsbehov</Label>
          <ValuePicker
            form={"true"}
            className="mb3"
            values={["Ersättningsrekrytering", "Ny tjänst", "Annat"]}
            placeholder="Välj orsak..."
            current={recruitmentReason}
            onSelected={setRecruitmentReason}
            noMatch="Inga träffar"
          />
          <Label>Arbetstidsomfattning</Label>
          <ValuePicker form={"true"} className="mb3" values={["Heltid", "Deltid"]} placeholder="Välj omfattning..." current={workScope} onSelected={setWorkScope} noMatch="Inga träffar" />
          <Label>Anställningsform</Label>
          <ValuePicker
            form={"true"}
            className="mb3"
            values={["Tillsvidareanställning", "Tidsbegränsad anställning", "Provanställning", "Säsongs- eller projektanställning", "Behovs- eller timanställning", "F-skatt / konsultuppdrag"]}
            placeholder="Välj anställningsform..."
            current={employmentForm}
            onSelected={setEmploymentForm}
            noMatch="Inga träffar"
          />
          <Label>Arbetstider</Label>
          <TextArea placeholder="Fyll i vilka arbetstider som gäller" value={workingHours} onChange={(e) => setWorkingHours(e.target.value)} />
          <Label>Möjlighet till distansarbete</Label>
          <RangeSlider showValues={true} onChange={setRemoteWork} unit="%" values={[remoteWorkFrom, remoteWorkTo]} />
          <Label>Lönespann</Label>
          <RangeSlider showValues={true} onChange={setSalary} unit="kr" min={10000} max={200000} step={1000} values={[salaryFrom, salaryTo]} />
        </FlexCol>
        <FlexCol>
          <Label>Arbetsgivarens erbjudande</Label>
          <Relative>
            <TextArea
              placeholder="Till exempel:
- Förmåner
- Semestererbjudande 
- Pensionsavsättningar
- Friskvårdsbidrag 
- Annat"
              value={employersOffer}
              onChange={(e) => setEmployersOffer(e.target.value)}
              rows={10}
            />
            <TipBox>
              <header>Till exempel:</header>
              <p>
                - Förmåner
                <br />
                - Semestererbjudande
                <br />
                - Pensionsavsättningar
                <br />
                - Friskvårdsbidrag
                <br />- Annat
              </p>
            </TipBox>
          </Relative>
          <Label>Övrig information</Label>
          <Relative>
            <TextArea
              placeholder="Till exempel:
- Beskrivning av organisationen
- Vilka utmaningar har ni som företag/
  organisation framför er nu?
- Vilka svårigheter möter man i rollen?
- Annat"
              value={otherInformation}
              onChange={(e) => setOtherInformation(e.target.value)}
              rows={10}
            />
            <TipBox>
              <header>Till exempel:</header>
              <p>
                - Beskrivning av organisationen
                <br />
                - Vilka utmaningar har ni som företag/
                <br />
                &nbsp; organisation framför er nu?
                <br />
                - Vilka svårigheter möter man i rollen?
                <br />- Annat
              </p>
            </TipBox>
          </Relative>
          <div className="mt3 center">
            <SecondaryButton disabled={saving} loading={saving} onClick={onSave}>
              {saving ? "Sparar" : "Spara"}
            </SecondaryButton>
          </div>
        </FlexCol>
      </FlexRow>
    </Content>
  )
}

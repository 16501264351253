import React from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import queryString from 'query-string'

import * as url from '_routes/url-names'
import { RecruitmentState } from '_root/constants';
import { SOK, REK_APPLICATIONS } from '_routes/url-names'
import { ResultList, ResultItem, SimpleShowMoreLink } from '_modules/search/common-layout/result-lists'

const SimpleRecruitmentsResults = props => {
    const { queryInput: query, results, meta } = props;
    const { match: { params: routeParams } } = props;
    const metaCount = (meta && meta._count && meta._count._all) || 0

    const linkParams = {
        pathname: `/${routeParams.company}${SOK}`,
        search: queryString.stringify({ category: "recruitments", query })
    }
    const showMoreLink = <SimpleShowMoreLink to={linkParams}>Visa fler ({ metaCount - results.length }) ></SimpleShowMoreLink>

    const stateUrl = state => {
        switch (state) {
            case RecruitmentState.ACTIVE:
                return url.REK_AKTIVA
            case RecruitmentState.DRAFT:
                return url.REK_UTKAST
            case RecruitmentState.CLOSED:
                return url.REK_AVSLUTADE
            default:
                return ''
        }
    }

    return (
        <div>
            <ResultList>
            {
                props.results.map((result) => 
                    <ResultItem key={result.id}>
                        <Link to={{ pathname: `/${result.company.urlName}/rekrytering${stateUrl(result.state)}/${result.id}${REK_APPLICATIONS}` }} target='_blank' rel='opener' onClick={props.onClose}>
                            { `${result.title} - (${result.company.name})` }
                        </Link>
                    </ResultItem>
                )
            }
            </ResultList>
            { (metaCount > results.length) && showMoreLink }
        </div>
    )
}

export default withRouter(SimpleRecruitmentsResults)
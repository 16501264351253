import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from "date-fns"
import withRouter from '_hoc/withRouter'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import MemberLayout from './member-layout'
import { Spinner } from '_layout/form-elements'
import Triangles from '_images/triangles-dropdown.svg'
import { JOB_ADS } from '_containers/member/members-ql'
import { GET_OCCUPATIONAL_AREAS, GET_REGIONS } from '_root/common-ql'
import { MEDLEM, ANNONS } from '_routes/url-names'
import inject from '_services/inject'

//#region Styles
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const AdList = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid var(--color-line);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      box-shadow: 0px 11px 8px -10px var(--color-bg-grey), 0px -11px 8px -10px var(--color-bg-grey);
    }
  }
`
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);

  input {
    display: block;
  }
`
const Logo = styled.div`
  display: none;
  flex: 0 0 144px;
  font-size: 0;
  line-height:0 ;

  img {
    max-width: 144px;
    max-height: 72px;
  }

  @media screen and (min-width: 550px) {
    display: block;
  }
`
const Details = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  
  @media screen and (min-width: 550px) {
    margin-right: 30px;
    padding-right: 20px;
    border-right: 1px solid var(--color-line);
  }
`
const CompanyDate = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 1.5;
`
const Region = styled.div`
  font-size: 14px;
  line-height: 1.5;
`
const Title = styled.h3`
  && {
    margin: 0.5rem 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-brand-red);
  }

  a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`
const ApplyDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    margin-right: 0.5rem;
  }
`
const Company = styled.div`
  margin: 0 1rem 0 0;
`
const FilterWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 700px) {
    flex-flow: row nowrap;
  }
`
const Filter = styled.select`
  position: relative;
  min-width: 285px;
  margin: 0 0 24px 0;
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
  background: url(${Triangles}) no-repeat scroll 95% 55%;
  color: #666;
  box-shadow: 0 2px 0 #e5e5e5;
  -webkit-appearance: none;
`
//#endregion

@compose(
    withApollo,
    withRouter,
    inject('user'),
    graphql(GET_OCCUPATIONAL_AREAS, { name: 'occupationalAreasQuery' }),
    graphql(GET_REGIONS, { name: 'regionsQuery' }),
    graphql(JOB_ADS, { name: 'jobAdsQuery', options: props => ({ variables: { now: new Date().toISOString() }, fetchPolicy: 'network-only' }) })
)
export default class MemberAds extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        jobAds: []
      }
    }

    filterRegion = e => {
      this.setState({ region: e.target.value })
    }

    filterArea = e => {
      this.setState({ area: e.target.value })
    }

    render() {
      let { jobAdsQuery: { loading, jobAds }, regionsQuery, occupationalAreasQuery } = this.props

      if (loading) {
        return <MemberLayout><Section><Spinner/></Section></MemberLayout>
      }

      const { region, area } = this.state

      if (region && region !== 'Alla regioner') {
        jobAds = jobAds.filter(ad => ad.recruitment.regions.find(r => r.name === region))
      }

      if (area && area !== 'Alla branscher') {
        jobAds = jobAds.filter(ad => ad.recruitment.occupationalAreas.find(a => a.name === area))
      }

      const baseUrl = `${MEDLEM}${ANNONS}/`

      return (
        <MemberLayout>
          <Section>
            <H1>Platsannonser</H1>
          </Section>
          <Section>
            <FilterWrapper>
              {regionsQuery.regions &&
              <Filter onChange={this.filterRegion}>
                {regionsQuery.regions.map(r => <option key={r.id} value={r.name}>{r.name}</option>)}
              </Filter>
              }
              {occupationalAreasQuery.occupationalAreas &&
              <Filter onChange={this.filterArea}>
                <option>Alla branscher</option>
                {occupationalAreasQuery.occupationalAreas.map(o => <option  key={o.id} value={o.name}>{o.name}</option>)}
              </Filter>
              }
            </FilterWrapper>
            {!!jobAds.length &&
            <AdList>
              {jobAds.map(ad =>
              <li key={ad.id}>
                <Details>
                  {ad.recruitment.regions && !!ad.recruitment.regions.length &&
                  <Region>{ad.recruitment.regions[0].name}</Region>
                  }
                  <Title><a href={baseUrl + ad.id}>{ad.recruitment.title}</a></Title>
                  <CompanyDate>
                    <Company>{ad.recruitment.company.name}</Company>
                    <ApplyDate>
                      <span>Ansök senast:</span>
                      <strong>{format(parseISO(ad.recruitment.applyStop), "yy-MM-dd")}</strong>
                    </ApplyDate>
                  </CompanyDate>
                </Details>
                <Logo>
                {!!ad.logotype && !!ad.logotype.url &&
                  <img src={ad.logotype.url} alt="Company logo" />
                }
                </Logo>
              </li>)}
            </AdList>
            }
            {!jobAds.length &&
            <em>Det finns inga annonser som matchar filtret</em>
            }
          </Section>
        </MemberLayout>
      )
    }
}

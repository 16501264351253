import React from 'react'
import confirm from '_components/confirm'
import message from '_root/components/message'
import TinyEditor from '_components/tiny-editor'
import PreviewTemplate from './preview-template'
import { PrimaryButton, CancelButton, SecondaryButton } from '_layout/buttons'
import ctrlKey from '_utils/ctrlKey'

export default class AddTemplate extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        close: false,
        type: props.type,
        name: props.name || '',
        content: props.content || null,
        namePlaceholder: props.namePlaceholder || 'Spara mall som...'
      }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.close !== prevState.close) {
            return { close: nextProps.close }
        }
        return null
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.state.close) {
          this.onClose()
        }
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onContentChange = content => {
        this.setState({content: content}, () => {
            if (this.state.saved) {
                this.onSave()
            }
        })
    }

    onClose = async () => {
      if (this.state.preview) {
        this.props.onCancel()
        this.onClosePreview()
      }
      else {
        if (await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
          this.onSave()
        }
        this.props.onClose && this.props.onClose()
      }
    }

    onPreview = () => {
      this.setState({ preview: true })
    }

    onClosePreview = () => {
      this.setState({ preview: false })
    }

    onSave = e => {
      const { type, name, content, saved } = this.state
      if (!name || !content) {
        if (saved) {
          this.setState({saved: false})
          message('Namn och innehåll är obligatoriskt.')
        }
        else {
          this.setState({saved: true})
        }
      }
      else {
        this.props.onAdd && this.props.onAdd({ type, name, contentHTML: content })
      }
    }

    render() {
      const { type, name, content, preview, namePlaceholder } = this.state
      if (preview) {
        const template = { type, name, contentHTML: content }
        return <PreviewTemplate template={template} onClose={this.onClosePreview} />
      }
      return (
          <div>
              <h3>{this.props.title}</h3>
              <input 
                  type='text'
                  className='mb3 w-100'
                  placeholder={namePlaceholder || ''}
                  value={this.state.name}
                  onChange={this.onNameChange} />
              <TinyEditor
                  form
                  placeholder={'Fyll i din mall här... (klistra in med ' + ctrlKey + '+V)'}
                  insert='h3 bold italic underline | bullist numlist | link table'
                  selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                  plugins='autolink link lists image table'
                  content={content}
                  onChange={this.onContentChange}
                  onDirty={this.props.onDirty}
                  style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 240px)', minHeight: 300 }} />
              <div className="tr">
                  <CancelButton className='mr3' onClick={this.onClose}>Avbryt</CancelButton>
                  <SecondaryButton className='mr3' onClick={this.onPreview}>Granska</SecondaryButton>
                  <PrimaryButton onClick={this.onSave}>Spara mall</PrimaryButton>
              </div>
          </div>
      )
    }
}
/**
 * Helper method to handle Promise errors.
 * @function to
 * @param  {Promise} promise Supplied promise to manage errors for.
 * @return {Array} Returns a new promise that resolves when supplied promise has resolved.
 */
export default function to(promise) {
  return promise
    .then((data) => {
      return [null, data]
    })
    .catch((err) => [err])
}

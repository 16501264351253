import React from 'react'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { map, filter } from 'lodash/fp'
import message from '_components/message'
import draftToHtml from 'draftjs-to-html'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import { PrimaryButton, CancelButton } from '_layout/buttons'
import { withEmailApplicationMutation } from '_modules/applications'
import to from '_services/await.to'
import inject from '_services/inject'
import ctrlKey from '_utils/ctrlKey'

//#region Styles
const Wrapper = styled.div`
  #tooltip {
    max-width: 500px;
  }
`
const Error = styled.p`
  margin: 0 0 1em 0;
  color: var(--color-brand-red);
`
//#endregion

@compose(
    inject('user'),
    withEmailApplicationMutation
)
export default class ApplicationMailing extends React.Component {
    state = {
        loading: true,
        editorKey: null
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (!prevState.loading) return null
        const applications = filter(a => a.candidate && a.candidate.user)(nextProps.applications || [])
        return {
            loading: false,
            templates: nextProps.templates || [],
            recipients: map(a => a.candidate.user.email)(applications),
            applications: applications,
            sender: nextProps.user.email || '',
            name: ''
        }
    }

    onSenderChange = e => {
        this.setState({sender: e.target.value})
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onContentChange = contentHTML => {
        this.setState({contentHTML})
    }

    onSelected = template => {
        if (!template) return
        this.setState({
            name: template.name || '',
            contentHTML: template.contentHTML || draftToHtml(template.content),
            selected: template,
            editorKey: template.id
        })
    }

    onSend = async e => {
        this.setState({sending: true})
        const { name, contentHTML, applications, recipients, sender } = this.state
        if (!sender || !name || !contentHTML || !recipients || !recipients.length) return
        message('E-post skickas i bakgrunden till ' + (recipients.length > 1 ? 'valda kandidater.' : 'vald kandidat.'), { text: 'Du kan fortsätta arbeta under tiden.' })
        this.onCancel()
        const [error] = await to(this.props.emailApplication({
            from: sender,
            recruitmentId: this.props.recruitmentId,
            to: map(a => ({
                applicationId: a.id,
                email: a.candidate.user.email
            }))(applications),
            subject: name,
            html: contentHTML,
            group: false
        }))
        if (error) {
            console.error('application-mailing:onSend:error: ', error);
            this.props.onError(error)
        }
        else {
            this.props.onUpdate()
        }
    }

    onCancel = e => {
        this.setState({name: '', contentHTML: null, sending: false, errorMessage: null, editorKey: null})
        this.props.onClose()
    }

    render() {
        const { sender, name, contentHTML, selected, templates, recipients, sending, errorMessage, editorKey } = this.state
        const canSend = sender && name && contentHTML && recipients && recipients.length

        return (
            <Wrapper>
                <h3>Utskick</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <label className='mb2 db'>Från:</label>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Från'}
                    value={sender}
                    onChange={this.onSenderChange} />
                <label className='mb2 db'>Skickas till: (endast synligt för administratörer)</label>
                <div className='mb3'>{recipients.join(', ')}</div>
                <ObjectPicker
                    form={'true'}
                    className='mb3'
                    values={templates}
                    placeholder='Välj mall...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga mallar' />
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Ämne'}
                    value={name}
                    onChange={this.onNameChange} />
                <TinyEditor
                    key={editorKey}
                    form
                    insert='h3 bold italic underline | bullist numlist | link table'
                    selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                    plugins='autolink link lists image table'
                    placeholder={'Fyll i din mall här... (klistra in med ' + ctrlKey + '+V)'}
                    content={contentHTML}
                    onChange={this.onContentChange}
                    onDirty={this.props.onDirty}
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 460px)', minHeight: 300 }} />
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                    <PrimaryButton onClick={this.onSend} disabled={!canSend} loading={sending}>Skicka</PrimaryButton>
                </div>
                <Tooltip id='tooltip' place='bottom' effect='solid' multiline={true} />
            </Wrapper>
        )
    }
}
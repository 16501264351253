import React from "react"
import { withApollo } from '@apollo/client/react/hoc'
import styled from "styled-components"
import filter from "lodash/fp/filter"
import { SmallSpinner, Grade } from "_layout/form-elements"

//#region Styles
const List = styled.ul`
    margin: 0;
    padding: ${props => props.$padding || 0};
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        width: 160px;
        white-space: nowrap;

        .spinner-container {
            display: inline-flex;
            height: 30px;
            width: 30px;
            margin-top: 8px;
            margin-right: 12px;
            margin-bottom: 8px;
            flex: none;
        }

        > span.legend {
            white-space: normal;
        }
    }

    &.disabled {
        pointer-events: none;
    }
`
//#endregion

const GradeState = {
    SAVING: "SAVING",
    DEFAULT: null
}

@withApollo
export default class GradeList extends React.Component {
    state = {
      gradeState: {
        1: GradeState.DEFAULT,
        2: GradeState.DEFAULT,
        3: GradeState.DEFAULT,
        4: GradeState.DEFAULT,
        5: GradeState.DEFAULT
      }
    }

    componentDidMount = () => {
        this.mounted = true
    }

    componentWillUnmount = () => {
        this.mounted = false
    }

    onGradeState = (grade, state) => {
        if (!this.mounted) {
            return
        }
        const gradeState = {
            ...this.state.gradeState,
            [grade]: state
        }
        this.setState({ gradeState })
    }

    onGrade = grade => {
      const { onGrade, application } = this.props
      if (!onGrade || !this.mounted) {
        return
      }
      this.onGradeState(grade, GradeState.SAVING)
      const newGrade = application.grade === grade ? 0 : grade
      onGrade(newGrade, saved => {
        if (saved) {
          this.onGradeState(grade, GradeState.DEFAULT)
        }
      })
    }

    render() {
        const { application } = this.props
        if (!application) {
            return null
        }
        const { gradeState } = this.state
        const saving = filter(gs => gs === GradeState.SAVING)(gradeState).length > 0
        return (
            <List $padding={this.props.$padding} className={saving && "disabled"}>
                <li>
                    {gradeState[1] === GradeState.SAVING ? (
                        <SmallSpinner />
                    ) : (
                      <Grade
                        level="1"
                        onClick={e => this.onGrade(1)}
                        onChange={() => {}}
                        $margin="8px 12px 8px 0"
                        checked={application.grade === 1}
                      />
                    )}
                    <span className="legend">Ofullständig ansökan</span>
                </li>
                <li>
                    {gradeState[2] === GradeState.SAVING ? (
                      <SmallSpinner />
                    ) : (
                      <Grade
                        level="2"
                        onClick={e => this.onGrade(2)}
                        onChange={() => {}}
                        $margin="8px 12px 8px 0"
                        checked={application.grade === 2}
                      />
                    )}
                    <span className="legend">Motsvarar ej kravprofil</span>
                </li>
                <li>
                    {gradeState[3] === GradeState.SAVING ? (
                      <SmallSpinner />
                    ) : (
                      <Grade
                        level="3"
                        onClick={e => this.onGrade(3)}
                        onChange={() => {}}
                        $margin="8px 12px 8px 0"
                        checked={application.grade === 3}
                      />
                    )}
                    <span className="legend">Motsvarar delvis kravprofil</span>
                </li>
                <li>
                    {gradeState[4] === GradeState.SAVING ? (
                      <SmallSpinner />
                    ) : (
                      <Grade
                        level="4"
                        onClick={e => this.onGrade(4)}
                        onChange={() => {}}
                        $margin="8px 12px 8px 0"
                        checked={application.grade === 4}
                      />
                    )}
                    <span className="legend">
                        Motsvarar i stort sett kravprofil
                    </span>
                </li>
                <li>
                  {gradeState[5] === GradeState.SAVING ? (
                    <SmallSpinner />
                  ) : (
                    <Grade
                      level="5"
                      onClick={e => this.onGrade(5)}
                      onChange={() => {}}
                      $margin="8px 12px 8px 0"
                      checked={application.grade === 5}
                    />
                  )}
                    <span className="legend">Motsvarar kravprofil</span>
                </li>
            </List>
        )
    }
}

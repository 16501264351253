import { gql } from '@apollo/client'

export const NAME_FILTER = `{ user: { OR: [{ AND: [{ firstName: { in: $names } }, { lastName: { in: $names } } ]}, { firstName: { contains: $fullName } }, { lastName: { contains: $fullName } }]}}`
export const EMAIL_FILTER = `{ user: { email: { contains: $email } }}`
export const MOBILE_FILTER = `{ user: { mobile: { contains: $mobile } }}`
export const POSTALCODE_FILTER = `{ user: { address: { postalCode: { contains: $postalCode }}}}`
export const POSTALCITY_FILTER = `{ user: { address: { postalCity: { contains: $postalCity }}}}`
export const EDUCATION_FILTER = `{ education: { level: { gte: $educationLevel } }}`
export const REGION_FILTER = `{ OR: [{ applications: { some: { recruitment: { regions: { some: { id: { in: $regionsIds }}}}}}}, { interests: { some: { regions: { some: { id: { in: $regionsIds }}}}}}]}`
export const AREA_FILTER = `{ OR: [{ applications: { some: { recruitment: { occupationalAreas: { some: { id: { in: $occupationalAreasIds }}}}}}}, { interests: { some: { occupationalGroups: { some: { occupationalArea: { id: { in: $occupationalAreasIds }}}}}}}]}`
export const GROUP_FILTER = `{ interests: { some: { occupationalGroups: { some: { id: { in: $occupationalGroupsIds }}}}}}`
export const INFORMATION_FILTER = `{ interests: { some: { information: { contains: $information }}}}`
export const SPAM_FILTER = `{ spam: { equals: $spam } }`

export const GET_OCCUPATIONAL_AREAS = gql`query GetOccupationalAreas {
    occupationalAreas {
        id
        name
    }
}
`
export const GET_OCCUPATIONAL_GROUPS = gql`query GetOccupationalGroups {
    occupationalGroups {
        id
        name
        occupationalArea {
            id
            name
        }
    }
}
`
export const GET_CANDIDATE = gql`
query Candidate($id: String!) {
    candidate(where: { id: $id }) {
        id
        user {
            id
            firstName
            lastName
            email
            mobile
            address {
                id
                postalCity
                postalCode
            }
            picture {
                id
                name
                url
            }
        }
        applications {
            id
            recruitment {
                id
                title
                regions {
                    id
                    name
                }
                occupationalAreas {
                    id
                    name
                }
                occupationalGroups {
                    id
                    name
                }
            }
        }
        interests {
            id
            company {
                id
            }
            cv {
                id
                name
                url
            }
            docs {
                id
                name
                url
            }
            links
            information
            occupationalGroups {
                id
                name
            }
            regions {
                id
                name
            }
        }
        education {
            id
            name
        }
        spam
    }
}
`
export const CANDIDATE_MESSAGE_LOGS_QUERY = gql`
query CandidateMessageLogs(
    $candidateId: String!
) {
    candidate(where: { id: $candidateId }) {
        messageLogs {
            id
            createdAt
            from
            to {
              id
              value
            }
            subject
            body
        }
    }
}
`

import React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import MenuIcon from '_images/menu-dots.svg'

//#region Styles
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.$small ? 'auto' : '100%'};
  margin-right: ${props => props.$noMargin ? '0px' : '15px'};
  transform-style: flat;
`
const Button = styled.button`
  display: inline-block;
  width: inherit;
  padding: 10px 30px 10px 20px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--color-bg-light);
  background: url(${MenuIcon}) scroll ${props => props.$hasLabel ? '100%' : '50%'} 50% no-repeat var(--color-bg-white);
  background-size: 24px 16px;
  text-align: left;
  box-shadow: 0px 3px 5px var(--color-bg-grey);
  outline: none;

  &[disabled], &[disabled]:hover {
    opacity: 0.6;
    color: var(--color-text-dark);
    background: var(--color-bg-white);
  }
`
const Content = styled.div`
  position: absolute;
  overflow-y: auto;
  padding: 10px;
  min-width: 100%;
  border: 1px solid var(--color-bg-light);
  background: var(--color-bg-white);
  box-shadow: 1px 1px 10px var(--color-bg-light);
  z-index: 10000;

  ${props => props.$right ? 'right: 0;' : 'left: 0;'}

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`
//#endregion

@onClickOutside
export default class DropDown extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        dropStyle: {}
      }
      this.button = React.createRef()
    }

    static getDerivedStateFromProps(props, state) {
      const keyFunction = e => {
        if (!props.open) return
        const code = e.keyCode || e.charCode
        if (code === 27 || code === 13) {
          e.preventDefault()
          this.props.toggle()
        }
      }
      if (props.open) document.addEventListener('keydown', keyFunction, false)
      else document.removeEventListener('keydown', keyFunction, false)
      return null
    }

    handleClickOutside = e => {
      if (this.props.open) this.props.toggle()
    }

    toggle = e => {
      if (this.button && this.button.current) {
        const docHeight = document.body.offsetHeight
        const buttonRect = this.button.current.getBoundingClientRect()
        const maxHeight = docHeight-buttonRect.bottom
        const dropStyle = { maxHeight }
        this.setState({dropStyle}, this.props.toggle)
      }
    }

    render() {
      const { open, label, children, disabled, right, noMargin, small, className } = this.props
      const classes = ['drop-down']
      open && classes.push('open')
      className && classes.push(className)

      return (
        <Wrapper className={classes.join(' ')} $noMargin={noMargin} $small={small}>
          <Button ref={this.button} $hasLabel={!!label} onClick={this.toggle} $open={open} disabled={disabled}>{label}</Button>
          {open &&
          <Content $right={right} style={this.state.dropStyle}>
            {children}
          </Content>}
        </Wrapper>
      )
    }
}
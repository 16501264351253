import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import sv from "date-fns/locale/sv"
import { Spinner } from "_layout/form-elements"
import { REK_APPLICATIONS, REK_APPLICATION, REKRYTERING, REK_AKTIVA, REK_UTKAST, REK_AVSLUTADE } from "_routes/url-names"
import { CLOSED_APPLICATIONS } from "_containers/interview/interview-ql"
import inject from "_services/inject"

//#region Styles
const Content = styled.div`
  padding: 1em 40px;
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  .heading {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .shadow {
    box-shadow: 0px 3px 5px var(--color-bg-grey);
  }

  .moreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 20px auto;
  }
`
const Rows = styled.div`
  flex: 1;
  margin: 0;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`
const InfoArea = styled.div`
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  cursor: ${(props) => (props.$pointer ? "pointer" : "default")};
  position: relative;
  flex: ${(props) => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.$left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);

  ${Row}.header & {
    border: none;
  }

  ${Row}.content:hover & {
    background: var(--color-bg-bright);
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  &.date {
    flex: 0 0 180px;
  }

  &.spots {
    flex: 0 0 140px;
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0 10px;
  }

  .content & {
    padding: 5px 10px;
    ${(props) => (props.$toggle ? "padding-right: 50px;" : null)};
  }

  .menu {
    pointer-events: auto;
    opacity: 0;
    visibility: hidden;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);

    &.type-light.border {
      border: 1px solid var(--color-line-dark);

      &.place-bottom:before {
        border-bottom-color: var(--color-line-dark);
      }
      &.place-top:before {
        border-top-color: var(--color-line-dark);
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
`
//#endregion Styles

// Put this outside of component to prevent endless loop of query
const now = new Date().toISOString()

@compose(withApollo, withRouter, inject("user"), graphql(CLOSED_APPLICATIONS, { name: "applicationsQuery", options: (props) => ({ variables: { company: props.user.companyView.id, now: now } }) }))
export default class ClosedInterviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      createDialog: false,
      sortOrder: {
        name: "asc",
      },
    }
  }

  componentDidMount = () => {
    document.getElementById("closed-interviews-content").scrollIntoView()
  }

  openApplication = (e) => {
    e.preventDefault()
    const { id, company } = e.currentTarget.dataset
    window.open(`/${company}${REK_APPLICATION}/${id}?q=1`, id, "width=1200,height=800,location=no")
    return false
  }

  setSortOrder = (prop) => {
    // const order = this.state.sortOrder[prop] !== 'desc' ? 'desc' : 'asc'
    // let sortOrder = {}
    // sortOrder[prop] = order
    // this.setState({ sortOrder }, this.filterCompanies)
  }

  render() {
    const { sortOrder } = this.state
    const {
      user,
      applicationsQuery: { loading, applications = [] },
      filter,
    } = this.props
    const states = { ACTIVE: REK_AKTIVA, DRAFT: REK_UTKAST, CLOSED: REK_AVSLUTADE }
    let filtered = applications
    if (filter && filter.length) {
      filtered = []
      applications.forEach((a) => {
        a.interviews.forEach((i) => {
          if (i.interview.interviewers.concat(i.interview.interviewersExternal).find((i) => filter.find((f) => f.id === i.id))) {
            filtered.push(a)
          }
        })
      })
    }

    return (
      <Content id="closed-interviews-content">
        <Wrapper>
          <h2>Tidigare händelser</h2>
          <Row className="header">
            <InfoArea>
              <InfoCell className="candidate" onClick={(e) => this.setSortOrder("candidate")}>
                Kandidat
                <Arrow className={sortOrder.candidate} />
              </InfoCell>
              <InfoCell className="slots" onClick={(e) => this.setSortOrder("slots")}>
                Tid
                <Arrow className={sortOrder.slots} />
              </InfoCell>
              <InfoCell className="date" onClick={(e) => this.setSortOrder("applyDate")}>
                Ansökt
                <Arrow className={sortOrder.applyDate} />
              </InfoCell>
              <InfoCell className="recruitment" onClick={(e) => this.setSortOrder("recruitment")}>
                Tjänst
                <Arrow className={sortOrder.recruitment} />
              </InfoCell>
              {user.superAdmin && user.companyView.id === user.employer.id && (
                <InfoCell className="company" onClick={(e) => this.setSortOrder("company")}>
                  Företag
                  <Arrow className={sortOrder.company} />
                </InfoCell>
              )}
            </InfoArea>
          </Row>
          <Rows>
            {loading && <Spinner />}
            {!loading && !filtered.length && <p>Det finns inga avslutade händelser.</p>}
            {!!filtered.length &&
              filtered.map((app) => (
                <Row className="content" key={app.id} onClick={this.editInterview} id={app.id}>
                  <InfoArea>
                    <InfoCell className="candidate">
                      {app?.candidate &&
                      <a onClick={this.openApplication} data-id={app.id} data-company={app.recruitment.company.urlName}>
                        {app.candidate.user.fullName}
                      </a>
                      }
                      {!app?.candidate && <em>Kandidaten borttagen</em>}
                    </InfoCell>
                    <InfoCell className="slots">{format(parseISO(app.interviews[0].interview.startTime), "d MMM HH:mm", { locale: sv })}</InfoCell>
                    <InfoCell className="date">{format(parseISO(app.createdAt), "yy-MM-dd", { locale: sv })}</InfoCell>
                    <InfoCell className="recruitment">
                      <NavLink to={`/${app.recruitment.company.urlName}${REKRYTERING}${states[app.recruitment.state]}/${app.recruitment.id}${REK_APPLICATIONS}`}>{app.recruitment.title}</NavLink>
                    </InfoCell>
                    {user.superAdmin && user.companyView.id === user.employer.id && (
                      <InfoCell className="company">
                        <div>{app.recruitment.company.name}</div>
                      </InfoCell>
                    )}
                  </InfoArea>
                </Row>
              ))}
          </Rows>
        </Wrapper>
      </Content>
    )
  }
}

import React from 'react'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import withApolloQuery from '_modules/search/components/with-apollo-query'

import inject from '_services/inject'
import companiesQueryBuilder from './groups-ql'
import SimpleGroupsResults from './simple-results';
import { Spinner } from '_layout/form-elements';
import {
    ResultGroupHeader,
    ResultWrapper
} from '_modules/search/common-layout/result-lists';

@compose(
    withRouter,
    inject('user'),
    withApolloQuery({
        options: props => ({
            notifyOnNetworkStatusChange: true,
            variables: {
                queryInput: props.queryInput,
                paging_limit: props.limit
            }
        })
    }, companiesQueryBuilder)
)
export default class SimpleGroupsResultGroup extends React.PureComponent {
    displayName = "GroupsResultGroup"

    render() {
        const { queryInput, onClose } = this.props;
        const { results, meta, loading } = this.props.apollodata;
        const metaCount = (meta && meta._count && meta._count._all) || 0
        const resultProps = { queryInput, results, meta, onClose };

        return [
            <ResultGroupHeader key="groupheader">
                Grupper
                { !loading && (metaCount ? ` - ${metaCount} ${metaCount === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
            </ResultGroupHeader>,
            <ResultWrapper key="groupresults">
                { loading ? <Spinner className="center" /> : <SimpleGroupsResults { ...resultProps } /> }
            </ResultWrapper>
        ]
    }
}
import React from 'react'
import styled from 'styled-components'

const Textarea = styled.textarea`
  font-size: 0.8rem;
  width: ${props => props.$big ? '400px' : '200px'};
  height: ${props => props.$big ? '200px' : '80px'};

  @media screen and (max-width: 767px) {
    width: 250px;
  }
`

export default class NotesInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editId: null,
      notes: props.notes || '',
      notesDefault: props.notes
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id !== prevState.editId) {
      if (prevState.notesDefault !== prevState.notes && nextProps.onChange) {
        nextProps.onChange(prevState.editId, prevState.notes)
      }
      return { notes: nextProps.notes, notesDefault: nextProps.notes, editId: nextProps.id }
    }
    return null
  }

  notesInput = e => {
    !this.props.readOnly && this.setState({notes: e.target.value})
  }

  saveNotes = () => {
    !this.props.readOnly && this.props.onChange && this.props.onChange(this.props.id, this.state.notes)
  }

  render() {
    const { big, readOnly } = this.props
    return <Textarea placeholder='Lägg till anteckning...' disabled={readOnly} $big={big} value={this.state.notes} onChange={this.notesInput} onBlur={this.saveNotes} />
  }
}

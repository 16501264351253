import React from 'react'
import keydown from 'react-keydown'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import differenceBy from 'lodash/fp/differenceBy'
import sortBy from 'lodash/fp/sortBy'
import some from 'lodash/fp/some'
import onClickOutside from 'react-onclickoutside'
import { getHidingParent } from '_services/util'
import { Checkbox } from '_layout/form-elements'
import Falafel from '_images/menu-dots.svg'
const remove = require('lodash/fp/remove').convert({ immutable: false })

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  box-shadow: 0px 2px 0px var(--color-line);
  width: ${props => props.$width ? props.$width : 'min(300px, 100%)'};

  input[type=text] {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Dropdown = styled.div`
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    left: 0;
    min-width: 225px;
    width: 100%;
    background: var(--color-bg-white);
    border: 1px solid var(--color-line-dark); 
    box-shadow: 2px 2px 10px var(--color-line-dark);
    z-index: 10000;

    &:focus {
        outline: none;
    }
`
const ObjectList = styled.ul`
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style-type: none;
`
const ListItem = styled.li`
    color: var(--color-text-dark);

    > label {
        cursor: pointer;
        position: relative;
        display: block;
        padding: 10px 10px 10px 36px;

        > label {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }

    &.no-objects {
      padding: 10px;
    }

    &:nth-child(even) {
      background: var(--color-bg-lightest);
    }

    &:hover {
      color: var(--color-text-white);
      background: var(--color-nav-1);
    }
`
const Title = styled.h4`
    flex: none;
    margin: 0;
    padding: 5px 10px;
    font-weight: 400;
    color: var(--color-text-white);
    background: var(--color-nav-2);
`
const Input = styled.input`
    cursor: pointer;
    width: 100%;
    background: url(${Falafel}) scroll 100% 50% no-repeat var(--color-bg-white);
    background-size: 24px 16px;

    &::placeholder {
      color: var(--color-text-dark);
    }

    &[type=text] {
        padding-right: 10%;
    }

    &:focus {
        cursor: text;
    }

    &[disabled] {
        background: var(--color-bg-lightest);
    }
`
//#endregion

@keydown
@onClickOutside
export default class MultiObjectPicker extends React.Component {
    constructor(props) {
        super(props)
        this.selectedText = props.selectedText || 'objekt vald(a)'
        this.noObjects = this.props.noObjects || 'Inga valbara objekt'
        this.noSelected = this.props.noSelected || 'Inga objekt valda'
        const selectable = differenceBy('id')(props.objects)(props.selected)
        this.textInput = React.createRef()

        this.state = {
            showList: false,
            filterText: '',
            objects: selectable || [],
            filtered: props.objects || [],
            selected: props.selected || [],
            placeholderDefault: props.placeholder || 'Välj objekt...'
        }
    }

    static getDerivedStateFromProps({ keydown, objects, count, selectedText }, { placeholderDefault, selected, showList }) {
        const nextState = {}
        if (showList && keydown.event && (keydown.event.which === 27 || keydown.event.which === 13)) {
            nextState.showList = false
            nextState.filterText = ''
        }
        const filterSelected = selected?.length ? filter(s => some(o => o.id === s.id)(objects))(selected) : []
        nextState.objects = differenceBy('id')(objects)(filterSelected)
        nextState.selected = filterSelected
        nextState.placeholder = filterSelected && filterSelected.length
            ? count ? `${filterSelected.length} ${selectedText}` : map(s => s.name)(filterSelected).join(', ')
            : placeholderDefault
        return nextState
    }

    getPlaceholder = selected => {
        return selected?.length
        ? this.props.count ? `${selected.length} ${this.selectedText}` : map(s => s.name)(selected).join(', ')
        : this.state.placeholderDefault
    }

    closeList = () => {
        if (!this.state.showList) return
        this.setState({ showList: false, filterText: '' })
    }

    handleClickOutside = e => {
        this.closeList()
    }

    onKeyDown = e => {
        const key = `${e.keyCode || e.charCode}`
        if (key === '27' || key === '13' || key === '10') {
            this.closeList()
            e.target.blur()
        }
    }

    objectInput = e => {
        const text = e.target.value && e.target.value.trim().toLowerCase()
        this.setState({ filterText: text })
    }

    inputFocus = e => {
      if (this.textInput && this.textInput.current) {
        const hidingParent = getHidingParent(this.textInput.current)
        const docHeight = hidingParent ? hidingParent.offsetHeight : document.body.offsetHeight
        const inputRect = this.textInput.current.getBoundingClientRect()
        const topOffset = inputRect.top - (hidingParent ? hidingParent.getBoundingClientRect().top : 0)
        const showListAbove = topOffset > docHeight / 2
        const listMaxHeight = (showListAbove ? topOffset : docHeight - topOffset - inputRect.height) - 10
        const dropStyle = showListAbove 
          ? { maxHeight: listMaxHeight, bottom: '100%' }
          : { maxHeight: listMaxHeight, top: '100%' }
        const sublistMaxHeight = (listMaxHeight / 2) - 28
        this.setState({ showList: true, dropStyle: dropStyle, sublistStyle: { maxHeight: sublistMaxHeight }})
      }
    }

    dropdownBlur = e => {
        if (!!e.relatedTarget && (e.relatedTarget.className.indexOf('object-picker-dropdown') < 0)) {
            this.setState({ showList: false })
        }
    }

    selectObject = e => {
        const objects = [...this.state.objects]
        const picked = remove(object => {
            return object.id.toString() === e.target.value
        })(objects)
        if (picked) {
            const selected = sortBy(['name'])([...picked, ...this.state.selected])
            this.setState({ objects: objects, selected: selected, placeholder: this.getPlaceholder(selected) },
                () => this.props.onSelected && this.props.onSelected(selected))
        }
    }

    removeObject = e => {
        const selected = [...this.state.selected]
        const picked = remove(object => {
            return object.id.toString() === e.target.value
        })(selected)
        if (picked) {
            const objects = sortBy(['name'])([...picked, ...this.state.objects])
            this.setState({ objects: objects, selected: selected, placeholder: this.getPlaceholder(selected) },
                () => this.props.onSelected && this.props.onSelected(selected))
        }
    }

    render() {

        let objectList = null
        let selectedList = null

        const { showList, dropStyle, sublistStyle, filterText, objects, selected, placeholder, placeholderDefault } = this.state

        if (showList) {
            const filtered = filterText
                ? filter(object => { return (object.name).toLowerCase().indexOf(filterText) > -1 })(objects)
                : [...objects]
            if (filtered?.length) {
                objectList = map(object =>
                    <ListItem key={object.id} title='Klicka för att välja objekt'>
                        <label>
                            <Checkbox tabIndex='0' className='object-picker-dropdown-input' value={object.id} onChange={this.selectObject} />
                            <span>{object.name}</span>
                        </label>
                    </ListItem>)(filtered)
            }
            else {
                objectList = <ListItem key='0' className='no-objects'>{this.noObjects}</ListItem>
            }
            if (selected?.length) {
                selectedList = map(object =>
                    <ListItem key={object.id} title='Klicka för att återställa vald objekt'>
                        <label>
                            <Checkbox tabIndex='0' className='object-picker-dropdown-input' value={object.id} onChange={this.removeObject} checked={true} />
                            <span>{object.name}</span>
                        </label>
                    </ListItem>)(selected)
            }
            else {
                selectedList = <ListItem key='0' className='no-objects'>{this.noSelected}</ListItem>
            }
        }

        return (
            <Content $width={this.props.width}>
                <Input type='text'
                  placeholder={placeholder || ''}
                  ref={this.textInput}
                  value={filterText}
                  onKeyDown={this.onKeyDown}
                  onChange={this.objectInput}
                  onFocus={this.inputFocus}
                />
                {showList &&
                    <Dropdown
                        tabIndex='1'
                        className={'object-picker-dropdown'}
                        style={dropStyle}
                        onBlur={this.dropdownBlur}>
                        {selectedList &&
                        <Title>{this.props.selectedTitle || 'Valda objekt'}</Title>
                        }
                        {selectedList &&
                        <ObjectList style={sublistStyle}>
                            {selectedList}
                        </ObjectList>
                        }
                        <Title>{placeholderDefault}</Title>
                        <ObjectList style={sublistStyle}>
                            {objectList}
                        </ObjectList>
                    </Dropdown>
                }
            </Content>
        )
    }
}

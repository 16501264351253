import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components'
import Debounce from 'debounce-decorator'
import { UnitInput } from '_root/layout/form-elements';

//#region Styles
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  min-height: ${props => props.$showFields ? '110px' : '50px'};
  width: 300px;

  & > div:last-child {
    margin-top: 1rem;
  }
`
const Fields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;

  div {
    width: 40%;
    margin-bottom: 0;
  }
`
//#endregion Styles

export default class RangeSlider extends React.Component {
  constructor(props) {
    super(props)
    const min = props.min || 0
    const max = props.max || 100
    const values = props.values && props.values.length && props.values[1] > props.values[0]
      ? [Math.max(min, props.values[0]), Math.min(max, props.values[1])]
      : [min, max]
    this.state = {
      values: values,
      min: min,
      max: max,
      showValues: props.showValues !== undefined ? props.showValues : true,
      showFields: props.showFields !== undefined ? props.showFields : true,
    }
  }

  @Debounce(750)
  onFinal() {
    this.props.onChange(this.state.values)
  }

  onChange = values => {
    this.setState({ values }, this.onFinal)
  }

  onFromChange = e => {
    if (e.target.value < this.state.min) return
    this.onChange([e.target.value, this.state.values[1]])
  }

  onToChange = e => {
    if (e.target.value > this.state.max) return
    this.onChange([this.state.values[0], e.target.value])
  }

  render() {
    const { values, showValues, showFields } = this.state
    const { min, max, step, unit } = this.props
    return <Wrapper $showFields>
      {showFields &&
      <Fields>
        <UnitInput type="number" min={min || 0} max={max || 100} value={values[0]} onChange={this.onFromChange} unit={unit} />
        <span>-</span>
        <UnitInput type="number" min={min || 0} max={max || 100} value={values[1]} onChange={this.onToChange} unit={unit} />
      </Fields>
      }
      <Range
        step={step || 1}
        min={min || 0}
        max={max || 100}
        values={values}
        onChange={this.onChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '284px',
              background: getTrackBackground({
                values: values,
                colors: ["var(--color-bg-grey-alpha)", "var(--color-brand-red)", "var(--color-bg-grey-alpha)"],
                min: min || 0,
                max: max || 100
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '16px',
              borderRadius: '50%',
              backgroundColor: isDragged ? 'var(--color-bg-grey-alpha)' : 'var(--color-brand-red)',
              boxShadow: "0px 2px 6px var(--color-bg-grey-alpha)",
              outline: 'none',
            }}
          >
            {showValues && isDragged &&
            <div
              style={{
                position: 'absolute',
                left: '50%',
                bottom: '-30px',
                color: 'var(--color-text-white)',
                padding: '0 4px',
                borderRadius: '4px',
                backgroundColor: 'var(--color-bg-grey-alpha)',
                transform: 'translate3d(-50%, 0, 0)',
              }}
            >
              {values[props.key]}
            </div>
            }
          </div>
        )}
      />
    </Wrapper>
  }
}

import React from 'react'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import uniq from 'lodash/fp/uniq'
import filter from 'lodash/fp/filter'
import { AnswerType } from '_root/constants'
import { Label as LabelStyle, Input, Grade, Radio, Checkbox } from '_layout/form-elements'

//#region
const QuestionText = styled.h4`
  font-weight: 400;
  color: ${props => props.$invalid ? 'var(--color-error)' : 'var(--color-text)'};
`
const Label = styled(LabelStyle)`
  font-weight: 400;
  input {
    margin-right: 10px;
  }
`
const AnswerArea = styled.div`
  margin-bottom: 30px;
  &.readonly .radio,
  &.readonly .checkbox {
    opacity: 0.2;
  }
`
//#endregion

export default class Question extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        readOnly: props.readOnly,
        question: props.question,
        answer: props.answer || ''
      }
    }

    onText = e => {
        this.props.onChange(this.state.answer)
    }

    onTextChange = e => {
        this.setState({answer: e.target.value})
    }

    onMultiple = e => {
        const items = filter(item => item.checked)(document.getElementsByName(this.state.question.id))
        const values = map(item => item.value)(items)
        const answer = values.join('|#')
        this.setState({answer: answer}, () => this.props.onChange(answer))
    }

    onChange = e => {
        const answer = e.target.value
        this.setState({answer: answer}, () => this.props.onChange(answer))
    }

    render() {
        const { question, answer, readOnly } = this.state
        const { invalid } = this.props
        let input = null
        const classes = readOnly ? 'readonly' : ''
        switch(question.type) {
            case AnswerType.TEXT:
              input = (
              <AnswerArea className={classes}>
                <Input type='text' readOnly={readOnly} value={answer} data-tooltip-content='Max 100 tecken' data-tooltip-place='bottom' data-tooltip-id="root-tooltip" onChange={this.onTextChange} onBlur={this.onText} maxLength={100} autoComplete="off" />
              </AnswerArea>
              )
            break
            case AnswerType.BOOL:
                input = (
                <AnswerArea className={classes}>
                    <Label>
                        <Radio name={question.id} value='ja' checked={answer === 'ja'} onChange={this.onChange} disabled={readOnly} /> Ja
                    </Label>
                    <Label>
                        <Radio name={question.id} value='nej' checked={answer === 'nej'} onChange={this.onChange} disabled={readOnly} /> Nej
                    </Label>
                </AnswerArea>
                )
            break
            case AnswerType.GRADE:
                input = (
                <AnswerArea className={classes}>
                    <Grade level='1' name={question.id} checked={answer === '1'} onChange={this.onChange} readOnly={readOnly} />
                    <Grade level='2' name={question.id} checked={answer === '2'} onChange={this.onChange} readOnly={readOnly} />
                    <Grade level='3' name={question.id} checked={answer === '3'} onChange={this.onChange} readOnly={readOnly} />
                    <Grade level='4' name={question.id} checked={answer === '4'} onChange={this.onChange} readOnly={readOnly} />
                    <Grade level='5' name={question.id} checked={answer === '5'} onChange={this.onChange} readOnly={readOnly} />
                </AnswerArea>
                )
            break
            case AnswerType.SINGLE:
                if (question.answers && question.answers.length) {
                    input = <AnswerArea className={classes}>
                        {map(option =>
                            <Label key={option.id}>
                                <Radio name={question.id} value={option.value} checked={option.value === answer} onChange={this.onChange} disabled={readOnly} /> {option.value}
                            </Label>
                        )(uniq(question.answers))}
                    </AnswerArea>
                }
                else {
                    input = <em>Svarsalternativ saknas</em>
                }
            break
            case AnswerType.MULTIPLE:
                if (question.answers && question.answers.length) {
                    const answers = answer ? answer.split('|#') : []
                    input = <AnswerArea className={classes}>
                        {map(option =>
                            <Label key={option.id}>
                                <Checkbox name={question.id} value={option.value} checked={answers.indexOf(option.value) > -1} onChange={this.onMultiple} disabled={readOnly} /> {option.value}
                            </Label>
                        )(uniq(question.answers))}
                    </AnswerArea>
                }
                else {
                    input = <em>Svarsalternativ saknas</em>
                }
            break
            default:
        }

        return (
            <li key={question.id}>
                <QuestionText $invalid={!!invalid}>{question.text}</QuestionText>
                {input}
            </li>
        )
    }
}
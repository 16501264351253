import React from 'react'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import inject from '_services/inject'
import { CheckboxButton } from '_layout/buttons'

//#region Styles
const Channel = styled.div`
    background: var(--color-bg-white);
    border-bottom: 2px solid var(--color-line-dark);

    &.selected {
        background: var(--color-bg-grey);
        
        button {
            background: var(--color-bg-dark);
        }
    }
`
const ChannelHeader = styled.div`
    position: relative;
    height: 40px;
    background-color: var(--color-bg-lightest);

    .publishchannel__logo {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
    }

    .publishchannel__logo {
      left: 20px;
    }
`
const ChannelContent = styled.div`
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100% - 40px);
    text-align: center;

    .channel__title {
        font-weight: bold;
        margin-bottom: 8px;
        padding: 0 52px;
    }

    .channel__description {
        color: var(--color-text-dark);
        margin: 24px 0 24px 0;

        &.alert {
          padding: 0.5rem;
          border: 2px solid var(--color-brand-red);
          background-color: var(--color-brand-red-10);
        }
    }
`
const StyledCheckboxButton = styled(CheckboxButton)`
    margin: auto 0 0 0;
    align-self: center;
`
//#endregion
@compose(
  inject('user')
)
export default class PublishCareer extends React.PureComponent {

    onTogglePublishState = async () => {
      await this.props.onChange({
        checkedState: !this.props.selected,
        channelId: this.props.id,
        selectionId: this.props.selectionId,
        activate: true
      })
    }

    render() {
        const { logoUrl, disableCareer, title, readOnly, selected } = this.props
        return (
            <Channel className={this.props.className}>
                <ChannelHeader>
                    <img className="publishchannel__logo" src={logoUrl} alt={`Logga för publiseringskanal - ${title}`} />
                </ChannelHeader>
                <ChannelContent>
                    <header className="channel__title">{title}</header>
                    {!disableCareer &&
                    <p className="channel__description">Publicera annonsen direkt på er egen karriärsida.</p>
                    }
                    {disableCareer &&
                    <p className="channel__description alert">
                      Ni saknar just nu koppling för att publicera annonser på er egen karriärsida. Kontakta <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>
                    </p>
                    }
                    {!disableCareer &&
                    <StyledCheckboxButton disabled={readOnly} checked={selected} onClick={this.onTogglePublishState}>{selected ? 'Publicerad' : 'Publicera'}</StyledCheckboxButton>
                    }
                </ChannelContent>
            </Channel>
        )
    }
}

import React from 'react'
import { Tooltip } from 'react-tooltip'
import keydown from 'react-keydown'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { findIndex, filter, find, isEqual, isEmpty } from 'lodash/fp'
import Falafel from '_images/menu-dots.svg'

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: ${props => props.$form === 'true' ? 'min(300px, 100%)' : (props.$width ? props.$width : 'auto')};
  box-shadow: 0px 2px 0px var(--color-line);

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Input = styled.input`
    cursor: pointer;
    width: 100%;
    background: url(${Falafel}) scroll 98% 50% no-repeat var(--color-bg-white);
    background-size: 24px 16px;

    &::placeholder, ::placeholder  {
      color: var(--color-text-dark);
    }

    &[type=text] {
        padding-right: 10%;
    }

    &:focus {
        cursor: text;
        outline: none;
    }

    &[disabled] {
        background: var(--color-bg-lightest);
    }
`
const Dropdown = styled.div`
    position: absolute;
    left: 0;
    min-width: 100%;
    display: flex;
    flex-flow: column nowrap;
    background: var(--color-bg-white);
    border: 1px solid var(--color-line-dark);
    box-shadow: 2px 2px 10px var(--color-line-dark);
    z-index: 10000;

    &:focus {
        outline: none;
    }
`
const ValueList = styled.ul`
    max-height: 250px;
    margin: 0;
    padding: 0;
    background: var(--color-bg-white);
    overflow-y: scroll;
    list-style-type: none;
`
const ListItem = styled.li`

  label {
    cursor: pointer;
    display: block;
    
    span {
      color: var(--color-text-dark);
      display: block;
      padding: 10px;

      &:hover {
        color: var(--color-text-white);
      }
    }
  }

    input[type=checkbox] {
        display: none;

        &:checked + span {
            cursor: default;
            color: var(--color-text-white);
            background: var(--color-nav-1);
        }
    }

    &.no-values {
      padding: 10px;
    }

    &:nth-child(even) {
      background: var(--color-bg-lightest);
    }

    &:hover {
      && {
        color: var(--color-text-white);
      }
      background: var(--color-nav-1);
    }
`
const Title = styled.h4`
  flex: 0 0 auto;
  margin: 0;
  padding: 5px 10px;
  font-weight: 400;
  && {
    color: var(--color-text-white);
  }
  background: var(--color-nav-2);
`
//#endregion

@keydown
@onClickOutside
export default class ObjectPicker extends React.Component {

  constructor(props) {
    super(props)
    this.noMatch = props.noMatch || 'Inga träffar'
    this.rand = Math.random()
    this.state = {
      readOnly: props.readOnly,
      showList: false,
      filtered: props.values || [],
      selected: props.current || null,
      current: props.current || null,
      placeholder: props.placeholder,
      textInput: '',
      filterText: ''
    }
    this.dd = React.createRef()
    this.textInput = React.createRef()
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    if (nextProps.filtered && !isEqual(nextProps.filtered)(prevState.filtered)) {
      nextState.filtered = nextProps.filtered
    }
    if (!isEqual(nextProps.current)(prevState.current)) {
      nextState.selected = nextProps.current
      nextState.current = nextProps.current
      nextState.filterText = ''
      nextState.textInput = ''
    }
    if (nextProps.values && !!nextProps.values.length && nextProps.values.length !== prevState.filtered.length) {
      nextState.filtered = nextProps.values
    }
    if (nextProps.placeholder && !isEqual(nextProps.placeholder)(prevState.placeholder)) {
      nextState.placeholder = nextProps.placeholder
    }
    return isEmpty(nextState) ? null : nextState
  }

  componentDidMount = () => {
    const placeholder = this.placeholder()
    if (placeholder !== this.state.placeholder) this.setState({placeholder})
  }

  componentDidUpdate = () => {
    this.checkScroll()
  }

  placeholder = () => {
    let selected = this.state && this.state.selected ? this.state.selected : this.props.current
    if (selected && selected.name) return selected.name
    return this.props.placeholder || 'Välj...'
  }

  closeDropDown = () => {
    if (!this.state.showList) return
    this.setState({ showList: false, textInput: '', filterText: '' })
  }

  handleClickOutside = e => {
    this.closeDropDown()
  }

  keyDown = e => {
    if (e.which === 9 || e.which === 13) {
      this.closeDropDown()
    }
    else if (e.which === 27) {
      this.setState({selected: this.pristine}, this.closeDropDown)
    }
    else if (e.which === 38 || e.which === 40) { // UP or DOWN arrow
      const { selected, filtered } = this.state
      const index = selected ? findIndex(o => o.id === selected.id)(filtered) + (e.which === 38 ? -1 : 1) : 0
      if (index < 0 || index === filtered.length) return
      const newSelected = filtered[index]
      this.setState({ selected: newSelected, placeholder: newSelected.name, textInput: newSelected.name }, () => this.props.onSelected(newSelected))
    }
  }

  checkScroll = () => {
    if (!this.state.showList || !this.state.selected) return
    const ddBounds = this.dd.current.getBoundingClientRect()
    const selected = document.getElementById(this.rand+this.state.selected.id)
    if (!selected) return
    const selectedBounds = selected.getBoundingClientRect()
    if (selectedBounds.top < ddBounds.top) {
      this.dd.current.scrollTop -= ddBounds.top - selectedBounds.top
    }
    else if (selectedBounds.bottom > ddBounds.bottom) {
      this.dd.current.scrollTop += selectedBounds.bottom - ddBounds.bottom
    }
  }

  userInput = e => {
    if (this.props.readOnly) return
    const { value } = e.target
    const text = value && value.trim().toLowerCase()
    this.setState({ filterText: text, textInput: value })
  }

  inputFocus = e => {
    if (this.textInput.current) {
      this.pristine = this.state.selected
      const docHeight = document.body.offsetHeight
      const inputRect = this.textInput.current.getBoundingClientRect()
      const showListAbove = inputRect.top > docHeight / 2
      const dropStyle = showListAbove 
        ? { bottom: '100%' } 
        : { top: '100%' }
      this.setState({ showList: true, dropStyle: dropStyle, textInput: '' })
    }
  }

  selectObject = async e => {
    if (this.props.readOnly) {
      this.setState({ showList: false })
      return
    }
    if (e.target.value && e.target.checked) {
      const selected = find(value => { return value.id === e.target.value})(this.props.values)
      if (selected !== this.state.selected && this.props.onSelected) {
        const result = await this.props.onSelected(selected)
        if (result === false) return
        this.setState({placeholder: selected.name, selected, showList: false, textInput: '', filterText: ''})
      }
    }
    else if (this.state.selected !== null) {
      const selected = this.props.required ? this.state.selected : null
      this.setState({placeholder: this.props.placeholder || 'Välj...', selected, showList: false, textInput: '', filterText: ''})
      this.props.onSelected && this.props.onSelected(selected)
    }
  }

  render() {
    let valueList = null
    const { showList, dropStyle, filterText, selected, placeholder } = this.state
    const { values, readOnly, required, form, width, className } = this.props

    if (showList) {
      const filtered = filterText
      ? filter(item => { return item.name.toLowerCase().indexOf(filterText) > -1 })(values)
      : [...values]
      if (filtered && filtered.length) {
        valueList = filtered.map(item =>
          <ListItem key={item.id} id={this.rand+item.id}>
            <label>
              <input
                disabled={readOnly}
                tabIndex='0'
                type='checkbox'
                className='object-picker-dropdown-input'
                value={item.id}
                onChange={this.selectObject}
                defaultChecked={selected && item.id === selected.id} />
              <span>{item.name}</span>
            </label>
          </ListItem>)
      }
      else {
        valueList = <ListItem key='0' className='no-values'>{this.noMatch}</ListItem>
      }
    }

    return (
      <Content className={className} $form={form} $width={width}>
        <Input type='text'
          data-tooltip-content={selected && !required ? 'Klicka på markerad rad för att avmarkera' : null}
          data-tooltip-id="root-tooltip"
          placeholder={selected && selected.name ? selected.name : (placeholder || '')}
          ref={this.textInput}
          value={this.state.textInput}
          disabled={this.props.disabled}
          onKeyDown={this.keyDown}
          onChange={this.userInput}
          onFocus={this.inputFocus}
        />
        {showList &&
        <Dropdown
          tabIndex='1'
          className={'object-picker-dropdown'}
          style={dropStyle}>
          <Title>{this.props.placeholder || 'Välj...'}</Title>
          <ValueList ref={this.dd}>
            {valueList}
          </ValueList>
        </Dropdown>
        }
      </Content>
    )
  }
}

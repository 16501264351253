import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import * as EmailValidator from 'email-validator'
import {
    isEmpty,
    uniq,
    map,
} from 'lodash/fp'
import message from '_components/message'
import { Spinner, UserPicker, Input, TextArea } from '_layout/form-elements'
import { PrimaryButton, CancelButton, MiniButton } from '_layout/buttons'
import { COMPANY_CONTACTS } from '_containers/recruitment/recruitment-ql'
import { withShareApplicationMutation } from '_modules/applications'
import to from '_services/await.to'
import inject from '_services/inject'
import { REK_APPLICATION } from '_root/routes/url-names';

//#region Styles
const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`
const Recipients = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 10px;

    input {
      margin-bottom: 0;
    }

    button {
      visibility: hidden;
    }

    &:hover {
      button {
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 767px) {
    li {
      input {
        width: 230px;
      }
    }
  }
`
//#endregion

@compose(
    inject('user'),
    withShareApplicationMutation,
    graphql(COMPANY_CONTACTS, { name: 'companyContacts', options: props => ({ variables: { companyId: props.company.id } }) })
)
export default class ApplicationSharing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            company: props.company,
            applications: props.applications,
            shareCompanyContacts: [],
            otherContacts: [''],
            currentOther: '',
            messageText: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}

        if (!prevState.companyContacts && nextProps.companyContacts && !nextProps.companyContacts.loading && nextProps.companyContacts.company) {
            nextState.companyContacts = nextProps.companyContacts.company.employees
        }

        return !isEmpty(nextState) ? nextState: null;
    }

    shareApplications = e => {
        const recipients = map(s => s.candidate.user)(this.state.selected)
        this.setState({emailModalOpen: false, shareModalOpen: true, recipients})
    }

    selectedCompanyContact = selected => {
        this.setState({shareCompanyContacts: [...selected]})
    }

    onAddRecipient = e => {
      const { otherContacts } = this.state
      const invalidEmail = otherContacts.find(oc => !EmailValidator.validate(oc))
      const disableAdd = invalidEmail || otherContacts.includes('')
      if (disableAdd) return
      otherContacts.push('')
      this.setState({ otherContacts })
    }

    onRemoveRecipient = e => {
        const { otherContacts } = this.state
        if (otherContacts.length === 1) {
          this.setState({ otherContacts: [''] })
        }
        else {
          otherContacts.splice(e.currentTarget.dataset.index, 1)
          this.setState({ otherContacts })
        }
    }

    applicationUrl = id => {
        return `${window.location.origin}/${this.props.company.urlName}${REK_APPLICATION}/${id}`
    }

    applicationSummary = application => {
      const applicant = application.candidate.user
      let summary = `<h3>${applicant.fullName}</h3><p>${applicant.email}</p>`
      if (applicant.mobile) summary += `<p>${applicant.mobile}</p>`
      if (applicant.address && applicant.address.postalCity) summary += `<p>${applicant.address.postalCity}</p>`
      if (application.cv) summary += `<h4>CV</h4><p><a href="${application.cv.url}">${application.cv.name}</a></p>`
      if (application.docs.length) {
        summary += '<h4>Dokument</h4>'
        application.docs.forEach(doc => summary += `<p><a href="${doc.url}">${doc.name}</a></p>`)
      }
      if (application.links) summary += `<h4>Länkar</h4><p>${application.links}</p>`
      if (application.information) summary += `<h4>Motivering</h4><p>${application.information}</p>`
      if (application.recruitment && application.recruitment.selection) {
        const { selection } = application.recruitment
        const { answers } = application
        summary += '<h4>Urvalsfrågor</h4>'
        selection.forEach(question => {
          summary += `<h5>${question.text}</h5>`
          !!answers[question.id] ? answers[question.id].split("|#").forEach(a => summary += `<div>${a}</div>`) : summary += '-'
        })
      }
      return summary
    }

    onShareApplications = async () => {
        this.setState({sending: true})
        const { shareCompanyContacts, otherContacts, applications, messageText } = this.state
        if (!shareCompanyContacts.length && otherContacts.length === 1 && !otherContacts[0].trim().length) {
            message('Välj minst en kontakt!')
            this.setState({sending: false})
            return
        }
        const summary = applications.map(a => this.applicationSummary(a)).join('<br/><hr/>')
        const ansokningar = applications.length > 1 ? 'Ansökningar' : 'Ansökan'
        const ansokningarLow = ansokningar.toLowerCase()
        const recipients = map(cc => cc.email)(shareCompanyContacts)
        const subject = `${ansokningar} för tjänsten: ${this.props.title}`
        const html = `<h1>${ansokningar}</h1><h2>Meddelande:</h2><p>${messageText}</p><hr/><p>${summary}</p>`
        const variables = {
            to: uniq(otherContacts),
            applicationIds: map(a => a.id)(applications),
            subject,
            text: '',
            html,
            recruitmentId: this.props.recruitmentId,
            group: false
        }
        const invalidOther = variables.to.find(oc => !EmailValidator.validate(oc))
        if (invalidOther) {
          message('Ogiltig e-post angiven.')
          this.setState({sending: false})
          return
        }
        let error
        if (!!variables.to.length && !!variables.to[0].length) {
          [error] = await to(this.props.shareApplication(variables))
        }
        if (recipients && recipients.length) {
          const linksText = applications.length > 1 ? 'Länkar' : 'Länk'
          const links = map(a => this.applicationUrl(a.id))(applications)
          const htmlLinks = map(url => `<li><a href="${url}">${url}</a></li>`)(links).join('')
          variables.to = recipients
          variables.text = `Se ${ansokningarLow} här:\n\n${links.join('\n')}`
          variables.html += `<br/><hr/><h3>${linksText} till ${ansokningarLow}:</h3><ul>${htmlLinks}</ul>`;
          [error] = await to(this.props.shareApplication(variables))
        }
        if (error) {
          console.error('application-sharing:onShareApplications:error: ', error);
          this.setState({sending: false, errorMessage: `Något gick fel när ${ansokningarLow} skulle skickas!`})
        }
        else {
          this.setState({sending: false, errorMessage: null}, this.onCancel)
          message(`E-post med länkar till ${ansokningarLow} har skickats till valda mottagare.`)
        }
    }

    setOtherContact = e => {
        const { otherContacts } = this.state
        if (!otherContacts.length) {
          this.setState({ otherContacts: [e.currentTarget.value] })
        }
        else {
          otherContacts[e.currentTarget.dataset.index] = e.currentTarget.value
          this.setState({ otherContacts: [...otherContacts] })
        }
    }

    onCancel = e => {
        this.setState({otherContacts: ['']})
        this.props.onClose()
    }

    render() {
        const { company, errorMessage, applications, companyContacts, shareCompanyContacts, otherContacts, messageText, sending } = this.state
        const disableAdd = otherContacts.find(oc => !EmailValidator.validate(oc)) || otherContacts.includes('')

        if (!companyContacts) return <Spinner/>

        return (
            <div>
                <h3 className='mb4'>Välj mottagare och skicka {applications.length} valda ansökningar.</h3>
                    {errorMessage &&
                    <Error>{errorMessage}</Error>
                    }
                    {companyContacts && companyContacts.length > 0 &&
                    <div>
                        <h3>Medarbetare på {company.name}</h3>
                        <UserPicker
                            className='mr3'
                            users={companyContacts}
                            placeholder='Välj mottagare...'
                            selected={shareCompanyContacts}
                            onSelected={this.selectedCompanyContact}
                            selectedText='person(er) vald(a)'
                            noSelected='Inga personer valda'
                            noUsers='Inga valbara personer' /> eller
                    </div>
                    }
                    <h3>Fyll i mottagares e-postadress</h3>
                    <Recipients>
                        {otherContacts.map((oc, index) => <li key={'contact-'+index}><Input className='mr3' placeholder='Skriv e-postadress' value={oc} data-index={index} onChange={this.setOtherContact} /><MiniButton data-index={index} onClick={this.onRemoveRecipient}>Ta bort</MiniButton></li>)}
                    </Recipients>
                    <div className='mb4'>
                      <MiniButton disabled={disableAdd} onClick={this.onAddRecipient}>Lägg till fler</MiniButton>
                    </div>
                    <div>
                      <h3>Meddelande:</h3>
                      <TextArea placeholder='Skriv ett meddelande' value={messageText} onChange={e => this.setState({ messageText: e.target.value })}></TextArea>
                    </div>
                    <div className='tr'>
                        <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                        <PrimaryButton onClick={this.onShareApplications} loading={sending}>Skicka valda ansökningar</PrimaryButton>
                    </div>
            </div>
        )
    }
}

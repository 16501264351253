import React from 'react'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import {
    isEmpty,
    map,
    orderBy
} from 'lodash/fp'
import confirm from '_components/confirm'
import SelectionAdd from '_components/recruit/selection-add'
import SelectionQuestion from '_components/recruit/selection-question'
import SelectionCopy from '_components/recruit/selection-copy'
import SelectionEdit from '_components/recruit/selection-edit'
import MainLayout from '_layout/main-layout'
import { H2, Spinner } from '_layout/form-elements'
import { SELECTION_QUERY, DELETE_QUESTION } from '_containers/recruitment/recruitment-ql'
import inject from '_services/inject'
import to from '_services/await.to'

//#region Styles
const Questions = styled.ul`
    margin: 0 0 40px 0;
    padding: 0;
    list-style-type: none;

    > li {
        margin-bottom: 0.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }
`
//#endregion

@compose(
    withRouter,
    inject('user'),
    graphql(SELECTION_QUERY, { name: 'selectionQuery', options: props => ({ variables: { urlName: props.match.params.company } }) }),
    graphql(DELETE_QUESTION, { name: 'deleteQuestion' })
)
export default class CompanySelection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}
        
        if (!nextProps.selectionQuery.loading && nextProps.selectionQuery.company) {
            const { id, questions } = nextProps.selectionQuery.company

            nextState.companyId = id
            nextState.questions = orderBy([q => q.text.toLowerCase()])(['asc'])(questions)
        }

        return !isEmpty(nextState) ? nextState : null
    }
       
    onUpdate = () => {
        this.props.selectionQuery.refetch()
    }

    deleteQuestion = async question => {
        const confirmed = await confirm('Vill du ta bort frågan: "'+question.text+'"?', { confirmText: 'Ta bort' })
        if (!confirmed) return
        const [error, result] = await to(this.props.deleteQuestion({variables: { id: question.id }}))
        if (error || !result) {
            console.error('deleteQuestion:error:', error, question)
            return
        }
        this.props.selectionQuery.refetch()
    }

    onEditQuestion = question => {
        this.setState({editQuestion: question})
    }

    onEditQuestionSaved = question => {
        this.props.selectionQuery.refetch()
    }

    onCloseEdit = () => {
        this.setState({editQuestion: null})
    }

    render() {

        const { user, selectionQuery } = this.props

        if (!user.companyView || selectionQuery.loading || !selectionQuery.company) {
            return (
                <MainLayout padding>
                    <Spinner />
                </MainLayout>
            )
        }

        const { match } = this.props
        const { questions, editQuestion } = this.state
        const questionList = questions && questions.length ? (
            <Questions>
                {map(question => <li key={question.id}><SelectionQuestion id={question.id} question={question} onEdit={this.onEditQuestion} onDelete={this.deleteQuestion} fixed /></li>)(questions)}
            </Questions>
        ) : <p>Inga urvalsfrågor har lagts till</p>

        return (
            <MainLayout padding maxHeight scroll>
                <div>
                    <H2>Urvalsfrågor</H2>
                    <div className='margin20'>
                        <p className='mb4'>
                            Urvalsfrågor läggs i anslutning till platsannonsen och besvaras av samtliga sökande till respektive tjänst. 
                            Här bygger ni upp en bank med bra urvalsfrågor som ni sedan kan välja från när ni skapar en ny rekrytering. 
                            Urvalsfrågorna bör spegla de kompetenser och erfarenheter ni anser viktigast för ert företag och respektive tjänst. 
                            Yrkesspecifika urvalsfrågor kan också skapas i samband med uppstart av rekrytering, under Annonsering.
                        </p>
                        {user.superAdmin && user.employer.id !== match.params.company &&
                        <SelectionCopy className='mb4 mr3' parent={user.employer} company={user.companyView} onClose={this.onUpdate} />
                        }
                        {user.superAdmin && user.companyView.groups.map(g =>
                        <SelectionCopy key={g.id} className='mb4 mr3' parent={g} company={user.companyView} onClose={this.onUpdate} />
                        )}
                        {user.groupAdmin && user.companyView.groups.map(g => g.id === user.employer.id &&
                        <SelectionCopy key={g.id} className='mb4 mr3' parent={g} company={user.companyView} onClose={this.onUpdate} />
                        )}
                        <div style={{ maxWidth: 500 }}>
                            <SelectionAdd className='mb3' companyId={this.state.companyId} onAddQuestion={this.onUpdate} />
                            {questionList}
                        </div>
                    </div>
                    <SelectionEdit
                        open={!!editQuestion}
                        question={editQuestion}
                        onSaveQuestion={this.onEditQuestionSaved}
                        onModalClose={this.onCloseEdit} />
                </div>
            </MainLayout>
        )
    }
}

import React from 'react'
import styled from 'styled-components'
import {
    map
} from 'lodash/fp'
import { CheckboxButton } from '_layout/buttons';
import JobbetSrc from '_images/publish/jobbetse-channel.png'
import ArbetsformedlingenSrc from '_images/publish/arbetsformedlingen-channel.png'
import FacebookSrc from '_images/publish/facebook-channel.png'
import LinkedInSrc from '_images/publish/linkedin-channel.png'
import InstagramSrc from '_images/publish/instagram-channel.png'
import SnapchatSrc from '_images/publish/snapchat-channel.png'
import TiktokSrc from '_images/publish/tiktok-channel.png'
import { FlexRow } from '_root/layout/form-elements';

//#region Styles
const Package = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 20px;
  border-right: 1px solid var(--color-line);

  &:last-child {
    border-right: none;
  }

  .margin-bottom {
    padding-bottom: 16px;
    margin-bottom: auto;
  }

  .margin-left {
    margin-left: auto;
  }
`
const PackageHeader = styled.header`
  position: relative;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 10px;
  padding: 0;
`
const PackageDescription = styled.p`
  margin: 0;
  padding: 0;
`
const PackageLogos = styled.div`
  margin: 0;
  padding: 12px 0;
  text-align: center;
  white-space: nowrap;
`
const Price = styled.div`
  margin-bottom: 1rem;
`
const ChannelIcon = styled.img`
  margin: 0 16px 16px 0;
  width: 46px;
  height: 46px;
`
//#endregion

const PackageComponent = props => {
  const { price, logotypes, description, packageText, packageTextSelected, readOnly } = props;
  return <Package>
    <PackageHeader>{props.title}</PackageHeader>
    <PackageDescription>{description}</PackageDescription>
    <PackageLogos>
    {logotypes && logotypes.length > 0 &&
      map(logo => <ChannelIcon key={logo.name} src={logo.src} alt={logo.name} title={logo.name}/>)(logotypes)
    }
    </PackageLogos>
    {price &&
    <FlexRow>
      <Price><strong>Pris:</strong> {price}</Price>
      <CheckboxButton className='margin-left' checked={props.selected} disabled={readOnly} onClick={props.onClick}>{props.selected ? packageTextSelected : packageText}</CheckboxButton>
    </FlexRow>
    }
  </Package>
}

export class InternetPackage extends React.PureComponent {
  render() {
    return <PackageComponent
      title='Medarbetare'
      description='Passar dig som vill nå medarbetare som inte är på tjänstemannanivå.'
      logotypes={[{ src: JobbetSrc, name: 'Jobbet.se'}, { src: FacebookSrc, name: 'Facebook'}, { src: InstagramSrc, name: 'Instagram'}, { src: ArbetsformedlingenSrc, name: 'Arbetsförmedlingen'}]}
      price='14 900 kr'
      packageText='Välj annonsering'
      packageTextSelected='Vald annonsering'
      selected={this.props.selected}
      readOnly={this.props.readOnly}
      onClick={this.props.onClick}
    />
  }
}

export class YoungPackage extends React.PureComponent {
  render() {
    return <PackageComponent
      title='Young professionals'
      description='Passar dig som vill nå nyexaminerade personer i början av sin karriär.'
      logotypes={[{ src: JobbetSrc, name: 'Jobbet.se'}, { src: SnapchatSrc, name: 'Snapchat'}, { src: TiktokSrc, name: 'Tiktok'}, { src: LinkedInSrc, name: 'LinkedIn'}]}
      price='16 900 kr'
      packageText='Välj annonsering'
      packageTextSelected='Vald annonsering'
      selected={this.props.selected}
      readOnly={this.props.readOnly}
      onClick={this.props.onClick}
      hasOffer={this.props.hasOffer}
      onOffer={this.props.onOffer}
    />
  }
}

export class SocialMediaPackage extends React.PureComponent {
  render() {
    return <PackageComponent
      title='Chef / tjänsteman'
      description='Passar dig som vill nå t.ex. chefer, specialister och tjänstemän.'
      logotypes={[{ src: JobbetSrc, name: 'Jobbet.se'}, { src: LinkedInSrc, name: 'LinkedIn'}, { src: FacebookSrc, name: 'Facebook'}, { src: InstagramSrc, name: 'Instagram'}]}
      price='17 900 kr'
      packageText='Välj annonsering'
      packageTextSelected='Vald annonsering'
      selected={this.props.selected}
      readOnly={this.props.readOnly}
      onClick={this.props.onClick}
      hasOffer={this.props.hasOffer}
      onOffer={this.props.onOffer}
    />
  }
}

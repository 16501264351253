import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

const EMAIL_APPLICATION = gql`
mutation MailApplicationMutation(
    $to: [ApplicationEmailMapPayload!]!,
    $from: String!,
    $recruitmentId: String!,
    $subject: String!,
    $html: String!
) {
    emailApplication(
        recruitmentId: $recruitmentId,
        to: $to,
        from: $from,
        subject: $subject,
        html: $html
    ) {
        message
    }
}
`

const withEmailApplicationMutation = graphql(EMAIL_APPLICATION, {
    props: ({ ownProps, mutate }) => ({
        emailApplication: ({ recruitmentId, from, to, subject, html }) => {
            const variables = { recruitmentId, from, to, subject, html }
            return mutate({ variables })
        }
    })
})

export default withEmailApplicationMutation
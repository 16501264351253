import React from 'react'
import { confirmable } from 'react-confirm'
import styled from 'styled-components'
import ModalBase from '_components/modal'
import { PrimaryButton, CancelButton } from '_layout/buttons'

const Modal = styled(ModalBase)`
    text-align: center;
`
const Button = styled(PrimaryButton)`
    margin: 20px 10px 0 10px;
    min-width: 100px;
    color: #ffffff;
    background-color: #e2001a;

    &:hover {
        background-color: #a61f1f;
    }
`
const Cancel = styled(CancelButton)`
    margin: 20px 10px 0 10px;
    min-width: 100px;
    color: #ffffff;
    background-color: #000000;

    &:hover {
        background-color: #333333;
    }
`

@confirmable
export default class ConfirmDialog extends React.Component {
    render() {
        const { show, proceed, dismiss, cancel, confirmation, options } = this.props
        return (
        <Modal isOpen={show} onRequestClose={dismiss} center>
            <h3>{confirmation}</h3>
            {options && options.text &&
            <p>{options.text}</p>
            }
            <Cancel onClick={cancel}>{options.cancelText || 'Avbryt'}</Cancel>
            <Button onClick={proceed}>{options.confirmText || 'OK'}</Button>
        </Modal>
        )
    }
}

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'

import inject from '_services/inject'
import { SEARCH_CANDIDATES } from './candidate-ql'
import DetailedCandidateResults from './detailed-results'
import { Spinner } from '_layout/form-elements'
import {
    ResultGroupHeader,
    GrowingResultWrapper,
    ArrowDown,
    ArrowUp
} from '_modules/search/common-layout/result-lists'

@compose(
    withRouter,
    inject('user'),
    graphql(SEARCH_CANDIDATES, { name: 'candidatesQuery', options: props => ({ variables: { query: props.queryInput, take: props.limit, skip: 0 } }) }),
)
export default class DetailedCandidatesResultGroup extends React.PureComponent {
    displayName = "CandidatesResultGroup"

    onFetchMoreResults() {
        const { searchCandidates, fetchMore } = this.props.candidatesQuery;
        const { candidates = [] } = searchCandidates || {}

        fetchMore({
            variables: {
                take: this.props.limit,
                skip: candidates.length,
                query: this.props.queryInput
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    searchCandidates: {...prev.searchCandidates, candidates: [...prev.searchCandidates.candidates, ...fetchMoreResult.searchCandidates.candidates]}
                });
            }
        });
    }

    render() {
        const { queryInput, isActive, onHeaderClick } = this.props;
        const { searchCandidates, loading, networkStatus } = this.props.candidatesQuery
        const { total = 0, candidates = [] } = searchCandidates || {}
        const isInitialLoading = networkStatus === 1 || networkStatus === 4;

        const resultProps = {
            queryInput,
            candidates,
            total,
            isFetchingMore: loading,
            onFetchMoreResults: this.onFetchMoreResults.bind(this)
        };

        const components = [
            <ResultGroupHeader key="groupheader" onClick={onHeaderClick} className={ isActive ? 'active' : ''}>
                Kandidater 
                { !isInitialLoading && ((total > 0) ? ` - ${total} ${total === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
                { isActive ? <ArrowDown/> : <ArrowUp/> }
            </ResultGroupHeader>,
            <GrowingResultWrapper key="groupresults" className={ isActive ? 'active' : ''}>
                { isInitialLoading ? <Spinner className="center" /> : <DetailedCandidateResults { ...resultProps } /> }
            </GrowingResultWrapper>
        ]

        return components
    }
}
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'

import {
  map,
} from 'lodash/fp'

import ProfileImage from '_images/profile.svg'
import MailIconSrc from '_images/email.svg'

import { COMPANY_CONTACTS } from '_settings/company-profile-ql'
import withSendmailMutation from '_hoc/withSendmailMutation'

import inject from '_services/inject'

//#region Styles
const RecruitingInformationWrapper = styled.div`
    padding: 15px 60px;
    margin-bottom: 20px;
    max-width: 960px;
    background: var(--color-brand-green-light);
    box-shadow: 0 12px 20px var(--color-bg-grey-dark);
    z-index: 100;

    h2 {
      margin: 0 0 10px 0;
    }

    p {
      display: inline-block;
      width: 60%;
      margin: 0;
      padding-right: 20px;
      font-size: 16px;
      vertical-align: top;

      strong {
        display: block;
        font-size: 20px;
        line-height: 32px;
        margin: 10px 0;
        color: var(--color-brand-green-dark);
      }
    }
`
const Contacts = styled.div`
  display: inline-flex;
  width: 40%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  white-space: nowrap;
`
const Contact = styled.div`
  display: inline-flex;
  margin-left: 30px;
  text-align: center;
  align-items: center;
  flex-flow: column nowrap;

  h4 {
    margin-bottom: 5px;
  }
`
const ContactImage = styled.div`
  display: inline-block;
  width: 95px;
  height: 95px;
  margin-bottom: 5px;
  border: 1px solid var(--color-text);
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-size: 34px 34px;

  img {
    max-width: 100%;
  }
`
const ContactLink = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--color-text);
  white-space: nowrap;
`
const MailIcon = styled.i`
  display: inline-block;
  margin-right: 4px;
  width: 18px;
  height: 10px;
  background: url(${MailIconSrc}) no-repeat scroll 50% 50%;
`
//#endregion Styles
@compose(
  inject('user'),
  withSendmailMutation,
  graphql(COMPANY_CONTACTS, { name: 'companyContacts', options: props => ({ variables: { urlName: props.user.companyView.urlName } }) }),
)
export default class RecruitingInformation extends PureComponent {
  render() {
    let { companyContacts, recruiters: contacts } = this.props
    if (!contacts || !contacts.length) {
      contacts = companyContacts.loading ? [] : companyContacts && companyContacts.company && companyContacts.company.jobbetContacts
    }
    return (
        <RecruitingInformationWrapper className={this.props.className}>
            <h2>Behöver du hjälp i din rekrytering? Då finns vi här för dig!</h2>
            <p>
              Våra kunder hanterar i snitt 6 av 10 rekryteringar helt på egen hand, men i de övriga 4 tar man hjälp av våra rekryteringskonsulter.
              <strong>
                Kontakta oss via e-post <a href='mailto:kundtjanst@jobbet.se'>kundtjanst@jobbet.se</a> eller telefon 018-100112.
                <br/>
              </strong>
              Du kan även boka rekryteringstjänster via knapparna nedan och använda våra intervjumallar när du rekryterar på egen hand.
            </p>
            {!!contacts.length &&
            <Contacts>{map(contact =>
              <Contact key={contact.id}>
                  <h4>Er kontakt</h4>
                  <ContactImage data-tooltip-id="root-tooltip" data-tooltip-content={contact.firstName + ' ' + contact.lastName}>
                    {contact && contact.picture && contact.picture.url &&
                    <img src={contact.picture.url} alt={contact.firstName + ' ' + contact.lastName} />
                    }
                  </ContactImage>
                  <span>
                      {contact && contact.email &&
                      <ContactLink href={'mailto:' + contact.email} data-tooltip-id="root-tooltip" data-tooltip-content={contact.email}><MailIcon/>{contact.firstName + ' ' + contact.lastName}</ContactLink>
                      }
                      <br/>
                      {contact && (contact.phone || contact.mobile) &&
                      <ContactLink href={'tel:' + (contact.mobile)}>{contact.mobile}</ContactLink>
                      }
                  </span>
              </Contact>
              )(contacts.slice(0, 2))}
            </Contacts>
          }
        </RecruitingInformationWrapper>
    );
  }
}

export const ROOT = '/'
export const SLASH = '/'
export const LOGIN = '/login'
export const COMPANY = '/:company'
export const JOBB = '/annonser'
export const ANNONS = '/annons'
export const ANNONSID = '/:annons'
export const URVAL = '/urval'
export const ANSOK = '/ansök'
export const MEDLEM = '/medlem'
export const PROFIL = '/profil'
export const REGISTRERA = '/registrera'
export const UPPDATERAD = '/uppdaterad'
export const BOKNING = '/bokning'
export const REDIGERA = '/redigera'
export const AKTIVERA = '/aktivera'
export const AKTIVERA_ID = '/:activate'
export const KUNDSERVICE = '/kundtjänst'
export const SOK = '/sök'
export const ANSOKNINGAR = '/ansökningar'
export const ANNONSER = '/annonser'
export const REKRYTERING = '/rekrytering'
export const REK_OVERSIKT = '/översikt'
export const REK_STATE = '/:state'
export const REK_MODE = '/:mode'
export const REK_SKAPA = '/skapa'
export const REK_NY = '/ny'
export const REK_AKTIVA = '/aktiva'
export const REK_UTKAST = '/utkast'
export const REK_AVSLUTADE = '/avslutade'
export const REK_APPLICATION = '/ansökan'
export const REK_APPLICATION_ID = '/:application'
export const REK_APPLICATIONS = '/ansökningar'
export const REK_APP_NEW = '/ny'
export const REK_APP_HISTORY = '/historik'
export const REK_PROTOCOL = '/protokoll'
export const REK_PROTOCOL_ID = '/:protocol'
export const REK_ID = '/:recruitment'
export const REK_GRUNDINFO = '/grundinfo'
export const REK_ANNONS = '/annons'
export const REK_KANALER = '/annonskanaler'
export const REK_INFO = '/info'
export const REK_GRANSKA = '/granska'
export const REK_PLANERA = '/planera'
export const REK_SEARCH = '/search'
export const REK_LOGGBOK = '/loggbok'
export const REK_APP_DOK = '/dokument'
export const KANDIDAT = '/kandidatbanken'
export const KANDIDAT_ID = '/:candidate'
export const STATISTIK = '/statistik'
export const INTERVIEW = '/bokning'
export const INT_VACANT = '/vakanta'
export const INT_INVITED = '/inbjudna'
export const INT_BOOKED = '/bokade'
export const INT_HISTORY = '/historik'
export const INSTALLNINGAR = '/inställningar'
export const INST_MY_PROFILE = '/min-profil'
export const INST_GROUP = '/grupp'
export const INST_COMPANY = '/företag'
export const INST_DEPARTMENTS = '/avdelningar'
export const INST_USERS = '/användare'
export const INST_NY = '/ny'
export const PAR_ID = '/:id'
export const INST_PERMISSION = '/behörighet'
export const INST_CV_DB = '/cv-databas'
export const INST_FILER = '/filer'
export const INST_MALLAR = '/mallar'
export const INST_UTSKICK = '/utskick'
export const INST_URVAL = '/urval'
export const INST_BILDBANK = '/bildbank'
export const ADMIN = '/admin'
export const ADMIN_NEW_COMPANY = '/nykund'
export const ADMIN_NEW_GROUP = '/nygrupp'
export const ADMIN_TEMPLATES = '/mallar'
export const ADMIN_PERMISSION = '/behörighet'
export const ADMIN_SITE = '/webbplats'
export const ADMIN_COMPANIES = '/kunder'
export const ADMIN_GROUPS = '/grupper'
export const CONSOLE = '/console'

import React from 'react'
import map from 'lodash/fp/map'
import styled from 'styled-components'
import MultiObjectPickerBase from '_components/multi-object-picker'
import { Label } from '_layout/form-elements'

//#region Styles
const MultiObjectPicker = styled(MultiObjectPickerBase)`
    margin-bottom: 20px;
    color: var(--color-text-dark);
`
const Container = styled.div`
    ul {
        li {
            color: var(--color-text-dark);
        }
        li.selected {
            color: var(--color-brand-red);
        }
    }
`
//#endregion

const RecruitAddons = props => {
    const { selectedAddons, readOnly, addons, onSelectedAddon } = props
    const showPicker = !readOnly
    const showReadonly = readOnly
    const selectedAddonsIds = map(a => a.id)(selectedAddons)

    return (
        <Container>
            <Label>{ selectedAddons && selectedAddons.length > 0 ? 'Bokade tjänster' : 'Bokningsbara tjänster' }</Label>
            {showPicker &&
            <MultiObjectPicker
              width='300px'
              selectedText='tjänster vald(a)'
              noObjects='Inga valbara tjänster'
              noSelected='Inga tjänster valda'
              placeholder='Välj tjänster...'
              objects={addons}
              selected={selectedAddons}
              onSelected={onSelectedAddon}/>
            }
            {showReadonly &&
                <ul>
                {map(a => (
                    <li key={a.id} className={ selectedAddonsIds.includes(a.id) ? 'selected' : null }>{a.name}</li>
                ))(addons)}
                </ul>
            }
        </Container>
    )
}

export default RecruitAddons
import React, { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import { map } from "lodash/fp"
import { Spinner } from "_layout/form-elements"
import { REK_APPLICATION, ANSOK } from "_routes/url-names"
import { GET_COMPANY_CANDIDATE_APPLICATIONS } from "_root/containers/member/members-ql"
import to from '_services/await.to'

//#region Styles
const Content = styled.div`
  padding: 40px;
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const Rows = styled.div`
  flex: 1;
  margin: 0;

  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 56px;

    .titleCell {
      position: relative;
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px 5px 10px;
      color: var(--color-text-dark);
      background: var(--color-bg-lighter);
      border-left: 1px solid var(--color-line-light);
      border-bottom: 1px solid var(--color-line-light);
    }

    .infoArea {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;

      .infoCell {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid var(--color-line-light);
        border-left: 1px solid var(--color-line-light);
        background: var(--color-bg-white);
        white-space: nowrap;

        &:last-child {
          border-right: 1px solid var(--color-line-light);
        }

        .content & {
          padding: 5px 10px;
        }

        &.date {
          max-width: 90px;
        }
      }
    }

    .actions {
      position: absolute;
      top: 15px;
      right: 7px;
      bottom: 50%;
      width: 25px;
      height: 25px;

      .actionSymbol {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px 5px 0;
        cursor: pointer;

        > img {
          max-width: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: var(--color-bg-grey);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
        }

        .message svg {
          stroke: #027874;
          fill: #027874;
        }
      }
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px 5px 10px;
  color: var(--color-text-dark);
  background: var(--color-bg-lighter);
  border-bottom: 1px solid var(--color-line-light);

  .header & {
    border: none;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-light);
    }
  }
`
const InfoArea = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);
  white-space: nowrap;

  ${Row}.header & {
    border: none;
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0 10px;

    &:hover {
      background: var(--color-bg-light);
    }
  }

  .content & {
    padding: 5px 10px;
  }

  &.date {
    max-width: 90px;
  }
`
//#endregion

export default function ApplicationHistory(props) {
  const [recruitment, application, company] = useOutletContext()

  const [applications, setApplications] = useState()

  useEffect(() => {
    (async () => {
      const variables = { candidateId: application?.candidate?.id }
      const [error, result] = await to(props.client.query({ query: GET_COMPANY_CANDIDATE_APPLICATIONS, fetchPolicy: 'network-only', variables}))
      if (error || !result) {
        console.error("ApplicationHistory:error: ", error)
        return []
      }
      const { candidate: { applications } = {} } = result.data
      setApplications(applications)
    })()
  }, [])

  if (!applications) {
    return <Spinner />
  }

  const openApplication = (e, id) => {
    e.preventDefault()
    window.open(`/${company}${REK_APPLICATION}/${id}`, id, "width=1200,height=800,location=no")
    return false
  }

  const date = date => {
    if (!date || typeof date !== "string" || date.length < 10) {
      return " "
    }
    return format(parseISO(date), "yy-MM-dd")
  }

  const history = applications.filter(a => a.id !== application.id)

  return (
    <Content>
      <h2>Tidigare ansökningar</h2>
      {history.length === 0 && <p>Det finns inga tidigare ansökningar</p>}
      {history.length > 0 && (
        <Table>
          <Row className="header">
            <TitleCell>Tjänst</TitleCell>
            <InfoArea>
              <InfoCell>Företag</InfoCell>
              <InfoCell className="date">Datum</InfoCell>
            </InfoArea>
          </Row>
          <Rows>
            {map((app) => (
              <div className="row" key={app.id}>
                <div className="titleCell">
                  <a className="link" onClick={(e) => openApplication(e, app.id)}>
                    {app.recruitment.title}
                  </a>
                </div>
                <div className="infoArea">
                  <div className="infoCell">{app.recruitment.company.name}</div>
                  <div className="infoCell date">{date(app.createdAt)}</div>
                </div>
              </div>
            ))(history)}
          </Rows>
        </Table>
      )}
    </Content>
  )
}

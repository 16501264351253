import React from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { NavLink, Outlet } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import styled from "styled-components"
import { history } from "_root/store"
import MainLayout from "_layout/main-layout"
import { SecondaryButton, SecondaryHrefButton } from "_layout/buttons"
import { UserPicker } from "_layout/form-elements"
import Modal from "_components/modal"
import { EMPLOYEE_QUERY } from "_containers/recruitment/recruitment-ql"
import { UPDATE_INTERVIEW_STATUS_SERVER } from "_containers/interview/interview-ql"
import { RecruitmentState } from "_root/constants"
import message from "_components/message"
import * as url from "_routes/url-names"
import inject from "_services/inject"
import { Input } from "_root/layout/form-elements"

//#region Styles
const Header = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 150px;
  border-bottom: 2px solid var(--color-line);
  padding: 25px 40px 0 40px;
  background: var(--color-bg-white);
  z-index: 10;
`
const TitleContainer = styled.div`
  position: relative;
  padding-bottom: 22px;

  .title {
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
  }

  .small {
    padding-top: 4px;
    padding-bottom: 6px;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
`
const Tools = styled.div`
  white-space: nowrap;
`
const Menu = styled.ul`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  left: 30px;
  right: 30px;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-top: 1px solid var(--color-line);
  z-index: 20;
`
const MenuItem = styled.li`
  background: var(--color-bg-white);

  &.wide {
    flex: 1;
    text-align: right;
  }
`
const MenuLink = styled(NavLink)`
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  color: var(--color-text-medium);
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 5px solid transparent;

  &.active,
  &.active:hover {
    color: var(--color-text-dark);
    border-bottom: 5px solid var(--color-brand-red-light);
  }

  &:hover {
    text-decoration: none;
    border-bottom: 5px solid var(--color-bg-grey);
  }
`
const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`
const Copy = styled.p`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 2rem;

  button {
    margin-left: 1rem;
  }
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(EMPLOYEE_QUERY, {
    name: "employeeQuery",
    options: props => ({ variables: { urlName: props.selected ? props.selected.company.urlName : props.match.params.company }, fetchPolicy: "network-only" }),
  }),
  graphql(UPDATE_INTERVIEW_STATUS_SERVER, { name: "updateInterviewStatus" })
)
export default class InterviewOverview extends React.PureComponent {
  constructor(props) {
    super(props)
    this.recruitmentId = props.match.params.recruitment
    this.state = {
      title: "",
      company: {},
      icalDialog: false,
      filter: [],
    }
    this.urlRef = React.createRef()
    this.mounted = false
  }

  componentWillUnmount = () => {
    this.mounted = false
  }

  componentDidMount = () => {
    this.mounted = true
    this.props.updateInterviewStatus()
  }

  componentDidUpdate = () => {
    this.checkNavigation()
  }

  checkNavigation = () => {
    if (!this.state || !this.state.state) return
    const { params } = this.props.match
    if (!params.state) return
    const paramState = `/${params.state}`
    if (paramState === url.REK_NY) return
    const { state } = this.state
    const urlState = state === RecruitmentState.DRAFT ? url.REK_UTKAST : state === RecruitmentState.ACTIVE ? url.REK_AKTIVA : url.REK_AVSLUTADE
    if (paramState !== urlState) {
      history.replace(history.location.pathname.replace(paramState, urlState))
    }
  }

  openDialog = () => {
    this.setState({ icalDialog: true })
  }

  closeDialog = () => {
    this.setState({ icalDialog: false })
  }

  copyToClipboard = async () => {
    navigator.clipboard.writeText(this.urlRef.current.value).then(
      async () => {
        await message("Du kan nu klistra in länken i din kalender.")
        this.closeDialog()
      }
    )
  }

  selectedInterviewers = selected => {
    this.setState({ filter: [...selected] })
  }

  render() {
    const { match, user, employeeQuery } = this.props
    const { icalDialog, filter } = this.state
    const baseUrl = `/${match.params.company}` + url.INTERVIEW
    const calendarUrl = process.env.SERVER_URL + "ics/" + user.id
    const isAdmin = user.isAdmin || user.superAdmin
    const participants = employeeQuery.users ? [...employeeQuery.users] : []
    // Add current user to filter list if another company
    participants.find(e => e.id === user.id) || participants.unshift(user)

    return (
      <MainLayout maxHeight scroll>
        <Header>
          <TitleContainer>
            <div className="small">Hantera</div>
            <span className="title">Tidsbokning</span>
          </TitleContainer>
          <Tools>
            {isAdmin && (
              <UserPicker
                width="300px"
                data-tooltip-id="dateTip"
                data-tooltip-place="top"
                data-tooltip-content="Filtrera på medverkande vid mötet."
                className="mr3"
                users={participants}
                placeholder="Filtrera..."
                selected={filter}
                onSelected={this.selectedInterviewers}
                selectedText="medverkande vald(a)"
                noSelected="Inga medverkande valda"
                noUsers="Inga valbara medverkande"
              />
            )}
            <SecondaryButton className="mt3" onClick={this.openDialog}>
              + min kalender
            </SecondaryButton>
          </Tools>
          <Menu>
            <MenuItem>
              <MenuLink end to={baseUrl}>
                Lediga
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={baseUrl + url.INT_INVITED}>Inbjudna</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={baseUrl + url.INT_BOOKED}>Bokade</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={baseUrl + url.INT_HISTORY}>Historik</MenuLink>
            </MenuItem>
          </Menu>
        </Header>
        <Content id="recruitment-edit-content">
          <Outlet context={[filter]} />
        </Content>
        <Modal isOpen={icalDialog} onRequestClose={this.closeDialog}>
          <h3>Importera till kalender</h3>
          <p>Välj mellan att kopiera webbadressen för prenumeration i din kalender, eller ladda ner fil för import av tider.</p>
          <Copy>
            <Input type="text" ref={this.urlRef} readOnly width="600px" value={calendarUrl} />
            <SecondaryButton onClick={this.copyToClipboard}>Kopiera</SecondaryButton>
          </Copy>
          <div className="tc">
            <SecondaryHrefButton href={calendarUrl} download>
              Ladda ner
            </SecondaryHrefButton>
          </div>
        </Modal>
      </MainLayout>
    )
  }
}

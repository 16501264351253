import React from "react"
import keydown from "react-keydown"
import onClickOutside from "react-onclickoutside"
import styled from "styled-components"
import map from "lodash/fp/map"
import findIndex from "lodash/fp/findIndex"
import filter from "lodash/fp/filter"
import Falafel from "_images/menu-dots.svg"

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: ${props => `${props.width || 300}px`};
  box-shadow: 0px 2px 0px var(--color-line);

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Input = styled.input`
  cursor: pointer;
  width: 100%;
  color: var(--color-text-dark);
  background: url(${Falafel}) scroll 100% 50% no-repeat var(--color-bg-white);
  background-size: 24px 16px;

  &::-webkit-input-placeholder {
    color: var(--color-text-dark);
  }

  &[type="text"] {
    padding-right: 10%;
  }

  &:focus {
    cursor: text;
  }

  &[disabled] {
    background: var(--color-bg-lightest);
  }
`
const Dropdown = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  min-width: 100%;
  flex-flow: column nowrap;
  background: var(--color-bg-white);
  border: 1px solid var(--color-line-dark);
  box-shadow: 2px 2px 10px var(--color-line-dark);
  z-index: 100;

  &:focus {
    outline: none;
  }
`
const ValueList = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
  list-style-type: none;
`
const ListItem = styled.li`
  color: var(--color-text-dark);

  label {
    cursor: pointer;
    display: block;

    span {
      display: block;
      padding: 10px;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked + span {
      color: var(--color-text-white);
      background: var(--color-nav-1);
    }
  }

  &.no-values {
    padding: 10px;
  }

  &:nth-child(even) {
    background: var(--color-bg-lightest);
  }

  &:hover {
    color: var(--color-text-white);
    background: var(--color-nav-1);
  }
`
const Title = styled.h4`
  flex: 0 0 auto;
  margin: 0;
  padding: 5px 10px;
  font-weight: 400;
  color: var(--color-text-white);
  background: var(--color-nav-2);
`
//#endregion

@keydown
@onClickOutside
export default class ValuePicker extends React.Component {
  constructor(props) {
    super(props)
    this.noMatch = props.noMatch || "Inga träffar"
    this.rand = Math.random()
    this.state = {
      showList: false,
      values: props.values || [],
      filtered: props.values || [],
      selected: props.current || null,
      placeholder: ValuePicker.placeholder(props),
      textInput: "",
    }
    this.dd = React.createRef()
    this.textInput = React.createRef()
  }

  static placeholder = props => {
    return props.disabled ? props.disabledPlaceholder || "Ingen vald" : props.placeholder || "Välj..."
  }

  static getDerivedStateFromProps(props, state) {
    const { keydown } = props
    let newState = {
      values: props.values || [],
      filtered: props.values || [],
      selected: props.current || null,
      placeholder: ValuePicker.placeholder(props),
      showList: state.showList,
      textInput: state.textInput,
    }
    if (keydown.event && (keydown.event.which === 27 || keydown.event.which === 13)) {
      newState.showList = false
      newState.textInput = ""
    }
    return newState
  }

  componentDidUpdate() {
    this.checkScroll()
  }

  closeDropDown = () => {
    if (!this.state.showList) return
    const selected = this.state.selected || ""
    this.setState({ showList: false, textInput: selected })
  }

  handleClickOutside = (e) => {
    this.closeDropDown()
  }

  userInput = (e) => {
    const { value } = e.target
    const text = value && value.trim().toLowerCase()
    this.setState({ filterText: text, textInput: value })
  }

  inputFocus = (e) => {
    if (this.textInput && this.textInput.current) {
      this.pristine = this.state.selected
      const docHeight = document.body.offsetHeight
      const inputRect = this.textInput.current.getBoundingClientRect()
      const showListAbove = inputRect.top > docHeight / 2
      const listMaxHeight = 250
      const dropStyle = showListAbove ? { maxHeight: listMaxHeight, bottom: "100%" } : { maxHeight: listMaxHeight, top: "100%" }
      this.setState({ showList: true, dropStyle: dropStyle, textInput: "" })
    }
  }

  keyDown = (e) => {
    if (e.which === 9 || e.which === 13) {
      this.closeDropDown()
    } else if (e.which === 27) {
      this.setState({ selected: this.pristine }, this.closeDropDown)
    } else if (e.which === 38 || e.which === 40) {
      // UP or DOWN arrow
      const { selected, filtered } = this.state
      const index = findIndex((o) => o === selected)(filtered) + (e.which === 38 ? -1 : 1)
      if (index < 0 || index === filtered.length) return
      const newSelected = filtered[index]
      this.setState({ selected: newSelected, placeholder: newSelected, textInput: newSelected })
      this.props.onSelected(newSelected)
    }
  }

  checkScroll = () => {
    if (!this.state.showList || !this.state.selected || !this.dd.current) return
    const ddBounds = this.dd.current.getBoundingClientRect()
    const index = findIndex((o) => o === this.state.selected)(this.state.filtered)
    const selected = document.getElementById(this.rand + index)
    if (!selected) return
    const selectedBounds = selected.getBoundingClientRect()
    if (selectedBounds.top < ddBounds.top) {
      this.dd.current.scrollTop -= ddBounds.top - selectedBounds.top
    } else if (selectedBounds.bottom > ddBounds.bottom) {
      this.dd.current.scrollTop += selectedBounds.bottom - ddBounds.bottom
    }
  }

  selectValue = (e) => {
    if (e.target.value && e.target.checked) {
      const { value } = e.target
      if (value !== this.state.selected) {
        this.setState({ placeholder: value, selected: value, showList: false, textInput: value })
        this.props.onSelected && this.props.onSelected(value)
      }
    } else if (this.state.selected !== null) {
      const selected = this.props.required ? this.state.selected : null
      this.setState({ placeholder: this.props.placeholder || "Välj...", selected, showList: false, textInput: selected ? selected.name : "" })
      this.props.onSelected(selected)
    }
  }

  render() {
    let valueList = null
    const { showList, dropStyle, filterText, values, selected, placeholder } = this.state
    if (showList) {
      const filtered = filterText
        ? filter((item) => {
            return item.toLowerCase().indexOf(filterText) > -1
          })(values)
        : [...values]
      if (filtered && filtered.length) {
        let index = 0
        valueList = map((item) => (
          <ListItem key={index} id={this.rand + index++}>
            <label>
              <input tabIndex="0" type="checkbox" className="value-picker-dropdown-input" value={item} onChange={this.selectValue} defaultChecked={item === selected} />
              <span>{item}</span>
            </label>
          </ListItem>
        ))(filtered)
      } else {
        valueList = (
          <ListItem key="0" className="no-values">
            {this.noMatch}
          </ListItem>
        )
      }
    }

    return (
      <Content width={this.props.width}>
        <Input
          type="text"
          placeholder={selected || placeholder || ""}
          ref={this.textInput}
          value={this.state.textInput}
          disabled={this.props.disabled}
          onChange={this.userInput}
          onKeyDown={this.keyDown}
          onFocus={this.inputFocus}
        />
        {showList && (
          <Dropdown tabIndex="1" className={"value-picker-dropdown"} style={dropStyle}>
            <Title>{this.props.placeholder || "Välj..."}</Title>
            <ValueList ref={this.dd}>{valueList}</ValueList>
          </Dropdown>
        )}
      </Content>
    )
  }
}

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { map, filter } from 'lodash/fp'
import { isAfter, parseISO, differenceInHours } from "date-fns"
import message from '_components/message'
import draftToHtml from 'draftjs-to-html'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import ManageInterview from '_root/components/interview/manage-interview'
import { PrimaryButton, CancelButton } from '_layout/buttons'
import { Spinner, Radio } from '_layout/form-elements'
import { withEmailApplicationMutation } from '_modules/applications'
import { VACANT_INTERVIEWS } from '_containers/interview/interview-ql'
import { NEW_INTERVIEW_MUTATION, BOOK_APP_INTERVIEW_MUTATION, SET_APP_INTERVIEW_MUTATION } from '_containers/interview/interview-ql'
import * as url from '_routes/url-names'
import to from '_services/await.to'
import inject from '_services/inject'
import all from '_root/services/await.all'
import ctrlKey from '_utils/ctrlKey'

//#region Styles
const Wrapper = styled.div`
    #tooltip {
        max-width: 500px;
    }
`
const TimeSlots = styled.div`
    margin: 0 0 1em 0;
    width: 750px;
    height: 264px;
    background: var(--body-bg);

    > a {
        margin-bottom: 0.5rem;
        display: inline-block;
        user-select: none;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--color-brand-red-light);
        }
    }
`
const TimeSlotsList = styled.ul`
    overflow: auto;
    height: 200px;
    margin: 0 0 0.5rem 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid var(--color-bg-light);

    li {
        padding: 10px;
        font-size: 0.9rem;
        border-bottom: 1px solid var(--color-bg-light);

        &:last-child {
            border-bottom: none;
        }

        &.divider {
            padding: 5px 10px;
            background: var(--color-bg-lightest);
        }

        > .radio {
            margin-bottom: 0;
            width: 120px;
            vertical-align: text-top;
        }

        > span {
            display: inline-block;
            width: 120px;
            margin-right: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: text-top;

            &:nth-of-type(1) {
                width: 2rem;
            }
        }
    }
`
const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`
//#endregion

@compose(
    inject('user'),
    graphql(VACANT_INTERVIEWS, { name: 'interviewsQuery', options: props => ({ variables: { company: props.companyId } }) }),
    graphql(NEW_INTERVIEW_MUTATION, { name: 'newInterview' }),
    graphql(BOOK_APP_INTERVIEW_MUTATION, { name: 'bookApplicationInterview' }),
    graphql(SET_APP_INTERVIEW_MUTATION, { name: 'setApplicationInterview' }),
    withEmailApplicationMutation
)
export default class ApplicationBooking extends React.Component {
    state = {
        loading: true,
        createDialog: false,
        bookedId: null,
        editorKey: null
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (!prevState.loading) return null
        const applications = filter(a => a.candidate && a.candidate.user)(nextProps.applications || [])
        return {
            loading: false,
            templates: nextProps.templates || [],
            recipients: map(a => a.candidate.user.email)(applications),
            applications: applications,
            sender: nextProps.user.email || '',
            name: ''
        }
    }

    onSenderChange = e => {
        this.setState({sender: e.target.value})
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onAddLink = () => {
        const bookingUrl = `${window.location.protocol}//${window.location.host}${url.MEDLEM}${url.BOKNING}`
        let { contentHTML = '', editorKey } = this.state
        contentHTML += `<p><a href="${bookingUrl}">${bookingUrl}</a></p>`
        this.setState({ contentHTML, editorKey: editorKey + '_edit' })
    }

    onCreateInterview = () => {
        this.setState({ createDialog: true })
    }

    onSaveInterview = async interview => {
        const variables = {
            createdBy: this.props.user.id,
            companyId: this.props.user.companyView.id,
            startTime: interview.startTime,
            endTime: interview.endTime,
            spots: interview.spots,
            note: interview.note,
            location: interview.location,
            guest: interview.guest,
            interviewers: interview.interviewers.map(i => ({ id: i.id })),
            interviewersExternal: interview.interviewersExternal.map(i => ({ id: i.id })),
        }
        this.setState({ createDialog: false })
        const [error, result] = await to(this.props.newInterview({ variables: variables }))
        if (error) {
            console.error('VacantInterviews.onSaveInterview', error)
        }
        else if (result) {
            this.props.interviewsQuery.refetch()
        }
        this.setState({ createDialog: false })
    }

    onContentChange = contentHTML => {
        this.setState({contentHTML})
    }

    onSelectSlot = e => {
      e.currentTarget.value && this.setState({ bookedId: e.currentTarget.value })
    }

    onSelected = template => {
        if (!template) return
        this.setState({
            name: template.name || '',
            contentHTML: template.contentHTML || draftToHtml(template.content),
            selected: template,
            editorKey: template.id
        })
    }

    rebook = async bookedId => {
        const booked = this.props.application.interviews.find(i => i.interview.id === bookedId)
        const previous = this.props.application.interviews.find(i => i.status === 'BOOKED')
        let promises
        if (booked) {
            promises = [
                this.props.setApplicationInterview({ variables: { id: booked.id, status: 'BOOKED' } }),
                this.props.setApplicationInterview({ variables: { id: previous.id, status: 'DECLINED' } })
            ]
        }
        else {
            promises = [
                this.props.setApplicationInterview({ variables: { id: previous.id, status: 'DECLINED' } }),
                this.props.bookApplicationInterview({ variables: { applicationId: this.props.application.id, interviewId: bookedId } })
            ]
        }
        return await all(promises)
    }

    onSend = async e => {
        const { name, contentHTML, applications, recipients, sender, bookedId } = this.state
        if (!sender || !name || !contentHTML || !recipients || !recipients.length) return
        const booked = this.props.application.interviews.find(i => i.status === 'BOOKED')
        if (booked && booked.interview.id === bookedId) {
            message('Du kan inte boka samma tid på nytt. Vänligen välj en annan tid.')
            return
        }
        this.setState({sending: true})
        const [errors] = await this.rebook(bookedId)
        if (errors) {
            console.error('application-booking:rebook:errors: ', errors);
            this.props.onError && this.props.onError(errors)
            message('Ett fel uppstod. Vänligen försök igen eller kontakta kundtjänst.')
            return
        }
        await message('E-post skickas i bakgrunden till ' + (recipients.length > 1 ? 'valda kandidater.' : 'vald kandidat.'), { text: 'Du kan fortsätta arbeta under tiden.' })
        this.onCancel()
        const [error] = await to(this.props.emailApplication({
            from: sender,
            recruitmentId: this.props.recruitmentId,
            to: map(a => ({
                applicationId: a.id,
                email: a.candidate.user.email
            }))(applications),
            subject: name,
            html: contentHTML,
            group: false
        }))
        if (error) {
            console.error('application-mailing:onSend:error: ', error);
            this.props.onError && this.props.onError(error)
        }
        else {
            this.props.onUpdate && this.props.onUpdate()
        }
    }

    onCancel = e => {
        this.setState({name: '', contentHTML: null, sending: false, errorMessage: null, editorKey: null})
        this.props.onClose()
    }

    render() {
        const { loading, vacantInterviews = [] } = this.props.interviewsQuery
        const { application } = this.props
        const { sender, name, contentHTML, selected, templates, recipients, sending, errorMessage, bookedId, createDialog, editorKey } = this.state
        const canSend = sender && name && contentHTML && recipients && recipients.length
        const booked = application.interviews.filter(i => i.status === 'BOOKED').map(i => i.interview)
        const declined = application.interviews.filter(i => i.status === 'DECLINED' && i.interview.vacantSpots > 0 && isAfter(i.interview.startTime, new Date())).map(i => i.interview)
        const invited = [...booked, ...declined]
        const vacant = vacantInterviews.filter(v => !invited.find(i => i.id === v.id))

        return (
            <Wrapper>
                <h3>Tidsbokning</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <label className='mb2 db'>Från:</label>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Från'}
                    value={sender}
                    onChange={this.onSenderChange} />
                <label className='mb2 db'>Skickas till: (endast synligt för administratörer)</label>
                <div className='mb3'>{recipients.join(', ')}</div>
                <ObjectPicker
                    form={'true'}
                    className='mb3'
                    values={templates}
                    placeholder='Välj mall...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga mallar' />
                <TimeSlots>
                    <a onClick={this.onCreateInterview}>+ skapa mötestid</a>
                    {loading &&
                    <Spinner/>
                    }
                    {!loading &&
                    <TimeSlotsList>
                        <li className="divider">Tidigare inbjudna tider</li>
                        {invited.map((i, index) =>
                        <li key={i.id}>
                            <Radio
                                id={i.id}
                                name="booking"
                                className="radio"
                                value={i.id}
                                label={format(parseISO(i.startTime), 'dd MMM HH:mm')}
                                title='Välj ny tid'
                                onClick={this.onSelectSlot}
                                checked={(!bookedId && !index) || bookedId === i.id} />
                            <span>{differenceInHours(parseISO(i.endTime), parseISO(i.startTime))}h</span>
                            <span data-tooltip-content={i.location} data-tooltip-id="tooltip">{i.location}</span>
                            <span data-tooltip-html={`${i.interviewers.map(iv => iv.fullName).join('<br/>')}`} data-tooltip-id="tooltip">{i.interviewers.map(iv => iv.firstName).join(', ')}</span>
                            <span>{i.vacantSpots} {i.vacantSpots !== 1 ? 'platser' : 'plats'}</span>
                        </li>)}
                        <li className="divider">Övriga lediga tider</li>
                        {vacant.map(i =>
                        <li key={i.id}>
                            <Radio
                                id={i.id}
                                name="booking"
                                className="radio"
                                value={i.id}
                                label={format(parseISO(i.startTime), 'dd MMM HH:mm')}
                                title='Välj ny tid'
                                onClick={this.onSelectSlot}
                                checked={bookedId === i.id} />
                            <span>{differenceInHours(parseISO(i.endTime), parseISO(i.startTime))}h</span>
                            <span data-tooltip-content={i.location} data-tooltip-id="tooltip">{i.location}</span>
                            <span data-tooltip-html={`${i.interviewers.map(iv => iv.fullName).join('<br/>')}`} data-tooltip-id="tooltip">{i.interviewers.map(iv => iv.firstName).join(', ')}</span>
                            <span>{i.vacantSpots} {i.vacantSpots !== 1 ? 'platser' : 'plats'}</span>
                        </li>)}
                    </TimeSlotsList>
                    }
                    <a onClick={this.onAddLink}>+ infoga bokningslänk i slutet av meddelande</a>
                </TimeSlots>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Ämne'}
                    value={name}
                    onChange={this.onNameChange} />
                <TinyEditor
                    key={editorKey}
                    form
                    placeholder={'Fyll i din mall här... (klistra in med ' + ctrlKey + '+V)'}
                    content={contentHTML}
                    onChange={this.onContentChange}
                    onDirty={this.props.onDirty}
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 260px)', minHeight: 300 }} />
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                    <PrimaryButton onClick={this.onSend} disabled={!canSend} loading={sending}>Skicka</PrimaryButton>
                </div>
                <ManageInterview
                    open={createDialog}
                    onClose={e => this.setState({createDialog: false})}
                    onCreate={this.onSaveInterview} />
                <Tooltip id='tooltip' place='bottom' effect='solid' multiline={true} />
            </Wrapper>
        )
    }
}

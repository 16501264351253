import store from "store"

export const getHidingParent = node => {
    if (!node || node.nodeType !== 1) return null
    let overflowY = window.getComputedStyle(node).overflowY
    let isScrollable = overflowY !== "visible"
    if (isScrollable) {
        return node
    } else {
        return getHidingParent(node.parentNode)
    }
}

export const setCookie = (name, value, days) => {
    let expires = ""
    if (days) {
        let date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

export const getCookie = name => {
    let nameEQ = name + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export const eraseCookie = name => {
    document.cookie = name + "=; Max-Age=-99999999;"
}

export const setStore = (name, value) => {
    store.set(name, value)
}

export const getStore = name => {
    return store.get(name)
}

export const eraseStore = name => {
    store.remove(name)
}

export const isLive = () => {
    return window.location.href.indexOf('rc.jobbet.se') > -1
}
import { gql } from "@apollo/client"

export const ACTIVE_COMPANIES = gql`
  query ActiveCompanies($take: Int, $skip: Int) {
    aggregateCompany(where: { AND: [{ group: { equals: false } }, { active: { equals: true } }] }) {
      _count {
        _all
      }
    }
    companies(where: { AND: [{ group: { equals: false } }, { active: { equals: true } }] }, orderBy: { name: asc }, take: $take, skip: $skip) {
      id
      active
      name
      urlName
      groups {
        id
        name
      }
      category
      careerPage
      createdAt
      policyAccepted
      employeeCount
      activeRecruitmentsCount
      closedRecruitmentsCount
      messageLogCount
      hasApplications
      hasAddons
      hasChannels
      jobbetContacts {
        id
      }
    }
  }
`
export const INACTIVE_COMPANIES = gql`
  query InactiveCompanies {
    companies(where: { AND: [{ group: { equals: false } }, { active: { equals: false } }] }, orderBy: { name: asc }) {
      id
      active
      name
      urlName
      groups {
        id
        name
      }
      category
      careerPage
      createdAt
      policyAccepted
      employeeCount
      activeRecruitmentsCount
      closedRecruitmentsCount
      messageLogCount
      hasApplications
      hasAddons
      hasChannels
      jobbetContacts {
        id
      }
    }
  }
`
export const COMPANY_MESSAGE_LOGS_QUERY = gql`
  query CompanyMessageLogs($companyId: String!) {
    company(where: { id: $companyId }) {
      messageLogs {
        id
        createdAt
        from
        to {
          id
          value
        }
        subject
        body
      }
    }
  }
`
export const DELETE_COMPANY = gql`
  mutation DeleteCompany($companyId: String!) {
    deleteFullCompany(id: $companyId) {
      id
    }
  }
`

export const JOBBET_USERS_QUERY = gql`
  query UserQuery {
    users(where: { role: { in: [SUPERADMIN, SITEADMIN] }, disabled: { equals: false } }) {
      id
      firstName
      lastName
    }
  }
`

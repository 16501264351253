import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { AuditLogList } from '_modules/audit'

const Content = styled.div`
  padding: 40px;
`

export default function ApplicationLogbook() {
  const [recruitment, application, company] = useOutletContext()
  return <Content>
    <h2>Loggbok</h2>
    <AuditLogList entityName="Application" entityId={application?.id} />
  </Content>
}

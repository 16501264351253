import React from 'react'
import { Navigate } from 'react-router-dom'
import { LOGIN, MEDLEM } from '_routes/url-names'
import inject from '_services/inject'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import message from '_root/components/message';

@compose(
  withApollo,
  inject('user')
)
export default class CommonRoute extends React.Component {

  componentDidMount = () => {
    const { user, location } = this.props
    let referrer = location?.state?.referrer
    if (!user.loading && !user.isCandidate && referrer && referrer === MEDLEM) {
      message('Du är inloggad som en systemanvändare och kan därför inte se medlemssidorna.')
    }
  }

  render() {
    const { location, children, authenticate, user } = this.props
    if (authenticate && !user.loggedIn) {
      return <Navigate to={LOGIN} state={{ from: location }} />
    }
    return React.Children.only(children)
  }
}

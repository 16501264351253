import React from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import ToolsMenu from '_components/menu/tools-menu'
import CandidateMenu from '_components/menu/candidate-menu'
import SearchField from '_modules/search'
import LogoImage from '_images/rc-jobbet.png'
import isLoggedIn from '_services/is-logged-in'
import inject from '_services/inject'

//#region Styles
const Head = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 30px;
  flex: 0 0 65px;
  height: 65px;
  border-bottom: 1px solid var(--color-line);
  background: var(--color-bg-white);
  z-index: 10000;

  @media (max-width: 767px) {
    padding: 0 0 0 80px;
  }
`
const LogoLink = styled(Link) `
    display: inline-block;

    @media screen and (max-width: 767px) {
      display: none;
    }
`
const Logo = styled.img`
    width: auto;
    height: 40px;

    @media (max-width: 500px) {
        height: 35px;
    }
`
//#endregion Styles
@compose(
  withRouter,
  inject('user')
)
export default class Header extends React.Component {

  state = {
    search: false
  }

  onSearch = () => {
    this.setState({ search: true })
  }

  onSearchClose = () => {
    this.setState({ search: false })
  }

  render() {

    const loggedIn = isLoggedIn()
    const inApp = !!this.props.match.params.company
    const { search } = this.state

    return (
      <Head>
        <LogoLink to={inApp ? `/${this.props.match.params.company}` : "/"}  title=' Jobbet.se'>
            <Logo src={LogoImage} alt=' Jobbet.se logotyp' title=' Jobbet.se' />
        </LogoLink>
        {loggedIn && inApp ? <SearchField onSearchClose={this.onSearchClose} search={search} /> : null}
        {loggedIn && inApp ? <ToolsMenu onSearch={this.onSearch} /> : null}
        {loggedIn && this.props.user.isCandidate ? <CandidateMenu user={this.props.user} /> : null}
      </Head>
    )
  }
}

import React from 'react'
import withRouter from '_hoc/withRouter'
import styled from 'styled-components'

import { KANDIDAT } from '_routes/url-names'
import { PrimaryButton } from '_layout/buttons';
import {
    ResultList,
    ResultItem,
    SimpleLink
} from '_modules/search/common-layout/result-lists'
import ApplicationList from '../application-list'

const ResultListWrapper = styled.div`
    overflow: auto;
    flex: 1;

    & .resultlist__fetchmore {
        display: block;
        margin: 20px auto;
    }

    a {
        color: var(--color-brand-red);
    }
`

const StyledApplicationList = styled(ApplicationList)`
    margin-left: auto;
`

const DetailedCandidateResult = props => {
    const { candidates, total, onFetchMoreResults, isFetchingMore } = props
    const openCandidateProfile = (e, id) => {
        e.preventDefault()
        const { match: { params: routeParams } } = props
        window.open(`/${routeParams.company}${KANDIDAT}/${id}`, id, 'width=1200,height=800,location=no')
        return false
    }

    return (
        <ResultListWrapper>
            <ResultList>
            {
                candidates.map((result) => 
                    <ResultItem key={result.user.id}>
                        <SimpleLink onClick={(e) => openCandidateProfile(e, result.id)}>{ `${result.user.firstName} ${result.user.lastName}` }</SimpleLink>
                        <StyledApplicationList applications={result.applications} />
                    </ResultItem>
                )
            }
            </ResultList>
            { (total > candidates.length) && <PrimaryButton loading={isFetchingMore} className="resultlist__fetchmore" onClick={ onFetchMoreResults }>Visa fler</PrimaryButton> }
        </ResultListWrapper>
    )
}

export default withRouter(DetailedCandidateResult)
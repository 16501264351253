import React from 'react'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { map } from 'lodash/fp'
import message from '_components/message'
import TinyEditor from '_components/tiny-editor'
import { PrimaryButton } from '_layout/buttons'
import { CancelButton } from '_layout/buttons'
import { withEmailCandidateMutation } from '_modules/applications'
import to from '_services/await.to'
import inject from '_services/inject'

const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`

@compose(
    inject('user'),
    withEmailCandidateMutation
)
export default class CandidateMailing extends React.Component {
    state = {
      loading: true
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
      if (!prevState.loading) return null
      return {
        loading: false,
        recipients: nextProps.candidates ? map(c => ({
          candidateId: c.id,
          email: c.user.email
        }))(nextProps.candidates) : [],
        sender: nextProps.user.email || '',
        subject: ''
      }
    }

    onSenderChange = e => {
        this.setState({sender: e.target.value})
    }

    onSubjectChange = e => {
        this.setState({subject: e.target.value})
    }

    onContentChange = content => {
        this.setState({content})
    }

    onSend = async e => {
        this.setState({sending: true})
        const { subject, content, recipients, sender } = this.state
        if (!sender || !subject || !content || !recipients || !recipients.length) return
        const [error] = await to(this.props.emailCandidate({
            from: sender,
            to: recipients,
            subject: subject,
            html: content,
            group: false
        }))
        if (error) {
            console.error('candidate-mailing:onSend:error: ', error);
            this.setState({sending: false, errorMessage: 'Något gick fel när e-post skulle skickas.'})
        }
        else {
            this.setState({sending: false, errorMessage: null}, () => {
                this.onUpdate()
                this.onCancel()
            })
            message('E-post har skickats till valda kandidater.')
        }
    }

    onDirty = e => {}

    onUpdate = () => {
      this.props.onUpdate()
    }

    onCancel = e => {
      this.setState({subject: '', content: null})
      this.props.onClose()
    }

    render() {
        const { sender, subject, content, recipients, sending, errorMessage } = this.state

        const canSend = sender && subject && content && recipients && recipients.length
        const recipientEmails = map(c => c.email)(recipients)

        return (
            <div>
                <h3>Utskick till kandidater</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <label className='mb2 db'>Från:</label>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Från'}
                    value={sender}
                    onChange={this.onSenderChange} />
                <label className='mb2 db'>Skickas till: (endast synligt för administratörer)</label>
                <div className='mb3'>{recipientEmails.join(', ')}</div>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Ämne'}
                    value={subject}
                    onChange={this.onSubjectChange} />
                <TinyEditor
                    placeholder='Meddelande'
                    form
                    insert='h3 bold italic underline | bullist numlist | link table'
                    selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                    plugins='autolink link lists image table'
                    content={content}
                    onChange={this.onContentChange}
                    onDirty={this.props.onDirty}
                    heading
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 400px)', minHeight: 300 }} />
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                    <PrimaryButton onClick={this.onSend} disabled={!canSend} loading={sending}>Skicka</PrimaryButton>
                </div>
            </div>
        )
    }
}
import React from 'react'
import styled from 'styled-components'
import { SecondaryButton } from '_layout/buttons'

const StyledButton = styled(SecondaryButton)`
    pointer-events: inherit;
    width: 130px;

    & + .react-datepicker__close-icon {
        &::after {
            background-color: var(--color-brand-green-dark);
        }
    }
`

export default class DateButton extends React.Component {
  render() {
    return <StyledButton onClick={this.props.onClick} style={this.props.style} data-tooltip-id="root-tooltip" data-tooltip-content={this.props.label}>{this.props.value || this.props.label}</StyledButton>
  }
}

import 'babel-polyfill'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as url from '_routes/url-names'
import AuthRoute from '_routes/authenticated-route'
import { SearchRoute } from '_modules/search'
import AfterLogin from '_root/containers/after-login'
import CustomerService from '_containers/customer-service'
import Overview from '_containers/recruitment/overview'
import NewRecruitment from '_containers/recruitment/recruitment-new'
import RecruitmentTemplates from '_containers/settings/recruitment-templates'
import RecruitmentMailings from '_containers/settings/recruitment-mailings'
import RecruitmentApplication from '_containers/recruitment/recruitment-application'
import RecruitmentApplications from '_containers/recruitment/recruitment-applications'
import RecruitmentImages from '_containers/settings/recruitment-images'
import RecruitmentEdit from '_containers/recruitment/recruitment-edit'
import RecruitBaseEdit from '_components/recruit/recruit-base-edit'
import Advertise from "_components/recruit/advertise"
import Publishing from "_root/components/recruit/publishing"
import RecruitmentInfo from "_components/recruit/recruitment-info"
import Planning from "_components/recruit/planning"
import LogBook from "_components/recruit/logbook"
import WorkDocuments from '_components/recruit/work-documents'
import ApplicationList from '_components/applications/application-list'
import ApplicationCurrent from '_containers/application/application-current'
import ApplicationHistory from '_containers/application/application-history'
import ApplicationLogbook from '_containers/application/application-logbook'
import WorkDocumentsApplication from '_components/recruit/work-documents-application'
import Candidates from '_containers/candidates'
import CandidateProfile from '_containers/candidate-profile'
import Statistics from '_containers/statistics'
import Interview from '_containers/interview/overview'
import UserProfile from '_containers/settings/user-profile'
import GroupProfile from '_containers/settings/group-profile'
import CompanyProfile from '_containers/settings/company-profile'
import CompanyDocs from '_containers/settings/company-docs'
import CompanySelection from '_containers/settings/company-selection'
import Departments from '_containers/settings/departments'
import Users from '_containers/settings/users'
import CreateUser from '_containers/settings/create-user'
import EditUser from '_containers/settings/edit-user'
import Permission from '_containers/settings/permission'
import CVDatabase from '_containers/settings/cv-database'
import AdminCompanies from '_containers/admin/companies'
import AdminNewCompany from '_containers/admin/new-company'
import AdminGroups from '_containers/admin/groups'
import AdminNewGroup from '_containers/admin/new-group'
import AdminTemplates from '_containers/admin/templates'
import AdminPermission from '_containers/admin/permission'
import AdminSite from '_containers/admin/site-admin'
import VacantInterviews from '_containers/interview/vacant'
import InvitedInterviews from '_containers/interview/invited'
import BookedInterviews from '_containers/interview/booked'
import ClosedInterviews from '_containers/interview/closed'
import Page404 from '_containers/404'
import ApplicationProtocol from '_root/containers/application/application-protocol'

import store, { history } from './store'
import client from './apollo-client'
import AppRoot from './app-root'
import CommonRoute from '_routes/common-route'
import StartRoute from '_routes/start-route'
import MemberRoute from '_routes/member-route'
import Login from '_containers/login'
import ShowAd from '_containers/show-ad'
import ShowSelection from '_containers/show-selection'
import Apply from '_containers/apply'
import Activate from '_containers/activate-account'
import Member from '_root/containers/member/member'
import MemberProfile from '_root/containers/member/member-profile'
import MemberApplications from '_root/containers/member/member-applications'
import MemberAd from '_root/containers/member/member-ad'
import MemberAds from '_root/containers/member/member-ads'
import MemberSettings from '_root/containers/member/member-settings'
import MemberBooking from '_root/containers/member/member-booking'
import MemberConfirmation from '_root/containers/member/member-confirmation'
import MemberRegistration from '_root/containers/member/member-registration'
import Console from '_containers/console'
import '_styles/index.scss'
import MemberLogin from './containers/member/member-login'

function App() {
  return <DndProvider backend={HTML5Backend}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <AppRoot>
            <Routes>
              <Route path={url.ROOT} element={<CommonRoute><StartRoute /></CommonRoute>} />
              <Route path={url.ANSOK + url.REK_ID} element={<CommonRoute><Apply /></CommonRoute>} />
              <Route path={url.LOGIN} element={<CommonRoute><Login /></CommonRoute>} status={200} />
              <Route path={url.AKTIVERA + url.AKTIVERA_ID} element={<CommonRoute><Activate /></CommonRoute>} />
              <Route path={url.ANNONS + url.ANNONSID} element={<CommonRoute><ShowAd /></CommonRoute>} />
              <Route path={url.URVAL + url.ANNONSID} element={<CommonRoute><ShowSelection /></CommonRoute>} />
              <Route path={url.CONSOLE} element={<CommonRoute><Console /></CommonRoute>} />

              <Route path={url.MEDLEM} element={<Member />}>
                <Route path={url.MEDLEM + url.LOGIN} element={<MemberLogin />} />
                <Route path={url.MEDLEM + url.REGISTRERA} element={<MemberRegistration />} />
                <Route path={url.MEDLEM + url.UPPDATERAD} element={<MemberConfirmation />} />
                <Route path={url.MEDLEM + url.ANNONS + url.ANNONSID} element={<MemberAd />} />
                <Route path={url.MEDLEM + url.ANSOKNINGAR} element={<MemberRoute><MemberApplications /></MemberRoute>} />
                <Route path={url.MEDLEM + url.ANNONSER} element={<MemberRoute><MemberAds /></MemberRoute>} />
                <Route path={url.MEDLEM + url.INSTALLNINGAR} element={<MemberRoute><MemberSettings /></MemberRoute>} />
                <Route path={url.MEDLEM + url.PROFIL} element={<MemberRoute><MemberProfile /></MemberRoute>} />
                <Route path={url.MEDLEM + url.BOKNING} element={<MemberRoute><MemberBooking /></MemberRoute>} />
              </Route>

              <Route path={url.COMPANY + url.SOK} element={<AuthRoute component={SearchRoute} />}/>
              <Route path={url.COMPANY + url.KUNDSERVICE} element={<AuthRoute component={CustomerService} />} />
              <Route path={url.COMPANY + url.REKRYTERING + url.REK_NY} element={<AuthRoute component={NewRecruitment} />} />
              <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE} element={<AuthRoute component={Overview} />} />
              <Route path={url.COMPANY + url.REK_PROTOCOL + url.REK_PROTOCOL_ID} element={<AuthRoute component={ApplicationProtocol} />} />
              <Route path={url.COMPANY + url.REK_APPLICATION + url.REK_APPLICATION_ID} element={<AuthRoute component={RecruitmentApplication} />}>
                <Route index element={<AuthRoute component={ApplicationCurrent} /> } />
                <Route path={url.COMPANY + url.REK_APPLICATION + url.REK_APPLICATION_ID + url.REK_APP_HISTORY} element={<AuthRoute component={ApplicationHistory} /> } />
                <Route path={url.COMPANY + url.REK_APPLICATION + url.REK_APPLICATION_ID + url.REK_LOGGBOK} element={<AuthRoute component={ApplicationLogbook} /> } />
                <Route path={url.COMPANY + url.REK_APPLICATION + url.REK_APPLICATION_ID + url.REK_APP_DOK} element={<AuthRoute component={WorkDocumentsApplication} /> } />
              </Route>
              <Route path={url.COMPANY + url.REKRYTERING + url.REK_NY + url.REK_ID} element={<AuthRoute component={RecruitmentEdit} />} />
              <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_APPLICATIONS} element={<AuthRoute component={RecruitmentApplications} />}>
                <Route index element={<AuthRoute component={ApplicationList} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_APPLICATIONS + url.REK_APP_DOK} element={<AuthRoute component={WorkDocuments} />} />
              </Route>
              <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID} element={<AuthRoute component={RecruitmentEdit} />}>
                <Route index element={<AuthRoute component={RecruitBaseEdit} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_ANNONS} element={<AuthRoute component={Advertise} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_KANALER} element={<AuthRoute component={Publishing} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_INFO} element={<AuthRoute component={RecruitmentInfo} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_PLANERA} element={<AuthRoute component={Planning} />} />
                <Route path={url.COMPANY + url.REKRYTERING + url.REK_STATE + url.REK_ID + url.REK_LOGGBOK} element={<AuthRoute component={LogBook} />} />
              </Route>
              <Route path={url.COMPANY + url.INTERVIEW} element={<AuthRoute component={Interview} />}>
                <Route index element={<AuthRoute component={VacantInterviews} />} />
                <Route path={url.COMPANY + url.INTERVIEW + url.INT_INVITED} element={<AuthRoute component={InvitedInterviews} />} />
                <Route path={url.COMPANY + url.INTERVIEW + url.INT_BOOKED} element={<AuthRoute component={BookedInterviews} />} />
                <Route path={url.COMPANY + url.INTERVIEW + url.INT_HISTORY} element={<AuthRoute component={ClosedInterviews} />} />
              </Route>
              <Route path={url.COMPANY + url.REKRYTERING} element={<AuthRoute component={AfterLogin} />} />
              <Route path={url.COMPANY + url.KANDIDAT + url.KANDIDAT_ID} element={<AuthRoute component={CandidateProfile} />} />
              <Route path={url.COMPANY + url.KANDIDAT} element={<AuthRoute component={Candidates} />} />
              <Route path={url.COMPANY + url.STATISTIK} element={<AuthRoute component={Statistics} />} />
              <Route path={url.COMPANY + url.INST_MY_PROFILE} element={<AuthRoute component={UserProfile} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_GROUP} element={<AuthRoute component={GroupProfile} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_COMPANY} element={<AuthRoute component={CompanyProfile} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_DEPARTMENTS} element={<AuthRoute component={Departments} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_USERS} element={<AuthRoute component={Users} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_USERS + url.INST_NY} element={<AuthRoute component={CreateUser} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_USERS + url.PAR_ID} element={<AuthRoute component={EditUser} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_PERMISSION} element={<AuthRoute component={Permission} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_CV_DB} element={<AuthRoute component={CVDatabase} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_FILER} element={<AuthRoute component={CompanyDocs} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_MALLAR} element={<AuthRoute component={RecruitmentTemplates} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_UTSKICK} element={<AuthRoute component={RecruitmentMailings} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_URVAL} element={<AuthRoute component={CompanySelection} />} />
              <Route path={url.COMPANY + url.INSTALLNINGAR + url.INST_BILDBANK} element={<AuthRoute component={RecruitmentImages} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_COMPANIES} element={<AuthRoute component={AdminCompanies} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_NEW_COMPANY} element={<AuthRoute component={AdminNewCompany} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_GROUPS} element={<AuthRoute component={AdminGroups} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_NEW_GROUP} element={<AuthRoute component={AdminNewGroup} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_TEMPLATES} element={<AuthRoute component={AdminTemplates} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_PERMISSION} element={<AuthRoute component={AdminPermission} />} />
              <Route path={url.COMPANY + url.ADMIN + url.ADMIN_SITE} element={<AuthRoute component={AdminSite} />} />
              <Route path={url.COMPANY} element={<AuthRoute component={AfterLogin} />} />
              <Route element={<Page404 />} />
            </Routes>
          </AppRoot>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </DndProvider>
}

createRoot(document.querySelector('#root')).render(<App />)

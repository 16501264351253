import { QueryProvider, FilterProviderMediator } from '_utils/graphql';

const getBaseQueryFilters = (runtimeContext) => {
  const { props } = runtimeContext
  const queryInput = props.queryInput.trim() || ""
  return { name: { contains: `"${queryInput}"` }}
}

/**
 * @function buildFilterFor
 * @param  {type} userContext {description}
 * @return {type} {description}
 */
const filterProvider = {
  getContextForSuperadmin: (runtimeContext) => {
    return {
      filters: {
        "companies": getBaseQueryFilters(runtimeContext)
      }
    }
  },

  getContextForGroupAdmin: (runtimeContext) => {
    return {
      filters: {
        "companies": {
          ...getBaseQueryFilters(runtimeContext)
        },
        "application": {
          recruitment: { company: { urlName: "$urlName" } } 
        }
      },
      variableResolvers: {
        "$urlName": props => ({ urlName: props.match.params.company })
      }
    }
  },

  getContextForAdmin: (runtimeContext) => {
    return {
      filters: {
        "companies": {
          ...getBaseQueryFilters(runtimeContext)
        },
        "application": {
          recruitment: { company: { urlName: "$urlName" } } 
        }
      },
      variableResolvers: {
        "$urlName": props => ({ urlName: props.match.params.company })
      }
    }
  },

    getContextForGroupRecruiterViewer: (runtimeContext) => {},

    getContextForRecruiterViewer: (runtimeContext) => {}
}

/**
 * @function buildQueryFor
 * @param  {type} userContext {description}
 * @return {type} {description}
 */
const groupQueryReconciler = (filterContext) => {
    const { filters } = filterContext;

    return `query GroupQuery(
      $paging_limit: Int!,
      $paging_skip: Int
    ){
      results: companies(
        where: { AND: [{ group: { equals: true } }, ${filters.companies}] },
        take: $paging_limit,
        skip: $paging_skip
      ) {
        id
        name
        urlName
      }
      meta: aggregateCompany(where: { AND: [{ group: { equals: true } }, ${filters.companies}] }) {
        _count {
          _all
        }
      }
    }`;
}

export default QueryProvider.Create()
    .withFilterProvider(new FilterProviderMediator(filterProvider))
    .withQueryReconciler(groupQueryReconciler)
    .get();
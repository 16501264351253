import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { Tooltip } from "react-tooltip"
import { map, find, uniq, flatten, isEmpty } from "lodash/fp"
import Slim from "_root/slim/slim.react"
import * as EmailValidator from "email-validator"
import UploadService from "_services/upload-service"
import { MEDLEM, BOKNING } from "_root/routes/url-names"
import { ADMIN_COMPANY_ID, FileType, AllFileTypes } from "_root/constants"
import { FlexRow, FlexCol, Spinner, EditIconRed, CloseIconRed, Input, FilePicker, TextArea, Checkbox } from "_layout/form-elements"
import { PrimaryButton } from "_root/layout/buttons"
import TrashIcon from "_images/trash.svg"
import ObjectPickerBase from "_components/object-picker"
import OccupationalGroupPicker from "_components/occupational-group-picker"
import { GET_EDUCATIONS, GET_REGIONS, GET_OCCUPATIONAL_AREAS, GET_OCCUPATIONAL_GROUPS } from "_root/common-ql"
import {
  GET_CANDIDATE,
  CREATE_ADDRESS,
  UPDATE_MEMBER_DETAILS,
  UPDATE_MEMBER_EDUCATION,
  UPDATE_MEMBER_CV,
  UPDATE_MEMBER_OCCUPATIONS,
  UPDATE_MEMBER_REGIONS,
  UPDATE_MEMBER_LINKS,
  UPDATE_MEMBER_DOCS,
  UPDATE_MEMBER_INFO,
  UPDATE_MEMBER_SPAM,
} from "_root/containers/member/members-ql"
import { CANDIDATE_INTERVIEWS } from "_containers/interview/interview-ql"
import MemberLayout from "_containers/member/member-layout"
import LoginService from "_root/services/login-service"
import inject from "_services/inject"
import to from "_services/await.to"
import all from "_services/await.all"

//#region Styles
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Image = styled.img`
  max-width: min(300px, 100%);
  max-height: 300px;
`
const Section = styled.section`
  position: relative;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--color-bg-white);
  background: var(--color-bg-white);

  @media screen and (min-width: 740px) {
    padding: 20px 40px;
  }
`
const SectionEdit = styled(Section)`
  border: 1px solid var(--color-brand-red);
  background: var(--color-bg-bright);
`
const GridRow = styled.div`
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
`
const Label = styled.label`
  display: block;
  font-weight: ${props => (props.$normal ? "400" : "700")};
  margin-top: 0;
  margin-bottom: 10px;
`
const PreWrap = styled.p`
  white-space: pre-wrap;
`
const List = styled.ul`
  list-style-type: none;
  margin: 20px 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 10px;

    &.no-links {
      font-style: italic;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`
const Trash = styled.a`
  display: inline-block;
  margin-left: 1rem;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const SlimEditor = styled.div`
  margin-bottom: 20px;
  width: min(300px, 100%);
  height: 300px;

  .slim {
    cursor: pointer;
    border: 1px solid var(--color-bg-dark);
    background: var(--color-bg-white);
    box-shadow: inset 0 0 20px 0 var(--color-bg-grey);

    &[data-state*="empty"]:hover {
      background: var(--color-bg-white);
      box-shadow: inset 0 0 20px 5px var(--color-bg-grey);
    }
  }
`
const ObjectPicker = styled(ObjectPickerBase)`
  box-shadow: unset;

  &:hover {
    box-shadow: unset;
  }
`
const RegionList = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  column-count: 3;

  @media (max-width: 600px) {
    column-count: 2;

    li {
      width: 50%;
    }
  }

  @media (max-width: 420px) {
    column-count: 1;

    li {
      width: 100%;
    }
  }

  li {
    display: block;
    margin-right: 15px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
`
const SaveArea = styled.div`
  margin-top: auto;
  align-self: flex-end;
  text-align: right;

  button {
    margin-bottom: 10px;
  }
`
const Error = styled.p`
  margin: 0 0 1em 0;
  font-size: 0.9em;
  color: var(--color-brand-red);

  input + & {
    margin-top: -1em;
  }
`
const Notice = styled.p`
  margin: 0;
  color: var(--color-brand-red);
`
//#endregion

@compose(
  withApollo,
  inject("user"),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "occupationalAreasQuery" }),
  graphql(GET_OCCUPATIONAL_GROUPS, { name: "occupationalGroupsQuery" }),
  graphql(GET_EDUCATIONS, { name: "educationsQuery" }),
  graphql(GET_CANDIDATE, { name: "candidateQuery", options: props => ({ variables: { id: props.user.candidate.id }, fetchPolicy: "network-only" }) }),
  graphql(CANDIDATE_INTERVIEWS, { name: "interviewsQuery", options: props => ({ variables: { member: props.user.id, now: new Date().toISOString() } }) }),
  graphql(CREATE_ADDRESS, { name: "createAddress" }),
  graphql(UPDATE_MEMBER_DETAILS, { name: "updateMemberDetails" }),
  graphql(UPDATE_MEMBER_EDUCATION, { name: "updateMemberEducation" }),
  graphql(UPDATE_MEMBER_CV, { name: "updateMemberCV" }),
  graphql(UPDATE_MEMBER_OCCUPATIONS, { name: "updateMemberOccupations" }),
  graphql(UPDATE_MEMBER_REGIONS, { name: "updateMemberRegions" }),
  graphql(UPDATE_MEMBER_LINKS, { name: "updateMemberLinks" }),
  graphql(UPDATE_MEMBER_DOCS, { name: "updateMemberDocs" }),
  graphql(UPDATE_MEMBER_INFO, { name: "updateMemberInfo" }),
  graphql(UPDATE_MEMBER_SPAM, { name: "updateMemberSpam" })
)
export default class MemberProfile extends React.Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      mobile: user.mobile || "",
      postalCity: (user.address && user.address.postalCity) || "",
      postalCode: (user.address && user.address.postalCode) || "",
      picture: user.picture || null,
      pictureChanged: false,
      spam: false,
      missingInformation: false,
      intCvList: [],
      docs: [],
      regions: [],
      occupationalAreas: [],
      occupationalGroups: [],
      education: null,
      educations: [],
      selectedGroups: [],
      selectedRegions: [],
      loading: true,
      editDetails: false,
      editEducation: false,
      editCV: false,
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const { user, regionsQuery, occupationalAreasQuery, occupationalGroupsQuery, educationsQuery, candidateQuery } = nextProps
    if (regionsQuery.regions && regionsQuery.regions.length) {
      nextState.regions = regionsQuery.regions
    }
    if (!prevState.occupationalAreas.length && occupationalAreasQuery.occupationalAreas && occupationalAreasQuery.occupationalAreas.length) {
      nextState.occupationalAreas = occupationalAreasQuery.occupationalAreas
    }
    if (!prevState.occupationalGroups.length && occupationalGroupsQuery.occupationalGroups && occupationalGroupsQuery.occupationalGroups.length) {
      nextState.occupationalGroups = occupationalGroupsQuery.occupationalGroups
    }
    if (!prevState.educations.length && educationsQuery.educations && educationsQuery.educations.length) {
      nextState.educations = educationsQuery.educations
    }
    const loading = nextProps.user.loading || nextProps.candidateQuery.loading
    if (prevState.loading !== loading) {
      nextState.loading = loading
    }
    if (!nextProps.candidateQuery.loading && nextProps.candidateQuery.candidate && !prevState.candidate) {
      const candidate = nextProps.candidateQuery.candidate
      const recruitments = map(a => a.recruitment)(candidate.applications)
      nextState.applicantId = user.id
      nextState.addressId = (user.address && user.address.id) || null
      nextState.candidate = candidate
      nextState.spam = candidate.spam
      nextState.education = candidate.education
      nextState.appRegions = uniq(flatten(map(r => r.regions)(recruitments)))
      nextState.appAreas = uniq(flatten(map(r => r.occupationalAreas)(recruitments)))
      const interest = find(i => i.company.id === ADMIN_COMPANY_ID)(candidate.interests)
      if (interest) {
        nextState.interest = interest
        nextState.cv = interest.cv
        nextState.docs = interest.docs || []
        nextState.links = interest.links || ""
        nextState.information = interest.information || ""
        nextState.selectedGroups = interest.occupationalGroups || []
        nextState.selectedRegions = interest.regions || []
      }
      const intCvList = candidate.interests.reduce((result, int) => [...result, ...(int.cv ? [int.cv] : [])], [])
      nextState.intCvList = intCvList
    }
    return !isEmpty(nextState) ? nextState : null
  }

  toggleDetails = () => {
    const { editDetails } = this.state
    if (editDetails) {
      this.setState({ editDetails: false })
    } else {
      this.setState({ editDetails: true, editEducation: false, editCV: false, editOccupation: false, editRegions: false, editLinks: false, editDocs: false })
    }
  }

  toggleEducation = () => {
    const { editEducation } = this.state
    if (editEducation) {
      this.setState({ editEducation: false })
    } else {
      this.setState({ editDetails: false, editEducation: true, editCV: false, editOccupation: false, editRegions: false, editLinks: false, editDocs: false, editOther: false })
    }
  }

  toggleCV = () => {
    const { editCV } = this.state
    if (editCV) {
      this.setState({ editCV: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: true, editOccupation: false, editRegions: false, editLinks: false, editDocs: false, editOther: false })
    }
  }

  toggleOccupation = () => {
    const { editOccupation } = this.state
    if (editOccupation) {
      this.setState({ editOccupation: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: false, editOccupation: true, editRegions: false, editLinks: false, editDocs: false, editOther: false })
    }
  }

  toggleRegions = () => {
    const { editRegions } = this.state
    if (editRegions) {
      this.setState({ editRegions: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: false, editOccupation: false, editRegions: true, editLinks: false, editDocs: false, editOther: false })
    }
  }

  toggleLinks = () => {
    const { editLinks } = this.state
    if (editLinks) {
      this.setState({ editLinks: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: false, editOccupation: false, editRegions: false, editLinks: true, editDocs: false, editOther: false })
    }
  }

  toggleDocs = () => {
    const { editDocs } = this.state
    if (editDocs) {
      this.setState({ editDocs: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: false, editOccupation: false, editRegions: false, editLinks: false, editDocs: true, editOther: false })
    }
  }

  toggleOther = () => {
    const { editOther } = this.state
    if (editOther) {
      this.setState({ editOther: false })
    } else {
      this.setState({ editDetails: false, editEducation: false, editCV: false, editOccupation: false, editRegions: false, editLinks: false, editDocs: false, editOther: true })
    }
  }

  validateDetails = () => {
    const { email, firstName, lastName, mobile, postalCity, postalCode } = this.state
    const invalid = {
      email: !EmailValidator.validate(email),
      firstName: !firstName || firstName.trim().length === 0,
      lastName: !lastName || lastName.trim().length === 0,
      mobile: !mobile || mobile.trim().length === 0,
      postalCity: !postalCity || postalCity.trim().length === 0,
      postalCode: !postalCode || postalCode.trim().length === 0,
    }
    const isInvalid = invalid.email || invalid.firstName || invalid.lastName || invalid.mobile || invalid.postalCity || invalid.postalCode
    this.setState({ invalidDetails: isInvalid ? invalid : null })
    return !isInvalid
  }

  setEmail = e => {
    const { invalidDetails } = this.state
    const invalid = invalidDetails && invalidDetails.email ? { ...invalidDetails, email: false } : invalidDetails
    this.setState({ email: e.target.value.trim(), invalidDetails: invalid })
  }

  onEducationChange = education => {
    this.setState({ education }, this.validateDetails)
  }

  onSlimInit = (data, slim) => {
    this.slim = slim
    const { picture } = this.state
    if (picture && picture.url) {
      slim._options.instantEdit = false
      slim.load(picture.url, (error, data) => {
        slim._options.instantEdit = true
      })
    }
  }

  onPhotoCancel = (data, slim) => {
    const { picture, pictureChanged } = this.state
    if (pictureChanged || !picture || !picture.url || !this.slim) return
    this.slim._options.instantEdit = false
    this.slim.load(picture.url, (error, data) => {
      this.slim._options.instantEdit = true
    })
  }

  onPhotoConfirm = (data, slim) => {
    this.setState({ picture: slim.dataBase64.output, pictureChanged: true })
  }

  onPhotoRemoved = (data, slim) => {
    this.setState({ picture: undefined, pictureChanged: true })
  }

  onFileSelected = async file => {
    if (file) {
      const [error, { data }] = await to(UploadService.uploadFile(file))
      if (error || !data || !data.id) {
        console.error("member-profile:onFileSelected:uploadProfile:error", error || data)
        return
      }
      this.setState({ cv: data })
    }
  }

  onDeleteCV = async e => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ cv: undefined })
  }

  onDocsSelected = async files => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map(file => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload || !resultUpload.length) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        return
      }
      const uploaded = map(upload => upload.data)(resultUpload)
      const docs = [...this.state.docs, ...uploaded]
      this.setState({ docs })
    }
  }

  onRegionChange = region => {
    let selectedRegions = [...this.state.selectedRegions]
    if (find(r => r.id === region.id)(this.state.selectedRegions)) {
      selectedRegions = selectedRegions.filter(r => r.id !== region.id)
    } else {
      selectedRegions = [...selectedRegions, region]
    }
    this.setState({ selectedRegions })
  }

  onDeleteFile = async e => {
    e.stopPropagation()
    e.preventDefault()
    if (!e.target.dataset.doc) return
    const docs = this.state.docs.filter(doc => doc.id !== e.target.dataset.doc)
    if (docs.length !== this.state.docs.length) {
      this.setState({ docs: docs })
    }
  }

  // checkProfile = state => {
  //   const { interest, cv, docs, links, information, selectedGroups, selectedRegions } = state || this.state
  //   return !interest || !cv || !docs.length || !links || !information || !selectedGroups.length || !selectedRegions.length
  // }

  saveDetails = async () => {
    if (!this.validateDetails()) return
    this.setState({ saving: true })
    const { applicantId, email, firstName, lastName, mobile, addressId, postalCity, postalCode, picture, pictureChanged } = this.state
    let newPicture = picture
    const variables = {
      applicantId,
      email,
      firstName,
      lastName,
      mobile,
      addressId,
      postalCity,
      postalCode,
    }
    if (pictureChanged) {
      if (!!picture) {
        const [error, result] = await to(UploadService.uploadBase64(picture.image, picture.name))
        if (error || !result?.data?.id) {
          console.error("member-profile:saveDetails:uploadPicture:error", error || data)
          this.setState({ errorMessage: "Ett fel uppstod vid uppladdning av foto." })
          return
        }
        newPicture = result.data
        variables.picture = { connect: { id: newPicture.id } }
      } else {
        variables.picture = { delete: true }
      }
    }
    if (!addressId) {
      const address = { userId: applicantId, postalCity, postalCode }
      const [error, result] = await to(this.props.createAddress({ variables: address }))
      if (error || !result) {
        console.error("member-profile:saveDetails:createAddress:error", error, address)
        this.setState({ errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
        return
      }
      variables.addressId = result.data.createAddress.id
    }
    const [error, result] = await to(this.props.updateMemberDetails({ variables }))
    if (error || !result) {
      console.error("member-profile:saveDetails:updateMember:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      const state = { ...this.state, picture: newPicture, errorMessage: null, saving: false, editDetails: false, missingInformation: false }
      this.setState(state, () => LoginService.reloadUser(this.props.client))
    }
  }

  saveEducation = async () => {
    const { candidate, education } = this.state
    if (!candidate || !candidate.id || !education || !education.id) return // TODO: Error message?
    this.setState({ saving: true })
    const variables = { candidateId: candidate.id, educationId: education.id }
    const [error, result] = await to(this.props.updateMemberEducation({ variables }))
    if (error || !result) {
      console.error("member-profile:saveEducation:updateMemberEducation:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editEducation: false })
    }
  }

  saveCV = async () => {
    const { interest, cv } = this.state
    if (!interest || !interest.id) return // TODO: Error message?
    this.setState({ saving: true })
    const variables = { interestId: interest.id, cv: cv ? { connect: { id: cv.id } } : { delete: true } }
    const [error, result] = await to(this.props.updateMemberCV({ variables }))
    if (error || !result) {
      console.error("member-profile:saveCV:updateMemberCV:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      const cvList = cv ? [cv] : []
      this.setState({ errorMessage: null, saving: false, editCV: false, intCvList: cvList })
    }
  }

  saveOccupations = async () => {
    const { interest, selectedGroups } = this.state
    if (!interest) return
    if (!selectedGroups || !selectedGroups.length) {
      this.setState({ errorMessage: "Ange minst ett yrke" })
      return
    }
    const occupationalGroupsIds = map(g => ({ id: g.id }))(selectedGroups)
    this.setState({ saving: true })
    const variables = { interestId: interest.id, occupationalGroupsIds }
    const [error, result] = await to(this.props.updateMemberOccupations({ variables }))
    if (error || !result) {
      console.error("member-profile:saveOccupations:updateMemberOccupations:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editOccupation: false })
    }
  }

  saveRegions = async () => {
    const { interest, selectedRegions } = this.state
    if (!interest || !selectedRegions) return // TODO: Error message?
    const regionsIds = map(g => ({ id: g.id }))(selectedRegions)
    this.setState({ saving: true })
    const variables = { interestId: interest.id, regionsIds }
    const [error, result] = await to(this.props.updateMemberRegions({ variables }))
    if (error || !result) {
      console.error("member-profile:saveRegions:updateMemberRegions:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editRegions: false })
    }
  }

  saveLinks = async () => {
    const { interest, links } = this.state
    if (!interest) return // TODO: Error message?
    this.setState({ saving: true })
    const variables = { interestId: interest.id, links }
    const [error, result] = await to(this.props.updateMemberLinks({ variables }))
    if (error || !result) {
      console.error("member-profile:saveLinks:updateMemberLinks:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editLinks: false })
    }
  }

  saveDocs = async () => {
    const { interest, docs } = this.state
    if (!interest) return // TODO: Error message?
    this.setState({ saving: true })
    const docsIds = docs.map(doc => ({ id: doc.id }))
    const variables = { interestId: interest.id, docsIds }
    const [error, result] = await to(this.props.updateMemberDocs({ variables }))
    if (error || !result) {
      console.error("member-profile:saveDocs:updateMemberDocs:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editDocs: false })
    }
  }

  saveOther = async () => {
    const { interest, information } = this.state
    if (!interest) return // TODO: Error message?
    this.setState({ saving: true })
    const variables = { interestId: interest.id, information }
    const [error, result] = await to(this.props.updateMemberInfo({ variables }))
    if (error || !result) {
      console.error("member-profile:saveMerits:updateMemberInfo:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, editOther: false })
    }
  }

  saveSpam = async () => {
    const { candidate, spam } = this.state
    if (!candidate) return // TODO: Error message?
    this.setState({ saving: true })
    const variables = { candidateId: candidate.id, spam: !this.state.spam }
    const [error, result] = await to(this.props.updateMemberSpam({ variables }))
    if (error || !result) {
      console.error("member-profile:saveSpam:updateMemberSpam:error", error, variables)
      this.setState({ saving: false, errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
    } else {
      this.setState({ errorMessage: null, saving: false, spam: !this.state.spam })
    }
  }

  render() {
    const {
      loading,
      firstName,
      lastName,
      email,
      mobile,
      postalCode,
      postalCity,
      picture,
      education,
      intCvList,
      educations,
      selectedGroups,
      selectedRegions,
      regions,
      cv,
      docs,
      links,
      information,
      spam,
      editDetails,
      editEducation,
      editCV,
      editOccupation,
      editRegions,
      editLinks,
      editDocs,
      editOther,
      invalidDetails,
      saving,
      errorMessage,
      missingInformation,
      occupationalAreas,
      occupationalGroups,
    } = this.state

    const {
      interviewsQuery: { applicationInterviews = [] },
      user,
    } = this.props
    const hasInvites = !!applicationInterviews.find(a => a.status === "INVITED")

    let linkList = links && links.trim().split(/\s+/)
    linkList =
      linkList && linkList.length && linkList[0]
        ? linkList.map(link => (
            <li key={link}>
              <a href={link.match(/^[a-zA-Z]+:\/\//) ? link : `http://${link}`} target="_blank">
                {link}
              </a>
            </li>
          ))
        : [
            <li key="no-links" className="no-links">
              <em>Uppgift saknas. Klicka på pennan till höger för att lägga till länkar.</em>
            </li>,
          ]

    if (loading) {
      return (
        <MemberLayout>
          <Spinner />
        </MemberLayout>
      )
    }

    return (
      <MemberLayout>
        {hasInvites && (
          <Section>
            <a href={MEDLEM + BOKNING}>OBS! Du har inbjudningar att besvara</a>
          </Section>
        )}
        {missingInformation && user.active && (
          <Section>
            <Notice>
              Din profil ser ut att vara ofullständig.
              <br />
              Komplettera gärna dina uppgifter för att öka chansen till ett nytt jobb.
            </Notice>
          </Section>
        )}
        <Section>
          <H1>Min profil</H1>
        </Section>
        {!editDetails && (
          <Section>
            <EditIconRed onClick={this.toggleDetails} />
            <FlexRow>
              <FlexCol className="mr5 mb3" $full>
                <h3>
                  {firstName} {lastName}
                </h3>
                {email}
                <br />
                {mobile}
                <br />
                <br />
                {postalCode} {postalCity}
              </FlexCol>
              <FlexCol>{picture && picture.url && <Image src={picture.url} alt={firstName + " " + lastName} />}</FlexCol>
            </FlexRow>
          </Section>
        )}
        {editDetails && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleDetails} />
            <h3>Redigera kontaktuppgifter</h3>
            <GridRow>
              <FlexCol>
                <Label>Förnamn *</Label>
                <Input
                  type="text"
                  autoFocus={!firstName}
                  placeholder="Förnamn"
                  $invalid={invalidDetails && invalidDetails.firstName}
                  value={firstName}
                  onBlur={this.validateDetails}
                  onChange={e => this.setState({ firstName: e.target.value })}
                />
                {invalidDetails && invalidDetails.firstName && <Error>Ange förnamn</Error>}
                <Label>Efternamn *</Label>
                <Input
                  type="text"
                  placeholder="Efternamn"
                  $invalid={invalidDetails && invalidDetails.lastName}
                  value={lastName}
                  onBlur={this.validateDetails}
                  onChange={e => this.setState({ lastName: e.target.value })}
                />
                {invalidDetails && invalidDetails.lastName && <Error>Ange efternamn</Error>}
                <Label>E-post *</Label>
                <Input type="email" placeholder="namn@exempel.com" $invalid={invalidDetails && invalidDetails.email} value={email} onBlur={this.validateDetails} onChange={this.setEmail} />
                {invalidDetails && invalidDetails.email && <Error>Ange en fullständig e-postadress</Error>}
                <Label>Mobil *</Label>
                <Input
                  type="text"
                  placeholder="07x-xxxxxxx"
                  $invalid={invalidDetails && invalidDetails.mobile}
                  value={mobile}
                  onBlur={this.validateDetails}
                  onChange={e => this.setState({ mobile: e.target.value })}
                />
                {invalidDetails && invalidDetails.mobile && <Error>Ange mobilnummer</Error>}
                <Label>Bostadsort *</Label>
                <Input
                  type="text"
                  placeholder="Bostadsort"
                  $invalid={invalidDetails && invalidDetails.postalCity}
                  value={postalCity}
                  onBlur={this.validateDetails}
                  onChange={e => this.setState({ postalCity: e.target.value })}
                />
                {invalidDetails && invalidDetails.postalCity && <Error>Ange bostadsort</Error>}
                <Label>Postnummer *</Label>
                <Input
                  type="text"
                  placeholder="Postnummer"
                  $invalid={invalidDetails && invalidDetails.postalCode}
                  value={postalCode}
                  onBlur={this.validateDetails}
                  onChange={e => this.setState({ postalCode: e.target.value })}
                />
                {invalidDetails && invalidDetails.postalCode && <Error>Ange postnummer</Error>}
              </FlexCol>
              <FlexCol>
                <Label>Foto</Label>
                <SlimEditor>
                  <Slim
                    ratio="1:1"
                    label="Dra din bild hit eller klicka här"
                    labelLoading="Laddar bild..."
                    instantEdit={true}
                    buttonEditTitle="Redigera"
                    buttonRemoveTitle="Ta bort"
                    buttonRotateTitle="Rotera"
                    buttonCancelLabel="Avbryt"
                    buttonCancelTitle="Avbryt"
                    buttonConfirmLabel="OK"
                    buttonConfirmTitle="OK"
                    didInit={this.onSlimInit}
                    didCancel={this.onPhotoCancel}
                    didConfirm={this.onPhotoConfirm}
                    didRemove={this.onPhotoRemoved}>
                    <input type="file" name="pictureFile" />
                  </Slim>
                </SlimEditor>
                <SaveArea>
                  <PrimaryButton disabled={invalidDetails || saving} onClick={this.saveDetails} loading={saving}>
                    Spara
                  </PrimaryButton>
                  {invalidDetails && <Error>Något saknas. Kontrollera formuläret.</Error>}
                  {errorMessage && <Error>{errorMessage}</Error>}
                </SaveArea>
              </FlexCol>
            </GridRow>
          </SectionEdit>
        )}
        {!editEducation && (
          <Section>
            <EditIconRed onClick={this.toggleEducation} />
            <Label className="mt3">Högsta utbildning</Label>
            {(education && education.name) || <em>Uppgift saknas. Klicka på pennan till höger för att lägga till utbildning.</em>}
          </Section>
        )}
        {!!editEducation && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleEducation} />
            <Label>Högsta utbildning</Label>
            <ObjectPicker form={"true"} required values={educations} placeholder="Välj utbildning..." current={education} onSelected={this.onEducationChange} noMatch="Inga träffar" />
            {!education && <Error>Ange din högsta utbildning</Error>}
            <SaveArea>
              <PrimaryButton disabled={!education || saving} onClick={this.saveEducation} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editCV && (
          <Section>
            <EditIconRed onClick={this.toggleCV} />
            <Label>Mitt CV</Label>
            {intCvList.length > 0 && (
              <List>
                {map(cv => (
                  <li key={cv.id}>
                    <a href={cv.url} target="_blank" rel="noopener noreferrer">
                      {cv.name}
                    </a>
                  </li>
                ))(intCvList)}
              </List>
            )}
            {!intCvList.length && <i>Du har inget uppladdat CV.</i>}
          </Section>
        )}
        {!!editCV && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleCV} />
            <Label>Mina CV</Label>
            <p>Här kan du ladda upp ett nytt CV.</p>
            <FilePicker
              black
              className="mb1"
              fileId={"candidate-cv"}
              text={"Ladda upp CV…"}
              accept={[FileType.DOC, FileType.DOCX, FileType.XLS, FileType.XLSX, FileType.ODT, FileType.ODS, FileType.PDF]}
              onFile={this.onFileSelected}
            />
            {cv && (
              <List>
                <li>
                  <a href={cv.url} target="_blank" rel="noopener noreferrer">
                    {cv.name}
                  </a>
                  <Trash onClick={this.onDeleteCV} data-tooltip-content="Radera CV" data-tooltip-id="tooltip" />
                </li>
              </List>
            )}
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveCV} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editOccupation && (
          <Section>
            <EditIconRed onClick={this.toggleOccupation} />
            <Label>Mina yrken</Label>
            {selectedGroups.length > 0 && <List>{map(group => <li key={group.id}>{group.name}</li>)(selectedGroups)}</List>}
            {!selectedGroups.length && <em>Uppgift saknas. Klicka på pennan till höger för att lägga till yrken</em>}
          </Section>
        )}
        {!!editOccupation && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleOccupation} />
            <Label>Mina yrken *</Label>
            {occupationalAreas.length > 0 && occupationalGroups.length > 0 && (
              <OccupationalGroupPicker
                className="mb3"
                occupationalAreas={occupationalAreas}
                occupationalGroups={occupationalGroups}
                selected={selectedGroups}
                onChange={selectedGroups => this.setState({ selectedGroups })}
              />
            )}
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveOccupations} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editRegions && (
          <Section>
            <EditIconRed onClick={this.toggleRegions} />
            <Label>Mina regioner</Label>
            {selectedRegions.length > 0 && <List>{map(region => <li key={region.id}>{region.name}</li>)(selectedRegions)}</List>}
          </Section>
        )}
        {!!editRegions && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleRegions} />
            <Label>Mina regioner</Label>
            {regions && regions.length > 0 && (
              <RegionList>
                {regions.map(region => (
                  <li key={region.id}>
                    <Checkbox value={region} onChange={e => this.onRegionChange(region)} checked={!!find(r => r.id === region.id)(selectedRegions)} label={region.name} />
                  </li>
                ))}
              </RegionList>
            )}
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveRegions} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editLinks && (
          <Section>
            <EditIconRed onClick={this.toggleLinks} />
            <Label>Mina länkar</Label>
            {linkList && linkList.length > 0 && <List>{linkList}</List>}
          </Section>
        )}
        {!!editLinks && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleLinks} />
            <Label>Mina länkar</Label>
            <p>Lägg till länkar till relevanta sidor som LinkedIn etc.</p>
            <TextArea placeholder="Länkar" width="100%" rows="3" value={links} onChange={e => this.setState({ links: e.target.value })} />
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveLinks} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editDocs && (
          <Section>
            <EditIconRed onClick={this.toggleDocs} />
            <Label>Mina bilagor</Label>
            {docs.length > 0 && (
              <List>
                {map(doc => (
                  <li key={doc.id}>
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                      {doc.name}
                    </a>
                  </li>
                ))(docs)}
              </List>
            )}
            {!docs.length && <em>Uppgift saknas. Klicka på pennan till höger för att lägga till bilagor.</em>}
          </Section>
        )}
        {!!editDocs && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleDocs} />
            <Label>Mina bilagor</Label>
            <p>Här kan man ladda upp personligt brev, intyg eller liknande.</p>
            <FilePicker black className="mb1" fileId={"candidate-files"} text={"Ladda upp bilagor…"} accept={AllFileTypes} onMultiple={this.onDocsSelected} />
            {docs && !!docs.length && (
              <List>
                {map(doc => (
                  <li key={doc.id}>
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                      {doc.name}
                    </a>
                    <Trash data-doc={doc.id} onClick={this.onDeleteFile} data-tooltip-content="Radera bilaga" data-tooltip-id="tooltip" />
                  </li>
                ))(docs)}
              </List>
            )}
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveDocs} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        {!editOther && (
          <Section>
            <EditIconRed onClick={this.toggleOther} />
            <Label>Mina övriga kunskaper</Label>
            <PreWrap>{information || <em>Uppgift saknas. Klicka på pennan till höger för att lägga till övriga kunskaper.</em>}</PreWrap>
          </Section>
        )}
        {!!editOther && (
          <SectionEdit>
            <CloseIconRed onClick={this.toggleOther} />
            <Label>Mina övriga kunskaper</Label>
            <p>Här kan man lägga in certifieringar, språkkunskaper, körkort etc.</p>
            <TextArea rows="4" width="100%" placeholder="Skriv eller klistra in text..." value={information} onChange={e => this.setState({ information: e.target.value })} />
            <SaveArea>
              <PrimaryButton disabled={saving} onClick={this.saveOther} loading={saving}>
                Spara
              </PrimaryButton>
              {errorMessage && <Error>{errorMessage}</Error>}
            </SaveArea>
          </SectionEdit>
        )}
        <Section>
          <Checkbox checked={spam} onChange={this.saveSpam} label="Ja tack, skicka mig jobbtips!" />
        </Section>
        <Tooltip id="tooltip" place="bottom" effect="solid" />
      </MemberLayout>
    )
  }
}

import React from 'react'
import styled from 'styled-components'
import classSet from 'react-classset'
import { useDrop } from 'react-dnd'
import { DragType } from '_root/constants'
import SelectionAdd from '_components/recruit/selection-add'

//#region Styles
const Dropzone = styled.div`
    flex: 1 1 auto;
    min-width: 300px;
    min-height: 150px;
    width: 100%;
    height: 100%;
    text-align: left;

    &.can-drop {
        background: var(--color-bg-light);

        &.is-over {
            background: var(--color-brand-green-light);
        }
    }
`
//#endregion

export default function SelectionRemovezone(props) {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DragType.QUESTION,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: item => ({
      item, dropzone: false, removezone: true
    }),
  }))

  const { companyId, children, onCreate } = props
  const dzClasses = classSet({ 'can-drop': canDrop, 'is-over': isOver })

  return <div>
    <Dropzone role="Dropzone" ref={drop} className={dzClasses}>
      {onCreate &&
        <SelectionAdd companyId={companyId} onAddQuestion={onCreate} />
      }
      {children}
    </Dropzone>
  </div>
}

import React from "react"
import withRouter from "_hoc/withRouter"
import styled from "styled-components"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { format, parseISO } from "date-fns"
import message from "_components/message"
import MainLayout from "_layout/main-layout"
import { H2, Label, Input, Spinner, Checkbox } from "_layout/form-elements"
import { PrimaryButton, SecondaryButton } from "_layout/buttons"
import ObjectPickerBase from "_components/object-picker"
import MultiObjectPickerBase from "_components/multi-object-picker"
import Modal from "_components/modal"
import CandidateMailing from "_containers/candidate-mailing"
import CandidateMessageLog from "_containers/candidate-messagelog"
import { GET_EDUCATIONS, GET_REGIONS, GET_OCCUPATIONAL_AREAS, GET_OCCUPATIONAL_GROUPS } from "_root/common-ql"
import {
  NAME_FILTER,
  EMAIL_FILTER,
  MOBILE_FILTER,
  POSTALCODE_FILTER,
  POSTALCITY_FILTER,
  EDUCATION_FILTER,
  AREA_FILTER,
  GROUP_FILTER,
  REGION_FILTER,
  INFORMATION_FILTER,
  SPAM_FILTER,
} from "_containers/candidates-ql"
import inject from "_services/inject"
import to from "_services/await.to"
import { KANDIDAT } from "_root/routes/url-names"
import { symbols } from "_root/constants"
import CheckIconSrc from "_images/check.svg"
import orderBy from "lodash/fp/orderBy"
import flow from "lodash/fp/flow"
import find from "lodash/fp/find"
import remove from "lodash/fp/remove"
import isEmpty from "lodash/fp/isEmpty"
import filter from "lodash/fp/filter"
import map from "lodash/fp/map"
import some from "lodash/fp/some"

//#region Styles
const Wrapper = styled.div`
  padding: 20px 40px 40px 40px;

  .heading {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`
const SearchBox = styled.div`
  padding: 30px;
  background: var(--color-bg-white);
  box-shadow: 0px 0px 10px var(--color-bg-light);
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  min-height: 665px;

  .columns {
    display: flex;
    flex-wrap: wrap;
    div:first-child {
      margin-right: 60px;
    }
  }
`
const ActionBox = styled.div`
  padding: 30px;
  background: var(--color-bg-light);
  box-shadow: 0px 0px 10px var(--color-bg-light);
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  > span {
    margin-left: 20px;
  }
`
const ObjectPicker = styled(ObjectPickerBase)`
  margin-bottom: 20px;
`
const MultiObjectPicker = styled(MultiObjectPickerBase)`
  margin-bottom: 20px;
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const Rows = styled.div`
  flex: 1;
  margin: 0;

  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 56px;

    .titleCell {
      position: relative;
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 15px 5px 10px;
      color: var(--color-text-dark);
      background: var(--color-bg-lighter);
      border-left: 1px solid var(--color-line-light);
      border-bottom: 1px solid var(--color-line-light);
    }

    .infoArea {
      flex: 2;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;

      .infoCell {
        cursor: ${(props) => (props.pointer ? "pointer" : "default")};
        position: relative;
        flex: ${(props) => props.$flex || "1"};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: ${(props) => (props.left ? "flex-start" : "center")};
        border-bottom: 1px solid var(--color-line-light);
        border-left: 1px solid var(--color-line-light);
        background: var(--color-bg-white);
        white-space: nowrap;

        &:last-child {
          border-right: 1px solid var(--color-line-light);
        }

        .content & {
          padding: 5px 10px;
          ${(props) => (props.toggle ? "padding-right: 50px;" : null)};
        }
      }
    }

    .actions {
      position: absolute;
      top: 15px;
      right: 7px;
      bottom: 50%;
      width: 25px;
      height: 25px;

      .actionSymbol {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px 5px 0;
        cursor: pointer;

        > img {
          max-width: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: var(--color-bg-grey);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
        }

        .message svg {
          stroke: #027874;
          fill: #027874;
        }
      }
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px 5px 10px;
  color: var(--color-text-dark);
  background: var(--color-bg-lighter);
  border-bottom: 1px solid var(--color-line-light);

  .header & {
    border: none;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background-color: var(--color-bg-light);
      }
    }
  }
`
const InfoArea = styled.div`
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
  position: relative;
  flex: ${(props) => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);
  white-space: nowrap;

  ${Row}.header & {
    border: none;
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background: var(--color-bg-light);
      }
    }
  }

  .content & {
    padding: 5px 10px;
    ${(props) => (props.toggle ? "padding-right: 50px;" : null)};
  }
`
const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${CheckIconSrc}) no-repeat scroll 50% 50%;
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "oaQuery" }),
  graphql(GET_OCCUPATIONAL_GROUPS, { name: "ogQuery" }),
  graphql(GET_EDUCATIONS, { name: "educationQuery" })
)
export default class Candidates extends React.Component {
  constructor(props) {
    super(props)
    const search = {
      name: "",
      email: "",
      mobile: "",
      postalCode: "",
      postalCity: "",
      information: "",
      education: null,
      occAreas: [],
      occGroups: [],
      regions: [],
      spam: false,
    }
    this.state = {
      mobile: window.innerWidth < 768,
      search,
      andOperator: true,
      candidates: [],
      filteredGroups: [],
      occupationalAreas: [],
      occupationalGroups: [],
      regions: [],
      educations: [],
      sortOrder: {
        createdAt: "asc",
      },
      selected: [],
      emailModalOpen: false,
    }
  }

  setSortOrder = (prop) => {
    const order = this.state.sortOrder[prop] !== "asc" ? "asc" : "desc"
    let sortOrder = {}
    sortOrder[prop] = order
    this.setState({ sortOrder })
  }

  selectAll = (e) => {
    let selected = []
    if (e.target.checked) {
      selected = [...this.state.candidates]
    }
    this.setState({ selectAll: e.target.checked, selected })
  }

  selectRow = (e) => {
    let selected = [...this.state.selected]
    const id = e.target.value
    if (e.target.checked) {
      if (!find((f) => f.id === id)(selected)) {
        const candidate = find((f) => f.id === id)(this.state.candidates)
        selected.push(candidate)
      }
    } else {
      selected = remove((c) => c.id === id)(selected)
    }
    this.setState({ selectAll: false, selected })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (!prevState.regions.length && nextProps.regionsQuery.regions && nextProps.regionsQuery.regions.length) {
      nextState.regions = nextProps.regionsQuery.regions
    }
    if (!prevState.occupationalAreas.length && nextProps.oaQuery?.occupationalAreas?.length) {
      nextState.occupationalAreas = nextProps.oaQuery.occupationalAreas
    }
    if (!prevState.occupationalGroups.length && nextProps.ogQuery.occupationalGroups && nextProps.ogQuery.occupationalGroups.length) {
      nextState.occupationalGroups = nextProps.ogQuery.occupationalGroups
      if (!prevState.filteredGroups.length) nextState.filteredGroups = [...nextState.occupationalGroups]
    }
    if (!prevState.educations.length && nextProps.educationQuery.educations && nextProps.educationQuery.educations.length) {
      nextState.educations = nextProps.educationQuery.educations
    }

    if (prevState.searching === false && prevState.candidates && prevState.candidates.length > 0) {
      const funcs = []
      if (prevState.sortOrder.name) {
        funcs.push(orderBy((c) => `${c.user.firstName}${c.user.lastName}`)([prevState.sortOrder.name]))
      }
      if (prevState.sortOrder.createdAt) {
        funcs.push(orderBy((c) => c.createdAt)([prevState.sortOrder.createdAt]))
      }
      if (prevState.sortOrder.spam) {
        funcs.push(orderBy((c) => (c.spam ? 0 : 1))([prevState.sortOrder.spam]))
      }
      if (funcs.length > 0) {
        nextState.candidates = flow(funcs)(prevState.candidates)
      }
    }

    return !isEmpty(nextState) ? nextState : null
  }

  onSelectedEducation = (selected) => {
    this.setState({ search: { ...this.state.search, education: selected || null } })
  }

  onSelectedAreas = (selected) => {
    const { search } = this.state
    const filteredGroups = selected?.length ? this.state.occupationalGroups.filter((g) => some((a) => a.id === g.occupationalArea.id)(selected)) : this.state.occupationalGroups
    const occGroups = search.occGroups.filter((og) => some((fg) => fg.id === og.id)(filteredGroups))
    this.setState({ filteredGroups, search: { ...search, occAreas: [...selected], occGroups } })
  }

  onSelectedGroups = (selected) => {
    this.setState({ search: { ...this.state.search, occGroups: [...selected] } })
  }

  onSelectedRegions = (selected) => {
    this.setState({ search: { ...this.state.search, regions: [...selected] } })
  }

  onKeyDown = (e) => {
    if (e.which === 13) this.search(false)
  }

  hasFilter = () => {
    const { name, email, mobile, postalCode, postalCity, education, occAreas, occGroups, regions, information, spam } = this.state.search
    return (
      !!name ||
      !!email ||
      !!mobile ||
      !!postalCode ||
      !!postalCity ||
      !!education ||
      !!(occAreas && occAreas.length) ||
      !!(occGroups && occGroups.length) ||
      !!(regions && regions.length) ||
      !!information ||
      !!spam
    )
  }

  search = async (refetch) => {
    this.setState({ searching: true, selectAll: false, candidates: [], selected: [] })
    const { name, email, mobile, postalCode, postalCity, education, occAreas, occGroups, regions, information, spam } = this.state.search
    const noFilter = !this.hasFilter()
    const occupationalAreasIds = map((a) => a.id)(occAreas)
    const occupationalGroupsIds = map((a) => a.id)(occGroups)
    const regionsIds = map((r) => r.id)(regions)
    const variables = {}
    const filters = []
    const parameters = []
    if (!noFilter) {
      if (name) {
        variables.fullName = name
        variables.names = name.split(" ")
        filters.push(NAME_FILTER)
        parameters.push("$fullName: String")
        parameters.push("$names: [String!]")
      }
      if (email) {
        variables.email = email
        filters.push(EMAIL_FILTER)
        parameters.push("$email: String")
      }
      if (mobile) {
        variables.mobile = mobile
        filters.push(MOBILE_FILTER)
        parameters.push("$mobile: String")
      }
      if (postalCode) {
        variables.postalCode = postalCode
        filters.push(POSTALCODE_FILTER)
        parameters.push("$postalCode: String")
      }
      if (postalCity) {
        variables.postalCity = postalCity
        filters.push(POSTALCITY_FILTER)
        parameters.push("$postalCity: String")
      }
      if (information) {
        variables.information = information
        filters.push(INFORMATION_FILTER)
        parameters.push("$information: String")
      }
      if (education) {
        variables.educationLevel = education.level
        filters.push(EDUCATION_FILTER)
        parameters.push("$educationLevel: Int")
      }
      if (regionsIds && regionsIds.length) {
        variables.regionsIds = regionsIds
        filters.push(REGION_FILTER)
        parameters.push("$regionsIds: [String!]")
      }
      if (occupationalGroupsIds && occupationalGroupsIds.length) {
        variables.occupationalGroupsIds = occupationalGroupsIds
        filters.push(GROUP_FILTER)
        parameters.push("$occupationalGroupsIds: [String!]")
      } else if (occupationalAreasIds && occupationalAreasIds.length) {
        variables.occupationalAreasIds = occupationalAreasIds
        filters.push(AREA_FILTER)
        parameters.push("$occupationalAreasIds: [String!]")
      }
      if (spam) {
        variables.spam = spam
        filters.push(SPAM_FILTER)
        parameters.push("$spam: Boolean!")
      }
    }
    const orderString = "orderBy: { createdAt: asc }, take: 1000"
    const operator = this.state.andOperator ? "AND" : "OR"
    const filterString = noFilter
      ? `(${orderString})`
      : `(${orderString}, where: {
            ${operator}: [
                ${filters.join(",\n")}
            ]
        })`
    const parameterString = noFilter
      ? ""
      : `(
            ${parameters.join(",\n")}
        )`
    const GET_CANDIDATES = `
        query GetCandidates${parameterString} {
            candidates${filterString} {
                id
                createdAt
                spam
                user {
                    id
                    firstName
                    lastName
                    email
                }
                messageLogCount
              }
              aggregateCandidate {
                _count {
                  _all
                }
              }
        }
        `
    const queryOptions = {
      query: gql(GET_CANDIDATES),
      variables: variables,
    }
    if (refetch) {
      queryOptions.fetchPolicy = "network-only"
    }
    const [error, result] = await to(this.props.client.query(queryOptions))
    if (error || !result) {
      console.error("candidates:search:error", error, variables)
      message("Något gick fel vid sökningen.")
      this.setState({ searching: false })
    } else {
      let {
        data: {
          candidates,
          aggregateCandidate: {
            _count: { _all: count },
          },
        },
      } = result
      if (!candidates || !candidates.length) {
        await message("Inga träffar!")
        candidates = []
      } else {
        candidates = filter((c) => c.user && c.user.firstName)(candidates)
        const actionSymbols = Candidates.GetActionSymbols(candidates)
        candidates = map((c) => {
          let actions = [].concat.apply([], map("symbols")(filter((s) => s.candidateId === c.id)(actionSymbols)))
          return { ...c, actions }
        })(candidates)
      }
      this.setState({ candidates, searching: false, totalCandidateCount: count })
    }
  }

  static GetActionSymbols(candidates) {
    const aSymbols = map((c) => {
      let symbols = []
      if (c.messageLogCount > 0) {
        symbols.push(Candidates.getMessageLogSymbol())
      }
      return {
        candidateId: c.id,
        symbols,
      }
    })(candidates)
    return remove((a) => a.symbols.length === 0)(aSymbols)
  }

  static getMessageLogSymbol() {
    return {
      id: "__autoML",
      editable: false,
      name: "message",
      text: "Meddelande skickat",
    }
  }

  openCandidateProfile = (e, id) => {
    e.preventDefault()
    window.open(`/${this.props.match.params.company}${KANDIDAT}/${id}`, id, "width=1200,height=800,location=no")
    return false
  }

  date = (date) => {
    if (!date || typeof date !== "string" || date.length < 10) {
      return " "
    }
    return format(parseISO(date), "yy-MM-dd")
  }

  onModalClose = (e) => {
    this.closeModal()
  }

  openModal = (dialog) => {
    let newState = {
      emailModalOpen: false,
      messageLogModalOpen: false,
    }
    switch (dialog) {
      case "email":
        newState.emailModalOpen = true
        break
      case "messageLog":
        newState.messageLogModalOpen = true
        break
      default:
        break
    }
    this.setState(newState)
  }

  closeModal = () => {
    this.openModal(null)
  }

  sendEmail = (e) => {
    this.openModal("email")
  }

  onCandidateAction(candidate, action) {
    switch (action) {
      case "message":
        this.setState({ messageLogParentId: candidate.id })
        this.openModal("messageLog")
        return
      default:
        return
    }
  }

  mailingOnUpdate = (e) => {
    this.search(true)
  }

  render() {
    const {
      search,
      candidates,
      regions,
      occupationalAreas,
      occupationalGroups,
      filteredGroups,
      educations,
      andOperator,
      searching,
      sortOrder,
      selectAll,
      selected,
      emailModalOpen,
      messageLogModalOpen,
      messageLogParentId,
      mobile,
    } = this.state
    const noActions = searching ? true : selected.length < 1

    if (!this.props.user.employer.candidateSearch) {
      return (
        <MainLayout>
          <Wrapper>
            <H2>Kandidatbanken</H2>
            <div className="margin20">
              Det finns ingen kandidatbank aktiverad till detta konto.
              <br />
              <br />
              Vill ditt företag starta upp en kandidatbank och ta emot spontanansökningar via er egen hemsida?
              <br />
              <br />
              Kontakta Jobbet.se för mer information:
              <br />
              <br />
              Mail: <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>
              <br />
              <br />
              Telefon: 018-100112
            </div>
          </Wrapper>
        </MainLayout>
      )
    }

    if (!regions.length || !educations || !educations.length || !occupationalAreas.length || !occupationalGroups.length) {
      return (
        <MainLayout maxHeight scroll>
          <Wrapper>
            <h2>Kandidatbanken</h2>
            <SearchBox>
              <span className="heading">Sök kandidater</span>
              <Spinner />
            </SearchBox>
          </Wrapper>
        </MainLayout>
      )
    }

    return (
      <MainLayout maxHeight scroll>
        <Wrapper>
          <h2>Kandidatbanken</h2>
          <SearchBox>
            <span className="heading">Sök kandidater</span>
            <div className="columns">
              <div>
                <Label>Namn</Label>
                <Input type="text" autoFocus placeholder="Namn" value={search.name} onKeyDown={this.onKeyDown} onChange={(e) => this.setState({ search: { ...search, name: e.target.value } })} />
                <Label>E-post</Label>
                <Input type="text" placeholder="namn@exempel.com" value={search.email} onKeyDown={this.onKeyDown} onChange={(e) => this.setState({ search: { ...search, email: e.target.value } })} />
                <Label>Mobil</Label>
                <Input type="text" placeholder="07x-xxxxxxx" value={search.mobile} onKeyDown={this.onKeyDown} onChange={(e) => this.setState({ search: { ...search, mobile: e.target.value } })} />
                <Label>Postnummer</Label>
                <Input
                  type="text"
                  placeholder="Postnummer"
                  value={search.postalCode}
                  onKeyDown={this.onKeyDown}
                  onChange={(e) => this.setState({ search: { ...search, postalCode: e.target.value } })}
                />
                <Label>Bostadsort</Label>
                <Input
                  type="text"
                  placeholder="Bostadsort"
                  value={search.postalCity}
                  onKeyDown={this.onKeyDown}
                  onChange={(e) => this.setState({ search: { ...search, postalCity: e.target.value } })}
                />
                <Label $normal data-tooltip-id="root-tooltip" data-tooltip-content="Visa endast kandidater som tackat ja till jobbtips">
                  <Checkbox checked={search.spam} onChange={(e) => this.setState({ search: { ...search, spam: !search.spam } })} />
                  Ja till jobbtips
                </Label>
              </div>
              <div>
                <Label>Lägsta utbildningsnivå</Label>
                <ObjectPicker
                  form={"true"}
                  onSelected={this.onSelectedEducation}
                  placeholder="Välj utbildningsnivå..."
                  noSelected="Ingen utbildningsnivå vald"
                  noValues="Ingen valbar utbildningsnivå"
                  current={search.education}
                  values={educations}
                  noMatch="Inga träffar"
                />
                <Label>Branscher</Label>
                <MultiObjectPicker
                  form={"true"}
                  onSelected={this.onSelectedAreas}
                  placeholder="Välj branscher..."
                  noSelected="Inga branscher valda"
                  noObjects="Inga valbara branscher"
                  objects={occupationalAreas}
                  selectedTitle="Valda branscher"
                  selectedText="bransch(er) vald(a)"
                />
                <Label>Yrkesområden</Label>
                <MultiObjectPicker
                  form={"true"}
                  onSelected={this.onSelectedGroups}
                  placeholder="Välj yrkesområden..."
                  noSelected="Inga yrkesområden valda"
                  noObjects="Inga valbara yrkesområden"
                  objects={filteredGroups}
                  selectedTitle="Valda yrkesområden"
                  selectedText="yrkesområde(er) vald(a)"
                />
                <Label>Regioner</Label>
                <MultiObjectPicker
                  form={"true"}
                  onSelected={this.onSelectedRegions}
                  placeholder="Välj regioner..."
                  noSelected="Inga regioner valda"
                  noObjects="Inga valbara regioner"
                  objects={regions}
                  selectedTitle="Valda regioner"
                  selectedText="region(er) vald(a)"
                />
                <Label>Övriga kunskaper</Label>
                <Input
                  type="text"
                  placeholder="Ange övriga kunskaper"
                  value={search.information}
                  onKeyDown={this.onKeyDown}
                  onChange={(e) => this.setState({ search: { ...search, information: e.target.value } })}
                />
                <Label $normal data-tooltip-id="root-tooltip" data-tooltip-content="Kryssa ur för träffar på något av angivna fält">
                  <Checkbox value={andOperator} checked={andOperator} onChange={(e) => this.setState({ andOperator: !andOperator })} />
                  Matcha ALLA angivna fält
                </Label>
              </div>
            </div>
            <div className="mt3">
              <PrimaryButton onClick={(e) => this.search(false)}>Sök</PrimaryButton>
            </div>
          </SearchBox>
          {searching && <Spinner />}
          {candidates && candidates.length > 0 && (
            <ActionBox data-tooltip-id="root-tooltip" data-tooltip-content={noActions ? "Markera minst en kandidat nedan." : null}>
              <SecondaryButton disabled={noActions} onClick={this.sendEmail}>
                Skicka epost till markerade kandidater
              </SecondaryButton>
              <span>
                {selected.length} av {candidates.length} markerade. {this.state.totalCandidateCount ? `(Totalt ${this.state.totalCandidateCount})` : null}
              </span>
            </ActionBox>
          )}
          {candidates && candidates.length > 0 && (
            <Table>
              <Row className="header">
                <TitleCell onClick={(e) => e.currentTarget === e.target && this.setSortOrder("name")}>
                  <Checkbox checked={selectAll} onChange={this.selectAll} />
                  Kandidat
                  <Arrow className={sortOrder.name} />
                </TitleCell>
                <InfoArea>
                  <InfoCell onClick={(e) => this.setSortOrder("createdAt")}>
                    Registreringsdatum
                    <Arrow className={sortOrder.createdAt} />
                  </InfoCell>
                  {!mobile && (
                    <InfoCell onClick={(e) => this.setSortOrder("spam")}>
                      Ja till jobbtips
                      <Arrow className={sortOrder.spam} />
                    </InfoCell>
                  )}
                </InfoArea>
              </Row>
              <Rows>
                {candidates.map((c) => (
                  <div className="row" key={c.id}>
                    <div className="titleCell">
                      <Checkbox value={c.id} checked={selectAll || !!find((s) => s.id === c.id)(selected)} onChange={this.selectRow} />
                      <a onClick={(e) => this.openCandidateProfile(e, c.id)}>{c.user.firstName + " " + c.user.lastName}</a>
                      {c.actions && c.actions.length > 0 && (
                        <div className="actions">
                          {map((action) => (
                            <i className="actionSymbol" key={action.id} data-tooltip-id="root-tooltip" data-tooltip-content={action.text} onClick={(e) => this.onCandidateAction(c, action.name)}>
                              <img src={symbols[action.name]} className={action.name} alt="" />
                            </i>
                          ))(c.actions)}
                        </div>
                      )}
                    </div>
                    {!mobile && (
                      <div className="infoArea">
                        <div className="infoCell">{this.date(c.createdAt)}</div>
                        <div className="infoCell">{c.spam && <CheckIcon />}</div>
                      </div>
                    )}
                  </div>
                ))}
              </Rows>
            </Table>
          )}
        </Wrapper>
        <Modal isOpen={emailModalOpen} onRequestClose={this.onModalClose}>
          <CandidateMailing candidates={selected} onClose={this.onModalClose} onUpdate={this.mailingOnUpdate} />
        </Modal>
        <Modal isOpen={messageLogModalOpen} onRequestClose={this.onModalClose}>
          <CandidateMessageLog candidateId={messageLogParentId} onClose={this.onModalClose} />
        </Modal>
      </MainLayout>
    )
  }
}

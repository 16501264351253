import React from 'react'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import { Checkbox, Spinner } from '_layout/form-elements'
import TrashIcon from '_images/trash.svg'
import inject from '_services/inject'

//#region Styles
const Title = styled.span`
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    min-height: 100%;
    line-height: 1.5;

    > div {
        margin-left: auto;
        img {
            margin-bottom: 0;
        }
    }
`
const Cell = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    min-height: 100%;
    border-left: 1px solid var(--color-line-light);
    line-height: 1.5;
    text-align: center;

    &.head {
        align-items: flex-end;
        padding-bottom: 5px;
    }

    input + span {
        margin-right: 0;
    }
`
const Link = styled.a`
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  text-decoration: none;
`
const Trash = styled.a`
    display: inline-block;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    background: url(${TrashIcon}) no-repeat scroll 50% 50%;
    background-size: 16px 16px;

    &:hover {
        opacity: 1;
    }
`
const List = styled.ul`
    margin: 0 0 20px 0;
    padding: 0;
    width: 100%;
    list-style-type: none;

    &.no-docs {
      margin-top: 20px;
    }

    li {
        display: flex;
        align-items: stretch;
        min-height: 40px;
        border-bottom: 1px solid var(--color-line-light);

        &.head {
            font-weight: 700;
            align-items: flex-end;
            height: 55px;
        }
    }
`
//endregion

@inject('user')
export default class DocumentList extends React.Component {
    visible = visibleClients => {
      return !!find(client => client.id === this.props.clientId)(visibleClients)
    }

    render() {
        const { title, docs, showDocument, deleteDoc, toggleClientDocument, loading, noDocsText, user } = this.props

        if (!docs || !docs.length) {
          return ([
            <List className="no-docs" key="1"><li className="italic">{noDocsText || 'Inga dokument tillgängliga'}</li></List>,
            ...( loading ? [<List className="no-docs" key="2"><Spinner /></List>] : [] )
          ])
        }
        return (
        <List>
            <li className='head'>
                <Title>{title || "Titel"} {loading && <Spinner/>}</Title>
                {this.props.user.superAdmin && toggleClientDocument &&
                <Cell className='head'>Synlig<br/>för kund</Cell>
                }
                {deleteDoc && !user.isViewer && !user.groupViewer &&
                <Cell className='head'>Ta bort</Cell>
                }
            </li>
            {map(doc =>
            <li key={doc.id}>
                <Link href={doc.file && doc.file.url} onClick={e => showDocument && showDocument(doc)} download>{doc.name}</Link>
                {this.props.user.superAdmin && toggleClientDocument &&
                <Cell><Checkbox onChange={e => toggleClientDocument(doc)} disabled={doc.shared} checked={doc.shared || this.visible(doc.visible)} /></Cell>
                }
                {deleteDoc && !user.isViewer && !user.groupViewer &&
                <Cell><Trash onClick={e => deleteDoc(doc)}/></Cell>
                }
            </li>
            )(docs)}
        </List>
        )
    }
}

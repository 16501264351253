import React from 'react'
import draftToHtml from 'draftjs-to-html'
import Modal from '_components/modal'
import TinyEditor from '_components/tiny-editor'
import { Checkbox } from '_layout/form-elements'
import { PrimaryButton, SecondaryButton, CancelButton, DeleteButton } from '_layout/buttons'
import inject from '_services/inject'
import styled from 'styled-components'
import ctrlKey from '_utils/ctrlKey'
import confirm from '_components/confirm'
import { TemplateType } from '_root/constants'

//#region
const Readonly = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`
const Header = styled.div`
  display: flex;
  padding: 16px 8px;
  line-height: 1.4rem;
  background: var(--color-brand-green-light);

  > div:first-child {
    font-weight: bold;
    margin-right: 2rem;
  }
`
//#endregion
@inject('user')
export default class EditDocument extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        editMode: !!props.startEdit,
        id: props.document.id,
        name: props.document.name,
        contentHTML: props.document.contentHTML || (props.document.content ? draftToHtml(props.document.content) : null),
        readonly: !!props.document.readonly || props.user.isViewer || props.user.groupViewer
      }
    }

    componentDidMount = () => {
        this.setState({isMounted: true})
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
      if (nextProps.document && nextProps.document.id !== prevState.id) {
        let contentHTML = nextProps.document.contentHTML || (nextProps.document.content ? draftToHtml(nextProps.document.content) : null)
        return {
          id: nextProps.document.id,
          name: nextProps.document.name,
          contentHTML: contentHTML,
          readonly: nextProps.document.readonly
        }
      }
      return null
    }

    onNameChange = e => {
      this.setState({name: e.target.value})
    }

    onContentChange = contentHTML => {
        if (!this.state.isMounted) return
        this.setState({contentHTML}, () => {
            if (!this.state.editMode) {
                this.onSave()
            }
        })
    }

    onEdit = e => {
      this.setState({editMode: true})
    }

    onSave = e => {
      this.setState({editMode: false}, () => {
        const { id, name, contentHTML, readonly } = this.state
        this.props.onSave && this.props.onSave({ id, name, contentHTML, readonly })
      })
    }

    clearDocument = () => {
      this.setState({editMode: false, id: null, name: '', contentHTML: null, isDirty: false})
    }

    onCloseDocument = async () => {
      if (!this.state.editMode || await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
        this.onSave()
      }
      this.props.onClose && this.props.onClose()
      this.clearDocument()
    }

    onDelete = e => {
      this.clearDocument()
      this.props.onDelete && this.props.onDelete(this.props.document)
    }

    toggleVisible = e => {
      this.props.onVisible && this.props.onVisible(this.props.document)
    }

    toggleReadonly = e => {
        this.setState({readonly: !this.state.readonly}, () => {
            const { id, name, contentHTML, readonly } = this.state
            this.props.onSave && this.props.onSave({ id, name, contentHTML, readonly })
        })
    }

    render() {
        const { user, title, document, open, companyId, onVisible } = this.props
        const { id, editMode, name, contentHTML, readonly } = this.state
        const { content, type } = document
        const pagehead = content && content.pagehead
        const canEdit = user.superAdmin || !readonly
        const canSave = editMode && (name !== document.name || contentHTML !== document.contentHTML)
        const visible = !!document.visible.find(c => c.id === companyId)

        return (
            <Modal isOpen={open} onRequestClose={this.onCloseDocument}>
                <h3>{title || name || 'Redigera mall'}</h3>
                {editMode &&
                <input 
                    type='text'
                    disabled={!editMode}
                    className='mb3 w-100'
                    placeholder={editMode ? 'Spara mall som...' : ''}
                    value={name}
                    onChange={this.onNameChange} />
                }
                <Header>
                  <div>
                    Tjänst:<br/>
                    Företag:<br/>
                    Kandidat:<br/>
                    Intervjuare:<br/>
                    Datum:<br/>
                  </div>
                  <div>
                    {pagehead.title || 'Namn på tjänst'}<br/>
                    {pagehead.company || 'Företagsnamn'}<br/>
                    {pagehead.candidate || 'Namn på kandidat'} {pagehead.phone && <a href={`tel:${pagehead.phone}`}>{pagehead.phone}</a>} {pagehead.email && <a href={`mailto:${pagehead.email}`}>{pagehead.email}</a>}<br/>
                    {pagehead.interviewer || 'Namn på intervjuare'}<br/>
                    {pagehead.date}<br/>
                  </div>
                </Header>
                <TinyEditor
                    key={editMode}
                    form
                    insert='h3 bold italic underline | bullist numlist | link table'
                    selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                    plugins='autolink link lists image table'
                    content={contentHTML}
                    placeholder={'Fyll i din mall här... (klistra in med ' + ctrlKey + '+V)'}
                    onChange={this.onContentChange}
                    onDirty={this.props.onDirty}
                    readOnly={!editMode}
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 420px)', minHeight: 300 }} />
                {user.superAdmin &&
                <Readonly>
                  <Checkbox value={id} onChange={this.toggleReadonly} checked={readonly} /> Skrivskyddad
                </Readonly>
                }
                <div className='tr'>
                  {user.superAdmin && !document.shared && onVisible &&
                  <Checkbox onChange={this.toggleVisible} checked={visible} label='Synlig för kund' />
                  }
                  <CancelButton className='ml3 invert' onClick={this.onCloseDocument}>Stäng</CancelButton>
                  {canEdit &&
                  <React.Fragment>
                    {!user.isViewer && !user.groupViewer &&
                    <DeleteButton className='ml3' onClick={this.onDelete}>Radera</DeleteButton>
                    }
                    <SecondaryButton className='ml3' onClick={this.onEdit} disabled={editMode}>Redigera</SecondaryButton>
                    <PrimaryButton className='ml3' onClick={this.onSave} disabled={!canSave}>Spara</PrimaryButton>
                  </React.Fragment>
                  }
                </div>
            </Modal>
        )
    }
}
import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { map, filter } from 'lodash/fp'
import { format, parseISO, differenceInHours } from "date-fns"
import sv from "date-fns/locale/sv"
import message from '_components/message'
import draftToHtml from 'draftjs-to-html'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import ManageInterview from '_root/components/interview/manage-interview'
import { PrimaryButton, CancelButton } from '_layout/buttons'
import { Spinner, Checkbox } from '_layout/form-elements'
import { withEmailApplicationMutation } from '_modules/applications'
import { VACANT_INTERVIEWS } from '_containers/interview/interview-ql'
import { NEW_INTERVIEW_MUTATION, NEW_APP_INTERVIEW_MUTATION } from '_containers/interview/interview-ql'
import * as url from '_routes/url-names'
import to from '_services/await.to'
import inject from '_services/inject'
import all from '_root/services/await.all'
import ctrlKey from '_utils/ctrlKey'

//#region Styles
const Wrapper = styled.div`
    #tooltip {
        max-width: 500px;
    }
`
const TimeSlots = styled.div`
    margin: 0;
    width: 750px;
    height: 250px;
    max-width: 100%;
    background: var(--body-bg);

    .notice {
      opacity: 0.4;
    }

    > a {
        margin-bottom: 0.5rem;
        display: inline-block;
        user-select: none;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--color-brand-red-light);
        }
    }
`
const TimeSlotsList = styled.ul`
  overflow: auto;
  height: 200px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid var(--color-bg-light);

  li {
    display: flex;
    padding: 10px;
    font-size: 0.9rem;
    border-bottom: 1px solid var(--color-bg-light);

    > label {
      width: 160px;
    }

    &:last-child {
      border-bottom: none;
    }

    &.invited {
      pointer-events: none;
      opacity: 0.4;

      > span:nth-of-type(1) {
        text-decoration: line-through;
      }
    }

    > span {
      display: inline-block;
      width: 120px;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: text-top;
    }
  }

  @media screen and (max-width: 767px) {
    li {
      display: block;

      > label {
        display: flex;
      }

      > span {
        width: 30%;
        margin-right: 3%;
        margin-top: 5px;
      }
    }
  }
`
const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`
//#endregion

@compose(
    inject('user'),
    graphql(VACANT_INTERVIEWS, { name: 'interviewsQuery', options: props => ({ variables: { company: props.companyId } }) }),
    graphql(NEW_INTERVIEW_MUTATION, { name: 'newInterview' }),
    graphql(NEW_APP_INTERVIEW_MUTATION, { name: 'newApplicationInterview' }),
    withEmailApplicationMutation
)
export default class ApplicationInterviews extends React.Component {
    state = {
      mobile: window.innerWidth < 768,
      loading: true,
      createDialog: false,
      slots: [],
      editorKey: null
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (!prevState.loading) return null
        const applications = filter(a => a.candidate && a.candidate.user)(nextProps.applications || [])
        return {
            loading: false,
            templates: nextProps.templates || [],
            recipients: map(a => a.candidate.user.email)(applications),
            applications: applications,
            sender: nextProps.user.email || '',
            name: ''
        }
    }

    onSenderChange = e => {
        this.setState({sender: e.target.value})
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onAddLink = () => {
        const bookingUrl = `${window.location.protocol}//${window.location.host}${url.MEDLEM}${url.BOKNING}`
        let { contentHTML = '', editorKey } = this.state
        contentHTML += `<p><a href="${bookingUrl}">${bookingUrl}</a></p>`
        this.setState({ contentHTML, editorKey: editorKey + '_edit' })
    }

    onCreateInterview = () => {
        this.setState({ createDialog: true })
    }

    onSaveInterview = async interview => {
      const variables = {
          createdBy: this.props.user.id,
          companyId: this.props.companyId,
          startTime: interview.startTime,
          endTime: interview.endTime,
          spots: interview.spots,
          note: interview.note,
          location: interview.location,
          guest: interview.guest,
          interviewers: interview.interviewers.map(i => ({ id: i.id })),
          interviewersExternal: interview.interviewersExternal.map(i => ({ id: i.id })),
      }
      this.setState({ createDialog: false })
      const [error, result] = await to(this.props.newInterview({ variables: variables }))
      let { slots } = this.state
      if (error) {
          console.error('VacantInterviews.onCreateInterview', error)
      }
      else if (result) {
        let { data: { createInterview: { id: interviewId} } } = result
        slots.push(interviewId)
        this.props.interviewsQuery.refetch()
      }
      this.setState({ createDialog: false, slots })
    }

    onContentChange = contentHTML => {
        this.setState({contentHTML})
    }

    onSelectSlot = (e, interviewId) => {
        let { slots } = this.state
        const index = slots.indexOf(interviewId)
        if (index > -1) {
            slots.splice(index, 1)
        }
        else {
            slots.push(interviewId)
        }
        this.setState({slots})
    }

    onSelected = template => {
        if (!template) return
        this.setState({
            name: template.name || '',
            contentHTML: template.contentHTML || draftToHtml(template.content),
            selected: template,
            editorKey: template.id
        })
    }

    createInvites = async (applications, slots) => {
        const appInterviews = []
        applications.forEach(app => {
            slots.forEach(slot => {
                appInterviews.push(this.props.newApplicationInterview({ variables: { applicationId: app.id, interviewId: slot } }))
            })
        })
        return await all(appInterviews)
    }

    onSend = async e => {
        this.setState({sending: true})
        const { name, contentHTML, applications, recipients, sender, slots } = this.state
        if (!sender || !name || !contentHTML || !recipients || !recipients.length) return
        const [errors] = await this.createInvites(applications, slots)
        if (errors) {
            console.error('application-mailing:onSend:errors: ', errors);
            this.props.onError(errors)
            return
        }
        message('E-post skickas i bakgrunden till ' + (recipients.length > 1 ? 'valda kandidater.' : 'vald kandidat.'), { text: 'Du kan fortsätta arbeta under tiden.' })
        this.onCancel()
        // TODO: Add ICS file as an attachment...
        const [error] = await to(this.props.emailApplication({
            from: sender,
            recruitmentId: this.props.recruitmentId,
            to: map(a => ({
                applicationId: a.id,
                email: a.candidate.user.email
            }))(applications),
            subject: name,
            html: contentHTML,
            group: false
        }))
        if (error) {
            console.error('application-mailing:onSend:error: ', error);
            this.props.onError(error)
        }
        else {
            this.props.onUpdate()
        }
    }

    onCancel = e => {
        this.setState({name: '', contentHTML: null, sending: false, errorMessage: null, editorKey: null})
        this.props.onClose()
    }

    render() {
        const { loading, vacantInterviews = [] } = this.props.interviewsQuery
        const { sender, name, contentHTML, selected, templates, recipients, sending, errorMessage, slots, createDialog, editorKey, applications, mobile } = this.state
        const invited = applications.map(a => a.interviews.filter(i => !!i.interview).map(i => i.interview.id)).flat()
        const vacant = vacantInterviews.map(v => ({...v, invited: !!invited.find(i => i === v.id)}))
        const canSend = sender && name && contentHTML && recipients && recipients.length
        const allInvited = invited.length && !vacant.length
        const anyInvited = !!vacant.find(v => v.invited)

        return (
            <Wrapper>
                <h3>Inbjudan till möte</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <label className='mb2 db'>Från:</label>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Från'}
                    value={sender}
                    onChange={this.onSenderChange} />
                <label className='mb2 db'>Skickas till: (endast synligt för administratörer)</label>
                <div className='mb3'>{recipients.join(', ')}</div>
                <TimeSlots>
                    <a onClick={this.onCreateInterview}>+ skapa mötestid</a>
                    {anyInvited &&
                    <span className="ml4 notice" data-tooltip-content="Dessa visas strukna i listan nedan" data-tooltip-id="tooltip">OBS! Det finns tidigare skickade inbjudningar.</span>
                    }
                    {loading &&
                    <Spinner/>
                    }
                    {!loading &&
                    <TimeSlotsList>
                        {!!allInvited &&
                        <li>Inbjudan har redan skickats till alla tillgängliga tider.</li>
                        }
                        {vacant.map(i =>
                        <li key={i.id} className={i.invited ? 'invited' : ''}>
                          <Checkbox value={i.id} onChange={this.onSelectSlot} checked={slots.includes(i.id)} label={format(parseISO(i.startTime), "d MMM HH:mm", { locale: sv }) + ' (' + differenceInHours(parseISO(i.endTime), parseISO(i.startTime)) + 'h)'} />
                          <span data-tooltip-content={i.location} data-tooltip-id="tooltip">{i.location}</span>
                          <span data-tooltip-html={`${i.interviewers.map(iv => iv.fullName).join('<br/>')}`} data-tooltip-id="tooltip">{i.interviewers.map(iv => iv.firstName).join(', ')}</span>
                          <span>{i.vacantSpots} {i.vacantSpots !== 1 ? 'platser' : 'plats'}</span>
                        </li>)}
                    </TimeSlotsList>
                    }
                </TimeSlots>
                <ObjectPicker
                    form={'true'}
                    className='mb3'
                    values={templates}
                    placeholder='Välj mall...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga mallar' />
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Ämne'}
                    value={name}
                    onChange={this.onNameChange} />
                <TinyEditor
                    key={editorKey}
                    form
                    placeholder={'Fyll i din mall här... (klistra in med ' + ctrlKey + '+V)'}
                    content={contentHTML}
                    onChange={this.onContentChange}
                    onDirty={this.props.onDirty}
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 560px)', minHeight: 300 }} />
                <div className='tr'>
                  <a className='fl' onClick={this.onAddLink}>+ infoga bokningslänk i slutet av meddelande</a>
                    <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                    <PrimaryButton onClick={this.onSend} disabled={!canSend} loading={sending}>Skicka</PrimaryButton>
                </div>
                <ManageInterview
                  mobile={mobile}
                  open={createDialog}
                  onClose={e => this.setState({createDialog: false})}
                  onCreate={this.onSaveInterview} />
                <Tooltip id='tooltip' place='bottom' effect='solid' multiline={true} />
            </Wrapper>
        )
    }
}
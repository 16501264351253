import { gql } from '@apollo/client'

export const SEARCH_EMPLOYEES = gql`
query SearchEmployees(
    $take: Int,
    $skip: Int,
    $query: String!
) {
    searchEmployees(query: $query, take: $take, skip: $skip) {
        total
        employees {
            id
            active
            firstName
            lastName
            employer {
                id
                name
                urlName
            }
        }
    }
}
`

import React from 'react'
import styled from 'styled-components'

import { PrimaryButton } from '_layout/buttons';
import { ResultList, ResultItem } from '_modules/search/common-layout/result-lists'

const ResultListWrapper = styled.div`
    overflow: auto;
    flex: 1;

    & .resultlist__fetchmore {
        display: block;
        margin: 20px auto;
    }
`

const DetailedGroupResult = props => {
    const { results, meta, onFetchMoreResults, isFetchingMore } = props;
    const metaCount = (meta && meta._count && meta._count._all) || 0

    return (
        <ResultListWrapper>
            <ResultList>
            {
                props.results.map((result) => 
                    <ResultItem key={result.id}>
                        <a href={`/${result.urlName}`} target='_blank' rel='opener'>{result.name}</a>
                    </ResultItem>
                )
            }
            </ResultList>
            { (metaCount > results.length) && <PrimaryButton loading={isFetchingMore} className="resultlist__fetchmore" onClick={onFetchMoreResults}>Visa fler</PrimaryButton> }
        </ResultListWrapper>
    )
}

export default DetailedGroupResult
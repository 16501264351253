import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import onClickOutside from "react-onclickoutside"
import styled from "styled-components"
import ProfileImage from "_images/profile-white.svg"
import LoginService from "_services/login-service"
import isLoggedIn from "_services/is-logged-in"
import * as url from "_routes/url-names"

//#region Styles
const SideMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`
const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 75px;
  cursor: pointer;
  border-left: 1px solid var(--color-line);

  &:hover {
    background-color: var(--color-brand-red-dark);
  }

  @media (max-width: 500px) {
    width: 75px;
  }
`
const ProfileButton = styled.a`
  display: block;
  width: 34px;
  height: 34px;
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-color: transparent;
`
const DropMenu = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style-type: none;
  min-width: 125px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(100% + 5px);
  right: 1px;
  background: var(--color-brand-black);
  border: 1px solid var(--color-bg-dark);
  box-shadow: 2px 2px 10px var(--color-bg-light);
  z-index: 10000;
`
const MenuItem = styled.li`
  display: flex;
  align-items: center;

  a {
    flex: 1;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
    white-space: nowrap;
    padding: 5px 10px;

    &.active {
      pointer-events: none;
      background: var(--color-brand-red);
    }

    &:hover {
      background: var(--color-white-hover);
    }
  }
`
const UserName = styled.span`
  color: var(--color-text-white);
  margin-right: 20px;

  @media (max-width: 500px) {
    max-width: 130px;
    margin: 0 6px;
  }
`
//#endregion

@compose(withRouter, withApollo, onClickOutside)
export default class CandidateMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileOpen: false,
      profileRoute: this.props.location.pathname.indexOf(url.MEDLEM) > -1,
    }
  }

  logout = (e) => {
    e.preventDefault()
    LoginService.clearToken({ client: this.props.client, nextUrl: url.MEDLEM })
    this.setState({ profileOpen: false })
  }

  handleClickOutside = (e) => {
    if (!this.state.profileOpen) return
    this.setState({ profileOpen: false })
  }

  render() {
    const { profileRoute, profileOpen } = this.state

    const userName = `${this.props.user.firstName} ${this.props.user.lastName}`

    if (isLoggedIn() === false || this.props.user.active === false) return null

    return (
      <SideMenu>
        <UserName>{userName}</UserName>
        <ButtonWrapper onClick={(e) => this.setState({ profileOpen: !profileOpen, settingsOpen: false })}>
          <ProfileButton />
        </ButtonWrapper>
        {profileOpen && (
          <DropMenu>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.PROFIL}>
                Min profil
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.ANSOKNINGAR}>
                Ansökningar
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.BOKNING}>
                Bokade möten
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.INSTALLNINGAR}>
                Hantera konto
              </NavLink>
            </MenuItem>
            <MenuItem>
              <a onClick={this.logout}>Logga ut</a>
            </MenuItem>
          </DropMenu>
        )}
      </SideMenu>
    )
  }
}

import React from 'react'
import { Navigate } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { isEmpty } from 'lodash/fp'
import styled from 'styled-components'
import { ROOT } from '_routes/url-names'
import Header from '_components/header'
import Policy from '_components/policy'
import { Spinner, FlexRow, FlexCol, Checkbox } from '_layout/form-elements'
import { PrimaryButton } from '_layout/buttons'
import inject from '_services/inject'
import LoginService from '_services/login-service'
import { USER_ROLE_QUERY, ACTIVATE_USER_MUTATION } from '_root/common-ql'
import to from '_services/await.to'

//#region Styles
const Main = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
`
const Head = styled(Header)`
    flex: 0 1 auto;
`
const Body = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
`
const Content = styled.div`
    position: relative;
    flex: 0 1 760px;
    padding: 0 40px;
    background: var(--color-bg-white);
`
const H2 = styled.h2`
    margin: 40px 0 30px 0;
    max-width: 760px;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--color-bg-light);
`
const Ingress = styled.p`
    margin-bottom: 30px;
`
//#endregion

@compose(
    withRouter,
    withApollo,
    inject('user'),
    graphql(USER_ROLE_QUERY, { name: 'userQuery', options: props => ({ variables: { id: props.match.params.activate } }) }),
    graphql(ACTIVATE_USER_MUTATION, { name: 'activateUser' })
)

export default class Activate extends React.Component {
    constructor(props) {
        super(props)
        const user = {...props.user, id: props.match.params.activate }
        this.state = {
            activated: false,
            accept: false,
            accepted: !!user.policyCompany && !!user.policyCompany.policyAccepted,
            loaded: !!user.policyCompany,
            user: user
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}
        if (!prevState.loaded && !nextProps.userQuery.loading && nextProps.userQuery.user ) {
            const user = {...prevState.user, ...nextProps.userQuery.user }
            nextState.user = user
            nextState.accept = !!user.policyCompany && !user.policyCompany.policyAccepted
            nextState.accepted = !!user.policyCompany && !!user.policyCompany.policyAccepted
            nextState.loaded = true
        }
        return !isEmpty(nextState) ? nextState : null
    }

    componentDidMount = () => {
        const { loaded, activated, accept } = this.state
        if (loaded && !accept && !activated) {
            this.onActivate()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { loaded, activated, accept } = this.state
        if (loaded && !accept && !activated) {
            this.onActivate()
        }
    }

    onPolicyOpen = () => {
        this.setState({policyOpened: true})
    }

    onAccept = e => {
        this.setState({accepted: e.target.checked})
    }

    onActivate = async e => {
        const { accept, accepted, user } = this.state
        if (accept && !accepted) return
        const [error, result] = await to(this.props.activateUser({ variables: { id: user.id, sendPassword: !user.loggedIn, policyAccepted: accept && accepted } }))
        if (error || !result) {
            console.error('activate-account:onActivate:error: ', error)
            return
        }
        this.setState({activated: true})
    }

    render() {
        const { user, loaded, accept, accepted, activated } = this.state

        if (user && user.active) {
            return <Navigate to={ROOT} />
        }
        let content = <FlexCol><Spinner /></FlexCol>
        if (loaded && !this.props.userQuery.loading) {
            if (user === null) {
                content = <FlexCol>
                    Användare kunde inte hittas!
                    <br/>
                    <br/>
                    Vänligen kontakta kundtjänst om du inte kommer vidare.
                </FlexCol>
            }
            else if (activated) {
                if (user && user.loggedIn) {
                    LoginService.logoutUser(this.props.client, ROOT)
                }
                else {
                   content = <FlexCol>
                        Kontot är aktiverat!
                        <br/>
                        <br/>
                        Inloggningsinformation har skickats till din e-post.
                    </FlexCol>
                }
            }
            else if (accept) {
                content = <FlexCol>
                    <h3>Godkännande av villkor</h3>
                    <p>
                        I rekryteringscenter hanteras personuppgifter.
                        <br/>
                        Vi behöver därför godkännande från dig som administratör innan kontot aktiveras.
                        {!user.loggedIn && <span><br/><br/>Efter godkännande skickas inloggningsinformation till dig via e-post.</span>}
                    </p>
                    <Policy className='mb4' onOpen={this.onPolicyOpen}>Läs villkor</Policy>
                    <Checkbox className='mb4' onChange={this.onAccept} label='Jag har läst och godkänner villkoren ovan.' />
                    <PrimaryButton onClick={this.onActivate} disabled={!accepted}>Skicka</PrimaryButton>
                </FlexCol>
            }
            else if(user.loggedIn) {
                content = <FlexCol>
                    <Ingress>Klicka på knappen nedan för att gå vidare.</Ingress>
                    <PrimaryButton onClick={this.onActivate}>Gå vidare</PrimaryButton>
                </FlexCol>
            }
        }

        return (
            <Main>
                <Head/>
                <Body>
                    <Content>
                        <H2>Hantera ditt konto</H2>
                        <FlexRow>
                            {content}
                        </FlexRow>
                    </Content>
                </Body>
            </Main>
        )
    }
}

import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import map from "lodash/fp/map"
import find from "lodash/fp/find"
import filter from "lodash/fp/filter"
import isEmpty from "lodash/fp/isEmpty"
import isEqual from "lodash/fp/isEqual"
import { debounce } from "lodash"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import { parseISO } from "date-fns"
import CloseIcon from "_images/menu-close.svg"
import FilterIcon from "_images/filter.svg"
import { Process, ProcessType, STORE_FILTER } from "_root/constants"
import { GET_OCCUPATIONAL_AREAS, GET_REGIONS } from "_root/common-ql"
import { JOBBET_USERS_QUERY } from "_containers/recruitment/recruitment-ql"
import { Input } from "_layout/form-elements"
import { CancelButton, SecondaryButton } from "_layout/buttons"
import MultiObjectPickerBase from "_components/multi-object-picker"
import inject from "_services/inject"
import { ALL_ADDONS } from "_containers/recruitment/recruitment-ql"
import UserPicker from "_root/components/user-picker"

//#region Styles
const SideBar = styled.div`
  color: var(--color-text-white);
  background-color: var(--color-brand-green);
  box-shadow: -2px 0px 10px var(--color-line-dark);
  z-index: 2;
  transition: background-color, box-shadow 0.1s;

  &.open {
    min-width: 320px;
    overflow-y: auto;

    &:hover {
      background-color: var(--color-brand-green);
    }
  }

  &:hover {
    background-color: var(--color-brand-green-dark);
  }

  @media (max-height: 500px) {
    &.open {
      min-width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 10px;
    right: 0;

    &.open {
      top: 0;
      width: 100%;
      max-width: 500px;
    }
  }
`
const SideBarHead = styled.div`
  position: relative;
  display: flex;
  justify-content: ${props => (props.$open ? "flex-start" : "center")};
  align-items: center;
  height: ${props => (props.$open ? "auto" : "100%")};
  padding-right: ${props => (props.$open ? "20px" : "")};
  border-bottom: 1px solid var(--color-text-medium);
  color: var(--color-text-white);
  background: ${props => (props.$open ? "var(--color-brand-green-dark)" : "transparent")};
  font-size: 0.9em;
  font-weight: 200;
  cursor: ${props => (props.$open ? "" : "pointer")};

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 767px) {
    border-bottom: none;
  }
`
const SideBarCloseWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const SideBarClose = styled.i`
  display: flex;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url(${CloseIcon}) no-repeat scroll 50% 50% var(--color-brand-green-dark);
  background-size: 40%;
`
const SideBarOpen = styled.i`
  display: flex;
  align-self: flex-start;
  width: 50px;
  height: 50px;
  background: url(${FilterIcon}) no-repeat scroll 50% 50% var(--color-brand-green);
  background-size: 50%;
  border-bottom: 1px solid var(--color-line-dark);
  transition: background-color 0.1s;

  ${SideBar}:hover & {
    background-color: var(--color-brand-green-dark);
  }
`
const SideBarShow = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-white);
  font-weight: 200;
  white-space: nowrap;
  text-transform: uppercase;
  transform: rotate(90deg);

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Triangle = styled.div`
  position: absolute;
  top: 50%;
  right: 75%;
  width: 20px;
  height: 20px;
  overflow: hidden;
  z-index: 1;
  transform: translate(0, -50%);
  transition: right 0.1s;

  ${SideBar}:hover & {
    right: 108%;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    right: -12px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    box-sizing: border-box;
    border: 1px solid var(--color-brand-green);
    background: transparent;
    transform: rotate(45deg);

    ${SideBar}:hover & {
      border: 1px solid var(--color-nav-2);
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const SaveFilter = styled(SecondaryButton)`
  height: 24px;
  margin-left: auto;
  padding: 3px 16px;
  border: none;

  &:hover {
    background: var(--color-brand-green-ad);
  }
`
const FilterIndicator = styled.div`
  margin-left: 10px;
  padding: 3px 8px;
  border-radius: 25px;
  background: var(--color-bg-white);
  color: var(--color-text-dark);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`
const Content = styled.div`
  padding: 20px;
  border-left: 2px solid var(--color-bg-light);
  background: var(--color-bg-light);
  overflow-y: auto;

  .mb {
    margin-bottom: 20px;
  }

  @media (max-height: 500px) {
    padding: 20px 10px;
  }
`
const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--color-text-dark);
`
const Date = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  color: var(--color-text-dark);

  .react-datepicker-wrapper {
    margin-left: auto;
    .react-datepicker__input-container {
      input {
        width: 173px;
        padding: 5px 10px;
      }
    }
  }
`
const MultiObjectPicker = styled(MultiObjectPickerBase)`
  margin-bottom: 20px;
  color: var(--color-text-dark);
`
//#endregion

@compose(
  withRouter,
  inject("user", "menu"),
  graphql(ALL_ADDONS, { name: "addonsQuery" }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "occupationalAreasQuery" }),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(JOBBET_USERS_QUERY, { name: "jobbetUsersQuery", options: props => ({ fetchPolicy: "network-only" }) })
)
export default class Filter extends React.Component {
  constructor(props) {
    super(props)
    registerLocale("sv", sv)
    this.state = {
      isJobbet: this.props.match.params.company === "jobbet.se",
      companyView: null,
      stored: false,
      randomKey: Math.random(),
      jobbetUsers: [],
      regions: [],
      occupationalAreas: [],
      activeFilters: 0,
      title: "",
      company: "",
      group: "",
      recruiter: "",
      guest: "",
      external: [],
      contacts: [],
      process: [],
      selectedRegions: [],
      selectedOccupationalAreas: [],
      applyStartAfter: null,
      applyStartBefore: null,
      applyStopAfter: null,
      applyStopBefore: null,
      addons: [],
      where: {},
      saved: true,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {}
    if (nextProps.user.companyView && !prevState.companyView) {
      nextState.companyView = nextProps.user.companyView
    }
    if (nextProps.jobbetUsersQuery.users && nextProps.jobbetUsersQuery.users.length !== prevState.jobbetUsers.length) {
      nextState.jobbetUsers = map(e => ({ ...e, name: `${e.firstName} ${e.lastName}` }))(nextProps.jobbetUsersQuery.users)
    }
    if (!prevState.regions.length && nextProps.regionsQuery.regions && nextProps.regionsQuery.regions.length) {
      nextState.regions = [...nextProps.regionsQuery.regions]
    }
    if (!prevState.occupationalAreas.length && nextProps.occupationalAreasQuery.occupationalAreas && nextProps.occupationalAreasQuery.occupationalAreas.length) {
      nextState.occupationalAreas = [...nextProps.occupationalAreasQuery.occupationalAreas]
    }
    if (!Filter.addons.length && !nextProps.addonsQuery.loading && nextProps.addonsQuery.addons && nextProps.addonsQuery.addons.length) {
      Filter.addons = [...nextProps.addonsQuery.addons]
    }
    if (!isEmpty(nextState)) {
      const { user } = nextProps
      const filterStored = user && user.filter && !isEmpty(user.filter.where) ? user.filter : Filter.getStoredFilter({ ...prevState, ...nextState })
      if (filterStored && Object.size(filterStored)) {
        nextState = { ...nextState, ...filterStored, stored: true }
        const activeFilters = Filter.countActiveFilters(nextState)
        nextState.activeFilters = activeFilters
      }
      return nextState
    }
    return null
  }

  static process = [
    { id: 0, type: ProcessType.SELECTION, name: "Urval" },
    { id: 1, type: ProcessType.INTERVIEW1, name: "1:a intervju" },
    { id: 2, type: ProcessType.TEST, name: "Test" },
    { id: 3, type: ProcessType.INTERVIEW2, name: "2:a intervju" },
    { id: 4, type: ProcessType.REFERENCES, name: "Referens" },
    { id: 5, type: ProcessType.OTHER, name: "Övrigt" },
    { id: 6, type: ProcessType.CLOSING, name: "Avslut" },
  ]

  static addons = []

  static getStoredFilter(state) {
    localStorage.removeItem(state.companyView ? "STORE_FILTER" + state.companyView.id : "STORE_FILTER") // Clear old filters
    const filterStored = (state.companyView && localStorage.getItem(STORE_FILTER + state.companyView.id)) || localStorage.getItem(STORE_FILTER)
    if (!filterStored) return null
    const filterProps = JSON.parse(filterStored)
    // Clean filter since last store.
    filterProps.external = filter(e => !!find(ju => e.id === ju.id)(state.jobbetUsers))(filterProps.external)
    filterProps.contacts = filter(e => !!find(ju => e.id === ju.id)(state.jobbetUsers))(filterProps.contacts)
    filterProps.process = filter(e => !!find(p => e.id === p.id)(Filter.process))(filterProps.process)
    filterProps.selectedRegions = filter(e => !!find(r => e.id === r.id)(state.regions))(filterProps.selectedRegions)
    filterProps.selectedOccupationalAreas = filter(e => !!find(oa => e.id === oa.id)(state.occupationalAreas))(filterProps.selectedOccupationalAreas)
    return filterProps
  }

  onChange = debounce(() => {
    const where = {}
    const {
      addons,
      applyStartAfter,
      applyStartBefore,
      applyStopAfter,
      applyStopBefore,
      group,
      company,
      companyView,
      contacts,
      external,
      guest,
      process,
      recruiter,
      selectedOccupationalAreas,
      selectedRegions,
      title,
      isJobbet,
    } = this.state
    if (addons && addons.length) where.addons = { some: { id: { in: addons.map(a => a.id) } } }
    if (applyStartAfter) where.applyStartAfter = { gte: applyStartAfter }
    if (applyStartBefore) where.applyStartBefore = { lte: applyStartBefore }
    if (applyStopAfter) where.applyStopAfter = { gte: applyStopAfter }
    if (applyStopBefore) where.applyStopBefore = { lte: applyStopBefore }
    if (!isJobbet) {
      where.company = { id: { equals: companyView.id } }
    } else {
      if (group && company) {
        where.company = { OR: [{ groups: { some: { name: { contains: group } } } }, { name: { contains: company } }] }
      } else if (group) {
        where.company = { groups: { some: { name: { contains: group } } } }
      } else if (company) {
        where.company = { name: { contains: company } }
      }
    }
    const recruiterNames = recruiter && map(name => ({ OR: [{ firstName: { contains: name } }, { lastName: { contains: name } }] }))(recruiter.split(/[ ,]+/))
    if (recruiterNames && recruiterNames.length) where.recruiter = { AND: recruiterNames }
    const guestNames = guest && map(name => ({ OR: [{ firstName: { contains: name } }, { lastName: { contains: name } }] }))(guest.split(/[ ,]+/))
    if (guestNames && guestNames.length) where.recruiterGuests = { some: { AND: guestNames } }
    if (external && external.length) where.recruiterExternals = { some: { id: { in: external.map(e => e.id) } } }
    if (contacts && contacts.length) {
      where.company = where.company || {}
      where.company.jobbetContacts = { some: { id: { in: contacts.map(c => c.id) } } }
    }
    if (selectedRegions && selectedRegions.length) where.regions = { some: { id: { in: selectedRegions.map(e => e.id) } } }
    if (selectedOccupationalAreas && selectedOccupationalAreas.length) where.occupationalAreas = { some: { id: { in: selectedOccupationalAreas.map(e => e.id) } } }
    if (process && process.length) where.process = { some: { AND: [{ state: { equals: Process.ACTIVE } }, { type: { in: process.map(p => p.type) } }] } }
    if (title) where.title = { contains: title }
    if (!isEqual(where)(this.state.where)) {
      this.setState({ where, saved: false })
      const filter = {
        addons,
        applyStartAfter,
        applyStartBefore,
        applyStopAfter,
        applyStopBefore,
        group,
        company,
        contacts,
        external,
        guest,
        process,
        recruiter,
        selectedOccupationalAreas,
        selectedRegions,
        title,
        where,
      }
      this.props.onChange(filter)
    }
  }, 500)

  componentDidUpdate = () => {
    const activeFilters = Filter.countActiveFilters(this.state)
    if (activeFilters !== this.state.activeFilters) {
      this.setState({ activeFilters })
    }
  }

  filterTitle = e => {
    this.setState({ title: e.target.value.toLowerCase() }, this.onChange)
  }

  filterCompany = e => {
    this.setState({ company: e.target.value.toLowerCase() }, this.onChange)
  }

  filterGroup = e => {
    this.setState({ group: e.target.value.toLowerCase() }, this.onChange)
  }

  filterRecruiter = e => {
    this.setState({ recruiter: e.target.value.toLowerCase() }, this.onChange)
  }

  filterExternal = external => {
    this.setState({ external }, this.onChange)
  }

  filterGuest = e => {
    this.setState({ guest: e.target.value.toLowerCase() }, this.onChange)
  }

  filterContacts = contacts => {
    this.setState({ contacts }, this.onChange)
  }

  filterRegions = selectedRegions => {
    this.setState({ selectedRegions }, this.onChange)
  }

  filterAreas = selectedOccupationalAreas => {
    this.setState({ selectedOccupationalAreas }, this.onChange)
  }

  filterProcess = process => {
    this.setState({ process }, this.onChange)
  }

  filterAfterStartDate = applyStartAfter => {
    this.setState({ applyStartAfter: applyStartAfter?.toISOString() }, this.onChange)
  }

  filterBeforeStartDate = applyStartBefore => {
    this.setState({ applyStartBefore: applyStartBefore?.toISOString() }, this.onChange)
  }

  filterAfterStopDate = applyStopAfter => {
    this.setState({ applyStopAfter: applyStopAfter?.toISOString() }, this.onChange)
  }

  filterBeforeStopDate = applyStopBefore => {
    this.setState({ applyStopBefore: applyStopBefore?.toISOString() }, this.onChange)
  }

  filterAddons = addons => {
    this.setState({ addons }, this.onChange)
  }

  saveFilter = () => {
    const { randomKey, isJobbet, jobbetUsers, activeFilters, regions, occupationalAreas, companyView, stored, saved, ...filter } = this.state
    localStorage.setItem(STORE_FILTER + this.props.user.companyView.id, JSON.stringify(filter))
    this.setState({ stored: true, saved: true })
  }

  loadFilter = () => {
    const filterStored = Filter.getStoredFilter(this.state)
    if (filterStored && Object.size(filterStored)) {
      this.setState({ ...filterStored, stored: true, randomKey: Math.random() }, () => {
        this.saveFilter()
        this.props.onChange(filterStored)
      })
    }
  }

  clearFilter = () => {
    localStorage.removeItem(STORE_FILTER)
    localStorage.removeItem(STORE_FILTER + this.props.user.companyView.id)
    this.setState({ stored: false })
  }

  static countActiveFilters = state => {
    let count = 0
    const { title, company, recruiter, guest, external, contacts, process, selectedRegions, selectedOccupationalAreas, applyStartAfter, applyStartBefore, addons } = state
    if (title) count++
    if (company) count++
    if (recruiter) count++
    if (guest) count++
    if (applyStartAfter) count++
    if (applyStartBefore) count++
    if (external && external.length) count++
    if (contacts && contacts.length) count++
    if (process && process.length) count++
    if (selectedRegions && selectedRegions.length) count++
    if (selectedOccupationalAreas && selectedOccupationalAreas.length) count++
    if (addons && addons.length) count++
    return count
  }

  render = () => {
    const { user, menu } = this.props

    const {
      title,
      company,
      group,
      recruiter,
      external,
      guest,
      contacts,
      jobbetUsers,
      regions,
      occupationalAreas,
      selectedRegions,
      selectedOccupationalAreas,
      occupationalArea,
      region,
      process,
      applyStartAfter,
      applyStartBefore,
      applyStopAfter,
      applyStopBefore,
      activeFilters,
      addons,
      isJobbet,
      randomKey,
      saved,
    } = this.state

    const canSave = !saved && !!(title || company || recruiter || external || guest || contacts || occupationalArea || (process && process.length) || region)

    if (!menu.filterMenuOpen) {
      return (
        <SideBar>
          <SideBarHead onClick={this.props.onClick}>
            <Triangle />
            <SideBarOpen />
            <SideBarShow>
              Visa filter
              {activeFilters > 0 ? <FilterIndicator>{activeFilters + (activeFilters !== 1 ? " aktiva filter" : " aktivt filter")}</FilterIndicator> : ""}
            </SideBarShow>
          </SideBarHead>
        </SideBar>
      )
    }

    return (
      <SideBar className="open">
        <SideBarHead $open>
          <SideBarCloseWrapper onClick={this.props.onClick}>
            <SideBarClose />
            Dölj filter
          </SideBarCloseWrapper>
          <SaveFilter onClick={this.saveFilter} disabled={!canSave}>
            Spara filter
          </SaveFilter>
        </SideBarHead>
        <Content>
          <div>
            <Label>Filtrera på tjänst</Label>
            <Input type="text" placeholder="Tjänst..." data-tooltip-content="Tjänst" data-tooltip-id="root-tooltip" data-tooltip-place="left" value={title} onChange={this.filterTitle} />
          </div>
          {user.superAdmin && isJobbet && (
            <div>
              <Label>Filtrera på företag</Label>
              <Input type="text" placeholder="Företag..." data-tooltip-content="Företag" data-tooltip-id="root-tooltip" data-tooltip-place="left" value={company} onChange={this.filterCompany} />
            </div>
          )}
          {user.superAdmin && isJobbet && (
            <div>
              <Label>Filtrera på grupp</Label>
              <Input type="text" placeholder="Grupp..." data-tooltip-content="Grupp" data-tooltip-id="root-tooltip" data-tooltip-place="left" value={group} onChange={this.filterGroup} />
            </div>
          )}
          <div>
            <Label>Filtrera på beställare</Label>
            <Input
              type="text"
              placeholder="Beställare..."
              data-tooltip-content="Beställare"
              data-tooltip-id="root-tooltip"
              data-tooltip-place="left"
              value={recruiter}
              onChange={this.filterRecruiter}
            />
          </div>
          <div>
            <Label>Filtrera på användare</Label>
            <Input type="text" placeholder="Användare..." data-tooltip-content="Användare" data-tooltip-id="root-tooltip" data-tooltip-place="left" value={guest} onChange={this.filterGuest} />
          </div>
          <div>
            <Label>Filtrera på extern rekryterare</Label>
            <UserPicker
              key={randomKey}
              data-tooltip-place="left"
              data-tooltip-content="Välj extern rekryterare."
              data-tooltip-id="root-tooltip"
              className="mb"
              users={jobbetUsers}
              placeholder="Välj extern rekryterare..."
              selected={external}
              onSelected={this.filterExternal}
              selectedText="person(er) vald(a)"
              noSelected="Inga personer valda"
              noUsers="Inga valbara personer"
            />
          </div>
          {user.superAdmin && isJobbet && (
            <div>
              <Label>Filtrera på kontaktperson</Label>
              <UserPicker
                key={randomKey}
                data-tooltip-place="left"
                data-tooltip-content="Välj kontaktperson."
                data-tooltip-id="root-tooltip"
                className="mb"
                users={jobbetUsers}
                placeholder="Välj kontaktperson..."
                selected={contacts}
                onSelected={this.filterContacts}
                selectedText="person(er) vald(a)"
                noSelected="Inga personer valda"
                noUsers="Inga valbara personer"
              />
            </div>
          )}
          <div>
            <Label>Filtrera på bransch</Label>
            <MultiObjectPicker
              key={randomKey}
              form={"true"}
              placeholder="Välj branscher..."
              data-tooltip-content="Välj branscher..."
              data-tooltip-id="root-tooltip"
              onSelected={this.filterAreas}
              noSelected="Inga branscher valda"
              noObjects="Inga valbara branscher"
              objects={occupationalAreas}
              selected={selectedOccupationalAreas}
              selectedTitle="Valda branscher"
              selectedText="bransch(er) vald(a)"
            />
          </div>
          <div>
            <Label>Filtrera på region</Label>
            <MultiObjectPicker
              key={randomKey}
              form={"true"}
              placeholder="Välj regioner..."
              data-tooltip-content="Välj regioner..."
              data-tooltip-id="root-tooltip"
              onSelected={this.filterRegions}
              noSelected="Ingen region vald"
              objects={regions}
              selected={selectedRegions}
              selectedTitle="Valda regioner"
              selectedText="region(er) vald(a)"
            />
          </div>
          <div>
            <Label>Filtrera på processteg</Label>
            <MultiObjectPicker
              key={randomKey}
              form={"true"}
              placeholder="Processteg..."
              data-tooltip-content="Välj processteg..."
              data-tooltip-id="root-tooltip"
              onSelected={this.filterProcess}
              noSelected="Inga processteg valda"
              noObjects="Inga valbara processteg"
              objects={Filter.process}
              selected={process}
              selectedTitle="Valda processteg"
              selectedText="processteg vald(a)"
            />
          </div>
          <div>
            <Label>Filtrera på bokade tjänster</Label>
            <MultiObjectPicker
              key={randomKey}
              form={"true"}
              placeholder="Välj tjänster..."
              data-tooltip-content="Välj tjänster..."
              data-tooltip-id="root-tooltip"
              onSelected={this.filterAddons}
              noSelected="Inga tjänster valda"
              noObjects="Inga valbara tjänster"
              objects={Filter.addons}
              selected={addons}
              selectedTitle="Valda tjänster"
              selectedText="tjänster vald(a)"
            />
          </div>
          <Date>
            <span>Startdatum från:</span>
            <DatePicker
              key={randomKey}
              isClearable={true}
              selected={applyStartAfter && parseISO(applyStartAfter)}
              maxDate={applyStartBefore && parseISO(applyStartBefore)}
              onChange={this.filterAfterStartDate}
              shouldCloseOnSelect={true}
              popperPlacement="top-end"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "65px, 0px",
                },
              }}
              locale="sv"
              dateFormat="yy-MM-dd"
              showWeekNumbers
              showYearDropdown
            />
          </Date>
          <Date>
            <span>Startdatum till:</span>
            <DatePicker
              key={randomKey}
              isClearable={true}
              selected={applyStartBefore && parseISO(applyStartBefore)}
              minDate={applyStartAfter && parseISO(applyStartAfter)}
              onChange={this.filterBeforeStartDate}
              popperPlacement="top-end"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "65px, 0px",
                },
              }}
              locale="sv"
              dateFormat="yy-MM-dd"
              showWeekNumbers
              showYearDropdown
            />
          </Date>
          <hr />
          <Date>
            <span>Slutdatum från:</span>
            <DatePicker
              key={randomKey}
              isClearable={true}
              selected={applyStopAfter && parseISO(applyStopAfter)}
              maxDate={applyStopBefore && parseISO(applyStopBefore)}
              onChange={this.filterAfterStopDate}
              popperPlacement="top-end"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "65px, 0px",
                },
              }}
              locale="sv"
              dateFormat="yy-MM-dd"
              showWeekNumbers
              showYearDropdown
            />
          </Date>
          <Date>
            <span>Slutdatum till:</span>
            <DatePicker
              key={randomKey}
              isClearable={true}
              selected={applyStopBefore && parseISO(applyStopBefore)}
              minDate={applyStopAfter && parseISO(applyStopAfter)}
              onChange={this.filterBeforeStopDate}
              popperPlacement="top-end"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "65px, 0px",
                },
              }}
              locale="sv"
              dateFormat="yy-MM-dd"
              showWeekNumbers
              showYearDropdown
            />
          </Date>
          <div>
            <CancelButton className="mt2" onClick={this.clearFilter} disabled={!this.state.stored}>
              Ta bort sparat filter
            </CancelButton>
            <br />
            <br />
            <SecondaryButton className="mt1" onClick={this.loadFilter} disabled={!this.state.stored}>
              Återgå till sparat filter
            </SecondaryButton>
          </div>
        </Content>
      </SideBar>
    )
  }
}

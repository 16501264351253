import React from "react"
import styled from "styled-components"
import { ProcessSubType } from "_root/constants"
import { MiniButton } from "_layout/buttons"

const Toggle = styled(MiniButton)`
  height: 24px;
  margin-left: 10px;
`

export default class SubtypeToggle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subType: this.props.step && this.props.step.subType,
    }
  }

  onToggle = e => {
    const subType = this.state.subType === ProcessSubType.PHONE ? ProcessSubType.PERSONAL : ProcessSubType.PHONE
    this.setState({ subType })
    this.props.onToggle && this.props.onToggle(this.props.step.type, subType)
  }

  render() {
    const { subType } = this.state
    const text = subType === ProcessSubType.PHONE ? "Telefon" : "Personlig"

    return <Toggle onClick={this.onToggle}>{text}</Toggle>
  }
}

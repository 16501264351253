import React from 'react'
import { isEmpty, isEqual } from 'lodash/fp'
import draftToHtml from 'draftjs-to-html';
import confirm from '_components/confirm'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import { PrimaryButton, CancelButton } from '_layout/buttons'

export default class CopyTemplates extends React.Component {
    constructor(props) {
      super(props)
      const selected = props.templates && props.templates.length ? {...props.templates[0]} : null
      if (selected) selected.contentHTML = selected.contentHTML || CopyTemplates.getHtmlContent(selected.content)
      this.state = {
        name: selected ? selected.name : '',
        content: selected.content,
        contentHTML: selected && selected.contentHTML,
        selected: selected,
        changed: false,
        company: props.company,
        templates: props.templates || [],
        shared: false
      }
    }

    static getHtmlContent = content => {
      return draftToHtml(content)
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
      const nextState = {}
      if (!isEqual(prevState.templates)(nextProps.templates)) {
        nextState.templates = nextProps.templates
        nextState.selected = nextProps.templates && nextProps.templates[0]
      }
      if (nextProps.close !== prevState.close) {
        nextState.close = nextProps.close
      }
      return isEmpty(nextState) ? null : nextState
    }

    componentDidUpdate = () => {
      if (this.state.close) {
        this.onClose()
      }
    }

    onNameChange = e => {
      this.setState({ name: e.target.value, changed: true })
    }

    onContentChange = content => {
      this.setState({ contentHTML: content, changed: true })
    }

    onSelected = template => {
      if (template) {
        this.setState({name: template.name || '', contentHTML: template.contentHTML || CopyTemplates.getHtmlContent(template.content), selected: template, changed: false })
      }
      else {
        this.setState({name: '', contentHTML: null, selected: null, changed: false})
      }
    }

    onCopy = e => {
      const { selected, name, content, contentHTML } = this.state
      if (!selected || !this.props.onCopy) return
      this.props.onCopy({...selected, name: name, content: content, contentHTML: contentHTML || CopyTemplates.getHtmlContent(selected.content)})
    }

    onClose = async e => {
      if (this.state.changed && await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
        this.onSave()
      }
      this.setState({name: '', contentHTML: null, selected: null, changed: false})
      this.props.onClose && this.props.onClose()
    }

    render() {
        const { name, contentHTML, selected, templates, company } = this.state

        return (
          <div>
            <h3>{this.props.title}</h3>
            <ObjectPicker
              form={'true'}
              className='mb3'
              values={templates}
              placeholder='Välj mall...' 
              current={selected}
              onSelected={this.onSelected}
              noMatch='Inga träffar' />
            <input
              autoFocus
              type='text'
              className='mb3 w-100'
              placeholder={'Spara mall som...'}
              value={name}
              onChange={this.onNameChange} />
            <TinyEditor
              key={selected && selected.id}
              form
              placeholder='Fyll i din mall här...'
              insert='h3 bold italic underline | bullist numlist | link table'
              selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
              plugins='autolink link lists image table'
              content={contentHTML}
              onChange={this.onContentChange}
              onDirty={this.props.onDirty}
              style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(90vh - 280px)', minHeight: 300 }} />
            <div className="tr">
              <CancelButton className='mr3' onClick={this.onClose}>Stäng</CancelButton>
              <PrimaryButton onClick={this.onCopy} disabled={!name || !contentHTML}>Kopiera till {company.name}</PrimaryButton>
            </div>
          </div>
      )
    }
}
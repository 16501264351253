import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import queryString from 'query-string'

import {
    SOK,
    INSTALLNINGAR,
    INST_USERS
} from '_routes/url-names'
import { ResultList, ResultItem, SimpleShowMoreLink } from '_modules/search/common-layout/result-lists'

const SideLink = styled.a`
    margin-left: auto;
    white-space: nowrap;
`

const SimpleUsersResults = props => {
    const { user, queryInput: query, employees, total } = props;
    const { match: { params: routeParams } } = props;

    const linkParams = {
        pathname: `/${routeParams.company}${SOK}`,
        search: queryString.stringify({ category: "users", query })
    }
    const showMoreLink = <SimpleShowMoreLink to={linkParams}>Visa fler ({ total - employees.length }) &gt;</SimpleShowMoreLink>

    return (
        <div>
            <ResultList>
            {employees.map(employee =>
                <ResultItem key={employee.id}>
                    <Link to={{ pathname: `/${routeParams.company}${INSTALLNINGAR}${INST_USERS}/${employee.id}` }} onClick={props.onClose}>{ `${employee.firstName} ${employee.lastName}` }</Link>
                    {!employee.active && <SideLink>Ej aktiverad</SideLink>}
                    {user.superAdmin && employee.active && employee.employer && <SideLink target='_blank' rel='opener' href={'/'+employee.employer.urlName}>{employee.employer.name}</SideLink>}
                </ResultItem>
            )}
            </ResultList>
            { (total > employees.length) && showMoreLink }
        </div>
    )
}

export default withRouter(SimpleUsersResults)

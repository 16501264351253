import React from "react"
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import styled from "styled-components"

import { map, filter, find } from "lodash/fp"

import { Checkbox } from "_layout/form-elements"
import SubtypeToggle from "_components/applications/subtype-toggle"

import to from "_services/await.to"

import { SmallSpinner } from "_layout/form-elements"
import { ProcessType, Process, ProcessTitlesList } from "_root/constants"

//#region Styles
const List = styled.ul`
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;

    li {
        position: relative;
        padding-bottom: 1rem;

        label {
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 0;
                border-right: 1px solid var(--color-bg-light);
                z-index: -1;
            }
        }

        &:last-child label:after {
            display: none;
        }
    }
`
const CheckboxArea = styled.div`
    display: flex;
    align-items: flex-start;
    > .picker {
        width: 26px;
        .spinner-container {
            justify-content: left;
        }
    }
`
const SubType = styled.div`
    margin-top: 5px;
    padding-left: 30px;
    min-height: 26px;

    .spinner {
        left: calc(50% - 8px);
    }

    button {
        margin-left: 0;
    }
`
//#endregion

const ProcessState = {
    SAVING_ACTIVE: "SAVING_ACTIVE",
    SAVING_INACTIVE: "SAVING_INACTIVE",
    ACTIVE: Process.ACTIVE,
    INACTIVE: Process.INACTIVE
}

@withApollo
export default class ProcessList extends React.Component {
    state = {}

    static getDerivedStateFromProps = (nextProps, prevState) => {
      let process
      if (nextProps.application) {
        const origin = nextProps.application.recruitment.process
        process = filter(s => s.order > 0 && !origin.find(o => o.type === s.type && o.state === Process.INVALID))(nextProps.application.process)
      }
      return { process }
    }

    componentDidMount = () => {
        this.mounted = true
    }

    componentWillUnmount = () => {
        this.mounted = false
    }

    toggleStatus = async step => {
        const savingState =
            step.state === ProcessState.INACTIVE
                ? ProcessState.SAVING_ACTIVE
                : ProcessState.SAVING_INACTIVE
        const process = map(s => {
            const newStep = { ...s }
            newStep.state = newStep.id === step.id ? savingState : newStep.state
            return newStep
        })(this.state.process)
        this.setState({ process })
        const recruitmentStep = find(s => s.type === step.type)(
          this.props.application.recruitment.process
        )
        let parentState = Process.ACTIVE
        if (step.state === Process.ACTIVE) {
            const steps = map(a => find(s => s.type === step.type)(a.process))(
                this.props.applications
            )
            const active = filter(s => s.state === Process.ACTIVE)(steps)
            if (active.length <= 1) parentState = Process.INACTIVE
        }
        let state
        switch (savingState) {
            case ProcessState.SAVING_ACTIVE:
                state = ProcessState.ACTIVE
                break
            case ProcessState.SAVING_INACTIVE:
                state = ProcessState.INACTIVE
                break
            default:
                break
        }
        const mutation = gql`mutation UpdateProcessStep {
          ${step.id}: updateProcessStep(where: { id: "${step.id}" }, data: { state: { set: ${state} } }) {
            id
          }
          ${recruitmentStep.id}: updateProcessStep(where: { id: "${recruitmentStep.id}" }, data: { state: { set: ${parentState} } }) {
            id
          }
        }`
        const [error, result] = await to(this.props.client.mutate({ mutation }))
        if (error || !result) {
            console.error("recruitment-application:toggleStatus:error", error)
        } else {
            const newProcess = map(s => {
                const newStep = { ...s }
                newStep.state = newStep.id === step.id ? state : newStep.state
                return newStep
            })(this.state.process)
            this.setState({ process: newProcess })
        }
    }

    onStatusSubtype = async (type, subType) => {
        const step = find(s => s.type === type)(this.state.process)
        if (!step) return
        const process = map(s => {
          const subTypeSaving = s.id === step.id
          return { ...s, subTypeSaving }
        })(this.state.process)
        this.setState({ process })
        const mutation = gql`mutation UpdateProcessStep {
          updateProcessStep(where: { id: "${step.id}" }, data: { subType: { set: ${subType} } }) {
            id
          }
        }`
        const [error, result] = await to(this.props.client.mutate({ mutation }))
        if (error || !result) {
          console.error(
            "recruitment-application:onStatusSubtype:error",
            error
          )
        } else {
          const newProcess = map(s => {
            const newStep = { ...s, subType }
            delete newStep.subTypeSaving
            return newStep
          })(this.state.process)
          this.setState({ process: newProcess })
        }
    }

    processSubType = step => {
        if (
            step.type === ProcessType.INTERVIEW1 ||
            step.type === ProcessType.INTERVIEW2
        ) {
            return (
                <SubType>
                    {step.subTypeSaving ? (
                        <SmallSpinner />
                    ) : (
                        <SubtypeToggle
                            step={step}
                            data-tooltip-content="Klicka för att ändra intervjutyp"
                            data-tooltip-id="root-tooltip"
                            onToggle={this.onStatusSubtype}
                        />
                    )}
                </SubType>
            )
        }
        return null
    }

    render() {
        const { process } = this.state
        const processSavingStates = [
            ProcessState.SAVING_ACTIVE,
            ProcessState.SAVING_INACTIVE
        ]
        return (
            <List>
                {map(step => (
                    <li key={step.id}>
                        <CheckboxArea>
                            <div className="picker">
                                {processSavingStates.includes(step.state) ? (
                                    <SmallSpinner />
                                ) : (
                                    <Checkbox
                                        checked={step.state === Process.ACTIVE}
                                        onChange={e => this.toggleStatus(step)}
                                    />
                                )}
                            </div>
                            <span>
                                {step.text || ProcessTitlesList[step.order]}
                            </span>
                        </CheckboxArea>
                        {this.processSubType(step)}
                    </li>
                ))(process)}
            </List>
        )
    }
}

import React from 'react'
import styled from 'styled-components'

import { REKRYTERING, ANSOKNINGAR } from '_routes/url-names'
import { SimpleRoutedLink } from '_modules/search/common-layout/result-lists'

//#region Styles
const StyledApplicationList = styled.div`
    display: inline-block;
    border-radius: 4px;
    background-color: #ccc;
    padding: 3px 8px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    vertical-align: middle;

    & .applist__content {
        display: none;

        &.expanded {
            display: block;
        }
    }
`
const ToggleButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    outline: none;
`
const Content = styled.ul`
    list-style: none;
    padding: 10px;
`
const ApplicationItem = styled.li``
//#endregion Styles

export default class ApplicationList extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: true
        }
    }
    
    displayName = "CandidateApplicationList"

    toggleCollapsedState = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    getToggleButtonText = () => {
        const { collapsed } = this.state;
        const { applications } = this.props;

        if ( collapsed ) {
            return `Visa ${applications.length} tjänster +`
        } else {
            return `Dölj -`
        }
    }

    openRecruitment = (e, companyUrlName, recruitmentId) => {
        e.preventDefault()
        window.open(`/${companyUrlName}${REKRYTERING}/aktiva/${recruitmentId}`, recruitmentId, 'width=1200,height=800,location=no')
        return false
    }

    render() {
        const { className, applications } = this.props;

        return (
            <StyledApplicationList className={className}>
                <ToggleButton onClick={this.toggleCollapsedState}>{this.getToggleButtonText()}</ToggleButton>
                <Content className={ `applist__content ${!this.state.collapsed ? 'expanded' : ''}` }>
                    {
                      applications.map((application) => {
                        const { recruitment, recruitment: { company } } = application;
                        const recruitmentUrl = `/${company.urlName}${REKRYTERING}/aktiva/${recruitment.id}${ANSOKNINGAR}`
                        return (
                            <ApplicationItem key={application.id}>
                                <SimpleRoutedLink to={recruitmentUrl} target='_blank' rel='opener'>
                                    { `${application.recruitment.title}, ${application.recruitment.company.name}` }
                                </SimpleRoutedLink>
                            </ApplicationItem>
                        )
                      })
                    }
                </Content>
            </StyledApplicationList>
        )
    }
}
import React from 'react'
import keydown from 'react-keydown'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import differenceBy from 'lodash/fp/differenceBy'
import sortBy from 'lodash/fp/sortBy'
import onClickOutside from 'react-onclickoutside'
import { getHidingParent } from '_services/util'
import { Checkbox } from '_layout/form-elements'
import Falafel from '_images/menu-dots.svg'
const remove = require('lodash/fp/remove').convert({ immutable: false })

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  box-shadow: 0px 2px 0px var(--color-line);
  width: ${props => props.$width ? props.$width : 'min(300px, 100%)'};

  input[type=text] {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Dropdown = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  left: 0;
  min-width: min(300px, 100%);
  background: var(--color-bg-white);
  border: 1px solid var(--color-line-dark);
  box-shadow: 2px 2px 10px var(--color-line-dark);
  z-index: 10000;

  &:focus {
    outline: none;
  }
`
const UserList = styled.ul`
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style-type: none;
`
const ListItem = styled.li`
    color: var(--color-text-dark);

    > label {
        cursor: pointer;
        position: relative;
        display: block;
        padding: 10px 10px 10px 36px;

        > label {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }

    &.no-users {
        padding: 10px;
    }

    &:nth-child(even) {
        background: var(--color-bg-lightest);
    }

    &:hover {
        color: var(--color-text-white);
        background: var(--color-nav-1);
    }
`
const Title = styled.h4`
    flex: none;
    margin: 0;
    padding: 5px 10px;
    font-weight: 400;
    color: var(--color-text-white);
    background: var(--color-nav-2);
`
const Input = styled.input`
    cursor: pointer;
    width: 100%;
    background: url(${Falafel}) scroll 100% 50% no-repeat var(--color-bg-white);
    background-size: 24px 16px;

    &[type=text] {
        padding-right: 10%;
    }

    &::placeholder, ::placeholder {
        color: var(--color-text-dark);
    }

    &:focus {
        cursor: text;
    }

    &[disabled] {
        background: var(--color-bg-lightest);
    }
`
//#endregion

@keydown
@onClickOutside
export default class UserPicker extends React.Component {
    constructor(props) {
      super(props)
      this.selectedText = props.selectedText || 'person(er) vald(a)'
      this.noUsers = this.props.noUsers || 'Inga valbara personer'
      this.noSelected = this.props.noSelected || 'Inga personer valda'
      this.textInput = React.createRef()

      this.state = {
        showList: false,
        filterText: '',
        readOnly: !!props.readOnly,
        selectable: sortBy(['firstName'])(differenceBy('id')(props.users)(props.selected)),
        selected: sortBy(['firstName'])(props.selected) || [],
        placeholderDefault: props.placeholder || 'Välj personer...'
      }
    }

    static getDerivedStateFromProps({ keydown, count, selected, selectedText, users }, { showList, placeholderDefault }) {
      const nextState = {}
      if (showList && keydown && keydown.event && (keydown.event.which === 27 || keydown.event.which === 13)) {
        nextState.showList = false
        nextState.filterText = ''
      }
      nextState.selected = selected
      nextState.selectable = sortBy(['firstName'])(differenceBy('id')(users)(selected))
      nextState.placeholder = selected && selected.length
        ? count ? `${selected.length} ${selectedText}` : map(s => `${s.firstName} ${s.lastName}`)(selected).join(', ')
        : placeholderDefault
      return nextState
    }

    getPlaceholder = selected => {
      return selected && selected.length
        ? this.props.count ? `${selected.length} ${this.selectedText}` : map(s => `${s.firstName} ${s.lastName}`)(selected).join(', ')
        : this.state.placeholder
    }

    closeList = () => {
      if (!this.state.showList) return
      this.setState({ showList: false, filterText: '' })
    }

    handleClickOutside = e => {
      this.closeList()
    }

    onKeyDown = e => {
      const key = `${e.keyCode || e.charCode}`
      if (key === '27' || key === '13' || key === '10') {
        this.closeList()
        e.target.blur()
      }
    }

    userInput = e => {
      if (this.state.readOnly) return
      const text = e.target.value && e.target.value.trim().toLowerCase()
      this.setState({filterText: text})
    }

    inputFocus = e => {
        if (this.textInput.current) {
            const hidingParent = getHidingParent(this.textInput.current)
            const docHeight = hidingParent ? hidingParent.offsetHeight : document.body.offsetHeight
            const inputRect = this.textInput.current.getBoundingClientRect()
            const topOffset = inputRect.top - (hidingParent ? hidingParent.getBoundingClientRect().top : 0)
            const showListAbove = topOffset > docHeight / 2
            const listMaxHeight = (showListAbove ? topOffset : docHeight - topOffset - inputRect.height) - 10
            const dropStyle = showListAbove 
                ? { maxHeight: listMaxHeight, bottom: '100%' } 
                : { maxHeight: listMaxHeight, top: '100%' }
            const sublistMaxHeight = (listMaxHeight / 2) - 28
            this.setState({ showList: true, dropStyle: dropStyle, sublistStyle: { maxHeight: sublistMaxHeight }})
        }
    }

    dropdownBlur = e => {
        if (!!e.relatedTarget && (e.relatedTarget.className.indexOf('user-picker-dropdown') < 0)) {
            this.setState({ showList: false })
        }
    }

    selectUser = e => {
        const picked = remove(user => {
            return user.id.toString() === e.target.value
        })([...this.state.selectable])
        if (picked) {
            const selected = sortBy(['firstName'])([...picked, ...this.state.selected])
            this.setState({ selected: selected, placeholder: this.getPlaceholder(selected) })
            this.props.onSelected && this.props.onSelected(selected)
        }
    }

    removeUser = e => {
        const selected = [...this.state.selected]
        const picked = remove(user => {
            return user.id.toString() === e.target.value
        })(selected)
        if (picked) {
            this.setState({ selected: selected, placeholder: this.getPlaceholder(selected) })
            this.props.onSelected && this.props.onSelected(selected)
        }
    }

    render() {

        let userList = null
        let selectedList = null

        const { showList, dropStyle, sublistStyle, filterText, selectable, selected, placeholder, readOnly } = this.state

        if (showList) {
          const filtered = filterText
            ? filter(user => { return (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(filterText) > -1 })(selectable)
            : [...selectable]
          if (filtered && filtered.length) {
            userList = map(user =>
              <ListItem key={user.id} title='Klicka för att välja person'>
                <label>
                  <Checkbox tabIndex='0' className='user-picker-dropdown-input' value={user.id} onChange={this.selectUser} />
                  <span>{user.firstName + ' ' + user.lastName}</span>
                </label>
              </ListItem>)(filtered)
          }
          else {
            userList = <ListItem key='0' className='no-users'>{this.noUsers}</ListItem>
          }
          if (selected && selected.length) {
            selectedList = map(user =>
              <ListItem key={user.id} title='Klicka för att återställa vald person'>
                <label>
                  <Checkbox tabIndex='0' className='user-picker-dropdown-input' value={user.id} onChange={this.removeUser} disabled={readOnly} checked={true} />
                  <span>{user.firstName + ' ' + user.lastName}</span>
                </label>
              </ListItem>)(selected)
          }
          else {
            selectedList = <ListItem key='0' className='no-users'>{this.noSelected}</ListItem>
          }
        }

        return (
          <Content $width={this.props.width} className={this.props.className || ''}>
            <Input type='text'
              placeholder={placeholder || ''}
              ref={this.textInput}
              value={filterText}
              onKeyDown={this.onKeyDown}
              onChange={this.userInput}
              onFocus={this.inputFocus}
            />
            {showList &&
            <Dropdown
              tabIndex='1'
              className={'user-picker-dropdown'}
              style={dropStyle}
              onBlur={this.dropdownBlur}>
              {selectedList &&
              <Title>Valda personer</Title>}
              {selectedList &&
              <UserList style={sublistStyle}>
                {selectedList}
              </UserList>}
              {readOnly || 
              <Title>Välj personer...</Title>}
              {readOnly || 
              <UserList style={sublistStyle}>
                {userList}
              </UserList>}
            </Dropdown>
            }
          </Content>
        )
    }
}

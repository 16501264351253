import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

const AUDITLOGS_QUERY = gql`
query ($entityName: String!, $entityId: String!, $correlationId: String) {
    logEntries: getAuditLogsByEntityNameId(
        entityName: $entityName
        entityId: $entityId
        correlationId: $correlationId
    ) {
        id
        createdAt
        createdByUser {
            firstName
            lastName
            role
        }
        kind
        message
        messageData
        propertyName
        propertyValueFrom
        propertyValueTo
    }
}
`

const withAuditLogs = graphql(
    AUDITLOGS_QUERY,
    {
        name: 'auditLogs',
        options: props => ({
            variables: {
                entityName: props.entityName,
                entityId: props.entityId,
                correlationId: props.correlationId
            },
            fetchPolicy: 'network-only'
        })
    }
)

export default withAuditLogs
import React from "react"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { isEmpty, map } from "lodash/fp"
import { Label, Input, UserPicker, Checkbox, FilePicker, Spinner } from "_layout/form-elements"
import MultiObjectPickerBase from "_components/multi-object-picker"
import ObjectPickerBase from "_components/object-picker"
import RecruitAddons from "_components/recruit/recruit-addons"
import UploadService from "_services/upload-service"
import TemplateEmptySrc from "_images/template-empty.svg"
import TrashIcon from "_images/trash.svg"
import { DELETE_FILE } from "_containers/recruitment/recruitment-docs-ql"
import { ADMIN_COMPANY, UserRoles, FileType } from "_root/constants"
import { GET_OCCUPATIONAL_AREAS, GET_OCCUPATIONAL_GROUPS, GET_REGIONS } from "_root/common-ql"
import { JOBBET_USERS_QUERY, GROUP_EMPLOYEE_QUERY, EMPLOYEE_QUERY, ALL_COMPANIES, ALL_ADDONS } from "_containers/recruitment/recruitment-ql"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const AdTitle = styled(Label)`
  font-size: 24px;
  line-height: 32px;
  margin: 10px 0;
  color: var(--color-brand-green-dark);
`
const AdInput = styled(Input)`
  && {
    border: 2px solid var(--color-brand-green-dark);
    background: var(--color-brand-green-light);
    padding: 15px 10px;
  }
`
const ObjectPicker = styled(ObjectPickerBase)`
  margin-bottom: 20px;
`
const MultiObjectPicker = styled(MultiObjectPickerBase)`
  margin-bottom: 20px;
`
const Error = styled.div`
  margin-top: -0.5em;
  margin-bottom: 20px;
  color: var(--color-error);
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: 960px) {
    flex-flow: row nowrap;
    justify-content: center;

    & > div:last-child {
      margin-left: 60px;
      padding-top: 30px;
    }
  }

  @media screen and (min-width: 1500px) {
    flex-flow: column nowrap;
    justify-content: flex-start;

    & > div:last-child {
      margin-left: 0;
      padding-top: 0;
    }
  }
`
const Relative = styled.div`
  position: relative;
`
const TipBox = styled.div`
  position: absolute;
  top: 20px;
  left: 320px;
  width: 420px;
  padding: 24px;
  background: var(--color-brand-green-light);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
  opacity: 0;
  transition: opacity 1s;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 2000;
  }

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--color-brand-green-light);
  }

  header {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 8px;
    color: var(--color-brand-green-dark);
  }

  p {
    strong {
      font-weight: 700;
      color: var(--color-brand-green);
    }
  }
`
const FileArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    text-decoration: none;

    img {
      width: 50px;
      height: 64px;
      margin-bottom: 1rem;
    }

    span {
      text-align: center;
    }
  }
`
const Trash = styled.a`
  display: inline-block;
  margin: 0 0 0 20px;
  padding: 36px 0 0 0;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 20px 20px;
  vertical-align: bottom;

  &:hover {
    opacity: 1;
  }
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(JOBBET_USERS_QUERY, {
    name: "jobbetUsersQuery",
    options: () => ({ fetchPolicy: "network-only" }),
  }),
  graphql(ALL_COMPANIES, {
    name: "companiesQuery",
    options: () => ({ fetchPolicy: "network-only" }),
  }),
  graphql(EMPLOYEE_QUERY, {
    name: "employeeQuery",
    options: props => ({
      variables: { urlName: props.match.params.company },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(GROUP_EMPLOYEE_QUERY, {
    name: "groupQuery",
    options: props => ({
      variables: { groups: props.groups },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "occupationalAreasQuery" }),
  graphql(GET_OCCUPATIONAL_GROUPS, { name: "occupationalGroupsQuery" }),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(ALL_ADDONS, { name: "addonsQuery" })
)
export default class RecruitBase extends React.Component {
  constructor(props) {
    super(props)
    const { recruitment } = this.props
    this.state = {
      showTip: false,
      blocked: recruitment?.blocked || [],
      recruiter: recruitment?.recruiter
        ? {
            ...recruitment.recruiter,
            name: `${recruitment.recruiter.firstName} ${recruitment.recruiter.lastName}`,
          }
        : null,
      recruiterGuests: recruitment?.recruiterGuests || [],
      recruiterExternals: recruitment?.recruiterExternals || [],
      company: recruitment?.company || props.company,
      groups: recruitment?.company?.groups || props.groups || [],
      title: recruitment?.title || "",
      interest: recruitment?.interest || false,
      occupationalAreas: recruitment?.occupationalAreas || [],
      occupationalGroups: recruitment?.occupationalGroups || [],
      regions: recruitment?.regions || [],
      employees: [],
      groupEmployees: [],
      selectedEmployees: [],
      jobbetUsers: [],
      companies: [],
      allOccupationalAreas: [],
      allOccupationalGroups: [],
      filteredOccupationalGroups: [],
      allRegions: [],
      addons: [],
      selectedAddons: recruitment?.addons || [],
      profileFile: recruitment?.profileFile,
    }

    this.isAdminCompany = props.match.params.company === ADMIN_COMPANY || props.user.companyView.group
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (nextProps.employeeQuery.users && nextProps.employeeQuery.users.length && !prevState.employees.length) {
      nextState.employees = map(e => ({ ...e, name: e.fullName }))(nextProps.employeeQuery.users)
    }
    if (nextProps.groupQuery?.users && nextProps.groupQuery.users.length && !prevState.groupEmployees.length) {
      nextState.groupEmployees = map(e => ({ ...e, name: e.fullName }))(nextProps.groupQuery.users)
    }
    if (nextProps.jobbetUsersQuery.users && nextProps.jobbetUsersQuery.users.length !== prevState.jobbetUsers.length) {
      nextState.jobbetUsers = map(e => ({ ...e, name: e.fullName }))(nextProps.jobbetUsersQuery.users)
    }
    if (nextProps.user.companyView.group) {
      nextState.companies = nextProps.user.companyView.groupies
    } else if (!prevState.companies.length && nextProps.companiesQuery.companies && nextProps.companiesQuery.companies.length) {
      nextState.companies = [...nextProps.companiesQuery.companies]
    }
    if (!prevState.allRegions.length && nextProps.regionsQuery.regions && nextProps.regionsQuery.regions.length) {
      nextState.allRegions = [...nextProps.regionsQuery.regions]
    }
    if (!prevState.allOccupationalAreas.length && nextProps.occupationalAreasQuery.occupationalAreas && nextProps.occupationalAreasQuery.occupationalAreas.length) {
      nextState.allOccupationalAreas = [...nextProps.occupationalAreasQuery.occupationalAreas]
    }
    if (!prevState.allOccupationalGroups.length && nextProps.occupationalGroupsQuery.occupationalGroups && nextProps.occupationalGroupsQuery.occupationalGroups.length) {
      nextState.allOccupationalGroups = [...nextProps.occupationalGroupsQuery.occupationalGroups]
      let groups = []
      prevState.occupationalAreas && prevState.occupationalAreas.forEach(area => {
        const matching = nextState.allOccupationalGroups.filter(group => group.occupationalArea.id === area.id)
        groups = groups.concat(matching)
      })
      nextState.filteredOccupationalGroups = groups
    }
    if (nextProps.regions && nextProps.regions.length !== prevState.regions.length) {
      nextState.regions = [...nextProps.regions]
    }
    if (nextProps.interest && !prevState.interest) {
      nextState.interest = true
    }
    if (nextProps.blocked && nextProps.blocked.length !== prevState.blocked.length) {
      nextState.blocked = [...nextProps.blocked]
    }
    if (nextProps.recruiterGuests && nextProps.recruiterGuests.length !== prevState.recruiterGuests.length) {
      nextState.recruiterGuests = [...nextProps.recruiterGuests]
    }
    if (nextProps.recruiter) {
      nextState.recruiter = {
        ...nextProps.recruiter,
        name: `${nextProps.recruiter.firstName} ${nextProps.recruiter.lastName}`,
      }
    }
    if (!prevState.addons.length && nextProps.addonsQuery.addons?.length) {
      nextState.addons = [...nextProps.addonsQuery.addons]
    }
    if (nextProps.addons && !nextProps.addons.equals(prevState.selectedAddons)) {
      nextState.selectedAddons = nextProps.addons
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidMount = () => {
    if (this.props.create) return
    const invalid = this.validate(this.state)
    if (invalid) {
      this.setState({ invalid })
    }
  }

  validate = state => {
    const invalid = {}
    const { company, title, recruiter, occupationalAreas, regions } = state
    invalid.company = !company || company.group
    invalid.title = !title
    invalid.recruiter = !recruiter
    invalid.occupationalAreas = !occupationalAreas || !occupationalAreas.length
    invalid.regions = !regions || !regions.length
    return invalid.company || invalid.title || invalid.recruiter || invalid.occupationalAreas || invalid.regions ? invalid : null
  }

  getEmployees = async company => {
    const variables = { urlName: company.urlName }
    const [error, result] = await to(this.props.client.query({ query: EMPLOYEE_QUERY, variables: variables }))
    if (error || !result) {
      console.error("recruit-base:getEmployees:error", error, variables)
      return
    }
    const selectedEmployees = map(e => ({
      ...e,
      name: `${e.firstName} ${e.lastName}`,
    }))(result.data.users)
    this.save({
      ...this.state,
      company,
      selectedEmployees,
      recruiter: null,
      recruiterGuests: [],
      recruiterExternals: [],
      blocked: [],
    })
  }

  selectedCompany = company => {
    if (company && this.state.company && company.id !== this.state.company.id) {
      this.getEmployees(company)
    }
  }

  setAdTitle = title => {
    if (title === this.state.title) return
    this.setState({ title: title }, () => {
      this.props.onDirty && this.props.onDirty()
    })
  }

  selectedRecruiter = recruiter => {
    this.save({ recruiter })
  }

  selectedExternal = selected => {
    this.save({ recruiterExternals: [...selected] })
  }

  selectedAddon = selectedAddons => {
    this.save({ selectedAddons })
  }

  selectedGuest = selected => {
    this.save({ recruiterGuests: [...selected] })
  }

  selectedBlocked = selected => {
    this.save({ blocked: [...selected] })
  }

  onFileSelected = async file => {
    if (file) {
      const [error, { data }] = await to(UploadService.uploadFile(file))
      if (error || !data || !data.id) {
        console.error("reqruit-base:onFileSelected:uploadProfile:error", error || data)
        return
      }
      this.save({ profileFileId: data.id, profileFile: data })
    }
  }

  deleteProfileFile = async () => {
    const [error, result] = await to(
      this.props.client.mutate({
        mutation: DELETE_FILE,
        variables: { id: this.state.profileFile.id },
      })
    )
    if (error || !result) {
      console.error("reqruit-base:deleteProfileFile:error", error)
    }
    this.save({ profileFileId: null, profileFile: null })
  }

  toggleInterest = e => {
    this.save({ interest: e.target.checked })
  }

  onSelectedAreas = occupationalAreas => {
    if (!occupationalAreas) return
    const { allOccupationalGroups: allGroups } = this.state
    let groups = []
    occupationalAreas.forEach(area => {
      const matching = allGroups.filter(group => group.occupationalArea.id === area.id)
      groups = groups.concat(matching)
    })
    this.save({ occupationalAreas, filteredOccupationalGroups: groups })
  }

  onSelectedGroups = occupationalGroups => {
    if (!occupationalGroups) return
    this.save({ occupationalGroups: occupationalGroups })
  }

  onSelectedRegions = regions => {
    if (!regions || !regions.length) return
    this.save({ regions: regions })
  }

  save = state => {
    const newState = { ...this.state, ...state }
    const invalid = this.validate(newState)
    const saveState = {
      ...newState,
      invalid,
      showTip: false,
      title: newState.title.trim(),
    }
    this.setState({ ...saveState }, () => this.saveState(saveState))
  }

  saveState = saveState => {
    if (saveState.invalid) return
    this.props.onDirty && this.props.onDirty()
    this.props.onChange && this.props.onChange(saveState)
  }

  onFocus = () => {
    this.setState({ showTip: true })
  }

  render() {
    const { user, className } = this.props
    const { loading, error } = this.props.jobbetUsersQuery
    const { loading: loadingEmployees, error: errorEmployees } = this.props.employeeQuery
    const { loading: loadingGroups, error: errorGroups } = this.props.groupQuery
    const {
      showTip,
      invalid,
      recruiter,
      recruiterGuests,
      recruiterExternals,
      interest,
      title,
      blocked,
      jobbetUsers,
      employees,
      groupEmployees,
      selectedEmployees,
      allOccupationalAreas,
      allRegions,
      filteredOccupationalGroups,
      occupationalAreas,
      occupationalGroups,
      regions,
      companies,
      company,
      addons,
      selectedAddons,
      profileFile,
    } = this.state

    if (loading || error || loadingEmployees || errorEmployees || loadingGroups || errorGroups || !companies.length || !company || !allRegions || !allRegions.length) {
      return <Spinner />
    }

    const users = employees.concat(groupEmployees).concat(selectedEmployees)
    const admins = users.filter(u => u.role === UserRoles.ADMINISTRATOR || u.role === UserRoles.GROUPADMIN)
    const showExternal = user.superAdmin && company.urlName !== ADMIN_COMPANY
    const showCompany = this.isAdminCompany && companies.length > 0
    const tipClass = showTip ? "show" : ""
    const current = !user.companyView.group ? company : null

    return (
      <Wrapper className={className}>
        <div>
          <Relative>
            <TipBox className={tipClass}>
              <header>TIPS!</header>
              <p>
                Rubriken är väldigt viktig för att nå rätt målgrupp med er annons. <strong>Har ni tänkt rätt?</strong> Rätt rubrik ökar såväl antalet visningar som ansökningar.
              </p>
            </TipBox>
            <AdTitle>Annonsrubrik *</AdTitle>
            <AdInput type="text" placeholder="Ange rubrik..." value={title} onFocus={this.onFocus} onBlur={e => this.save(this.state)} onChange={e => this.setAdTitle(e.target.value)} />
            {invalid && invalid.title && <Error>Annonsrubrik kan inte vara tom</Error>}
          </Relative>
          {showExternal && (
            <>
              <Label>Rekryterare</Label>
              <UserPicker
                users={jobbetUsers}
                width="300px"
                placeholder="Välj kontakt på Jobbet.se..."
                selected={recruiterExternals}
                onSelected={this.selectedExternal}
                selectedText="person(er) vald(a)"
                noSelected="Inga personer valda"
                noUsers="Inga valbara personer"
              />
            </>
          )}
          {showCompany && <Label>Välj företag *</Label>}
          {showCompany && (
            <ObjectPicker
              form={"true"}
              required
              width="300px"
              values={companies}
              placeholder="Välj företag..."
              current={current}
              onSelected={this.selectedCompany}
              data-tooltip-content="Ange vilket företag som rekryteringen ska kopplas till"
              data-tooltip-id="root-tooltip"
              noMatch="Inga träffar"
            />
          )}
          {invalid && invalid.company && <Error>Ett företag måste väljas</Error>}
          <Label>Branscher *</Label>
          <MultiObjectPicker
            form={"true"}
            width="300px"
            placeholder="Välj branscher..."
            onSelected={this.onSelectedAreas}
            noSelected="Inga branscher valda"
            noObjects="Inga valbara branscher"
            objects={allOccupationalAreas}
            selected={occupationalAreas}
            selectedTitle="Valda branscher"
            selectedText="bransch(er) vald(a)"
          />
          {invalid && invalid.occupationalAreas && <Error>Minst en bransch måste väljas</Error>}
          <Label>Yrken</Label>
          <MultiObjectPicker
            width="300px"
            placeholder="Välj yrken..."
            onSelected={this.onSelectedGroups}
            noSelected="Inga yrken valda"
            noObjects="Välj minst 1 bransch ovan"
            objects={filteredOccupationalGroups}
            selected={occupationalGroups}
            selectedTitle="Valda yrken"
            selectedText="yrken valda"
          />
          <Label>Region *</Label>
          <MultiObjectPicker
            width="300px"
            placeholder="Välj regioner..."
            onSelected={this.onSelectedRegions}
            noSelected="Ingen region vald"
            objects={allRegions}
            selected={regions}
            selectedTitle="Valda regioner"
            selectedText="region(er) vald(a)"
          />
          {invalid && invalid.regions && <Error>Minst en region måste väljas {invalid.regions.length}</Error>}
        </div>
        <div>
          <Label>Rekryterande chef *</Label>
          <ObjectPicker required width="300px" values={users} placeholder="Välj person..." current={recruiter} onSelected={this.selectedRecruiter} noMatch="Inga träffar" />
          {invalid && invalid.recruiter && <Error>Rekryterande chef måste anges</Error>}
          <Label>Medverkande</Label>
          <UserPicker
            width="300px"
            data-tooltip-place="left"
            data-tooltip-content="Lägg till en användare som har rätt att se den enskilda rekryteringen."
            data-tooltip-id="root-tooltip"
            users={users}
            placeholder="Välj användare..."
            selected={recruiterGuests}
            onSelected={this.selectedGuest}
            selectedText="person(er) vald(a)"
            noSelected="Inga personer valda"
            noUsers="Inga valbara personer"
          />
          {user.superAdmin && showExternal && <RecruitAddons readOnly={false} addons={addons} selectedAddons={selectedAddons} onSelectedAddon={this.selectedAddon} />}
          {user.superAdmin && showExternal && (
            <React.Fragment>
              <Label>Blockerade</Label>
              <UserPicker
                width="300px"
                data-tooltip-place="left"
                data-tooltip-content="Välj användare som inte har rätt att se den enskilda rekryteringen."
                data-tooltip-id="root-tooltip"
                users={admins}
                placeholder="Välj användare..."
                selected={blocked}
                onSelected={this.selectedBlocked}
                selectedText="person(er) vald(a)"
                noSelected="Inga personer valda"
                noUsers="Inga valbara personer"
              />
            </React.Fragment>
          )}
          <div>
            <h4 className="mb2">Fil för kravprofil</h4>
            {profileFile && profileFile.url && (
              <FileArea>
                <a href={profileFile.url}>
                  <img src={TemplateEmptySrc} alt="Ikon för uppladdad kravprofil" title={profileFile.name} />
                  <span>{profileFile.name}</span>
                </a>
                {profileFile && <Trash onClick={this.deleteProfileFile} data-tooltip-content="Radera kravprofilen" data-tooltip-id="root-tooltip" />}
              </FileArea>
            )}
            <FilePicker fileId={"requirements-profile"} text={"Ladda upp fil…"} accept={[FileType.PDF, FileType.DOC, FileType.DOCX]} onFile={this.onFileSelected} />
          </div>
          <div className="mb4 mt4">
            <Checkbox checked={interest} onChange={this.toggleInterest} label="Spontanansökan" />
          </div>
        </div>
      </Wrapper>
    )
  }
}

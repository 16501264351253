import React from 'react'
import { connect } from 'react-redux'
import each from 'lodash/fp/each'

/**
 * @function inject
 * @param  {String} ...args List of state keys to map to the supplied component.
 * @return {object} New component that wraps the supplied Component.
 */
export default (...args) => {
    const mapStateToProps = state => {
        const props = {}
        each(arg => props[arg] = state[arg])(args)
        return props
    }

    return Wrapped => {
        return connect(mapStateToProps)(class extends React.Component {
            render() {
                return <Wrapped {...this.props} />
            }
        })
    }
}

import React from 'react'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'

export default class Login extends React.Component {
    render() {
        return (
            <MainLayout padding>
                <H2>Kundtjänst</H2>
                <FlexRow className='margin20'>
                    <FlexCol>
                        <p>Telefon: 018 - 100 112</p>
                        <p>E-post: <a href='mailto:kundtjanst@jobbet.se'>kundtjanst@jobbet.se</a></p>
                        <p>Adress: Fyristorg 6, 753 10 Uppsala</p>
                    </FlexCol>
                </FlexRow>
            </MainLayout>
        )
    }
}

import React from 'react'
import { Navigate } from 'react-router-dom'
import { LOGIN } from '_routes/url-names'
import inject from '_services/inject'
import AfterLogin from '_containers/after-login'

@inject('user')
export default class StartRoute extends React.Component {
    render() {
        const { user } = this.props
        if (user.loading) return null
        return user.loggedIn ? <AfterLogin /> : <Navigate to={LOGIN} />
    }
}

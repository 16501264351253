import React from 'react'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import withApolloQuery from '_modules/search/components/with-apollo-query'
import withUpdateApolloQuery from '_modules/search/components/with-update-apollo-query'

import inject from '_services/inject'
import groupsQueryProvider from './groups-ql'
import DetailedGroupResults from './detailed-results';
import { Spinner } from '_layout/form-elements';
import {
    ResultGroupHeader,
    GrowingResultWrapper,
    ArrowDown,
    ArrowUp
} from '_modules/search/common-layout/result-lists';

@compose(
    withRouter,
    inject('user'),
    withApolloQuery({
        options: props => ({
            notifyOnNetworkStatusChange: true,
            variables: {
                queryInput: props.queryInput,
                paging_limit: props.limit
            }
        })
    }, groupsQueryProvider),
    withUpdateApolloQuery()
)
export default class DetailedGroupResultGroup extends React.PureComponent {
    displayName = "GroupResultGroup"

    onFetchMoreResults() {
        const { results, fetchMore } = this.props.apollodata;

        fetchMore({
            variables: {
                paging_skip: results.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    results: [...prev.results, ...fetchMoreResult.results]
                });
            }
        });
    }

    render() {
        const { queryInput, isActive, onHeaderClick } = this.props;
        const { results, meta, loading, networkStatus } = this.props.apollodata;
        const metaCount = (meta && meta._count && meta._count._all) || 0

        const isInitialLoading = networkStatus === 1;

        const resultProps = {
            queryInput,
            results,
            meta,
            isFetchingMore: loading,
            onFetchMoreResults: this.onFetchMoreResults.bind(this)
        };

        const components = [
            <ResultGroupHeader key="groupheader" onClick={onHeaderClick} className={ isActive ? 'active' : ''}>
                Grupper 
                { !isInitialLoading && ((metaCount > 0) ? ` - ${metaCount} ${metaCount === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
                { isActive ? <ArrowDown/> : <ArrowUp/> }
            </ResultGroupHeader>,
            <GrowingResultWrapper key="groupresults" className={ isActive ? 'active' : ''}>
                { isInitialLoading ? <Spinner className="center" /> : <DetailedGroupResults { ...resultProps }  /> }
            </GrowingResultWrapper>
        ]

        return components
    }
}
import {
  MENU_CLOSE_MAIN,
  MENU_TOGGLE_MAIN,
  MENU_TOGGLE_FILTER,
  MENU_ACTIVE_COUNT,
  MENU_DRAFT_COUNT,
  MENU_CLOSED_COUNT
} from '_actions/menu-actions'

const initialState = () => ({
    mainMenuOpen: window.innerWidth > 767,
    filterMenuOpen: false
})

export default (state = initialState(), action) => {
  switch (action.type) {
    case MENU_CLOSE_MAIN:
      return {
        ...state,
        mainMenuOpen: false,
        filterMenuOpen: false
      }
    case MENU_TOGGLE_MAIN:
      return {
        ...state,
        mainMenuOpen: !state.mainMenuOpen,
        filterMenuOpen: false
      }
    case MENU_TOGGLE_FILTER:
      return {
        ...state,
        mainMenuOpen: false,
        filterMenuOpen: !state.filterMenuOpen
      }
    case MENU_ACTIVE_COUNT:
      return {
        ...state,
        activeCount: action.payload
      }
    case MENU_DRAFT_COUNT:
      return {
        ...state,
        draftCount: action.payload
      }
    case MENU_CLOSED_COUNT:
      return {
        ...state,
        closedCount: action.payload
      }
    default:
      return state
  }
}

import React from 'react'
import styled from 'styled-components'
import { Editor } from '@tinymce/tinymce-react'
import { EditIcon } from '_layout/form-elements'

//#region Styles
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-width: 200px;
    margin-bottom: 20px;
    padding: ${props => props.readOnly ? '0' : '10px 20px 10px 10px'};
    border: 1px solid ${props => props.readOnly ? 'transparent' : 'var(--color-line)'};
    color: var(--color-text-dark);
    background: ${props => props.readOnly ? 'none' : 'var(--color-bg-white)'};
    font-weight: 700;
    font-size: 2em;

    p {
        line-height: 1;
        margin-top: 0;
        margin-bottom: ${props => props.readOnly ? 'inherit' : '0'};
    }

    &.focus {
        background: var(--color-bg-white);
    }

    .mce-content-body {
        outline: none;
        padding: 0;
        line-height: 1;
    }

    .mce-edit-focus {
        outline: none !important;
    }

    .mce-tinymce {
        position: absolute;
        bottom: 100%;
        top: initial !important;
        left: -1px !important;
        z-index: 10000;
    }
`
//#endregion

export default class HeadingEditor extends React.Component {
  constructor(props) {
    super(props)
    const config = {
      language: 'sv_SE',
      language_url : '/langs/sv_SE.js',
      inline: true,
      toolbar: false,
      menubar: false,
      placeholder: (!props.readOnly && props.placeholder) || 'Rubrik',
      setup: editor => { editor.on('keydown', e => { if (e.keyCode === 13) { e.preventDefault() } }) },
    }
    this.state = {
      config,
      readOnly: props.readOnly,
      heading: props.text
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.readOnly !== prevState.readOnly) {
      return { readOnly: nextProps.readOnly }
    }
    return null
  }

  componentDidMount = () => {
      if (this.props.readOnly && this.editor && this.editor.editor) this.editor.editor.setMode('readonly')
  }

  componentDidUpdate = (prevProps, prevState) => {
      if (this.editor && this.editor.editor && this.props.readOnly !== prevState.readOnly) {
          this.editor.editor.setMode(this.props.readOnly ? 'readonly' : null)
      }
  }

  focusEditor = () => {
      this.editor && this.editor.editor && this.editor.editor.focus()
  }

  onFocus = focus => {
      this.setState({focus: focus})
  }

  handleEditorChange = (_, editor) => {
    const heading = editor.getContent({format : 'text'})
    this.props.onChange && this.props.onChange(heading)
  }

  render() {

    const { config, heading, focus } = this.state
    const { className } = this.props
    const onFocus = this.onFocus.bind(this)

    if (!config.init_instance_callback) {
      config.init_instance_callback = function (editor) {
        editor.on('focus', () => onFocus(true))
        editor.on('blur', () => onFocus(false))
      }
    }

    return (
      <Wrapper className={ `${focus ? 'heading-editor focus' : 'heading-editor'} ${className}`} readOnly={this.props.readOnly}>
        {this.props.icon &&
        <EditIcon onClick={this.focusEditor} title='Klicka för att redigera' />
        }
        <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
          ref={ref => this.editor = ref}
          init={config}
          initialValue={heading}
          readOnly={this.props.readOnly}
          onEditorChange={this.handleEditorChange} />
      </Wrapper>
    )
  }
}

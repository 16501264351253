import React from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import { isBefore, isAfter, parseISO, addHours } from "date-fns"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import { find, isEqual, isEmpty } from "lodash/fp"
import CalendarIconSrc from "_images/calendar-icon.svg"
import ArrowRightSrc from "_images/arrow_right.svg"
import "react-datepicker/dist/react-datepicker.css"
import { Spinner } from "_layout/form-elements"
import Modal from "_components/modal"
import { UserPicker } from "_layout/form-elements"
import { PrimaryButton, SecondaryButton, CancelButton } from "_layout/buttons"
import { JOBBET_USERS_QUERY, EMPLOYEE_QUERY } from "_containers/recruitment/recruitment-ql"
import message from "_root/components/message"
import confirm from "_root/components/confirm"
import inject from "_services/inject"

//#region Styles
const DateBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
  width: 100%;
  max-width: 620px;
  margin-bottom: 20px;
  background: var(--color-bg-white);
  z-index: 2;

  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      display: block;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        padding-left: 50px;
        transform: translateY(-50%);
      }

      input {
        width: 100%;
        padding-left: 100px;
        color: var(--color-text-medium);
        background: url(${CalendarIconSrc}) no-repeat scroll 15px 50%;
        background-size: 20px 20px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 1000;

    .react-datepicker__time-container {
      .react-datepicker__time {
        .react-datepicker__time-box {
          ul {
            &.react-datepicker__time-list {
              padding: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const DatePickerStart = styled.div`
  flex: 0 1 auto;
  width: 100%;

  .react-datepicker__input-container {
    &:before {
      content: "Från *";
    }
  }
`
const DatePickerStop = styled.div`
  flex: 0 1 auto;
  width: 100%;

  .react-datepicker__input-container {
    &:before {
      content: "Till *";
    }
  }
`
const ArrowRight = styled.div`
  display: inline-block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 0 15px;
  background: url(${ArrowRightSrc}) no-repeat scroll 50% 50%;
  background-size: 20px 20px;
`
const InputBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 620px;

  &:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    width: 150px;
    text-align: right;
    border-right: 1px solid var(--color-line-light);
  }
  > input[type="text"],
  > input[type="number"] {
    padding-left: 158px;
  }

  &#spots {
    &:before {
      content: "Antal platser";
    }
  }
  &#location {
    &:before {
      content: "Plats för möte *";
    }
  }
  &#note {
    &:before {
      content: "Egen notering";
    }
  }
  &#guest {
    &:before {
      content: "Extern deltagare";
    }
  }
`
//#endregion Styles

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(JOBBET_USERS_QUERY, { name: "jobbetUsersQuery", options: () => ({ fetchPolicy: "network-only" }) }),
  graphql(EMPLOYEE_QUERY, {
    name: "employeeQuery",
    options: (props) => ({ variables: { urlName: props.selected ? props.selected.company.urlName : props.match.params.company }, fetchPolicy: "network-only" }),
  })
)
export default class ManageInterview extends React.Component {
  constructor(props) {
    super(props)
    registerLocale("sv", sv)
    this.state = {
      mobile: !!props.mobile,
      startTime: null,
      endTime: null,
      spots: 1,
      note: "",
      guest: "",
      location: "",
      interviewers: [],
      interviewersExternal: [],
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = {}

    if (prevState.open !== nextProps.open) {
      nextState.open = nextProps.open
    }

    if (!isEqual(prevState.selected)(nextProps.selected)) {
      nextState.selected = nextProps.selected
      if (nextProps.selected) {
        nextState.startTime = nextProps.selected.startTime
        nextState.endTime = nextProps.selected.endTime
        nextState.guest = nextProps.selected.guest || ""
        nextState.note = nextProps.selected.note || ""
        nextState.location = nextProps.selected.location || ""
        nextState.spots = nextProps.selected.spots
        nextState.interviewers = nextState.selectedInternal = nextProps.selected.interviewers
        nextState.interviewersExternal = nextState.selectedExternal = nextProps.selected.interviewersExternal
      } else {
        nextState.startTime = null
        nextState.endTime = null
        nextState.guest = ""
        nextState.note = ""
        nextState.location = ""
        nextState.spots = 1
        nextState.interviewers = []
        if (nextProps.employeeQuery.users && find((u) => u.id === nextProps.user.id)(nextProps.employeeQuery.users)) {
          nextState.interviewers = nextState.selectedInternal = [nextProps.user]
        }
        nextState.interviewersExternal = nextState.selectedExternal = []
      }
    }
    return !isEmpty(nextState) ? nextState : null
  }

  onSpotsChange = (e) => {
    const spots = parseInt(e.target.value, 10)
    this.setState({ spots })
  }

  onLocationChange = (e) => {
    const location = e.target.value
    this.setState({ location })
  }

  onNoteChange = (e) => {
    const note = e.target.value
    this.setState({ note })
  }

  onExternalChange = (e) => {
    const guest = e.target.value
    this.setState({ guest })
  }

  setStartTime = date => {
    if (!date) return
    const startTime = date
    if (isBefore(startTime, new Date())) {
      message("Du kan inte skapa en mötestid som ligger bakåt i tiden.")
    }
    else if (!this.state.endTime || isAfter(startTime, parseISO(this.state.endTime))) {
      const endTime = addHours(startTime, 1)
      this.setState({ startTime: startTime.toISOString(), endTime: endTime.toISOString() })
    }
    else {
      this.setState({ startTime: startTime.toISOString() })
    }
  }

  setEndTime = date => {
    if (!date) return
    if (isBefore(date, new Date())) {
      message("Du kan inte skapa en mötestid som ligger bakåt i tiden.")
    }
    else if (this.state.startTime && isBefore(date, parseISO(this.state.startTime))) {
      message("Sluttid måste vara efter starttid")
    }
    else {
      this.setState({ endTime: date.toISOString() })
    }
  }

  selectedInterviewers = (selected) => {
    this.setState({ interviewers: [...selected] })
  }

  selectedExternal = (selected) => {
    this.setState({ interviewersExternal: [...selected] })
  }

  clearInterview = () => {
    this.setState({
      spots: 1,
      location: "",
      note: "",
      startTime: null,
      endTime: null,
      interviewers: this.state.selectedInternal,
      interviewersExternal: this.state.selectedExternal,
      guest: "",
      selected: null,
    })
  }

  deleteInterview = async () => {
    const confirmed = await confirm("Vill du ta bort mötestiden?", { confirmText: "Ta bort" })
    if (confirmed) {
      this.clearInterview()
      this.props.onDelete && this.props.onDelete(this.state.selected)
    }
  }

  closeDialog = () => {
    this.clearInterview()
    this.props.onClose && this.props.onClose()
  }

  saveInterview = () => {
    const { startTime, endTime, spots, location, note, interviewers, interviewersExternal, guest, selected } = this.state
    if (!startTime || !endTime || !(interviewers.length + interviewersExternal.length)) return
    if (selected) {
      if (this.props.onEdit && startTime && endTime) {
        this.props.onEdit({ id: selected.id, startTime, endTime, spots, note, location, interviewers, interviewersExternal, guest })
        this.clearInterview()
      }
    } else if (this.props.onCreate && startTime && endTime) {
      this.props.onCreate({ startTime, endTime, spots, note, location, interviewers, interviewersExternal, guest })
      this.clearInterview()
    }
  }

  saveInterviewCopy = () => {
    const { startTime, endTime, spots, location, note, interviewers, interviewersExternal, guest, selected } = this.state
    if (!startTime || !endTime || !(interviewers.length + interviewersExternal.length)) return
    if (selected && this.props.onCreate && startTime && endTime) {
      this.props.onCreate({ startTime, endTime, spots, note, location, interviewers, interviewersExternal, guest })
    }
  }

  render() {
    const { user, open, employeeQuery, jobbetUsersQuery, selected, match, onDelete, onCreate, onEdit, mobile } = this.props
    const { spots, location, note, startTime, endTime, interviewers, interviewersExternal, guest } = this.state
    const isJobbet = selected ? selected.company.urlName === "jobbet.se" : match.params.company === "jobbet.se"
    const loading = employeeQuery.loading || (jobbetUsersQuery && jobbetUsersQuery.loading)
    const canSave = !!startTime && !!endTime && !!location && (interviewers.length || interviewersExternal.length)
    const calendarUrl = selected && process.env.SERVER_URL + "ics/" + selected.id + "/single"

    return (
      <Modal overflow isOpen={open} onRequestClose={this.closeDialog} full={mobile}>
        <h3>{selected ? "Mötestid" : "Skapa mötestid"}</h3>
        <DateBox>
          <DatePickerStart data-tooltip-content="Starttid" data-tooltip-id="dateTip" data-tooltip-place="top">
            <DatePicker
              locale="sv"
              timeCaption="Tid"
              timeFormat="HH:mm"
              dateFormat="yy-MM-dd HH:mm"
              showTimeSelect
              showWeekNumbers
              minDate={new Date()}
              selected={startTime && parseISO(startTime)}
              readOnly={user.isViewer || user.groupViewer}
              onFocus={(e) => e.target.blur()}
              onChange={this.setStartTime}
            />
          </DatePickerStart>
          {!mobile && <ArrowRight />}
          <DatePickerStop data-tooltip-content="Sluttid" data-tooltip-id="dateTip" data-tooltip-place="top">
            <DatePicker
              locale="sv"
              timeCaption="Tid"
              timeFormat="HH:mm"
              dateFormat="yy-MM-dd HH:mm"
              showTimeSelect
              showWeekNumbers
              minDate={startTime && parseISO(startTime)}
              selected={endTime && parseISO(endTime)}
              readOnly={user.isViewer || user.groupViewer}
              onFocus={(e) => e.target.blur()}
              onChange={this.setEndTime}
            />
          </DatePickerStop>
        </DateBox>
        <InputBox id="spots">
          <input
            type="number"
            className="mb3 w-100"
            min={1}
            value={spots}
            readOnly={user.isViewer || user.groupViewer}
            data-tooltip-id="dateTip"
            data-tooltip-place="top"
            data-tooltip-content="Antal platser"
            onChange={this.onSpotsChange}
          />
        </InputBox>
        <InputBox id="location">
          <input
            type="text"
            className="mb3 w-100"
            value={location}
            placeholder="(synligt för kandidat)"
            required
            readOnly={user.isViewer || user.groupViewer}
            data-tooltip-id="dateTip"
            data-tooltip-place="top"
            data-tooltip-content="Plats för möte (visas för kandidat)"
            onChange={this.onLocationChange}
          />
        </InputBox>
        {loading && <Spinner />}
        {!loading && (
          <div>
            <UserPicker
              width={mobile ? "100%" : "296px"}
              data-tooltip-id="dateTip"
              data-tooltip-place="top"
              data-tooltip-content="Välj medverkande vid mötet."
              className="mr3"
              users={employeeQuery.users}
              placeholder="Välj medverkande..."
              readOnly={user.isViewer || user.groupViewer}
              selected={interviewers}
              onSelected={this.selectedInterviewers}
              selectedText="medverkande vald(a)"
              noSelected="Inga medverkande valda"
              noUsers="Inga valbara medverkande"
            />
            {!isJobbet && user.superAdmin && (
              <UserPicker
                width={mobile ? "100%" : "296px"}
                data-tooltip-id="dateTip"
                data-tooltip-place="top"
                data-tooltip-content="Välj rekryteringshjälp."
                users={jobbetUsersQuery.users}
                placeholder="Välj rekryteringshjälp..."
                readOnly={user.isViewer || user.groupViewer}
                selected={interviewersExternal}
                onSelected={this.selectedExternal}
                selectedText="rekryteringshjälp vald"
                noSelected="Ingen rekryteringshjälp vald"
                noUsers="Ingen valbar rekryteringshjälp"
              />
            )}
          </div>
        )}
        <InputBox id="guest">
          <input
            type="text"
            className="mb3 w-100"
            value={guest}
            readOnly={user.isViewer || user.groupViewer}
            data-tooltip-id="dateTip"
            data-tooltip-place="top"
            data-tooltip-content="E-post till extern deltagare"
            onChange={this.onExternalChange}
          />
        </InputBox>
        <InputBox id="note">
          <input
            type="text"
            className="mb3 w-100"
            value={note}
            readOnly={user.isViewer || user.groupViewer}
            placeholder="(ej synligt för kandidat)"
            data-tooltip-id="dateTip"
            data-tooltip-place="top"
            data-tooltip-content="Egen notering (visas EJ för kandidat)"
            onChange={this.onNoteChange}
          />
        </InputBox>
        {!!calendarUrl && (
          <div className="mb3 tr">
            <a href={calendarUrl} title="Spara till kalender">
              Lägg till i kalender
            </a>
          </div>
        )}
        <div className="tr">
          <CancelButton className="mt2 mr3 invert" onClick={this.closeDialog}>
            Stäng
          </CancelButton>
          {selected && onDelete && !user.isViewer && !user.groupViewer && onDelete && (
            <SecondaryButton className="mt2 mr3" onClick={this.deleteInterview}>
              Ta bort
            </SecondaryButton>
          )}
          {!user.isViewer && !user.groupViewer && selected && onCreate && (
            <SecondaryButton className="mt2 mr3" onClick={this.saveInterviewCopy} disabled={!canSave}>
              Spara ny
            </SecondaryButton>
          )}
          {!user.isViewer && !user.groupViewer && (onEdit || onCreate) && (
            <PrimaryButton className="mt2" onClick={this.saveInterview} disabled={!canSave}>
              Spara
            </PrimaryButton>
          )}
        </div>
        <Tooltip id="dateTip" place="bottom" effect="solid" multiline={true} />
      </Modal>
    )
  }
}

import React from "react"
import styled from "styled-components"
import { RecruitmentState } from "_root/constants"

//#region Styles
const StateArea = styled.ul`
  display: flex;
  list-style-type: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
`
const StateButton = styled.li`
  cursor: ${props => (props.$selected ? "default" : "pointer")};
  opacity: ${props => (props.$selected ? 1 : 0.5)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 2px solid ${props => (props.$selected ? props.$color : "var(--color-bg-light)")};
  color: var(--color-text-dark);
  background: var(--color-bg-white);
  box-shadow: 0 2px 2px var(--color-bg-light);
  text-decoration: ${props => (props.$selected ? "underline" : "none")};

  &:hover {
    opacity: 1;
    border-color: ${props => props.$color};
    background-color: var(--color-bg-lightest);
  }

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    opacity: 0.5;
  }
`
const Indicator = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${props => (props.$green ? "var(--color-success)" : props.$red ? "var(--color-error)" : "var(--color-yellow)")};
`
//#endregion

export default class StateToggle extends React.PureComponent {
  onToggleState = recruitmentState => {
    if (this.props.state !== recruitmentState) {
      this.props.onToggle(recruitmentState)
    }
  }

  draftText = "Annonsen är inte aktiv och det går inte att söka tjänsten."
  activeText = "Annonsen är aktiv och det går att söka tjänsten.<br>OBS! Ni behöver välja annonskanal för att marknadsföra och publicera annonsen."
  closedText = "Annonsen är inte längre aktiv och det går inte att söka tjänsten."

  render = () => {
    const { state } = this.props

    return (
      <StateArea>
        <StateButton
          $selected={state === RecruitmentState.DRAFT}
          data-tooltip-content={this.draftText}
          data-tooltip-id="root-tooltip"
          $color="var(--color-yellow)"
          onClick={e => this.onToggleState(RecruitmentState.DRAFT)}>
          <Indicator $yellow />
          Utkast
        </StateButton>
        <StateButton
          $selected={state === RecruitmentState.ACTIVE}
          data-tooltip-html={this.activeText}
          data-tooltip-id="root-tooltip"
          $color="var(--color-success)"
          onClick={e => this.onToggleState(RecruitmentState.ACTIVE)}>
          <Indicator $green />
          Aktiv
        </StateButton>
        <StateButton
          $selected={state === RecruitmentState.CLOSED}
          data-tooltip-content={this.closedText}
          data-tooltip-id="root-tooltip"
          $color="var(--color-error)"
          onClick={e => this.onToggleState(RecruitmentState.CLOSED)}>
          <Indicator $red />
          Avslutad
        </StateButton>
      </StateArea>
    )
  }
}

import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import styled from "styled-components"
import assign from "lodash/fp/assign"
import isEmpty from "lodash/fp/isEmpty"
import filter from "lodash/fp/filter"
import find from "lodash/fp/find"
import map from "lodash/fp/map"
import { format } from "date-fns"
import Modal from "_components/modal"
import PreviewTemplate from "../templates/preview-template"
import { AllFileTypes } from "_root/constants"
import { FilePicker, Radio, Spinner } from "_layout/form-elements"
import { PrimaryButton, SecondaryButton } from "_layout/buttons"
import DocumentList from "_components/recruit/document-list"
import confirm from "_components/confirm"
import UploadService from "_services/upload-service"
import { ADMIN_COMPANY, TemplateType, ProcessType, Process } from "_root/constants"
import { APPLICATION_WORKING_DOCS_QUERY } from "_containers/recruitment/application-ql"
import { COMPANY_QUERY, RECRUITMENT_DOCUMENTS_QUERY, DELETE_FILE_AND_DOC, DELETE_DOCUMENT } from "_containers/recruitment/recruitment-docs-ql"
import { REK_PROTOCOL } from "_root/routes/url-names"
import { withUpdateQueryOnBroadcast } from "_root/apollo-client/links/window-broadcast-link"
import to from "_services/await.to"
import all from "_services/await.all"
import inject from "_services/inject"

//#region Styles
const Content = styled.div`
  flex: 1;
  padding: 40px;
`
const H2 = styled.h2`
  margin: 0;
  max-width: 760px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--color-line-light);

  & + p {
    margin: 20px 0;
  }
`
const Section = styled.div`
  padding: 20px 0 40px 0;
  border-bottom: 1px solid var(--color-line-light);
  transform: translateZ(0);

  h3 {
    margin-top: 1em;
  }

  &:last-child {
    border-bottom: none;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;

  > *:nth-child(odd) {
    padding-right: 20px;
    border-right: 1px solid #eee;
  }

  > *:nth-child(even) {
    padding-left: 20px;
  }

  > div {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 50%;
    align-items: flex-start;
    padding-bottom: 20px;

    > div {
      display: flex;
      flex: 0 0 auto;
      margin-top: 30px;
    }

    > ul {
      flex: 1 0 auto;
    }
  }
  > ul {
    margin: 0;
  }
`
const TemplateList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 100%;

  li {
    display: flex;
    align-items: center;
    height: 30px;

    label {
      margin-bottom: 0;
      max-width: 100%;
    }
  }
`
const Controls = styled.div`
  text-align: right;
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(COMPANY_QUERY, {
    name: "companySharedQuery",
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        urlName: props.recruitment.company.urlName,
        filter: { AND: [{ shared: { equals: true } }, { OR: [{ type: { equals: TemplateType.INTERVIEW } }, { type: { equals: TemplateType.REFERENCES } }] }] },
      },
    }),
  }),
  graphql(COMPANY_QUERY, {
    skip: (props) => props.recruitment.company.urlName === ADMIN_COMPANY,
    name: "jobbetSharedQuery",
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        urlName: ADMIN_COMPANY,
        filter: { AND: [{ shared: { equals: true } }, { OR: [{ type: { equals: TemplateType.INTERVIEW } }, { type: { equals: TemplateType.REFERENCES } }] }] },
      },
    }),
  }),
  graphql(APPLICATION_WORKING_DOCS_QUERY, { name: "workingDocsQuery", options: (props) => ({ notifyOnNetworkStatusChange: true, variables: { id: props.application.id } }) }),
  graphql(RECRUITMENT_DOCUMENTS_QUERY, { name: "docsQuery", options: (props) => ({ notifyOnNetworkStatusChange: true, variables: { id: props.recruitment.id } }) }),
  graphql(DELETE_FILE_AND_DOC, { name: "deleteFile" }),
  graphql(DELETE_DOCUMENT, { name: "deleteDocument" }),
  withUpdateQueryOnBroadcast({
    workingDocsQuery: ["UpdateDocument"], // Update applicationQuery above when any UpdateApplication mutation happens in childwindow.
  })
)
export class WorkDocumentsApplicationClass extends React.Component {
  constructor(props) {
    super(props)
    this.protocols = []
    const { settings } = props.recruitment
    const interviewId = settings?.templates?.selected?.interview
    const referencesId = settings?.templates?.selected?.references
    this.state = {
      documents: [],
      docsContent: [],
      docsContentApplication: [],
      docsInterviewApplication: [],
      docsReferenceApplication: [],
      docsUploadedApplication: [],
      interviewDocs: [],
      companyInterviews: [],
      jobbetInterviews: [],
      referenceDocs: [],
      companyReferences: [],
      jobbetReferences: [],
      recruitment: props.recruitment,
      interviewId,
      referencesId,
      updateInterviewId: !interviewId,
      updateReferenceId: !referencesId,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    const { user, companySharedQuery, jobbetSharedQuery, docsQuery, workingDocsQuery } = nextProps

    let updateSelected = false

    if (!prevState.recruitment && nextProps.recruitment) {
      nextState.recruitment = nextProps.recruitment
      nextState.clientId = nextProps.recruitment.company.id
    }

    if (!prevState.employerId && user && user.employer) {
      nextState.employerId = user.employer.id
    }

    if (companySharedQuery && !companySharedQuery.loading && companySharedQuery.company) {
      nextState.companyInterviews = [...companySharedQuery.company.templates.filter((t) => t.type === TemplateType.INTERVIEW)]
      nextState.companyReferences = [...companySharedQuery.company.templates.filter((t) => t.type === TemplateType.REFERENCES)]
      updateSelected = prevState.updateInterviewId
    }

    if (jobbetSharedQuery && !jobbetSharedQuery.loading && jobbetSharedQuery.company) {
      nextState.jobbetInterviews = [...jobbetSharedQuery.company.templates.filter((t) => t.type === TemplateType.INTERVIEW)]
      nextState.jobbetReferences = [...jobbetSharedQuery.company.templates.filter((t) => t.type === TemplateType.REFERENCES)]
      updateSelected = prevState.updateReferenceId
    }

    if (!docsQuery.loading && docsQuery.recruitment && nextProps.recruitment) {
      const {
        recruitment: { documents },
      } = docsQuery

      if (!prevState.clientId) {
        nextState.clientId = nextProps.docsQuery.recruitment.company.id
      }

      let recruitmentDocs = documents
      if (!user.superAdmin) {
        recruitmentDocs = filter((doc) => find((v) => v.id === nextProps.recruitment.company.id)(doc.visible))(recruitmentDocs)
      }

      const docsContent = filter((doc) => !!doc.content || !!doc.contentHTML)(recruitmentDocs)
      const interviewDocs = docsContent.filter((d) => d.type === TemplateType.INTERVIEW)
      const referenceDocs = docsContent.filter((d) => d.type === TemplateType.REFERENCES)
      updateSelected = prevState.updateInterviewId || prevState.updateReferenceId

      assign.convert({ immutable: false })(nextState, {
        documents,
        docsContent,
        interviewDocs,
        referenceDocs,
        loadingTemplates: false,
        loadingFiles: false,
        loadingCompanyFiles: false,
      })
    }

    if (!workingDocsQuery.loading && workingDocsQuery.application) {
      const {
        application: { workingDocs },
      } = workingDocsQuery
      let applicationDocs = workingDocs
      if (!user.superAdmin) {
        applicationDocs = filter((doc) => find((v) => v.id === nextProps.recruitment.company.id)(doc.visible))(applicationDocs)
      }
      const docsUploadedApplication = filter((doc) => doc.file)(applicationDocs)
      const docsContentApplication = filter((doc) => doc.content || doc.contentHTML)(applicationDocs)
      const docsInterviewApplication = docsContentApplication.filter((d) => d.type === TemplateType.INTERVIEW)
      const docsReferenceApplication = docsContentApplication.filter((d) => d.type === TemplateType.REFERENCES)
      assign.convert({ immutable: false })(nextState, {
        docsUploadedApplication,
        docsContentApplication,
        docsInterviewApplication,
        docsReferenceApplication,
      })
    }

    if (updateSelected) {
      if (prevState.updateInterviewId) {
        if (nextState.interviewDocs && nextState.interviewDocs.length) {
          nextState.interviewId = nextState.interviewDocs[0].id
        } else if (nextState.companyInterviews && nextState.companyInterviews.length) {
          nextState.interviewId = nextState.companyInterviews[0].id
        } else if (nextState.jobbetInterviews && nextState.jobbetInterviews.length) {
          nextState.interviewId = nextState.jobbetInterviews[0].id
        }
      }
      if (prevState.updateReferenceId) {
        if (nextState.referenceDocs && nextState.referenceDocs.length) {
          nextState.referencesId = nextState.referenceDocs[0].id
        } else if (nextState.companyReferences && nextState.companyReferences.length) {
          nextState.referencesId = nextState.companyReferences[0].id
        } else if (nextState.jobbetReferences && nextState.jobbetReferences.length) {
          nextState.referencesId = nextState.jobbetReferences[0].id
        }
      }
    }

    return !isEmpty(nextState) ? nextState : null
  }

  onFilesSelected = async (files) => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map((file) => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
        if (reload) window.location.reload()
      } else {
        const createDocuments = map(
          (file) => `
                ${file.data.id}: createDocument(data: {
                    name: "${file.data.name}",
                    file: { connect: { id: "${file.data.id}"} },
                    application: { connect: { id: "${this.props.application.id}" }},
                    visible: { connect: ${this.props.user.superAdmin ? `[]` : `[{ id: "${this.state.clientId}" }]`}}
                }) {
                    id
                }`
        )(resultUpload).join("\n")
        const mutation = gql`mutation CreateDocuments {
                    ${createDocuments}
                }`
        const [error, result] = await to(this.props.client.mutate({ mutation }))
        if (error || !result) {
          console.error("work-documents-application:onFilesSelected:CreateDocuments:error", error)
          const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
          if (reload) window.location.reload()
        } else {
          this.props.workingDocsQuery.refetch()
        }
      }
    }
  }

  createHeader = () => {
    const {
      user,
      recruitment: { title, company },
      application: {
        candidate: { user: candidate },
      },
    } = this.props
    return {
      date: format(new Date, "yy-MM-dd"),
      title,
      company: company.name,
      candidate: {
        name: candidate.fullName,
        email: candidate.email,
        phone: candidate.mobile,
      },
      interviewer: user.fullName,
    }
  }

  showDocument = (doc) => {
    this.openProtocol(doc.id)
  }

  deleteDoc = async (doc) => {
    const confirmed = await confirm(`Vill du ta bort ${doc.name}?`)
    if (!confirmed) return
    if (doc.file && !doc.file.fileCompany) {
      const [error] = await to(this.props.deleteFile({ variables: { fileId: doc.file.id, docId: doc.id } }))
      if (error) {
        console.error("work-documents-application:deleteDoc:deleteFile:error", error)
        const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
        if (reload) window.location.reload()
        else return
      }
    } else {
      const [errorRemoved] = await to(this.props.deleteDocument({ variables: { id: doc.id } }))
      if (errorRemoved) {
        console.error("work-documents-application:deleteDoc:deleteDocument:errorRemoved: ", errorRemoved)
        const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
        if (reload) window.location.reload()
        else return
      }
    }
    if (doc.application) this.props.workingDocsQuery.refetch()
    if (doc.recruitment) this.props.docsQuery.refetch()
  }

  setInterviewTemplate = (e) => {
    this.setState({ interviewId: e.target.value, updateInterviewId: false })
  }

  setReferenceTemplate = (e) => {
    this.setState({ referencesId: e.target.value, updateReferenceId: false })
  }

  getInterview = () => {
    const { interviewId, interviewDocs, companyInterviews, jobbetInterviews } = this.state
    if (!interviewId) return
    const template = interviewDocs.find((d) => d.id === interviewId) || companyInterviews.find((d) => d.id === interviewId) || jobbetInterviews.find((d) => d.id === interviewId)
    return template
  }

  updateInterviewState = async () => {
    const interview1 = this.props.application.process.find((p) => p.type === ProcessType.INTERVIEW1)
    const interview2 = this.props.application.process.find((p) => p.type === ProcessType.INTERVIEW2)
    let step = interview1.state === Process.INACTIVE ? interview1 : interview2
    const recruitmentStep = this.props.application.recruitment.process.find((p) => p.type === step.type)
    const mutation = gql`mutation UpdateProcessStep {
        ${step.id}: updateProcessStep(where: { id: "${step.id}" }, data: { state: { set: ${Process.ACTIVE} } }) {
            id
        }
        ${recruitmentStep.id}: updateProcessStep(where: { id: "${recruitmentStep.id}" }, data: { state: { set: ${Process.ACTIVE} } }) {
            id
        }
      }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("work-documents-application:updateInterviewState:error", error)
    }
  }

  updateReferenceState = async () => {
    const step = this.props.application.process.find((p) => p.type === ProcessType.REFERENCES)
    const recruitmentStep = this.props.application.recruitment.process.find((p) => p.type === step.type)
    const mutation = gql`mutation UpdateProcessStep {
        ${step.id}: updateProcessStep(where: { id: "${step.id}" }, data: { state: { set: ${Process.ACTIVE} } }) {
            id
        }
        ${recruitmentStep.id}: updateProcessStep(where: { id: "${recruitmentStep.id}" }, data: { state: { set: ${Process.ACTIVE} } }) {
            id
        }
      }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("work-documents-application:updateReferenceState:error", error)
    }
  }

  openProtocol = (id) => {
    const win = this.protocols.find((p) => p.name === id)
    if (win) {
      win.focus()
    } else {
      const protocol = window.open(`/${this.props.recruitment.company.urlName}${REK_PROTOCOL}/${id}`, id, "width=1200,height=800,location=no")
      this.protocols.push(protocol)
    }
  }

  startInterview = async () => {
    this.onRequestModalClose()
    const doc = await this.onCreatePrototcol(this.getInterview())
    this.updateInterviewState()
    if (doc && doc.id) this.openProtocol(doc.id)
  }

  previewInterview = () => {
    this.onRequestModalClose()
    this.onPreview(this.getInterview())
  }

  getReference = () => {
    const { referencesId, referenceDocs, companyReferences, jobbetReferences } = this.state
    if (!referencesId) return
    const template = companyReferences.find((d) => d.id === referencesId) || jobbetReferences.find((d) => d.id === referencesId) || referenceDocs.find((d) => d.id === referencesId)
    return template
  }

  startReference = async () => {
    this.onRequestModalClose()
    const doc = await this.onCreatePrototcol(this.getReference())
    this.updateReferenceState()
    if (doc && doc.id) this.openProtocol(doc.id)
  }

  previewReference = () => {
    this.onRequestModalClose()
    this.onPreview(this.getReference())
  }

  onPreview = (template) => {
    this.setState({ previewTemplate: template })
  }

  onRequestModalClose = () => {
    this.setState({ previewTemplate: null })
  }

  onCreatePrototcol = async (doc) => {
    const header = { pagehead: this.createHeader() }
    this.setState({ loadingTemplates: true })
    const name = (doc.type === TemplateType.INTERVIEW ? "Intervju " : "Referens ") + format(new Date(), "yy-MM-dd HH:mm")
    const createDocumentMutation = `mutation CreateDocument($content: Json, $contentHTML: String) {
        createDocument(data: {
          content: $content,
          contentHTML: $contentHTML,
          name: "${name}",
          type: ${doc.type},
          application: { connect: { id: "${this.props.application.id}" } },
          visible: { connect: ${this.props.user.superAdmin ? `[]` : `[{ id: "${this.state.clientId}" }]`} }
        }) {
          id
          name
          type
          contentHTML
      }}`
    const [error, result] = await to(
      this.props.client.mutate({
        mutation: gql`
          ${createDocumentMutation}
        `,
        variables: { content: header, contentHTML: doc.contentHTML },
      })
    )
    if (error || !result) {
      console.error("work-documents-application:onCopyTemplateDoc:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    } else {
      this.props.workingDocsQuery.refetch()
      return result.data.createDocument
    }
  }

  toggleClientDocument = async (doc) => {
    const { clientId } = this.state
    if (!clientId) return
    let clientIds = []
    if (find((doc) => doc.id === clientId)(doc.visible)) {
      clientIds = filter((doc) => doc.id !== clientId)(doc.visible)
    } else {
      clientIds.push(clientId)
    }
    const visibleIds = map((id) => ({ id: id }))(clientIds)
    const variables = { id: doc.id, visibleIds: visibleIds }
    const mutation = gql`
      mutation UpdateDocument($id: String!, $visibleIds: [CompanyWhereUniqueInput!]!) {
        updateDocument(where: { id: $id }, data: { visible: { set: $visibleIds } }) {
          id
        }
      }
    `
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("work-documents-application:toggleClientDocument:updateDocument:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    } else {
      this.props.workingDocsQuery.refetch()
    }
  }

  render() {
    const {
      docsQuery,
      workingDocsQuery,
      user,
      application: { candidate },
      recruitment,
    } = this.props

    if (!this.state.clientId || docsQuery.networkStatus < 4 || !docsQuery.recruitment) {
      return (
        <Content>
          <Spinner />
        </Content>
      )
    }

    const {
      clientId,
      interviewId,
      referencesId,
      docsUploadedApplication,
      docsInterviewApplication,
      docsReferenceApplication,
      interviewDocs,
      referenceDocs,
      companyInterviews,
      jobbetInterviews,
      companyReferences,
      jobbetReferences,
      previewTemplate,
    } = this.state

    const startInterviews = companyInterviews.length + jobbetInterviews.length + interviewDocs.length > 0
    const startReferences = companyReferences.length + jobbetReferences.length + referenceDocs.length > 0

    return (
      <Content>
        <Section>
          <Grid>
            {!user.isViewer && !user.groupViewer && startInterviews && (
              <div>
                <H2>Intervju</H2>
                <p>Välj en mall i listan nedan och klicka sedan på knappen Starta intervju för att påbörja ett intervjuprotokoll.</p>
                <TemplateList>
                  {interviewDocs.map((t) => (
                    <li key={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                      <Radio name="interviewDoc" value={t.id} onChange={this.setInterviewTemplate} checked={t.id === interviewId} label={t.name} />
                    </li>
                  ))}
                  {companyInterviews.map((t) => (
                    <li key={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                      <Radio name="interviewDoc" value={t.id} onChange={this.setInterviewTemplate} checked={t.id === interviewId} label={t.name} />
                    </li>
                  ))}
                  {jobbetInterviews.map((t) => (
                    <li key={t.id} data-tooltip-content={t.name} data-tooltip-id="root-tooltip">
                      <Radio name="interviewDoc" value={t.id} onChange={this.setInterviewTemplate} checked={t.id === interviewId} label={t.name} />
                    </li>
                  ))}
                </TemplateList>
                <div>
                  <SecondaryButton className="mr2" onClick={this.previewInterview}>
                    Granska
                  </SecondaryButton>
                  <PrimaryButton onClick={this.startInterview}>Starta intervju</PrimaryButton>
                </div>
              </div>
            )}
            {!user.isViewer && !user.groupViewer && startReferences && (
              <div>
                <H2>Referenser</H2>
                <p>Välj en mall i listan nedan och klicka sedan på knappen Starta referenstagning för att påbörja ett referensprotokoll.</p>
                <TemplateList>
                  {referenceDocs.map((t) => (
                    <li key={t.id}>
                      <Radio name="referenceDoc" value={t.id} onChange={this.setReferenceTemplate} checked={t.id === referencesId} label={t.name} />
                    </li>
                  ))}
                  {companyReferences.map((t) => (
                    <li key={t.id}>
                      <Radio name="referenceDoc" value={t.id} onChange={this.setReferenceTemplate} checked={t.id === referencesId} label={t.name} />
                    </li>
                  ))}
                  {jobbetReferences.map((t) => (
                    <li key={t.id}>
                      <Radio name="referenceDoc" value={t.id} onChange={this.setReferenceTemplate} checked={t.id === referencesId} label={t.name} />
                    </li>
                  ))}
                </TemplateList>
                <div>
                  <SecondaryButton className="mr2" onClick={this.previewReference}>
                    Granska
                  </SecondaryButton>
                  <PrimaryButton onClick={this.startReference}>Starta referenstagning</PrimaryButton>
                </div>
              </div>
            )}
            <DocumentList
              clientId={clientId}
              title="Protokoll"
              loading={workingDocsQuery.loading}
              docs={docsInterviewApplication}
              noDocsText="Inga protokoll tillgängliga"
              showDocument={this.showDocument}
              toggleClientDocument={this.toggleClientDocument}
              deleteDoc={this.deleteDoc}
            />
            <DocumentList
              clientId={clientId}
              title="Protokoll"
              loading={workingDocsQuery.loading}
              docs={docsReferenceApplication}
              noDocsText="Inga protokoll tillgängliga"
              showDocument={this.showDocument}
              toggleClientDocument={this.toggleClientDocument}
              deleteDoc={this.deleteDoc}
            />
          </Grid>
        </Section>
        <Section>
          <h3>Filer för denna ansökan</h3>
          <p>Tester, arbetsprov, testresultat och annan dokumentation.</p>
          <DocumentList
            clientId={clientId}
            loading={workingDocsQuery.loading}
            docs={docsUploadedApplication}
            noDocsText="Inga filer tillgängliga"
            toggleClientDocument={this.toggleClientDocument}
            deleteDoc={this.deleteDoc}
          />
          <Controls>
            {!user.isViewer && !user.groupViewer && <FilePicker className="mr0 mb0" fileId={"work-document"} text={"Ladda upp filer…"} accept={AllFileTypes} onMultiple={this.onFilesSelected} />}
          </Controls>
        </Section>
        {previewTemplate && (
          <Modal isOpen={!!previewTemplate} onRequestClose={this.onRequestModalClose} overflow>
            <PreviewTemplate
              template={previewTemplate}
              job={recruitment.title}
              company={recruitment.company.name}
              candidate={candidate.user.fullName}
              recruiter={recruitment.recruiter.firstName + " " + recruitment.recruiter.lastName}
              external={recruitment.recruiterExternals.map((e) => e.firstName + " " + e.lastName).join(", ")}
              interviewer={user.firstName + " " + user.lastName}
              onAction={previewTemplate.type === TemplateType.INTERVIEW ? this.startInterview : this.startReference}
              onActionText={previewTemplate.type === TemplateType.INTERVIEW ? "Starta intervju" : "Starta referenstagning"}
              onClose={this.onRequestModalClose}
            ></PreviewTemplate>
          </Modal>
        )}
      </Content>
    )
  }
}

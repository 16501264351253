import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import withRouter from "_hoc/withRouter"
import * as EmailValidator from "email-validator"
import Slim from "_root/slim/slim.react"
import styled from "styled-components"
import map from "lodash/fp/map"
import find from "lodash/fp/find"
import filter from "lodash/fp/filter"
import flatten from "lodash/fp/flatten"
import uniq from "lodash/fp/uniq"
import isEmpty from "lodash/fp/isEmpty"
import { FlexCol, Spinner, FilePicker, Checkbox, Label, Input, TextArea } from "_layout/form-elements"
import { PrimaryButton, SaveButton } from "_layout/buttons"
import confirm from "_components/confirm"
import OccupationalGroupPicker from "_components/occupational-group-picker"
import ObjectPickerBase from "_components/object-picker"
import UploadService from "_services/upload-service"
import { ADMIN_COMPANY_ID, FileType, AllFileTypes } from "_root/constants"
import { GET_EDUCATIONS, GET_REGIONS, GET_OCCUPATIONAL_AREAS, GET_OCCUPATIONAL_GROUPS } from "_root/common-ql"
import { GET_CANDIDATE } from "_containers/candidates-ql"
import { CREATE_ADDRESS, UPDATE_MEMBER, CREATE_INTEREST, DELETE_MEMBER } from "_root/containers/member/members-ql"
import inject from "_services/inject"
import to from "_services/await.to"
import all from "_services/await.all"

//#region Styles
const Main = styled.div`
  overflow-x: auto;
  min-height: 100vh;
`
const Header = styled.div`
  position: relative;
  padding: 20px 40px;
  background: var(--color-bg-grey);
`
const Title = styled.h1`
  position: relative;
  font-weight: 400;

  span {
    position: absolute;
    bottom: 110%;
    font-size: 0.5em;
    font-weight: 400;
  }
`
const H2 = styled.h2`
  margin: 40px 0 30px 0;
  max-width: 760px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--color-bg-light);
`
const Content = styled.div`
  position: relative;
  padding: 40px;
  background: var(--color-bg-white);
`
const Section = styled.section`
  margin: 0 0 40px 0;
  padding: 0 0 40px 0;
  border-bottom: 1px solid var(--color-bg-light);

  & *:last-child {
    margin-bottom: 0;
  }

  .doc-link {
    margin-right: 1em;
  }
`
const GridRow = styled.div`
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
`
const InfoBox = styled.div`
  margin: 20px 0 40px 0;
  padding: 20px;
  border: 1px solid var(--color-bg-grey);
  background: var(--color-bg-lightest);

  h4 {
    margin-top: 0;
    margin-bottom: 1em;
  }
`
const Error = styled.p`
  margin: -1em 0 1em 0;
  font-size: 0.9em;
  color: var(--color-brand-red);
`
const RegionList = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  column-count: 3;

  li {
    display: block;
    margin-right: 15px;
    margin-bottom: 10px;
  }
`
const SlimEditor = styled.div`
  width: min(300px, 100%);
  height: 300px;
  .slim {
    cursor: pointer;
    border: 1px solid var(--color-bg-dark);
    background: var(--color-bg-white);
    box-shadow: inset 0 0 20px 0 var(--color-bg-grey);

    &[data-state*="empty"]:hover {
      background: var(--color-bg-white);
      box-shadow: inset 0 0 20px 5px var(--color-bg-grey);
    }
  }
`
const ObjectPicker = styled(ObjectPickerBase)`
  margin-bottom: 20px;
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(GET_CANDIDATE, { name: "candidateQuery", options: (props) => ({ variables: { id: props.match.params.candidate } }) }),
  graphql(GET_REGIONS, { name: "regionsQuery" }),
  graphql(GET_OCCUPATIONAL_AREAS, { name: "occupationalAreasQuery" }),
  graphql(GET_OCCUPATIONAL_GROUPS, { name: "occupationalGroupsQuery" }),
  graphql(GET_EDUCATIONS, { name: "educationQuery" }),
  graphql(CREATE_ADDRESS, { name: "createAddress" }),
  graphql(CREATE_INTEREST, { name: "createInterest" }),
  graphql(UPDATE_MEMBER, { name: "updateMember" })
)
export default class CandidateProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  initialState = {
    readonly: !this.props.user.superAdmin,
    userLoaded: false,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    picture: null,
    addressId: null,
    postalCity: "",
    postalCode: "",
    interest: null,
    links: "",
    information: "",
    cv: null,
    docs: [],
    spam: false,
    mailChecked: this.props.user.loggedIn,
    checkLogin: false,
    regions: [],
    occupationalAreas: [],
    occupationalGroups: [],
    selectedGroups: [],
    selectedRegions: [],
    appAreas: [],
    appRegions: [],
  }

  componentDidMount = () => {
    document.title = "Laddar kandidatprofil... - Jobbet.se"
    this.loadPicture()
  }

  componentDidUpdate = async () => {
    this.loadPicture()
    if (!this.state.interest && this.state.candidateId && !this.interestLoaded) {
      const variables = {
        candidateId: this.state.candidateId,
        companyId: ADMIN_COMPANY_ID,
        docsIds: [],
        occupationalGroupsIds: [],
        regionsIds: [],
      }
      const [error, result] = await to(this.props.createInterest({ variables }))
      if (error) {
        console.error("candidate-profile:componentDidUpdate:error", error)
        this.setState({ errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
      } else {
        this.interestLoaded = true
        const interest = result.data.createInterest
        this.setState({
          interest,
          cv: interest.cv,
          cvId: interest.cv && interest.cv.id,
          docs: interest.docs || [],
          links: interest.links || "",
          information: interest.information || "",
          selectedGroups: interest.occupationalGroups || [],
          selectedRegions: interest.regions || [],
        })
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    const { candidateQuery } = nextProps
    if (!candidateQuery.loading && !candidateQuery.candidate) {
      document.title = "Kandidaten kan inte hittas"
    } else if (!candidateQuery.loading && !prevState.candidateId && candidateQuery.candidate && candidateQuery.candidate.user) {
      let candidate = candidateQuery.candidate
      document.title = `${candidate.user.firstName} ${candidate.user.lastName} - Jobbet.se`
      nextState.candidate = candidate
      nextState.candidateId = candidate.id
      nextState.education = candidate.education
      nextState.spam = candidate.spam
      nextState.applicantId = candidate.user.id
      nextState.firstName = candidate.user.firstName
      nextState.lastName = candidate.user.lastName
      nextState.email = candidate.user.email
      nextState.mobile = candidate.user.mobile || ""
      nextState.picture = candidate.user.picture
      if (candidate.user.address) {
        nextState.addressId = candidate.user.address.id
        nextState.postalCode = candidate.user.address.postalCode
        nextState.postalCity = candidate.user.address.postalCity
      }
      const recruitments = map((a) => a.recruitment)(candidate.applications)
      nextState.appRegions = uniq(flatten(map((r) => r.regions)(recruitments)))
      nextState.appAreas = uniq(flatten(map((r) => r.occupationalAreas)(recruitments)))
      const interest = find((i) => i.company.id === ADMIN_COMPANY_ID)(candidate.interests)
      if (interest) {
        nextState.interest = interest
        nextState.cv = interest.cv
        nextState.cvId = interest.cv && interest.cv.id
        nextState.docs = interest.docs || []
        nextState.links = interest.links || ""
        nextState.information = interest.information || ""
        nextState.selectedGroups = interest.occupationalGroups || []
        nextState.selectedRegions = interest.regions || []
      }
    }
    if (!prevState.regions.length && nextProps.regionsQuery.regions && nextProps.regionsQuery.regions.length) {
      nextState.regions = nextProps.regionsQuery.regions
    }
    if (!prevState.occupationalAreas.length && nextProps.occupationalAreasQuery.occupationalAreas && nextProps.occupationalAreasQuery.occupationalAreas.length) {
      nextState.occupationalAreas = nextProps.occupationalAreasQuery.occupationalAreas
    }
    if (!prevState.occupationalGroups.length && nextProps.occupationalGroupsQuery.occupationalGroups && nextProps.occupationalGroupsQuery.occupationalGroups.length) {
      nextState.occupationalGroups = nextProps.occupationalGroupsQuery.occupationalGroups
    }
    return !isEmpty(nextState) ? nextState : null
  }

  loadPicture = () => {
    const { picture } = this.state
    if (!this.pictureLoaded && this.slim && picture && picture.url && picture.url.length) {
      this.pictureLoaded = true
      this.slim._options.instantEdit = false
      this.slim.load(picture.url, () => {
        this.slim._options.instantEdit = true
      })
    }
  }

  onSlimInit = (data, slim) => {
    this.slim = slim
    this.loadPicture()
  }

  onPhotoCancel = (data, slim) => {
    const { picture } = this.state
    if (this.pictureChanged || !picture || !picture.url || !this.slim) return
    this.slim._options.instantEdit = false
    this.slim.load(picture.url, (error, data) => {
      this.slim._options.instantEdit = true
    })
  }

  onPhotoConfirm = (data, slim) => {
    this.pictureChanged = true
    this.setState({ picture: slim.dataBase64.output })
  }

  onPhotoRemoved = (data, slim) => {
    this.pictureChanged = true
    this.setState({ picture: undefined })
  }

  onFileSelected = async (file) => {
    if (file) {
      // TODO: Delete existing file?
      const [error, { data }] = await to(UploadService.uploadFile(file))
      if (error || !data || !data.id) {
        console.error("candidate-profile:onFileSelected:uploadProfile:error", error || data)
        return
      }
      this.setState({ cvId: data.id, cv: data })
    }
  }

  deleteFile = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!e.target.dataset.doc) return
    const docs = this.state.docs.filter((doc) => doc.id !== e.target.dataset.doc)
    if (docs.length !== this.state.docs.length) {
      this.setState({ docs: docs })
    }
  }

  deleteCV = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ cv: null, cvId: null })
  }

  onSetEmail = (e) => {
    const invalid = this.state.invalid && this.state.invalid.email ? { ...this.state.invalid, email: false } : this.state.invalid
    this.setState({ email: e.target.value, invalid })
  }

  onDocsSelected = async (files) => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map((file) => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload || !resultUpload.length) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        return
      }
      const uploaded = map((upload) => upload.data)(resultUpload)
      const docs = [...this.state.docs, ...uploaded]
      this.setState({ docs })
    }
  }

  onRegionChange = (e, region) => {
    let selectedRegions = [...this.state.selectedRegions]
    if (find((r) => r.id === region.id)(this.state.selectedRegions)) {
      selectedRegions = filter((r) => r.id !== region.id)(selectedRegions)
    } else {
      selectedRegions = [...selectedRegions, region]
    }
    this.setState({ selectedRegions })
  }

  onEducationChange = (education) => {
    this.setState({ education }, this.validate)
  }

  validate = () => {
    const { email, firstName, lastName, mobile, postalCity, postalCode, education } = this.state
    const invalid = {
      email: !EmailValidator.validate(email),
      firstName: !firstName || firstName.trim().length === 0,
      lastName: !lastName || lastName.trim().length === 0,
      mobile: !mobile || mobile.trim().length === 0,
      postalCity: !postalCity || postalCity.trim().length === 0,
      postalCode: !postalCode || postalCode.trim().length === 0,
      education: !education || !education.id,
    }
    const isInvalid = invalid.email || invalid.firstName || invalid.lastName || invalid.mobile || invalid.postalCity || invalid.postalCode || invalid.education
    this.setState({ invalid: isInvalid ? invalid : null })
    return !isInvalid
  }

  saveCandidate = async () => {
    if (!this.validate()) return
    const {
      applicantId,
      firstName,
      lastName,
      mobile,
      addressId,
      postalCity,
      postalCode,
      picture,
      interest,
      cvId,
      docs,
      links,
      information,
      selectedGroups,
      selectedRegions,
      candidateId,
      spam,
      education,
      email,
    } = this.state
    let newPicture = picture
    const docsIds = map((doc) => ({ id: doc.id }))(docs)
    const regionsIds = map((r) => ({ id: r.id }))(selectedRegions)
    const occupationalGroupsIds = map((g) => ({ id: g.id }))(selectedGroups)
    const variables = {
      interestId: interest && interest.id,
      cv: cvId ? { connect: { id: cvId } } : undefined,
      links,
      information,
      docsIds,
      occupationalGroupsIds,
      regionsIds,
      applicantId,
      firstName,
      lastName,
      email,
      mobile,
      addressId,
      postalCity,
      postalCode,
      candidateId,
      spam,
      educationId: education && education.id,
    }
    if (this.pictureChanged) {
      if (picture) {
        const [error, { data }] = await to(UploadService.uploadBase64(picture.image, picture.name))
        if (error || !data || !data.id) {
          console.error("candidate-profile:saveUser:uploadPicture:error", error || data)
          this.setState({ errorMessage: "Ett fel uppstod vid uppladdning av foto." })
          return
        }
        newPicture = data
        variables.picture = picture ? { connect: { id: data.id } } : undefined
      }
    }
    if (!addressId) {
      const address = { userId: applicantId, postalCity, postalCode }
      const [error, result] = await to(this.props.createAddress({ variables: address }))
      if (error || !result) {
        console.error("candidate-profile:saveCandidate:createAddress:error", error, address)
        this.setState({ errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
        return
      }
      variables.addressId = result.data.createAddress.id
    }
    let [error, result] = await to(this.props.updateMember({ variables }))
    if (error || !result) {
      console.error("candidate-profile:saveCandidate:updateMember:error", error, variables)
      this.setState({ errorMessage: "Ett fel uppstod och uppgifterna kunde inte sparas." })
      return
    }
    this.setState({ picture: newPicture, confirmed: true, errorMessage: null })
  }

  deleteAccount = async () => {
    const confirmed = await confirm("Är du säker på att all information om kandidaten ska raderas? Detta går inte att återställas!")
    if (!confirmed) return
    const { candidate } = this.state
    const [error, result] = await to(this.props.client.mutate({ mutation: DELETE_MEMBER, variables: { id: candidate.user.id } }))
    if (error || !result) {
      console.error("candidate-profile:deleteAccount:error", error, candidate.user.id)
      return
    }
    this.setState({ deleted: true })
  }

  goBack = () => {
    window.location.reload()
  }

  render() {
    const styles = this.props.maxHeight ? { maxHeight: "100vh" } : {}
    const contentStyles = this.props.row ? { flexDirection: "row" } : {}
    const {
      candidate,
      firstName,
      lastName,
      email,
      postalCity,
      postalCode,
      mobile,
      picture,
      links,
      cv,
      docs,
      spam,
      information,
      education,
      occupationalAreas,
      occupationalGroups,
      invalid,
      errorMessage,
      regions,
      selectedGroups,
      selectedRegions,
      deleted,
      confirmed,
      appAreas,
      appRegions,
      readonly,
    } = this.state

    const { educations } = this.props.educationQuery
    const { loading } = this.props.candidateQuery

    let content
    if (deleted) {
      content = (
        <Content>
          <H2>Kontot har nu raderats.</H2>
          <p>
            <a onClick={window.close}>Stäng fönster</a>
          </p>
        </Content>
      )
    } else if (loading) {
      content = (
        <Content>
          <Spinner />
        </Content>
      )
    } else if (!candidate) {
      content = (
        <Content>
          <H2>Kandidaten kan inte hittas.</H2>
          <p>Den kan ha blivit borttagen nyligen.</p>
          <p>
            <a onClick={window.close}>Stäng fönster</a>
          </p>
        </Content>
      )
    } else if (confirmed) {
      content = (
        <Content>
          <H2>Kandidatens uppgifter har blivit uppdaterade!</H2>
          <p>
            <a onClick={this.goBack}>Tillbaka</a>
          </p>
        </Content>
      )
    } else {
      content = (
        <Content id="main-content" style={contentStyles}>
          <Section>
            <h3>Kontaktuppgifter</h3>
            <GridRow>
              <FlexCol className="mr5">
                <Label>E-post *</Label>
                <Input type="email" disabled={readonly} placeholder="namn@exempel.com" $invalid={invalid && invalid.email} value={email} onBlur={this.validate} onChange={this.onSetEmail} />
                {invalid && invalid && invalid.email && <Error>Ange en fullständig e-postadress</Error>}
                <Label>Förnamn *</Label>
                <Input
                  type="text"
                  disabled={readonly}
                  autoFocus={!firstName}
                  placeholder="Förnamn"
                  $invalid={invalid && invalid.firstName}
                  value={firstName}
                  onBlur={this.validate}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
                {invalid && invalid && invalid.firstName && <Error>Ange förnamn</Error>}
                <Label>Efternamn *</Label>
                <Input
                  type="text"
                  disabled={readonly}
                  placeholder="Efternamn"
                  $invalid={invalid && invalid.lastName}
                  value={lastName}
                  onBlur={this.validate}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
                {invalid && invalid && invalid.lastName && <Error>Ange efternamn</Error>}
                <Label>Mobil *</Label>
                <Input
                  type="text"
                  disabled={readonly}
                  placeholder="07x-xxxxxxx"
                  $invalid={invalid && invalid.mobile}
                  value={mobile}
                  onBlur={this.validate}
                  onChange={(e) => this.setState({ mobile: e.target.value })}
                />
                {invalid && invalid && invalid.mobile && <Error>Ange mobilnummer</Error>}
                <Label>Bostadsort *</Label>
                <Input
                  type="text"
                  disabled={readonly}
                  placeholder="Bostadsort"
                  $invalid={invalid && invalid.postalCity}
                  value={postalCity}
                  onBlur={this.validate}
                  onChange={(e) => this.setState({ postalCity: e.target.value })}
                />
                {invalid && invalid && invalid.postalCity && <Error>Ange bostadsort</Error>}
                <Label>Postnummer *</Label>
                <Input
                  type="text"
                  disabled={readonly}
                  placeholder="Postnummer"
                  $invalid={invalid && invalid.postalCode}
                  value={postalCode}
                  onBlur={this.validate}
                  onChange={(e) => this.setState({ postalCode: e.target.value })}
                />
                {invalid && invalid && invalid.postalCode && <Error>Ange postnummer</Error>}
              </FlexCol>
              <FlexCol>
                <Label>Foto</Label>
                {readonly && picture && <img src={picture.url} alt="Candidate profile" />}
                {!readonly && (
                  <SlimEditor>
                    <Slim
                      ratio="1:1"
                      label="Dra din bild hit eller klicka här"
                      labelLoading="Laddar bild..."
                      instantEdit={true}
                      buttonEditTitle="Redigera"
                      buttonRemoveTitle="Ta bort"
                      buttonRotateTitle="Rotera"
                      buttonCancelLabel="Avbryt"
                      buttonCancelTitle="Avbryt"
                      buttonConfirmLabel="OK"
                      buttonConfirmTitle="OK"
                      didInit={this.onSlimInit}
                      didCancel={this.onPhotoCancel}
                      didConfirm={this.onPhotoConfirm}
                      didRemove={this.onPhotoRemoved}
                      size={{ width: 300, height: 300 }}
                    >
                      <input type="file" name="pictureFile" />
                    </Slim>
                  </SlimEditor>
                )}
              </FlexCol>
            </GridRow>
          </Section>
          {educations && (
            <Section>
              <h3>Kandidatens högsta utbildning</h3>
              <ObjectPicker readOnly={readonly} form={"true"} values={educations} placeholder="Välj utbildning..." current={education} onSelected={this.onEducationChange} noMatch="Inga träffar" />
              {invalid && invalid.education && <Error>Ange din högsta utbildning</Error>}
            </Section>
          )}
          <Section>
            <h3>CV</h3>
            <p>Här kan man ladda upp ett CV.</p>
            {!readonly && (
              <FilePicker
                className="mb1"
                readOnly={readonly}
                fileId={"candidate-cv"}
                text={"Ladda upp CV…"}
                accept={[FileType.DOC, FileType.DOCX, FileType.XLS, FileType.XLSX, FileType.ODT, FileType.ODS, FileType.PDF, FileType.TXT, FileType.RTF]}
                onFile={this.onFileSelected}
              />
            )}
            {cv && (
              <React.Fragment>
                <a className="doc-link" href={cv.url}>
                  {cv.name}
                </a>
                {!readonly && [
                  <a className="delete-link" onClick={this.deleteCV}>
                    Ta bort
                  </a>,
                ]}
              </React.Fragment>
            )}
          </Section>
          {occupationalAreas && occupationalAreas.length > 0 && occupationalGroups && occupationalGroups.length > 0 && (
            <Section>
              <h3>Intresserad av följande yrken:</h3>
              {appAreas && appAreas.length > 0 && (
                <InfoBox>
                  <h4>Branscher från tidigare ansökningar:</h4>
                  <div>{map((r) => r.name)(appAreas).join(", ")}</div>
                </InfoBox>
              )}
              <OccupationalGroupPicker
                readonly={readonly}
                occupationalAreas={occupationalAreas}
                occupationalGroups={occupationalGroups}
                selected={selectedGroups}
                onChange={(selectedGroups) => this.setState({ selectedGroups })}
              />
            </Section>
          )}
          <Section>
            <h3>Intresserad av att jobba i följande regioner:</h3>
            {appRegions && appRegions.length > 0 && (
              <InfoBox>
                <h4>Regioner från tidigare ansökningar:</h4>
                <div>{map((r) => r.name)(appRegions).join(", ")}</div>
              </InfoBox>
            )}
            {regions && regions.length > 0 && (
              <RegionList>
                {map((region) => (
                  <li key={region.id}>
                    <label>
                      <Checkbox disabled={readonly} value={region} onChange={(e) => this.onRegionChange(e, region)} checked={!!find((r) => r.id === region.id)(selectedRegions)} />
                      {region.name}
                    </label>
                  </li>
                ))(regions)}
              </RegionList>
            )}
          </Section>
          <Section>
            <h3>Länkar</h3>
            <p>Lägg till länkar till relevanta sidor som LinkedIn etc.</p>
            <TextArea disabled={readonly} placeholder="Länkar" rows="3" value={links} onChange={(e) => this.setState({ links: e.target.value })} />
          </Section>
          <Section>
            <h3>Bilagor</h3>
            <p>Här kan man ladda upp personligt brev, intyg eller liknande.</p>
            {!readonly && <FilePicker className="mb1 mr4" fileId={"candidate-files"} text={"Ladda upp bilagor…"} accept={AllFileTypes} onMultiple={this.onDocsSelected} />}
            {docs && !!docs.length && (
              <ul>
                {map((doc) => (
                  <li key={doc.id}>
                    <a className="doc-link" href={doc.url}>
                      {doc.name}
                    </a>
                    {!readonly && [
                      <a className="delete-link" data-doc={doc.id} onClick={this.deleteFile}>
                        Ta bort
                      </a>,
                    ]}
                  </li>
                ))(docs)}
              </ul>
            )}
          </Section>
          <Section>
            <h3>Övriga kunskaper</h3>
            <p>Här kan man lägga in certifieringar, språkkunskaper, körkort etc.</p>
            <TextArea disabled={readonly} placeholder="Skriv eller klistra in text..." rows="4" value={information} onChange={(e) => this.setState({ information: e.target.value })} />
          </Section>
          <Section>
            <label className="mb4 db">
              <Checkbox value={spam} disabled={readonly} checked={spam} onChange={(e) => this.setState({ spam: !spam })} /> Ja till jobbtips!
            </label>
            {!readonly && (
              <SaveButton className="mt0" onClick={this.saveCandidate}>
                Spara kandidatens profil
              </SaveButton>
            )}
            {invalid && <Error>Något saknas. Kontrollera formuläret ovan.</Error>}
            {errorMessage && <Error>{errorMessage}</Error>}
          </Section>
          {!readonly && (
            <Section>
              <h3>Radera kandidatens uppgifter</h3>
              <p>Kandidatens konto samt alla relaterade uppgifter kommer att raderas.</p>
              <p>
                <PrimaryButton className="mt2" onClick={this.deleteAccount}>
                  Radera kandidatens konto
                </PrimaryButton>
              </p>
            </Section>
          )}
        </Content>
      )
    }

    return (
      <Main style={styles}>
        {candidate && (
          <Header>
            <Title>
              <span>Kandidat:</span>
              {`${candidate.user.firstName} ${candidate.user.lastName}`}
            </Title>
          </Header>
        )}
        {content}
      </Main>
    )
  }
}

import React from 'react'
import withRouter from '_hoc/withRouter'
import styled from 'styled-components'
import queryString from 'query-string'

import { SOK, KANDIDAT } from '_routes/url-names'
import {
    ResultList,
    ResultItem,
    SimpleShowMoreLink,
    SimpleLink
} from '_modules/search/common-layout/result-lists'
import ApplicationList from '../application-list'

const ResultListApplications = styled(ApplicationList)`
    margin-left: auto;
`

const SimpleCandidateResults = props => {
    const { queryInput: query, candidates, total } = props;
    const { match: { params: routeParams } } = props;
    const linkParams = {
        pathname: `/${routeParams.company}${SOK}`,
        search: queryString.stringify({ category: "candidates", query })
    }
    const showMoreLink = <SimpleShowMoreLink to={linkParams}>Visa fler ({ total - candidates.length }) &gt;</SimpleShowMoreLink>

    const openCandidateProfile = (e, id) => {
        e.preventDefault()
        const { match: { params: routeParams } } = props
        props.onClose && props.onClose()
        window.open(`/${routeParams.company}${KANDIDAT}/${id}`, id, 'width=1200,height=800,location=no')
        return false
    }

    return (
        <div>
            <ResultList>
            {
                candidates.map(candidate =>
                    <ResultItem key={candidate.user.id}>
                        <SimpleLink onClick={(e) => openCandidateProfile(e, candidate.id)}>{ `${candidate.user.firstName} ${candidate.user.lastName}` }</SimpleLink>
                        <ResultListApplications applications={candidate.applications} />
                    </ResultItem>
                )
            }
            </ResultList>
            { (total > candidates.length) && showMoreLink }
        </div>
    )
}

export default withRouter(SimpleCandidateResults)

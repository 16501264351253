import React from 'react';
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIconSrc from '_images/cross.svg'

//#region Styles
const styles = props => {
  return {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 100000
    },
    content: {
      position: 'absolute',
      top: props.full ? '0' : '3vh',
      left: props.full ? '0' : '50%',
      right: props.full ? '0' : 'auto',
      bottom: props.full ? '0' : 'auto',
      border: '1px solid #ccc',
      borderRadius: '0',
      background: '#fff',
      textAlign: props.center ? 'center' : 'left',
      overflow: props.overflow || 'auto',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      transform: props.full ? 'none' : 'translateX(-50%)',
      padding: '0',
      minWidth: props.min || '360px',
      maxHeight: props.full ? '100vh' : '95vh'
    }
  }
}

const Content = styled.div`
  position: relative;
  padding: ${props => props.$nopadding ? 0 : '30px'};
`
const Close = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url(${CloseIconSrc}) no-repeat;
  opacity: 0.5;

  &:hover { opacity: 1; }
`
//#endregion

Modal.setAppElement('#root')

export default props => 
  <Modal {...props} style={styles(props)}>
    <Content $nopadding={props.nopadding}>
      <Close onClick={props.onRequestClose} />
      {props.children}
    </Content>
  </Modal>

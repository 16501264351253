import isLoggedIn from '_services/is-logged-in'
import { USER_SET_USER, USER_DELETE, USER_LOGOUT, USER_SET_LOGGEDIN, USER_SET_RELOAD } from '_actions/user-actions'

const initialState = {
  companyView: null,
  loggedIn: isLoggedIn(),
  reload: false,
  loading: true,
  authenticated: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_SET_USER:
            const user = action.payload
            return {
                ...state,
                ...user
            }
        case USER_DELETE:
            return {
                ...initialState
            }
        case USER_SET_LOGGEDIN:
            return {
                ...state,
                loggedIn: action.payload
            }
        case USER_SET_RELOAD:
            return {
                ...state,
                reload: action.payload
            }
        case USER_LOGOUT:
            return {
                ...initialState,
                loggedIn: false,
                loading: false
            }
        default:
            return state
    }
}

import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

const SHARE_APPLICATION = gql`
mutation ShareApplicationMutation(
    $to: [String!]!,
    $applicationIds: [String!]!,
    $recruitmentId: String!,
    $subject: String!,
    $text: String,
    $html: String
) {
    shareApplication(
        to: $to,
        applicationIds: $applicationIds,
        recruitmentId: $recruitmentId,
        subject: $subject,
        text: $text,
        html: $html
    ) {
        message
    }
}
`

const withShareApplicationMutation = graphql(SHARE_APPLICATION, {
    props: ({ ownProps, mutate }) => ({
        shareApplication: ({ to, applicationIds, recruitmentId, subject, text, html }) => {
            return mutate({
                variables: {
                    to,
                    applicationIds,
                    recruitmentId,
                    subject,
                    text,
                    html
                }
            })
        }
    })
})

export default withShareApplicationMutation
import React from "react"
import withRouter from '_hoc/withRouter'
import queryString from "query-string"
import { SOK } from "_routes/url-names"
import {
  ResultList,
  ResultItem,
  SimpleShowMoreLink,
} from "_modules/search/common-layout/result-lists"

const SimpleCompanyResult = props => {
  const { queryInput: query, results, meta } = props;
  const {
    match: { params: routeParams },
  } = props;
  const metaCount = (meta && meta._count && meta._count._all) || 0;

  const linkParams = {
    pathname: `/${routeParams.company}${SOK}`,
    search: queryString.stringify({ category: "companies", query }),
  };
  const showMoreLink = (
    <SimpleShowMoreLink to={linkParams}>
      Visa fler ({metaCount - results.length}) &gt;
    </SimpleShowMoreLink>
  );

  return (
    <div>
      <ResultList>
        {props.results.map((result) => (
          <ResultItem key={result.id}>
            <a
              href={`/${result.urlName}`}
              target="_blank"
              rel="opener"
              onClick={props.onClose}
            >
              {result.name}
            </a>
          </ResultItem>
        ))}
      </ResultList>
      {metaCount > results.length && showMoreLink}
    </div>
  );
};

export default withRouter(SimpleCompanyResult);

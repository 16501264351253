import React from 'react'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { map } from 'lodash/fp'
import message from '_components/message'
import TinyEditor from '_components/tiny-editor'
import { PrimaryButton } from '_layout/buttons'
import { CancelButton } from '_layout/buttons'
import { withEmailCompanyMutation } from '_modules/applications'
import to from '_services/await.to'
import inject from '_services/inject'

const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`

@compose(
    inject('user'),
    withEmailCompanyMutation
)
export default class CompanyMailing extends React.Component {
    state = {
        loading: true
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (!prevState.loading) return null
        return {
            loading: false,
            companyNames: nextProps.companies ? map(c => c.name)(nextProps.companies) : [],
            companyIds: nextProps.companies ? map(c => c.id)(nextProps.companies) : [],
            sender: nextProps.user.email || '',
            name: ''
        }
    }

    onSenderChange = e => {
        this.setState({sender: e.target.value})
    }

    onNameChange = e => {
        this.setState({name: e.target.value})
    }

    onContentChange = content => {
        this.setState({content})
    }

    onSend = async e => {
        this.setState({sending: true})
        const { name, content, companyIds, sender } = this.state
        if (!sender || !name || !content || !companyIds || !companyIds.length) return
        const [error] = await to(this.props.emailCompany({
            from: sender,
            to: companyIds,
            subject: name,
            html: content,
            group: false
        }))
        if (error) {
            console.error('company-mailing:onSend:error: ', error);
            this.setState({sending: false, errorMessage: 'Något gick fel när e-post skulle skickas.'})
        }
        else {
            this.setState({sending: false, errorMessage: null}, () => {
                this.onUpdate()
                this.onCancel()
            })
            message('E-post har skickats till användare på valda företag.')
        }
    }

    onDirty = e => {}

    onUpdate = () => {
        this.props.onUpdate()
    }

    onCancel = e => {
        this.onClose(e)
    }

    onClose = e => {
        this.setState({name: '', content: null})
        this.props.onClose(e)
    }

    render() {
        const { sender, name, content, companyNames, companyIds, sending, errorMessage } = this.state

        const canSend = sender && name && content && companyIds && companyIds.length

        return (
            <div>
                <h3>Utskick till användare på valda företag</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <label className='mb2 db'>Från:</label>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Från'}
                    value={sender}
                    onChange={this.onSenderChange} />
                <label className='mb2 db'>Skickas till: (endast synligt för administratörer)</label>
                <div className='mb3'>{companyNames.join(', ')}</div>
                <input 
                    type='text'
                    className='mb3 w-100'
                    placeholder={'Ämne'}
                    value={name}
                    onChange={this.onNameChange} />
                <TinyEditor
                    placeholder='Meddelande'
                    form
                    insert='h3 bold italic underline | bullist numlist | link table'
                    selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                    plugins='autolink link lists image table'
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 440px)', minHeight: 300 }}
                    content={content}
                    onChange={this.onContentChange}
                    onDirty={this.onDirty} />
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Avbryt</CancelButton>
                    <PrimaryButton onClick={this.onSend} disabled={!canSend} loading={sending}>Skicka</PrimaryButton>
                </div>
            </div>
        )
    }
}
import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { isEmpty, filter, map } from "lodash/fp"
import { AllFileTypes } from "_root/constants"
import MainLayout from "_layout/main-layout"
import confirm from "_components/confirm"
import Loading from "_components/loading"
import FileList from "_components/recruit/file-list"
import UploadService from "_services/upload-service"
import { H2, FlexRow, FlexCol, FilePicker } from "_layout/form-elements"
import { COMPANY_QUERY, UPDATE_COMPANY, DELETE_FILE } from "_containers/recruitment/recruitment-docs-ql"
import to from "_services/await.to"
import all from "_services/await.all"

//#region Styles
const File = styled(FilePicker)`
  display: inline-block;
  margin-right: 40px;

  input + span {
    background-color: var(--color-brand-red);

    &:hover {
      background-color: var(--color-brand-red-dark);
    }
  }
`
//#endregion

@compose(
  withRouter,
  graphql(COMPANY_QUERY, { name: "companyQuery", options: (props) => ({ notifyOnNetworkStatusChange: true, variables: { urlName: props.match.params.company } }) }),
  graphql(UPDATE_COMPANY, { name: "updateCompany" }),
  graphql(DELETE_FILE, { name: "deleteFile" })
)
export default class CompanyDocs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (!nextProps.companyQuery.loading) {
      nextState.company = nextProps.companyQuery.company
      nextState.files = nextProps.companyQuery.company.files
      nextState.loading = false
    }

    return !isEmpty(nextState) ? nextState : null
  }

  onFilesSelected = (files) => {
    const doUpload = async () => {
      if (files && files.length) {
        const [errorUpload, resultUpload] = await all(map((file) => UploadService.uploadFile(file))(files))
        if (errorUpload || !resultUpload) {
          console.error("onFilesSelected:uploadFile:error ", errorUpload)
          return
        }
        const filesIds = map((file) => ({ id: file.id }))(this.state.files)
        const uploadedIds = map((upload) => ({ id: upload.data.id }))(resultUpload)
        const [error] = await to(
          this.props.updateCompany({
            variables: {
              id: this.state.company.id,
              filesIds: [...filesIds, ...uploadedIds],
            },
          })
        )
        if (error) {
          console.error("onFilesSelected:updateCompany:error", error)
          return
        }
        this.props.companyQuery.refetch()
      } else {
        this.setState({ loading: false })
      }
    }
    this.setState({ loading: true }, doUpload)
  }

  deleteDoc = (file) => {
    const doDelete = async () => {
      const confirmed = await confirm(`Vill du ta bort ${file.name}?`)
      if (!confirmed) return
      const files = filter((d) => d.id !== file.id)(this.state.files)
      this.setState({ files })
      const [error] = await to(this.props.deleteFile({ variables: { id: file.id } }))
      if (error) {
        console.error("deleteDoc:error", error)
        return
      }
      this.props.companyQuery.refetch()
    }
    this.setState({ loading: true }, doDelete)
  }

  render() {
    if (this.props.companyQuery.networkStatus < 4) {
      return <Loading title="Hämtar filer..." />
    }

    const { loading, company, files } = this.state

    return (
      <MainLayout padding>
        <H2>Filer</H2>
        <FlexRow className="margin20">
          <FlexCol $full>
            <p>
              Ladda upp filer och dokument i en företagscentral filbank.
              <br />
            </p>
            <p className="mb4">
              Företagspresentationer, mallar, instruktioner, arbetsprover m.m.
              <br />
              Alla vanliga bild- och filformaten stöds (t.ex. PDF, DOCX, JPG, PNG).
            </p>
            <File onMultiple={this.onFilesSelected} fileId={"company-docs"} accept={AllFileTypes} text={"Ladda upp filer…"} />
            {files && files.length > 0 && <FileList loading={loading} clientId={company.id} files={files} deleteFile={this.deleteDoc} />}
            {(!files || files.length === 0) && <div>Inga filer har blivit uppladdade.</div>}
          </FlexCol>
        </FlexRow>
      </MainLayout>
    )
  }
}

import React from "react"
import { Navigate, Link } from "react-router-dom"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import * as EmailValidator from "email-validator"
import { Input, Spinner } from "_root/layout/form-elements"
import { PrimaryButton, PrimaryInvertLinkButton, CancelButton } from "_root/layout/buttons"
import { loginUser } from "_services/login-service"
import message from "_root/components/message"
import inject from "_services/inject"
import to from "_services/await.to"
import { MEDLEM, PROFIL, REGISTRERA } from "_root/routes/url-names"
import { SIGNIN_USER_MUTATION, FORGOT_PASSWORD_MUTATION, SYSTEM_SETTINGS } from "_root/common-ql"
import MemberLayout from "./member-layout"

//#region Styles
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const H2 = styled.h2`
  margin: 0;
  color: var(--color-text);

  @media (max-width: 360px) {
    a {
      display: none;
    }
  }
`
const Error = styled.p`
  margin: -1em 0 1em 0;
  font-size: 0.9em;
  color: var(--color-brand-red);
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
const ForgotLink = styled.a`
  align-self: center;
  margin-left: 20px;
`
//#endregion

@compose(inject("user"), graphql(SYSTEM_SETTINGS, { name: "system" }), graphql(SIGNIN_USER_MUTATION, { name: "signinUser" }), graphql(FORGOT_PASSWORD_MUTATION, { name: "forgotPassword" }))
export default class MemberLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: props.user.loggedIn,
      email: "",
      password: "",
      forgotPassword: false,
      loading: false,
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.user.authenticated) {
      return { loggedIn: true }
    }
    return null
  }

  checkEmail = () => {
    const invalid = EmailValidator.validate(this.state.email) ? null : { email: true }
    this.setState({ invalid })
  }

  onPasswordKeyPress = (e) => {
    const key = `${e.keyCode || e.charCode}`
    if (key === "13" || key === "10") {
      this.login()
    }
  }

  forgotPassword = (e) => {
    e.preventDefault()
    this.setState({ forgotPassword: true })
    return false
  }

  sendPassword = async (e) => {
    e.preventDefault()
    this.setState({ loadingPassword: true })
    const { email } = this.state
    const [error, result] = await to(this.props.forgotPassword({ variables: { email, fromAdmin: false } }))
    if (error) {
      console.error("sendPassword:error: ", error, email)
      message("Ett fel uppstod när lösenord skulle hanteras.")
    } else {
      if (!result.data.forgotPassword) console.error("login:sendPassword: ", result)
      message("Ett nytt lösenord har skickats till angiven e-postadress om det finns ett medlemskonto.")
    }
    this.setState({ forgotPassword: false, loadingPassword: false })
    return false
  }

  login = async () => {
    if (EmailValidator.validate(this.state.email)) {
      const { email, password } = this.state
      this.setState({ loading: true })
      const [error, result] = await to(
        this.props.signinUser({
          variables: {
            email,
            password,
          },
        })
      )
      if (error || !result) {
        message("Inloggning misslyckades! Vänligen kontrollera dina uppgifter.")
        console.error("member:login:error:", error)
        this.setState({ loading: false })
      }
      else {
        const {
          data: { authenticateUser },
        } = result
        if (authenticateUser && authenticateUser.token) {
          loginUser(authenticateUser.token)
        } else {
          message("Inloggning misslyckades! Ett fel inträffade på servern. Vänligen kontakta kundtjänst eller försök igen.")
          console.error("member:login:error:authenticateUser:", result)
          this.setState({ loading: false })
        }
      }
    }
    else {
      this.setState({ invalid: { ...this.state.invalid, email: true } })
    }
  }

  render() {
    const { loggedIn, nextUrl, email, password, forgotPassword, invalid, loading, loadingPassword } = this.state
    const { system } = this.props
    const panic = system.systems && system.systems[0].panic

    if (system.loading) {
      return (
        <MemberLayout>
          <Section>
            <Spinner />
          </Section>
        </MemberLayout>
      )
    }

    if (panic) {
      return (
        <MemberLayout>
          <H2>Systemet är tillfälligt stängt för underhåll.</H2>
          <Section>
            <h2 className="mt4">Kundtjänst</h2>
            <p>Telefon: 018 - 100 112</p>
            <p>
              E-post: <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>
            </p>
            <p>Adress: Fyristorg 6, 753 10 Uppsala</p>
          </Section>
        </MemberLayout>
      )
    }

    if (loggedIn) {
      return <Navigate to={nextUrl || MEDLEM + PROFIL} />
    }

    return (
      <MemberLayout nomenu>
        <Section>
          <H2>
            Medlem{" "}
            <PrimaryInvertLinkButton className="fr" to={MEDLEM + REGISTRERA}>
              Bli medlem
            </PrimaryInvertLinkButton>
          </H2>
        </Section>
        {!forgotPassword && (
          <Section>
            <Ingress>
              Välkommen att logga in nedan eller <Link to={MEDLEM + REGISTRERA}>registrera dig som medlem</Link>.
            </Ingress>
            <Input
              type="email"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value.trim() })}
              onBlur={this.checkEmail}
              $invalid={invalid && invalid.email}
              placeholder="Din e-postadress"
            />
            {invalid && invalid.email && <Error>Ange en fullständig e-postadress</Error>}
            <br />
            <Input
              autoFocus
              value={password}
              autoComplete="new-password"
              onChange={(e) => this.setState({ password: e.target.value.trim() })}
              onKeyPress={this.onPasswordKeyPress}
              type="password"
              placeholder="Ditt lösenord"
            />
            <div className="flex login">
              <PrimaryButton className="pointer mr2" onClick={this.login} loading={loading}>
                Logga in
              </PrimaryButton>
              <ForgotLink href={MEDLEM} onClick={this.forgotPassword}>
                Glömt lösenord?
              </ForgotLink>
            </div>
          </Section>
        )}
        {forgotPassword && (
          <Section>
            <Ingress>Ange din e-postadress nedan och klicka på knappen för att få ett nytt lösenord.</Ingress>
            <Input
              type="email"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value.trim() })}
              onBlur={this.checkEmail}
              $invalid={invalid && invalid.email}
              placeholder="Din e-postadress"
            />
            {invalid && invalid.email && <Error>Ange en fullständig e-postadress</Error>}
            <div className="flex login">
              <CancelButton className="mr3" onClick={(e) => this.setState({ forgotPassword: false })}>
                Avbryt
              </CancelButton>
              <PrimaryButton onClick={this.sendPassword} disabled={invalid && invalid.email} loading={loading || loadingPassword}>
                Skicka nytt lösenord
              </PrimaryButton>
            </div>
          </Section>
        )}
      </MemberLayout>
    )
  }
}

import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { format, parseISO } from "date-fns"
import map from 'lodash/fp/map'
import ObjectPicker from '_components/object-picker'
import { CancelButton } from '_layout/buttons'
import { COMPANY_MESSAGE_LOGS_QUERY } from '_containers/admin/companies-ql'
import isEmpty from 'lodash/fp/isEmpty'
import { Spinner } from '_layout/form-elements'

const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`
const MessageHeader = styled.div`
    label {
        margin-right: 10px;
        color: var(--color-text);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-transform: uppercase;
        display: inline-block;
        min-width: 4.2em;
    }
`
const MessageBody = styled.span`
    display: block;
`
const Dialog = styled.div`
    min-width: 600px;
`
const Message = styled.div`
    min-height: 300px;
`

@compose(
    graphql(COMPANY_MESSAGE_LOGS_QUERY, { name: 'messageLogsQuery', options: props => ({ variables: { companyId: props.companyId }, notifyOnNetworkStatusChange: true }) })
)
export default class CompanyMessageLog extends React.Component {
    state = {
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            if (this.props.messageLogsQuery.networkStatus >= 7) {
                // query is executed by a previous instance, refetch data
                this.props.messageLogsQuery.refetch()
            }
        })        
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let nextState = {}
        const loading = nextProps.messageLogsQuery.loading
        if (prevState.loading !== loading) {
            nextState.loading = loading
        }
        if (prevState.loading === true && nextState.loading === false) {
            const messageLogs = map((ml) => {
                return {...ml, name: ml.subject}
            })(nextProps.messageLogsQuery.company.messageLogs)
            nextState.selected = (messageLogs.length > 0) ? messageLogs[messageLogs.length - 1] : undefined
            nextState.messageLogs = messageLogs
        }
        return !isEmpty(nextState) ? nextState : null
    }

    onSelected = messageLog => {
        if (!messageLog) return
        this.setState({
            selected: messageLog
        })
    }

    onCancel = e => {
        this.props.onClose()
    }

    getBodyHtml = () => {
        const __html = this.state.selected
            ? this.state.selected.body
            : ''
        return { __html }
    }

    render() {
        const { loading, messageLogs, selected, errorMessage } = this.state
        if (loading) {
            return <Dialog><Spinner /></Dialog>
        }
        return (
            <Dialog>
                <h3>Skickade meddelanden</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <ObjectPicker
                    form={'true'}
                    className='mb3'
                    values={messageLogs}
                    placeholder='Välj meddelande...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga meddelanden' />
                {!selected &&
                <Message></Message>
                }
                {selected &&
                <Message>
                    <MessageHeader><label>Skickat</label> {format(parseISO(selected.createdAt), "yy-MM-dd HH:mm")}</MessageHeader>
                    <MessageHeader><label>Från</label> {selected.from}</MessageHeader>
                    <MessageHeader><label>Till</label> {selected.to.map(t => t.value).join(', ')}</MessageHeader>
                    <MessageHeader><label>Ämne</label> {selected.subject}</MessageHeader>
                    <MessageBody dangerouslySetInnerHTML={this.getBodyHtml()}></MessageBody>
                </Message>
                }
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Stäng</CancelButton>
                </div>
            </Dialog>
        )
    }
}
import React from 'react'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import to from '_services/await.to'
import inject from '_services/inject'
import { SYSTEM_SETTINGS, PANIC_MODE } from '_root/common-ql'
import confirm from '_components/confirm'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol, Spinner } from '_layout/form-elements'
import { PrimaryButton, SecondaryButton } from '_root/layout/buttons';

@compose(
    withApollo,
    inject('user'),
    graphql(SYSTEM_SETTINGS, { name: 'system' }),
    graphql(PANIC_MODE, { name: 'panic' })
)
export default class AdminSite extends React.Component {

    togglePanic = async panicMode => {
        const { system, panic } = this.props
        const id = system.systems[0].id
        const [error, result] = await to(panic({ variables: { id: id, panic: panicMode } }))
        if (error || !result) {
            console.error('site-admin:togglePanic:error: ', error)
        }
        else {
            system.refetch()
        }
    }

    startPanic = async () => {
        let confirmed = await confirm('Är du säker?', { text: 'Är du säker på att du vill stoppa inloggning för alla kunder och kandidater?'})
        if (!confirmed) return
        confirmed = await confirm('Är du VERKLIGEN säker?', { text: 'Är du riktigt säker på att du vill stoppa inloggning för alla kunder och kandidater?'})
        if (!confirmed) return
        await this.togglePanic(true)
    }

    stopPanic = async () => {
        const confirmed = await confirm('Är du säker?', { text: 'Är du säker på att du vill tillåta inloggning för alla kunder och kandidater?'})
        if (!confirmed) return
        await this.togglePanic(false)
    }

    render() {
        const { system: { loading, systems }, panic } = this.props
        const system = systems && systems.length && systems[0]

        return (
            <MainLayout padding maxHeight scroll>
                <div>
                    <H2>Administrera webbplats</H2>
                    <FlexRow className='margin20'>
                        <FlexCol>
                        {loading &&
                            <Spinner/>
                        }
                        {system && !system.panic &&
                            <PrimaryButton onClick={this.startPanic} loading={panic.loading}>Stoppa inloggning för kunder &amp; kandidater</PrimaryButton>
                        }
                        {system && system.panic &&
                            <SecondaryButton onClick={this.stopPanic} loading={panic.loading}>Starta inloggning för kunder &amp; kandidater</SecondaryButton>
                        }
                        </FlexCol>
                    </FlexRow>
                </div>
            </MainLayout>
        )
    }
}

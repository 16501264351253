import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import inject from '_services/inject'
import { SEARCH_EMPLOYEES } from './users-ql'
import DetailedUsersResults from './detailed-results'
import { Spinner } from '_layout/form-elements';
import {
    ResultGroupHeader,
    GrowingResultWrapper,
    ArrowDown,
    ArrowUp
} from '_modules/search/common-layout/result-lists';

@compose(
    withRouter,
    inject('user'),
    graphql(SEARCH_EMPLOYEES, { name: 'employeeQuery', options: props => ({ variables: { query: props.queryInput, take: props.limit, skip: 0 } }) }),
)
export default class DetailedUsersResultGroup extends React.PureComponent {
    displayName = "UsersResultGroup"

    onFetchMoreResults() {
        const { searchEmployees, fetchMore } = this.props.employeeQuery;
        const { employees = [] } = searchEmployees || {}

        fetchMore({
            variables: {
                take: this.props.limit,
                skip: employees.length,
                query: this.props.queryInput
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    searchEmployees: {...prev.searchEmployees, employees: [...prev.searchEmployees.employees, ...fetchMoreResult.searchEmployees.employees]}
                });
            }
        });
    }

    render() {
        const { user, queryInput, isActive, onHeaderClick } = this.props;
        const { searchEmployees, loading, networkStatus } = this.props.employeeQuery
        const { total = 0, employees = [] } = searchEmployees || {}
        const isInitialLoading = networkStatus === 1 || networkStatus === 4;
        const resultProps = {
            user,
            queryInput,
            employees,
            total,
            isFetchingMore: loading,
            onFetchMoreResults: this.onFetchMoreResults.bind(this)
        }
        const components = [
            <ResultGroupHeader key="groupheader" onClick={onHeaderClick} className={ isActive ? 'active' : ''}>
                Användare 
                { !isInitialLoading && (total ? ` - ${total} ${total === 1 ? 'träff' : 'träffar'}` : " - Inga träffar") }
                { isActive ? <ArrowDown/> : <ArrowUp/> }
            </ResultGroupHeader>,
            <GrowingResultWrapper key="groupresults" className={ isActive ? 'active' : ''}>
                { isInitialLoading ? <Spinner className="center" /> : <DetailedUsersResults { ...resultProps } /> }
            </GrowingResultWrapper>
        ]
        return components
    }
}
import React from 'react'
import styled from 'styled-components'

import Modal from '_components/modal'
import { SecondaryButton, PrimaryButton } from '_root/layout/buttons';

//#region Styles
const Wrapper = styled.div`
    display: ${props => props.$inline ? 'inline-block' : 'block'};

    h2 {
        text-transform: uppercase;
    }
`
const PolicyText = styled.div`
    width: 100%;
    height: 360px;
    overflow: auto;
    padding: 20px;
    border: 1px solid var(--color-bg-grey);
    margin-bottom: 20px;
`
//#endregion

export default class Policy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false
        }
    }

    openPolicy = async () => {
        this.props.onOpen && this.props.onOpen()
        this.setState({modalOpen: true})
    }

    onModalClose = () => {
        this.setState({modalOpen: false})
    }

    render() {
      return (
        <Wrapper $inline={this.props.inline}>
          <SecondaryButton onClick={this.openPolicy} data-tooltip-id="root-tooltip" data-tooltip-place="right" data-tooltip-content="Klicka för att läsa villkoren">{this.props.children || 'Läs villkor'}</SecondaryButton>
          <p>Jobbets Avtal avseende Gemensam Personuppgiftsbehandling.</p>
          <Modal isOpen={this.state.modalOpen} onRequestClose={this.onModalClose}>
            <h2 className="tc">Allmänna villkor –<br/>rekryteringsverktyget rekryteringscenter</h2>
            <PolicyText>
              <p>
                Dessa allmänna villkor (”<strong>Allmänna Villkor</strong>”) gäller för Kunds användarkonto i och nyttjande av LS Jobbet AB:s, org.nr 556375-2822, Fyristorg 6, 753 10 Uppsala (”<strong>Jobbet</strong>”) rekryteringsverktyg Rekryteringscenter.
              </p>
              <p className="mv4 f4">
                <a href="https://api.jobbet.se/filer/cjmac925a06bd0188ht536gz2/Allm%C3%A4nna_villkor_grundtj%C3%A4nst.pdf" target='_blank' rel="noopener noreferrer">Allmänna villkor grundtjänst.pdf</a>
              </p>
            </PolicyText>
            <PrimaryButton onClick={this.onModalClose}>Stäng</PrimaryButton>
          </Modal>
        </Wrapper>
      )
    }
}

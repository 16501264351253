import React from 'react'
import withRouter from '_hoc/withRouter'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import queryString from 'query-string'
import * as EmailValidator from 'email-validator'
import { loginUser, isExpiredUser } from '_services/login-service'
import { PrimaryButton, CancelButton } from '_layout/buttons'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'
import LogoImage from '_images/rc-jobbet.png'
import { STORED_PATH } from '_root/constants'
import { SIGNIN_USER_MUTATION, FORGOT_PASSWORD_MUTATION, SYSTEM_SETTINGS } from '_root/common-ql'
import AfterLogin from '_containers/after-login'
import to from '_services/await.to'
import inject from '_services/inject'
import message from '_components/message'
import { ROOT } from "_routes/url-names";

//#region Styles
const Logo = styled.img`
  margin-top: 60px;
  width: auto;
  height: 60px;
`
const Input = styled.input`
  width: 400px;
  max-width: 400px;
  margin-bottom: 1em;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
const ForgotLink = styled.a`
    align-self: center;
    margin-left: 20px;
`
const Error = styled.p`
    margin: -1em 0 1em 0;
    font-size: 0.9em;
    color: var(--color-brand-red);
`
//#endregion

@compose(
    withApollo,
    withRouter,
    inject('user'),
    graphql(SYSTEM_SETTINGS, { name: 'system' }),
    graphql(SIGNIN_USER_MUTATION, { name: 'signinUser' }),
    graphql(FORGOT_PASSWORD_MUTATION, { name: 'forgotPassword' })
)
export default class Login extends React.Component {

    constructor(props) {
        super(props)
        const parsedQueryString = queryString.parse(props.location.search)
        this.state = {
            email: '',
            password: '',
            forgotPassword: parsedQueryString.forgotPassword === "true"
        }
    }

    onPasswordKeyPress = e => {
        const key = `${e.keyCode || e.charCode}`
        if (key === '13' || key === '10') {
            this.login()
        }
    }

    checkEmail = () => {
        this.setState({invalidEmail: !EmailValidator.validate(this.state.email)})
    }

    forgotPassword = e => {
        e.preventDefault()
        this.setState({forgotPassword: true})
        return false
    }

    sendPassword = async e => {
        e.preventDefault()
        this.setState({loadingPassword: true})
        const { email } = this.state
        const [error, result] = await to(this.props.forgotPassword({ variables: { email, fromAdmin: false } }))
        if (error) {
            console.error('sendPassword:error: ', error, email)
            message('Ett fel uppstod när lösenord skulle hanteras.')
        }
        else {
            if (!result.data.forgotPassword) console.error('login:sendPassword: ', result)
            message('Ett nytt lösenord har skickats till angiven e-postadress.')
        }
        this.setState({forgotPassword: false, loadingPassword: false})
        this.props.navigate(this.props.location.path)
        return false
    }

    login = async () => {
      const { email, password } = this.state
      if (!email || !email.trim()) return
      this.setState({loadingLogin: true})
      const [error, result] = await to(this.props.signinUser({
        variables: {
          email,
          password
        }
      }))
      if (error || !result) {
        this.setState({loadingLogin: false})
        if (error && error.message && error.message.indexOf('System stopped') > -1) {
          message('Inloggning misslyckades.', { text: 'Systemet är tillfälligt stängt för underhåll. Vänligen kontakta kundtjänst!'})
        }
        else if (error && error.message && error.message.indexOf('Disabled employer') > -1) {
          message('Inloggning misslyckades.', { text: 'Företaget har inaktiverats. Vänligen kontakta kundtjänst!'})
        }
        else if (error && error.message && error.message.indexOf('Disabled user') > -1) {
          message('Inloggning misslyckades.', { text: 'Kontot har inaktiverats. Vänligen kontakta kundtjänst!' })
        }
        else if (error && error.message && error.message.indexOf('Inactive user') > -1) {
          message('Inloggning misslyckades.', { text: 'Du har inte aktiverat ditt konto. Vänligen kontakta kundtjänst!' })
        }
        else {
          message('Inloggning misslyckades.', { text: 'Vänligen kontrollera e-post och lösenord.' })
        }
      }
      else {
        const { data: { authenticateUser }} = result
        loginUser(authenticateUser.token)
      }
    }

    render() {
        const { email, password, invalidEmail, loadingLogin, forgotPassword, loadingPassword } = this.state
        const { user, location, system } = this.props
        const panic = system.systems && system.systems[0].panic

        if (user.loggedIn) {
          let nextUrl = location.state && location.state.from && location.state.from.pathname && location.state.from.pathname !== '/' ? location.state.from.pathname : ROOT
          const storedPath = window.sessionStorage.getItem(STORED_PATH + window.name)
          if (storedPath) nextUrl = storedPath
          return <AfterLogin nextUrl={nextUrl} />
        }

        const expired = isExpiredUser()

        return <div>
          <FlexRow className='margin20 mb4'>
            <Logo src={LogoImage} alt=' Jobbet.se logotyp' title=' Jobbet.se' />
          </FlexRow>
          {panic &&
          <React.Fragment>
            <H2 className='margin20 mv3'>Systemet är tillfälligt stängt för underhåll.</H2>
            <FlexRow className='margin20 mb4'>
              <FlexCol>
                <h2 className='mt4'>Kundtjänst</h2>
                <p>Telefon: 018 - 100 112</p>
                <p>E-post: <a href='mailto:kundtjanst@jobbet.se'>kundtjanst@jobbet.se</a></p>
                <p>Adress: Fyristorg 6, 753 10 Uppsala</p>
              </FlexCol>
            </FlexRow>
          </React.Fragment>
          }
          <H2 className='margin20 mv3'>{forgotPassword ? 'Ange din e-postadress' : (panic ? 'Login (endast systemansvarig)' : 'Login')}</H2>
          {expired &&
          <FlexRow className='margin20 mt3'>
            <Error>Din session har löpt ut. Vänligen logga in på nytt.</Error>
          </FlexRow>
          }
          <FlexRow className='margin20'>
              <div>
              <Input
                autoFocus={true}
                value={email}
                onChange={(e) => this.setState({email: e.target.value.trim() })}
                onBlur={this.checkEmail}
                type='email'
                placeholder='Din e-postadress' />
              {invalidEmail &&
              <Error>Ange en fullständig e-postadress</Error>
              }
              {forgotPassword || [
              <Input
                key='passwordInput'
                value={password}
                onChange={(e) => this.setState({password: e.target.value })}
                onKeyPress={this.onPasswordKeyPress}
                type='password'
                placeholder='Ditt lösenord' />,
              <div className='flex mt3' key='loginButtonAndForgotLink'>
                <PrimaryButton className='mr2' onClick={this.login} disabled={invalidEmail} loading={loadingLogin}>Login</PrimaryButton>
                <ForgotLink href='/' onClick={this.forgotPassword}>Glömt lösenord?</ForgotLink>
              </div>
              ]}
              {forgotPassword &&
              <div className='flex mt3'>
                <CancelButton className='mr2' onClick={e => this.setState({forgotPassword: false})}>Avbryt</CancelButton>
                <PrimaryButton onClick={this.sendPassword} disabled={invalidEmail} loading={loadingPassword}>Skicka nytt lösenord</PrimaryButton>
              </div>
              }
              </div>
          </FlexRow>
        </div>
    }
}

import React from 'react'
import draftToHtml from 'draftjs-to-html';
import Modal from '_components/modal'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import { PrimaryButton, SecondaryButton, CancelButton } from '_layout/buttons'
import ctrlKey from '_utils/ctrlKey'
import confirm from '_components/confirm'
import { TemplateType } from '_root/constants';

export default class CreateDocument extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        name: '',
        type: props.type || TemplateType.INTERVIEW,
        contentHTML: null,
        selected: null,
        dirty: false,
        open: false
      }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
      if (prevState.open !== nextProps.open) {
        return {
          open: nextProps.open,
          name: '',
          contentHTML: null,
          selected: null,
          dirty: false
        }
      }
      return null
    }

    onSelected = template => {
      if (!template) return
      const contentHTML = template.contentHTML || draftToHtml(template.content)
      this.setState({name: template.name || '', type: template.type, contentHTML: contentHTML, selected: template, dirty: true})
    }

    onNameChange = e => {
      const name = e.target.value
      this.setState({name, dirty: name !== this.state.name})
    }

    onContentChange = contentHTML => {
      this.setState({contentHTML, dirty: true})
    }

    clearDocument = () => {
        this.setState({name: '', contentHTML: null, selected: null, dirty: false})
    }

    closeDocument = async () => {
      if (await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
        this.saveDocument()
      }
      this.clearDocument()
      this.props.onClose && this.props.onClose()
    }

    saveDocument = () => {
      const { name, type, contentHTML } = this.state
      if (name && contentHTML) {
        this.props.onSave && this.props.onSave({ name, type, contentHTML })
        this.setState({ dirty: false })
      }
    }

    render() {
        const { open, title, templates } = this.props
        const { name, contentHTML, type, selected, dirty } = this.state
        const filteredTemplates = templates.filter(t => t.type === type)
        const canSave = !!name && !!contentHTML && dirty
        return (
            <Modal isOpen={open} onRequestClose={this.closeDocument}>
                <h3>{title || 'Ny mall'}</h3>
                <ObjectPicker
                  form={'true'}
                  className='mb3'
                  values={filteredTemplates}
                  placeholder='Utgå från mall (frivilligt)...'
                  current={selected}
                  onSelected={this.onSelected}
                  noMatch='Inga träffar' />
                <input
                  type='text'
                  className='mb3 w-100'
                  placeholder='Spara mall som...'
                  value={name}
                  onChange={this.onNameChange} />
                <TinyEditor
                  key={selected && selected.id}
                  form
                  insert='bold italic underline | bullist numlist | link image table'
                  selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
                  plugins='autolink link lists image table'
                  content={contentHTML}
                  placeholder={'Fyll i ditt innehåll här... (klistra in med ' + ctrlKey + '+V)'}
                  onChange={this.onContentChange}
                  onDirty={this.props.onDirty}
                  style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(90vh - 260px)', minHeight: 300 }} />
                <div className="tr">
                  <CancelButton className='mt2 mr3 invert' onClick={this.closeDocument}>Stäng</CancelButton>
                  <SecondaryButton className='mt2 mr3' onClick={this.clearDocument}>Rensa</SecondaryButton>
                  <PrimaryButton className='mt2' onClick={this.saveDocument} disabled={!canSave}>Spara</PrimaryButton>
                </div>
            </Modal>
        )
    }
}

import React from "react"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import draftToHtml from "draftjs-to-html"
import { format, parseISO, isValid, differenceInCalendarDays } from "date-fns"
import map from "lodash/fp/map"
import isUndefined from "lodash/fp/isUndefined"
import { PrimaryHrefButton } from "_layout/buttons"
import { Spinner, EditIcon } from "_layout/form-elements"
import EditIconSrc from "_images/edit-icon.svg"
import Modal from "_components/modal"
import HeadingEditor from "_components/heading-editor"
import IngressEditor from "_components/ingress-editor"
import TinyEditor from "_components/tiny-editor"
import { AdLayout, RecruitmentState } from "_root/constants"

//#region Styles
const Layout = styled.div`
  max-width: 1100px;
  border: ${props => (props.$border ? "1px solid " + "var(--color-bg-dark)" : "none")};
  background: ${props => (props.$white ? "var(--color-bg-white)" : "none")};
`
const ImageWrapper = styled.div`
  display: inline;
  position: relative;
  cursor: ${props => (props.readOnly ? "default" : "pointer")};
  font-size: 0;
  line-height: 0;

  &.column {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 4;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  &.inline {
    display: block;
    overflow: hidden;
    max-width: 300px;
    height: auto;
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (min-width: 890px) {
      overflow: visible;
      position: relative;
      float: right;
      clear: right;
      max-width: 470px;
      margin: unset;
      margin-bottom: 2rem;
    }

    &:not([readonly]) {
      text-align: right;
      width: 45%;
      max-width: 360px;
      box-shadow: inset 0 0 10px 4px var(--color-bg-grey);

      &:before {
        content: "";
        display: block;
        content: "";
        width: 100%;
        padding-top: 66.66%;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 10px 4px var(--color-bg-grey);
  }

  &:after {
    content: "";
    display: ${props => (props.readOnly ? "none" : "block")};
    position: absolute;
    top: 12px;
    right: 12px;
    width: 34px;
    height: 34px;
    background: url(${EditIconSrc}) no-repeat;
  }
`
const ApplicationDetails = styled.div`
  max-width: 300px;
  text-align: center;
  box-shadow: 0 0 30px 4px var(--color-bg-grey);
  padding: 40px;
  margin: 0 auto 40px;

  @media screen and (min-width: 890px) {
    width: 300px;
    float: right;
    clear: right;
  }

  .relative {
    position: relative;
  }

  span {
    font-size: 0.875em;
    display: inline-block;
    text-align: center;
  }

  img {
    display: block;
    margin: 15px auto 15px;
    max-width: 150px;
    height: auto;
  }

  dl {
    color: var(--color-text);
    font-size: 0.875em;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-top: 1rem;
    border-top: 1px solid var(--color-line);

    dt {
      font-weight: bold;
    }

    dd {
      margin-left: 0;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.error {
        color: var(--color-error);
      }
    }
  }
`
const StyledHeadingEditor = styled(HeadingEditor)`
  color: #000;
  font-size: 1.875rem;
  margin-bottom: 20px;
`
const StyledIngressEditor = styled(IngressEditor)`
  color: #666;
  font-weight: 700;
  margin-bottom: 20px;
`
const AdContent = styled.div`
  flex: 1 0 auto;
  padding: 40px 40px 0 40px;
  min-height: 100vh;
`
const MainContent = styled.div`
  max-width: 700px;
  color: var(--color-text);

  @media screen and (min-width: 890px) {
    margin-right: 320px;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 400px;
  }

  h1 {
    margin: 0;
    font-size: 1.87rem;
    font-weight: 400;
    line-height: 1.13em;
  }

  .ingress {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.37em;
    margin-bottom: 20px;
  }

  &.inline ${StyledIngressEditor}:not([readonly]) {
    min-height: 90px;
  }
`
const AdSection = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .public-DraftStyleDefault-block {
    margin: 0 0 1.5rem 0;
  }

  h3 {
    margin-top: 1em;
    margin-bottom: 10px;
    color: var(--color-text);
    font-size: 1.125rem;
    line-height: 1;
  }

  p,
  ul {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
`
const AdCol = styled.div`
  display: inline-block;
  margin-bottom: 40px;
  width: 48%;
  vertical-align: top;

  &:first-child {
    margin-right: 4%;
  }

  .ad-requirements,
  .ad-merits {
    ul {
      margin: 1rem 0 1.5rem 0;
      padding-left: 20px;
    }
  }
`
const Apply = styled.div`
  padding: 40px;
`
const CompanyInfo = styled.div`
  font-size: 0.875em;
  color: #666;
  margin-bottom: 30px;

  strong {
    font-size: 1rem;
  }

  h3 {
    margin: 0;
    font-size: 1.12rem;
    line-height: 1.33em;
    font-weight: 400;
  }

  p {
    font-size: 0.87rem;
    line-height: 1.28em;
    margin: 1rem 0;
    flex: 0 1 auto;
  }

  @media screen and (min-width: 890px) {
    width: calc(100% - 320px);
  }

  @media screen and (min-width: 1100px) {
    width: calc(100% - 400px);
    max-width: 700px;
  }
`
const Gallery = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 10px;
    padding: 5px;
    border: 1px solid var(--color-bg-grey);
    background: var(--color-bg-bright);

    &:hover {
      background: var(--color-bg-white);
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`
const CompanyLogo = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 10px;
  padding: 5px;
  border: 1px solid var(--color-bg-grey);
  background: var(--color-bg-bright);

  &:hover {
    background: var(--color-bg-white);
  }
`
//#endregion Styles

@withRouter
export default class JobAdEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jobAd: JobAdEditor.getHtmlAd(props.jobAd),
      modalOpen: false,
      logoModalOpen: false,
      editorImages: props.company.gallery.map(image => ({ title: image.description || image.name, value: image.url })),
    }
  }

  static getHtmlAd = jobAd => {
    const title = jobAd.recruitment ? jobAd.recruitment.title : ""
    const logotype = jobAd.logotype || jobAd.recruitment.company.logotype
    const textHTML = jobAd.textHTML || (jobAd.text ? draftToHtml(JSON.parse(jobAd.text)) : null)
    const meritsHTML = jobAd.meritsHTML === null ? (jobAd.merits ? draftToHtml(JSON.parse(jobAd.merits)) : null) : jobAd.meritsHTML
    const requirementsHTML = jobAd.requirementsHTML === null ? (jobAd.requirements ? draftToHtml(JSON.parse(jobAd.requirements)) : null) : jobAd.requirementsHTML
    return { ...jobAd, title, logotype, textHTML, meritsHTML, requirementsHTML }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.jobAd) return null
    if (prevState.jobAd) {
      if (prevState.jobAd.layout !== nextProps.jobAd.layout) {
        return { jobAd: { ...prevState.jobAd, layout: nextProps.jobAd.layout } }
      }
      if (prevState.jobAd.recruitment.applyStop !== nextProps.jobAd.recruitment.applyStop) {
        return { jobAd: { ...prevState.jobAd, recruitment: nextProps.jobAd.recruitment } }
      }
      return null
    }
    return { jobAd: JobAdEditor.getHtmlAd(nextProps.jobAd) }
  }

  save = jobAd => {
    this.setState({ modalOpen: false, logoModalOpen: false, jobAd }, () => {
      this.props.onSave && this.props.onSave(jobAd)
    })
  }

  onAdTitleChange = adTitle => {
    this.save({ ...this.state.jobAd, title: adTitle })
  }

  onAdIngressChange = adIngress => {
    this.save({ ...this.state.jobAd, ingress: adIngress })
  }

  onAdTextChange = adHtml => {
    this.save({ ...this.state.jobAd, textHTML: adHtml })
  }

  onRequirementsChange = requirementsHTML => {
    this.save({ ...this.state.jobAd, requirementsHTML })
  }

  onMeritsChange = meritsHTML => {
    this.save({ ...this.state.jobAd, meritsHTML })
  }

  onModalClose = e => {
    this.setState({ modalOpen: false })
  }

  onLogoModalClose = e => {
    this.setState({ logoModalOpen: false })
  }

  onImageSelected = image => {
    this.save({ ...this.state.jobAd, image })
  }

  onSelectLogotype = () => {
    this.setState({ logoModalOpen: true })
  }

  onLogoSelected = logotype => {
    if (!logotype || (this.state.jobAd.logotype && logotype.id === this.state.jobAd.logotype.id)) return
    this.save({ ...this.state.jobAd, logotype })
  }

  render() {
    const { company, readOnly, border, white } = this.props

    if (!company || !this.state.jobAd) {
      return (
        <Layout className={AdLayout.NOIMAGE}>
          <Spinner />
        </Layout>
      )
    }

    const { jobAd, editorImages } = this.state

    const layoutClass = jobAd.layout || AdLayout.NOIMAGE

    let adImage =
      jobAd.image && jobAd.image.url ? (
        <img className="hej" src={jobAd.image.url} readOnly={readOnly} alt={jobAd.image.description || "Annonsbild"} title={jobAd.image.description} />
      ) : (
        <span>{readOnly ? "Bild saknas" : "Klicka här för att välja bild."}</span>
      )

    const applyableDate = differenceInCalendarDays(parseISO(jobAd.recruitment.applyStop), new Date()) > -1
    const applyable = applyableDate && jobAd.recruitment.state === RecruitmentState.ACTIVE
    const applyUrl = jobAd.recruitment.applyUrl || `/ansök/${jobAd.recruitment.id}`
    const applyStop = parseISO(jobAd.recruitment.applyStop)

    const images =
      !readOnly && company
        ? map(image => {
            return (
              <li key={image.id} title={image.name} onClick={e => this.onImageSelected(image)}>
                <img src={image.url} alt={image.description || image.name} title={image.description || image.name} />
              </li>
            )
          })(company.gallery)
        : null

    const logotypes =
      !readOnly && company
        ? map(image => {
            return (
              <li key={image.id} title={image.name} onClick={e => this.onLogoSelected(image)}>
                <img src={image.url} alt={image.description || image.name} title={image.description || image.name} />
              </li>
            )
          })(company.gallery)
        : null

    const logotype = jobAd.logotype || (company && company.logotype)

    return (
      <Layout className={layoutClass} $border={border} $white={white}>
        {!readOnly && (
          <React.Fragment>
            <Modal isOpen={this.state.modalOpen} onRequestClose={this.onModalClose}>
              {images && images.length ? (
                <div>
                  <h3>Välj en bild ur bildbanken</h3>
                  <Gallery>{images}</Gallery>
                </div>
              ) : (
                <p>Inga bilder kunde hittas</p>
              )}
            </Modal>
            <Modal isOpen={this.state.logoModalOpen} onRequestClose={this.onLogoModalClose}>
              {logotypes && logotypes.length ? (
                <div>
                  <h3>Välj en bild ur bildbanken</h3>
                  <Gallery>{logotypes}</Gallery>
                </div>
              ) : (
                <p>Inga bilder kunde hittas</p>
              )}
              {company.logotype && logotype && company.logotype.id !== logotype.id && (
                <div className="mt3">
                  <h3>Eller använd företagets logotyp</h3>
                  <CompanyLogo title={company.logotype.name} onClick={e => this.onLogoSelected(company.logotype)}>
                    <img src={company.logotype.url} alt={company.logotype.name} title={company.logotype.name} />
                  </CompanyLogo>
                </div>
              )}
            </Modal>
          </React.Fragment>
        )}
        {layoutClass === AdLayout.COLUMN && (
          <ImageWrapper onClick={e => !readOnly && this.setState({ modalOpen: true })} className={AdLayout.COLUMN} readOnly={readOnly}>
            {adImage}
          </ImageWrapper>
        )}
        <AdContent className={layoutClass}>
          {layoutClass === AdLayout.INLINE && (
            <ImageWrapper readOnly={readOnly} onClick={e => !readOnly && this.setState({ modalOpen: true })} className={AdLayout.INLINE}>
              {adImage}
            </ImageWrapper>
          )}
          <ApplicationDetails>
            {readOnly && applyable && (
              <PrimaryHrefButton href={applyUrl} target="_blank" rel="opener">
                Sök detta jobb
              </PrimaryHrefButton>
            )}
            {!applyableDate && <span>Sista ansökningsdag har passerat</span>}
            <div className="relative">
              {logotype && logotype.url && <img src={logotype.url} alt={company.name} />}
              {!readOnly && <EditIcon onClick={this.onSelectLogotype} title="Klicka för att välja bild" />}
            </div>
            <dl>
              <dt>Arbetsgivare</dt>
              <dd>{company.name}</dd>
              <dt>Region</dt>
              <dd>{jobAd.recruitment.regions.map(r => r.name).join(", ")}</dd>
              <dt>Bransch</dt>
              <dd>{jobAd.recruitment.occupationalAreas.map(r => r.name).join(", ")}</dd>
              <dt>Sista ansökningsdag</dt>
              {applyStop && isValid(applyStop) ? <dd>{format(applyStop, "yy-MM-dd")}</dd> : <dd className="error">Datum saknas</dd>}
            </dl>
          </ApplicationDetails>
          {company.description && (
            <CompanyInfo>
              <h3>Om arbetsgivaren - {company.name}</h3>
              <p dangerouslySetInnerHTML={{ __html: company.description.replaceAll("\n", "<br/>") }} />
            </CompanyInfo>
          )}
          <MainContent className={layoutClass}>
            {readOnly && jobAd.title && <h1>{jobAd.title}</h1>}
            {readOnly || <StyledHeadingEditor placeholder="Skriv annonsrubrik här..." text={jobAd.title} onChange={this.onAdTitleChange} onDirty={this.props.onDirty} readOnly={readOnly} />}
            {readOnly && jobAd.ingress && <p className="ingress">{jobAd.ingress}</p>}
            {readOnly || <StyledIngressEditor placeholder="Skriv ingress här..." text={jobAd.ingress} onChange={this.onAdIngressChange} onDirty={this.props.onDirty} readOnly={readOnly} />}
            <AdSection>
              {readOnly && jobAd.textHTML && <div dangerouslySetInnerHTML={{ __html: jobAd.textHTML }}></div>}
              {!readOnly && (
                <TinyEditor
                  form
                  insert="bold italic underline | bullist numlist | link table rcImageGallery"
                  selection="h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink rcImageGallery | removeformat"
                  plugins="autolink link lists table"
                  content={jobAd.textHTML}
                  placeholder={"Skriv annonstext här..."}
                  onChange={this.onAdTextChange}
                  onDirty={this.props.onDirty}
                  images={editorImages}
                  style={{ width: "700px", maxWidth: "100%", height: "100vh", maxHeight: "calc(90vh - 260px)", minHeight: 300 }}
                />
              )}
            </AdSection>
            <AdSection>
              {(jobAd.requirementsHTML || !readOnly) && (
                <AdCol>
                  <h3>Krav</h3>
                  {readOnly && jobAd.requirementsHTML && <div className="ad-requirements" dangerouslySetInnerHTML={{ __html: jobAd.requirementsHTML }}></div>}
                  {!readOnly && (
                    <TinyEditor
                      content={jobAd.requirementsHTML}
                      bullets={isUndefined(jobAd.requirementsHTML) || jobAd.requirementsHTML === null ? ["…", "…"] : null}
                      insert="bold italic underline | bullist numlist | link"
                      plugins="autolink link lists"
                      selection="h3 bold italic underline | bullist numlist outdent indent | link unlink | removeformat"
                      onChange={this.onRequirementsChange}
                      onDirty={this.props.onDirty}
                      readOnly={false}
                      style={{ maxWidth: "100%", height: 300, maxHeight: 300, minHeight: 300 }}
                    />
                  )}
                </AdCol>
              )}
              {(jobAd.meritsHTML || !readOnly) && (
                <AdCol>
                  <h3>Meriterande</h3>
                  {readOnly && jobAd.meritsHTML && <div className="ad-merits" dangerouslySetInnerHTML={{ __html: jobAd.meritsHTML }}></div>}
                  {!readOnly && (
                    <TinyEditor
                      content={jobAd.meritsHTML}
                      bullets={isUndefined(jobAd.meritsHTML) || jobAd.meritsHTML === null ? ["…", "…"] : null}
                      insert="bold italic underline | bullist numlist | link"
                      selection="h3 bold italic underline | bullist numlist outdent indent | link unlink | removeformat"
                      onChange={this.onMeritsChange}
                      onDirty={this.props.onDirty}
                      readOnly={false}
                      style={{ maxWidth: "100%", height: 300, maxHeight: 300, minHeight: 300 }}
                    />
                  )}
                </AdCol>
              )}
            </AdSection>
          </MainContent>
        </AdContent>
        {readOnly && (
          <Apply>
            {applyable ? (
              <PrimaryHrefButton href={applyUrl} target="_blank" rel="opener">
                Sök detta jobb
              </PrimaryHrefButton>
            ) : (
              <span>Denna tjänst är inte öppen för ansökningar.</span>
            )}
          </Apply>
        )}
      </Layout>
    )
  }
}

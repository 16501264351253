import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, gql } from '@apollo/client'
import authLink from './links/auth-link'
import errorLink from './links/error-link'
import WindowBroadcastLink from './links/window-broadcast-link/'
import { REC_STATS_ID } from '_root/constants'

// TODO: Remove in prod:
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
// loadDevMessages();
// loadErrorMessages();

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_ENDPOINT,
})

const client = new ApolloClient({
  dataIdFromObject: o => o.id,
  link: ApolloLink.from([
    new WindowBroadcastLink(window),
    authLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  resolvers: {},
})

client.writeQuery({
  query: gql`
    query RecruitmentStats {
      recruitmentStats {
        id
        countRecruitmentsIsStale
        activeRecruitmentsIsStale
        draftRecruitmentsIsStale
        closedRecruitmentsIsStale
      }
    }`,
  data: {
    recruitmentStats: {
      __typename: 'RecruitmentStats',
      id: REC_STATS_ID,
      countRecruitmentsIsStale: false,
      activeRecruitmentsIsStale: false,
      draftRecruitmentsIsStale: false,
      closedRecruitmentsIsStale: false
    }
  },
})

export default client

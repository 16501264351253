import React from "react"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { format, parseISO } from "date-fns"
import { Spinner } from "_layout/form-elements"
import { CANDIDATE_INTERVIEWS, DECLINE_INTERVIEWS, UPDATE_INTERVIEW_STATUS, UPDATE_INTERVIEW_STATUS_SERVER } from "_containers/interview/interview-ql"
import { getConfirmInterviewMailContent, getBookedInterviewMailContent } from "_modules/recruitments/services/mailtemplate-service"
import withSendmailMutation from "_hoc/withSendmailMutation"
import inject from "_services/inject"
import confirm from "_components/confirm"
import message from "_components/message"
import to from "_services/await.to"
import MemberLayout from "./member-layout"

//#region Styles
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
const Applications = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin-bottom: 1rem;

    & + li {
      padding-top: 2rem;
      border-top: 1px solid var(--color-line);
    }
  }
`
const Slots = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`
const Slot = styled.li`
  display: inline-block;
  margin: 0 20px 20px 0;
  padding: 20px;
  border: 1px solid var(--color-line);
  background: var(--color-bg-white);
  box-shadow: 5px 5px 5px var(--color-line);
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  text-decoration: ${props => (props.disabled ? "line-through" : "none")};
  text-align: center;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 5px var(--color-bg-grey);
  }
`
const Location = styled.div`
  margin-top: 1rem;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
//#endregion

@compose(
  withApollo,
  withRouter,
  withSendmailMutation,
  inject("user"),
  graphql(CANDIDATE_INTERVIEWS, { name: "interviewsQuery", options: props => ({ variables: { member: props.user.id, now: new Date().toISOString() } }) }),
  graphql(DECLINE_INTERVIEWS, { name: "declineStatus" }),
  graphql(UPDATE_INTERVIEW_STATUS, { name: "updateStatus" }),
  graphql(UPDATE_INTERVIEW_STATUS_SERVER, { name: "updateInterviewStatus" })
)
export default class MemberBooking extends React.Component {
  componentDidMount() {
    this.props.updateInterviewStatus()
  }

  acceptInvitation = async e => {
    const id = e.currentTarget.dataset.slot
    const applicationId = e.currentTarget.dataset.app
    const queryOptions = {
      query: CANDIDATE_INTERVIEWS,
      fetchPolicy: "network-only",
      variables: { member: this.props.user.id, now: new Date().toISOString() },
    }
    const [error, result] = await to(this.props.client.query(queryOptions))
    if (error || !result || !result.data) {
      await message('Tyvärr uppstod ett tekniskt fel vid bokningen.<br/><br/>Vänligen kontakta kundtjänst:<br/>018 - 100 112 eller <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>')
      return
    }
    const { applicationInterviews } = result.data
    const { user, sendMail, updateStatus, declineStatus } = this.props
    const accept = applicationInterviews.find(ai => ai.id === id)
    if (accept.interview.vacantSpots === 0) {
      await message("Tyvärr har tiden redan blivit bokad. Vänligen välj en annan tid.")
      this.props.interviewsQuery.refetch()
      return
    }
    const time = format(parseISO(accept.interview.startTime), "dd MMM HH:mm")
    const confirmed = await confirm("Vill du boka möte den " + time + "?")
    if (confirmed) {
      const [errorBooked, resultBooked] = await to(updateStatus({ variables: { id: id, status: "BOOKED" } }))
      if (errorBooked) {
        await message('Tyvärr uppstod ett tekniskt fel vid bokningen.<br/><br/>Vänligen kontakta kundtjänst:<br/>018 - 100 112 eller <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>')
      } else if (resultBooked) {
        await to(declineStatus({ variables: { applicationId: applicationId } }))
        const sender = accept.interview.company.name
        const jobDescription = accept.application.recruitment.title
        const location = accept.interview.location
        const calendarUrl = process.env.SERVER_URL + "ics/" + accept.interview.id + "/single"
        this.props.interviewsQuery.refetch()
        const [err] = await to(
          sendMail({
            from: "noreply@jobbet.se",
            to: user.email,
            subject: "Bekräftelse för bokat möte den " + time,
            html: getConfirmInterviewMailContent({
              user,
              jobDescription,
              time,
              location,
              sender,
              calendarUrl,
            }),
            showLogo: false,
            showFooter: true,
          })
        )
        err && console.error("acceptInvitation: ", err)
        const emails = accept.interview.interviewers.concat(accept.interview.interviewersExternal).filter(i => i.acceptInterviewEmail !== false)
        accept.interview.guest && accept.interview.guest.split(",").forEach(g => emails.push({ email: g, firstName: g }))
        emails.forEach(async interviewer => {
          const [errorMail] = await to(
            sendMail({
              from: "noreply@jobbet.se",
              to: interviewer.email,
              subject: "Bekräftelse för bokat möte den " + time,
              html: getBookedInterviewMailContent({
                user,
                jobDescription,
                time,
                location,
                interviewer,
                sender,
                calendarUrl,
              }),
              showFooter: true,
            })
          )
          errorMail && console.error("acceptInvitation: ", errorMail)
        })
      }
    }
  }

  render() {
    const {
      interviewsQuery: { loading, applicationInterviews = [] },
    } = this.props
    const reducer = (apps, a) => {
      const {
        title,
        company: { name },
      } = a.application.recruitment
      const key = title + " - " + name
      if (!apps.hasOwnProperty(key)) apps[key] = []
      if (a.status === "INVITED" || a.status === "BOOKED") apps[key].push(a)
      return apps
    }
    const interviews = applicationInterviews.reduce(reducer, {})

    return (
      <MemberLayout>
        <Section>
          <H1>Boka tid för möte</H1>
        </Section>
        {loading && (
          <Section>
            <Spinner />
          </Section>
        )}
        {!loading && !applicationInterviews.length && (
          <Section>
            <Ingress>Just nu har du inga inbjudningar.</Ingress>
          </Section>
        )}
        {!loading && !!applicationInterviews.length && (
          <Section>
            <Ingress>Nedan kan du svara på mötesinbjudan. Klicka på den tid du vill boka.</Ingress>
            <Applications>
              {Object.keys(interviews).map(key => (
                <li key={key}>
                  <h3>{key}</h3>
                  {interviews[key].find(i => i.status === "BOOKED") && (
                    <p>Du har accepterat mötesinbjudan den {format(parseISO(interviews[key].find(i => i.status === "BOOKED").interview.startTime), "dd MMM HH:mm")}</p>
                  )}
                  {!interviews[key].find(i => i.status === "BOOKED") && (
                    <Slots>
                      {interviews[key].map(i => (
                        <Slot key={i.id} onClick={this.acceptInvitation} data-app={i.application.id} data-slot={i.id} disabled={i.interview.vacantSpots === 0}>
                          {format(parseISO(i.interview.startTime), "dd MMM HH:mm")}
                          <Location data-tooltip-id="root-tooltip" data-tooltip-content={i.interview.location}>
                            {i.interview.location}
                          </Location>
                        </Slot>
                      ))}
                    </Slots>
                  )}
                </li>
              ))}
            </Applications>
          </Section>
        )}
      </MemberLayout>
    )
  }
}

import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'
import queryString from 'query-string'
import { debounce } from 'lodash'

import { SOK } from '_routes/url-names'

import { COMPANY_QUERY } from '_containers/settings/company-profile-ql'

import inject from '_services/inject'
import SearchIconSrc from '_images/search.svg'
import { UserRoles } from '_root/constants'

import SimpleCandidatesResultGroup from './candidates/simple-resultgroup'
import SimpleCompaniesResultGroup from './companies/simple-resultgroup'
import SimpleGroupResultGroup from './groups/simple-resultgroup'
import SimpleRecruitmentsResultGroup from './recruitments/simple-resultgroup'
import SimpleUsersResultGroup from './users/simple-resultgroup'

//#region Styles
const Content = styled.div`
  display: ${props => props.$open ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-bg-white);
  z-index: 20000;

  @media screen and (max-width: 767px) {
    padding-left: 65px;
  }
`
const SearchInput = styled.input`
  text-align: left;
  font-weight: 200;
  margin: 0 20px;
  padding: 10px 5px;
  width: 550px;
  border: 2px solid var(--color-line-dark);
  border-radius: 4px;
  background: url(${SearchIconSrc}) scroll 98% 50% no-repeat;
  background-size: 18px;
  background-color: var(--color-bg-lighter);
`
const SearchResult = styled.div`
  position: absolute;
  top: 50px;
  min-width: 550px;
  border-left: 1px solid var(--color-bg-light);
  border-right: 1px solid var(--color-bg-light);
  background: var(--color-bg-white);
  z-index: 11000;

  h4 {
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: 767px) {
    top: 64px;
    left: 0;
    width: 100%;
    min-width: unset;
    border: none;
  }
`
const ShowAllNav = styled(NavLink)`
    display: block;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
`
//#endregion

@compose(
    withRouter,
    inject('user'),
    graphql(COMPANY_QUERY, {
        name: 'companyQuery',
        options: props => ({ variables: { urlName: props.match.params.company } }),
        props: ({ companyQuery: { company } }) => ({
            currentCompany: company
        })
    }),
    onClickOutside
)
export default class SearchField extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        open: false,
        input: '',
        query: ''
      }
      this.onSearchInputDebounced = debounce(function (query) {
        const queryInput = query && query.trim().replace(/"/g, '')
        this.setState({ open: !!queryInput, query: queryInput })
      }, 250)
    }

    // componentDidMount() {
    //   this.unlisten = this.props.history.listen(() => {
    //     this.setState({ open: false, query: '' })
    //   })
    // }

    // componentWillUnmount() {
    //     this.unlisten()
    // }

    componentDidUpdate = () => {
      this.input && this.input.focus()
    }

    handleClickOutside = e => {
      if (!this.state.open) return
      this.onClose()
    }

    handleClickWrapper = e => {
      if (!this.state.open || e.target.id !== 'searchWrapper') return
      this.onClose()
    }

    onClose = () => {
        this.setState({ query: '', input: '', open: false })
        this.props.onClose && this.props.onClose()
    }

    onSearchInput = e => {
        e.persist();
        const input = e.target.value.replace(/"/g, '')
        this.setState({ input })
        this.onSearchInputDebounced(input)
    }

    onFocus = () => {
        this.setState({focus: true})
    }

    onBlur = () => {
        this.setState({focus: false})
        this.props.onSearchClose && this.props.onSearchClose()
    }

    render() {
        const { focus, open, query, input } = this.state
        const { match: { params: routeParams }, user, currentCompany, search } = this.props
        const isOpen = search || open || focus
        const openClass = isOpen ? 'open' : null
        const isViewingJobbetCompany = ( routeParams.company && routeParams.company === "jobbet.se" )
        const isSuperAdminOrSiteAdmin = [UserRoles.SUPERADMIN, UserRoles.SITEADMIN].indexOf(user.role) > -1
        const isGroupUser = isSuperAdminOrSiteAdmin || [UserRoles.GROUPADMIN, UserRoles.GROUPRECRUITER, UserRoles.GROUPVIEWER].includes(user.role)
        const isAdmin = user.role === UserRoles.ADMINISTRATOR
        const isViewer = [UserRoles.GROUPVIEWER, UserRoles.VIEWER].indexOf(user.role) > -1
        const commonProps = { limit: 3, queryInput: query, currentCompany, onClose: this.onClose }

        return (
            <Content id='searchWrapper' $open={isOpen} onClick={this.handleClickWrapper}>
                <SearchInput
                  type='text'
                  ref={ref => this.input = ref}
                  placeholder='Sök...'
                  className={openClass}
                  value={input}
                  onFocus={this.onFocus}
                  onChange={this.onSearchInput}
                  onBlur={this.onBlur}
                  open={isOpen}
                  $focus={focus} />
                {open &&
                    <SearchResult open={open}>
                        { !isViewer && <SimpleCandidatesResultGroup {...commonProps} /> }
                        { isViewingJobbetCompany && isSuperAdminOrSiteAdmin && <SimpleCompaniesResultGroup {...commonProps} /> }
                        { user.companyView.group && isGroupUser && <SimpleCompaniesResultGroup {...commonProps} /> }
                        { isViewingJobbetCompany && isSuperAdminOrSiteAdmin && <SimpleGroupResultGroup {...commonProps} /> }
                        { (isSuperAdminOrSiteAdmin || isAdmin) && <SimpleUsersResultGroup {...commonProps} /> }
                        <SimpleRecruitmentsResultGroup {...commonProps} />
                        <ShowAllNav end to={{ pathname: `/${routeParams.company}${SOK}`, search: queryString.stringify({query}) }}>Visa alla &gt;</ShowAllNav>
                    </SearchResult>
                }
            </Content>
        )
    }
}
import React from 'react';
import styled from 'styled-components'
import spinner from '_images/spinner.gif'
import { Checkbox } from '_layout/form-elements'
import { Link } from 'react-router-dom'
import isUndefined from 'lodash/fp/isUndefined'

const StyledPrimaryButton = styled.button`
  position: relative;
  height: 36px;
  padding: 0 32px;
  border: none;
  border-radius: 21px;
  font-size: 0.9rem;
  color: var(--color-text-white);
  background-color: var(--color-brand-red);
  outline: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background-color: var(--color-brand-red-dark);
  }

  .spinner {
    position: absolute;
    right: 10px;
    max-width: 16px;
    height: auto;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  @media screen and (max-width: 767px) {
    height: 32px;
    padding: 0 20px;

    &:hover {
      background-color: var(--color-brand-red);
    }
  }
`

const StyledPrimaryLinkButton = styled(Link)`
  position: relative;
  height: 36px;
  padding: 0 32px;
  border: none;
  border-radius: 21px;
  font-size: 0.9rem;
  color: var(--color-text-white);
  background-color: var(--color-brand-red);
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background-color: var(--color-brand-red-dark);
  }

  .spinner {
    position: absolute;
    right: 10px;
    max-width: 16px;
    height: auto;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  @media screen and (max-width: 767px) {
    height: 32px;
    padding: 0 20px;
  }
`

const StyledPrimaryHrefButton = styled.a`
  position: relative;
  height: 36px;
  padding: 0 32px;
  border: none;
  border-radius: 21px;
  font-size: 0.9rem;
  color: var(--color-text-white);
  background-color: var(--color-brand-red);
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background-color: var(--color-brand-red-dark);
  }

  .spinner {
    position: absolute;
    right: 10px;
    max-width: 16px;
    height: auto;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  @media screen and (max-width: 767px) {
    height: 32px;
    padding: 0 20px;
  }
`

const StyledSecondaryButton = styled(StyledPrimaryButton)`
  border-radius: 18px;
  background-color: ${props => props.$black ? 'var(--color-bg-dark)' : 'var(--color-brand-green)'};

  &:hover {
    background-color: ${props => props.$black ? 'var(--color-brand-black-hover)' : 'var(--color-brand-green-dark)'};
    background-color: var(--color-brand-green-dark);
  }

  .spinner {
    max-width: 16px;
    height: auto;
  }
`

const StyledSecondaryInvertButton = styled(StyledPrimaryButton)`
    font-weight: 700;
    color: var(--color-brand-green);
    border: 2px solid var(--color-brand-green);
    border-radius: 18px;
    background-color: var(--color-bg-white);

    &:hover {
      color: var(--color-text-white);
      background-color: var(--color-brand-green);
    }

    .spinner {
      max-width: 16px;
      height: auto;
    }
`

const StyledPrimaryInvertButton = styled(StyledPrimaryButton)`
  font-weight: 700;
  color: var(--color-brand-red);
  border: 2px solid var(--color-brand-red);
  border-radius: 18px;
  background-color: var(--color-bg-white);

  &:hover {
    color: var(--color-text-white);
    background-color: var(--color-brand-red);
  }

  .spinner {
    margin-left: 10px;
    max-width: 16px;
    height: auto;
  }
`
const StyledWhiteButton = styled(StyledPrimaryButton)`
  font-weight: 700;
  color: var(--color-text-dark);
  border: 2px solid var(--color-bg-dark);
  border-radius: 18px;
  background-color: var(--color-bg-white);

  &:hover {
    color: var(--color-text-white);
    background-color: var(--color-brand-red);
  }

  .spinner {
    margin-left: 10px;
    max-width: 16px;
    height: auto;
  }
`

const StyledPrimaryInvertLinkButton = styled(StyledPrimaryLinkButton)`
  color: var(--color-text);
  font-weight: 700;
  border: 2px solid var(--color-brand-red);
  background-color: var(--color-bg-white);

  &:hover {
    background-color: var(--color-bg-lightest);
  }
`
const StyledPrimaryInvertHrefButton = styled(StyledPrimaryHrefButton)`
  font-weight: 700;
  color: var(--color-text);
  border: 2px solid var(--color-brand-red);
  background-color: var(--color-bg-white);

  &:hover {
    background-color: var(--color-bg-lightest);
  }
`

const StyledSecondaryLinkButton = styled(StyledPrimaryLinkButton)`
    border-radius: 18px;
    background-color: var(--color-brand-green);

    &:hover {
      background-color: var(--color-brand-green-dark);
    }

    .spinner {
      max-width: 16px;
      height: auto;
    }
`

const StyledSecondaryHrefButton = styled(StyledPrimaryHrefButton)`
  border-radius: 18px;
  background-color: var(--color-brand-green);

  &:hover {
    background-color: var(--color-brand-green-dark);
  }

  .spinner {
    max-width: 16px;
    height: auto;
  }
`

const StyledDeleteButton = styled(StyledPrimaryButton)`
    font-weight: 700;
    border: 2px solid var(--color-brand-red);
    border-radius: 18px;
    color: var(--color-brand-red);
    background-color: var(--color-bg-white);

    &:hover {
      background-color: var(--color-bg-lightest);
    }

    .spinner {
      max-width: 16px;
      height: auto;
    }
`

const StyledCancelButton = styled(StyledSecondaryButton)`
  border-radius: 18px;
  background-color: var(--color-bg-dark);

  &:hover {
    background-color: var(--color-menu-five);
  }
`

const StyledCancelLinkButton = styled(StyledSecondaryLinkButton)`
  border-radius: 18px;
  background-color: var(--color-bg-dark);

  &:hover {
    background-color: var(--color-menu-five);
  }
`

const StyledCheckBoxButton = styled(StyledSecondaryButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-white);
  border: 2px solid var(--color-brand-green);
  color: var(--color-brand-green);
  font-weight: 700;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    background-color: var(--color-brand-green-dark);
    color: var(--color-text-white)
  }

  &[disabled] {
    pointer-events: none;
  }
`

const StyledMiniButton = styled(StyledPrimaryButton)`
  height: 28px;
  padding: 0 10px;
  font-size: 0.8rem;
  color: ${props => props.$red ? 'var(--color-text-white)' : 'var(--color-text-dark)'};
  background: ${props => props.$red ? 'var(--color-brand-red)' : 'var(--color-bg-white)'};
  box-shadow: 0 0 5px 0 var(--color-bg-light);

  &:hover {
    background: ${props => props.$red ? 'var(--color-brand-red)' : 'var(--color-bg-white)'};
    box-shadow: 0 0 5px 0 var(--color-bg-grey-dark);
  }

  @media screen and (max-width: 767px) {
    &:hover {
      box-shadow: none;
    }
  }
`

const Button = props => {
    const {
        children,
        component: Component,
        loading,
        ...ownProps
    } = props

    return (
        <Component {...ownProps}>
            { children }
            { loading && <img src={spinner} className={`spinner`} alt='Laddar...' title='Laddar...' /> }
        </Component>
    )
}

export const PrimaryButton = props => {
    return (
        <Button component={StyledPrimaryButton} {...props} />
    )
}

export const PrimaryLinkButton = props => {
    return (
        <Button component={StyledPrimaryLinkButton} {...props} />
    )
}

export const PrimaryHrefButton = props => {
    return (
        <Button component={StyledPrimaryHrefButton} {...props} />
    )
}

export const SecondaryButton = props => {
    return (
        <Button component={StyledSecondaryButton} {...props} />
    )
}

export const SecondaryInvertButton = props => {
    return <Button component={StyledSecondaryInvertButton} {...props} />
}

export const SecondaryLinkButton = props => {
    return (
        <Button component={StyledSecondaryLinkButton} {...props} />
    )
}

export const SecondaryHrefButton = props => {
    return (
        <Button component={StyledSecondaryHrefButton} {...props} />
    )
}

export const PrimaryInvertButton = props => {
  return <Button component={StyledPrimaryInvertButton} {...props} />
}

export const PrimaryInvertHrefButton = props => {
  return <Button component={StyledPrimaryInvertHrefButton} {...props} />
}

export const PrimaryInvertLinkButton = props => {
  return <Button component={StyledPrimaryInvertLinkButton} {...props} />
}

export const DeleteButton = props => {
    return (
        <Button component={StyledDeleteButton} {...props} />
    )
}

export const WhiteButton = props => {
    return (
        <Button component={StyledWhiteButton} {...props} />
    )    
}    

export const BlackButton = props => {
    return (
        <Button component={StyledCancelButton} {...props} />
    )    
}    

export const CancelButton = props => {
    return (
        <Button component={StyledCancelButton} {...props} />
    )    
}    

export const CancelLinkButton = props => {
    return (
        <Button component={StyledCancelLinkButton} {...props} />
    )
}

export const MiniButton = props => {
    return (
        <Button component={StyledMiniButton} {...props} />
    )
}

export class CheckboxButton extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            checked: props.checked,
            checkedDefault: props.checked
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!isUndefined(nextProps.checked) && nextProps.checked !== prevState.checkedDefault) {
            return { checked: nextProps.checked, checkedDefault: nextProps.checked }
        }
        return {}
    }

    onClickHandler = () => {
        const newState = !this.state.checked
        this.setState({ checked: newState }, () => this.props.onClick({ checkedState: newState }))
    }

    render() {
        const { children, checkBoxProps, ...ownProps } = this.props;
        return (
            <Button component={StyledCheckBoxButton} {...ownProps} onClick={this.onClickHandler}>
                <Checkbox key={this.state.checked} { ...checkBoxProps } disableOnClick={true} checked={this.state.checked} onChange={e => {}} />
                {children}
            </Button>
        )
    }
}

export const SaveButton = styled(PrimaryButton)`
  min-width: min(300px, 100%);
  margin-top: 20px;
  margin-bottom: 20px;
`
export const ResetButton = styled(CancelButton)`
  width: min(300px, 100%);
  margin: 20px 0 0 0;
`
export const NextLink = styled(PrimaryLinkButton)`
  margin: 20px 0;
`

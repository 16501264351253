import { template } from "lodash"
import { JOBB } from "_routes/url-names"
import bookingMailTemplate from "_modules/recruitments/templates/bookingmail.template.html"
import supportMailTemplate from "_modules/recruitments/templates/supportmail.template.html"
import recruitmentBookingTemplate from "_modules/recruitments/templates/recruitment-booking.template.html"
import publishSupportTemplate from "_modules/recruitments/templates/publish-support.template.html"
import confirmInterviewMailTemplate from "_modules/recruitments/templates/confirm-interview.template.html"
import bookedInterviewMailTemplate from "_modules/recruitments/templates/booked-interview.template.html"
import bookedServiceMailTemplate from "_modules/recruitments/templates/booked-service.template.html"

const resolveRecruitmentState = state => {
    switch (state) {
        case "ACTIVE":
            return "aktiva"
        case "DRAFT":
            return "utkast"
        case "CLOSED":
            return "avslutade"
        default:
            return ""
    }
}

export const getPublishChannelBookingMailContent = bookingContext => {
  const compiledTemplate = template(bookingMailTemplate)
  const {
    recruitment: {
      id: recruitmentId,
      jobAd: { id: jobAdId },
      title: recruitmentTitle,
      company: {
        urlName: companySlug
      },
      state,
      recruitmentSelectedPublishChannels: channels = [],
      publishPackageTypes = [],
      publishChannelsOther
    },
    user,
    orderMessage
  } = bookingContext
  const baseUrl = `${window.location.protocol}//${window.location.host}`

  return compiledTemplate({
    senderName: `${user.firstName} ${user.lastName}`,
    senderCompany: user.companyView.name,
    publishChannelsOther,
    publishPackageTypes: publishPackageTypes.map(p => 
      p.value === 'SOCIALMEDIA' ? 'Tjänsteman / Jobbet social' : 
      p.value === 'YOUNG' ? 'Young professionals' : 'Medarbetare / Jobbet medium'
    ).join(", "),
    publicationChannels: channels.map(pc => pc.publishChannel.title).join(', '),
    recruitmentUrl: `${baseUrl}/jobbet.se/rekrytering/${resolveRecruitmentState(state)}/${recruitmentId}/annonskanaler`,
    recruitmentTitle,
    orderMessage,
    publicAdUrl: `https://www.jobbet.se${JOBB}/${companySlug}/${recruitmentTitle}/${jobAdId}`
    // publicAdUrl: `https://www.jobbet.se/platsannonser/paagaaende-rekrytering/?id=${jobAdId}`
  })
}

export const getPublishChannelSupportMailContent = ({ userMessage, user }) => {
    const compiledTemplate = template(supportMailTemplate)

    return compiledTemplate({
        senderName: `${user.firstName} ${user.lastName}`,
        senderCompany: user.companyView.name,
        userMessage: userMessage
    })
}

export const getExternalSupportMailContent = ({ userMessage, user }) => {
    const compiledTemplate = template(supportMailTemplate)
    return compiledTemplate({
      senderName: `${user.firstName} ${user.lastName}`,
      senderCompany: user.companyView.name,
      userMessage: userMessage
    })
}

export const getPublishSupportMailContent = ({ userMessage, user, recruitment }) => {
  const compiledTemplate = template(publishSupportTemplate)
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const { id, state, title } = recruitment
  return compiledTemplate({
    senderName: `${user.firstName} ${user.lastName}`,
    senderCompany: user.companyView.name,
    recruitmentUrl: `${baseUrl}/jobbet.se/rekrytering/${resolveRecruitmentState(state)}/${id}/annonskanaler`,
    recruitmentTitle: title,
    userMessage: userMessage
  })
}

export const getRecruitmentBookingMailContent = ({ bookingTitle, bookingPhone, bookingTimes, user }) => {
  const compiledTemplate = template(recruitmentBookingTemplate)
  return compiledTemplate({
    bookingTitle: bookingTitle,
    bookingPhone: bookingPhone,
    bookingTimes: bookingTimes,
    recruitmentCompany: user.companyView.name,
    senderCompany: user.employer.name,
    senderName: user.fullName,
    senderEmail: user.email
  })
}

export const getConfirmInterviewMailContent = ({ user, time, location, jobDescription, sender, calendarUrl }) => {
    const compiledTemplate = template(confirmInterviewMailTemplate)
    return compiledTemplate({
        recipient: user.firstName,
        time,
        location,
        jobDescription,
        sender,
        calendarUrl
    })
}

export const getBookedInterviewMailContent = ({ user, time, location, jobDescription, interviewer, sender, calendarUrl }) => {
  const compiledTemplate = template(bookedInterviewMailTemplate)
  return compiledTemplate({
    recipient: interviewer.firstName,
    candidate: `${user.firstName[0]}${user.lastName[0]}`,
    time,
    location,
    jobDescription,
    sender,
    calendarUrl
  })
}

export const getBookedServiceMailContent = ({ sender, company, job, order, orderMessage }) => {
  const compiledTemplate = template(bookedServiceMailTemplate)
  return compiledTemplate({
    sender,
    company,
    job,
    order,
    orderMessage
  })
}

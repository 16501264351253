import { gql } from '@apollo/client';

export const NEW_INTERVIEW_MUTATION = gql`
  mutation CreateInterview(
    $companyId: String!
    $createdBy: String!
    $startTime: DateTime!
    $endTime: DateTime
    $guest: String
    $note: String
    $location: String
    $spots: Int!
    $interviewers: [UserWhereUniqueInput!]!
    $interviewersExternal: [UserWhereUniqueInput!]!
  ) {
    createInterview(
      data: {
        company: { connect: { id: $companyId } }
        createdBy: { connect: { id: $createdBy } }
        startTime: $startTime
        endTime: $endTime
        guest: $guest
        location: $location
        note: $note
        spots: $spots
        interviewers: { connect: $interviewers }
        interviewersExternal: { connect: $interviewersExternal }
      }
    ) {
      id
    }
  }
`;
export const EDIT_INTERVIEW_MUTATION = gql`
  mutation UpdateInterview(
    $id: String!
    $startTime: DateTime!
    $endTime: DateTime
    $guest: String
    $location: String
    $note: String
    $spots: Int!
    $interviewers: [UserWhereUniqueInput!]!
    $interviewersExternal: [UserWhereUniqueInput!]!
  ) {
    updateInterview(
      where: { id: $id }
      data: {
        startTime: { set: $startTime }
        endTime: { set: $endTime }
        guest: { set: $guest }
        note: { set: $note }
        location: { set: $location }
        spots: { set: $spots }
        interviewers: { set: $interviewers }
        interviewersExternal: { set: $interviewersExternal }
      }
    ) {
      id
    }
  }
`;
export const DELETE_INTERVIEW_MUTATION = gql`
  mutation DeleteInterview($id: String!) {
    deleteInterview(where: { id: $id }) {
      id
    }
    deleteManyApplicationInterview(where: { interviewId: { equals: $id } }) {
      count
    }
  }
`;
export const DELETE_INVITES_MUTATION = gql`
  mutation DeleteApplicationInterviews($appId: String!) {
    deleteManyApplicationInterview(where: { applicationId: { equals: $appId } }) {
      count
    }
  }
`;
export const NEW_APP_INTERVIEW_MUTATION = gql`
  mutation CreateApplicationInterview($applicationId: String!, $interviewId: String!) {
    createApplicationInterview(
      data: {
        application: { connect: { id: $applicationId } }
        interview: { connect: { id: $interviewId } }
        status: INVITED
      }
    ) {
      id
    }
  }
`;
export const BOOK_APP_INTERVIEW_MUTATION = gql`
  mutation CreateApplicationInterview($applicationId: String!, $interviewId: String!) {
    createApplicationInterview(
      data: {
        application: { connect: { id: $applicationId } }
        interview: { connect: { id: $interviewId } }
        status: BOOKED
      }
    ) {
      id
    }
  }
`;
export const SET_APP_INTERVIEW_MUTATION = gql`
  mutation UpdateApplicationInterview($id: String!, $status: ApplicationInterviewStatus!) {
    updateApplicationInterview(
        where: { id: $id },
        data: { status: { set: $status } }
    ) {
      id
    }
  }
`;
export const VACANT_INTERVIEWS = gql`
  query GetVacantInterviews($company: String!) {
    vacantInterviews(
      where: { companyId: { equals: $company } },
      orderBy: { startTime: asc }
    ) {
      id
      startTime
      endTime
      spots
      vacantSpots
      location
      note
      guest
      createdBy {
        id
        fullName
      }
      company {
        id
        name
        urlName
      }
      interviewers {
        id
        fullName
        firstName
        lastName
      }
      interviewersExternal {
        id
        fullName
        firstName
        lastName
      }
    }
  }
`;
export const INVITED_INTERVIEWS = gql`
  query GetInterviews($company: String!) {
    applicationInterviews(
      where: {
        status: { equals: INVITED }
        interview: { company: { id: { equals: $company } } }
      }
    ) {
        id
        interview {
            id
            startTime
            endTime
            spots
            vacantSpots
            location
            note
            guest
            createdBy {
                id
                fullName
            }
            company {
                id
                name
                urlName
            }
            interviewers {
                id
                fullName
                firstName
                lastName
            }
            interviewersExternal {
                id
                fullName
                firstName
                lastName
            }
        }
        application {
            id
        }
    }
  }
`;
export const CANDIDATE_INTERVIEWS = gql`
  query GetApplicationInterviews($member: String!, $now: DateTime!) {
    applicationInterviews(
      where: { application: { candidate: { user: { id: { equals: $member } } } }, interview: { startTime: { gt: $now } } },
      orderBy: { interview: { startTime: asc } }
    ) {
      id
      status
      application {
        id
        recruitment {
          id
          title
          company {
              id
              name
          }
        }
      }
      interview {
        id
        startTime
        endTime
        spots
        vacantSpots
        location
        note
        guest
        createdBy {
          id
          fullName
        }
        company {
          id
          name
          urlName
        }
        interviewers {
          id
          acceptInterviewEmail
          email
          fullName
          firstName
          lastName
        }
        interviewersExternal {
          id
          acceptInterviewEmail
          email
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;
export const DECLINE_INTERVIEWS = gql`
  mutation DeclineApplicationInterview($applicationId: String!) {
    updateManyApplicationInterview(
      where: {
        AND: [{
          applicationId: { equals: $applicationId }
        }, {
          status: { not: { equals: BOOKED } }
        }]
      },
      data: { status: { set: DECLINED } }
    ) {
      count
    }
  }
`;
export const UPDATE_INTERVIEW_STATUS = gql`
    mutation UpdateApplicationInterview($id: String!, $status: ApplicationInterviewStatus!) {
        updateApplicationInterview(
            where: { id: $id },
            data: { status: { set: $status } }
        ) {
            id
        }
    }
`;
export const UPDATE_INTERVIEW_STATUS_SERVER = gql`
    mutation {
      updateInterviewStatus
    }
`;
export const INVITED_APPLICATIONS = gql`
  query GetApplications($company: String!) {
    applications(
      where: {
        interviews: { some: { status: { equals: INVITED } } },
        recruitment: { company: { id: { equals: $company } } }
      }
    ) {
        id
        createdAt
        candidate {
            id
            user {
                id
                fullName
            }
        }
        recruitment {
            id
            title
            state
            company {
                id
                name
                urlName
            }
        }
        interviews(where: { status: { equals: INVITED } }, orderBy: { interview: { startTime: asc } }) {
            id
            createdAt
            interview {
              id
              startTime
              endTime
              location
              note
              guest
              spots
              vacantSpots
              company {
                id
                urlName
              }
              interviewers {
                  id
                  firstName
                  lastName
                  fullName
              }
              interviewersExternal {
                  id
                  firstName
                  lastName
                  fullName
              }
            }
        }
    }
}`
export const BOOKED_INTERVIEWS = gql`
  query GetInterviews($company: String!, $now: DateTime!) {
    interviews(
      where: {
        applications: { some: { status: { equals: BOOKED }, interview: { startTime: { gt: $now } } } },
        companyId: { equals: $company }
      }
    ) {
        id
        startTime
        endTime
        spots
        vacantSpots
        location
        note
        guest
        createdBy {
            id
            fullName
        }
        company {
            id
            name
            urlName
        }
        interviewers {
            id
            fullName
            firstName
            lastName
        }
        interviewersExternal {
            id
            fullName
            firstName
            lastName
        }
        applications(where: {status: { equals: BOOKED } }) {
            id
            application {
                id
                recruitment {
                  id
                  title
                }
                createdAt
                candidate {
                    id
                    user {
                        id
                        fullName
                    }
                }
            }
        }
    }
}`

export const CLOSED_APPLICATIONS = gql`
  query GetApplications($company: String!, $now: DateTime!) {
    applications(
      where: {
        OR: [{ interviews: { some: { status: { equals: BOOKED }, interview: { startTime: { lt: $now } } }}}, {interviews: { some: { status: { equals: COMPLETED } } }}],
        recruitment: { companyId: { equals: $company } }
      }
    ) {
        id
        createdAt
        candidate {
            id
            user {
                id
                fullName
            }
        }
        recruitment {
            id
            title
            state
            company {
                id
                name
                urlName
            }
        }
        interviews(where: { OR: [{ status: { equals: BOOKED } }, { status: { equals: COMPLETED } }] }) {
            id
            status
            interview {
                id
                startTime
                interviewers {
                  id
                  firstName
                  lastName
                  fullName
                }
                interviewersExternal {
                    id
                    firstName
                    lastName
                    fullName
                }
            }
        }
    }
}`

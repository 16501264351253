import React from 'react'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { compose } from 'react-recompose'
import * as EmailValidator from 'email-validator'
import styled from 'styled-components'
import { getRoleName, UserRoles } from '_root/constants'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol, Label, Input, Checkbox } from '_layout/form-elements'
import { CancelLinkButton, PrimaryButton } from '_layout/buttons'
import ObjectPickerBase from '_components/object-picker'
import * as url from '_routes/url-names'
import to from '_services/await.to'
import inject from '_services/inject'
import message from '_root/components/message';

const ObjectPicker = styled(ObjectPickerBase)`
    margin-bottom: 20px;
`
const CREATE_USER_MUTATION = gql`
mutation CreateUserMutation(
    $firstName: String!,
    $lastName: String!,
    $role: UserRole,
    $companyId: String!,
    $email: String!,
    $companyName: String!,
    $phone: String,
    $mobile: String,
    $notify: Boolean
) {
    signupUser(
        firstName: $firstName,
        lastName: $lastName,
        companyId: $companyId,
        companyName: $companyName,
        role: $role,
        phone: $phone,
        mobile: $mobile,
        email: $email,
        notify: $notify
    ) {
        id
    }
}
`

@compose(
    withRouter,
    inject('user'),
    graphql(CREATE_USER_MUTATION, { name: 'createUser' })
)
export default class CreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.initialState
    }

    initialState = {
        created: false,
        jobbetRoles: [
            { id: UserRoles.SUPERADMIN, name: getRoleName(UserRoles.SUPERADMIN) },
            { id: UserRoles.SITEADMIN, name: getRoleName(UserRoles.SITEADMIN) }
        ],
        groupRoles: [
            { id: UserRoles.GROUPADMIN, name: getRoleName(UserRoles.GROUPADMIN) },
            { id: UserRoles.GROUPRECRUITER, name: getRoleName(UserRoles.GROUPRECRUITER) },
            { id: UserRoles.GROUPVIEWER, name: getRoleName(UserRoles.GROUPVIEWER) }
        ],
        clientRoles: [
            { id: UserRoles.ADMINISTRATOR, name: getRoleName(UserRoles.ADMINISTRATOR) },
            { id: UserRoles.RECRUITER, name: getRoleName(UserRoles.RECRUITER) },
            { id: UserRoles.VIEWER, name: getRoleName(UserRoles.VIEWER) }
        ],
        userFirstname: '',
        userLastname: '',
        userMail: '',
        userPhone: '',
        userMobile: '',
        userRole: null,
        companyId: this.props.user.companyView.id,
        companyName: this.props.user.companyView.name,
        jobbetView: this.props.user.companyView.isJobbet,
        groupView: this.props.user.companyView.group,
        mailPreview: '',
        sendMail: false,
        logotype: null,
        selectedAdmins: [],
        companyCreated: false,
        missingInput: false,
    }

    clearState = () => {
        this.setState({...this.initialState})
    }

    selectedRole = userRole => { this.setState({ userRole })}

    toggleNotify = e => {
        this.setState({sendMail: !this.state.sendMail})
    }

    saveUser = async () => {
        this.setState({saving: true})
        const { companyName, userFirstname, userLastname, userMail, userPhone, userMobile, userRole, sendMail, companyId } = this.state
        const userVariables = {
            firstName: userFirstname,
            lastName: userLastname,
            companyId: companyId,
            companyName: companyName,
            role: userRole.id,
            email: userMail,
            phone: userPhone,
            mobile: userMobile,
            notify: sendMail
        }
        const [error, result] = await to(this.props.createUser({ variables: userVariables }))
        if (error || !result) {
            if (error && error.message && error.message.indexOf('user already exists') > -1) {
                message('Det finns redan en användare med angiven e-post.')
            }
            else {
                message('Något gick fel. Användare kunde inte skapas.')
            }
            this.setState({created: false, saving: false})
        }
        else {
            this.setState({created: true, saving: false})
        }
    }

    render() {

        if (this.state.created) {
            return (
                <MainLayout padding>
                    <H2>Skapa användare</H2>
                    <FlexRow className='margin20'>
                        <FlexCol>
                            Ny användare skapad.
                            <PrimaryButton className='mt4' onClick={this.clearState}>Tillbaka</PrimaryButton>
                        </FlexCol>
                    </FlexRow>
                </MainLayout>
            )
        }

        const {
          userFirstname, userLastname, userMail, userPhone, userMobile, jobbetView, groupView,
          jobbetRoles, groupRoles, clientRoles, userRole, missingInput, saving
        } = this.state

        const userRoles = jobbetView ? jobbetRoles : groupView ? groupRoles : clientRoles
        const canSave = userFirstname && userLastname && userRole && EmailValidator.validate(userMail)
        const cancelUrl = '/' + this.props.match.params.company + url.INSTALLNINGAR + url.INST_USERS

        return (
            <MainLayout padding>
                <H2>Skapa användare</H2>
                <FlexRow className='margin20'>
                    <FlexCol>
                        <Label>Förnamn *</Label>
                        <Input type='text'
                            placeholder='Förnamn *'
                            value={userFirstname}
                            required
                            onChange={e => this.setState({ userFirstname: e.target.value })} />
                        <Label>Efternamn *</Label>
                        <Input type='text'
                            placeholder='Efternamn *'
                            value={userLastname}
                            required
                            onChange={e => this.setState({ userLastname: e.target.value })} />
                        <Label>E-post *</Label>
                        <Input type='text'
                            placeholder='E-postadress *'
                            value={userMail}
                            required
                            onChange={e => this.setState({ userMail: e.target.value })} />
                        <Label>Telefon</Label>
                        <Input type='text'
                            placeholder='Telefonnummer'
                            value={userPhone}
                            onChange={e => this.setState({ userPhone: e.target.value })} />
                        <Label>Mobiltelefon</Label>
                        <Input type='text'
                            placeholder='Mobilnummer'
                            value={userMobile}
                            onChange={e => this.setState({ userMobile: e.target.value })} />
                        <Label>Välj systemroll *</Label>
                        <ObjectPicker
                            form={'true'}
                            values={userRoles}
                            placeholder='Välj roll...'
                            current={userRole}
                            onSelected={this.selectedRole}
                            noMatch='Inga träffar' />
                        <div>
                            <Checkbox className='mr2' onChange={this.toggleNotify} label='Skicka inloggning till användare' />
                            <FlexRow className='mt4'>
                                <CancelLinkButton to={cancelUrl} className='mr3'>Avbryt</CancelLinkButton>
                                <PrimaryButton disabled={!canSave} onClick={this.saveUser} loading={saving}>Spara</PrimaryButton>
                            </FlexRow>
                            {missingInput && // This should never happen
                                <p>Vänligen fyll i alla obligatoriska fält.</p>
                            }
                        </div>
                    </FlexCol>
                </FlexRow>
            </MainLayout>
        )
    }
}

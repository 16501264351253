/**
 * Helper method to handle Promise errors for multiple promises.
 * @function all
 * @param  {[Promise]} promises Array of promises to handle errors for.
 * @return {Promise} Returns new promise that resolve when all supplied promises has resolved.
 */
export default function all(promises) {
    return Promise.all(promises).then(data => {
        return [null, data]
    })
    .catch(err => [err])
}

import React from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import styled from 'styled-components'

import {
    INSTALLNINGAR,
    INST_USERS
} from '_routes/url-names'

import { PrimaryButton } from '_layout/buttons';
import { ResultList, ResultItem } from '_modules/search/common-layout/result-lists'

const ResultListWrapper = styled.div`
    overflow: auto;
    flex: 1;

    & .resultlist__fetchmore {
        display: block;
        margin: 20px auto;
    }
`
const SideLink = styled.a`
    margin-left: auto;
    white-space: nowrap;
`

const DetailedUsersResults = props => {
    const { user, employees, total, onFetchMoreResults, isFetchingMore } = props
    const { match: { params: routeParams } } = props

    return (
        <ResultListWrapper>
            <ResultList>
            {
                employees.map(employee =>
                    <ResultItem key={employee.id}>
                        <Link to={{ pathname: `/${routeParams.company}${INSTALLNINGAR}${INST_USERS}/${employee.id}` }}>
                            { `${employee.firstName} ${employee.lastName}` }
                        </Link>
                        {!employee.active && <SideLink>Ej aktiverad</SideLink>}
                        {user.superAdmin && employee.active && employee.employer && <SideLink target='_blank' rel='opener' href={'/'+employee.employer.urlName}>{employee.employer.name}</SideLink>}
                    </ResultItem>
                )
            }
            </ResultList>
            { (total > employees.length) && <PrimaryButton loading={isFetchingMore} className="resultlist__fetchmore" onClick={onFetchMoreResults}>Visa fler</PrimaryButton> }
        </ResultListWrapper>
    )
}

export default withRouter(DetailedUsersResults)
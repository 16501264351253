import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { formatISO } from "date-fns"
import LoginService from "_services/login-service"
import isLoggedIn from "_services/is-logged-in"
import { CANDIDATE_INTERVIEWS } from "_containers/interview/interview-ql"
import inject from "_services/inject"
import * as url from "_routes/url-names"

//#region Styles
const MenuWrapper = styled.div`
  height: 100%;
  background-color: var(--color-bg-grey-alpha);
`
const MenuContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  padding: 20px 80px 20px 20px;
  width: fit-content;
  height: 100%;
  background-color: var(--color-bg-white);

  > a {
    align-self: center;
    margin-top: 20px;
  }

  @media (min-width: 1100px) {
    padding: 20px;
    height: unset;
    border-radius: inherit;
    box-shadow: 0 8px 17px var(--color-shadow);

    > a {
      position: absolute;
      top: 100%;
    }
  }
`
const Menu = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style-type: none;
  min-width: 200px;
  margin: 0;
  padding: 0;
`
const MenuItem = styled.li`
  display: flex;
  align-items: center;

  a {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--color-text);
    text-decoration: none;
    white-space: nowrap;
    padding: 5px 10px;
    border-left: 5px solid transparent;

    &.active {
      pointer-events: none;
      font-weight: 700;
      background: var(--color-bg-lighter);
      border-left: 5px solid var(--color-brand-red);
    }

    &:hover {
      font-weight: 700;
      text-decoration: none;
      background: var(--color-bg-bright);
    }

    i {
      display: inline-flex;
      font-size: 0.75rem;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;
      width: 1.5em;
      height: 1.5em;
      color: var(--color-text-white);
      border-radius: 50%;
      background-color: var(--color-brand-red);
    }
  }
`
const UserName = styled.h3`
  color: var(--color-text);
  line-height: 1;
  margin-left: 15px;
  margin-bottom: 20px;
  white-space: nowrap;
`
const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
`
//#endregion

@compose(
  inject("user"),
  withRouter,
  withApollo,
  graphql(CANDIDATE_INTERVIEWS, { name: "interviewsQuery", options: (props) => ({ variables: { member: props.user.id, now: formatISO(new Date()) } }) })
)
export default class MemberMenu extends React.Component {
  logout = (e) => {
    e.preventDefault()
    LoginService.clearToken({ client: this.props.client, reload: true })
  }

  menuClick = (e) => {
    if (e.target && e.target.className && e.target.className.includes("member-menu")) {
      this.props.onClose && this.props.onClose()
    }
  }

  render() {
    const {
      interviewsQuery: { applicationInterviews = [] },
      user,
    } = this.props
    const userName = `${user.firstName} ${user.lastName}`
    const invites = applicationInterviews
      .filter((a) => a.status === "INVITED")
      .map((a) => a.application.id)
      .filter((value, index, self) => self.indexOf(value) === index)

    if (isLoggedIn() === false || user.active === false) return null

    return (
      <MenuWrapper className="member-menu" onClick={this.menuClick}>
        <MenuContainer>
          <UserName>{userName}</UserName>
          {user.picture && user.picture.url && <Image src={user.picture.url} alt={userName} />}
          <Menu>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.PROFIL}>
                Min profil
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.ANSOKNINGAR}>
                Ansökningar
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.ANNONSER}>
                Platsannonser
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.BOKNING}>
                Mötesinbjudningar{!!invites.length && <i>{invites.length}</i>}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink end to={url.MEDLEM + url.INSTALLNINGAR}>
                Hantera konto
              </NavLink>
            </MenuItem>
            <MenuItem>
              <a onClick={this.logout}>Logga ut</a>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </MenuWrapper>
    )
  }
}

import React from 'react'
import { func, object, oneOfType, string } from 'prop-types'
import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

// Cache of QueryContainer components based on parsed query or query string
const cache = new Map()

class QueryComponent extends React.PureComponent {
    render() {
        const { loading, error, render, options } = this.props
        const data = this.props[options.name] || this.props["apollodata"] || this.props["data"]
        if (!data) return render(null)
        if (data.networkStatus === 1) {
            return loading(data)
        }
        if (data.networkStatus === 8) {
            // Error, with no request in flight
            return error(data)
        }
        if (data.error) {
            // Error, but there is a request in flight...
            // ...let's hope for the best!
            return loading(data)
        }
        var result = render(data)
        return result
    }
}

QueryComponent.displayName = 'QueryComponent'

class Query extends React.PureComponent {
    render() {
        const { name, query, options } = this.props
        const cacheKey = name || query
        if (!cache.has(cacheKey)) {
            const parsedQuery = typeof query === 'object' ? query : gql(query)
            cache.set(
                cacheKey,
                graphql(parsedQuery, options)(QueryComponent)
            )
        }
        const QueryContainer = cache.get(cacheKey)
        return <QueryContainer {...this.props} />
    }
}

Query.displayName = 'Query'

Query.propTypes = {
    query: oneOfType([ string, object ]).isRequired,
    options: object,
    loading: func,
    error: func,
    render: func.isRequired
}

Query.defaultProps = {
    loading: () => null,
    error: () => null
}

export default Query

import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from "date-fns"
import ObjectPicker from '_components/object-picker'
import { CancelButton } from '_layout/buttons'
import { Spinner } from '_layout/form-elements'

//#region Styles
const Error = styled.p`
    margin: 0 0 1em 0;
    color: var(--color-brand-red);
`
const MessageHeader = styled.div`
    label {
        margin-right: 10px;
        color: var(--color-text);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-transform: uppercase;
        display: inline-block;
        min-width: 4.2em;
    }
`
const MessageBody = styled.span`
    display: block;
    margin-top: 1rem;
`
const Dialog = styled.div`
    min-width: 600px;
`
const Message = styled.div`
    min-height: 300px;
`
//#endregion Styles

export default class ReviewMessageLog extends React.Component {
  constructor(props) {
    super(props)
    const logs = props.logs && props.logs.map(log => ({...log, name: format(parseISO(log.createdAt), "yy-MM-dd HH:mm")}))
    this.state = {
      logs,
      selected: logs && !!logs.length && logs[0]
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.logs && prevState.logs && nextProps.logs.length !== prevState.logs.length) {
      const logs = nextProps.logs.map(log => ({...log, name: format(parseISO(log.createdAt), "yy-MM-dd HH:mm")}))
      return { logs, selected: logs && !!logs.length && logs[0] }
    }
    return null
  }

  onSelected = messageLog => {
        if (!messageLog) return
        this.setState({
            selected: messageLog
        })
    }

    onCancel = e => {
        this.props.onClose()
    }

    getBodyHtml = () => {
        const __html = this.state.selected && this.state.selected.messageData
            ? this.state.selected.messageData.html
            : ''
        return { __html }
    }

    render() {
        const { loading, logs, selected, errorMessage } = this.state

        if (loading) {
            return <Dialog><Spinner /></Dialog>
        }
        return (
            <Dialog>
                <h3>Skickade meddelanden</h3>
                {errorMessage &&
                <Error>{errorMessage}</Error>
                }
                <ObjectPicker
                    form={'true'}
                    className='mb3'
                    values={logs}
                    placeholder='Välj meddelande...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga meddelanden' />
                {!selected &&
                <Message></Message>
                }
                {selected &&
                <Message>
                    <MessageHeader><label>Skickat</label> {format(parseISO(selected.createdAt), "yy-MM-dd HH:mm")}</MessageHeader>
                    <MessageHeader><label>Från</label> {selected.createdByUser.firstName} {selected.createdByUser.lastName}</MessageHeader>
                    <MessageHeader><label>Till</label> {selected.messageData.to}</MessageHeader>
                    <MessageHeader><label>Ämne</label> {selected.message}</MessageHeader>
                    <MessageBody dangerouslySetInnerHTML={this.getBodyHtml()}></MessageBody>
                </Message>
                }
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.onCancel}>Stäng</CancelButton>
                </div>
            </Dialog>
        )
    }
}
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

export const RECRUITMENT_SYMBOLS = gql`
query RecruitmentSymbols($recruitmentId: String!) {
    recruitmentSymbols(recruitmentId: $recruitmentId) {
      id
      name
      editable
      text
      customText
      customTextId
    }
}
`

const withRecruitmentSymbols = queryOptions => {
    const defaultQueryoptions = {
        name: 'recruitmentSymbolsQuery',
        options: props => ({ 
            variables: {
                recruitmentId: props.match.params.recruitment
            }
        })
    }
    return graphql(
        RECRUITMENT_SYMBOLS,
        queryOptions ? { ...defaultQueryoptions, ...queryOptions } : defaultQueryoptions
    )
}

export default withRecruitmentSymbols
'use strict';

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) { // eslint-disable-line
      position = position || 0
      return this.substring(position, searchString.length) === searchString
  }
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) { // eslint-disable-line
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length
        }
        return this.substring(this_len - search.length, this_len) === search
    }
}

if (!String.prototype.replaceAll) {
    function escapeRegExp(string) {
        return string.replace(/([.*$^:=+?!{}()|\[\]\\\/])/g, "\\$1")
    }

    String.prototype.replaceAll = function(replaceString, replaceWith) { // eslint-disable-line
        return this.replace(new RegExp(escapeRegExp(replaceString), "g"), replaceWith)
    }
}

if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) { // eslint-disable-line
        if (typeof start !== 'number') {
            start = 0
        }
        if (start + search.length > this.length) {
            return false
        } else {
            return this.indexOf(search, start) !== -1
        }
    }
}

if (!Array.prototype.equals) {
  Object.defineProperty(Array.prototype, 'equals', { // eslint-disable-line
    value: function(other) {
      return JSON.stringify(this) === JSON.stringify(other)
    }
  })
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', { // eslint-disable-line
        value: function(searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            // 1. Let O be ? ToObject(this value).
            var o = Object(this);
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }
            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;
            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }
            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                // c. Increase k by 1. 
                k++;
            }
            // 8. Return false
            return false;
        }
    })
}

if (!String.prototype.hashCode) {
    String.prototype.hashCode = function() { // eslint-disable-line
        var hash = 0, i, chr;
        if (this.length === 0) return hash;
        for (i = 0; i < this.length; i++) {
            chr   = this.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}

Object.size = function(obj) {
    var size = 0, key
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++
    }
    return size
}
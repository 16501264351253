import React, { useState } from "react"
import styled from "styled-components"
import { compose } from 'react-recompose'
import withRouter from "_hoc/withRouter"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import map from "lodash/fp/map"
import { SaveButton } from "_layout/buttons"
import { FlexRow, FlexCol, Spinner, RecruitGrid } from "_layout/form-elements"
import MainLayout from "_layout/main-layout"
import RecruitBase from "_components/recruit/recruit-base"
import RecruitingInformation from "_modules/recruitments/components/advertisement/recruiting-information"
import RecruitingDeals from "_modules/recruitments/components/advertisement/recruiting-deals"
import { Process, ProcessType, REC_STATS_ID } from "_root/constants"
import { REKRYTERING, REK_ANNONS, REK_UTKAST } from "_routes/url-names"
import { APP_SYMBOLS_QUERY, NEW_PROCESS_MUTATION, NEW_RECRUITMENT_MUTATION } from "_containers/recruitment/recruitment-ql"
import { COMPANY_QUERY } from "_containers/settings/company-profile-ql"
import to from "_services/await.to"
import all from "_services/await.all"
import inject from "_services/inject"

//#region Styles
const InputArea = styled.div`
  max-width: 960px;
`
//#endregion Styles

const NewRecruitment = props => {

  const [recruitment, setRecruitment] = useState()
  const [isSaving, setIsSaving] = useState()


  const onRecruitment = data => {
    if (recruitment?.addons) {
      setRecruitment({ ...data, addons: recruitment.addons })
    }
    else {
      setRecruitment({ ...data })
    }
  }


  const createNewProcess = async () => {
    const { newProcess } = props

    const steps = [
      () => newProcess({ variables: { order: 0, type: ProcessType.SELECTION, state: Process.INACTIVE } }),
      () => newProcess({ variables: { order: 1, type: ProcessType.INTERVIEW1, state: Process.INACTIVE } }),
      () => newProcess({ variables: { order: 2, type: ProcessType.TEST, state: Process.INACTIVE } }),
      () => newProcess({ variables: { order: 3, type: ProcessType.INTERVIEW2, state: Process.INACTIVE } }),
      () => newProcess({ variables: { order: 4, type: ProcessType.REFERENCES, state: Process.INACTIVE } }),
      () => newProcess({ variables: { order: 5, type: ProcessType.OTHER, state: Process.INVALID, text: "" } }),
      () => newProcess({ variables: { order: 6, type: ProcessType.CLOSING, state: Process.INACTIVE } }),
    ]

    const [errorSteps, resultSteps] = await all(map((step) => step())(steps))

    if (errorSteps) {
      console.error("newProcess:error", errorSteps)
      return []
    }
    return map((step) => step.data.createProcessStep.id)(resultSteps)
  }

  const saveAddons = rec => {
    setRecruitment({...recruitment, addons: rec.selectedAddons})
  }

  const save = async () => {
    const { match, user } = props
    const { symbols } = props.symbolsQuery

    if (!user || !user.employer || !user.employer.id || !symbols || !symbols.length) {
      console.error("Missing user or symbols (new recruitment : save", user, symbols)
      return
    }

    setIsSaving(true)

    const processIds = await createNewProcess()

    if (!processIds || !processIds.length) return

    const { recruiter, recruiterGuests, recruiterExternals, company, title, interest, occupationalAreas, occupationalGroups, regions, profileFileId, selectedAddons } = recruitment

    const variables = {
      title: title,
      interest: interest,
      companyId: company && company.id ? company.id : user.employer.id,
      recruiterId: recruiter.id,
      recruiterGuestIds: map((rg) => ({ id: rg.id }))(recruiterGuests || []),
      recruiterExternalIds: map((re) => ({ id: re.id }))(recruiterExternals || []),
      occupationalAreasIds: map((a) => ({ id: a.id }))(occupationalAreas),
      occupationalGroupsIds: map((a) => ({ id: a.id }))(occupationalGroups),
      regionsIds: map((r) => ({ id: r.id }))(regions),
      processIds: map((id) => ({ id: id }))(processIds || []),
      symbolsIds: map((symbol) => ({ id: symbol.id }))(symbols),
      addonsIds: map((ra) => ({ id: ra.id }))(selectedAddons || []),
      profileFileId: profileFileId ? { id: profileFileId } : undefined,
    }

    const [error, result] = await to(props.newRecruitment({ variables }))

    props.client.writeQuery({
      query: gql`
        query RecruitmentStats {
          recruitmentStats {
            countRecruitmentsIsStale
            activeRecruitmentsIsStale
            draftRecruitmentsIsStale
            closedRecruitmentsIsStale
          }
        }
      `,
      data: {
        recruitmentStats: {
          __typename: "RecruitmentStats",
          id: REC_STATS_ID,
          countRecruitmentsIsStale: true,
          draftRecruitmentsIsStale: true,
          activeRecruitmentsIsStale: false,
          closedRecruitmentsIsStale: false
        },
      },
    })

    if (error) {
      console.error("recruitment-new: error:", error)
      return
    }

    const { data: { createRecruitment: { id } }} = result
    const companyView = match.params.company || user.companyView.urlName
    const url = "/" + companyView + REKRYTERING + REK_UTKAST + "/" + id + REK_ANNONS
    props.navigate(url)
  }

  const {
    user,
    companyQuery: { loading, company },
  } = props

  if (loading || !company?.urlName) {
    return <MainLayout padding>
      <h2>Skapa rekrytering</h2>
      <FlexRow className="margin20">
        <FlexCol>
          <Spinner />
        </FlexCol>
      </FlexRow>
    </MainLayout>
  }

  const groups = company.groups.map((g) => g.id)

  return <MainLayout padding scroll>
    <RecruitGrid $padding='40px'>
      <InputArea className="input-area">
        <h2>Skapa rekrytering</h2>
        <p className="mb4">
          Här påbörjar du en rekrytering. Efter att ha fyllt i grundinformationen i detta steg kan du fortsätta med denna rekrytering direkt, eller komma tillbaka senare och fortsätta.
        </p>
        <RecruitBase onChange={onRecruitment} company={company} groups={groups} create={true} recruitment={recruitment} />
      </InputArea>
      {!user.companyView.isJobbet && (
        <>
          <RecruitingInformation className="info-area" />
          <RecruitingDeals className="deals-area" onChange={saveAddons} />
        </>
      )}
      <SaveButton className="button-area" disabled={!recruitment || isSaving} loading={isSaving} onClick={save}>
        Skapa rekrytering och gå vidare
      </SaveButton>
    </RecruitGrid>
  </MainLayout>
}

export default compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(NEW_PROCESS_MUTATION, { name: "newProcess" }),
  graphql(NEW_RECRUITMENT_MUTATION, { name: "newRecruitment" }),
  graphql(APP_SYMBOLS_QUERY, { name: "symbolsQuery" }),
  graphql(COMPANY_QUERY, { name: "companyQuery", options: (props) => ({ variables: { urlName: props.match.params.company } }) })
)(NewRecruitment)

import { setContext } from "@apollo/client/link/context"
import { userToken, expiredUser } from '_services/login-service'
import { decode } from 'jsonwebtoken'


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = userToken()
    // return the headers to the context so httpLink can read them
    if (token) {
      const jwToken = decode(token)
      const currentSecond = new Date().getTime() / 1000
      const exp = jwToken.exp || 0
      if (currentSecond > exp) {
        expiredUser()
        return null
      }
      return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`
            }
          }
    } else {
        return {
            headers: {
                ...headers
            }
        }
    }
})

export default authLink
import React from 'react'
import styled from 'styled-components'

//#region Styles
const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 25px;
`
const Inner = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    transform: rotate(${props => props.$level*20}deg);
    z-index: 1;
`
const Upper = styled.span`
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid var(--color-bg-grey);
    z-index: 2;
`
const Next = styled.span`
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid var(--color-brand-green-ad);
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(${props => 45+(props.$level*20)}deg);
    z-index: 3;
`
const Lower = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 20px; /* as the half of the width */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 3px solid var(--color-brand-red-light);
    border-top: 0;
    z-index: 4;
`
const Nail = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 0;
    border-bottom: 2px solid var(--color-bg-grey-dark);
    z-index: 5;
`
//#endregion

export default class AdMeter extends React.Component {
    render() {
        return (
          <Wrapper>
            <Inner $level={this.props.level}>
              <Upper/>
              <Next $level={this.props.booked}/>
              <Lower/>
              <Nail/>
            </Inner>
          </Wrapper>
        )
    }
}

import React from 'react'
import { isEmpty } from 'lodash/fp'
import draftToHtml from 'draftjs-to-html';
import confirm from '_components/confirm'
import TinyEditor from '_components/tiny-editor'
import { PrimaryButton, CancelButton } from '_layout/buttons'

export default class CopyTemplate extends React.Component {
    constructor(props) {
      super(props)
      const { original } = props
      this.state = {
        original: original,
        name: original.name,
        contentHTML: original.contentHTML || CopyTemplate.getHtmlContent(original.content),
        changed: false,
      }
    }

    static getHtmlContent = content => {
      return draftToHtml(content)
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
      const nextState = {}
      if (nextProps.close !== prevState.close) {
        nextState.close = nextProps.close
      }
      return isEmpty(nextState) ? null : nextState
    }

    componentDidUpdate = () => {
      if (this.state.close) {
        this.onClose()
      }
    }

    onNameChange = e => {
      this.setState({ name: e.target.value, changed: true })
    }

    onContentChange = content => {
      this.setState({ contentHTML: content, changed: true })
    }

    onCopy = () => {
      const { original, name, contentHTML } = this.state
      if (!original || !this.props.onCopy) return
      this.props.onCopy({...original, name: name, contentHTML: contentHTML, shared: false})
      this.setState({name: '', contentHTML: null, selected: null, changed: false})
      this.props.onClose && this.props.onClose()
    }

    onClose = async e => {
      if (this.state.changed && await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
        this.onCopy()
      }
      else {
        this.setState({name: '', contentHTML: null, selected: null, changed: false})
        this.props.onClose && this.props.onClose()
      }
    }

    render() {
        const { name, contentHTML } = this.state

        return (
          <div>
            <h3>{this.props.title}</h3>
            <input
              autoFocus
              type='text'
              className='mb3 w-100'
              placeholder={'Spara mall som...'}
              value={name}
              onChange={this.onNameChange} />
            <TinyEditor
              form
              placeholder='Fyll i din mall här...'
              insert='h3 bold italic underline | bullist numlist | link table'
              selection='h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
              plugins='autolink link lists image table'
              content={contentHTML}
              onChange={this.onContentChange}
              onDirty={this.props.onDirty}
              style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(90vh - 280px)', minHeight: 300 }} />
            <div className="tr">
              <CancelButton className='mr3' onClick={this.onClose}>Stäng</CancelButton>
              <PrimaryButton onClick={this.onCopy} disabled={!name || !contentHTML}>Spara kopia</PrimaryButton>
            </div>
          </div>
      )
    }
}
import React from 'react';
import Modal from 'react-modal'
import styled from 'styled-components'
import spinner from '_images/spinner.gif'

//#region Styles
const styles = props => {
    return {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100000
        },
        content: {
            position: 'absolute',
            top: '25%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            border: '1px solid #ccc',
            borderRadius: '32px',
            background: '#fff',
            overflow: props.overflow ? 'initial' : 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            transform: 'translate#d(-50%,-50%,0)',
            padding: '0',
            maxHeight: '80vh',
        }
    }
}

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;

    span {
        color: var(--color-text);
        font-size: 30px;
        margin-left: 10px;
    }
`
//#endregion

Modal.setAppElement('#root')

export default props => 
    <Modal {...props} style={styles(props)}>
        <Content>
            <img src={spinner} className={`spinner ${props.className }`} alt='Laddar...' title='Laddar...' />
            {/* <span>Laddar...</span> */}
        </Content>
    </Modal>

import React, { Component } from 'react'
import withRouter from '_hoc/withRouter'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import LogoImage from '_images/jobbet-logo-vit.png'
import { Spinner } from '_root/layout/form-elements'

//#region Styles
const Main = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: var(--color-text);
  background: var(--color-bg-bright);

  h1, h2, h3, h4, h5, label {
    font-weight: 400;
  }

  h1 {
    font-size: 28px;
    line-height: 1;
  }
`
const Head = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  flex: 0 0 65px;
  width: 100%;
  height: 160px;
  background: var(--color-bg-white);
  border-bottom: 1px solid var(--color-brand-red);
  z-index: 1;

  > a {
    height: 100%;
  }
  
  @media (max-width: 1400px) {
    height: 140px;
    padding: 20px 20px 40px 20px;
  }
`
const CompanyLogo = styled.img`
  width: auto;
  height: auto;
  max-width: 300px;
  max-height: 100%;
`
const JobbetLogo = styled.img`
  width: auto;
  height: 40px;
`
const Body = styled.div`
  margin: 0 auto;
  padding: 120px 20px 10vh 20px;
  width: 100%;
  max-width: 760px;
  z-index: 2;

  section {
    box-shadow: 0 8px 17px var(--color-shadow);
  }

  @media (min-width: 1100px) {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1400px) {
    padding-top: 40px;
  }

  @media (max-width: 360px) {
    padding: 120px 0 10vh 0;

    section {
      border-radius: 0;
    }
  }
`
const Content = styled.div`
  z-index: 2;
`
const Footer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  padding: 0.75rem 20px;
  width: 100%;
  font-size: 0.9rem;
  color: var(--color-text-white);
  background: var(--color-brand-red);
  z-index: 3;

  a {
    display: flex;
    margin-left: 1rem;
  }
`
//#endregion

@compose(
  withRouter
)
export class CompanyLayout extends Component {

  componentDidMount = () => {
    document.body.classList.add('company-layout');
  }

  render() {
    const { company } = this.props

    if (!company) {
      return <Main><Spinner/></Main>
    }

    return <Main>
      <Head>
        {company &&
        <CompanyLogo src={company.logotype?.url} alt={company.name} />
        }
      </Head>
      <Body>
        <Content>
          {this.props.children}
        </Content>
      </Body>
      <Footer>
        Powered by: <a href='https://www.jobbet.se' title=' Jobbet.se' target='_blank'><JobbetLogo src={LogoImage} alt=' Jobbet.se logotyp' title=' Jobbet.se' /></a>
      </Footer>
    </Main>
  }
}

export default CompanyLayout

import React from "react"
import styled from "styled-components"
import { parseISO } from "date-fns"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import Modal from "_components/modal"
import { PrimaryButton } from "_layout/buttons"
import to from "_services/await.to"
import CalendarIconSrc from "_images/calendar-icon.svg"
import ArrowRightSrc from "_images/arrow_right.svg"

//#region Styles
const ModalHeader = styled.header`
  font-size: 1.875rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const DateLabel = styled.div`
  color: var(--color-text-medium);
  text-transform: uppercase;
`
const DateBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  padding: 10px 20px 10px 40px;
  margin-bottom: 20px;
  border: 1px solid var(--color-bg-grey);
  background: var(--color-bg-white);
  z-index: 2;

  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      display: block;
      margin: 0 20px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        padding-left: 50px;
        transform: translateY(-50%);
      }

      input {
        width: 100%;
        padding-left: 95px;
        color: var(--color-text-medium);
        background: url(${CalendarIconSrc}) no-repeat scroll 15px 50%;
        background-size: 20px 20px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 1000;
  }
`
const DatePickerStart = styled.div`
  .react-datepicker__input-container {
    &:before {
      content: "Från:";
    }
  }
`
const DatePickerStop = styled.div`
  .react-datepicker__input-container {
    &:before {
      content: "Till:";
    }
  }
`
const ArrowRight = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 15px;
  background: url(${ArrowRightSrc}) no-repeat scroll 50% 50%;
  background-size: 20px 20px;
`
//#endregion

export default WrappedComponent => {
  return class extends React.PureComponent {
    displayName = `withChannelActivationInterceptor(${WrappedComponent.displayName || WrappedComponent.name})`

    constructor(props) {
      super(props)
      registerLocale("sv", sv)
      this.state = {
        loading: false,
        modalOpen: false,
        selectionContext: null,
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.publishStart || prevState.publishStop) return null
      const {
        jobAdDuration: { recruitment: { title = "", jobAd: { publishStart, publishStop } = {}, company = {} } = {} },
        recruitmentDuration,
      } = nextProps
      return {
        title,
        company,
        publishStart: publishStart || recruitmentDuration.applyStart || null,
        publishStop: publishStop || recruitmentDuration.applyStop || null,
      }
    }

    handleActivationStateChange = selectionContext => {
      const { onPersistDuration } = this.props
      if (selectionContext.activated) {
        this.setState({
          modalOpen: true,
          selectionContext,
        })
      } else {
        onPersistDuration({
          ...selectionContext,
          publishStart: null,
          publishStop: null,
        })
      }
    }

    closeAndClearDialog = () => {
      const {
        jobAdDuration: {
          recruitment: {
            jobAd: { publishStart, publishStop },
          },
        },
        recruitmentDuration,
      } = this.props
      this.setState({
        loading: false,
        modalOpen: false,
        selectionContext: null,
        publishStart: publishStart || recruitmentDuration.applyStart || null,
        publishStop: publishStop || recruitmentDuration.applyStop || null,
      })
    }

    onRequestModalClose = () => {
      this.closeAndClearDialog()
    }

    persistDuration = async () => {
      this.setState({ loading: true })
      const { onPersistDuration } = this.props
      const { selectionContext, publishStart, publishStop } = this.state
      const [err] = await to(
        onPersistDuration({
          ...selectionContext,
          publishStart: publishStart,
          publishStop: publishStop,
        })
      )
      if (err) {
        console.error("err: ", err)
      }
      this.closeAndClearDialog()
    }

    setPublishStart = selectedDate => {
      this.setState({ publishStart: selectedDate.toISOString() })
    }

    setPublishStop = selectedDate => {
      this.setState({ publishStop: selectedDate.toISOString() })
    }

    render() {
      const { onActivationChange, ...ownProps } = this.props
      const { modalOpen, publishStart, publishStop, loading } = this.state

      const stateChangeHandler = this.props.title === "Jobbet.se" ? this.handleActivationStateChange : onActivationChange

      return [
        <WrappedComponent {...ownProps} onActivationChange={stateChangeHandler} key={`withIntercept${ownProps.id}`} />,
        <Modal isOpen={modalOpen} onRequestClose={this.onRequestModalClose} overflow="visible" key={`modalFor${ownProps.id}`}>
          <ModalHeader>Välj publiceringstid</ModalHeader>
          <DateBox>
            <DateLabel>Datum</DateLabel>
            <DatePickerStart data-tooltip-place="right">
              <DatePicker placeholderText="Startdatum" selected={publishStart && parseISO(publishStart)} onChange={this.setPublishStart} locale="sv" dateFormat="yy-MM-dd" showWeekNumbers />
            </DatePickerStart>
            <ArrowRight />
            <DatePickerStop data-tooltip-place="right">
              <DatePicker placeholderText="Slutdatum" selected={publishStop && parseISO(publishStop)} onChange={this.setPublishStop} locale="sv" dateFormat="yy-MM-dd" showWeekNumbers />
            </DatePickerStop>
          </DateBox>
          <PrimaryButton onClick={this.persistDuration} loading={loading}>
            Aktivera
          </PrimaryButton>
        </Modal>,
      ]
    }
  }
}

import React from 'react'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { isEmpty } from 'lodash/fp'
import Slim from '_root/slim/slim.react'
import MainLayout from '_layout/main-layout'
import { isStrongEnough } from '_services/password'
import { PrimaryButton } from '_layout/buttons'
import { H2, FlexRow, FlexCol, Password, Checkbox } from '_layout/form-elements'
import message from '_components/message'
import confirm from '_components/confirm'
import { UPDATE_PASSWORD_MUTATION, UPDATE_USER_PICTURE, DELETE_USER_PICTURE, UPDATE_USER_INTERVIEW_EMAIL } from '_root/common-ql'
import UploadService from '_services/upload-service'
import LoginService from '_services/login-service'
import inject from '_services/inject'
import to from '_services/await.to'

//#region Styles
const Error = styled.div`
    margin-top: -0.5em;
    margin-bottom: 20px;
    color: var(--color-error);
`
const SlimEditor = styled.div`
    width: min(300px, 100%);
    height: 300px;
    .slim {
        cursor: pointer;
        border: 1px solid var(--color-bg-dark);
        background: var(--color-bg-white);
        box-shadow: inset 0 0 20px 0 var(--color-bg-grey);

        &[data-state*=empty]:hover {
            background: var(--color-bg-white);
            box-shadow: inset 0 0 20px 5px var(--color-bg-grey);
        }

        .hidden {
            display: none;
        }
    }
`
//#endregion
@compose(
    withApollo,
    inject('user'),
    graphql(UPDATE_PASSWORD_MUTATION, { name: 'updateUserPassword' }),
    graphql(UPDATE_USER_INTERVIEW_EMAIL, { name: 'updateInterviewEmail' }),
    graphql(UPDATE_USER_PICTURE, { name: 'updateUserPicture' }),
    graphql(DELETE_USER_PICTURE, { name: 'deleteUserPicture' })
)
export default class UserProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPassword: '',
            newPassword1: '',
            newPassword2: '',
            picture: null
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let nextState = {}
        if (nextProps.user) {
            nextState.picture = nextProps.user.picture
        }
        return !isEmpty(nextState) ? nextState : null;
    }

    updatePassword = async () => {
        const { currentPassword, newPassword1 } = this.state
        if (isStrongEnough(newPassword1)) {
            const variables = { email: this.props.user.email, oldPassword: currentPassword, newPassword: newPassword1 }
            const [error, result] = await to(this.props.updateUserPassword({ variables }))
            if (error || !result) {
                console.error('updatePassword:error: ', error)
                message('Ett fel uppstod när lösenord skulle uppdateras.')
            }
            else {
                message(result.data.updateUserPassword.message)
            }
            this.setState({weakPassword: false})
        }
        else {
            this.setState({weakPassword: true})
        }
    }

    onSlimInit = (data, slim) => {
        this.slim = slim
        const { picture } = this.state
        if (picture && picture.url) {
            slim._options.instantEdit = false
            slim.load(picture.url, (error, data) => { slim._options.instantEdit = true })
        }
    }

    onPhotoCancel = (data, slim) => {
        const { picture } = this.state
        if (!picture || !picture.url || !this.slim) return
        this.slim._options.instantEdit = false
        this.slim.load(picture.url, (error, data) => { this.slim._options.instantEdit = true })
    }

    onPhotoConfirm = async (data, slim) => {
        const variables = { userId: this.props.user.id, pictureId: { id: null } }
        let picture = slim.dataBase64.output
        if (picture) {
            const [error, { data }] = await to(UploadService.uploadBase64(picture.image, picture.name))
            if (error || !data || !data.id) {
                console.error('user-profile:savePicture:error', error || data)
                this.setState({ errorMessage: 'Ett fel uppstod vid uppladdning av foto.' })
                return
            }
            picture = data
            variables.pictureId = { id: data.id }
        }
        const [error, result] = await to(this.props.updateUserPicture({ variables }))
        if (error || !result) {
            console.error('updateUserPicture:error: ', error)
            message('Ett fel uppstod när bilden skulle sparas.')
        }
        else {
            this.setState({ picture: picture }, () => LoginService.reloadUser(this.props.client))
            message('Bilden har sparats')
        }
    }

    onPhotoRemoved = async (data) => {
        if (!this.state.picture || !this.state.picture.id) return
        const confirmed = await confirm('Vill du verkligen ta bort din profilbild?')
        if (!confirmed) return
        const variables = { pictureId: { id: this.state.picture.id } }
        const [error, result] = await to(this.props.deleteUserPicture({ variables }))
        if (error || !result) {
            console.error('removePicture:error: ', error)
            message('Ett fel uppstod när bilden skulle raderas.')
        }
        else {
            this.slim.remove()
            this.setState({ picture: null }, () => { LoginService.reloadUser(this.props.client) })
            message('Bilden har tagits bort.')
        }
    }

    onUpdateInterviewEmail = async e => {
      const checked = e.target.checked
      const variables = { userId: this.props.user.id, accept: checked }
      const [error, result] = await to(this.props.updateInterviewEmail({ variables }))
      if (error || !result) {
          console.error('updateInterviewEmail:error: ', error)
          message('Ett fel uppstod när profilen skulle uppdateras.')
      }
      else {
        LoginService.reloadUser(this.props.client)
      }
    }

    render() {
        const { currentPassword, newPassword1, newPassword2, weakPassword, picture } = this.state

        const { user } = this.props

        const disableButton = !currentPassword || !newPassword1 || !newPassword2 || (newPassword1 !== newPassword2)

        return (
            <MainLayout padding maxHeight scroll>
                <div>
                    <H2>Min profil - {user.firstName} {user.lastName}</H2>
                    <div className='margin20 mb4'>
                        <div >
                            <h3>Foto</h3>
                            <SlimEditor title='Klicka för att ladda upp en bild'>
                                <Slim
                                    ratio='1:1'
                                    label='Dra din bild hit eller klicka här'
                                    labelLoading='Laddar bild...'
                                    instantEdit={true}
                                    buttonEditTitle='Redigera'
                                    buttonRemoveClassName='hidden'
                                    buttonRotateTitle='Rotera'
                                    buttonCancelLabel='Avbryt'
                                    buttonCancelTitle='Avbryt'
                                    buttonConfirmLabel='OK'
                                    buttonConfirmTitle='OK'
                                    didInit={this.onSlimInit}
                                    didCancel={this.onPhotoCancel}
                                    didConfirm={this.onPhotoConfirm}>
                                    <input type='file' name='pictureFile' />
                                </Slim>
                            </SlimEditor>
                            <PrimaryButton className='mt3' onClick={this.onPhotoRemoved} disabled={!picture}>Ta bort bild</PrimaryButton>
                        </div>
                    </div>
                    <FlexRow className='margin20 mb4'>
                        <FlexCol>
                            <h3>Byte av lösenord</h3>
                            <p>Ange minst 8 tecken, minst 1 stor bokstav och minst 1 siffra.</p>
                            <Password autoComplete='new-password' placeholder='Nuvarande lösenord' value={currentPassword} onChange={e => this.setState({currentPassword: e.target.value})} />
                            <Password autoComplete='new-password' placeholder='Nytt lösenord' value={newPassword1} onChange={e => this.setState({newPassword1: e.target.value, weakPassword: false})} />
                            <Password autoComplete='new-password' placeholder='Upprepa nytt lösenord' value={newPassword2} onChange={e => this.setState({newPassword2: e.target.value, weakPassword: false})} />
                            {weakPassword &&
                            <Error>Lösenordet uppfyller inte villkoren</Error>
                            }
                            <PrimaryButton onClick={this.updatePassword} disabled={disableButton}>Byt lösenord</PrimaryButton>
                        </FlexCol>
                    </FlexRow>
                    <FlexRow className='margin20'>
                        <FlexCol>
                            <h3>E-post</h3>
                            <p>
                              <Checkbox checked={user.acceptInterviewEmail !== false} onChange={this.onUpdateInterviewEmail} label='Jag vill ha e-post när en kandidat accepterar en mötestid.' />
                            </p>
                        </FlexCol>
                    </FlexRow>
                </div>
            </MainLayout>
        )
    }
}

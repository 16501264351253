import { gql } from '@apollo/client'

export const CREATE_ADDRESS = gql`
mutation CreateAddress(
    $userId: String!,
    $postalCity: String!,
    $postalCode: String!
) {
    createAddress(data: {
        user: { connect: { id: $userId } },
        postalCity: $postalCity,
        postalCode: $postalCode
    }) {
        id
    }
}
`
export const REGISTER_CANDIDATE = gql`
mutation RegisterCandidate(
    $email: String!,
    $password: String!,
    $firstName: String!,
    $lastName: String!,
    $mobile: String,
    $pictureId: String,
    $postalCity: String,
    $postalCode: String,
    $educationId: String,
    $spam: Boolean,
    $interestCompanyId: String,
    $cvId: String,
    $docsIds: [String!]!,
    $information: String,
    $links: String,
    $occupationalGroupsIds: [String!]!,
    $regionsIds: [String!]!
) {
    registerCandidate(
        email: $email,
        password: $password,
        firstName: $firstName,
        lastName: $lastName,
        mobile: $mobile,
        pictureId: $pictureId,
        postalCity: $postalCity,
        postalCode: $postalCode,
        educationId: $educationId,
        spam: $spam,
        interestCompanyId: $interestCompanyId,
        cvId: $cvId,
        docsIds: $docsIds,
        information: $information,
        links: $links,
        occupationalGroupsIds: $occupationalGroupsIds,
        regionsIds: $regionsIds
    ) {
        id
    }
}
`
export const CHECK_CANDIDATE = gql`
query Candidate($email: String!) {
    user(where: {email: $email}) {
        id
        active
        createdAt
    }
}
`
export const GET_CANDIDATE = gql`
query Candidate($id: String!) {
    candidate(where: { id: $id }) {
        id
        applications {
            id
            cv {
                id
                name
                url
            }
            docs {
                id
                name
                url
            }
            answers
            links
            information
            process(orderBy: { order: { sort: asc } }) {
                id
            }
            recruitment {
                id
                title
                regions {
                    id
                    name
                }
                occupationalAreas {
                    id
                    name
                }
                occupationalGroups {
                    id
                    name
                }
            }
        }
        interests {
            id
            company {
                id
            }
            cv {
                id
                name
                url
            }
            docs {
                id
                name
                url
            }
            information
            links
            occupationalGroups {
                id
                name
            }
            regions {
                id
                name
            }
        }
        education {
            id
            name
        }
        spam
    }
}
`
export const GET_CANDIDATE_APPLICATIONS = gql`
query CandidateApplications($candidateId: String!) {
  findManyApplication(where: { candidateId: { equals: $candidateId } }, orderBy: { createdAt: desc }) {
    id
    createdAt
    grade
    answers
    links
    information
    recruitment {
        id
        title
        applyStop
        state
        company {
            id
            name
        }
        regions {
            id
            name
        }
        occupationalAreas {
            id
            name
        }
        occupationalGroups {
            id
            name
        }
    }
  }
}
`
export const GET_COMPANY_CANDIDATE_APPLICATIONS = gql`
query CandidateApplication($candidateId: String!) {
    candidate(where: { id: $candidateId }) {
        id
        applications {
            id
            createdAt
            answers
            links
            information
            recruitment {
                id
                title
                applyStop
                state
                company {
                    id
                    name
                }
                regions {
                    id
                    name
                }
                occupationalAreas {
                    id
                    name
                }
                occupationalGroups {
                    id
                    name
                }
            }
        }
    }
}
`
export const DELETE_APPLICATION = gql`
mutation DeleteApplication(
    $id: String!
) {
    deleteApplication(where: { id: $id }) {
        id
    }
}
`
export const CREATE_INTEREST = gql`
mutation CreateInterest(
    $candidateId: String!,
    $companyId: String!,
    $cvId: FileWhereUniqueInput,
    $docsIds: [FileWhereUniqueInput!]!,
    $links: String,
    $information: String
    $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!,
    $regionsIds: [RegionWhereUniqueInput!]!
) {
    createInterest(data: {
        candidate: { connect: { id: $candidateId } },
        company: { connect: { id: $companyId } },
        cv: { connect: $cvId },
        docs: { connect: $docsIds },
        links: $links,
        information: $information,
        occupationalGroups: { connect: $occupationalGroupsIds },
        regions: { connect: $regionsIds }
    }) {
        id
        candidate {
            id
        }
    }
}
`
export const UPDATE_MEMBER = gql`
mutation UpdateMember(
  $interestId: String!,
  $applicantId: String!,
  $cv: FileUpdateOneWithoutCvInterestNestedInput,
  $docsIds: [FileWhereUniqueInput!]!,
  $links: String,
  $information: String,
  $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!,
  $regionsIds: [RegionWhereUniqueInput!]!,
  $firstName: String,
  $lastName: String,
  $mobile: String,
  $addressId: String!,
  $postalCity: String,
  $postalCode: String,
  $picture: FileUpdateOneWithoutPictureUserNestedInput
  $candidateId: String!,
  $educationId: String!,
  $spam: Boolean,
  $email: String
) {
  updateUser(
    where: { id: $applicantId },
    data: {
      firstName: { set: $firstName },
      lastName: { set: $lastName },
      mobile: { set: $mobile },
      picture: $picture,
      email: { set: $email }
    }
  ) {
    id
  }
  updateAddress(
    where: { id: $addressId },
    data: {
      postalCity: { set: $postalCity },
      postalCode: { set: $postalCode }
    }
  ) {
    id
  }
  updateCandidate(
    where: { id: $candidateId },
    data: {
      education: { connect: { id: $educationId } },
      spam: { set: $spam }
    }
  ) {
      id
  }
  updateInterest(
    where: { id: $interestId },
    data: {
      cv: $cv,
      docs: { set: $docsIds },
      links: { set: $links },
      information: { set: $information },
      occupationalGroups: { set: $occupationalGroupsIds },
      regions: { set: $regionsIds }
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_DETAILS = gql`
mutation UpdateMemberDetails(
  $applicantId: String!,
  $firstName: String,
  $lastName: String,
  $mobile: String,
  $addressId: String!,
  $postalCity: String,
  $postalCode: String,
  $picture: FileUpdateOneWithoutPictureUserNestedInput
  $email: String
) {
  updateUser(
    where: { id: $applicantId },
    data: {
      firstName: { set: $firstName },
      lastName: { set: $lastName },
      mobile: { set: $mobile },
      picture: $picture,
      email: { set: $email }
    }
  ) {
    id
  }
  updateAddress(
    where: { id: $addressId },
    data: {
      postalCity: { set: $postalCity },
      postalCode: { set: $postalCode }
    }
  ) {
    id
  }
}
`

export const UPDATE_MEMBER_EDUCATION = gql`
mutation UpdateMemberEducation(
  $candidateId: String!,
  $educationId: String!
) {
  updateCandidate(
    where: { id: $candidateId },
    data: {
      education: { connect: { id: $educationId } },
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_CV = gql`
mutation UpdateMemberCV(
  $interestId: String!,
  $cv: FileUpdateOneWithoutCvInterestNestedInput
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      cv: $cv,
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_OCCUPATIONS = gql`
mutation UpdateMemberOccupations(
  $interestId: String!,
  $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!,
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      occupationalGroups: { set: $occupationalGroupsIds },
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_REGIONS = gql`
mutation UpdateMemberRegions(
  $interestId: String!,
  $regionsIds: [RegionWhereUniqueInput!]!,
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      regions: { set: $regionsIds },
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_LINKS = gql`
mutation UpdateMemberLinks(
  $interestId: String!,
  $links: String,
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      links: { set: $links }
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_DOCS = gql`
mutation UpdateMemberDocs(
  $interestId: String!,
  $docsIds: [FileWhereUniqueInput!]!
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      docs: { set: $docsIds },
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_INFO = gql`
mutation UpdateMemberInfo(
  $interestId: String!,
  $information: String,
) {
  updateInterest(
    where: { id: $interestId },
    data: {
      information: { set: $information },
    }
  ) {
    id
  }
}
`
export const UPDATE_MEMBER_SPAM = gql`
mutation UpdateMemberSpam(
  $candidateId: String!,
  $spam: Boolean,
) {
  updateCandidate(
    where: { id: $candidateId },
    data: {
      spam: { set: $spam },
    }
  ) {
    id
  }
}
`
export const DELETE_MEMBER = gql`
mutation DeleteMember($id: String!) {
  deleteMember(id: $id) {
    id
  }
}
`
export const JOB_ADS = gql`
query JobAds($now: DateTime) {
  jobAds(orderBy: { publishStart: { sort: desc } }, where, : {
    recruitment: {
      state: { equals: ACTIVE },
      recruitmentSelectedPublishChannels: {
        some: {
          activated: { equals: true },
          publishChannel: {
            title: { equals: "jobbet.se" }
          }
        }
      }
    },
    publishStart: { lt: $now },
    publishStop: { gte: $now }
  }) {
    id
    text
    publishStart
    publishStop
    createdAt
    updatedAt
    image {
      id
      url
    }
    logotype {
      id
      url
    }
    recruitment {
      title
      applyStart
      applyStop
      applyUrl
      occupationalAreas { name }
      occupationalGroups { name }
      regions { name }
      company {
        id
        name
        logotype {
          id
          url
        }
      }
    }
  }
}
`

import React from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import {
    assign,
    isEmpty
} from 'lodash/fp'

import { getRoleName, UserRoles, ADMIN_COMPANY_ID } from '_root/constants'
import MainLayout from '_layout/main-layout'
import { SaveButton } from '_layout/buttons'
import { H2, FlexRow, FlexCol, Label, Input, Spinner } from '_layout/form-elements'
import ObjectPickerBase from '_components/object-picker'
import message from '_components/message'

import * as url from '_routes/url-names'
import to from '_services/await.to'
import inject from '_services/inject'

import * as EmailValidator from 'email-validator'

const ObjectPicker = styled(ObjectPickerBase)`
    margin-bottom: 20px;
`

//#region GQL
const GETUSER_QUERY = gql`
query UserQuery($userId: String!) {
    user(where: { id: $userId }) {
        id
        active
        disabled
        firstName
        lastName
        role
        phone
        mobile
        email
        employer {
            id
            name
            urlName
        }
    }
}
`
const UPDATE_USER_MUTATION = gql`
mutation UpdateUserMutation(
    $id: String!,
    $firstName: String!,
    $lastName: String!,
    $role: UserRole,
    $email: String!,
    $phone: String,
    $mobile: String,
) {
    updateUser(
        where: { id: $id },
        data: {
            firstName: { set: $firstName },
            lastName: { set: $lastName },
            role: { set: $role },
            phone: { set: $phone },
            mobile: { set: $mobile },
            email: { set: $email }
        }
    ) {
        id
    }
}
`
//#endregion

@compose(
    withRouter,
    inject('user'),
    graphql(GETUSER_QUERY, { name: 'userQuery', options: props => ({ variables: { userId: props.match.params.id } }) }),
    graphql(UPDATE_USER_MUTATION, { name: 'updateUser' })
)
export default class EditUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.initialState
    }

    initialState = {
      jobbetRoles: [
        { id: UserRoles.SUPERADMIN, name: getRoleName(UserRoles.SUPERADMIN) },
        { id: UserRoles.SITEADMIN, name: getRoleName(UserRoles.SITEADMIN) }
      ],
      groupRoles: [
        { id: UserRoles.GROUPADMIN, name: getRoleName(UserRoles.GROUPADMIN) },
        { id: UserRoles.GROUPRECRUITER, name: getRoleName(UserRoles.GROUPRECRUITER) },
        { id: UserRoles.GROUPVIEWER, name: getRoleName(UserRoles.GROUPVIEWER) }
      ],
      clientRoles: [
        { id: UserRoles.ADMINISTRATOR, name: getRoleName(UserRoles.ADMINISTRATOR) },
        { id: UserRoles.RECRUITER, name: getRoleName(UserRoles.RECRUITER) },
        { id: UserRoles.VIEWER, name: getRoleName(UserRoles.VIEWER) }
      ],
      userFirstname: '',
      userLastname: '',
      userMail: '',
      userPhone: '',
      userMobile: '',
      userRole: undefined,
      missingInput: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}
        const { userQuery } = nextProps
        if (!userQuery.loading && userQuery.user && prevState.userId !== userQuery.user.id) {
            const { user } = userQuery
            assign.convert({"immutable": false})(nextState, {
                jobbetUser: user.employer && user.employer.id === ADMIN_COMPANY_ID,
                userId: user.id || '',
                userFirstname: user.firstName || '',
                userLastname: user.lastName || '',
                userMail: user.email || '',
                userPhone: user.phone || '',
                userMobile: user.mobile || '',
                userEmployer: user.employer,
                userRole: user.role ? { id: user.role, name: getRoleName(user.role) } : undefined
            })
        }
        return !isEmpty(nextState) ? nextState : null
    }

    clearState = () => {
        this.setState({...this.initialState})
    }

    selectedRole = userRole => { this.setState({ userRole })}

    updateUser = async () => {
        this.setState({saving: true})
        const { userId, userFirstname, userLastname, userMail, userPhone, userMobile, userRole } = this.state
        const userVariables = {
            id: userId,
            firstName: userFirstname,
            lastName: userLastname,
            role: userRole.id,
            email: userMail,
            phone: userPhone,
            mobile: userMobile
        }
        const [error, result] = await to(this.props.updateUser({ variables: userVariables }))
        if (error || !result) {
            message('Något gick tyvärr fel när användaruppgifterna skulle sparas.')
            console.error('Users:saveUser', error, userVariables)
            return
        }
        message('Användaruppgifterna har blivit sparade.')
        this.setState({saving: false}, this.props.userQuery.refetch)
    }

    render() {

        const { userQuery, user } = this.props

        const editUser = userQuery.user

        const {
          jobbetUser, userFirstname, userLastname, userMail, userPhone, userMobile, userEmployer,
          jobbetRoles, groupRoles, clientRoles, userRole, missingInput, saving
        } = this.state

        const userRoles = jobbetUser ? jobbetRoles : user.companyView.group ? groupRoles : clientRoles

        const canSave = userFirstname && userLastname && userRole && EmailValidator.validate(userMail)

        const isAdmin = ["SUPERADMIN", "SITEADMIN", "GROUPADMIN", "ADMINISTRATOR"].indexOf(user.role) > -1

        const usersUrl = '/' + (!!userEmployer ? userEmployer.urlName : this.props.match.params.company) + url.INSTALLNINGAR + url.INST_USERS

        return (
            <MainLayout padding>
                <H2>Redigera användaruppgifter</H2>
                <FlexRow className='margin20'>
                    {!userQuery.loading && !editUser &&
                    <FlexCol>Hittar inte angiven användare.</FlexCol>
                    }
                    {userQuery.loading &&
                    <FlexCol><Spinner/></FlexCol>
                    }
                    {!userQuery.loading && editUser &&
                    <FlexCol>
                        {editUser.disabled &&
                        <h3>(inaktiverad användare)</h3>
                        }
                        {userEmployer &&
                        <React.Fragment>
                            <Label>Företag</Label>
                            <div>{userEmployer.name}</div>
                            <br/>
                        </React.Fragment>
                        }
                        <Label>Förnamn *</Label>
                        <Input type='text'
                            placeholder='Förnamn *'
                            readOnly={!isAdmin}
                            value={userFirstname}
                            required
                            onChange={e => this.setState({ userFirstname: e.target.value })} />
                        <Label>Efternamn *</Label>
                        <Input type='text'
                            placeholder='Efternamn *'
                            readOnly={!isAdmin}
                            value={userLastname}
                            required
                            onChange={e => this.setState({ userLastname: e.target.value })} />
                        <Label>E-post *</Label>
                        <Input type='text'
                            placeholder='E-postadress *'
                            readOnly={!isAdmin}
                            value={userMail}
                            required
                            onChange={e => this.setState({ userMail: e.target.value })} />
                        <Label>Telefon</Label>
                        <Input type='text'
                            placeholder='Telefonnummer'
                            readOnly={!isAdmin}
                            value={userPhone}
                            onChange={e => this.setState({ userPhone: e.target.value })} />
                        <Label>Mobiltelefon</Label>
                        <Input type='text'
                            placeholder='Mobilnummer'
                            readOnly={!isAdmin}
                            value={userMobile}
                            onChange={e => this.setState({ userMobile: e.target.value })} />
                        <Label>Välj systemroll *</Label>
                        <ObjectPicker
                            form={'true'}
                            readOnly={!isAdmin}
                            values={userRoles}
                            placeholder='Välj roll...'
                            current={userRole}
                            onSelected={this.selectedRole}
                            noMatch='Inga träffar' />
                        <div>
                            {isAdmin && <SaveButton disabled={!canSave} onClick={this.updateUser} loading={saving}>Spara användare</SaveButton>}
                            {missingInput && // This should never happen
                                <p>Vänligen fyll i alla obligatoriska fält.</p>
                            }
                        </div>
                        <Link to={usersUrl} className='mv4'>Till hantera användare &raquo;</Link>
                    </FlexCol>
                    }
                </FlexRow>
            </MainLayout>
        )
    }
}

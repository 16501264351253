import React from "react"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import { isEqual } from "lodash/fp"
import { Spinner } from "_layout/form-elements"
import ManageInterview from "_root/components/interview/manage-interview"
import { REK_APPLICATION } from "_routes/url-names"
import { BOOKED_INTERVIEWS, EDIT_INTERVIEW_MUTATION } from "_containers/interview/interview-ql"
import inject from "_services/inject"

//#region Styles
const Content = styled.div`
  padding: 1em 40px;
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  .heading {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .shadow {
    box-shadow: 0px 3px 5px var(--color-bg-grey);
  }

  .moreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 20px auto;
  }
`
const Rows = styled.div`
  flex: 1;
  margin: 0;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  &.content {
    min-height: 56px;
  }

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`
const InfoArea = styled.div`
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  cursor: ${props => (props.$pointer ? "pointer" : "default")};
  position: relative;
  flex: ${props => props.$flex || "1"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.$left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);

  ${Row}.header & {
    border: none;
  }

  ${Row}.content:hover & {
    cursor: pointer;
    background: var(--color-bg-lightest);
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  &.date {
    flex: 0 0 180px;
  }

  &.spots {
    flex: 0 0 140px;
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0 10px;
  }

  .content & {
    padding: 5px 10px;
    ${(props) => (props.$toggle ? "padding-right: 50px;" : null)};
  }

  .menu {
    pointer-events: auto;
    opacity: 0;
    visibility: hidden;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);

    &.type-light.border {
      border: 1px solid var(--color-line-dark);

      &.place-bottom:before {
        border-bottom-color: var(--color-line-dark);
      }
      &.place-top:before {
        border-top-color: var(--color-line-dark);
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
`
const Text = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: flex-start;
`
//#endregion Styles

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(BOOKED_INTERVIEWS, { name: "interviewsQuery", options: (props) => ({ variables: { company: props.user.companyView.id, now: new Date().toISOString() } }) }),
  graphql(EDIT_INTERVIEW_MUTATION, { name: "editInterview" })
)
export default class BookedInterviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interviewDialog: false,
      sortOrder: {
        name: "asc",
      },
    }
  }

  componentDidMount = () => {
    document.getElementById("booked-interviews-content").scrollIntoView(true)
  }

  openApplication = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { id, company } = e.currentTarget.dataset
    window.open(`/${company}${REK_APPLICATION}/${id}?q=1`, id, "width=1200,height=800,location=no")
    return false
  }

  setSortOrder = (prop) => {
    // const order = this.state.sortOrder[prop] !== 'desc' ? 'desc' : 'asc'
    // let sortOrder = {}
    // sortOrder[prop] = order
    // this.setState({ sortOrder }, this.filterCompanies)
  }

  openApplication = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { id, company } = e.currentTarget.dataset
    window.open(`/${company}${REK_APPLICATION}/${id}?q=1`, id, "width=1200,height=800,location=no")
    return false
  }

  editInterview = (e) => {
    const interview = this.props.interviewsQuery.interviews.find((i) => i.id === e.currentTarget.id)
    this.setState({ selected: interview, interviewDialog: true })
  }

  onCloseInterview = () => {
    this.setState({ interviewDialog: false, selected: null })
  }

  onEditInterview = async (interview) => {
    const { selected } = this.state
    if (!isEqual(interview)(selected)) {
      const variables = {
        id: interview.id,
        startTime: interview.startTime,
        endTime: interview.endTime,
        spots: interview.spots,
        location: interview.location,
        note: interview.note,
        guest: interview.guest,
        interviewers: interview.interviewers.map((i) => ({ id: i.id })),
        interviewersExternal: interview.interviewersExternal.map((i) => ({ id: i.id })),
      }
      await this.props.editInterview({ variables: variables })
    }
    this.props.interviewsQuery.refetch()
    this.setState({ selected: null, interviewDialog: false })
  }

  render() {
    const { sortOrder, interviewDialog, selected } = this.state
    const {
      interviewsQuery: { loading, interviews = [] },
      filter,
    } = this.props
    let filtered = interviews
    if (filter && filter.length) {
      filtered = interviews.filter((v) => v.interviewers.concat(v.interviewersExternal).find((i) => filter.find((f) => f.id === i.id)))
    }

    return (
      <Content id="booked-interviews-content">
        <Wrapper>
          <h2>Bekräftade tider</h2>
          <Row className="header">
            <InfoArea>
              <InfoCell className="date" onClick={(e) => this.setSortOrder("startTime")}>
                Från
                <Arrow className={sortOrder.startTime} />
              </InfoCell>
              <InfoCell className="date" onClick={(e) => this.setSortOrder("endTime")}>
                Till
                <Arrow className={sortOrder.endTime} />
              </InfoCell>
              <InfoCell className="candidate" onClick={(e) => this.setSortOrder("candidate")}>
                Kandidater
                <Arrow className={sortOrder.candidate} />
              </InfoCell>
              <InfoCell onClick={(e) => this.setSortOrder("participators")}>
                Medverkande
                <Arrow className={sortOrder.participators} />
              </InfoCell>
              <InfoCell onClick={(e) => this.setSortOrder("category")}>
                Plats för möte
                <Arrow className={sortOrder.category} />
              </InfoCell>
              <InfoCell onClick={(e) => this.setSortOrder("job")}>
                Tjänst
                <Arrow className={sortOrder.note} />
              </InfoCell>
              <InfoCell onClick={(e) => this.setSortOrder("note")}>
                Notering
                <Arrow className={sortOrder.note} />
              </InfoCell>
            </InfoArea>
          </Row>
          <Rows>
            {loading && <Spinner />}
            {!loading && !filtered.length && <p>Just nu finns inga bekräftade tider.</p>}
            {!!filtered.length &&
              filtered.map((interview) => (
                <Row className="content" key={interview.id} onClick={this.editInterview} id={interview.id}>
                  <InfoArea>
                    <InfoCell className="date">{format(parseISO(interview.startTime), "dd MMM HH:mm")}</InfoCell>
                    <InfoCell className="date">{format(parseISO(interview.endTime), "dd MMM HH:mm")}</InfoCell>
                    <InfoCell>
                      {interview.applications
                        .filter((a) => !!a.application)
                        .map((a) => (
                          <a key={a.id} onClick={this.openApplication} data-id={a.application.id} data-company={interview.company.urlName}>
                            {a.application.candidate.user.fullName}
                          </a>
                        ))}
                    </InfoCell>
                    <InfoCell>
                      {interview.interviewers
                        .concat(interview.interviewersExternal)
                        .map((i) => i.fullName)
                        .join(", ")}
                    </InfoCell>
                    <InfoCell>
                      <Text data-tooltip-id="root-tooltip" data-tooltip-content={interview.location} data-tooltip-place="top">
                        {interview.location || "-"}
                      </Text>
                    </InfoCell>
                    <InfoCell>
                      {!!interview.applications[0].application && (
                        <Text data-tooltip-id="root-tooltip" data-tooltip-content={interview.applications[0].application.recruitment.title} data-tooltip-place="top">
                          {interview.applications[0].application.recruitment.title}
                        </Text>
                      )}
                    </InfoCell>
                    <InfoCell>
                      <Text data-tooltip-id="root-tooltip" data-tooltip-content={interview.note} data-tooltip-place="top">
                        {interview.note || "-"}
                      </Text>
                    </InfoCell>
                  </InfoArea>
                </Row>
              ))}
          </Rows>
          {!!selected && <ManageInterview open={interviewDialog} selected={selected} onClose={this.onCloseInterview} onEdit={this.onEditInterview} />}
        </Wrapper>
      </Content>
    )
  }
}

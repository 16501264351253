import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { isUndefined } from "lodash"
import spinner from "_images/spinner.gif"
import CheckmarkIcon from "_images/check_red.svg"
import EditIconSrc from "_images/edit-icon.svg"
import EditIconRedSrc from "_images/edit-icon-red.svg"
import CloseIconSrc from "_images/cross.svg"
import CloseIconRedSrc from "_images/cross-red-bg.svg"
import UserPickerBase from "_components/user-picker"
import FilePickerBase from "_components/file-picker"
import { isEqual } from "lodash/fp"

export const H2 = styled.h2`
  margin: 0 20% 30px 20%;
  max-width: 760px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-line-light);

  @media screen and (max-width: 767px) {
    margin: 0 20px 20px 20px;
  }
`
export const FlexRow = styled.div`
  overflow: ${props => (props.$scroll ? "auto" : "initial")};
  position: ${props => (props.$relative ? "relative" : "initial")};
  display: flex;
  flex-wrap: ${props => (props.$nowrap ? "nowrap" : "wrap")};
  flex-direction: ${props => (props.$column ? "column" : "row")};
  justify-content: ${props => (props.$center ? "center" : props.$between ? "space-between" : "flex-start")};
  align-items: ${props => (props.$middle ? "center" : "flex-start")};
  column-gap: ${props => props.$gap || "none"};
  row-gap: ${props => props.$gap || "none"};
`
export const FlexCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: ${props => `${props.$full ? "1" : "0"} 1 ${props.$left || props.$right ? "50%" : "auto"}`};
  align-self: stretch;
  align-items: ${props => (props.$center ? "center" : props.$stretch ? "stretch" : "flex-start")};
  justify-content: ${props => (props.$center ? "center" : "flex-start")};
  padding-right: ${props => props.$left || "initial"};
  padding-left: ${props => props.$right || "initial"};
`
export const Label = styled.label`
  display: block;
  font-weight: ${props => (props.$normal ? "400" : "700")};
  margin-bottom: 10px;
`
export const Input = styled.input`
  width: ${props => (props.width ? props.width : "min(300px, 100%)")};
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--color-line);
  background: ${props => (props.$invalid ? "var(--color-bg-invalid)" : "var(--color-bg-white)")};

  &:hover {
    border: 1px solid var(--color-line-dark);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-line-dark);
    box-shadow: 0px 1px 5px var(--color-line-dark);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
const UnitStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.width ? props.width : "min(300px, 100%)")};
  margin-bottom: 20px;
  border: 1px solid var(--color-line);
  background: ${props => (props.$invalid ? "var(--color-bg-invalid)" : "var(--color-bg-white)")};

  input:not([type="radio"]),
  input:not([type="checkbox"]) {
    padding: 10px;
    width: 100%;
    border: none;
    outline: none;
  }

  span {
    padding-right: 0.5rem;
    height: 100%;
  }

  &:hover {
    border: 1px solid var(--color-line-dark);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-line-dark);
    box-shadow: 0px 1px 5px var(--color-line-dark);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
export const UnitInput = props => (
  <UnitStyle>
    <input {...props} />
    <span>{props.unit}</span>
  </UnitStyle>
)

export const Readonly = styled.div`
  width: ${props => (props.width ? props.width : "min(300px, 100%)")};
  margin-bottom: 20px;
`
export const Password = props => <Input type="password" autoComplete={props.autoComplete} $invalid={props.$invalid} placeholder={props.placeholder || ""} onChange={props.onChange} />

export const Logo = styled.img`
  margin-bottom: 20px;
  max-width: min(300px, 100%);
  height: auto;
`
export const EditIcon = styled.i`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 12px;
  right: -12px;
  width: 24px;
  height: 24px;
  background: var(--color-bg-white) url(${EditIconSrc}) scroll 0 0 no-repeat;
  background-size: 24px 24px;
  border-radius: 50%;
  z-index: 2;

  &:hover {
    transform: scale(95%);
  }
`
export const EditIconRed = styled(EditIcon)`
  width: 28px;
  height: 28px;
  border: 2px solid var(--color-bg-white);
  background: var(--color-brand-red) url(${EditIconRedSrc}) scroll 0 0 no-repeat;

  @media (max-width: 360px) {
    top: 6px;
    right: 6px;
  }
`
export const CloseIcon = styled.i`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 12px;
  right: -12px;
  width: 24px;
  height: 24px;
  background: url(${CloseIconSrc}) center center no-repeat;
  z-index: 2;

  &:hover {
    transform: scale(95%);
  }
`
export const CloseIconRed = styled(CloseIcon)`
  width: 28px;
  height: 28px;
  border: 2px solid var(--color-bg-white);
  background: url(${CloseIconRedSrc}) scroll 0 0 no-repeat;
  border-radius: 50%;

  @media (max-width: 360px) {
    top: 6px;
    right: 6px;
  }
`
export const UserPicker = styled(UserPickerBase)`
  margin-bottom: 20px;
`
export const TextArea = styled.textarea`
  width: ${props => props.width || "300px"};
  height: ${props => props.height || "auto"};
  line-height: 1.5;
  margin-bottom: 20px;
  border: 1px solid var(--color-line);

  &:hover {
    border: 1px solid var(--color-line-dark);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-line-dark);
    box-shadow: 0px 1px 6px var(--color-line-dark);
  }
`
export const ButtonLink = styled.a`
  cursor: pointer;
  display: inline-block;
  width: 100px;
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 0.9em;
  color: var(--color-text-dark);
  border: 1px solid var(--color-bg-light);
  background: var(--color-bg-lightest);

  &:hover {
    opacity: 0.75;
    text-decoration: none;
  }

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    opacity: 0.5;
  }
`
export const ButtonNavLink = styled(NavLink)`
  cursor: pointer;
  display: inline-block;
  width: 100px;
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 0.9em;
  color: var(--color-text-dark);
  border: 1px solid var(--color-bg-light);
  background: var(--color-bg-lightest);

  &:hover {
    opacity: 0.75;
    text-decoration: none;
  }

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    opacity: 0.5;
  }
`
export const Spinner = props => (
  <div>
    <img src={spinner} className={`spinner ${props.className}`} alt="Laddar..." title="Laddar..." />
  </div>
)
export const SmallSpinner = props => (
  <div className="spinner-container">
    <img src={spinner} className={`small spinner ${props.className ? props.className : ""}`} alt="Laddar..." title="Laddar..." />
  </div>
)

const GradeStyle = styled.label`
  cursor: pointer;
  display: inline-flex;
  margin: ${props => props.$margin || "10px"};
  flex: none;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.$width || "30px"};
    height: ${props => props.$height || "30px"};
    border: 1px solid var(--color-bg-grey-dark);
    color: var(--color-text);
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-lightest);
    }
  }

  input {
    display: none;

    &:checked + span {
      color: var(--color-text-white);
      background: var(--color-bg-grey-dark);
    }
  }
`
export const Grade = props => {
  const { level, onChange, onClick, checked, readOnly, children, ...ownProps } = props
  return (
    <GradeStyle {...ownProps}>
      <input type="radio" value={level} onChange={onChange} onClick={onClick} checked={checked} disabled={readOnly} />
      <span>{level || children}</span>
    </GradeStyle>
  )
}

const RadioStyle = styled.label`
  display: inline-flex;
  align-items: center;
  margin: ${props => props.$margin || "0 10px 10px 0"};
  flex: none;

  .radio {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: ${props => (props.$label ? "10px" : "0")};
    width: ${props => props.$width || "16px"};
    height: ${props => props.$height || "16px"};
    border-radius: 8px;
    border: 1px solid var(--color-bg-grey);
    color: var(--color-text);
    background: var(--color-bg-white);
    box-shadow: 0 1px 3px 0 rgba(128, 128, 128, 0.7);

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 3px solid var(--color-bg-white);
    }
    &:active {
      background: var(--color-bg-grey);
    }
    &:hover {
      border: 1px solid var(--color-bg-grey-dark);
    }
  }

  .label {
    display: inline-block;
    width: calc(100% - 26px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    display: none;

    &:checked + .radio {
      border: 1px solid var(--color-brand-red-dark);
      color: var(--color-text-white);
      box-shadow: none;

      &:before {
        border-color: var(--color-brand-red);
      }
      &:hover {
        opacity: 0.75;
      }
    }

    &[disabled] + .radio,
    &[disabled]:checked + .radio {
      cursor: default;
      opacity: 0.5;
      box-shadow: none;
      pointer-events: none;
    }
  }
`
export const Radio = props => (
  <RadioStyle $label={props.label} $margin={props.margin} $width={props.width} $height={props.height}>
    {props.onChange && (
      <input type="radio" name={props.name} value={props.value} onChange={props.onChange} onClick={props.onClick} checked={props.checked} disabled={props.readOnly || props.disabled} />
    )}
    {!props.onChange && <input type="radio" name={props.name} value={props.value} onClick={props.onClick} defaultChecked={props.checked} disabled={props.readOnly || props.disabled} />}
    <span className="radio" />
    {props.label && <span className="label">{props.label}</span>}
  </RadioStyle>
)

const LargeRadioStyle = styled.label`
  cursor: ${props => (props.readOnly ? "default" : "pointer")};
  display: inline-flex;
  margin: ${props => props.$margin || "0 10px 10px 0"};
  flex: none;

  .largeradio__span {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.$width || "28px"};
    height: ${props => props.$height || "28px"};
    border-radius: 14px;
    background: var(--color-bg-white);
    border: 3px solid var(--color-brand-red-light);

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 3px solid var(--color-bg-white);
      background-color: var(--color-bg-white);
    }
    &:active {
      background: var(--color-bg-grey);
    }
  }

  input {
    display: none;

    &:checked + .largeradio__span {
      &:before {
        background-color: var(--color-brand-red-light);
      }
    }
  }
`
export const LargeRadio = props => {
  const { name, value, onChange, onClick, checked, readOnly, ...ownProps } = props

  return (
    <LargeRadioStyle {...ownProps} readOnly={!!readOnly}>
      <input type="radio" name={name} value={value} disabled={readOnly} onChange={onChange || (() => {})} onClick={onClick} defaultChecked={checked} />
      <span className="largeradio__span" />
      {props.children}
    </LargeRadioStyle>
  )
}

const CheckboxStyle = styled.label`
  display: inline-flex;
  align-items: center;

  .checkbox {
    cursor: pointer;
    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    width: ${props => props.$width || "16px"};
    height: ${props => props.$height || "16px"};
    margin: ${props => props.$margin || "0 10px 0 0"};
    border-radius: 2px;
    border: 1px solid var(--color-bg-grey);
    background: var(--color-bg-white);
    box-shadow: 0 1px 3px 0 rgba(128, 128, 128, 0.7);
    &:active {
      background: var(--color-bg-grey);
    }
    &:hover {
      border: 1px solid var(--color-bg-grey-dark);
    }
  }

  .label {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    min-height: ${props => props.$height || "16px"};
  }

  input[type="checkbox"] {
    display: none;

    &:checked + .checkbox {
      background: url(${CheckmarkIcon}) no-repeat;
      background-size: cover;
      border-radius: 0;
      border: none;
      box-shadow: none;
      &:hover {
        opacity: 0.75;
      }
    }

    &[disabled] + .checkbox,
    &[disabled]:checked + .checkbox {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
      box-shadow: none;
    }
  }
`
export class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      checked: "checked" in props ? !!props.checked : !!props.defaultChecked || false,
      disabled: props.disabled || props.readOnly || false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { checked, disabled, readOnly } = nextProps
    const stateProps = { checked, disabled, readOnly }
    if (isEqual(stateProps)(prevState.stateProps)) {
      return null
    }
    const nextState = { stateProps }
    if (!isUndefined(nextProps.checked) && prevState.checked !== nextProps.checked) {
      nextState.checked = nextProps.checked
    }
    if (!isUndefined(nextProps.disabled) && prevState.disabled !== nextProps.disabled) {
      nextState.disabled = nextProps.disabled
    }
    if (!isUndefined(nextProps.readOnly) && prevState.disabled !== nextProps.readOnly) {
      nextState.disabled = nextProps.readOnly
    }
    return nextState
  }

  onClickHandler = (e) => {
    e.stopPropagation()
    if (this.props.disableOnClick) return

    this.setState((prevState) => ({
      checked: !prevState.checked,
    }))
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e, this.props.value)
  }

  render() {
    return (
      <CheckboxStyle title={this.props.title} className={this.props.className}>
        <input name={this.props.name} type="checkbox" value={this.state.value} onClick={this.onClickHandler} checked={this.state.checked} onChange={this.onChange} disabled={this.state.disabled} />
        <span className="checkbox" />
        {this.props.label && <span className="label">{this.props.label}</span>}
      </CheckboxStyle>
    )
  }
}

export const RecruitGrid = styled.div`
  display: grid;
  justify-items: center;
  gap: 30px 40px;
  grid-template-columns: minmax(640px, max-content);
  grid-template-rows: repeat(3, min-content);
  grid-template-areas:
    "input-area"
    "info-area"
    "deals-area"
    "button-area";
  padding: ${props => props.$padding || 0};

  .input-area {
    grid-area: input-area;
  }

  .info-area {
    grid-area: info-area;
  }

  .deals-area {
    grid-area: deals-area;
  }

  .button-area {
    grid-area: button-area;
  }

  @media screen and (min-width: 1500px) {
    justify-items: flex-start;
    grid-template-columns: 360px 1fr;
    grid-template-rows: repeat(4, min-content);
    grid-template-areas:
      "input-area info-area"
      "input-area deals-area"
      "button-area deals-area"
      ". deals-area";
  }
`
export const FilePicker = FilePickerBase

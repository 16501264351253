import React from 'react'
import MainLayout from '_layout/main-layout'
import { TemplateType } from '_root/constants'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'
import TemplateList from '_root/components/templates/template-list';
import inject from '_services/inject'

@inject('user')
export default class RecruitmentTemplates extends React.Component {
  render() {
    const { user } = this.props
    return (
      <MainLayout padding maxHeight scroll>
        <H2>{user.companyView.isJobbet ? 'Grundmallar' : 'Rekryteringsmallar'}</H2>
        <div>
          <FlexRow className='margin20'>
            <FlexCol $full $stretch>
              <p>
                Här skapar ni företagsanpassade mallar för kravprofil, intervjuer och referenstagning. Ni kan med fördel utgå från Jobbet.se's grundmallar. Klicka på en grundmall, gör de anpassningar ni önskar och ger er nya mall ett nytt namn.
              </p>
              <h3 className='mb3'>Intervjumallar</h3>
              <TemplateList type={TemplateType.INTERVIEW} title='intervjuer' />
              <h3 className='mb3'>Referensmallar</h3>
              <TemplateList type={TemplateType.REFERENCES} title='referenser' />
            </FlexCol>
          </FlexRow>
        </div>
      </MainLayout>
    )
  }
}

import React from 'react'
import { confirmable } from 'react-confirm'
import styled from 'styled-components'
import ModalBase from '_components/modal'
import { PrimaryButton } from '_layout/buttons'

const Button = styled(PrimaryButton)`
  margin: 20px 10px 0 10px;
  width: 100px;
  color: #ffffff;
  background-color: #e2001a;

  &:hover {
    background-color: #a61f1f;
  }
`

@confirmable
export default class MessageDialog extends React.Component {
  render() {
    const { show, proceed, dismiss, message, options, min } = this.props

    return (
    <ModalBase isOpen={show} onRequestClose={dismiss} center min={min}>
      <h3 dangerouslySetInnerHTML={{__html: message}}/>
      {options && options.text &&
      <p>{options.text}</p>
      }
      <Button onClick={proceed}>OK</Button>
    </ModalBase>
    )
  }
}

import React from 'react'
import { gql } from '@apollo/client'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import styled from 'styled-components'
import draftToHtml from 'draftjs-to-html'
import TinyEditor from '_components/tiny-editor'
import { PrimaryButton, CancelButton } from '_layout/buttons'
import { DOCUMENT_QUERY } from '_containers/recruitment/application-ql'
import inject from '_services/inject'
import ctrlKey from '_utils/ctrlKey'
import confirm from '_components/confirm'
import to from '_services/await.to'

//#region Styles
const Content = styled.div`
  padding: 20px;
`
const Header = styled.div`
  display: flex;
  padding: 16px 8px;
  line-height: 1.4rem;
  background: var(--color-brand-green-light);

  > div:first-child {
    font-weight: bold;
    margin-right: 2rem;
  }
`
//#endregion Styles

@compose(
  withRouter,
  withApollo,
  inject('user'),
  graphql(DOCUMENT_QUERY, { name: 'documentQuery', options: props => ({ variables: { id: props.match.params.protocol }, notifyOnNetworkStatusChange: true }) })
)
export default class ApplicationProtocol extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.protocol,
      name: '',
      document: null,
      isDirty: false,
      isMounted: false,
      isLoaded: false
    }
  }

  componentDidMount = () => {
    this.setState({isMounted: true})
    this.timer = setInterval(() => {
      if (this.state.isDirty) this.onSave()
    }, 2700)
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (!prevState.document && !nextProps.documentQuery.loading) {
      const document = nextProps.documentQuery.document
      return {
        document: document,
        id: document.id,
        name: document.name,
        type: document.type,
        content: document.content,
        contentHTML: document.contentHTML || (document.content ? draftToHtml(document.content) : null),
        isLoaded: true
      }
    }
    return null
  }

  onNameChange = e => {
    this.setState({name: e.target.value, isDirty: true})
  }

  onContentChange = contentHTML => {
    if (!this.state.isMounted) return
    this.setState({contentHTML, isDirty: true})
  }

  onDirty = () => {
    this.setState({ isDirty: true })
  }

  onSave = async () => {
    const { id, name, contentHTML } = this.state
    const mutation = gql`mutation UpdateDocument($id: String!, $name: String, $contentHTML: String) {
      updateDocument(where: { id: $id }, data: { name: { set: $name }, contentHTML: { set: $contentHTML } }) {
        id
      }
    }`
    const [error, result] = await to(this.props.client.mutate({ mutation, variables: { id: id, name: name, contentHTML: contentHTML }}))
    if (error || !result) {
      console.error('application-protocol:onSave:updateDocument:error', error)
      // const reload = await confirm('Ett oväntat fel inträffade.', { text: 'Klicka på OK för att ladda om sidan.'})
      // if (reload) window.location.reload()
    }
    this.setState({isDirty: false})
  }

  closeDocument = async () => {
      if (this.state.isDirty && await confirm('Vill du spara dina ändringar?',  { confirmText: 'Spara och stäng', cancelText: 'Stäng utan att spara' })) {
        await this.onSave()
      }
      window.close()
  }

  render() {
    const { isLoaded, name, content, contentHTML, isDirty } = this.state
    const pagehead = content && content.pagehead
    const saveText = isDirty ? "Det finns ändringar att spara" : "Allting är sparat"

    return (
      <Content>
        <input 
          type='text'
          className='mb3 w-100'
          placeholder={'Spara protokoll som...'}
          value={name}
          onChange={this.onNameChange} />
        {pagehead &&
        <Header>
          <div>
            Tjänst:<br/>
            Företag:<br/>
            Kandidat:<br/>
            Intervjuare:<br/>
            Datum:<br/>
          </div>
          <div>
            {pagehead.title}<br/>
            {pagehead.company}<br/>
            {pagehead.candidate.name} {pagehead.candidate.phone && <span> | <a href={`tel:${pagehead.candidate.phone}`}>{pagehead.candidate.phone}</a></span>} {pagehead.candidate.email && <span> | <a href={`mailto:${pagehead.candidate.email}`}>{pagehead.candidate.email}</a></span>}<br/>
            {pagehead.interviewer}<br/>
            {pagehead.date}<br/>
          </div>
        </Header>
        }
        <TinyEditor
          form
          key={isLoaded}
          insert='h3 bold italic underline | bullist numlist | link table'
          toolbar='undo redpo | h3 bold italic underline forecolor backcolor | bullist numlist outdent indent | link unlink | removeformat'
          plugins='autolink link lists image table'
          content={contentHTML}
          placeholder={'Fyll i ditt protokoll här... (klistra in med ' + ctrlKey + '+V)'}
          onChange={this.onContentChange}
          onDirty={this.onDirty}
          style={{ height: '100vh', maxHeight: 'calc(100vh - 300px)', minHeight: 300 }} />
        <div className='tr'>
          <span className='mr3'>{saveText}</span>
          <CancelButton className='ml3 invert' onClick={this.closeDocument}>Stäng</CancelButton>
          <PrimaryButton className='ml3' onClick={this.onSave}>Spara</PrimaryButton>
        </div>
      </Content>
    )
  }
}

import React from 'react'
import styled from 'styled-components'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { map } from 'lodash/fp'
import { Spinner } from '_layout/form-elements'
import { JOBAD_SELECTION_QUERY } from '_containers/recruitment/recruitment-ql'
import Question from '_components/applications/question'

//#region Styles
const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`
const Section = styled.section`
  padding: 40px;
`
const Selection = styled.ol`
  padding: 0 0 0 1em;
`
//#endregion

@compose(
    withRouter,
    graphql(JOBAD_SELECTION_QUERY, { name: 'selectionQuery', options: props => ({ variables: { id: props.match.params.annons } }) })
)
export default class ShowSelection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            incremented: false
        }
    }

    render() {
        let content = null
        if (this.props.selectionQuery.loading || !this.props.selectionQuery.jobAd) {
            content = <Section>
                <h3>Korrektur av urvalsfrågor</h3>
                <Spinner />
            </Section>
        }
        else {
            const { jobAd: { recruitment: { selection, title } } } = this.props.selectionQuery
            if (!selection || !selection.length) {
                content = <Section>
                    <h3>Korrektur av urvalsfrågor{title && ` för tjänsten ${title}`}</h3>
                    <p className='italic'>Urvalsfrågor saknas</p>
                </Section>
            }
            else {
                content = <Section>
                    <h3>Korrektur av urvalsfrågor{title && ` för tjänsten ${title}`}</h3>
                    <Selection>
                    {map(question =>
                        <Question key={question.id} question={question} readOnly={true} />
                    )(selection)}
                    </Selection>
                </Section>
            }
        }
        return (
            <Main>
                {content}
            </Main>
        )
    }
}

import React from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import * as EmailValidator from 'email-validator'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import MainLayout from '_layout/main-layout'
import { PrimaryButton, SaveButton } from '_layout/buttons'
import { H2, FlexRow, FlexCol, Label, Input, TextArea, Logo, UserPicker, FilePicker, Checkbox } from '_layout/form-elements'
import {
    GET_USER,
    CREATE_ADDRESS,
    CREATE_COMPANY_MUTATION,
    CREATE_ADMIN_MUTATION,
    ALL_COMPANIES,
    ALL_SUPERADMIN_QUERY
} from './new-company-ql'
import UploadService from '_services/upload-service'
import inject from '_services/inject'
import to from '_services/await.to'
import message from '_root/components/message'
import { FileType } from '_root/constants'

const Error = styled.p`
    margin: -1em 0 1em 0;
    font-size: 0.9em;
    color: var(--color-brand-red);
`

@compose(
    withApollo,
    inject('user'),
    graphql(ALL_SUPERADMIN_QUERY, { name: 'userQuery' }),
    graphql(ALL_COMPANIES, { name: 'companiesQuery' }),
    graphql(CREATE_ADDRESS, { name: 'createAddress' }),
    graphql(CREATE_COMPANY_MUTATION, { name: 'createCompanyMutation' }),
    graphql(CREATE_ADMIN_MUTATION, { name: 'createAdminMutation' })
)
export default class AdminNewCompany extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.initialState
    }

    initialState = {
        companyName: '',
        companyUrl: '',
        companyInfo: '',
        billingInfo: '',
        adminFirstname: '',
        adminLastname: '',
        adminMail: '',
        adminPhone: '',
        adminMobile: '',
        mailPreview: '',
        careerPage: false,
        sendMail: false,
        logotype: null,
        selectedAdmins: [],
        companyCreated: false,
        allCompanies: []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.allCompanies.length && nextProps.companiesQuery.companies && nextProps.companiesQuery.companies.length) {
            return { allCompanies: nextProps.companiesQuery.companies }
        }
        return {}
    }

    clearState = () => {
        this.setState({...this.initialState})
    }

    onSelectedAdmins = selected => {
        this.setState({selectedAdmins: [...selected]})
    }

    onLogoSelected = (base64, file) => {
        if (base64) {
            this.setState({ logotype: base64, logotypeFile: file })
        }
    }

    duplicateCompanyName = name => {
        const { allCompanies } = this.state
        if (!allCompanies.length || !name) return false
        return find(c => c.name.toLowerCase() === name.toLowerCase())(allCompanies)
    }

    duplicateCompanyUrl = url => {
        const { allCompanies } = this.state
        if (!allCompanies.length) return false
        return find(c => c.urlName === url)(allCompanies)
    }

    validate = () => {
        const { companyName, companyUrl, adminFirstname, adminLastname, adminMail } = this.state
        const invalid = {
            companyName: !companyName || companyName.trim().length === 0,
            duplicateCompanyName: this.duplicateCompanyName(companyName),
            companyUrl: !companyUrl || companyUrl.trim().length === 0 || !/^[a-ö0-9-]+$/i.test(companyUrl),
            duplicateCompanyUrl: this.duplicateCompanyUrl(companyUrl),
            firstName: !adminFirstname || adminFirstname.trim().length === 0,
            lastName: !adminLastname || adminLastname.trim().length === 0,
            email: !EmailValidator.validate(adminMail)
        }
        const isInvalid = invalid.companyName || invalid.duplicateCompanyName || invalid.companyUrl || invalid.duplicateCompanyUrl || invalid.firstName || invalid.lastName || invalid.email
        this.setState({ invalid: isInvalid ? invalid : null })
        return !isInvalid
    }

    checkEmail = async () => {
        const { adminMail } = this.state
        if (!EmailValidator.validate(adminMail)) {
            message('Ange en giltig e-postadress')
            return false
        }
        else {
            const [error, result] = await to(this.props.client.query({ query: GET_USER, variables:{email: adminMail}}))
            if (error || !result) {
                console.error('new-company:checkEmail:error', error, adminMail)
                return false
            }
            if (result.data && result.data.user) {
                message('Angiven e-post finns redan för en användare i systemet.')
                return false
            }
        }
        return true
    }

    setUrlName = e => {
        const urlName = e.target.value.toLowerCase()
        if (!urlName || !urlName.length) {
            this.setState({ companyUrl: '' })
        }
        else if (/^[a-ö0-9-]+$/g.test(urlName)) {
            this.setState({ companyUrl: urlName })
        }
    }

    saveCompany = async e => {
        const {
          companyName,
          companyUrl,
          companyInfo,
          billingInfo,
          selectedAdmins,
          logotypeFile,
          adminFirstname,
          adminLastname,
          adminMail,
          adminPhone,
          adminMobile,
          sendMail,
          careerPage
        } = this.state

        if (!this.validate()) return

        const [error, result] = await to(this.checkEmail())
        if (error || !result) return

        this.setState({saving: true})

        let logotypeId = null
        if (logotypeFile) {
            const [error, { data }] = await to(UploadService.uploadFile(logotypeFile))
            if (error) {
                console.error('new-company:saveCompany:uploadLogotype:', error)
            }
            else if (data) {
                logotypeId = data.id
            }
        }
        const companyVariables = {
            name: companyName,
            urlName: companyUrl.toLowerCase(),
            description: companyInfo,
            billingInfo: billingInfo,
            careerPage: careerPage,
            logotype: logotypeId ? { connect: { id: logotypeId } } : undefined,
            jobbetContactsIds: map(admin => ({ id: admin.id }))(selectedAdmins)
        }
        const [errorCompany, resultCompany] = await to(this.props.createCompanyMutation({ variables: companyVariables }))
        if (errorCompany) {
            console.error("create company error:", errorCompany)
            return
        }
        const { data: { createCompany: { id: companyId } } } = resultCompany

        const adminVariables = {
            companyId: companyId,
            companyName: companyName,
            firstName: adminFirstname,
            lastName: adminLastname,
            email: adminMail,
            phone: adminPhone,
            mobile: adminMobile,
            notify: sendMail
        }
        const [errorAdmin] = await to(this.props.createAdminMutation({ variables: adminVariables }))
        if (errorAdmin) {
          await message("Ett oväntat fel inträffade!")
          this.setState({companyCreated: false, saving: false})
          console.error("create admin error:", errorAdmin)
          return
        }
        this.setState({companyCreated: true, saving: false})
    }

    render() {

        if (this.state.companyCreated) {
            return <MainLayout padding>
                <H2>Företag skapat</H2>
                <FlexRow className='margin20'>
                    <FlexCol>
                        {this.state.mailPreview &&
                        <p><a href={this.state.mailPreview} target='_blank' rel='opener'>E-post skickat</a></p>
                        }
                        <div className='tc'>
                            <PrimaryButton onClick={this.clearState}>Tillbaka</PrimaryButton>
                        </div>
                    </FlexCol>
                </FlexRow>
            </MainLayout>
        }

        const { loading, error, users } = this.props.userQuery

        if (loading || error) return null

        const { companyName, companyUrl, companyInfo, billingInfo, adminFirstname, adminLastname, adminMail, adminPhone, adminMobile, missingInput, saving, selectedAdmins, invalid } = this.state

        return (
            <MainLayout padding maxHeight scroll>
                <div>
                    <H2>Skapa ny kund</H2>
                    <FlexRow className='margin20'>
                        <FlexCol className='mt4 mr5'>
                            <h3>Företagsuppgifter</h3>
                            <Label>Namn på företag *</Label>
                            <Input type='text'
                                placeholder='Företagsnamn *'
                                $invalid={invalid && (invalid.companyName || invalid.duplicateCompanyName)}
                                value={companyName}
                                onChange={e => this.setState({ companyName: e.target.value })}
                            />
                            {invalid && invalid.companyName &&
                               <Error>Ange företagsnamn</Error>
                            }
                            {invalid && invalid.duplicateCompanyName &&
                               <Error>Företagsnamnet finns redan</Error>
                            }
                            <Label>Företagsnamn i webbadress *</Label>
                            <Input type='text'
                              data-tooltip-id="root-tooltip"
                              data-tooltip-content='Endast a-z, 0-9 och -'
                              placeholder='Företagsnamn i webbadress *'
                              $invalid={invalid && (invalid.companyUrl || invalid.duplicateCompanyUrl)}
                              value={companyUrl}
                              onChange={this.setUrlName}
                            />
                            {invalid && invalid.companyUrl &&
                                <Error>Ange endast bokstäver och/eller siffror</Error>
                            }
                            {invalid && invalid.duplicateCompanyUrl &&
                                <Error>Denna URL finns redan för ett annat företag</Error>
                            }
                            <Label>Beskrivning av företag</Label>
                            <TextArea
                                placeholder='Företagsbeskrivning'
                                rows='3'
                                value={companyInfo}
                                onChange={e => this.setState({ companyInfo: e.target.value })}
                            />
                            {this.props.user.superAdmin &&
                                <div>
                                    <Label>Faktureringsinformation</Label>
                                    <TextArea
                                        placeholder='Fakturering'
                                        rows='3'
                                        value={billingInfo}
                                        onChange={e => this.setState({ billingInfo: e.target.value })}
                                    />
                                </div>
                            }
                            <Label>Kontaktpersoner hos Jobbet.se</Label>
                            <UserPicker
                                onSelected={this.onSelectedAdmins}
                                placeholder='Välj personer...'
                                noSelected='Inga personer valda'
                                noUsers='Inga valbara personer'
                                users={users}
                                selected={selectedAdmins}
                                selectedText='person(er) vald(a)' />
                            <FilePicker
                                label={'Logotyp för företag'}
                                fileId={'company-logo'}
                                text={'Välj fil...'}
                                accept={[FileType.JPEG, FileType.PNG, FileType.SVG]}
                                onBase64={this.onLogoSelected} />
                            {this.state.logotype &&
                                <Logo src={this.state.logotype} alt='Företagets logotyp' />
                            }
                        </FlexCol>
                        <FlexCol className='mt4'>
                            <h3>Företagets huvudadministratör</h3>
                            <Label>Förnamn *</Label>
                            <Input type='text'
                                placeholder='Förnamn *'
                                value={adminFirstname}
                                $invalid={invalid && invalid.firstName}
                                required
                                onChange={e => this.setState({ adminFirstname: e.target.value })}
                            />
                            {invalid && invalid.firstName &&
                                <Error>Ange förnamn</Error>
                            }
                            <Label>Efternamn *</Label>
                            <Input type='text'
                                placeholder='Efternamn *'
                                value={adminLastname}
                                $invalid={invalid && invalid.lastName}
                                required
                                onChange={e => this.setState({ adminLastname: e.target.value })}
                            />
                            {invalid && invalid.lastName &&
                                <Error>Ange efternamn</Error>
                            }
                            <Label>E-post *</Label>
                            <Input type='text'
                                placeholder='E-postadress *'
                                value={adminMail}
                                $invalid={invalid && invalid.email}
                                required
                                onChange={e => this.setState({ adminMail: e.target.value })}
                            />
                            {invalid && invalid.email &&
                                <Error>Ange E-post</Error>
                            }
                            <Label>Telefon</Label>
                            <Input type='text'
                                placeholder='Telefonnummer'
                                value={adminPhone}
                                $invalid={invalid && invalid.phone}
                                onChange={e => this.setState({ adminPhone: e.target.value })}
                            />
                            {invalid && invalid.phone &&
                                <Error>Ange telefonnummer</Error>
                            }
                            <Label>Mobiltelefon</Label>
                            <Input type='text'
                                placeholder='Mobilnummer'
                                value={adminMobile}
                                $invalid={invalid && invalid.mobile}
                                onChange={e => this.setState({ adminMobile: e.target.value })}
                            />
                            {invalid && invalid.mobile &&
                                <Error>Ange mobilnummer</Error>
                            }
                            <div className="mb3">
                                <Checkbox onChange={e => this.setState({sendMail: !this.state.sendMail})} label="Skicka inloggning till administratör" />
                            </div>
                            <div>
                                <SaveButton onClick={this.saveCompany} loading={saving}>Spara</SaveButton>
                                {missingInput && // This should never happen
                                    <p>Vänligen fyll i alla obligatoriska fält.</p>
                                }
                            </div>
                        </FlexCol>
                    </FlexRow>
                </div>
            </MainLayout>
        )
    }
}

import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { format, parseISO, differenceInHours } from "date-fns"
import sv from "date-fns/locale/sv"
import { Spinner } from "_layout/form-elements"
import Modal from "_components/modal"
import { PrimaryButton } from "_layout/buttons"
import ManageInterview from "_root/components/interview/manage-interview"
import TrashIcon from "_images/trash.svg"
import { REK_APPLICATIONS, REK_APPLICATION, REKRYTERING, REK_AKTIVA, REK_UTKAST, REK_AVSLUTADE } from "_routes/url-names"
import { INVITED_APPLICATIONS, EDIT_INTERVIEW_MUTATION, DELETE_INVITES_MUTATION } from "_containers/interview/interview-ql"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const Content = styled.div`
  padding: 1em 40px;
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  .heading {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .shadow {
    box-shadow: 0px 3px 5px var(--color-bg-grey);
  }

  .moreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 20px auto;
  }
`
const Rows = styled.div`
  flex: 1;
  margin: 0;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`
const Trash = styled.a`
  position: absolute;
  top: 18px;
  right: -24px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;
  z-index: 100;
`
const InfoArea = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;

  > a {
    opacity: 0.1;
    transition: opacity 0.4s;
  }

  &:hover {
    > a {
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
`
const InfoCell = styled.div`
  cursor: ${props => (props.$pointer ? "pointer" : "default")};
  position: relative;
  flex: ${props => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.$left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);

  ${Row}.header & {
    border: none;
  }

  ${Row}.content:hover & {
    background: var(--color-bg-bright);
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  &.date {
    flex: 0 0 180px;
  }

  &.spots {
    flex: 0 0 140px;
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0 10px;
  }

  .content & {
    padding: 5px 10px;
    ${props => (props.$toggle ? "padding-right: 50px;" : null)};
  }

  .menu {
    pointer-events: auto;
    opacity: 0;
    visibility: hidden;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);

    &.type-light.border {
      border: 1px solid var(--color-line-dark);

      &.place-bottom:before {
        border-bottom-color: var(--color-line-dark);
      }
      &.place-top:before {
        border-top-color: var(--color-line-dark);
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
`
const TimeSlotsList = styled.ul`
  overflow: auto;
  height: 200px;
  margin: 0 0 0.5rem 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid var(--color-bg-light);

  li {
    padding: 10px;
    font-size: 0.9rem;
    border-bottom: 1px solid var(--color-bg-light);

    &:last-child {
      border-bottom: none;
    }

    > span, a {
      display: inline-block;
      width: 120px;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: text-top;
    }

    > a {
      &:nth-of-type(1) {
        width: 90px;
      }
    }

    > span {
      &:nth-of-type(1) {
        width: 2rem;
      }
    }
  }
`
//#endregion Styles

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(INVITED_APPLICATIONS, { name: "applicationsQuery", options: props => ({ variables: { company: props.user.companyView.id } }) }),
  graphql(EDIT_INTERVIEW_MUTATION, { name: "editInterview" }),
  graphql(DELETE_INVITES_MUTATION, { name: "deleteInvites" })
)
export default class InvitedInterviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interviewDialog: false,
      slotsModalOpen: false,
      sortOrder: {
        name: "asc",
      },
    }
  }

  componentDidMount = () => {
    document.getElementById("invited-interviews-content").scrollIntoView(true)
  }

  openApplication = e => {
    e.preventDefault()
    const { id, company } = e.currentTarget.dataset
    window.open(`/${company}${REK_APPLICATION}/${id}?q=1`, id, "width=1200,height=800,location=no")
    return false
  }

  viewSlots = e => {
    e.preventDefault()
    const { id } = e.currentTarget.dataset
    const app = this.props.applicationsQuery.applications.find(a => a.id === id)
    if (!app) return
    this.setState({ appId: id, slotsModalOpen: true, slots: app.interviews.map(i => i.interview) })
    return false
  }

  setSortOrder = prop => {
    // const order = this.state.sortOrder[prop] !== 'desc' ? 'desc' : 'asc'
    // let sortOrder = {}
    // sortOrder[prop] = order
    // this.setState({ sortOrder }, this.filterCompanies)
  }

  onModalClose = e => {
    this.setState({ slotsModalOpen: false })
  }

  editInterview = e => {
    this.setState({ selectedId: e.currentTarget.dataset.id, interviewDialog: true })
  }

  onCloseInterview = () => {
    this.setState({ interviewDialog: false, selectedId: null })
  }

  onEditInterview = async interview => {
    const variables = {
      id: interview.id,
      startTime: interview.startTime,
      endTime: interview.endTime,
      spots: interview.spots,
      location: interview.location,
      note: interview.note,
      guest: interview.guest,
      interviewers: interview.interviewers.map(i => ({ id: i.id })),
      interviewersExternal: interview.interviewersExternal.map(i => ({ id: i.id })),
    }
    await this.props.editInterview({ variables: variables })
    this.props.applicationsQuery.refetch()
    this.setState({ selectedId: null, interviewDialog: false })
  }

  removeInvite = async e => {
    const appId = e.currentTarget.dataset.app
    const {
      applicationsQuery: { applications = [] },
      deleteInvites,
    } = this.props
    const app = appId && applications.find(a => a.id === appId)
    if (app) {
      const [error] = await to(deleteInvites({ variables: { appId } }))
      if (error) {
        console.error("removeInvite:error: ", error)
      } else {
        this.props.applicationsQuery.refetch()
      }
    }
  }

  render() {
    const { appId, sortOrder, slotsModalOpen, interviewDialog, selectedId } = this.state
    const {
      user,
      applicationsQuery: { loading, applications = [] },
      filter,
    } = this.props
    let slots, selected
    const app = appId && applications.find(a => a.id === appId)
    if (app) {
      slots = app.interviews.map(i => i.interview).filter(i => !!i)
      selected = slots.find(s => s.id === selectedId)
    }
    const states = { ACTIVE: REK_AKTIVA, DRAFT: REK_UTKAST, CLOSED: REK_AVSLUTADE }
    let filtered = applications.filter(a => !!a.candidate)
    if (filter && filter.length) {
      filtered = []
      applications.forEach(a => {
        if (a.candidate !== null) {
          a.interviews.forEach(i => {
            if (i.interview.interviewers.concat(i.interview.interviewersExternal).find(i => filter.find(f => f.id === i.id))) {
              filtered.push(a)
            }
          })
        }
      })
    }

    return (
      <Content id="invited-interviews-content">
        <Wrapper>
          <h2>Inbjudna kandidater</h2>
          <Row className="header">
            <InfoArea>
              <InfoCell className="candidate" onClick={e => this.setSortOrder("candidate")}>
                Kandidat
                <Arrow className={sortOrder.candidate} />
              </InfoCell>
              <InfoCell className="slots" onClick={e => this.setSortOrder("slots")}>
                Tider
                <Arrow className={sortOrder.slots} />
              </InfoCell>
              <InfoCell className="date" onClick={e => this.setSortOrder("sentDate")}>
                Skickat
                <Arrow className={sortOrder.applyDate} />
              </InfoCell>
              <InfoCell className="recruitment" onClick={e => this.setSortOrder("recruitment")}>
                Tjänst
                <Arrow className={sortOrder.recruitment} />
              </InfoCell>
              {user.superAdmin && user.companyView.id === user.employer.id && (
                <InfoCell className="company" onClick={e => this.setSortOrder("company")}>
                  Företag
                  <Arrow className={sortOrder.company} />
                </InfoCell>
              )}
            </InfoArea>
          </Row>
          <Rows>
            {loading && <Spinner />}
            {!!filtered.length &&
              filtered.map(app => (
                <Row className="content" key={app.id}>
                  <InfoArea>
                    <Trash data-tooltip-content="Ta bort inbjudan för denna kandidat" data-app={app.id} onClick={this.removeInvite} />
                    <InfoCell className="candidate">
                      <a onClick={this.openApplication} data-id={app.id} data-company={app.recruitment.company.urlName}>
                        {app.candidate.user.fullName}
                      </a>
                    </InfoCell>
                    <InfoCell className="slots">
                      <a onClick={this.viewSlots} data-id={app.id}>
                        {app.interviews.filter(i => !!i.interview).length} tider
                      </a>
                    </InfoCell>
                    <InfoCell className="date">{format(parseISO(app.interviews[0].createdAt), "yy-MM-dd")}</InfoCell>
                    <InfoCell className="recruitment">
                      <NavLink to={`/${app.recruitment.company.urlName}${REKRYTERING}${states[app.recruitment.state]}/${app.recruitment.id}${REK_APPLICATIONS}`}>{app.recruitment.title}</NavLink>
                    </InfoCell>
                    {user.superAdmin && user.companyView.id === user.employer.id && (
                      <InfoCell className="company">
                        <div>{app.recruitment.company.name}</div>
                      </InfoCell>
                    )}
                  </InfoArea>
                </Row>
              ))}
          </Rows>
        </Wrapper>
        <Modal isOpen={slotsModalOpen} onRequestClose={this.onModalClose}>
          <div>
            <h3>Skickade tider för inbjudan</h3>
            <TimeSlotsList>
              {slots &&
                slots.map(i => (
                  <li key={i.id}>
                    <a onClick={this.editInterview} data-id={i.id}>
                      {format(parseISO(i.startTime), "d MMM HH:mm", { locale: sv })}
                    </a>
                    <span>{differenceInHours(parseISO(i.endTime), parseISO(i.startTime))}h</span>
                    <span data-tooltip-content={i.location} data-tooltip-id="tooltip">
                      {i.location}
                    </span>
                    <span data-tooltip-html={i.interviewers.map(iv => iv.fullName).join("<br/>")} data-tooltip-id="tooltip">
                      {i.interviewers.map(iv => iv.firstName).join(", ")}
                    </span>
                    <span>
                      {i.vacantSpots} {i.vacantSpots !== 1 ? "platser" : "plats"}
                    </span>
                  </li>
                ))}
            </TimeSlotsList>
            {!!selected && <ManageInterview open={interviewDialog} selected={selected} onClose={this.onCloseInterview} onEdit={this.onEditInterview} />}
            <div className="tr mt3">
              <PrimaryButton onClick={this.onModalClose}>Stäng</PrimaryButton>
            </div>
          </div>
        </Modal>
      </Content>
    )
  }
}

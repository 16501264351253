import { SIGNED_URL, REGISTER_FILE } from '../common-ql'
import client from '../apollo-client'
import axios from 'axios'
import to from '_services/await.to'

const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while(n--){
        u8arr[n] = bstr.charCodeAt(n)
    }
    let file
    try {
        file = new File([u8arr], filename, {type:mime})
    }
    catch (e) {
        file = new Blob([u8arr], {type:mime})
    }
    return file
}

const uploadFile = async file => {
  const [errorSignedUrl, resultSignedUrl] = await to(client.query({ query: SIGNED_URL, variables: { filename: file.name, filetype: file.type } }))
  if (errorSignedUrl) {
      console.error('errorSignedUrl: ', errorSignedUrl)
      throw errorSignedUrl
  }
  const { data: { signedUrl } } = resultSignedUrl
  const upload = await axios.put(signedUrl.url, file)
  if (upload && upload.status === 200) {
    const [errorFile, resultFile] = await to(client.mutate({ mutation: REGISTER_FILE, variables: { id: signedUrl.id, name: signedUrl.name, type: file.type, size: file.size } }))
    if (errorFile) {
      console.error('errorFile: ', errorFile)
      throw errorFile
    }
    const { registerFile } = resultFile.data
    return { data: { id: signedUrl.id, name: registerFile.name, url: registerFile.url } }
  }
  throw new Error("Something went wrong when uploading the file: ", file.name)
}

const uploadBase64 = async (base64, filename) => {
    const file = dataURLtoFile(base64, filename)
    return await uploadFile(file)
}

export default { uploadFile, uploadBase64 }

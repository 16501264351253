import { gql } from '@apollo/client'

export const SEARCH_CANDIDATES = gql`
query SearchCandidates(
    $take: Int,
    $skip: Int,
    $query: String!
) {
    searchCandidates(query: $query, take: $take, skip: $skip) {
        total
        candidates {
            id
            applications {
                id
                information
                recruitment {
                    id
                    title
                    company {
                        id
                        name
                        urlName
                    }
                }
            }
            user {
                id
                firstName
                lastName
            }
        }
    }
}
`

import React from 'react'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import filter from 'lodash/fp/filter'
import { Label, FlexRow, FlexCol } from '_layout/form-elements'
import TrashIcon from '_images/trash.svg'
import { SecondaryButton } from '_layout/buttons'
import ObjectPickerBase from '_components/object-picker'

//#region Styles
const ObjectPicker = styled(ObjectPickerBase)`
    margin-bottom: 20px;
`
const GroupList = styled.ul`
    margin: 20px 0 0 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-top: 1px solid var(--color-bg-grey);

      &:first-child {
        border-top: none;
      }

      a {
        margin-left: 10px;
      }
    }
`
const Trash = styled.a`
  display: inline-block;
  margin-left: 1rem;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
//#endregion Styles

export default class OccupationalGroupPicker extends React.Component {
    constructor(props) {
      super(props)
      this.props = props
      this.state = {
        readonly: props.readonly,
        occupationalAreas: props.occupationalAreas,
        occupationalGroups: props.occupationalGroups,
        currentGroups: [],
        selectedArea: null,
        selectedGroup: null,
        selectedGroups: props.selected || []
      }
    }

    onSelectedArea = selectedArea => {
        if (this.state.selectedArea && selectedArea && selectedArea.id === this.state.selectedArea.id) return
        const areaId = selectedArea && selectedArea.id
        const currentGroups = filter(group => group.occupationalArea.id === areaId)(this.props.occupationalGroups)
        this.setState({selectedArea, currentGroups, selectedGroup: null})
    }

    onSelectedGroup = selectedGroup => {
        this.setState({selectedGroup})
    }

    onAddGroup = e => {
        if (find(p => p === this.state.selectedGroup)(this.state.selectedGroups)) return
        const selectedGroups = [...this.state.selectedGroups, this.state.selectedGroup]
        this.setState({selectedGroups})
        this.props.onChange && this.props.onChange(selectedGroups)
    }

    onRemoveGroup = group => {
        const selectedGroups = filter(g => g.id !== group.id)(this.state.selectedGroups)
        this.setState({selectedGroups})
        this.props.onChange && this.props.onChange(selectedGroups)
    }

    render() {
        const { occupationalAreas, selectedArea, currentGroups, selectedGroup, selectedGroups, readonly } = this.state
        return (
        <div className={this.props.className || ''}>
          {!readonly &&
          <FlexRow>
              <FlexCol className='mr3'>
                  <Label>1. Välj bransch</Label>
                  <ObjectPicker
                      width={'260px'}
                      values={occupationalAreas}
                      placeholder='Välj bransch...'
                      current={selectedArea}
                      onSelected={this.onSelectedArea}
                      noMatch='Inga träffar' />
              </FlexCol>
              <FlexCol className='mr3'>
                  <Label>2. Välj yrkesområde</Label>
                  <ObjectPicker
                      width={'260px'}
                      values={currentGroups}
                      placeholder='Välj yrkesområde...'
                      disabled={!selectedArea}
                      current={selectedGroup}
                      onSelected={this.onSelectedGroup}
                      noMatch='Inga träffar' />
              </FlexCol>
              <FlexCol>
                  <Label>3. Lägg till</Label>
                  <SecondaryButton $black={this.props.black} disabled={!selectedGroup} className='mv1' onClick={this.onAddGroup}>Lägg till</SecondaryButton>
              </FlexCol>
          </FlexRow>
          }
          {selectedGroups && selectedGroups.length > 0 &&
          <GroupList>
            {map(group => <li key={group.id}>{group.name}{!readonly && <Trash onClick={e => this.onRemoveGroup(group)} />}</li>)(selectedGroups)}
          </GroupList>
          }
        </div>
        )
    }
}

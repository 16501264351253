import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { filter, map, some, orderBy, isEmpty, isEqual } from "lodash/fp"
import Modal from "_components/modal"
import { FlexRow, FlexCol, Spinner } from "_layout/form-elements"
import { SecondaryButton } from "_layout/buttons"
import SelectionQuestion from "_components/recruit/selection-question"
import SelectionDropzone from "_components/recruit/selection-dropzone"
import SelectionRemovezone from "_components/recruit/selection-removezone"
import { ADMIN_COMPANY_ID } from "_root/constants"
import { COMPANY_SELECTION_QUERY, CREATE_RECRUITMENT_QUESTION } from "_containers/recruitment/recruitment-ql"
import ArrowSrc from "_images/arrow_right_light.svg"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const Wrapper = styled.div`
  position: relative;
  display: ${props => (props.$full ? "block" : "inline-block")};

  h3 {
    color: var(--color-text);
    text-transform: uppercase;
    text-align: left;
  }
`
const Button = styled(SecondaryButton)`
  width: ${props => (props.$full ? "100%" : "auto")};
`
const Arrow = styled.div`
  position: absolute;
  top: 250px;
  left: 50%;
  width: 60px;
  height: 60px;
  opacity: 0.5;
  border-radius: 50%;
  background: url(${ArrowSrc}) no-repeat scroll 50% 50% var(--color-brand-green);
  background-size: 50% 50%;
  transform: translateX(-50%);
`
const LeftCol = styled(FlexCol)`
  width: 500px;
  margin-right: 40px;
  padding: 20px;
  background: var(--color-bg-lightest);
  box-shadow: 0 0 10px 0 var(--color-line-dark);
  text-align: left;
`
const RightCol = styled(FlexCol)`
  width: 500px;
  padding: 20px;
  background: var(--color-bg-white);
  box-shadow: 0 0 10px 0 var(--color-line-dark);
  text-align: left;
`
//#endregion

@compose(
  inject("user"),
  graphql(CREATE_RECRUITMENT_QUESTION, { name: "createQuestion" }),
  graphql(COMPANY_SELECTION_QUERY, { name: "jobbetQuery", options: props => ({ variables: { companyId: ADMIN_COMPANY_ID } }) })
)
export default class SelectionFetch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      title: props.title,
      recruitment: props.recruitment,
    }
  }

  onDropped = async dropped => {
    const question = dropped.item || dropped
    if (!question?.answers || dropped.removezone) return
    let { selection } = this.state.recruitment
    const order = selection && selection.length ? Math.max(...map((q) => q.order)(this.state.recruitment.selection)) + 1 : 0
    const questionInput = {
      order: order || 0,
      type: question.type,
      text: question.text,
    }
    if (question.answers && question.answers.length) {
      if (question.answers[0].value) {
        questionInput.answers = { create: question.answers.map((answer) => ({ value: answer.value, position: answer.position })) }
      } else {
        questionInput.answers = { create: question.answers.map((answer, index) => ({ value: answer, position: index })) }
      }
    }
    if (this.state.recruitment.id) questionInput.recruitments = { connect: [{ id: this.state.recruitment.id }] }
    const [error, result] = await to(this.props.createQuestion({ variables: { questionInput } }))
    if (error || !result) {
      console.error("onAddedQuestion:createQuestion:error:", error)
      return
    }
    selection = orderBy((q) => q.order)(["asc"])([...this.state.selection, result.data.createQuestion])
    this.setState({ selection }, () => {
      this.props.onChange && this.props.onChange({ selectionIds: selection ? map((q) => q.id)(selection) : [] })
    })
  }

  onModalOpen = () => {
    this.props.jobbetQuery.refetch()
    this.setState({ modalOpen: true, selection: undefined })
  }

  onModalClose = () => {
    this.props.onClose && this.props.onClose()
    this.setState({ modalOpen: false })
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = {}
    const { jobbetQuery } = nextProps
    let { selection, questions } = prevState
    const prevSelectionIds = selection ? map((q) => q.id)(selection) : null
    const prevQuestionIds = questions ? map((q) => q.id)(questions) : null
    if (!selection && nextProps.recruitment) {
      nextState.recruitment = nextProps.recruitment
      selection = orderBy((q) => q.order)(["asc"])(nextProps.recruitment.selection)
    }
    if (selection && jobbetQuery && !jobbetQuery.loading && jobbetQuery.company && jobbetQuery.company.questions) {
      questions = filter((q) => !some((s) => s.text === q.text)(selection))(jobbetQuery.company.questions)
    }
    const selectionIds = selection ? map((q) => q.id)(selection) : []
    if (!isEqual(prevSelectionIds, selectionIds)) {
      nextState.selection = selection
    }
    const questionIds = questions ? map((q) => q.id)(questions) : []
    if (!isEqual(prevQuestionIds, questionIds)) {
      nextState.questions = questions
    }
    return !isEmpty(nextState) ? nextState : null
  }

  render() {
    const { modalOpen, title, questions, selection } = this.state
    const loading = this.props.jobbetQuery.loading
    const { button, className, full } = this.props
    const questionsMarkup = modalOpen && map((question) => <SelectionQuestion key={question.id} id={question.id} question={question} onAdd={this.onDropped} onAddText="Lägg till" onDropped={this.onDropped} />)(questions)
    const selectionMarkup = map((question) => <SelectionQuestion key={question.id} id={question.id} question={question} />)(selection)

    return (
      <Wrapper $full={full}>
        <Button className={className} onClick={this.onModalOpen} $full={full} loading={loading}>
          {button || "Hämta urval från Jobbet.se"}
        </Button>
        <Modal isOpen={modalOpen} onRequestClose={this.onModalClose}>
          <FlexRow $nowrap $relative>
            <Arrow />
            <LeftCol>
              <h3>Urvalsfrågor Jobbet.se</h3>
              {loading && <Spinner />}
              <SelectionRemovezone companyId={ADMIN_COMPANY_ID} onCreate={this.onCreate}>
                {questionsMarkup}
              </SelectionRemovezone>
            </LeftCol>
            <RightCol>
              <h3>Lägg till för {title}</h3>
              <SelectionDropzone>{selectionMarkup}</SelectionDropzone>
            </RightCol>
          </FlexRow>
        </Modal>
      </Wrapper>
    )
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import MemberMenu from '_components/menu/member-menu'
import isLoggedIn from '_services/is-logged-in'
import LogoImage from '_images/jobbet-logo-vit.png'
import inject from '_services/inject'
import { MEDLEM } from '_root/routes/url-names'

//#region Styles
const Main = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: var(--color-text);
  background: var(--color-bg-lighter);

  h1, h2, h3, h4, h5, label {
    font-weight: 400;
  }

  h1 {
    font-size: 28px;
    line-height: 1;
  }
`
const Head = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 30px;
  flex: 0 0 65px;
  width: 100%;
  height: 160px;
  background: var(--color-brand-red);
  box-shadow: 0px 2px 6px var(--color-bg-light);
  z-index: 1;
  
  @media (max-width: 500px) {
    padding: 0 0 0 10px;
  }
`
const LogoLink = styled(Link) `
  display: inline-block;
  margin-top: 40px;

  @media (min-width: 1400px) {
    align-self: center;
  }
`
const Logo = styled.img`
    width: auto;
    height: 40px;

    @media (max-width: 500px) {
      height: 35px;
    }
`
const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.$menuOpen ? 'transparent' : 'var(--color-brand-red)'};
  outline: none;
  z-index: 3;

  span {
    position: relative;
    margin: 0 auto;

    &, &::before, &:after {
      display: block;
      width: 30px;
      height: 4px;
      background: var(--color-bg-white);
      transition-property: all;
      transition-duration: .1s;
    }

    &:before {
      position: absolute;
      content: '';
      transform: translateY(-9px);
    }

    &:after {
      position: absolute;
      content: '';
      transform: translateY(9px);
    }

    &.open {
      background: transparent;

      &:before {
        background: var(--color-brand-red);
        transform: rotate(-45deg);
      }

      &:after {
        background: var(--color-brand-red);
        transform: rotate(45deg);
      }
    }
  }

  @media (min-width: 1100px) {
    display: none;
  }
`
const Body = styled.div`
  margin: 0 auto;
  padding: 120px 20px 10vh 20px;
  width: 100%;
  max-width: ${props => props.$nomenu && !props.$wide ? '760px' : '1030px'};
  z-index: 2;

  section {
    box-shadow: 0 8px 17px var(--color-shadow);
  }

  @media (min-width: 1100px) {
    display: ${props => props.$nomenu ? 'block' : 'grid'};
    grid-template-columns: 240px 760px;
    column-gap: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1400px) {
    padding-top: 40px;
  }

  @media (max-width: 360px) {
    padding: 120px 0 10vh 0;

    section {
      border-radius: 0;
    }
  }
`
const Content = styled.div`
  z-index: 2;
`
const Side = styled.div`
  z-index: 3;

  > .member-menu {
    visibility: ${props => props.$show ? 'visible' : 'hidden'};
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    z-index: 4;

    @media (min-width: 1100px) {
      visibility: visible;
      top: unset;
      left: unset;
      border-radius: 10px;
      width: unset;
      height: unset;
    }
  }
`
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: var(--color-brand-red);
  z-index: 3;
`
//#endregion

@compose(
  withRouter,
  inject('user')
)
export class MemberLayout extends Component {

  state = {
    menuOpen: false
  }

  componentDidMount = () => {
    document.body.classList.add('member-layout');
  }

  toggleMenu = e => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    const loggedIn = isLoggedIn()
    const { menuOpen } = this.state
    const { user, nomenu, wide } = this.props

    return <Main className="member-pages">
      {loggedIn &&
      <MenuButton onClick={this.toggleMenu} $menuOpen={menuOpen}><span className={menuOpen ? 'open' : ''} /></MenuButton>
      }
      <Head>
        <LogoLink to={MEDLEM}  title='Jobbet.se'>
          <Logo src={LogoImage} alt='Jobbet.se logotyp' title='Jobbet.se' />
        </LogoLink>
      </Head>
      <Body $nomenu={nomenu} $wide={wide}>
        {nomenu ||
        <Side $show={menuOpen}>
          {loggedIn && user.isCandidate ? <MemberMenu user={user} onClose={this.toggleMenu} /> : null}
        </Side>
        }
        <Content>
          {this.props.children}
        </Content>
      </Body>
      <Footer/>
    </Main>
  }
}

export default MemberLayout

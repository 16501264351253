import React from 'react'

const style = {
    pointerEvents: 'inherit'
}

export default class DateLink extends React.Component {
    render() {
        return <a onClick={this.props.onClick} style={this.props.style || style} title={this.props.label}>{this.props.value || this.props.label}</a>
    }
}

import React, { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import { DragType, AnswerType } from '_root/constants'
import { MiniButton } from '_layout/buttons'
import DownIcon from '_images/menu-down-dark.svg'
import EditIconSrc from '_images/edit-icon.svg'

//#region Styles
const Link = styled(MiniButton)`
    visibility: hidden;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-right: 10px;
`
const EditIcon = styled.i`
    cursor: pointer;
    visibility: hidden;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border: none;
    background: url(${EditIconSrc}) scroll 0 0 no-repeat transparent;
    background-size: 28px 28px;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`
const Card = styled.div`
    position: relative;
    cursor: ${props => props.$fixed ? 'default' : 'move'};
    min-width: 300px;
    margin-bottom: 10px;
    padding: 10px 40px 10px 10px;
    opacity: ${props => props.$isDragging ? 0.3 : 1 };
    border: 1px solid var(--color-line-dark);
    background: var(--color-bg-white);
    box-shadow: ${props => `${props.isDragging ? '0 1px 3px 3px' : '0 1px 3px 0'}`} var(--color-menu-zero);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        width: 1;
        border-left: 1px solid var(--color-line-light);
    }

    &:hover {
      background: linear-gradient(to right, var(--color-bg-white), var(--color-bg-white), var(--color-bg-light));

      ${Link}, ${EditIcon} {
        visibility: visible;
      }
    }

    ul {
        list-style-type: none;
        margin: 0.5em 10px 0 0;
        padding-top: 10px;
        padding-left: 0.5em;
        border-top: 1px solid var(--color-line-light);

        li {
            color: var(--color-text);
            margin-bottom: 0.25em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`
const Toolbar = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
`
const Type = styled.div`
    flex: 1 0 auto;
    color: var(--color-text);
    font-size: 0.7rem;
    text-transform: uppercase;
`
const ToggleIcon = styled.i`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background: url(${DownIcon}) no-repeat scroll 50% 50% transparent;
    transform: ${props => props.$open ? 'rotate(180deg)' : 'none'};
`
//#endregion

const typeText = type => {
  switch(type) {
    case AnswerType.TEXT:
      return "Fritext"
    case AnswerType.BOOL:
      return "Ja / nej"
    case AnswerType.SINGLE:
      return "Envalsfråga"
    case AnswerType.MULTIPLE:
      return "Flervalsfråga"
    case AnswerType.GRADE:
      return "Gradering 1-5"
    default:
      return "Fritext"
  }
}

export default function SelectionQuestion(props) {
  const [open, setOpen] = useState(false)
  const [tooltip, setTooltip] = useState(typeText(props.question.type))
  const ref = useRef(null)

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: DragType.QUESTION,
    item: {...props.question, index: props.index},
    canDrag: (monitor) => { return !!props.onDropped },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        props.onDropped && props.onDropped(monitor.getDropResult())
      }
    },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  }))

  const [collectedProps, drop] = useDrop(() => ({
    accept: DragType.QUESTION,
    hover: (item, monitor) => {
      if (!ref.current) return
      const dragIndex = item.index
      const thisIndex = props.index
      const dragId = item.id
      const thisId = props.id
      if (!props.onMove || dragIndex === undefined || dragId === thisId ) return
      // Determine rectangle on screen
      const thisBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const thisMiddleY = (thisBoundingRect.bottom - thisBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const thisClientY = clientOffset.y - thisBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < thisIndex && thisClientY < thisMiddleY) { // Dragging downwards
        return
      }
      if (dragIndex > thisIndex && thisClientY > thisMiddleY) { // Dragging upwards
        return
      }
      item.index = thisIndex
      props.onMove(dragId, thisId)
    }
  }))

  drag(drop(ref))

  const { question, className, onDropped, onAdd, onEdit, onDelete, onAddText, onDeleteText } = props
  const questionType = typeText(question.type)
  const answers = question.answers && question.answers.length ? question.answers : [{ value: questionType }]

  return <div className={className} title={tooltip}>
    <Card role="Handle" ref={ref} $isDragging={collected.isDragging} $fixed={!onDropped}>
      <div>{question.text}</div>
      <Toolbar>
        <Type>{questionType}</Type>
        {onAdd &&
        <Link onClick={e => onAdd(question)}>{onAddText || 'Lägg till'}</Link>
        }
        {onDelete &&
        <Link onClick={e => onDelete(question)}>{onDeleteText || 'Ta bort'}</Link>
        }
        {onEdit &&
        <EditIcon onClick={e => onEdit(question)} title='Klicka för att redigera' />
        }
      </Toolbar>
      {open && answers.length > 1 &&
      <ul>
        {map(answer => <li key={answer.value}>{answer.value}</li>)(answers)}
      </ul>
      }
      {answers.length > 1 &&
      <ToggleIcon $open={open} onClick={e => setOpen(!open)} />
      }
    </Card>
  </div>
}

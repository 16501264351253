import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import withRouter from '_hoc/withRouter'
import { NextLink } from '_layout/buttons'
import { RecruitGrid } from '_layout/form-elements'
import RecruitBase from '_components/recruit/recruit-base'
import RecruitingInformation from '_modules/recruitments/components/advertisement/recruiting-information'
import RecruitingDeals from '_modules/recruitments/components/advertisement/recruiting-deals'
import inject from '_services/inject'
import * as url from "_routes/url-names";

//#region Styles
const Content = styled.div`
  padding: 40px;
`
//#endregion Styles

const RecruitBaseEdit = function(props) {

  const [recruitment, state, groups, urlBase, saving, saved, saveRecruitment] = useOutletContext()

  useEffect(() => {
    document.getElementById('recruitment-base-content').scrollIntoView(true)
  })

  return <Content id='recruitment-base-content'>
    <RecruitGrid>
      <RecruitBase className='input-area' recruitment={recruitment} groups={groups} onChange={saveRecruitment} {...props} />
      {!props.user.companyView.isJobbet &&
      <>
      <RecruitingInformation className='info-area' recruiters={recruitment.recruiterExternals} />
      <RecruitingDeals className='deals-area' title={recruitment.title} addons={recruitment.addons} onChange={saveRecruitment} />
      </>
      }
      <NextLink className='button-area' to={urlBase + url.REK_ANNONS} data-tooltip-content={props.saved ? 'Dina ändringar har sparats' : null} data-tooltip-id="root-tooltip">Gå vidare till platsannons</NextLink>
    </RecruitGrid>
  </Content>
}

export default compose(withApollo, withRouter, inject('user'))(RecruitBaseEdit)

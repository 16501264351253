import React from "react"
import withRouter from "_hoc/withRouter"
import { withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import styled from "styled-components"
import { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import { isEqual, isEmpty, cloneDeep } from "lodash/fp"
import Debounce from "debounce-decorator"
import "react-datepicker/dist/react-datepicker.css"
import { RecruitmentState, STORE_CHANNELS, REC_STATS_ID } from "_root/constants"
import withSendmailMutation from "_hoc/withSendmailMutation"
import message from "_components/message"
import Modal from "_components/modal"
import { getPublishSupportMailContent, getPublishChannelBookingMailContent } from "_modules/recruitments/services/mailtemplate-service"
import OrderConfirmedDialog from "_modules/recruitments/components/advertisement/order-confirmed.dialog"
import PublicationEditSection from "_modules/recruitments/components/advertisement/publications-edit-section"
import { PrimaryButton, PrimaryLinkButton, SecondaryButton, CancelButton } from "_layout/buttons"
import inject from "_services/inject"
import to from "_services/await.to"
import * as url from "_routes/url-names"
import { TextArea } from "_root/layout/form-elements"

//#region Styles
const Section = styled.section`
  margin: 0;
  padding: 2em 40px;

  &.job-ad-section {
    padding-left: 100px;
    padding-right: 100px;
  }

  > div {
    margin-left: auto;
    margin-right: auto;
  }

  &.button-section {
    height: 100px;

    button {
      margin: 0 auto;
      position: relative;
      top: -53px;
    }
  }
`
const ModalHeader = styled.header`
  font-size: 1.875rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const Nowrap = styled.div`
  margin-top: 30px;
  text-align: center;
  white-space: nowrap;
`
const Checkout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  bottom: 30px;
  right: 40px;
  width: 220px;
  min-height: 200px;
  background: var(--color-bg-white);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
`
const Title = styled.div`
  padding: 5px 0;
  text-align: center;
  color: var(--color-text-white);
  background: var(--color-brand-red);
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  > p {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    flex: 1;
    margin-bottom: 0;
    padding: 0 16px;
    list-style-type: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 18px;
      font-size: 14px;

      &:before {
        content: "✓";
        font-size: 16px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }

      &.added {
        &:before {
          content: "+";
        }
        color: var(--color-brand-red);
      }

      &.removed {
        &:before {
          content: "–";
        }
        color: var(--color-brand-red);
        text-decoration: line-through;
      }
    }
  }

  button {
    margin: 10px 20px;
  }
`
//#endregion

@compose(withApollo, withRouter, withSendmailMutation, inject("user"))
export class PublishingClass extends React.PureComponent {
  constructor(props) {
    super(props)
    registerLocale("sv", sv)
    this.ticking = false
    this.navigated = false
    this.recruitmentId = props.match.params.recruitment
    this.state = {
      applyStart: (props.recruitment && props.recruitment.applyStart) || null,
      applyStop: (props.recruitment && props.recruitment.applyStop) || null,
      showConfirmOrderModal: false,
      showConfirmLeavePageModal: false,
      showOrderConfirmation: false,
      showMailSupportConfirmation: false,
      orderMessage: "",
      recruitment: props.recruitment,
      initialRecruitment: this.initialRecruitment(),
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = {}
    if (!prevState.recruitment || !isEqual(nextProps.recruitment)(prevState.recruitment)) {
      nextState.recruitment = nextProps.recruitment
    }
    return isEmpty(nextState) ? null : nextState
  }

  componentDidMount() {
    document.getElementById("publish-content").scrollIntoView(true)
  }

  initialRecruitment = () => {
    let initialRecruitment = cloneDeep(this.props.recruitment)
    return initialRecruitment
  }

  onDirty = (state) => {
    this.dirty = true
    this.props.onDirty && this.props.onDirty(state)
  }

  activate = () => {
    this.setState({ showConfirmLeavePageModal: false, showConfirmActivateModal: false })
    this.setRecruitmentState(RecruitmentState.ACTIVE)
  }

  setRecruitmentState = async (recruitmentState) => {
    this.setState({ state: recruitmentState })
    await this.props.onChange({ state: recruitmentState })

    const fromState = this.state.state
    const toState = recruitmentState

    this.props.client.writeQuery({
      query: gql`
        query RecruitmentStats {
          recruitmentStats {
            countRecruitmentsIsStale
            activeRecruitmentsIsStale
            draftRecruitmentsIsStale
            closedRecruitmentsIsStale
          }
        }
      `,
      data: {
        recruitmentStats: {
          __typename: "RecruitmentStats",
          id: REC_STATS_ID,
          countRecruitmentsIsStale: true,
          activeRecruitmentsIsStale: true,
          draftRecruitmentsIsStale: true,
          closedRecruitmentsIsStale: true
      },
      },
    })
  }

  onToggleState = async (recruitmentState) => {
    if (this.state.state !== recruitmentState) {
      if (recruitmentState === RecruitmentState.ACTIVE) {
        if (!this.state.applyStop) {
          message("Sista ansökningsdag måste anges för att kunna aktivera denna rekrytering!")
        } else {
          const selectedChannels = this.selectedChannels(this.state.recruitment)
          if (selectedChannels && selectedChannels.length) {
            this.setState({ showConfirmActivateModal: true })
          } else {
            this.setState({ showConfirmLeavePageModal: true })
          }
        }
      } else {
        this.setRecruitmentState(recruitmentState)
      }
    }
  }

  @Debounce(750)
  saveRecruitment(recruitment) {
    this.props.onChange(recruitment)
  }

  onPackageSelectionChange = (packageType) => {
    this.onDirty({ dirtyState: true })
    const { publishPackageTypes } = this.state.recruitment
    const publishPackageType = publishPackageTypes.find((p) => p.value === packageType)
    const packageTypes = {}
    if (publishPackageType) {
      packageTypes.delete = [{ id: publishPackageType.id }]
    } else {
      packageTypes.create = [{ value: packageType, position: 1000 }]
    }
    this.saveRecruitment({ ...this.state.recruitment, publishPackageTypes: packageTypes })
  }

  onPublishChannelSelectionChange = (state) => {
    if (state === "PENDING") {
      if (this.dirty) return
      this.onDirty({ dirtyState: true })
    } else {
      this.onDirty({ dirtyState: false })
      this.dirty = false
      this.saveRecruitment({ ...this.state.recruitment })
    }
  }

  onOtherChannelChange = (value) => {
    this.onDirty({ dirtyState: true })
    this.saveRecruitment({ ...this.state.recruitment, publishChannelsOther: value })
  }

  confirmActivation = () => {
    const selectedChannels = this.selectedChannels(this.state.recruitment)
    if (selectedChannels && selectedChannels.length) {
      this.setState({ showConfirmOrderModal: true })
    } else {
      this.setState({ showConfirmLeavePageModal: true })
    }
  }

  executeActivation = () => {
    this.setRecruitmentState(RecruitmentState.ACTIVE)
    this.handleOrder()
  }

  onOrderMessageChange = (e) => {
    this.setState({ orderMessage: e.currentTarget.value })
  }

  dismissActivateModal = () => {
    this.setState({ showConfirmActivateModal: false })
  }

  dismissOrderModal = () => {
    this.setState({ showConfirmOrderModal: false })
  }

  dismissLeavePageModal = () => {
    this.setState({ showConfirmLeavePageModal: false })
  }

  proceedToLeavePage = () => {
    this.setState({ showConfirmLeavePageModal: false }, this.activate)
  }

  cancelConfirmation = () => {
    this.setState({ showOrderConfirmation: false, showMailSupportConfirmation: false })
  }

  proceedConfirmation = () => {
    this.setState({ showOrderConfirmation: false })
  }

  proceedBooking = () => {
    this.dismissLeavePageModal()
  }

  proceedToMailSupport = () => {
    const { sendMail, user } = this.props
    this.setState({ isSending: true }, async () => {
      const [error] = await to(
        sendMail({
          from: "noreply@jobbet.se",
          to: process.env.REACT_APP_AD_SUPPORT,
          subject: "Jag vill ha rådgivning från Jobbet.se",
          html: getPublishSupportMailContent({
            userMessage: "Angående annonsering för:",
            user,
            recruitment: this.state.recruitment,
          }),
          showFooter: false,
        })
      )
      if (!error) {
        this.setState({ showMailSupportConfirmation: true })
      }
    })
  }

  selectedChannels = (recruitment) => {
    const { recruitmentSelectedPublishChannels: channels = [], publishPackageTypes = [], publishChannelsOther } = recruitment
    const selectedChannels = channels.map((ch) => ch.publishChannel.title).filter((title) => title !== "Karriärsida")
    if (publishPackageTypes.find((p) => p.value === "SOCIALMEDIA")) selectedChannels.push("Tjänsteman")
    if (publishPackageTypes.find((p) => p.value === "YOUNG")) selectedChannels.push("Young professionals")
    if (publishPackageTypes.find((p) => p.value === "INTERNET")) selectedChannels.push("Medarbetare")
    if (publishChannelsOther && publishChannelsOther.trim()) selectedChannels.push("Övrig annonsering")
    return selectedChannels
  }

  initialChannels = () => {
    let initialChannels = localStorage.getItem(STORE_CHANNELS + this.props.recruitment.id)
    if (initialChannels) {
      initialChannels = JSON.parse(initialChannels)
    } else {
      initialChannels = this.selectedChannels(this.state.initialRecruitment)
      localStorage.setItem(STORE_CHANNELS + this.props.recruitment.id, JSON.stringify(initialChannels))
    }
    return initialChannels
  }

  clearChannels = () => {
    localStorage.removeItem(STORE_CHANNELS + this.props.recruitment.id)
    this.setState({ orderMessage: "" })
  }

  confirmLeavePage = () => {
    this.props.navigate(this.props.urlBase + url.REK_INFO)
  }

  handleOrder = async () => {
    const { recruitment, orderMessage } = this.state
    const { sendMail, user } = this.props
    const [error] = await to(
      sendMail({
        from: "noreply@jobbet.se",
        to: process.env.REACT_APP_AD_SUPPORT,
        subject: "Beställning - Annonskanaler",
        html: getPublishChannelBookingMailContent({ recruitment, user, orderMessage }),
        showFooter: false,
      })
    )
    if (!error) {
      const initialRecruitment = this.initialRecruitment()
      this.setState(
        {
          showOrderConfirmation: true,
          showConfirmActivateModal: false,
          showConfirmOrderModal: false,
          orderMessage: "",
          initialRecruitment,
        },
        () => {
          this.clearChannels()
        }
      )
    } else {
      // Kontakta kundtjänst.
    }
  }

  render() {
    const { applyStart, applyStop, recruitment } = this.state
    if (!applyStart || !applyStop || !recruitment.jobAd) {
      return (
        <div id="publish-content" className="tc">
          <h2 className="mv4">Vänligen skapa först en platsannons samt ange ansökningsdatum.</h2>
          <PrimaryButton className="mr3" onClick={() => this.props.navigate(this.props.urlBase + url.REK_ANNONS)}>
            Tillbaka till platsannons
          </PrimaryButton>
        </div>
      )
    }
    const caseClosed = recruitment.state === "CLOSED"
    const selectedChannels = this.selectedChannels(recruitment)
    const initialChannels = this.initialChannels()
    const disablePublish = !selectedChannels.length
    const disableOrder = disablePublish || isEqual(selectedChannels, initialChannels)

    return (
      <div id="publish-content">
        <Section className="job-ad-section">
          <PublicationEditSection
            state={recruitment.state}
            recruitment={recruitment}
            packageSelection={recruitment.publishPackageTypes || []}
            otherChannelsComment={recruitment.publishChannelsOther}
            onPackageSelectionChange={this.onPackageSelectionChange}
            onPublishSelectionChange={this.onPublishChannelSelectionChange}
            publishChannelsOther={recruitment.publishChannelsOther}
            onOtherChannelChange={this.onOtherChannelChange}
            onStateChange={this.onToggleState}
            recruitmentDuration={{
              applyStart,
              applyStop,
            }}
          />
        </Section>
        <Section className="tc">
          <PrimaryLinkButton to={this.props.urlBase + url.REK_INFO}>Gå vidare till formalia</PrimaryLinkButton>
        </Section>
        {caseClosed || (
          <Checkout>
            <Title>Vald marknadsföring</Title>
            <Content>
              {disablePublish && <p>Ingen marknadsföring vald</p>}
              {!disablePublish && (
                <ul>
                  {selectedChannels.map((ch, index) => (
                    <li key={"ch" + index}>{ch}</li>
                  ))}
                </ul>
              )}
              <PrimaryButton disabled={disableOrder} onClick={this.confirmActivation}>
                Skicka beställning
              </PrimaryButton>
            </Content>
          </Checkout>
        )}
        <Modal center isOpen={this.state.showConfirmLeavePageModal} onRequestClose={this.dismissLeavePageModal}>
          <ModalHeader>Kom ihåg att boka annonsering!</ModalHeader>
          <p>
            Din annons är nu aktiverad och det går att söka tjänsten, men den är inte synlig för kandidater.
            <br />
            <br />
            Med aktiva insatser för att nå rätt målgrupp ökar du dina chanser att lyckas med rekryteringen.
            <br />
            <br />
            Vi rekommenderar därför att du även bokar annonsering.
          </p>
          <Nowrap>
            <PrimaryButton onClick={this.activate}>Jag har tagit del av informationen</PrimaryButton>
          </Nowrap>
        </Modal>
        <Modal isOpen={this.state.showConfirmActivateModal} onRequestClose={this.dismissActivateModal}>
          <ModalHeader>Vill du även beställa vald annonsering?</ModalHeader>
          <Nowrap>
            <SecondaryButton className="mr3" onClick={this.activate}>
              Jag vill endast aktivera min annons
            </SecondaryButton>
            <PrimaryButton onClick={this.confirmActivation}>Jag vill aktivera samt beställa annonsering</PrimaryButton>
          </Nowrap>
        </Modal>
        <Modal center isOpen={this.state.showConfirmOrderModal} onRequestClose={this.dismissOrderModal}>
          <ModalHeader>Tack för att du vill beställa annonsering!</ModalHeader>
          <TextArea width="500px" rows={8} placeholder="Lämna gärna ett meddelande till oss här..." onChange={this.onOrderMessageChange} value={this.state.orderMessage} />
          <Nowrap>
            <CancelButton className="mr3" onClick={this.dismissOrderModal}>
              Avbryt
            </CancelButton>
            <PrimaryButton onClick={this.executeActivation}>Skicka beställning</PrimaryButton>
          </Nowrap>
        </Modal>
        <OrderConfirmedDialog
          show={this.state.showOrderConfirmation}
          proceed={this.proceedConfirmation}
          dismiss={this.cancelConfirmation}
          messageHeader="Tack för din beställning!"
          messageText="Beställningen har skickats iväg och ärendet kommer hanteras inom kort."
          confirmText="OK"
        />
        <OrderConfirmedDialog
          show={this.state.showMailSupportConfirmation}
          proceed={this.cancelConfirmation}
          dismiss={this.cancelConfirmation}
          messageHeader="Tack för din förfrågan!"
          messageText="Vi kommer att kontakta dig inom kort."
          confirmText="OK"
        />
      </div>
    )
  }
}

import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import onClickOutside from "react-onclickoutside"
import styled from "styled-components"
import SettingsImage from "_images/settings.svg"
import ProfileImage from "_images/profile.svg"
import SupportImage from "_images/questionmark.svg"
import SearchImage from "_images/search.svg"
import LoginService from "_services/login-service"
import isLoggedIn from "_services/is-logged-in"
import * as url from "_routes/url-names"
import inject from "_services/inject"

//#region Styles
const SideMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
`
const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 75px;
  cursor: pointer;
  border-left: 1px solid var(--color-line);

  &:hover {
    background-color: var(--color-bg-lightest);
  }

  @media screen and (max-width: 767px) {
    display: ${props => (props.$mobile ? "none" : "flex")};
  }
`
const ProfileButton = styled.a`
  display: block;
  width: 34px;
  height: 34px;
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-color: ${props => (props.$active ? "var(--color-white-hover)" : props.$isRoute ? "var(--color-bg-light)" : "transparent")};
`
const SupportButton = styled(ProfileButton)`
  background: url(${SupportImage}) no-repeat scroll 50% 50%;
`
const SearchButton = styled(ProfileButton)`
  background: url(${SearchImage}) no-repeat scroll 50% 50%;
  background-size: 32px 32px;
`
const SettingsButton = styled(ProfileButton)`
  background: url(${SettingsImage}) no-repeat scroll 50% 50%;
`
const DropMenu = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--color-brand-black);
  border: 1px solid var(--color-bg-dark);
  box-shadow: 2px 2px 10px var(--color-bg-light);
  z-index: 10000;
`
const MenuItem = styled.li`
  display: flex;
  align-items: center;
`
const MenuLink = styled(NavLink)`
  flex: 1;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 20px;
  white-space: nowrap;

  &.active {
    pointer-events: none;
    background: var(--color-brand-red);
  }

  &:hover {
    background: var(--color-white-hover);
  }
`
const LoggedInAs = styled.div`
  position: relative;
  color: var(--color-text-dark);
  margin-right: 25px;
  line-height: 1;
  border-bottom: 5px solid ${props => (props.$isJobbet ? "var(--color-brand-red)" : props.$isGroup ? "var(--color-brand-green)" : "var(--color-line)")};

  @media screen and (min-width: 768px) {
    &:before {
      content: "Grupp";
      display: ${props => (props.$isGroup ? "block" : "none")};
      position: absolute;
      top: -12px;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--color-text-medium);
    }

    &:after {
      content: "Gruppmedlem";
      display: ${props => (props.$isMember ? "block" : "none")};
      position: absolute;
      top: -12px;
      right: 0;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--color-text-medium);
    }
  }
`
const CompanyName = styled.span`
  display: inline-block;
  text-align: right;
  margin-top: 3px;
  font-weight: 600;
  color: ${props => (props.$active ? null : "var(--color-brand-red)")};
  text-decoration: ${props => (props.$active ? null : "line-through")};
  text-decoration-color: var(--color-text-dark);
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
//#endregion

@compose(withRouter, withApollo, inject("user"), onClickOutside)
export default class ToolsMenuBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      settingsOpen: false,
      profileOpen: false,
      settingsRoute: this.props.location.pathname.indexOf(url.INSTALLNINGAR) > -1,
      profileRoute: this.props.location.pathname.indexOf(url.INST_MY_PROFILE) > -1,
    }
  }

  logout = e => {
    e.preventDefault()
    LoginService.clearToken({ client: this.props.client, reload: true })
  }

  handleClickOutside = e => {
    if (!this.state.profileOpen && !this.state.settingsOpen) return
    this.setState({ profileOpen: false, settingsOpen: false })
  }

  toggleSettings = () => {
    const profileOpen = false
    const settingsOpen = !this.state.settingsOpen
    this.setState({ profileOpen, settingsOpen })
  }

  toggleProfile = () => {
    const profileOpen = !this.state.profileOpen
    const settingsOpen = false
    this.setState({ profileOpen, settingsOpen })
  }

  render() {
    const { user, match, onSearch } = this.props
    const groupUser = user.superAdmin || user.groupAdmin || user.groupRecruiter || user.groupViewer
    const isMember = user.superAdmin && !!user.companyView.groups.length
    const isAdmin = user.active && (user.superAdmin || user.groupAdmin || user.isAdmin)
    const COMPANY = "/" + match.params.company
    const { name: companyName, active: companyActive, group: companyGroup } = user.companyView
    const { settingsRoute, settingsOpen, profileRoute, profileOpen } = this.state
    const templatesText = user.companyView.isJobbet ? "Grundmallar" : "Rekryteringsmallar"

    if (isLoggedIn() === false) return null

    return (
      <SideMenu>
        {groupUser && companyName && (
          <LoggedInAs $isJobbet={user.companyView.isJobbet} $isGroup={user.companyView.group} $isMember={isMember}>
            <CompanyName $active={companyActive} data-tooltip-content={companyActive ? null : "Inaktiverat"} data-tooltip-id="root-tooltip">
              {companyName}
            </CompanyName>
          </LoggedInAs>
        )}
        <ButtonWrapper data-tooltip-content="Sök i systemet" data-tooltip-id="root-tooltip">
          <SearchButton onClick={onSearch} />
        </ButtonWrapper>
        <ButtonWrapper data-tooltip-content="Kundtjänst" data-tooltip-id="root-tooltip" $mobile>
          <SupportButton href={COMPANY + url.KUNDSERVICE} />
        </ButtonWrapper>
        {isAdmin && (
          <ButtonWrapper data-tooltip-content="Inställningar" data-tooltip-place="left" data-tooltip-id="root-tooltip" $mobile $active={settingsOpen} $isRoute={settingsRoute} onClick={this.toggleSettings}>
            <SettingsButton />
            {settingsOpen && (
              <DropMenu>
                {companyGroup && (
                  <MenuItem>
                    <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_GROUP}>
                      Om gruppen
                    </MenuLink>
                  </MenuItem>
                )}
                {companyGroup || (
                  <MenuItem>
                    <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_COMPANY}>
                      Företagsuppgifter
                    </MenuLink>
                  </MenuItem>
                )}
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_USERS}>
                    Användare
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_PERMISSION}>
                    Behörighet
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_FILER}>
                    Filer
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_MALLAR}>
                    {templatesText}
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_UTSKICK}>
                    Utskicksmallar
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_URVAL}>
                    Urval
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INSTALLNINGAR + url.INST_BILDBANK}>
                    Bildbank
                  </MenuLink>
                </MenuItem>
              </DropMenu>
            )}
          </ButtonWrapper>
        )}
        {this.props.user.active && (
          <ButtonWrapper data-tooltip-content="Mitt konto" data-tooltip-id="root-tooltip" data-tooltip-place="left" $mobile $active={profileOpen} $isRoute={profileRoute} onClick={this.toggleProfile}>
            <ProfileButton />
            {profileOpen && (
              <DropMenu>
                <MenuItem>
                  <MenuLink end to={COMPANY + url.INST_MY_PROFILE}>
                    Min profil
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink end to="/" onClick={this.logout}>
                    Logga ut
                  </MenuLink>
                </MenuItem>
              </DropMenu>
            )}
          </ButtonWrapper>
        )}
      </SideMenu>
    )
  }
}

const minLength = 8
const uppercaseMinCount = 1
const lowercaseMinCount = 1
const numberMinCount = 1
const UPPERCASE_RE = /([A-Z])/g
const LOWERCASE_RE = /([a-z])/g
const NUMBER_RE = /([\d])/g

export const isStrongEnough = password => {
    const uc = password.match(UPPERCASE_RE)
    const lc = password.match(LOWERCASE_RE)
    const n = password.match(NUMBER_RE)
    return password.length >= minLength &&
        uc && uc.length >= uppercaseMinCount &&
        lc && lc.length >= lowercaseMinCount &&
        n && n.length >= numberMinCount
}

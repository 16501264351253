import React from 'react'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import queryString from 'query-string'
import {
    isEmpty
} from 'lodash/fp'

import inject from '_services/inject'
import { UserRoles } from '_root/constants'

import { COMPANY_QUERY } from '_containers/settings/company-profile-ql'

import MainLayout from '_layout/main-layout'
import { H2 } from '_layout/form-elements'

import DetailedCandidatesResultGroup from '../components/candidates/detailed-resultgroup';
import DetailedCompaniesResultGroup from '../components/companies/detailed-resultgroup';
import DetailedGroupResultGroup from '../components/groups/detailed-resultgroup';
import DetailedRecruitmentsResultGroup from '../components/recruitments/detailed-resultgroup';
import DetailedUsersResultGroup from '../components/users/detailed-resultgroup';

//#region Styles
const SearchResultContent = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: flex-start;
`
//#endregion

@compose(
    withRouter,
    graphql(COMPANY_QUERY, {
        name: 'companyQuery',
        options: props => ({ variables: { urlName: props.match.params.company } }),
        props: ({ companyQuery: { company } }) => ({
            currentCompany: company
        })
    }),
    inject('user')
)
export default class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSection: 'candidates',
            query: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {}
        const parsedQueryString = queryString.parse(nextProps.location.search)
        if (parsedQueryString.query !== prevState.query ) {
            nextState.activeSection = parsedQueryString.category || 'candidates'
            nextState.query = parsedQueryString.query
        }
        return !isEmpty(nextState) ? nextState : null
    }

    setActiveSection(section) {
        this.setState({ activeSection: section })
    }

    render() {
        const { match: { params: routeParams }, user, currentCompany } = this.props
        const isViewingJobbetCompany = ( routeParams.company && routeParams.company === "jobbet.se" )
        const isSuperAdminOrSiteAdmin = [UserRoles.SUPERADMIN, UserRoles.SITEADMIN].indexOf(user.role) > -1
        const isAdmin = user.role === UserRoles.ADMIN
        const isRecruiterOrViewer = [UserRoles.RECRUITER, UserRoles.VIEWER].indexOf(user.role) > -1
        const commonProps = {
            limit: 16,
            queryInput: this.state.query,
            currentCompany
        }
        return (
            // TODO: should be handled better by each result-group component below.
            // When filtering is based on the current company each of the result-compnents
            // has a dependency on the currentCompany prop being availbale.
            // This ternary-check keeps the components from being rendered when the prop will be used
            // but is not yet available.
            (!currentCompany && (isAdmin || (!isViewingJobbetCompany && isSuperAdminOrSiteAdmin))) 
            ? null
            : <MainLayout padding maxHeight>
                <H2>Sök</H2>
                <SearchResultContent className='margin20'>
                    { !isRecruiterOrViewer && <DetailedCandidatesResultGroup
                        {...commonProps}
                        isActive={this.state.activeSection==='candidates'}
                        onHeaderClick={ () => this.setActiveSection('candidates') }
                    /> }
                    { (isViewingJobbetCompany && isSuperAdminOrSiteAdmin ) && <DetailedCompaniesResultGroup
                        {...commonProps}
                        isActive={this.state.activeSection==='companies'}
                        onHeaderClick={ () => this.setActiveSection('companies') }
                    /> }
                    { (isViewingJobbetCompany && isSuperAdminOrSiteAdmin ) && <DetailedGroupResultGroup
                        {...commonProps}
                        isActive={this.state.activeSection==='groups'}
                        onHeaderClick={ () => this.setActiveSection('groups') }
                    /> }
                    { (isSuperAdminOrSiteAdmin || isAdmin) && <DetailedUsersResultGroup
                        {...commonProps}
                        isActive={this.state.activeSection==='users'}
                        onHeaderClick={ () => this.setActiveSection('users') }
                    /> }
                    <DetailedRecruitmentsResultGroup
                        {...commonProps}
                        isActive={ isRecruiterOrViewer || this.state.activeSection==='recruitments' }
                        onHeaderClick={ () => this.setActiveSection('recruitments') }
                    />
                </SearchResultContent>
            </MainLayout>
        )
    }
}

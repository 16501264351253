import React, { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { useMutation } from "@apollo/client"
import styled from "styled-components"
import DatePicker, { registerLocale } from "react-datepicker"
import sv from "date-fns/locale/sv"
import { parseISO } from "date-fns"
import map from "lodash/fp/map"
import orderBy from "lodash/fp/orderBy"
import draftToHtml from "draftjs-to-html"
import BackingIcon from "_images/notes.svg"
import Backing from "_components/recruit/planning-backing"
import Modal from "_components/modal"
import { FlexRow, FlexCol, Input, Checkbox } from "_layout/form-elements"
import DateLink from "_components/date-link"
import { Process, ProcessType, ProcessTitles } from "_root/constants"
import { UPDATE_PROCESS_MUTATION } from "_containers/recruitment/recruitment-ql"
import to from "_services/await.to"
import all from "_services/await.all"
import { SecondaryButton } from "_root/layout/buttons"

//#region Styles
const Content = styled.div`
  padding: 40px;

  p {
    margin-top: 0;
  }
`
const Steps = styled.ul`
  display: inline-block;
  text-align: left;
  margin: 1em 0 0 0;
  padding: 0;
  list-style-type: none;
`
const Step = styled.li`
  display: flex;
  flex-flow: row nowrap;
  min-width: 450px;
  padding-right: 10px;
  margin-bottom: 5px;
  background-color: var(--color-bg-white);
  opacity: ${props => (props.$active ? "1" : "0.5")};

  .dates {
    visibility: ${props => (props.$active ? "visible" : "hidden")};
  }

  &:hover > .backing {
    visibility: visible;
  }
`
const Label = styled.label`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
`
const Dates = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding: 5px;
  font-size: 0.9em;
  .react-datepicker__close-icon {
    top: 10%;
    right: -8px;
    width: 16px;
    height: 16px;
    &:after {
      line-height: 10px;
    }
  }
`
const Other = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 14px 20px;

  input {
    cursor: default;
    margin-bottom: 0;

    &[type="text"] {
      width: 200px;
      padding: 5px;
    }
  }
`
const BackingLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  background: url(${BackingIcon}) scroll 50% 50% no-repeat var(--color-bg-white);
  background-size: 50%;
  visibility: hidden;
`
//#endregion

export default function Planning(props) {
  registerLocale("sv", sv)
  const [recruitment, state, groups, urlBase, saving, saved, saveRecruitment, saveRecruitmentInfo, dirtyRecruitment, cleanRecruitment] = useOutletContext()
  const pristineOther = recruitment?.process && recruitment.process[5] && recruitment.process[5].text
  const [process, setProcess] = useState(orderBy((p) => p.order)(["asc"])(map((p) => ({ ...p }))(recruitment.process)))
  const [backingStep, setBackingStep] = useState()
  const [backingTitle, setBackingTitle] = useState()
  const [backingContent, setBackingContent] = useState()
  const [backingOpen, setBackingOpen] = useState()
  const [backingChanged, setBackingChanged] = useState()

  const [updateProcess, { data, loading, error }] = useMutation(UPDATE_PROCESS_MUTATION)

  if (error) {
    console.error('Planning:updateProcess:error: ', error)
  }

  useEffect(() => {
    document.getElementById("planning-content").scrollIntoView(true)
  })

  useEffect(() => {
    onChange()
  }, [process])

  useEffect(() => {
    setProcess(orderBy((p) => p.order)(["asc"])(map((p) => ({ ...p }))(recruitment.process)))
  }, [recruitment])

  const processVariables = step => {
    const data = {
      state: { set: step.state },
      start: { set: step.start || null },
      stop: { set: step.stop || null },
      text: { set: step.text || "" },
      backingHTML: { set: step.backingHTML || draftToHtml(step.backing) },
    }
    return { id: step.id, data }
  }

  const onChange = async () => {
    const [error, _] = await all(map(step => updateProcess({ variables: processVariables(step) }))(process))
    if (error) {
      console.error("planning:onChange", error)
      return
    }
    cleanRecruitment()
  }

  const toggle = (order, checked) => {
    process[order]["state"] = checked ? Process.INACTIVE : Process.INVALID
    setProcess([...process])
  }

  const otherInput = (e, order) => {
    const text = e.target.value
    process[order].text = text
    setProcess([...process])
    if (dirtyRecruitment && pristineOther !== text.trim()) {
      dirtyRecruitment()
    }
  }

  const otherBlur = (e, order) => {
    if (!e.target.value || !e.target.value.trim().length) {
      process[order].text = "Övrigt"
      setProcess([...process])
    }
  }

  const handleDate = (order, prop, date) => {
    process[order][prop] = date ? new Date(date) : null
    setProcess([...process])
  }

  const openBacking = step => {
    setBackingOpen(true)
    setBackingStep(step)
    setBackingTitle(step.text || ProcessTitles[step.type])
    setBackingContent(step.backingHTML || draftToHtml(step.backing))
    setBackingChanged(false)
  }

  const saveBacking = async (backingHTML) => {
    setBackingOpen(false)
    const [error, result] = await to(updateProcess({ variables: { id: backingStep.id, data: { backingHTML: { set: backingHTML } } } }))
    if (error || !result) {
      console.error("planning:saveBacking", error, backingStep)
    } else {
      cleanRecruitment()
    }
  }

  const closeBacking = () => {
    setBackingOpen(false)
  }

  return (
    <Content id="planning-content">
      <h2>Planera rekrytering</h2>
      <FlexRow>
        <FlexCol>
          <p>Välj vilka moment du vill ska ingå i denna rekrytering och lägg en tidsplan.</p>
          <p>En övergripande tidsplanering bidrar till en effektiv rekryteringsprocess och du får även påminnelse av systemet om du har glömt något moment eller hamnat på efterkälken i tidsplanen.</p>
          {(!process || !process.length) && (
            <p>
              <i>Något har blivit fel, planeringssteg saknas. Vänligen kontakta kundtjänst.</i>
            </p>
          )}
          <Steps>
            {process.map(step => (
              <Step key={step.order} $active={step.state !== Process.INVALID} className="process-step">
                {step.type === ProcessType.OTHER && step.state !== Process.INVALID ? (
                  <Other>
                    <Checkbox checked={step.state !== Process.INVALID} onChange={(e) => toggle(step.order, e.target.checked)} />
                    <Input type="text" placeholder="Övrigt" value={step.text} onChange={(e) => otherInput(e, step.order)} onBlur={(e) => otherBlur(e, step.order)} />
                  </Other>
                ) : (
                  <Label>
                    <Checkbox checked={step.state !== Process.INVALID} onChange={(e) => toggle(step.order, e.target.checked)} />
                    <span>{ProcessTitles[step.type]}</span>
                  </Label>
                )}
                <Dates className="dates">
                  <DatePicker
                    customInput={<DateLink label="Startdatum" style={{ display: "inline-block", marginLeft: "4px", marginRight: "12px" }} />}
                    selected={typeof(step.start) === "string" ? parseISO(step.start) : step.start}
                    maxDate={typeof(step.stop) === "string" ? parseISO(step.stop) : step.stop}
                    onChange={(date) => handleDate(step.order, "start", date)}
                    locale="sv"
                    dateFormat="yy-MM-dd"
                    showWeekNumbers
                    isClearable={true}
                  />
                  <div className="mh2">-</div>
                  <DatePicker
                    customInput={<DateLink label="Slutdatum" style={{ display: "inline-block", marginLeft: "4px", marginRight: "12px" }} />}
                    selected={typeof(step.stop) === "string" ? parseISO(step.stop) : step.stop}
                    minDate={typeof(step.start) === "string" ? parseISO(step.start) : step.start}
                    onChange={(date) => handleDate(step.order, "stop", date)}
                    locale="sv"
                    dateFormat="yy-MM-dd"
                    showWeekNumbers
                    isClearable={true}
                  />
                </Dates>
                {step.state !== Process.INVALID && <BackingLink data-tooltip-content="Protokollunderlag" data-tooltip-id="root-tooltip" className="backing" onClick={() => openBacking(step)} />}
              </Step>
            ))}
          </Steps>
          <div className="mt3 center">
            <SecondaryButton disabled={saving} loading={saving} onClick={saveRecruitment}>
              {saving ? "Sparar" : "Spara"}
            </SecondaryButton>
          </div>
          <Modal isOpen={backingOpen} onRequestClose={closeBacking} overflow>
            <Backing title={backingTitle} content={backingContent} onSave={saveBacking} onClose={closeBacking} />
          </Modal>
        </FlexCol>
      </FlexRow>
    </Content>
  )
}

import React from "react"
import { withApollo } from '@apollo/client/react/hoc'
import styled from "styled-components"
import {
    map,
    orderBy,
    isEmpty
} from "lodash/fp"
import { Radio } from "_layout/form-elements"
import { symbols as symbolSrc } from "_root/constants"

//#region Styles
const Label = styled.label`
    display: block;
    font-size: 1.2em;
    font-weight: 700;
    padding: 15px 10px;
`
const SymbolList = styled.ul`
    cursor: default;
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0;
        white-space: nowrap;

        i {
            margin: 0 10px 0 0;
        }

        > label {
            display: flex;
            flex-flow: row nowrap;
        }

        > .end {
            width: 26px;
        }
    }
`
const Symbol = styled.i`
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px 5px 0;

    svg {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: var(--color-bg-grey);
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
    }

    .blackFlag svg {
        stroke: #000000;
    }
    .redFlag svg {
        stroke: #ff0000;
    }
    .blueFlag svg {
        stroke: #0000ff;
    }
    .greenFlag svg {
        stroke: #00ff00;
    }
    .yellowFlag svg {
        stroke: #ffe600;
    }
`
//#endregion

@withApollo
export default class SymbolSorter extends React.Component {
    state = {
        selectedSymbolId: ''
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let nextState = {}
        if (nextProps.symbolId && nextProps.symbolId !== prevState.symbolId) {
            nextState.selectedSymbolId = nextProps.symbolId
        }
        if (!prevState.symbols) {
            nextState.symbols = nextProps.symbols
        }
        return !isEmpty(nextState) ? nextState : null
    }

    componentDidMount = () => {
        this.mounted = true
    }

    componentWillUnmount = () => {
        this.mounted = false
    }

    onSymbol = async e => {
        if (this.mounted === false) return
        const selectedSymbolId = e.target.value
        this.setState({ selectedSymbolId }, () => { this.props.onChange && this.props.onChange(selectedSymbolId) })
    }

    render() {
        if (this.mounted === false) return null

        const { selectedSymbolId, symbols } = this.state

        return (
            <div>
                <Label>Välj symbol som ska prioriteras i listan.</Label>
                <SymbolList
                    style={
                        this.props.maxHeight
                            ? {
                                maxHeight: 250,
                                overflowY: "scroll",
                                paddingLeft: 10,
                                paddingRight: 10
                            }
                            : null
                    }>
                    <li>
                        <Radio name="symbol" value="" onChange={this.onSymbol} checked={selectedSymbolId === ""} label="Ingen prioritering" />
                    </li>
                    {map(symbol => (
                        <li key={symbol.id}>
                            <label>
                                <Radio name="symbol" value={symbol.id} onChange={this.onSymbol} checked={selectedSymbolId === symbol.id} />
                                <Symbol title={symbol.customText || symbol.text}>
                                    <img src={symbolSrc[symbol.name]} className={symbol.name} alt="Processymbol" />
                                </Symbol>
                            </label>
                        </li>
                    ))(orderBy(["order"])(["asc"])(symbols))}
                </SymbolList>
            </div>
        )
    }
}

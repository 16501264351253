import React from "react"
import { useOutletContext } from "react-router-dom"
import styled from "styled-components"
import { FlexRow, FlexCol } from "_layout/form-elements"
import { AuditLogList } from "_modules/audit"
// import inject from "_services/inject"

const Content = styled.div`
  padding: 2em 40px;
`

// inject("user")
export default function LogBook(props) {
  const [recruitment, state, groups, urlBase, saving, saved, saveRecruitment, saveRecruitmentInfo, dirtyRecruitment, cleanRecruitment] = useOutletContext()
  if (!props.user.superAdmin || !recruitment?.id) return null
  return <Content>
    <h2>Loggbok</h2>
    <FlexRow>
      <FlexCol>
        <AuditLogList entityName="Recruitment" entityId={recruitment.id} />
      </FlexCol>
    </FlexRow>
  </Content>
}

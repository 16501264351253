import React from 'react'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'

export default class Statistics extends React.Component {

    render() {

        return (
            <MainLayout padding>
                <H2>Statistik</H2>
                <FlexRow className='margin20'>
                    <FlexCol>
                        Denna funktion är under uppbyggnad.
                    </FlexCol>
                </FlexRow>
            </MainLayout>
        )
    }
}

import { QueryProvider, FilterProviderMediator } from '_utils/graphql';

const getBaseQueryFilters = runtimeContext => {
    return {
        title: { contains: "$queryInput" }
    }
}

/**
 * @function filterProvider
 * @param  {type} const filterProvider {description}
 * @return {type} {description}
 */
const filterProvider = {

    getContextForSuperadmin: (runtimeContext) => {
        return {
            filters: {
                'recruitments': getBaseQueryFilters(runtimeContext)
            }
        }
    },

    getContextForGroupAdmin: (runtimeContext) => {
        return {
            filters: {
              'recruitments': {
                ...getBaseQueryFilters(runtimeContext),
                OR: [
                  { company: { groups: { some: { id: "$companyId" } }} },
                  { company: { id: "$companyId" } }
                ]
              }
            },
            variableResolvers: {
              "$companyId": props => ({ companyId: { equals: props.currentCompany.id } })
            },
            variableDefinitions: [
              { "$companyId": "StringFilter!" } 
            ]
        }
    },

    getContextForAdmin: (runtimeContext) => {
        return {
            filters: {
                'recruitments': {
                    ...getBaseQueryFilters(runtimeContext),
                    company: { id: "$companyId" }
                }
            },
            variableResolvers: {
                "$companyId": props => ({ companyId: { equals: props.currentCompany.id } })
            },
            variableDefinitions: [
                { "$companyId": "StringFilter!" } 
            ]
        }
    },

  getContextForGroupRecruiterViewer: runtimeContext => {
    return {
      filters: {
        "recruitments": {
          AND : [{
              ...getBaseQueryFilters(runtimeContext)
            }, {
              OR: [
                { recruiter: { id: "$userId" } },
                { recruiterGuests: { some: { id: "$userId" } }}
              ]
            }
          ]
        }
      },
      variableResolvers: {
        "$userId": props => ({ userId: { equals: props.user.id } })
      },
      variableDefinitions: [
        { "$userId": "StringFilter!" }
      ]
    }
  },

  getContextForRecruiterViewer: runtimeContext => {
    return {
      filters: {
        "recruitments": {
          AND : [{
              ...getBaseQueryFilters(runtimeContext)
            }, {
              OR: [
                { recruiter: { id: "$userId" } },
                { recruiterGuests: { some: { id: "$userId" } }}
              ]
            }
          ]
        }
      },
      variableResolvers: {
        "$userId": props => ({ userId: { equals: props.user.id } })
      },
      variableDefinitions: [
        { "$userId": "StringFilter!" }
      ]
    }
  }
}

/**
 * @function recruitmentsQueryReconciler
 * @param  {type} filterContext {description}
 * @return {type} {description}
 */
const recruitmentsQueryReconciler = (filterContext) => {
    const { filters, variableDefinitions } = filterContext;
    return `query RecruitmentQuery(
        $queryInput: String,
        $paging_limit: Int!,
        $paging_skip: Int
        ${ variableDefinitions ? `, ${variableDefinitions}` : "" }
    ){
        results: recruitments(
          where: ${filters.recruitments},
          take: $paging_limit,
          skip: $paging_skip
        ){
          id
          title
          state
          company {
              id
              name,
              urlName
          }
        }
        meta: aggregateRecruitment(where: ${filters.recruitments}) {
          _count {
            _all
          }
        }
    }`;
}

export default QueryProvider.Create()
    .withFilterProvider(new FilterProviderMediator(filterProvider))
    .withQueryReconciler(recruitmentsQueryReconciler)
    .get();

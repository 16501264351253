import React from 'react'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import draftToHtml from 'draftjs-to-html'
import TinyEditor from '_components/tiny-editor'
import ObjectPicker from '_components/object-picker'
import { PrimaryButton, SecondaryButton, CancelButton } from '_layout/buttons'
import { COMPANY_QUERY } from '_containers/recruitment/recruitment-docs-ql'
import { TemplateType } from '../../constants';

@compose(
    withRouter,
    graphql(COMPANY_QUERY, { name: 'companyQuery', options: props => ({ variables: { urlName: props.match.params.company, filter: { OR: [{type: { equals: TemplateType.INTERVIEW } }, {type: { equals: TemplateType.REFERENCES } }, {type: { equals: TemplateType.OTHER } }] } } }) })
)
export default class Backing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contentHTML: props.content,
            templates: [],
            selected: null,
            dirty: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.companyQuery.loading && nextProps.companyQuery.company && nextProps.companyQuery.company.templates && nextProps.companyQuery.company.templates.length) {
            return { templates: nextProps.companyQuery.company.templates }
        }
        return null
    }
    
    onContentChange = contentHTML => {
        this.setState({contentHTML, dirty: true})
    }

    onSelected = selected => {
        this.setState({selected, contentHTML: selected.contentHTML || draftToHtml(selected.content), dirty: true})
    }

    onDirty = () => {
        this.setState({dirty: true}, this.props.onDirty)
    }

    onClean = e => {
        this.setState({contentHTML: null, selected: null, dirty: false})
    }

    onSave = e => {
        this.props.onSave(this.state.contentHTML)
    }

    render() {
        const { templates, selected, contentHTML } = this.state

        return (
            <div>
                <h3>{this.props.title}</h3>
                <TinyEditor
                    key={selected && selected.id}
                    form
                    placeholder='Fyll i ditt underlag här...'
                    content={contentHTML}
                    onChange={this.onContentChange}
                    onDirty={this.onDirty}
                    style={{ width: '80vw', maxWidth: 1024, height: '100vh', maxHeight: 'calc(95vh - 260px)', minHeight: 300 }} />
                {templates.length > 0 &&
                <ObjectPicker
                    className='mb4'
                    values={templates}
                    placeholder='Hämta från mall...' 
                    current={selected}
                    onSelected={this.onSelected}
                    noMatch='Inga träffar' />
                }
                <div className='tr'>
                    <CancelButton className='mr3' onClick={this.props.onClose}>Avbryt</CancelButton>
                    <SecondaryButton className='mr3' onClick={this.onClean}>Rensa</SecondaryButton>
                    <PrimaryButton onClick={this.onSave} disabled={!this.state.dirty}>Spara</PrimaryButton>
                </div>
            </div>
        )
    }
}

import React from 'react'
import styled from 'styled-components'
import { Spinner } from '_layout/form-elements'
import message from '_root/components/message'
import map from 'lodash/fp/map'
import flatMap from 'lodash/fp/flatMap'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'

//#region Styles
const Component = styled.div`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;

  > span {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  input {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -1;

    &:focus + .button {
      outline: 1px dotted var(--color-bg-dark);
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

  input+span {
    cursor: pointer;
    position: relative;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 32px;
    border-radius: 18px;
    font-size: 0.9em;
    color: var(--color-text-white);
    background-color: ${props => props.$red ? 'var(--color-brand-red)' : (props.$black ? 'var(--color-bg-dark)' : 'var(--color-brand-green)')};

    &:hover {
      background-color: ${props => props.$red ? 'var(--color-brand-red-hover)' : (props.$black ? 'var(--color-menu-five)' : 'var(--color-brand-green-dark)')};
    }

    .spinner {
      position: absolute;
      right: 10px;
      width: 24px;
      margin: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
`
//#endregion

export default class FilePicker extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        loading: props.loading,
        text: props.text || 'Välj fil...',
        exts: [],
        mimes: [],
        names: []
      }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const mimes = nextProps.accept && nextProps.accept.length
            ? map(t => t.mime)(nextProps.accept)
            : []
        const exts = nextProps.accept && nextProps.accept.length
            ? flatMap(t => t.ext)(nextProps.accept)
            : []
        const names = nextProps.accept && nextProps.accept.length
            ? map(t => t.name)(nextProps.accept)
            : []
        const nextState = { mimes, exts, names }
        if (nextProps.loading !== prevState.loading) {
            nextState.loading = nextProps.loading
        }
        return nextState
    }

    picked = e => {
        const fileInput = document.getElementById(this.props.fileId)
        if (this.props.onMultiple) {
            // Return mutiple files
            const validFiles = fileInput && fileInput.files ? this.validateFiles(fileInput.files) : []
            if (validFiles.length) {
                this.props.onMultiple(validFiles)
                this.setState({ fileKey: Math.random() })
            }
        }
        else {
            const file = fileInput && fileInput.files && fileInput.files[0]
            if (file) {
                if (this.validateFiles([file]).length !== 1) return
                if (this.props.onFile) {
                    // Return single file only
                    this.props.onFile(file)
                    this.setState({ fileKey: Math.random() })
                }
                else if (this.props.onBase64) {
                    // Return Base64 of file and file itself
                    const reader = new FileReader()
                    const that = this
                    reader.onload = function (e) {
                        that.props.onBase64(e.target.result, file)
                        that.setState({ ...that.state, fileKey: Math.random(), text: file.name || that.props.text })
                    }
                    reader.readAsDataURL(file)
                }
            }
        }
    }

    validateFiles = files => {
        const { mimes, names } = this.state
        if (!mimes || !mimes.length) return files
        const validFiles = filter(f => !f.type || mimes.includes(f.type))(files)
        if (files.length !== validFiles.length) {
            message(`Välj fil med något av följande format:<br/><br/>${names.join(", ")}`)
            return []
        }
        return this.validateExtensions(validFiles)
    }

    checkExtension = filename => {
        if (!filename || !filename.length) return false
        const parts = filename.toLowerCase().split('.')
        if (parts.length === 1) return false
        const ext = '.' + parts[parts.length-1]
        const { exts } = this.state
        return exts && find(e => e === ext)(exts)
    }

    validateExtensions = files => {
      const { mimes, names } = this.state
      if (!mimes || !mimes.length) return files
      const validFiles = filter(f => !f.name || this.checkExtension(f.name))(files)
      if (files.length !== validFiles.length) {
        message(`Filändelse saknas! Välj fil med något av följande format:<br/><br/>${names.join(", ")}`)
        return []
      }
      return validFiles
    }

    inputAccept = () => {
      const { mimes, exts } = this.state
      return mimes.concat(exts).join(", ")
    }

    render() {
      const { text, loading, fileKey } = this.state
      const { label, fileId, onMultiple, readOnly, red, black } = this.props
      const inputAccept = this.inputAccept()
      return (
        <Component $red={red} $black={black}>
          {label &&
          <span>{label}</span>
          }
          <label htmlFor={fileId}>
            <input key={fileKey} id={fileId} name={fileId} type='file' onChange={this.picked} accept={inputAccept} multiple={!!onMultiple} disabled={readOnly} />
            <span>
              { text }
              { loading && <Spinner /> }
            </span>
          </label>
        </Component>
      )
    }
}

import React from 'react'
import styled from 'styled-components'
import withRouter from '_hoc/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { Spinner } from '_layout/form-elements'
import JobAdEditor from '_components/recruit/job-ad-editor'
import { JOB_AD_QUERY, UPDATE_JOB_AD_VIEWS } from '_containers/recruitment/recruitment-ql'
import to from '_services/await.to'

//#region Styles
const Main = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
`
//#endregion

@compose(
    withRouter,
    graphql(JOB_AD_QUERY, { name: 'jobAdQuery', options: props => ({ variables: { id: props.match.params.annons } }) }),
    graphql(UPDATE_JOB_AD_VIEWS, { name: 'updateJobAdViews' })
)
export default class ShowAd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            incremented: false
        }
    }

    componentDidMount = async () => {
      const [error, result] = await to(this.props.updateJobAdViews({variables: { jobAdId: this.props.match.params.annons }}))
      if (error || !result) {
        console.error('ShowAd:componentDidMount:error: ', error, this.props.match.params.annons);
      }
    }

    render() {
      let content = null
      if (this.props.jobAdQuery.loading || !this.props.jobAdQuery.jobAd) {
        content = <Spinner />
      }
      else {
        const { jobAd } = this.props.jobAdQuery
        content = <JobAdEditor white company={jobAd.recruitment.company} jobAd={jobAd} readOnly />
      }
      return (
        <Main>
          {content}
        </Main>
      )
    }
}

import React from "react"
import styled from "styled-components"
import map from "lodash/fp/map"
import isEmpty from "lodash/fp/isEmpty"
import { Editor } from "@tinymce/tinymce-react"
import { EditIcon } from "_layout/form-elements"

//#region Styles
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  background: ${props => (props.readOnly ? "none" : "var(--color-bg-white)")};

  &.form {
    min-width: min(300px, 100%);
    max-height: 35vh;
    overflow: auto;
    margin-bottom: 20px;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .tox-tinymce {
    border-color: var(--color-line);
  }

  .tox.tox-tinymce-aux {
    z-index: 20000;
  }

  .tox-statusbar__branding {
    visibility: hidden;
  }

  .mce-content-body {
    outline: none;
    padding: 10px;
    height: 100%;
    font-family: var(--font-family);

    &.mce-edit-focus {
      overflow-y: auto;
      max-height: 50vh;
    }
  }

  .mce-edit-focus {
    outline: none !important;
  }

  .mce-tinymce {
    position: absolute;
    bottom: 100%;
    top: initial !important;
    left: -1px !important;
    z-index: 10000;
  }

  p:first-child {
    margin-top: 0;
  }

  .content-editor:before,
  .content-editor.empty:focus:before {
    display: none;
  }
  .content-editor.empty:before {
    display: block;
    content: attr(data-placeholder);
    color: var(--color-text-medium);
  }
`
//#endregion

export default class TinyEditor extends React.Component {
  constructor(props) {
    super(props)

    const config = {
      language: "sv_SE",
      language_url: "/langs/sv_SE.js",
      browser_spellcheck: true,
      readonly: props.readOnly,
      promotion: false,
      height: "100%",
      resize: false,
      skin: "tinymce-5",
      browser_spellcheck: false,
      convert_urls: false,
      relative_urls: false,
      content_css: "/styles/tinymce-custom.css",
      menubar: "edit insert format table",
      paste_as_text: true,
      newline_behavior: "default",
      contextmenu: false,
      statusbar: false,
      placeholder: (!props.readOnly && props.placeholder) || "",
      menu: {
        edit: { title: "Edit", items: "undo redo | cut copy paste pastetext | selectall | searchreplace" },
        insert: {
          title: "Insert",
          items: props.insert || "bullist numlist | link addcomment template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
        },
        format: {
          title: "Format",
          items: "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
        },
        table: { title: "Table", items: "inserttable | cell row column | advtablesort | tableprops deletetable" },
      },
      inline: !!props.inline,
      external_plugins: { rcImageGallery: "/plugins/rc-image-gallery/plugin.min.js" },
      plugins: props.plugins || "autolink link lists",
      init_instance_callback: this.onEditorInit,
    }
    if (props.inline) {
      config.fixed_toolbar_container = "#tinyToolbar"
    }
    if (props.selection) {
      config.toolbar = props.selection
    }
    if (props.toolbar) {
      config.toolbar = props.toolbar
    } else {
      config.insert_toolbar = props.insert || "h3 bold italic underline | bullist numlist | link"
      config.selection_toolbar = props.selection || "h3 bold italic underline | bullist numlist outdent indent | link unlink | removeformat"
    }
    if (props.blocks) {
      config.block_formats = "Brödtext=p;Rubrik 2=h2;Rubrik 3=h3;Rubrik 4=h4"
    }
    if (props.images) {
      config.image_list = props.images
      config.formats = {
        alignleft: { selector: "img", classes: "left" },
        alignright: { selector: "img", classes: "right" },
      }
      if (props.imagetools) {
        config.imagetools_cors_hosts = ["rc.devserver.phosdev.se", "jobbet.devserver.phosdev.se", "jobbet.se"]
      }
      if (config.insert_toolbar && config.insert_toolbar.indexOf("image") < 0) {
        config.insert_toolbar += " image"
      } else if (config.toolbar && config.toolbar.indexOf("image") < 0) {
        config.toolbar += " image"
      }
    } else {
      if (config.insert_toolbar && config.insert_toolbar.indexOf("image") >= 0) {
        config.insert_toolbar = config.insert_toolbar.replace("image", "")
      } else if (config.toolbar && config.toolbar.indexOf("image") >= 0) {
        config.toolbar = config.toolbar.replace("image", "")
      }
    }

    const bullets = () => {
      if (!props.bullets || !props.bullets.length) return null
      const items = map((bullet) => `<li>${bullet}</li>`)(props.bullets)
      return `<ul>${items.join("")}</ul>`
    }

    const content = props.content === undefined || props.content === null ? bullets() : props.content
    this.state = {
      focus: false,
      modalOpen: false,
      icon: props.icon,
      readOnly: props.readOnly,
      content: content,
      contentDefault: props.content,
      config: config,
      editorKey: "init",
    }
  }

  componentDidMount() {
    if (this.props.scrollToView) {
      this.wrapper = document.getElementById("tinyWrapper")
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.readOnly !== prevState.readOnly) {
      nextState.readOnly = nextProps.readOnly
    }
    if (nextProps.insert !== prevState.insert) {
      nextState.insert = nextProps.insert
    }
    return isEmpty(nextState) ? null : nextState
  }

  focusEditor = () => {
    this.editor && this.editor.editor && this.editor.editor.focus()
  }

  onFocus = (focus) => {
    if (focus && this.wrapper) {
      this.wrapper.scrollIntoView(true)
    }
    this.setState({ focus: focus })
  }

  handleEditorChange = (content) => {
    const { onChange } = this.props
    this.setState({ content: content }, () => onChange && onChange(content))
  }

  onEditorInit = (editor) => {
    this.editor = editor
    editor.on("focus", () => this.onFocus(true))
    editor.on("blur", () => this.onFocus(false))
    editor.dom.addClass(editor.bodyElement, "content-editor")
    if (!this.state.content) {
      editor.dom.addClass(editor.bodyElement, "empty")
    }
    editor.dom.setAttrib(editor.bodyElement, "data-placeholder", this.props.placeholder)
    editor.on("selectionchange", function () {
      if (editor.getContent() === "") {
        editor.dom.addClass(editor.bodyElement, "empty")
      } else {
        editor.dom.removeClass(editor.bodyElement, "empty")
      }
    })
    this.setState({ editorKey: "mounted" })
  }

  render() {
    const { icon, content, contentDefault, focus, config, readOnly, editorKey } = this.state
    const { form, inline } = this.props
    const classes = form ? ["form"] : []
    if (focus) classes.push("focus")

    return (
      <Wrapper id="tinyWrapper" className={classes.join(" ")} style={this.props.style}>
        {inline && <div id="tinyToolbar"></div>}
        {icon && <EditIcon onClick={this.focusEditor} title="Klicka för att redigera" />}
        <Editor
          key={"gallery-" + editorKey}
          disabled={readOnly}
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          // ref={(ref) => (this.editor = ref)}
          initialValue={contentDefault}
          value={content}
          init={config}
          onClick={this.focusEditor}
          onEditorChange={this.handleEditorChange}
          onDirty={this.props.onDirty}
        />
      </Wrapper>
    )
  }
}

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import { map } from 'lodash/fp'

import ProfileImage from '_images/profile.svg'
import MailIconSrc from '_images/email.svg'

import { COMPANY_CONTACTS } from '_settings/company-profile-ql'
import withSendmailMutation from '_hoc/withSendmailMutation'

import inject from '_services/inject'

//#region Styles
const PackageInformationWrapper = styled.div`
    padding: 15px 20px;
    margin-bottom: 20px;
    background: var(--color-brand-green-light);
    box-shadow: 0 12px 20px var(--color-bg-grey-dark);
    z-index: 100;

    h2 {
      margin: 0 0 10px 80px;
    }

    p {
      display: inline-block;
      width: 45%;
      margin: 0 0 0 80px;
      padding-right: 20px;
      font-size: 16px;
      vertical-align: top;

      strong {
        display: block;
        font-size: 26px;
        line-height: 32px;
        margin: 10px 0 20px 0;
        white-space: nowrap;
        color: var(--color-brand-green-dark);
      }
    }  

    button {
        display: block;
        width: 185px;
        margin: 0 auto;
    }
`
const Contacts = styled.div`
  display: inline-flex;
  width: 35%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
`
const Contact = styled.div`
  display: inline-flex;
  margin: 0 30px;
  text-align: center;
  align-items: center;
  flex-flow: column nowrap;
`
const ContactImage = styled.div`
  display: inline-block;
  width: 95px;
  height: 95px;
  margin-bottom: 5px;
  border: 1px solid var(--color-text);
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-size: 34px 34px;

  img {
    max-width: 100%;
  }
`
const ContactLink = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--color-text);
  white-space: nowrap;
`
const MailIcon = styled.i`
  display: inline-block;
  margin-right: 4px;
  width: 18px;
  height: 10px;
  background: url(${MailIconSrc}) no-repeat scroll 50% 50%;
`
//#endregion Styles
@compose(
  inject('user'),
  withSendmailMutation,
  graphql(COMPANY_CONTACTS, { name: 'companyContacts', options: props => ({ variables: { urlName: props.recruitment.company.urlName } }) }),
)
export default class PackageInformation extends PureComponent {
  render() {
    let { companyContacts, recruitment: { recruiterExternals: contacts } } = this.props
    if (!contacts || !contacts.length) {
      contacts = companyContacts.loading ? [] : companyContacts && companyContacts.company && companyContacts.company.jobbetContacts
    }
    return (
        <PackageInformationWrapper>
            <h2>Ni får fler och bättre ansökningar med rätt marknadsföring av er platsannons!</h2>
            <p>
              <strong>Fråga gärna oss!</strong>
              Vi kan tipsa om vilka annonskanaler som ger bäst effekt för just den här rekryteringens målgrupp.
            </p>
            {!!contacts.length &&
            <Contacts>{map(contact =>
              <Contact key={contact.id}>
                  <ContactImage data-tooltip-id="root-tooltip" data-tooltip-content={contact.firstName + ' ' + contact.lastName}>
                      {contact && contact.picture && contact.picture.url &&
                      <img src={contact.picture.url} alt={contact.firstName + ' ' + contact.lastName} />
                      }
                  </ContactImage>
                  <span>
                      {contact && contact.email &&
                      <ContactLink href={'mailto:' + contact.email} data-tooltip-id="root-tooltip" data-tooltip-content={contact.email}><MailIcon/>{contact.firstName + ' ' + contact.lastName}</ContactLink>
                      }
                      <br/>
                      {contact && (contact.phone || contact.mobile) &&
                      <ContactLink href={'tel:' + (contact.mobile)}>{contact.mobile}</ContactLink>
                      }
                  </span>
              </Contact>
              )(contacts.slice(0, 2))}
            </Contacts>
          }
        </PackageInformationWrapper>
    );
  }
}

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import withRouter from '_hoc/withRouter'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'react-recompose'
import styled from 'styled-components'
import { differenceInCalendarDays, parseISO } from 'date-fns'
import {
  map,
  uniq,
  uniqBy,
  find,
  filter,
  flatten,
  isEqual,
  isEmpty,
  isUndefined
} from 'lodash/fp'
import Slim from '_root/slim/slim.react'
import LoginService from '_services/login-service'
import isLoggedIn from '_services/is-logged-in'
import UploadService from '_services/upload-service'
import TrashIcon from '_images/trash.svg'
import { Spinner } from '_layout/form-elements'
import MemberLayout from '_containers/member/member-layout'
import { FlexCol, Label, Input, FilePicker, TextArea, Checkbox, Radio } from '_layout/form-elements'
import { BlackButton, CancelButton } from '_layout/buttons'
import Question from '_components/applications/question'
import { RECRUITMENT_APPLY_QUERY } from '_containers/recruitment/recruitment-ql'
import { REGISTER_AND_APPLY, SEND_APPLICATION, CREATE_ADDRESS, UPDATE_APPLICANT } from '_containers/recruitment/application-ql'
import { GET_CANDIDATE } from '_root/containers/member/members-ql'
import { CHECK_USER, SIGNIN_USER_MUTATION, FORGOT_PASSWORD_MUTATION } from '_root/common-ql'
import { RecruitmentState, ADMIN_COMPANY_ID, FileType, AllFileTypes } from '_root/constants'
import inject from '_services/inject'
import to from '_services/await.to'
import all from '_services/await.all'
import message from '_root/components/message'
import confirm from '_root/components/confirm'
import { MEDLEM } from '_root/routes/url-names'
import CompanyLayout from './apply/company-layout'
import { Helmet } from 'react-helmet'

//#region Styles
const Warning = styled.div`
  padding: 10px;
  color: var(--color-brand-red);
`
const H2 = styled.h2`
  margin: 0;
  color: var(--color-text);
`
const Section = styled.section`
  position: relative;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--color-bg-white);
  background: var(--color-bg-white);

  @media screen and (min-width: 740px) {
    padding: 20px 40px;
  }

  .doc-link {
    margin-right: 1em;
  }
`
const GridRow = styled.div`
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
`
const Error = styled.p`
  margin: -1em 0 1em 0;
  font-size: 0.9em;
  color: var(--color-error);
`
const Ingress = styled.p`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`
const IngressError = styled.p`
  color: var(--color-error);
`
const Selection = styled.ol`
  padding: 0 0 0 1em;
`
const ForgotLink = styled.a`
  align-self: center;
  margin-left: 20px;
`
const List = styled.ul`
  list-style-type: none;
  margin: 20px 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 10px;

    &.no-links {
      font-style: italic;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`
const Trash = styled.a`
  display: inline-block;
  margin-left: 1rem;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const CustomInput = styled(Input)`
  &[readonly] {
    pointer-events: none;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
  }
`
const SlimEditor = styled.div`
  width: min(300px, 100%);
  height: 300px;

  .slim {
    cursor: pointer;
    border: 1px solid var(--color-bg-dark);
    background: var(--color-bg-white);
    box-shadow: inset 0 0 20px 0 var(--color-bg-grey);

    &[data-state*=empty]:hover {
      background: var(--color-bg-white);
      box-shadow: inset 0 0 20px 5px var(--color-bg-grey);
    }
  }

  > img {
    width: 100%;
    height: auto;
  }
`
const FileList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  a {
    line-height: 1;
    font-size: 16px;
    vertical-align: text-top;
  }
`
const FileArea = styled.div`
  display: inline-flex;
  align-items: center;
`
const SaveArea = styled.div`
  text-align: center;
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject('user'),
  graphql(SIGNIN_USER_MUTATION, { name: 'signinUser' }),
  graphql(FORGOT_PASSWORD_MUTATION, { name: 'forgotPassword' }),
  graphql(SEND_APPLICATION, { name: 'sendApplication' }),
  graphql(REGISTER_AND_APPLY, { name: 'registerAndApply' }),
  graphql(CREATE_ADDRESS, { name: 'createAddress' }),
  graphql(UPDATE_APPLICANT, { name: 'updateApplicant' }),
  graphql(GET_CANDIDATE, { name: 'candidateQuery', skip: ({ user }) => !(user.candidate && user.candidate.id), options: ({ user: { candidate }}) => ({ variables: { id: candidate.id } }) }),
  graphql(RECRUITMENT_APPLY_QUERY, { name: 'recruitmentQuery', options: props => ({ variables: { id: props.match.params.recruitment } }) })
)
export default class Apply extends React.Component {
  constructor(props) {
    super(props)
    let oldBrowser = false
    const platform = (navigator.userAgentData && navigator.userAgentData.platform) || navigator.platform
    const iOSdevice = platform && ['iphone', 'ipad', 'ipod'].includes(platform.toLowerCase())
    if (iOSdevice) {
      try {
        oldBrowser = parseInt((navigator.userAgent.match(/\b[0-9]+_[0-9]+(?:_[0-9]+)?\b/)||[''])[0].split('_')[0], 10) < 14
      }
      catch (e) {
        console.error('e: ', e)
      }
    }
    this.state = {...this.initialState, oldBrowser}
  }

  initialState = {
    approved: false,
    loading: true,
    candidateId: null,
    loadingUser: false,
    firstName: '',
    lastName: '',
    email: '',
    forgotPassword: false,
    password: '',
    password1: '',
    password2: '',
    mobile: '',
    postalCity: '',
    postalCode: '',
    links: '',
    information: '',
    docs: [],
    oldDocs: [],
    answers: {},
    spam: false,
    showSpam: true,
    mailChecked: false,
    checkedEmail: null,
    applicant: null,
    isUpdatingApplication: false
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const { user, recruitmentQuery, candidateQuery } = nextProps
    if (!prevState.recruitment && !recruitmentQuery.loading && recruitmentQuery.recruitment) {
      const recruitment = recruitmentQuery.recruitment
      nextState.recruitment = recruitment
      const remainingDays = differenceInCalendarDays(parseISO(recruitment.applyStop), new Date())
      nextState.applyable = recruitment.state === RecruitmentState.ACTIVE && remainingDays > -1
    }
    if (user.authenticated && user.id && !prevState.applicantId) {
      nextState.applicant = null
      nextState.applicantId = user.id
      nextState.systemUser = !user.candidate
      nextState.firstName = user.firstName || ''
      nextState.lastName = user.lastName || ''
      nextState.email = user.email
      nextState.mobile = user.mobile || ''
      nextState.picture = user.picture
      nextState.mailChecked = true
      if (user.address) {
        nextState.addressId = user.address.id // TODO: Remove when handled by server?
        nextState.postalCity = user.address.postalCity
        nextState.postalCode = user.address.postalCode
      }
      if (this.slim && user.picture && !prevState.pictureLoaded) {
        this.slim._options.instantEdit = false
        this.slim.load(user.picture.url, (error, data) => { this.slim._options.instantEdit = true })
        nextState.pictureLoaded = true
      }
    }
    if (candidateQuery && !prevState.candidate && !candidateQuery.loading && candidateQuery.candidate) {
      const candidate = candidateQuery.candidate
      nextState.candidate = candidate
      nextState.cvList = uniqBy('id')(filter(cv => cv !== null)([...map(a => a.cv)(candidate.applications), ...map(i => i.cv)(candidate.interests)]))
      nextState.docList = uniqBy('id')(filter(doc => doc !== null && !!doc.id)(flatten(candidate.applications.map(a => a.docs).concat(candidate.interests.map(i => i.docs)))))
      nextState.spam = candidate.spam
      nextState.showSpam = !candidate.spam
      nextState.cvId = nextState.cvList.length ? nextState.cvList[0].id : null
      const interest = find(i => i.company.id === ADMIN_COMPANY_ID)(candidate.interests)
      if (interest) {
        nextState.interest = interest
      }
      nextState.pristine = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        postalCity: user.address && user.address.postalCity,
        postalCode: user.address && user.address.postalCode,
        mobile: user.mobile,
        picture: user.picture,
        spam: candidate.spam
      }
    }
    const candidate = prevState.candidate || nextState.candidate
    const recruitment = prevState.recruitment || nextState.recruitment
    if (candidate && recruitment && isUndefined(prevState.existingApplication)) {
      const existingApplication = find(a => a.recruitment.id === recruitment.id)(candidate.applications)
      if (existingApplication) {
        nextState.existingApplication = true
        nextState.applyable = false
        nextState.answers = existingApplication.answers
        nextState.cv = existingApplication.cv
        nextState.docs = existingApplication.docs
        nextState.information = existingApplication.information
        nextState.links = existingApplication.links
        let processedLinks = existingApplication.links.trim().split(/\s+/)
        processedLinks = processedLinks.length && processedLinks[0]
          ? processedLinks.map(link => <li key={link}><a href={link.match(/^[a-zA-Z]+:\/\//) ? link : `http://${link}`} target='_blank' rel='opener'>{link}</a></li>)
          : []
        nextState.processedLinks = processedLinks && processedLinks.length ? <ul>{processedLinks}</ul> : ''
      }
    }
    if (prevState.loadingUser && user.authenticated && !user.loading) {
      nextState.loadingUser = false
    }
    const candidateLoading = candidateQuery && candidateQuery.loading
    const loading = candidateLoading || recruitmentQuery.loading
    if (prevState.loading !== loading) {
      nextState.loading = loading
    }
    return !isEmpty(nextState) ? nextState : null;
  }

  notPristineState = () => {
    const { firstName, lastName, email, postalCity, postalCode, mobile, picture, spam } = this.state
    const current = { firstName, lastName, email, postalCity, postalCode, mobile, picture, spam }
    return !isEqual(current)(this.state.pristine)
  }

  validatePassword = () => {
    const { candidate, password1, password2 } = this.state
    if (candidate) return true
    if (!password1 || !password2) return false
    if (password1 !== password2) return false
    if (password1.length < 8) return false
    const hasUpperCase = /[A-Z]/.test(password1)
    const hasLowerCase = /[a-z]/.test(password1)
    const hasNumbers = /\d/.test(password1)
    const hasNonalphas = /\W/.test(password1)
    return hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas > 2
  }

  validate = () => {
    const { email, firstName, lastName, mobile, postalCity, postalCode, answers, recruitment } = this.state
    const invalid = {
      email: !email || email.trim().length === 0,
      password: !this.validatePassword(),
      firstName: !firstName || firstName.trim().length === 0,
      lastName: !lastName || lastName.trim().length === 0,
      mobile: !mobile || mobile.trim().length === 0,
      postalCity: !postalCity || postalCity.trim().length === 0,
      postalCode: !postalCode || postalCode.trim().length === 0,
      selection: recruitment.selection.filter(q => !answers[q.id]).map(q => q.id)
    }
    const isInvalid = invalid.email || invalid.password || invalid.firstName || invalid.lastName || invalid.mobile || invalid.postalCity || invalid.postalCode || invalid.selection.length
    this.setState({invalid: isInvalid ? invalid : null})
    return !isInvalid
  }

  searchUser = async () => {
    const variables = { email: this.state.email }
    const [error, result] = await to(this.props.client.query({ query: CHECK_USER, variables: variables }))
    if (error || !result) {
      if (error.message && error.message.indexOf('Invalid') > -1) {
          this.setState({invalid: {...this.state.invalid, email: true}})
      }
      return
    }
    const { data: { user: applicant } } = result
    this.setState({ applicant: applicant, mailChecked: true, invalid: null })
  }

  login = async () => {
    const { email, password } = this.state
    this.setState({ loadingUser: true })
    const [error, result] = await to(this.props.signinUser({
      variables: {
        email,
        password
      }
    }))
    if (error || !result) {
      if (error.message && error.message.indexOf('Invalid email') > -1) {
        this.setState({invalid: {...this.state.invalid, email: true}})
      }
      else {
        message('Inloggning misslyckades. Vänligen kontrollera e-post och lösenord.')
        this.setState({ loadingUser: false })
        console.warn('apply:login:error:', error)
      }
    }
    else {
      const { data: { authenticateUser }} = result
      LoginService.loginUser(authenticateUser.token)
    }
  }

  forgotPassword = e => {
    e.preventDefault()
    this.setState({forgotPassword: true})
    return false
  }

  sendPassword = async e => {
    e.preventDefault()
    this.setState({loadingPassword: true})
    const { email } = this.state
    const [error, result] = await to(this.props.forgotPassword({ variables: { email, fromAdmin: false } }))
    if (error) {
      console.error('sendPassword:error: ', error, email)
      message('Ett fel uppstod när lösenord skulle hanteras.')
    }
    else {
      if (!result.data.forgotPassword) console.error('login:sendPassword: ', result)
      message('Ett nytt lösenord har skickats till angiven e-postadress om det finns ett medlemskonto.')
    }
    this.setState({forgotPassword: false, loadingPassword: false})
    return false
  }

  sendApplication = async () => {
    if (!this.state.applyable || !this.validate()) return
    if (!this.state.candidate) {
      return this.newCandidateAndApplication()
    }
    const {
      firstName, lastName, email, mobile, postalCity, postalCode, picture, spam, candidate: { id: candidateId },
      answers, cvId, docs, oldDocs, information, links, recruitment, interest
    } = this.state
    const proceed = !!cvId || await confirm('Vill du gå vidare utan att bifoga ett CV?')
    if (!proceed) return
    this.setState({isUpdatingApplication: true})
    if (email && email.trim() && firstName && lastName && mobile && postalCity && postalCode) {
      const groups = interest.occupationalGroups.map(o => o.id)
      let groupsAdded = false
      recruitment.occupationalGroups.forEach(o => {
        if (!groups.includes(o.id)) {
          groups.push(o.id)}
          groupsAdded = true
      })
      if (this.notPristineState() || groupsAdded) {
        const variables = {
          firstName,
          lastName,
          email,
          mobile,
          postalCity,
          postalCode,
          candidateId: { id: candidateId },
          interestId: { id: interest.id },
          occupationalGroupsIds: groups.map(id => ({ id: id })),
          spam
        }
        if (this.pictureChanged) {
          variables.pictureId = undefined
          if (picture) {
            const [error, { data }] = await to(UploadService.uploadBase64(picture.image, picture.name))
            if (error || !data || !data.id) {
              console.error('apply:saveUser:uploadPicture:error', error || data)
            }
            else {
              variables.pictureId = { connect: { id: data.id } }
            }
          }
        }
        variables.id = this.state.applicantId
        if (!this.state.addressId) {
          const address = { userId: variables.id, postalCity, postalCode }
          const [error, result] = await to(this.props.createAddress({variables: address}))
          if (error || !result) {
            console.error('apply:saveUser:createAddress:error', error, address)
            this.setState({isUpdatingApplication: false})
            return
          }
          variables.addressId = { id: result.data.createAddress.id }
        }
        else {
          variables.addressId = { id: this.state.addressId }
        }
        let [error, result] = await to(this.props.updateApplicant({variables}))
        if (error || !result) {
          console.error('apply:saveUser:update:error', error, variables)
          return
        }
      }
    }
    const docsIds = uniq(docs.map(doc => doc.id).concat(oldDocs))
    const [error, result] = await to(this.props.sendApplication({variables: { candidateId, email, recruitmentId: recruitment.id, recruitmentTitle: recruitment.title, answers, cvId, docsIds, information, links }}))
    if (error || !result) {
      console.error('apply:sendApplication:error', error)
      message('Något gick fel och ansökan kunde inte skickas. Vänligen försök igen eller kontakta kundtjänst.')
      this.setState({isUpdatingApplication: false})
    }
    else {
      this.setState({ confirmed: true, isUpdatingApplication: false })
    }
  }

  newCandidateAndApplication = async () => {
    if (!this.state.applyable) return
    const { firstName, lastName, email, password1: password, mobile, postalCity, postalCode, picture, spam, answers, cvId, docs, information, links, recruitment } = this.state
    const proceed = !!cvId || await confirm('Vill du gå vidare utan att bifoga ett CV?')
    if (!proceed) return
    this.setState({isUpdatingApplication: true})
    const docsIds = uniq(map(doc => doc.id)(docs))
    const variables = {
      firstName,
      lastName,
      email,
      password,
      mobile,
      postalCity,
      postalCode,
      recruitmentId: recruitment.id,
      recruitmentTitle: recruitment.title,
      interestCompanyId: ADMIN_COMPANY_ID,
      occupationalGroupsIds: recruitment.occupationalGroups.map(o => o.id),
      answers,
      cvId,
      docsIds,
      information,
      links,
      spam
    }
    if (this.pictureChanged) {
      variables.pictureId = undefined
      if (picture) {
        const [error, { data }] = await to(UploadService.uploadBase64(picture.image, picture.name))
        if (error || !data || !data.id) {
          console.error('apply:newCandidateAndApplication:uploadPicture:error', error || data)
        }
        else {
          variables.pictureId = data.id
        }
      }
    }
    const [error, result] = await to(this.props.registerAndApply({variables}))
    if (error || !result) {
      console.error('apply:registerAndApply:error', error)
      message('Något gick fel och ansökan kunde inte skickas. Vänligen försök igen eller kontakta kundtjänst.')
      this.setState({isUpdatingApplication: false})
    }
    else {
      this.setState({ confirmed: true, isUpdatingApplication: false })
    }
  }

  onEmailKeyPress = e => {
    const key = `${e.keyCode || e.charCode}`
    if (key === '13' || key === '10') {
      this.searchUser()
    }
  }

  onLoginKeyPress = e => {
    const key = `${e.keyCode || e.charCode}`
    if (key === '13' || key === '10') {
      this.login()
    }
  }

  setEmail = e => {
    this.setState({email: e.target.value.trim(), invalid: {...this.state.invalid, email: false}})
  }

  checkEmail = () => {
    const invalid = !this.state.email || !this.state.email.trim().length
    this.setState({invalid: { ...this.state.invalid, email: invalid }})
  }

  acceptTerms = e => {
    // if (!this.state.approved || !this.state.recaptcha) return
    if (!this.state.approved) return
    this.signup()
  }

  onSlimInit = (data, slim) => {
    this.slim = slim
    const { picture } = this.state
    if (picture && picture.url) {
      slim._options.instantEdit = false
      slim.load(picture.url, (error, data) => { slim._options.instantEdit = true })
    }
  }

  onPhotoCancel = (data, slim) => {
    const { picture } = this.state
    if (this.pictureChanged || !picture || !picture.url || !this.slim) return
    this.slim._options.instantEdit = false
    this.slim.load(picture.url, (error, data) => { this.slim._options.instantEdit = true })
  }

  onPhotoConfirm = (data, slim) => {
    this.pictureChanged = true
    this.setState({picture: slim.dataBase64.output})
  }

  onPhotoRemoved = (data, slim) => {
    this.pictureChanged = true
    this.setState({picture: undefined})
  }

  onAnswer = (questionId, answer) => {
    const answers = {...this.state.answers}
    answers[questionId] = answer
    this.setState({answers})
  }

  onCvClick = e => {
    this.setState({cvId: e.target.value, cv: null})
  }

  onDocClick = e => {
    if (!e.target.value) return false
    const docId = e.target.value
    const added = e.target.checked
    const { oldDocs } = this.state
    if (added) {
      oldDocs.push(docId)
    }
    else {
      oldDocs.splice(oldDocs.indexOf(docId), 1)
    }
    this.setState({ oldDocs })
  }

  onFileSelected = async file => {
    if (this.state.docs?.filter(d => d.name === file.name)?.length > 0) {
      message("Vänligen ladda inte upp flera filer med samma namn!")
      return
    }
    if (file) {
      const [error, { data }] = await to(UploadService.uploadFile(file))
      if (error || !data || !data.id) {
        message("Något gick fel, vänligen prova att byta namn på filen och försök igen.")
        console.error('apply:onFileSelected:uploadProfile:error', error || data)
        return
      }
      this.setState({cvId: data.id, cv: data})
    }
  }

  onDocsSelected = async files => {
    const cvName = this.state.cv?.name
    if (files?.filter(f => f.name === cvName)?.length > 0) {
      message("Vänligen ladda inte upp flera filer med samma namn!")
      return
    }
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map(file => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload || !resultUpload.length) {
        message("Något gick fel, vänligen prova att byta namn på filen och försök igen.")
        console.error('onFilesSelected:uploadFile:error ', errorUpload)
        return
      }
      const uploaded = map(upload => upload.data)(resultUpload)
      const docs = uniqBy('id')([...this.state.docs, ...uploaded])
      this.setState({docs})
    }
  }

  onDeleteFile = async e => {
    e.stopPropagation()
    e.preventDefault()
    const docId = e.target.dataset.doc
    if (!docId) return
    if (await confirm('Är du säker på att du vill radera uppladdad fil?')) {
      const docs = this.state.docs.filter(doc => doc.id !== docId)
      if (docs.length !== this.state.docs.length) {
        this.setState({ docs: docs })
      }
    }
  }

  onDeleteCV = async e => {
    e.stopPropagation()
    e.preventDefault()
    if (await confirm('Är du säker på att du vill radera uppladdat CV?')) {
      this.setState({ cv: null, cvId: null })
    }
  }

  render() {
    let content = null

    const loggedIn = isLoggedIn()
    const {
      loading, systemUser, applicantId, recruitment, applicant, existingApplication,
      applyable, confirmed, mailChecked, invalid, oldBrowser
    } = this.state

    if (systemUser) {
      content = <Fragment>
        {recruitment &&
        <Section>
          <H2>Ansökan till tjänsten {recruitment.title}, {recruitment.company.name}</H2>
        </Section>
        }
        <Section>
          <IngressError>Du är inloggad som en systemanvändare och kan därför inte se denna ansökan.<br/>Logga ut ifall du vill kunna se ansökningssidan.</IngressError>
          <p>
            <a onClick={e => LoginService.clearToken({ client: this.props.client, nextUrl: this.props.location.pathname })}>Logga ut</a>
          </p>
        </Section>
      </Fragment>
    }
    else if (loading) {
      content = <Fragment><Spinner /></Fragment>
    }
    else if (confirmed) {
      content = <Fragment>
        <Section>
          <H2>Tack för din ansökan!</H2>
        </Section>
        <Section>
          <Ingress>Ett e-post har skickats till din adress med uppgifter kring din ansökan.</Ingress>
          <p>I samband med din ansökan har ett konto skapats åt dig hos Jobbet.se.</p>
          <p><Link to={MEDLEM}>Klicka här för att komma till dina sidor hos Jobbet.se</Link></p>
        </Section>
      </Fragment>
    }
    else if (!applyable && !existingApplication) {
      content = <Fragment>
        <Helmet>
          <title>Ansökan till tjänsten {recruitment.title}</title>
        </Helmet>
        <Section>
          <H2>Ansökan till tjänsten {recruitment.title}</H2>
        </Section>
        <Section>
          <Ingress>Denna tjänst är inte öppen för ansökningar.</Ingress>
          <p><Link to={MEDLEM}>Till medlemssidan på Jobbet.se</Link></p>
        </Section>
      </Fragment>
    }
    else if (!mailChecked || (applicant && applicant.employer)) {
      const { email, invalid } = this.state
      content = <Fragment>
        <Helmet>
          <title>Ansökan till tjänsten {recruitment.title}</title>
        </Helmet>
        <Section>
          <H2>Ansökan till tjänsten {recruitment.title}, {recruitment.company.name}</H2>
        </Section>
        <Section>
          {applicant && applicant.employer &&
          <IngressError>
            Denna e-postadress är kopplad till en systemanvändare. Vänligen använd en annan e-postadress för att söka denna tjänst.
          </IngressError>
          }
          {!applicant &&
          <Ingress>
            Vänligen börja med att fylla i din e-postadress nedan.
          </Ingress>
          }
          <Label>E-post *</Label>
            <Input type='email'
              autoFocus
              className='mr4'
              required={true}
              placeholder='kandidat@exempel.com'
              value={email}
              $invalid={invalid && invalid.email}
              onKeyPress={this.onEmailKeyPress}
              onBlur={this.checkEmail}
              onChange={this.setEmail} />
            <BlackButton onClick={this.searchUser} className='mb4' disabled={invalid && invalid.email}>Gå vidare</BlackButton>
            {invalid && invalid.email &&
            <Error>Ange en fullständig e-postadress</Error>
            }
        </Section>
      </Fragment>
    }
    else if (applicant && applicant.candidate) {
      const { email, password, forgotPassword, loadingPassword, loadingUser } = this.state
      content = <Fragment>
        <Helmet>
          <title>Ansökan till tjänsten {recruitment.title}</title>
        </Helmet>
        <Section>
          <H2>Ansökan till tjänsten {recruitment.title}</H2>
        </Section>
        {!forgotPassword &&
        <Section>
          <Ingress>
            Vi ser att du tidigare sökt ett jobb med denna e-postadress via Jobbet.se. Vänligen fortsätt med att logga in nedan.
          </Ingress>
          <Input
            value={email}
            onChange={this.setEmail}
            onBlur={this.checkEmail}
            $invalid={invalid && invalid.email}
            type='email'
            placeholder='Din e-postadress'/>
          {invalid && invalid.email &&
          <Error>Ange en fullständig e-postadress</Error>
          }
          <br/>
          <Input
            autoFocus
            value={password}
            onKeyPress={this.onLoginKeyPress}
            onChange={e => this.setState({password: e.target.value })}
            type='password'
            placeholder='Ditt lösenord'/>
          <div className='flex mt3'>
            <BlackButton className='mr2' onClick={this.login} loading={loadingUser}>Login</BlackButton>
            <ForgotLink onClick={this.forgotPassword}>Glömt lösenord?</ForgotLink>
          </div>
        </Section>
        }
        {forgotPassword &&
        <Section>
          <Ingress>
            Ange din e-postadress nedan och klicka på knappen för att få ett nytt lösenord.
          </Ingress>
          <Input
            type='email'
            value={email}
            onChange={this.setEmail}
            onBlur={this.checkEmail}
            $invalid={invalid && invalid.email}
            placeholder='Din e-postadress'/>
          {invalid && invalid.email &&
          <Error>Ange en fullständig e-postadress</Error>
          }
          <div className='flex login'>
            <CancelButton className='mr3' onClick={e => this.setState({forgotPassword: false})}>Avbryt</CancelButton>
            <BlackButton onClick={this.sendPassword} disabled={invalid && invalid.email} loading={loadingPassword}>Skicka nytt lösenord</BlackButton>
          </div>
        </Section>
        }
      </Fragment>
    }
    else {
      const { 
        firstName, lastName, email, postalCity, postalCode, mobile, picture, answers, links, spam, password1, password2,
        information, cvId, cv, docs, cvList, docList, approved, isUpdatingApplication, candidate, processedLinks, showSpam
      } = this.state

      const canSave = (!!candidate || approved)

      content = <Fragment>
        <Helmet>
          <title>Ansökan till tjänsten {recruitment.title}</title>
        </Helmet>
        <Section>
          <H2>Ansökan till tjänsten {recruitment.title}</H2>
          {existingApplication &&
          <IngressError>Du har redan sökt denna tjänst.</IngressError>
          }
        </Section>
        <Section>
          <h3>Kontaktuppgifter</h3>
          {applyable &&
          <Ingress>
          Fyll i dina personuppgifter.
          </Ingress>
          }
          <GridRow>
            <FlexCol>
              <Label>E-post *</Label>
              <CustomInput type='email'
                readOnly={applicantId || !applyable}
                placeholder='namn@exempel.com'
                value={email}
                $invalid={invalid && invalid.email}
                onBlur={this.checkEmail}
                onChange={this.setEmail} />
              {invalid && invalid && invalid.email &&
              <Error>Ange en fullständig e-postadress</Error>
              }
              <Label>Förnamn *</Label>
              <CustomInput type='text'
                readOnly={!applyable}
                autoFocus={!firstName}
                placeholder='Förnamn'
                value={firstName}
                $invalid={invalid && invalid.firstName}
                onBlur={invalid && this.validate}
                onChange={e => this.setState({firstName: e.target.value})} />
              {invalid && invalid && invalid.firstName &&
              <Error>Ange förnamn</Error>
              }
              <Label>Efternamn *</Label>
              <CustomInput type='text'
                readOnly={!applyable}
                placeholder='Efternamn'
                value={lastName}
                $invalid={invalid && invalid.lastName}
                onBlur={invalid && this.validate}
                onChange={e => this.setState({lastName: e.target.value})} />
              {invalid && invalid && invalid.lastName &&
              <Error>Ange efternamn</Error>
              }
              <Label>Mobil *</Label>
              <CustomInput type='text'
                readOnly={!applyable}
                placeholder='07x-xxxxxxx'
                value={mobile}
                $invalid={invalid && invalid.mobile}
                onBlur={invalid && this.validate}
                onChange={e => this.setState({mobile: e.target.value})} />
              {invalid && invalid && invalid.mobile &&
              <Error>Ange mobilnummer</Error>
              }
              <Label>Bostadsort *</Label>
              <CustomInput type='text'
                readOnly={!applyable}
                placeholder='Bostadsort'
                value={postalCity}
                $invalid={invalid && invalid.postalCity}
                onBlur={invalid && this.validate}
                onChange={e => this.setState({postalCity: e.target.value})} />
              {invalid && invalid && invalid.postalCity &&
              <Error>Ange bostadsort</Error>
              }
              <Label>Postnummer *</Label>
              <CustomInput type='text'
                readOnly={!applyable}
                placeholder='Postnummer'
                value={postalCode}
                $invalid={invalid && invalid.postalCode}
                onBlur={invalid && this.validate}
                onChange={e => this.setState({postalCode: e.target.value})} />
              {invalid && invalid && invalid.postalCode &&
              <Error>Ange postnummer</Error>
              }
            </FlexCol>
            <FlexCol>
              {(applyable || picture) &&
              <Label>Foto</Label>
              }
              <SlimEditor>
                {applyable &&
                <Slim
                  ratio='1:1'
                  label='Dra din bild hit eller klicka här'
                  labelLoading='Laddar bild...'
                  instantEdit={true}
                  buttonEditTitle='Redigera'
                  buttonRemoveTitle='Ta bort'
                  buttonRotateTitle='Rotera'
                  buttonCancelLabel='Avbryt'
                  buttonCancelTitle='Avbryt'
                  buttonConfirmLabel='OK'
                  buttonConfirmTitle='OK'
                  didInit={this.onSlimInit}
                  didCancel={this.onPhotoCancel}
                  didConfirm={this.onPhotoConfirm}
                  didRemove={this.onPhotoRemoved}
                  size={{ width:300, height:300 }}>
                  <input type='file' name='pictureFile'/>
                </Slim>
                }
                {!applyable && picture &&
                <img src={picture.url} alt={`${firstName} ${lastName}`} />
                }
              </SlimEditor>
            </FlexCol>
          </GridRow>
        </Section>
        <Section>
          <h3>Kort motivering</h3>
          {applyable &&
          <Fragment>
          <p>Innehållet i denna ruta visas som en presentation för arbetsgivaren.</p>
          <TextArea
            readOnly={!applyable}
            placeholder='Skriv eller klistra in text...'
            width='100%'
            rows='6'
            value={information}
            onChange={e => this.setState({ information: e.target.value})}>{information}</TextArea>
          </Fragment>
          }
          {applyable ||
          <p>{information || <em>Du har inte angett någon motivering</em>}</p>
          }
        </Section>
        {recruitment.selection.length > 0 &&
        <Section>
          <h3>Urvalsfrågor</h3>
          <Selection>
          {map(question =>
            <Question
              key={question.id}
              question={question}
              answer={answers[question.id]}
              onChange={answer => this.onAnswer(question.id, answer)}
              readOnly={!applyable}
              invalid={invalid && invalid.selection.includes(question.id)}
            />
          )(recruitment.selection)}
          </Selection>
          {invalid && invalid.selection.length > 0 &&
          <Error className='mt3'>Alla urvalsfrågor är obligatoriska (obesvarade frågor har röd text).</Error>
          }
        </Section>}
        <Section>
          <h3>CV</h3>
          {applyable &&
          <Fragment>
            <p>Här kan man ladda upp ett CV för denna ansökan.</p>
            <FilePicker
              black
              readOnly={!applyable}
              className='mb1'
              fileId={'candidate-cv'}
              text={'Ladda upp CV…'}
              accept={[FileType.DOC, FileType.DOCX, FileType.XLS, FileType.XLSX, FileType.ODT, FileType.ODS, FileType.PDF, FileType.TXT, FileType.RTF]}
              onFile={this.onFileSelected} />
          </Fragment>
          }
          {!cv && !applyable &&
          <p className='italic'>Du har inte angivit något CV.</p>
          }
          {cv &&
          <FileArea>
            <a className='doc-link' href={cv.url} download='cv.name' target='_blank' rel='noopener noreferrer'>{cv.name}</a>
            {applyable &&
            <Trash onClick={this.onDeleteCV} data-tooltip-content="Radera CV" data-tooltip-id="tooltip" />
            }
          </FileArea>
          }
          {applyable && cvList && cvList.length > 0 &&
          <div>
            <p>...eller välja ett tidigare uppladdat CV.</p>
            <FileList key={cvId}>
              {map(c => c &&
              <li key={c.id}>
                <Radio name='oldCv' value={c.id} onChange={this.onCvClick} checked={cvId && (c.id === cvId)} readOnly={!applyable} />
                <a href={c.url} title={'Ladda ner ' + c.name} download='c.name' target='_blank' rel='noopener noreferrer'>{c.name}</a>
              </li>)(cvList)}
              <Radio name='oldCv' value='' onChange={this.onCvClick} checked={!cvId} readOnly={!applyable} label='Skicka inget CV' />
            </FileList>
          </div>
          }
        </Section>
        <Section>
          <h3>Länkar</h3>
          {applyable &&
          <Fragment>
            <p>Lägg till länkar till relevanta sidor som LinkedIn etc.</p>
            <TextArea
              readOnly={!applyable}
              placeholder='Länkar'
              rows='3'
              width='100%'
              value={links}
              onChange={e => this.setState({links: e.target.value})} />
          </Fragment>
          }
          {applyable || processedLinks || <p><em>Du har inte angett några länkar</em></p>}
        </Section>
        <Section>
          <h3>Bilagor</h3>
          {applyable &&
          <Fragment>
            <p>Här kan man ladda upp personligt brev, intyg eller liknande.</p>
            <FilePicker
              black
              readOnly={!applyable}
              className='mb1'
              fileId={'candidate-files'}
              text={'Ladda upp bilagor…'}
              accept={AllFileTypes}
              onMultiple={this.onDocsSelected} />
          </Fragment>
          }
          {docs && !!docs.length &&
          <List>
            {map(doc => <li key={doc.id}>
              <a className='doc-link' href={doc.url} download target='_blank' rel='noopener noreferrer'>{doc.name}</a>
              {applyable &&
              <Trash onClick={this.onDeleteFile} data-doc={doc.id} data-tooltip-content="Radera bilaga" data-tooltip-id="tooltip" />
              }
            </li>)(docs)}
          </List>
          }
          {(!docs || !docs.length) &&
          <p><em>Inga bilagor har laddats upp.</em></p>
          }
          {applyable && docList && docList.length > 0 &&
          <div>
            <p>...och/eller välja tidigare uppladdade dokument.</p>
            <FileList>
              {docList.map(d => d &&
              <li key={d.id}>
                <Checkbox name='oldDoc' value={d.id} onChange={this.onDocClick} checked={docs.find(doc => doc.id === d.id)} />
                <a href={d.url} title={'Ladda ner ' + d.name} download='c.name' target='_blank' rel='noopener noreferrer'>{d.name}</a>
              </li>)}
            </FileList>
          </div>
          }
        </Section>
        {!candidate &&
        <Section>
          <h3>Välj lösenord *</h3>
          <p>I samband med din ansökan skapas ett konto åt dig hos Jobbet.se.</p>
          <Ingress>
            Lösenordet måste var minst 8 tecken långt samt uppfylla minst 3 av följande:<br/>
            <i title='ABC...'>stor bokstav</i>, <i title='abc...'>liten bokstav</i>, <i title='123...'>siffra</i>, <i title='!"#?...'>specialtecken</i>.
          </Ingress>
          <Input type='password'
            required
            autoComplete='new-password'
            value={password1}
            onChange={e => this.setState({password1: e.target.value})}
            onBlur={invalid && this.validate}
            $invalid={invalid && invalid.password}
            placeholder='Ange lösenord' />
          <br/>
          <Input type='password'
            required
            autoComplete='new-password'
            value={password2}
            onChange={e => this.setState({password2: e.target.value})}
            onBlur={this.validate}
            $invalid={invalid && invalid.password}
            placeholder='Bekräfta lösenord' />
          {invalid && invalid.password &&
          <Error>Lösenordet uppfyller inte kraven.</Error>
          }
        </Section>
        }
        {!candidate &&
        <Section>
          <Ingress>
            Innan du kan skicka ansökan behöver du godkänna villkoren nedan.
          </Ingress>
          <p>
            <a href='https://api.jobbet.se/filer/cltclwcln007dtkvmhf6g824o/PolicyJobbet.se.pdf' target='_blank' rel='noopener noreferrer'>Personuppgiftspolicy</a>
          </p>
          <Checkbox checked={approved} onChange={e => this.setState({approved: !approved})} label='Jag godkänner villkoren i policyn ovan.' />
        </Section>
        }
        {showSpam &&
        <Section>
          <Checkbox defaultChecked={spam} onChange={e => this.setState({spam: !spam})} disabled={!applyable} label='Ja tack, skicka mig jobbtips!' />
        </Section>
        }
        <SaveArea>
          <BlackButton
            className='mt4 mb4'
            loading={isUpdatingApplication}
            disabled={isUpdatingApplication || !applyable || !canSave}
            onClick={this.sendApplication}>
            Skicka ansökan
          </BlackButton>
          {!applyable &&
          <Error>{existingApplication ? 'Denna tjänst går inte att söka igen.' : 'Denna tjänst är inte öppen för ansökningar.'}</Error>
          }
          {invalid &&
          <Error>Något saknas. Kontrollera formuläret ovan.</Error>
          }
        </SaveArea>
      </Fragment>
    }
    return loggedIn ? <MemberLayout nomenu={!loggedIn}>
      {oldBrowser &&
      <Section>
        <Warning>Din version av iOS är gammal och stödjer kanske inte alla funktioner på denna sida.</Warning>
      </Section>
      }
      {content}
    </MemberLayout> :
    <CompanyLayout company={recruitment?.company}>
      {content}
    </CompanyLayout>
  }
}
